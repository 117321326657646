import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SalesLogsService } from './sales-logs.service';

@Injectable({
  providedIn: 'root'
})
export class SalesLogsResolver implements Resolve<any> {
  constructor(private salesLogsService: SalesLogsService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    return this.salesLogsService.getSalesLogs().pipe(
      map(response => {
        if (response && response['status'] === 'SUCCESS') {
          // Reverse the array before returning it
          return response['data'].slice().reverse(); // Use slice to create a copy and reverse it
        } else {
          throw new Error('Error fetching data');
        }
      }),
      catchError(error => {
        console.error('Resolver error:', error);
        return of([]); // Return an empty array in case of an error
      })
    );
  }
}
