import {
    AfterViewInit,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, forkJoin } from 'rxjs';
import Swal from 'sweetalert2';


import { ExportService } from 'app/csv_export/csv.service';
import { ExchangesService } from '../exchanges.service';
import { Exchanges } from '../exchanges.type';
import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';
import { PackagesOderService } from 'app/packages/packages.service';
import { RoleService } from 'app/core/auth/guards/role.service';



const EXCEL_EXTENSION = '.xlsx';
export interface SalesOrderData {
    customerName: string;
    saleNumber: string;
    status: string;
    shippingDate: string;
    shipping_area: string;
    delivery_type: string;
    products: any;
    totalCost: number;
    isExpanded: boolean;
    returningDate: string;
    rfr: string;
    isDebited: boolean,
}

@Component({
    selector: 'app-list-exchanges',
    templateUrl: './list-exchanges.component.html',
    styleUrls: ['./list-exchanges.component.scss'],
})
export class ListExchangesComponent implements OnInit, OnDestroy, AfterViewInit {
    displayedColumns: string[] = [

        'select',
        'CustomerName',
        'SaleNumber',
        'ReturnDate',
        'Status',
        "Delivery Type",
        "Shipping Area",
        'total',
        'total_ex',
        'remiangNewTotal',
        'rfr',
        'actions',
        'create'

    ];
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;


    salesOrders: any;
    dataSource: MatTableDataSource<any>;
    @ViewChild('salesOrderTabel') salesOrderTabel: ElementRef;
    salesorder$: Observable<Exchanges[]>;
    flashMessage: 'success' | 'error' | null = null;
    isLoading: boolean = true;
    dropdownHidden: boolean = true;
    fileName = 'SalesReturnSheet.xlsx';
    numberOfCustomers: number;
    numberOfProducts: number;
    highestSellerCustomerId: string;
    biggestSellerProductId: string;
    completedSalesOrdersCount: number;
    startedSalesOrdersCount: number;
    checked = false;
    selectedDate: any;
    selection = new SelectionModel<SalesOrderData>(true, []);
    totalRows: number = 0;
    selectedShippingArea: string;
    selectedDeliveryType: string;
    selectedRowIds: any[] = [];
    selectAll: boolean = false;
    counter: number = 0;
    drawerMode = 'side';
    drawerOpened = true;
    requiredRoles: string[] = ["Manager", "Operation Manager", "HR"];  // Define the roles allowed to execute the function
    requiredRolesCreation: string[] = ["Manager", "Communication Manager", "HR"];  // Define the roles allowed to execute the function
    constructor(
        private _router: Router,
        private _salesorderService: ExchangesService,
        private datePipe: DatePipe,
        private route: ActivatedRoute,
        private roleService: RoleService,

        private _packageService: PackagesOderService,


    ) {

    }


    ngOnInit(): void {


        this.loadSalesOrders();
    }
    loadSalesOrders(): void {
        this.route.data.subscribe((data: { exchange: Exchanges[] }) => {
            this.salesOrders = data.exchange;
            this.loadExchanges();
        });
    }
    refreshExchanges(): void {
        this.isLoading = true;
        this._salesorderService.getSalesOrder().subscribe(
            (exchanges: Exchanges[]) => {
                this.salesOrders = exchanges;
                this.loadExchanges();

                this.isLoading = false;
            },
            error => {
                this.isLoading = false;
                console.error('Error refreshing exchanges:', error);
            }
        );
    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        setTimeout(() => {
            if (this.dataSource) {

            }
        }, 400);
    }

    multipleInvoice() {

    }

    async createPackageOrder(packageData: any): Promise<any> {
        try {
            const response = await this._packageService.createPackageOrder(packageData);
            if (response.success) {
                return { success: true, message: `${packageData.packageOrder} Package Order created successfully.` };
            } else {
                return { success: false, errors: response.errors };
            }
        } catch (error) {
            throw new Error(`Error creating Package Order: ${error.message}`);
        }
    }
    async UpdateRecive(packageData: any, id: any): Promise<any> {
        try {
            const response = await this._salesorderService.updateSalesOrder(id, packageData).toPromise();
            if (response) { // Check if response is truthy
                return { success: true, message: `${packageData.packageOrder} Package Order received successfully.` };
            } else {
                return { success: false, message: `Failed to receive package order for ${packageData.packageOrder}.` };
            }
        } catch (error) {
            throw new Error(`Error receiving Package Order: ${error.message}`);
        }
    }



    toggleDrawerMode(): void {
        this.drawerMode = this.drawerMode === 'side' ? 'over' : 'side';
    }

    /**
     * Toggle the drawer open
     */
    toggleDrawerOpen(): void {
        this.drawerOpened = !this.drawerOpened;
    }

    /**
     * Drawer opened changed
     *
     * @param opened
     */
    drawerOpenedChanged(opened: boolean): void {
        this.drawerOpened = opened;
    }



    async singlePackage(orderId: any) {
        this.roleService.withRoleCheck(this.requiredRoles, async () => {


            try {
                const currentDate = new Date().toISOString().split('T')[0];
                let paid = false;
                let isCreditAndDelivared = false;

                this._salesorderService.getSalesOrderById(orderId).subscribe(
                    async (data: any) => {

                        let total_remaining_total: number;

                      
                        
                        const shippingArea = data.shipping_area;
                        const address = data.address;
                        const deliveryType = data.delivery_type;
                        const customerName = data.sales_order.Customers.firstName + ' ' + data.sales_order.Customers.lastName;
                        const customerRemarks = data.notes;
                        const paymentType = data.payment_type;
                        const packageOrder = this.generatePackageCode();
                        const packageDate = currentDate;
                        const total = data.remainingAmount;
                        const received = data.received;
                        const exchanges = data.id;
                        const isPackaged = true;
                        const isShipped = true;
                        const isExchange = true;
                        total_remaining_total = data.remainingAmount;

                        // Calculate `paid` and `isCreditAndDelivared`
                        if (total < 0) {
                            // If total is less than 0, it means an overpayment or negative balance
                            total_remaining_total = Math.abs(total); // Convert total to positive for remaining total
                            if (data.paymentType !== 'CASH ON DELIVERY') {
                                paid = true;
                            }
                        } else if (total > 0) {
                            // If total is greater than 0, it means there is still an outstanding amount
                            total_remaining_total = total; // Keep total as is
                            isCreditAndDelivared = true;
                            paid = false;
                        } else {
                            // If total is exactly 0, there is no remaining amount
                            total_remaining_total = 0;
                            paid = false;
                            isCreditAndDelivared = false;
                        }
                        
                        let shippingCarrier = '';

                        if (data.shipping_carrier_invalley_others) {
                            shippingCarrier = data.shipping_carrier_invalley_others;
                        } else if (data.others_out) {
                            shippingCarrier = data.others_out;
                        } else {
                            // Fallback to specific shipping area cases
                            switch (shippingArea) {
                                case 'Inside Valley':
                                    shippingCarrier = data.shipping_carrier_invalley || '';
                                    break;
                                case 'Outside Valley':
                                    shippingCarrier = data.shipping_carrier_outvalley || '';
                                    break;
                                case 'International':
                                    shippingCarrier = data.shipping_carrier_int || '';
                                    break;
                                default:
                                    shippingCarrier = ''; // or handle a default case if needed
                                    break;
                            }
                        }



                        // Add data to the row object
                        const row = {
                            packageDate,
                            exchanges,
                            isPackaged,
                            isShipped,
                            isExchange,
                            shippingArea,
                            address,
                            deliveryType,
                            customerName,
                            paymentType,
                            packageOrder,
                            customerRemarks,
                            total,
                            received,
                            shippingCarrier,
                            paid,
                            total_remaining_total,
                            isCreditAndDelivared,
                        };

                        // Create the package order
                        const packageResult = await this.createPackageOrder(row);

                        if (packageResult.success) {
                            // Show success notification using Swal
                            Swal.fire({
                                icon: 'success',
                                title: 'Package Order Created!',
                                text: packageResult.message,
                            });

                            const Toast = Swal.mixin({
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 3000,
                                timerProgressBar: true,
                                didOpen: (toast) => {
                                    toast.addEventListener(
                                        'mouseenter',
                                        Swal.stopTimer
                                    );
                                    toast.addEventListener(
                                        'mouseleave',
                                        Swal.resumeTimer
                                    );
                                },
                            });

                            Toast.fire({
                                icon: 'success',
                                title: `Selected Sales Order has been shipped!!`,
                            });
                        } else {
                            // Show error notification using Swal
                            Swal.fire({
                                icon: 'error',
                                title: 'Error Creating Package Order',
                                text: JSON.stringify(packageResult.errors),
                            });
                        }

                        this._router.navigate(['apps/sales-logs']);
                    },
                    (error) => {
                        console.error('Error fetching sales order details:', error);
                    }
                );
            } catch (error) {
                console.error('Error fetching or processing sales order details:', error);
            }
        });
    }
    confirmReceive(orderId: any) {
        this.roleService.withRoleCheck(this.requiredRoles, async () => {

            try {
                // Show loading dialog
                Swal.fire({
                    title: 'Processing...',
                    html: 'Please wait while the package is being processed.',
                    allowOutsideClick: false,
                    didOpen: () => {
                        Swal.showLoading();
                    }
                });

                // Fetch the data for a single sales order and subscribe to the observable
                this._salesorderService.getSalesOrderById(orderId).subscribe(
                    async (data: any) => {
                        const currentDate = new Date().toISOString().split('T')[0];

                        const date = currentDate;
                        const sales_order = data.sales_order.id;
                        const id = orderId;
                        const received = true;
                        const exchanges = data.id;
                        const status = "Started Exchange Order";

                        // Add data to the row object
                        const row = {
                            date,
                            exchanges,
                            sales_order,
                            received,
                            status,
                        };

                        try {
                            // Create the package order
                            const packageResult = await this.UpdateRecive(row, orderId);
                            this.refreshExchanges();


                            // Close the loading dialog

                            if (packageResult.success) {
                                // Show success notification using Swal
                                Swal.fire({
                                    icon: 'success',
                                    title: 'Package Received!',
                                    text: packageResult.message,
                                });

                                const Toast = Swal.mixin({
                                    toast: true,
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 3000,
                                    timerProgressBar: true,
                                    didOpen: (toast) => {
                                        toast.addEventListener('mouseenter', Swal.stopTimer);
                                        toast.addEventListener('mouseleave', Swal.resumeTimer);
                                    },
                                });

                                Toast.fire({
                                    icon: 'success',
                                    title: `Selected Package has been received, you can now proceed to ship it!`,
                                });



                            } else {
                                // Show error notification using Swal
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Error Creating Package Order',
                                    text: JSON.stringify(packageResult.errors),
                                });
                            }

                            this._router.navigate(['apps/exchanges']);
                            Swal.close();

                        } catch (error) {
                            console.error('Error processing sales order details:', error);
                            Swal.fire({
                                icon: 'error',
                                title: 'Error',
                                text: 'An unexpected error occurred while processing the package.',
                            });
                        }
                    },
                    (error) => {
                        console.error('Error fetching sales order details:', error);
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: 'An error occurred while fetching sales order details.',
                        });
                    }
                );
            } catch (error) {
                console.error('Error fetching or processing sales order details:', error);
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'An unexpected error occurred while processing the package.',
                });
            }
        });
    }


    multiplePackage() {
        this.roleService.withRoleCheck(this.requiredRoles, async () => {

            const ids = this.selectedRowIds;
            const currentDate = new Date().toISOString().split('T')[0];
            const requests = ids.map(id => this._salesorderService.getSalesOrderById(id));

            // Use forkJoin to execute all requests concurrently and get their results
            forkJoin(requests).subscribe(
                async (results: any[]) => {
                    const rows: any[] = [];

                    results.forEach(data => {
                        if (data.status === 'Started Exchange Order') {

                            let total_remaining_total: number;

                            const shippingArea = data.shipping_area;
                            const address = data.address;
                            const deliveryType = data.sales_order.delivery_type;
                            const customerName = data.sales_order.Customers.firstName + ' ' + data.sales_order.Customers.lastName;
                            const customerRemarks = data.sales_order.notes;
                            const paymentType = data.payment_type;
                            const packageOrder = this.generatePackageCode();
                            const packageDate = currentDate;
                            const total = data.remainingAmount;
                            const received = data.received;
                            const exchanges = data.id;
                            const isPackaged = true;
                            const isShipped = true;
                            const isExchange = true;
                            total_remaining_total = data.remainingAmount;

                            let paid = false;
                            let isCreditAndDelivared = false;

                            if (total < 0) {
                                // If total is less than 0, it means an overpayment or negative balance
                                total_remaining_total = Math.abs(total); // Convert total to positive for remaining total
                                if (data.paymentType !== 'CASH ON DELIVERY') {
                                    paid = true;
                                }
                            } else if (total > 0) {
                                // If total is greater than 0, it means there is still an outstanding amount
                                total_remaining_total = total; // Keep total as is
                                isCreditAndDelivared = true;
                                paid = false;
                            } else {
                                // If total is exactly 0, there is no remaining amount
                                total_remaining_total = 0;
                                paid = false;
                                isCreditAndDelivared = false;
                            }
                            


                            let shippingCarrier = '';

                            if (data.shipping_carrier_invalley_others) {
                                shippingCarrier = data.shipping_carrier_invalley_others;
                            } else if (data.others_out) {
                                shippingCarrier = data.others_out;
                            } else {
                                // Fallback to specific shipping area cases
                                switch (shippingArea) {
                                    case 'Inside Valley':
                                        shippingCarrier = data.shipping_carrier_invalley || '';
                                        break;
                                    case 'Outside Valley':
                                        shippingCarrier = data.shipping_carrier_outvalley || '';
                                        break;
                                    case 'International':
                                        shippingCarrier = data.shipping_carrier_int || '';
                                        break;
                                    default:
                                        shippingCarrier = ''; // or handle a default case if needed
                                        break;
                                }
                            }


                            rows.push({
                                packageDate,
                                exchanges,
                                isPackaged,
                                isShipped,
                                isExchange,
                                shippingArea,
                                address,
                                deliveryType,
                                customerName,
                                paymentType,
                                packageOrder,
                                customerRemarks,
                                total,
                                received,
                                paid,
                                total_remaining_total,
                                isCreditAndDelivared,
                                shippingCarrier
                            });
                        }
                    });

                    // Iterate through the rows and create package orders for each eligible sales order
                    for (const row of rows) {
                        const packageResult = await this.createPackageOrder(row);
                        if (packageResult.success) {
                            // Show success notification using Swal
                            Swal.fire({
                                icon: 'success',
                                title: 'Package Order Created!',
                                text: packageResult.message,
                            });
                            const Toast = Swal.mixin({
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 3000,
                                timerProgressBar: true,
                                didOpen: (toast) => {
                                    toast.addEventListener(
                                        'mouseenter',
                                        Swal.stopTimer
                                    );
                                    toast.addEventListener(
                                        'mouseleave',
                                        Swal.resumeTimer
                                    );
                                },
                            });

                            Toast.fire({
                                icon: 'success',
                                title: `Selected Sales Order has been shipped!!`,
                            });
                        } else {
                            // Show error notification using Swal
                            Swal.fire({
                                icon: 'error',
                                title: 'Error Creating Package Order',
                                text: JSON.stringify(packageResult.errors),
                            });
                        }
                        this._router.navigate(['apps/sales-logs']);
                    }
                },
                (error) => {
                    console.error('Error fetching sales order details:', error);
                }
            );
        });
    }




    generatePackageCode(): string {
        this.counter++;
        const randomPart = Array.from(
            { length: 8 },
            () => Math.random().toString(36).toUpperCase()[2]
        ).join('');
        const packageCode = `PKG-E${String(this.counter).padStart(
            4,
            '0'
        )}-${randomPart}`;
        return packageCode;
    }

    updateSelectedRowIds(row: any): void {
        const index = this.selectedRowIds.indexOf(row.id);

        if (index >= 0) {
            // If the ID is already in the array, remove it
            this.selectedRowIds.splice(index, 1);
        } else {
            // If the ID is not in the array, add it
            this.selectedRowIds.push(row.id);
        }

    }

    isAllSelected() {
        if (!this.dataSource || !this.dataSource.data) {
            // If dataSource or dataSource.data is undefined or null, return false
            return false;
        }

        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected > 0 && numSelected === numRows;
    }


    /** Selects all rows if they are not all selected; otherwise clear selection. */
    toggleAllRows() {
        if (this.isAllSelected()) {
            this.selection.clear();
            this.selectedRowIds = []; // Clear selectedRowIds when deselecting all
        } else {
            this.dataSource.data.forEach(row => {
                this.selection.select(row); // Select all rows in the dataSource
                this.updateSelectedRowIds(row); // Update selectedRowIds for each row
            });
        }
    }

    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: SalesOrderData): string {
        if (!row) {
            const result = `${this.isAllSelected() ? 'deselect' : 'select'} all`;
            return result;
        }
        const result = `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.customerName + 1}`;
        return result;
    }

    calculateTotal(): number {
        // Check if the dataSource is defined and has data
        if (this.dataSource && this.dataSource.data) {
            return this.dataSource.data.reduce((total, row) => total + row.totalCost, 0);
        }
        // Return 0 if data is not available or undefined
        return 0;
    }
    applyFilters() {
        // Get the filter strings for shipping area and delivery type
        const shippingAreaFilter = this.selectedShippingArea ? this.selectedShippingArea.trim().toLowerCase() : '';
        const deliveryTypeFilter = this.selectedDeliveryType ? this.selectedDeliveryType.trim().toLowerCase() : '';
        const selectedDateFilter = this.selectedDate ? this.datePipe.transform(this.selectedDate, 'yyyy-MM-dd') : '';

        // Apply filter only if either shipping area, delivery type, or date is selected
        if (shippingAreaFilter || deliveryTypeFilter || selectedDateFilter) {
            // Set the custom filter function
            this.dataSource.filterPredicate = (data, filter) => {
                const shippingAreaMatch = data.shipping_area.toLowerCase().includes(shippingAreaFilter);
                const deliveryTypeMatch = data.delivery_type.toLowerCase().includes(deliveryTypeFilter);
                const dateMatch = data.shippingDate.includes(selectedDateFilter); // Assuming shippingDate is in 'YYYY-MM-DD' format

                return shippingAreaMatch && deliveryTypeMatch && dateMatch;
            };

            // Combine filter strings for logging
            const combinedFilterString = `${shippingAreaFilter} ${deliveryTypeFilter} ${selectedDateFilter}`;

            // Apply the combined filter
            this.dataSource.filter = combinedFilterString;
        } else {
            // Clear filter if neither shipping area, delivery type, nor date is selected
            this.dataSource.filter = '';
        }
    }
    calculateStatistics() {

        const data = this.dataSource.data;

        // Initialize statistics object
        const statistics = {
            numberOfCustomers: 0,
            numberOfProducts: 0,
            highestSellerCustomerId: '',
            biggestSellerProductId: '',
            completedSalesOrdersCount: 0,
            startedSalesOrdersCount: 0,
        };

        // Calculate the number of customers
        const uniqueCustomerIds = new Set<string>();
        data.forEach((row) => {
            uniqueCustomerIds.add(row.customerName);
        });
        statistics.numberOfCustomers = uniqueCustomerIds.size;
        this.numberOfCustomers = statistics.numberOfCustomers
        // Calculate the number of products
        const uniqueProductNames = new Set<string>();
        data.forEach((row) => {
            row.products.forEach((product) => {
                uniqueProductNames.add(product.productName);
            });
        });
        statistics.numberOfProducts = uniqueProductNames.size;
        this.numberOfProducts = statistics.numberOfProducts
        // Calculate the customer with the highest sales
        const customerSalesMap = new Map<string, number>();
        data.forEach((row) => {
            const customerId = row.customerName;
            const totalSales = row.totalCost;

            if (!customerSalesMap.has(customerId)) {
                customerSalesMap.set(customerId, totalSales);
            } else {
                const currentTotal = customerSalesMap.get(customerId) || 0;
                customerSalesMap.set(customerId, currentTotal + totalSales);
            }
        });
        const sortedCustomerSales = [...customerSalesMap.entries()].sort(
            (a, b) => b[1] - a[1]
        );
        if (sortedCustomerSales.length > 0) {
            statistics.highestSellerCustomerId = sortedCustomerSales[0][0];
        }
        this.highestSellerCustomerId = statistics.highestSellerCustomerId
        // Calculate the product with the highest sales
        const productSalesMap = new Map<string, number>();
        data.forEach((row) => {
            row.products.forEach((product) => {
                const productName = product.productName;
                const quantity = product.quantity;

                if (!productSalesMap.has(productName)) {
                    productSalesMap.set(productName, quantity);
                } else {
                    const currentQuantity = productSalesMap.get(productName) || 0;
                    productSalesMap.set(productName, currentQuantity + quantity);
                }
            });
        });
        const sortedProductSales = [...productSalesMap.entries()].sort(
            (a, b) => b[1] - a[1]
        );
        if (sortedProductSales.length > 0) {
            statistics.biggestSellerProductId = sortedProductSales[0][0];
        }

        this.biggestSellerProductId = statistics.biggestSellerProductId

        // Calculate the completed and started sales orders
        this.completedSalesOrdersCount = statistics.completedSalesOrdersCount = data.filter(
            (row) => row.status === 'Completed'
        ).length;

        this.startedSalesOrdersCount = statistics.startedSalesOrdersCount = data.filter(
            (row) => row.status === 'Started'
        ).length;

        // Return the calculated statistics
        return statistics;



    }


    updateVendorsCredit() { }


    deleteVendorsCredit(id: any) {
        this.roleService.withRoleCheck(this.requiredRoles, async () => {

            // Prompt the user for confirmation before deleting the employee
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {

                if (result.isConfirmed) {
                    this._salesorderService.deleteSalesOrder(id)

                        .subscribe((isDeleted) => {

                            this.refreshExchanges();
                            if (!isDeleted) {
                                Swal.fire("Error!", "Cannot Delete");
                                return;
                            }

                            // Display a success message
                            Swal.fire(
                                'Deleted!',
                                'Your file has been deleted.',
                                'success'
                            )
                        });
                }
            });
        });
    }





    refreshProductList() {
        this._salesorderService.getSalesOrder()
            .subscribe((data) => {
                this.salesOrders = data['data']; 


            });
    }


    showDetails(id: any) {
        this._router.navigate(['apps/exchanges', id]);
    }



    getStatusIcon(status: string): { icon: string; color: string } {
        if (status === 'Started Sales Order') {
            return { icon: 'heroicons_outline:shopping-bag', color: 'green' };
        } else if (status === 'Shipped Sales Order') {
            return { icon: 'heroicons_outline:check-circle', color: 'blue' };
        } else if (status === 'Delivered Sales Order') {
            return { icon: 'heroicons_outline:ban', color: 'yellow' };
        } else if (status === 'Completed Sales Order') {
            return { icon: 'heroicons_outline:ban', color: 'green' };
        } else if (status === 'Delivered Sales Order') {
            return { icon: 'heroicons_outline:ban', color: 'yellow' };
        } else if (status === 'Returned Sales Order') {
            return { icon: 'heroicons_outline:ban', color: 'red' };
        } else if (status === 'Canceled Sales Order') {
            return { icon: 'heroicons_outline:ban', color: 'red' };
        } else if (status === 'Package Created') {
            return { icon: 'heroicons_outline:shopping-bag', color: 'red' };
        }

        // Return a default icon and color if the status doesn't match any of the conditions
        return { icon: 'heroicons_outline:ban', color: 'black' };
    }

    isCollapsed(row: SalesOrderData): boolean {
        return !row.isExpanded;
    }

    toggleCollapse(row: SalesOrderData): void {
        row.isExpanded = !row.isExpanded;
    }
    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }
    createSalesExchange() {
        this.roleService.withRoleCheck(this.requiredRolesCreation, async () => {

            this._router.navigate(['apps/exchanges', 'NEW']);

        });
    }
    getStatusColor(status: string): string {
        if (status === 'pending') {
            return 'yellow';
        } else if (status === 'completed') {
            return 'green';
        } else if (status === 'failed') {
            return 'red';
        }
        return '';
    }
    ngOnDestroy(): void {
        // throw new Error('Method not implemented.');
    }

    loadExchanges(): void {
        if (!this.salesOrders || !this.salesOrders.data) {
            console.error("salesOrders or salesOrders.data is undefined or null");
            return;
        }

        const salesOrdersData = this.salesOrders.data;

        this.dataSource = new MatTableDataSource<SalesOrderData>(
            salesOrdersData
                .filter(saleExchange => saleExchange.status === 'Started Exchange Order' || saleExchange.status === 'Waiting to Receive')
                .map((saleExchange) => {
                    const productsInfo = saleExchange?.sales_order?.products?.map((product) => ({
                        productName: product.product?.productName,
                        quantity: product.quantity,
                    }));

                    const exchangeProductsInfo = saleExchange?.exchange_products?.map((product) => ({
                        productName: product.product?.productName,
                        quantity: product.quantity,
                    }));

                    return {
                        customerName: saleExchange?.sales_order?.Customers?.firstName,
                        saleNumber: saleExchange?.exchange_number,
                        status: saleExchange?.status,
                        shippingDate: saleExchange?.sales_order?.sales_order_shippmentDate,
                        returningDate: saleExchange?.date,
                        products: productsInfo,
                        exchangeProducts: exchangeProductsInfo,
                        delivery_type: saleExchange?.sales_order?.delivery_type,
                        shipping_area: saleExchange?.sales_order?.shipping_area,
                        remainingNewTotal: saleExchange?.remainingAmount,
                        isDebited: saleExchange?.isDebited,
                        received: saleExchange?.received,
                        isExpanded: false,
                        totalCost: saleExchange?.totalAmount,
                        totalCoste: saleExchange?.total,
                        id: saleExchange.id,
                        productTotal: saleExchange?.sales_order?.sub_total,
                        rfr: saleExchange?.reasonForReturn,
                    };
                })
        );


        this.calculateStatistics();
        this.isLoading = false;
    }

    getTooltipText(row: any): string {
        // Check if isDebited is true
        if (row.isDebited) {
            return "Converted to Credit Note";
        } else {
            // Optional: return an empty string for non-debited rows
            return "";
        }
    }

}

/** Builds and returns a new User. */
