import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { Exchanges } from './exchanges.type';
import { server_env } from 'config';

@Injectable({
    providedIn: 'root',
})
export class ExchangesService {
    // Private
    private _salesorder: BehaviorSubject<Exchanges | null> =
        new BehaviorSubject(null);
    private _salesorderss: BehaviorSubject<Exchanges[] | null> =
        new BehaviorSubject(null);

    public url = `${server_env.URL}api/v1/salesOrderExchange/`;

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors

    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for contact
     */
    get salesOrder$(): Observable<Exchanges> {
        return this._salesorder.asObservable();
    }

    /**
     * Getter for contacts
     */
    get salesOrders$(): Observable<Exchanges[]> {
        return this._salesorderss.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get contacts
     */

    getSalesOrder(): Observable<Exchanges[]> {
        return this._httpClient.get<Exchanges[]>(`${this.url}`).pipe(
            tap((salesorder: any) => {
                this._salesorderss.next(salesorder.data);

                for (salesorder in salesorder.data) {
                }
            })
        );
    }

    /**
     * Get contact by id
     */
    getSalesOrderById(id: string): Observable<Exchanges> {
        const requestUrl = this.url + id;

        return this._httpClient.get<Exchanges[]>(`${requestUrl}`).pipe(
            take(1),
            map((salesorder: any) => {
                this._salesorder.next(salesorder.data);

                // Return the contact
                return salesorder.data;
            }),
            switchMap((salesorder) => {
                if (!salesorder) {
                    return throwError(
                        'Could not found contact with id of ' + id + '!'
                    );
                }

                return of(salesorder);
            })
        );
    }

    

    /**
     * create customer
     * @param data
     * @returns
     */

    createSalesOrders(data): Promise<any> {
        return new Promise((resolve, reject) => {
            const requestUrl = this.url;

            // console.log('datassss', data);
            this._httpClient.post<any>(`${requestUrl}`, data).subscribe(
                
                (response) => {
                    return resolve(response);
                },
                (error) => {
                    return reject(error);
                }
            );
        });
    }
  

    updateSalesOrder(id: string, contact: Exchanges): Observable<any> {
        return this._httpClient.patch(`${this.url}${id}/`, contact, { observe: 'response' })
          .pipe(
            map(response => response.body), // Return the response body directly
            catchError(error => throwError(error))
          );
      }
      
    /**
     * Delete the contact
     *
     * @param id
     */
    deleteSalesOrder(id: string): Observable<boolean> {
        return this.salesOrders$.pipe(
            take(1),
            switchMap((salesOrder) =>
                this._httpClient.delete(`${this.url}${id}/`).pipe(
                    map((isDeleted: boolean) => {
                        // Find the index of the deleted contact
                        const index = salesOrder.findIndex(
                            (item) => item.id === id
                        );

                        // Delete the contact
                        salesOrder.splice(index, 1);

                        // Update the contacts
                        this._salesorderss.next(salesOrder);

                        // Return the deleted status
                        return isDeleted;
                    })
                )
            )
        );
    }


    getSalesReturnCreditNote(salesRID: string): Observable<any> {
        const requestUrl = `${server_env.URL}api/v1/salesExchanges_c/${salesRID}/`
        return this._httpClient.get<any[]>(`${requestUrl}`).pipe(
            tap((variants: any) => {
                this._salesorder.next(variants.data);

                for (variants in variants.data) {
                }
            })
        );
    }
}
