<!-- productForm form -->

<app-loader *ngIf="isLoading"></app-loader>

<div *ngIf="!isLoading" class="w-full h-full grid-form-container">

    <div class="left-side-content">
        <form enctype="multipart/form-data" [formGroup]="productForm" appUnsavedChanges class="bg-gray-100 rounded"
            (keydown.enter)="$event.preventDefault()">

            <div *ngIf="productForm" class="wrap-all mr-4">

                <div class="flex flex-col mt-8 p-5 pb-5 bg-card rounded shadow overflow-hidden">
                    <div class="flex flex-row-reverse justify-between ">
                        <span>
                            <button mat-raised-button [routerLink]="['..']">
                                <span class="inline-flex items-center">
                                    <mat-icon svgIcon="feather:x"></mat-icon>
                                </span>
                            </button>
                        </span>
                        <span>
                            <p class="text-lg font-medium">Product Information</p>
                            <p class="text-secondary">View your Basic Product Details,like SKU its Fabric and Materials.
                            </p>
                        </span>
                    </div>
                    <mat-divider class="mt-8 mb-5"></mat-divider>


                    <div class="flex mb-2 ">
                        <mat-form-field class="flex-auto fuse-mat-emphasized-affix fuse-mat-rounded ">
                            <mat-label>Name</mat-label>
                            <div class="prefix-container mr-3"> </div>
                            <input required readonly matInput placeholder="Product Name" formControlName="productName"
                                class="elevated-input" />
                            <mat-icon matPrefix svgIcon="drive_file_rename_outline"></mat-icon>
                        </mat-form-field>








                    </div>


                    <div class="flex mb-2 ">


                        <mat-form-field style="max-width:500px"
                            class="flex-auto fuse-mat-emphasized-affix fuse-mat-rounded  ">
                            <mat-label>SKU</mat-label>
                            <input readonly oninput="this.value = this.value.toUpperCase()" required matInput
                                placeholder="SKU" formControlName="sku" class="elevated-input" />
                            <mat-icon matPrefix svgIcon="assignment"></mat-icon>

                        </mat-form-field>

                        <mat-form-field style="max-width: 200px"
                            class="flex-auto fuse-mat-emphasized-affix fuse-mat-rounded  ml-3">
                            <mat-label>Year </mat-label>

                            <input matInput readonly placeholder="year" name="year" [formControlName]="'year'"
                                class="elevated-input">


                            <mat-icon matPrefix svgIcon="date_range"></mat-icon>

                        </mat-form-field>

                    </div>
                    <div class="flex mb-2 ">


                        <mat-form-field style="max-width: 500px"
                            class="flex-auto fuse-mat-emphasized-affix fuse-mat-rounded  ml-3">
                            <mat-label>Collection </mat-label>

                            <input matInput readonly placeholder="Collection" name="collection"
                                [formControlName]="'collection'" class="elevated-input">




                            <mat-icon matPrefix svgIcon="checkroom"></mat-icon>

                        </mat-form-field>
                    </div>

                    <div class="flex mb-2">
                        <mat-form-field style="max-width: 900px;"
                            class="flex-auto fuse-mat-emphasized-affix fuse-mat-rounded">
                            <mat-label>Description</mat-label>
                            <mat-icon class="icon-size-5" matPrefix svgIcon="feather:file-text"></mat-icon>

                            <textarea readonly style="height: 100px;" matInput placeholder="Description"
                                formControlName="description" class="elevated-input"></textarea>
                        </mat-form-field>
                    </div>


                    <div class="flex mb-2">

                        <mat-form-field style="max-width:500px"
                            class="flex-auto fuse-mat-emphasized-affix fuse-mat-rounded">
                            <mat-label> Primary Fabric</mat-label>
                            <input readonly matInput placeholder="Fabric" formControlName="fabric"
                                class="elevated-input" />
                            <mat-icon matPrefix svgIcon="dry_cleaning"></mat-icon>

                        </mat-form-field>
                    </div>

                    <div class="flex mb-2">


                        <div *ngIf="showFabricOptions" class="flex-auto mt-2 ml-5" style="max-width: 200px;">
                            <mat-checkbox onclick="return false;" formControlName="secondary_fabric_c"
                                class="example-margin" (change)="secondary = $event.checked">Secondary
                                Fabric</mat-checkbox>
                        </div>


                        <div *ngIf="showFabricOptions" class="flex-auto mt-2" style="max-width: 200px;">

                            <mat-checkbox onclick="return false;" formControlName="inner_fabric_c"
                                (change)="inner = $event.checked" class="example-margin">Inner Fabric</mat-checkbox>
                        </div>




                    </div>
                    <p *ngIf="secondary" class="text-secondary mt-3 mb-3">
                        Add multiple fields by pressing enter.
                    </p>

                    <div *ngIf="secondary" class="flex mb-2">

                        <!-- Secondary Fabric -->
                        <mat-form-field class="flex-auto fuse-mat-emphasized-affix fuse-mat-rounded">
                            <mat-label>Secondary Fabric</mat-label>
                            <mat-chip-list #chipListS>
                                <mat-chip *ngFor="let fabric of secondaryFabrics" [selectable]="selectable"
                                    [removable]="removable" (removed)="remove(fabric)">
                                    {{ fabric.name }}
                                </mat-chip>
                                <input readonly matInput formControlName='secondary_fabric'
                                    placeholder="New Secondary Material..." [matChipInputFor]="chipListS"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="addOnBlur"
                                    (matChipInputTokenEnd)="addSecondaryFabric($event)">
                            </mat-chip-list>
                            <mat-icon matPrefix svgIcon="dry_cleaning"></mat-icon>
                        </mat-form-field>

                    </div>



                    <div *ngIf="inner" class="flex mb-2 ">

                        <mat-form-field class="flex-auto fuse-mat-emphasized-affix fuse-mat-rounded">
                            <mat-label>Inner Fabric</mat-label>
                            <mat-chip-list #chipListI>
                                <mat-chip *ngFor="let fabric of innerFabrics" [selectable]="selectable"
                                    [removable]="removable" (removed)="removeInnerFabric(fabric)">
                                    {{fabric.name}}
                                </mat-chip>
                                <input readonly matInput formControlName='inner_fabric'
                                    placeholder="New Inner Fabric..." [matChipInputFor]="chipListI"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="addInnerFabric($event)">


                            </mat-chip-list>
                            <mat-icon matPrefix svgIcon="dry_cleaning"></mat-icon>

                        </mat-form-field>

                    </div>


                </div>



                <div class="flex flex-col mt-8 p-8 pb-5 bg-card shadow rounded overflow-hidden">
                    <p class="text-lg font-medium  " style="font-family: Montserrat">Pricing</p>

                    <p class="text-secondary">
                        View Pricing for your product.
                    </p>
                    <mat-divider class="mt-8 mb-5"></mat-divider>
                    <div class="layout2">



                        <div class="flex mb-1">
                            <mat-form-field style="max-width: 300px"
                                class="flex-auto fuse-mat-emphasized-affix fuse-mat-rounded">
                                <mat-label>Price</mat-label>
                                <input readonly type="text" onkeypress="return event.charCode>=48 && event.charCode<=57"
                                    required matInput placeholder="Price" formControlName="basePrice" mask="separator"
                                    thousandSeparator="," class="elevated-input" />
                                <mat-icon class="icon-size-4" type="text"
                                    onkeypress="return event.charCode>=48 && event.charCode<=57" class="icon-size-4"
                                    matPrefix svgIcon="">रु</mat-icon>
                            </mat-form-field>
                        </div>




                    </div>



                    <div class="flex mb-2">
                        <mat-form-field style="max-width: 300px; margin-right: 30px;"
                            class="flex-auto fuse-mat-emphasized-affix fuse-mat-rounded">
                            <mat-label>Cost of goods</mat-label>
                            <input readonly type="text" onkeypress="return event.charCode>=48 && event.charCode<=57"
                                matInput placeholder="Price" formControlName="cost_of_goods" class="elevated-input" />
                            <mat-icon class="icon-size-4" type="text"
                                onkeypress="return event.charCode>=48 && event.charCode<=57" class="icon-size-4"
                                matPrefix svgIcon="feather:dollar-sign"></mat-icon>
                        </mat-form-field>

                        <mat-form-field style="max-width: 300px;margin-right: 30px;"
                            class="flex-auto fuse-mat-emphasized-affix fuse-mat-rounded">
                            <mat-label>Profit</mat-label>
                            <input readonly type="text" onkeypress="return event.charCode>=48 && event.charCode<=57"
                                matInput placeholder="basePrice" formControlName="profit" class="elevated-input" />
                            <mat-icon class="icon-size-4" type="text"
                                onkeypress="return event.charCode>=48 && event.charCode<=57" class="icon-size-4"
                                matPrefix svgIcon="feather:dollar-sign"></mat-icon>
                        </mat-form-field>

                        <mat-form-field style="max-width: 300px;margin-right: 30px;"
                            class="flex-auto fuse-mat-emphasized-affix fuse-mat-rounded">
                            <mat-label>Margin</mat-label>
                            <input readonly type="text" onkeypress="return event.charCode>=48 && event.charCode<=57"
                                matInput placeholder="basePrice" formControlName="margin" class="elevated-input" />
                            <mat-icon class="icon-size-4" matPrefix svgIcon="feather:percent"></mat-icon>
                        </mat-form-field>
                    </div>


                </div>


                <div class="flex flex-col mt-5 p-5 pb-5 bg-card shadow rounded overflow-hidden">

                    <p class="text-lg font-medium">
                        More About Product </p>

                    <p class="text-secondary mt-3">
                        In this section you can state the weight of the product .
                        This is very benfical for determining base pricce for shipping.

                    </p>

                    <mat-divider class="mt-5 mb-5"></mat-divider>


                    <div class="flex mb-2">
                        <mat-form-field style="max-width: 350px;"
                            class="flex-auto fuse-mat-emphasized-affix fuse-mat-rounded ">
                            <mat-label>Product Weight</mat-label>

                            <input type="text" readonly class="elevated-input" required [placeholder]="'Product Weight'"
                                matInput formControlName="product_weight">

                            <mat-hint class="mt-2 ml-2">
                                Approximate Average shirt for male weighs 150-300 g.
                            </mat-hint>

                            <mat-icon matPrefix class="icon-size-4" svgIcon="line_weight"></mat-icon>

                            <!-- Add this part for displaying weight_alis -->
                            <!-- Styled chip for weight_alis -->
                            <span class="ml-2" style="font-size: small; padding: 4px 8px; border-radius: 16px;">
                                {{weight_alis }} </span>

                        </mat-form-field>


                    </div>
                    <mat-divider class="mt-3 mb-1"></mat-divider>

                    <!-- Image preview card -->
                    <div  class="mt-4 bg-white p-4 rounded shadow-md relative">
                        <label class="block  font-medium text-gray-700 mb-2">Size Chart</label>
                        <p class="text-sm font-medium text-gray-700">Image Preview:</p>
                       
                        <img [src]="thumbnail" alt="Image Preview"
                            class="mt-2 max-h-64 object-contain cursor-pointer" (click)="toggleFullScreen($event)">
                    </div>


                </div>


                <div class="flex flex-col mt-8 p-8 pb-5 bg-card rounded shadow overflow-hidden">
                    <p class="text-lg font-medium">Product Options</p>
                    <p class="text-secondary">
                        Your product comes in different options, like size and color. View them here.
                    </p>




                    <div style="max-width: 100px;margin-top:50px;">


                    </div>
                    <mat-divider class="mt-5 mb-2"></mat-divider>

                    <mat-selection-list #shoes [multiple]="false">
                        <mat-list-option *ngFor="let shoe of typesOfShoes" [value]="shoe"
                            (mouseenter)="showActions = true" (mouseleave)="showActions = false">
                            <div class="shoe-item">
                                <div class="shoe-info">
                                    <span class="shoe-name">{{ shoe.name }}</span>
                                    <span class="shoe-values">{{ shoe.values }}</span>
                                </div>
                                <div class="shoe-actions" *ngIf="showActions">


                                </div>
                            </div>
                        </mat-list-option>
                    </mat-selection-list>




                    <mat-slide-toggle *ngIf="show_ppu_toogle" class="mb-3 mt-3" formControlName="varients_toggel">
                        <p class="text-secondary">View pricing and inventory for
                            each product variant</p>
                    </mat-slide-toggle>





                </div>


                <div class="flex flex-col mt-8 p-8 pb-5 bg-card rounded shadow overflow-hidden">
                    <p class="text-lg font-medium">Varients</p>
                    <p class="text-secondary">
                        View your Product Varients here
                    </p>


                    <mat-divider class="mt-5"></mat-divider>
                    <div class="">
                        <div class="flex flex-auto overflow-hidden">
                            <!-- Products list -->
                            <div class="flex flex-col flex-auto sm:mb-18 overflow-hidden sm:overflow-y-auto">
                                <div class="mat-elevation-z8" style="max-height: 300px; overflow-y: auto;">
                                    <table mat-table [dataSource]="dataSource" matSort class="mat-table-varients">
                                        <!-- Header Row -->
                                        <ng-container matColumnDef="Varient">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Varient Name </th>
                                            <td mat-cell *matCellDef="let row"> {{ row.varient }} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="SKU">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header> SKU </th>
                                            <td mat-cell *matCellDef="let row"> {{ row.sku }} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="Size">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Size </th>
                                            <td mat-cell *matCellDef="let row"> {{ row.size }} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="Color">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Color </th>
                                            <td mat-cell *matCellDef="let row"> {{ row.color }} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="Price">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Price-Difference
                                            </th>
                                            <td mat-cell *matCellDef="let row"> {{ row.price }} </td>
                                        </ng-container>

                                        <!-- Header Row Definition -->
                                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

                                        <!-- Data Row Definition -->
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                                    </table>
                                </div>

                            </div>
                        </div>






                    </div>
                </div>




                <div class="flex flex-col mt-8 p-8 pb-5 bg-card rounded shadow overflow-hidden">
                    <p class="text-lg font-medium">Pre-Order </p>
                    <div class="flex" style="align-items: center;">
                        <p class="text-secondary">Is your product up for Pre Order?</p>
                        <mat-slide-toggle class="ml-5" onclick="return false;" readonly
                            formControlName="pre_order_toggel"></mat-slide-toggle>
                    </div>





                    <mat-divider class="mt-8 mb-6"></mat-divider>
                    <div class="flex items-center">

                    </div>

                    <div class="flex mb-2" style="padding: 5px;">
                        <ng-container *ngIf="productForm.get('pre_order_toggel').value">
                            <mat-form-field style="max-width: 600px" class="flex-auto">
                                <mat-label>Expected Release Date</mat-label>
                                <input readonly matInput placeholder="VendorCreditDate" name="expected_release_date"
                                    [formControlName]="'expected_release_date'" class="elevated-input small-input-text">

                                <mat-icon class="icon-size-4" matPrefix [svgIcon]="'heroicons_solid:calendar'">
                                </mat-icon>
                            </mat-form-field>
                        </ng-container>


                    </div>


                    <ng-container *ngIf="productForm.get('pre_order_toggel').value">
                        <p class="text-m font-medium mt-4">PRE-ORDER LIMIT
                        </p>

                        <p class="text-secondary mb-5">Limit the total number of items available for pre-order. If
                            this product has variants, the limit will apply to each one individually..</p>


                        <table mat-table [dataSource]="dataSource" matSort class="mat-table">
                            <!-- Waist Column -->
                            <ng-container matColumnDef="Varient">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Varient Name
                                </th>
                                <td mat-cell *matCellDef="let row"> {{ row.varient }} </td>
                            </ng-container>

                            <!-- Size Column -->
                            <ng-container matColumnDef="SKU">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> SKU
                                </th>
                                <td mat-cell *matCellDef="let row"> {{ row.sku }} </td>
                            </ng-container>


                            <!-- Size Column -->
                            <ng-container matColumnDef="Size">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Size
                                </th>
                                <td mat-cell *matCellDef="let row"> {{ row.size }} </td>
                            </ng-container>

                            <!-- Hips Column -->
                            <ng-container matColumnDef="Color">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Color
                                </th>
                                <td mat-cell *matCellDef="let row"> {{ row.color }} </td>
                            </ng-container>

                            <!-- Bust Column -->


                            <ng-container matColumnDef="Stock">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Assumed Inventory </th>
                                <td mat-cell *matCellDef="let row"> {{ row.stock }} </td>
                            </ng-container>

                            <!-- Actions Column -->


                            <tr mat-header-row *matHeaderRowDef="displayedColumnss"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumnss"></tr>
                        </table>

                    </ng-container>





                </div>









            </div>

        </form>
    </div>

    <!-- Right-side Cards -->

    <div class="right-side-cards">


        <fuse-card class="flex flex-col mt-8 p-8 w-80 pb-5 filter-list">

            <div class="flex items-center justify-between">
                <p class="text-lg font-medium"
                    style="display: flex; align-items: center; justify-content: space-between; flex: 1;">
                    <span>Category</span>
                </p>
                <button>
                    <mat-icon class="icon-size-4" svgIcon="feather:list"></mat-icon>
                </button>
            </div>



            <mat-divider class="mt-3 mb-5"></mat-divider>

            <!-- Search bar -->
            <input type="text" placeholder="Search category" [(ngModel)]="searchText"
                class="p-2 border rounded-full mb-2">
            <mat-divider class="mt-3 mb-2"></mat-divider>

            <!-- Category list with pagination and scroll -->
            <div class="category-list" style="max-height: 200px; overflow-y: auto;">



                <div *ngFor="let category of filteredCategories" class="category-checkbox">
                    <label style="font-size:small ;">
                        <mat-checkbox onclick="return false;" [(ngModel)]="category.selected" [value]="category.name"
                            (change)="onCategoryChange(category)" class="checkbox"></mat-checkbox>
                        {{ category.name }}
                    </label>
                </div>


            </div>

            <!-- Pagination controls -->
            <div class="pagination-controls flex items-center justify-between mt-3">
                <button mat-icon-button (click)="prevPage()" [disabled]="currentPage === 1">
                    <mat-icon>chevron_left</mat-icon>
                </button>
                <span>{{ currentPage }} / {{ totalPages }}</span>
                <button mat-icon-button (click)="nextPage()" [disabled]="currentPage === totalPages">
                    <mat-icon>chevron_right</mat-icon>
                </button>
            </div>

        </fuse-card>





        <!-- Gender -->

        <div class="flex flex-col mt-3 p-8 pb-5 bg-card rounded shadow overflow-hidden">
            <p class="text-lg font-medium" style="display: flex; align-items: center;">
                Gender <mat-icon style="margin-left: auto;" svgIcon="supervised_user_circle"></mat-icon>
            </p>



            <mat-divider class="mt-3 mb-5"></mat-divider>

            <div class="category-nav-bar">
                <div *ngFor="let gender of genders" class="category-radio">
                    <input onclick="return false;" type="radio" [id]="gender.name" [name]="'gender'"
                        [(ngModel)]="selected_gender" [value]="gender.name" (change)="onGenderChange()" />

                    <label [for]="gender.name" style="margin-left: 10px; font-size: small;"> {{ gender.name}}</label>
                </div>
            </div>
        </div>


        <!-- Gender ENd -->

    </div>