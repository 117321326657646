<!-- THis is up  -->


<div *ngIf="vendor" class="mt-15 ml-15 md:ml-50 lg:ml-90">


    <div class="absolute inset-0 min-w-0 text-center overflow-auto print:overflow-visible" cdkScrollable>



        <div class="ml-10 mt-5 mr-10 ">
            <mat-accordion>

                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <div class="justify-self-end font-medium tracking-tight text-secondary">Refund History -
                                <span style="color:rgb(53, 139, 87)">
                                    {{length}}
                                </span>
                            </div>

                        </mat-panel-title>
                        <mat-panel-description>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <table mat-table [dataSource]="dataSource" matSort matPaginator class="mat-table ">


                        <!-- Status Column -->
                        <ng-container matColumnDef="amount">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                Refunded Amount
                            </th>
                            <td mat-cell *matCellDef="let row">
                                <div class="avatar">

                                    NPR {{ row.amount }}
                                </div>
                            </td>
                        </ng-container>

                        <!-- OrderShipped Date Column -->
                        <ng-container matColumnDef="date">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                Refund Date
                            </th>
                            <td mat-cell *matCellDef="let row">
                                NPR {{ row.date }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="remaning_amount">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                Total </th>
                            <td mat-cell *matCellDef="let row">
                                NPR {{ row.remaining_amount }}
                            </td>
                        </ng-container>



                        <!-- Actions Column -->


                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                    </table>
                </mat-expansion-panel>
            </mat-accordion>



        </div>




        <div class="w-full inline-block p-6 sm:p-10 text-left print:p-0">

            <!-- Invoice -->
            <div
                class="w-full  p-16 rounded-2xl shadow bg-card print:w-auto print:p-0 print:rounded-none print:shadow-none print:bg-transparent">

                <div class="flex items-center">
                    <div class="grid grid-rows-2 place-items-center ">

                        <div class="grid-cols-2  place-items-center">


                            <button mat-button type="button" matTooltip="Close" class="hide-on-print"
                                (click)="goBack()">
                                <span class="inline-flex items-center">
                                    <mat-icon svgIcon="feather:x"></mat-icon>
                                </span>
                            </button>

                            <button mat-button type="button" class="hide-on-print" (click)="printPage()">
                                <span class="inline-flex items-center">
                                    <mat-icon svgIcon="feather:file"></mat-icon>
                                </span>
                            </button>



                        </div>
                    </div>
                </div>
                <!-- Main -->



                <div class="flex items-start">

                    <div class="grid grid-rows-2 place-items-start ">
                        <div class="grid auto-cols-max grid-flow-col gap-x-5">

                            <div class="pl-4text-md">
                                <div class="font-medium"> {{ vendor.Customers.firstName }} {{
                                    vendor.Customers.lastName
                                    }}
                                </div>
                                <div>{{vendor.Customers.gender}}</div>
                                <div>{{vendor.Customers.socialMedia }}</div>
                                <ng-container *ngFor="let number of vendor.Customers.phoneNumbers"
                                    class="col-span-12 flex items-center">

                                    <div> {{number.label}} +977-{{number.number}}</div>
                                </ng-container>
                                <div *ngFor="let email of vendor.Customers.emails">
                                    <div>{{ email.email}}</div>
                                </div>
                                <div *ngFor="let email of vendor.Customers.billingAddress">
                                    <div>{{email.type}} {{email.city}} {{ email.address}}</div>
                                </div>

                                <div *ngFor="let email of vendor.Customers.shippingArea">
                                    <div>{{email.type}} {{email.city}} {{ email.address}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="text-xl mt-10    tracking-light">About Credit Note</div>

                        <div class=" text-sm text-secondary">
                            {{vendor.voucher_description}}
                        </div>



                    </div>




                    <div class="grid grid-cols-2 gap-x-4 gap-y-1 ml-auto">

                        <div class="justify-self-end text-4xl tracking-tight text-secondary">Credit Note</div>
                        <div class="text-4xl">{{vendor.voucher_order_num}}</div>
                        <div class="justify-self-end font-medium tracking-tight text-secondary">Created Date</div>
                        <div class="font-medium">{{vendor.voucher_order_date}}</div>

                        <div class="justify-self-end font-medium tracking-tight text-secondary">Total </div>
                        <div class="font-medium">रू {{vendor.total}}</div>


                        <div class="justify-self-end font-medium tracking-tight text-secondary">Status </div>
                        <div class="font-medium">{{vendor.status}}</div>
                        <div class="justify-self-end font-medium tracking-tight text-secondary">Voucher Type </div>
                        <div class="font-medium">{{vendor.voucher_type}}</div>
                        <div class="justify-self-end font-medium tracking-tight text-secondary">Voucher Relation </div>
                        <!-- Conditionally display voucher relation based on pattern presence -->
                        <div class="font-medium" *ngIf="hasVoucherPattern()">
                            {{ getVoucherNumberFromDescription() }}
                        </div>

                        <div class="font-medium" *ngIf="vendor.salesExchange">

                            <a [routerLink]="determineVoucherType()">
                                {{vendor.salesExchange.exchange_number}}
                            </a>
                            -- ({{vendor.salesExchange.id}})
                        </div>
                        <div class="font-medium" *ngIf="vendor.salesReturn">
                            <a [routerLink]="determineVoucherType()">
                                {{vendor.salesReturn.salesReturnNumber}} </a>

                            --({{vendor.salesReturn.id}})
                        </div>


                    </div>

                </div>







                <div class="mt-16">
                    <div class="flex items-start mt-4">
                        <img class="flex-0 w-20 mt-2" src="assets/images/logo/logo.png">
                        <div class="ml-6  mt-8 text-sm text-secondary">
                            <i>
                                {{vendor.voucher_order_num}} - @{{ vendor.created_by.username }} --
                                {{vendor.voucher_order_date}}
                            </i>
                        </div>
                    </div>
                </div>



            </div>

        </div>

    </div>
</div>