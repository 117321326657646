<mat-dialog-content class="dialog-content">

  <form [formGroup]="customer_popup" (ngSubmit)="submitForm(customer_popup.value)"
    (keydown.enter)="$event.preventDefault()">

    <div>


      <div class="flex flex-col mt-8 p-8 pb-5  rounded shadow overflow-hidden">
        <p class="text-lg font-medium">Create a customer</p>
        <p class="text-secondary">
          Set your primary customer information here.<br>

           To add more information  vist the customer page.
        </p>
        <mat-divider class="mt-8 mb-10"></mat-divider>

        <div class="flex w-100">
          <mat-form-field class="flex-auto mr-3">
            <mat-label>First name</mat-label>
            <input [ngClass]="formFieldHelpers" required matInput formControlName="firstName" placeholder="First Name"
              name="firstName">
            <mat-icon class="icon-size-4" matPrefix [svgIcon]="'heroicons_solid:user'"></mat-icon>
            <mat-error class="m-1 ml-5 mt-2"
              *ngIf="customer_popup?.get('firstName')?.invalid && customer_popup?.get('firstName')?.touched">
              <ng-container *ngIf="customer_popup?.get('firstName')?.hasError('required')">
                Name is required.
              </ng-container>
              <ng-container *ngIf="customer_popup?.get('firstName')?.hasError('minlength')">
                Name length invalid.<br>
              </ng-container>
              <ng-container *ngIf="customer_popup?.get('firstName')?.hasError('pattern')">
                Name must start with a capital letter and end with a lowercase letter.<br>
              </ng-container>
            </mat-error>
          </mat-form-field>

          <mat-form-field class="flex-auto">
            <mat-label>Last name</mat-label>
            <input [ngClass]="formFieldHelpers" required formControlName="lastName" matInput placeholder="Last Name"
              name="lastName">
            <mat-icon class="icon-size-4" matPrefix [svgIcon]="'heroicons_solid:user'"></mat-icon>
            <mat-error class="m-1 ml-5 mt-2"
              *ngIf="customer_popup?.get('lastName')?.invalid && customer_popup?.get('firstName')?.touched">
              <ng-container *ngIf="customer_popup?.get('lastName')?.hasError('required')">
                Last name is required.
              </ng-container>
              <ng-container *ngIf="customer_popup?.get('lastName')?.hasError('minlength')">
                Last Name must be at least 3 characters long.<br>
              </ng-container>
              <ng-container *ngIf="customer_popup?.get('lastName')?.hasError('pattern')">
                Last Name must start with a capital letter and end with a lowercase letter.<br>
              </ng-container>
            </mat-error>
          </mat-form-field>
        </div>


        <div class="flex flex-col gt-xs:flex-row w-100">
          <mat-form-field class="flex-auto gt-xs:pl-3">
            <mat-label>Gender</mat-label>
            <mat-select formControlName="gender" placeholder="Gender" name="gender">
              <mat-option value="Male">Male</mat-option>
              <mat-option value="Female">Female</mat-option>
              <mat-option value="Others">Others</mat-option>
            </mat-select>
            <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:translate'"></mat-icon>
          </mat-form-field>
        </div>

        <div class="mt-2">

          <ng-container
            *ngFor="let phoneNumbers of customer_popup.get('phoneNumbers')['controls']; let i = index; let first = first; let last = last; trackBy: trackByFn">
            <div class="flex w-100 ">
              <mat-form-field [ngClass]="formFieldHelpers" class="fuse-mat-no-subscript flex-auto mb-2">
                <mat-label *ngIf="first">Phone number</mat-label>
                <mat-icon matPrefix class="hidden sm:flex icon-size-4" [svgIcon]="'heroicons_solid:phone'"></mat-icon>
                <input type="text" onkeypress="return event.charCode>=48 && event.charCode<=57" [disableArrowUpDownWheel] required matInput name="phoneNumbers.number"
                  [formControl]="phoneNumbers.get('number')" [placeholder]="'Phone Number'" [spellcheck]="false">


              </mat-form-field>

              <!-- Remove email -->
              <ng-container *ngIf="!(first && last)">
                <div class="flex items-center w-10 pl-2" [ngClass]="{'mt-6': first}">
                  <button class="w-8 h-8 min-h-8" mat-icon-button (click)="removePhoneField(i)" matTooltip="Remove">
                    <mat-icon class="icon-size-4" [svgIcon]="'heroicons_solid:trash'"></mat-icon>
                  </button>
                </div>
              </ng-container>

            </div>
          </ng-container>


          <div class="group inline-flex items-center mt-2 -ml-4 py-2 px-4 rounded cursor-pointer"
            (click)="addPhoneNumberField()">
            <mat-icon class="icon-size-4" [svgIcon]="'heroicons_solid:plus-circle'"></mat-icon>
            <span class="ml-2 font-medium text-secondary group-hover:underline">Add an phonenumber</span>
          </div>

          <div class="flex items-center justify-end mt-10">
            <button class="px-6 ml-3" mat-flat-button type="submit" color="primary">
              Save
            </button>
            <button (click)="cancel()" class="px-6 ml-3" mat-flat-button type="button" color="error">
              Cancel
            </button>
          </div>
        </div>

      </div>


    </div>
  </form>
</mat-dialog-content>