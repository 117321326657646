import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SalesOrderService } from './sales-order.service';
import { SalesOrder } from './sales-order.type';

@Injectable({
  providedIn: 'root'
})
export class SalesOrderResolver implements Resolve<SalesOrder[]> {

  constructor(private salesOrderService: SalesOrderService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<SalesOrder[]> {
    return this.salesOrderService.getSalesOrders().pipe(
      catchError((error) => {
        console.error('Error fetching sales orders', error);
        return of([]);
      })
    );
  }
}
