import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductCategoriesComponent } from './product-categories/product-categories.component';
import { ListproductCategoriesComponent } from './listproduct-categories/listproduct-categories.component';
import { CreateproductCategoriesComponent } from './createproduct-categories/createproduct-categories.component';
import { DetailproductCategoriesComponent } from './detailproduct-categories/detailproduct-categories.component';
import { MaterialModule } from 'app/modules/material/material.module';
import { ProductCategoriesRoutes } from './product-categories.routing';
import { RouterModule } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { NgxColorsModule } from 'ngx-colors';

@NgModule({
    declarations: [
        ProductCategoriesComponent,
        ListproductCategoriesComponent,
        CreateproductCategoriesComponent,
        DetailproductCategoriesComponent,
    ],
    imports: [
        RouterModule.forChild(ProductCategoriesRoutes),
        CommonModule,
        MaterialModule,
        MatDialogModule,
        MatAutocompleteModule,
        NgxColorsModule,
    ],
})
export class ProductCategoriesModule {}
