import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrPackagesReturnComponent } from './pr-packages-return/pr-packages-return.component';
import { ListprPackagesReturnComponent } from './listpr-packages-return/listpr-packages-return.component';
import { CreateprPackagesReturnComponent } from './createpr-packages-return/createpr-packages-return.component';
import { DetailprPackagesReturnComponent } from './detailpr-packages-return/detailpr-packages-return.component';



@NgModule({
  declarations: [
    PrPackagesReturnComponent,
    ListprPackagesReturnComponent,
    CreateprPackagesReturnComponent,
    DetailprPackagesReturnComponent
  ],
  imports: [
    CommonModule
  ]
})
export class PrPackagesReturnModule { }
