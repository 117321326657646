<!-- vendorsCredit form -->
<mat-dialog-content class="dialog-content">

    <form [formGroup]="vendorsCredit" class="" (keydown.enter)="$event.preventDefault()">
        <!-- Avatar -->
        <!-- Avatar code goes here -->

        <!-- Personal Information Form -->
        <div *ngIf="vendorsCredit" class="p-8">
            <div class="flex justify-between items-center">
                <!-- Total Amount Display -->
                <div class="flex items-center">

                </div>
                <!-- Close Button -->
                <button mat-raised-button (click)="_matDialog.close()" mat-icon-button>
                    <mat-icon svgIcon="feather:x"></mat-icon>
                </button>



            </div>
            <div class="flex flex-col mt-3">
                <p class="text-lg font-medium">Expense Approval</p>
                <p class="text-secondary">
                    This form is used to validate and approve the expense. Please ensure all details, 
                    including the approval date and payment method, are filled in accurately. 
                    Once approved, the payment amount will be deducted from the selected account. 
                    Double-check the information to confirm that the expense is justified and all fields 
                    are correctly completed.
                </p>
            

                <div class="flex justify-end ">

                    <p style="color:rgb(255, 104, 99) ;" class="text-lg mt-2 
                font-medium ">
                        <u>
                            <i>
                                Expense Total: {{ credit_noteTotal }}
                            </i>
                        </u>
                    </p>
                </div>
                <mat-divider class="mt-3 mb-3"></mat-divider>

                <!-- Refund Date -->
                <div class="flex mb-2">
                    <mat-form-field style="max-width: 600px"
                        class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix mr-3">
                        <mat-label>Approved Date</mat-label>
                        <input matInput [matDatepicker]="picker2" placeholder="Refund Date" name="approved_expense_date"
                            [formControlName]="'approved_expense_date'" class="elevated-input" mask="00/00/0000" required
                            (dateChange)="formatDate($event.value, 'yyyy-MM-dd', 'approved_expense_date')" />
                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                        <mat-icon class="icon-size-5" matPrefix svgIcon="heroicons_solid:calendar"></mat-icon>
                    </mat-form-field>

                    <!-- Payment Type -->
                    <mat-form-field style="max-width: 600px"
                        class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix">
                        <mat-label class="ml-3">Payment Type</mat-label>
                        <mat-select [ngClass]="formFieldHelpers" placeholder="Payment Method" name="paymentType"
                            [formControlName]="'approved_payment_method'" class="elevated-input">
                            <mat-option *ngFor="let option of paymentOptions" [value]="option">{{ option }}</mat-option>
                        </mat-select>
                        <mat-icon class="icon-size-4" matPrefix svgIcon="payments"></mat-icon>

                        <mat-error class="m-1 ml-5 mt-2"
                            *ngIf="vendorsCredit?.get('approved_payment_method')?.invalid && vendorsCredit?.get('approved_payment_method')?.touched">
                            <ng-container *ngIf="vendorsCredit?.get('approved_payment_method')?.hasError('required')">
                                Payment Type is required.
                            </ng-container>
                        </mat-error>
                    </mat-form-field>
                </div>

                <!-- Description -->
              
          

                <!-- Action Buttons -->
                <div class="flex items-center justify-start mt-5">
                        <button style="margin: 5px" (click)="saveRefund()"
                            class="px-6 py-2 rounded text-white bg-blue-500 hover:bg-green-600 ml-5" mat-flat-button
                            type="submit" color="'accent'">Save</button>

                    <button (click)="_matDialog.close();"
                        class="px-6 py-2 rounded text-white bg-red-500 hover:bg-yellow-600 ml-5" mat-flat-button
                        color="'accent'">Cancel</button>
                </div>
            </div>
        </div>
    </form>
</mat-dialog-content>