import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { VendorsExpenseComponent } from './vendors-expense/vendors-expense.component';
import { ListvendorsExpenseComponent } from './listvendors-expense/listvendors-expense.component';
import { DetailvendorsExpenseComponent } from './detailvendors-expense/detailvendors-expense.component';
import { CreatevendorsExpenseComponent } from './createvendors-expense/createvendors-expense.component';
import { MaterialModule } from 'app/modules/material/material.module';
import { RouterModule } from '@angular/router';
import { VendorsExpenseRouting } from './vendors-expense.routing';
import { MatDialogModule } from '@angular/material/dialog';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MAT_DATE_FORMATS } from '@angular/material/core';

@NgModule({
    declarations: [
        VendorsExpenseComponent,
        ListvendorsExpenseComponent,
        DetailvendorsExpenseComponent,
        CreatevendorsExpenseComponent,
    ],
    imports: [
        RouterModule.forChild(VendorsExpenseRouting),
        MaterialModule,
        CommonModule,
        MatDialogModule,
        MatAutocompleteModule,
    ],providers   : [
        {
            provide : MAT_DATE_FORMATS,
            useValue: {
                parse  : {
                    dateInput: "YYYY-MM-DD"
                },
                display: {
                    dateInput         : 'YYYY-MM-DD',
                    monthYearLabel    : 'MMM YYYY',
                    dateA11yLabel     : 'LL',
                    monthYearA11yLabel: 'MMMM YYYY'
                    
                }
            }
        },
        DatePipe
    ]
})
export class VendorsExpenseModule {}
