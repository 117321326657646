import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { productsService } from 'app/products/products.service';
import { PromoCodeService } from '../promo-code.service';
import { productVaraitons } from 'app/productVariations/productVariations.service';
import { Observable, forkJoin, of } from 'rxjs';
import { products } from 'app/products/products.type';
import { map, switchMap, take } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-create-promo-code',
  templateUrl: './create-promo-code.component.html',
  styleUrls: ['./create-promo-code.component.scss']
})
export class CreatePromoCodeComponent implements OnInit {
  promoCodeForm: FormGroup;
  filteredProductOptions$: Observable<productVaraitons[]>;
  mappedVariations: any;
  sizes: any[] = []; // Initialize with empty array
  colors: any[] = []; // Initialize with empty array
  active: boolean[] = [];
  animationFlags: boolean[] = [];
  matchedVariationIds: Set<string> = new Set();
  current_stock: any[] = [];
  product_details: any[] = [];
  match_stock: boolean[] = [];
  productVariationsArray: { [productId: string]: any[] } = {};
  formFieldHelpers: string[] = [''];
  todayDate: string;
  isActives: boolean = false;
  productActive: any[] = [];
  selectVariant: any[] = [];
  configForm: FormGroup;


  constructor(
    private _formBuilder: FormBuilder,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _productServices: productsService,
    private _promoCodeSerive: PromoCodeService,
    private cd: ChangeDetectorRef,
    private datePipe: DatePipe,
    private _fuseConfirmationService: FuseConfirmationService,


  ) {

    const products$: Observable<products[]> = this._productServices
      .getproducts()
      .pipe(
        take(1),
        map((response) => response)
      );
    products$.subscribe((products) => {
    });
  }

  ngOnInit(): void {

    this.configForm = this._formBuilder.group({
      title: 'Info',
      message: '<span class="font-medium">Are you sure you want to proceed?</span> Please be aware that once you remove the product, all the data will be reverted. Are you sure you want to finalize it? Proceed carefully.',
      icon: this._formBuilder.group({
        show: true,
        name: 'heroicons_outline:exclamation',
        color: 'info'
      }),
      actions: this._formBuilder.group({
        confirm: this._formBuilder.group({
          show: true,
          label: 'Proceed',
          color: 'accent'
        }),
        cancel: this._formBuilder.group({
          show: true,
          label: 'Cancel',
          color: 'warn'

        })
      }),
      dismissible: true
    });

    this.promoCodeForm = this._formBuilder.group({
      name: [''],
      description: [''],
      products: this._formBuilder.array([]),
      discount: [''],
      discount_alias: [''],
      disable_date: [''],
      isActive: [''],



    });

    const first_date = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    const current_date = new Date(first_date)

    current_date.setDate(current_date.getDate() + 1)

    this.todayDate = this.datePipe.transform(current_date, 'yyyy-MM-dd');

    (this.promoCodeForm.get('products') as FormArray).clear();
    const initialProductCount = 1;
    this.active = Array(initialProductCount).fill(false);

    const productArray = this.promoCodeForm.get('products') as FormArray;
    for (let i = 0; i < productArray.length; i++) {
      this.animationFlags[i] = false;
      this.productActive[i] = false;
      this.active[i] = false;
      this.selectVariant[i] = false;
    };
    this.active = Array(initialProductCount).fill(false);
    for (let i = 0; i < initialProductCount; i++) {
      const productGroup = this._formBuilder.group({
        id: [''],
        product: ['', [Validators.required]],
        size: [''],
        color: [''],
        isVarient: [''],



      });


      (this.promoCodeForm.get('products') as FormArray).push(
        productGroup
      );
    }
  }


  toggleVariant(index: number): void {
    console.log('Checkbox state changed for index:', index);

    console.log(this.selectVariant[index], "sds")
    this.selectVariant[index] = !this.selectVariant[index];

    // Reset size and color values if the variant is deselected
    if (!this.selectVariant[index]) {
      const productArray = this.promoCodeForm.get('products') as FormArray;
      if (productArray && productArray.at(index)) {
        const productControl = productArray.at(index) as FormGroup;
        productControl.patchValue({
          size: null,  // Reset size
          color: null  // Reset color
        });
      }
    }
  }



  sumbitPromoCode() {

    let Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener(
          'mouseenter',
          Swal.stopTimer
        );
        toast.addEventListener(
          'mouseleave',
          Swal.resumeTimer
        );
      },
    });

    if (this.promoCodeForm.valid) {
      // Show the loading indicator
      const promoCodeData = { ...this.promoCodeForm.value };
    


      const products = this.promoCodeForm.get('products') as FormArray;
      const updatedProducts = products.value.map((item: any) => {

        return {
          product:item.id, 
          base_product:item.product.id,
          size:item.size,
          color:item.color,
      
        };
      });

      promoCodeData.products = updatedProducts;
      Swal.fire({
        title: "Loading",
        html: `
                <div style="display: flex; flex-direction: column; align-items: center;">
                    <img src="assets/dragon.gif" style="width: 60px; height: 80px;" alt="loading"/>
                    <p style="margin: 10px 0 0 0; font-size: 14px; color: #666;">Creating, please wait...</p>
                </div>
            `,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      // Update the contact on the server
      this._promoCodeSerive
        .createPromoCode(promoCodeData)
        .then((response) => {
          if (response.success) {


            Toast.fire({
              icon: 'success',
              title: `${promoCodeData.name} Promo Code created!!`,
            });

            this.promoCodeForm.reset();
            this._router.navigate(['../'], {
              relativeTo: this._activatedRoute,
            });
          } else {
            Swal.close();

            const errorMessages = response.errors;


            Toast.fire({
              icon: 'error',
              title: 'Error creating Promo Code ',
              text: JSON.stringify(errorMessages),
            });
          }
        })
        .catch((error) => {
          // Hide the loading indicator
          const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener(
                'mouseenter',
                Swal.stopTimer
              );
              toast.addEventListener(
                'mouseleave',
                Swal.resumeTimer
              );
            },
          });

          Toast.fire({
            icon: 'error',
            title: `Error creating Promo Code : ${error.message}`,
          });
        });
    } else {
      Swal.close();

      const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer);
          toast.addEventListener('mouseleave', Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: 'error',
        title: 'Invalid form data',
        text: 'Please fill in all the required fields correctly.',
      });
    }
  }



  /**
 * Track by function for ngFor loops
 *
 * @param index
 * @param item
 */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }




  addProductField(): void {
    const productFormGroup = this._formBuilder.group({
      id: [''],
      product: ['', [Validators.required]],
      size: [''],
      color: [''],
      isVarient: [''],


    });

    const productArray = this.promoCodeForm.get('products') as FormArray;
    productArray.push(productFormGroup);

    const index = productArray.length - 1;

  }
  


  filterOptionsProduct(value: string): void {
    if (typeof value !== 'string') {
      this.filteredProductOptions$ = of([]);
      return;
    }

    this._productServices.getproducts().pipe(
      take(1),
      switchMap((products) => {
        if (!Array.isArray(products['data'])) {
          return of([]);
        }

        const productObservables = products['data'].map((product) => {
          return this._productServices.getProductVariations(product.id).pipe(
            map((variations) => ({
              product,
              variations: variations.data,
            }))
          );
        });

        // Combine all product observables into a single observable
        return forkJoin(productObservables);
      }),
      map((productsWithVariations) => {
        return productsWithVariations.map(({ product, variations }) => {
          const productId = product.id;

          // Check if any variation has stock
          const hasStock = variations.some((variation) => variation.stock > 0);


          const isOutOfStock = !hasStock;
          const productStyle = isOutOfStock ? { color: 'red' } : {};
          const disabled = isOutOfStock;

          return {
            product: productId,
            id: productId,
            productName: product.productName,
            sku: product.sku,
            isOutOfStock: isOutOfStock,
            style: productStyle,
            description: product.description,
            disabled: disabled,
          };
        });
      })
    ).subscribe((filteredProducts) => {
      this.filteredProductOptions$ = of(filteredProducts);
    });
  }




  filtersOptionsProduct(productId: string, index: number): void {
    this._productServices.getProductVariations(productId).subscribe((variations) => {
      this.mappedVariations = variations.data
        .map((variation) => ({
          id: variation.id,
          size: variation.size,
          color: variation.color,
          price: variation.basePrice,
          base_product: productId,
          stock: variation.stock,
          preOrder: variation.preOrder,
          active: variation.active,
          description: variation.base_product.description,
          category: variation.base_product.category,
          gender: variation.base_product.gender,
        }));

      const sizeToColorMap = {};
      const allColorsDisabledMap = {};

      this.mappedVariations.forEach((variation) => {
        const { size, color, id, active, preOrder } = variation;

        if (!sizeToColorMap[size]) {
          sizeToColorMap[size] = [];
        }

        const isNotActive = !active;
        const isPreOrder = preOrder;

        const isSelected = this.matchedVariationIds.has(id);
        const isOutOfStock = isNotActive || isSelected || (isPreOrder === true);
        const style = isOutOfStock ? { color: 'red' } : {};
        const disabled = isOutOfStock;

        sizeToColorMap[size].push({
          id,
          color,
          style,
          disabled,
        });

        allColorsDisabledMap[color] = allColorsDisabledMap[color] || {};
        allColorsDisabledMap[color][size] = disabled;
      });

      const sizes = Object.keys(sizeToColorMap).map((size) => {
        const colors = sizeToColorMap[size] || [];

        // Check if all colors for this size are disabled
        const disabled = colors.every((color) => allColorsDisabledMap[color.color]?.[size]);

        // console.log('Disabled:', disabled);
        return {
          id: size,
          name: size,
          disabled,
        };
      });

      const firstSize = sizes[0]?.id;
      const colors =
        sizeToColorMap[firstSize]?.map((color) => ({
          id: color.color,
          name: color.color,
          style: color.style,
          disabled: allColorsDisabledMap[color.color]?.[firstSize],
        })) || [];

      this.updateSizesAndColors(index, sizes, colors);
    });
  }

  updateSizesAndColors(index: number, sizes: any[], colors: any[]): void {
    this.sizes[index] = sizes;
    this.colors[index] = colors;
  }





  onSelectionChange(index: number): void {
    const productArray = this.promoCodeForm.get('products') as FormArray;
    const existingFormGroup = productArray.at(index) as FormGroup;

    if (existingFormGroup) {
      const selectedProductId = existingFormGroup.get('product').value.id;
      const selectedSizeId = existingFormGroup.get('size').value;
      this.colors[index]?.forEach((color) => {
        color.disabled = false;
      });

      this.mappedVariations.forEach((variation) => {
        const { size, color, id, active, stock, preOrder } = variation;

        if (size === selectedSizeId) {
          const colorToDisable = this.colors[index]?.find(c => c.name === color);

          if (colorToDisable) {
            colorToDisable.disabled = !active || stock == 0 || (preOrder === true) || this.matchedVariationIds.has(id);
          }
        }
      });

      const selectedColorId = existingFormGroup.get('color').value;

      const selectedSizeColorCombo = `${selectedSizeId}${selectedColorId}`;
      const matchingOriginalVariation = this.mappedVariations?.find(variation => {
        const variationSizeColorCombo = `${variation.size}${variation.color}`;
        return variationSizeColorCombo === selectedSizeColorCombo;
      });

      if (matchingOriginalVariation) {
        if (matchingOriginalVariation.stock != 0) {
          if (!this.matchedVariationIds.has(matchingOriginalVariation.id)) {
            existingFormGroup.get('size').patchValue(matchingOriginalVariation.size);
            existingFormGroup.get('color').patchValue(matchingOriginalVariation.color);
            existingFormGroup.get('id').patchValue(matchingOriginalVariation.id);
            this.active[index] = true;

            this.animationFlags[index] = true;

            this.matchedVariationIds.add(matchingOriginalVariation.id);

            this.disableColorsForSelectedSize(index, selectedSizeId);
            this.addProductField();


            setTimeout(() => {
              this.animationFlags[index] = false;
            }, 1000);

            // console.log("Matching variation found for the selected size and color:", matchingOriginalVariation);
          } else {
          }
        } else {
        }
      } else {
        // console.log("No matching original variation found for the selected size and color.");
      }
    }
  }

  disableColorsForSelectedSize(index: number, selectedSizeId: string): void {
    this.colors[index].forEach((color) => {
      color.disabled = color.id !== selectedSizeId;
    });

    this.updateSizesAndColors(index, this.sizes[index], this.colors[index]);
  }


  // Add this method to handle size and color updates

  displayProductOption(product: productVaraitons): string {
    if (product) {
      const productName = product.productName || '';
      const productPrice = product.sku
        ? ' - ' + product.sku.toString()
        : '' + product.productName.toString();

      return productName + productPrice;
    }
    return '';
  }


  removeProductField(index: number): void {
    const productArray = this.promoCodeForm.get('products') as FormArray;
    const existingFormGroup = productArray.at(index) as FormGroup;

    const removedId = existingFormGroup.get('id').value;
    this.matchedVariationIds.delete(removedId);

    this.active.splice(index, 1);
    this.animationFlags.splice(index, 1);
    this.match_stock.splice(index, 1);
    this.current_stock.splice(index, 1);
    this.product_details.splice(index, 1);

    this.sizes.splice(index, 1);
    this.colors.splice(index, 1);

    productArray.removeAt(index);

    this.cd.markForCheck();
  }






  selectOptionProduct(option: products, index: number): void {

    if (option && !option.isOutOfStock) {
      const productFormGroup = this._formBuilder.group({
        id: [option.id],
        product: [option, [Validators.required]],
        quantity: [1],
        rate: [option.basePrice, ''],
        amount: [''],
        size: [],
        color: [],
      });


      this.productActive[index] = true;
      this.filtersOptionsProduct(option.id, index)

      const productArray = this.promoCodeForm.get('products') as FormArray;
      const existingFormGroup = productArray.at(index) as FormGroup;
      existingFormGroup.get('id').patchValue(option.id);




    }
  }

  hasUnsavedChanges(): boolean {
    return this.promoCodeForm.dirty;
  }

  canDeactivate(): boolean {
    return !this.promoCodeForm.dirty || confirm('You have unsaved changes. Are you sure you want to leave?');
  }
  getFormFieldHelpersAsString(): string {
    return this.formFieldHelpers.join(' ');
  }

  onCheckboxChange(event: any): void {
    if (event.target.checked) {



    } else {

      this.isActives = false;

    }

  }
  openConfirmationDialog(): Observable<boolean> {
    const dialogRef = this._fuseConfirmationService.open(this.configForm.value);

    return dialogRef.afterClosed().pipe(
      map(result => {
        return result === 'confirmed';
      })
    );
  }



  async makeFieldEditableProduct(index: number) {

    const confirmationResult = await this.openConfirmationDialog().toPromise();
    if (confirmationResult) {

      this.productActive[index] = false;
      const productInput = document.getElementById(`product-input-${index}`) as HTMLInputElement;
      if (productInput) {
        productInput.removeAttribute('readonly'); // Make the input field editable
        productInput.value = ''; // Clear the value of the input field
      }

      const productArray = this.promoCodeForm.get('products') as FormArray;
      const existingFormGroup = productArray.at(index) as FormGroup;

      // Remove the ID from the matchedVariationIds set
      const removedId = existingFormGroup.get('id').value;
      this.matchedVariationIds.delete(removedId);

      // Clear values associated with the deleted product
      this.active.splice(index, 1);
      this.animationFlags.splice(index, 1);
      this.match_stock.splice(index, 1);
      this.current_stock.splice(index, 1);
      this.product_details.splice(index, 1);

      // Clear sizes and colors associated with the deleted product
      this.sizes[index] = [];
      this.colors[index] = [];

      // Remove the productActive flag associated with the deleted product
      this.productActive.splice(index, 1);

      // Remove the form group from the form array
      productArray.removeAt(index);
      
      this.addProductField();
      this.cd.markForCheck();
    } else {
      ""
    }

  }


}