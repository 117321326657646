import {
    Component,
    EventEmitter,
    Inject,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FuseCardComponent } from '@fuse/components/card';
import { ConfirmPopupComponent } from 'app/confirm-popup/confirm-popup.component';

@Component({
    selector: 'app-vendorpopup',
    templateUrl: './vendorpopup.component.html',
    styleUrls: ['./vendorpopup.component.scss'],
})
export class VendorpopupComponent implements OnInit {
    @Output() formSubmitted: EventEmitter<any> = new EventEmitter<any>();

    vendorsForm: FormGroup;
    emails: FormArray;
    @ViewChild('fuseCard') fuseCard: FuseCardComponent;

    constructor(
        private _matDialog: MatDialogRef<ConfirmPopupComponent>,
        private _formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit(): void {
        this.vendorsForm = this._formBuilder.group({
            firstName: ['', [Validators.required, Validators.minLength(2)]],
            lastName: ['', [Validators.required, Validators.minLength(2)]],
            contact_person:[''],
            phoneNumbers: ['', [Validators.required, Validators.minLength(9)]],
            pan_number:[''],
            vat_number:[''],
            emails: this._formBuilder.array([
                this._formBuilder.group({ email: [''],label:[''] }),
            ]),
        });
    }
    onlyNumberValidator(): ValidatorFn {
        return (control: FormControl): { [key: string]: any } | null => {
            const value = control.value;
            if (value && isNaN(value)) {
                return { onlyNumbers: true };
            }
            return null;
        };
    }

    emailControls() {
        return (this.vendorsForm.get('emails') as FormArray).controls;
    }

    addEmail(): void {
        (<FormArray>this.vendorsForm.get('emails')).push(
            this._formBuilder.group({ email: [''],label:[''] })
        );
    }

    removeEmail(index: number): void {
        (<FormArray>this.vendorsForm.get('emails')).removeAt(index);
    }

    submitvendorsInfo(): void {
        this.formSubmitted.emit(this.vendorsForm.value);

        // Close the modal
        this._matDialog.close();
    }
    // Emit the form data

    cancel(): void {
        this._matDialog.close();
    }
}
