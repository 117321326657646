<!-- productForm form -->


<div class="w-full h-full grid-form-container">

    <div class="left-side-content">
        <form enctype="multipart/form-data" [formGroup]="productForm" appUnsavedChanges class="rounded"
            (keydown.enter)="$event.preventDefault()">

            <div *ngIf="productForm" class="wrap-all mr-4">

                <div class="flex flex-col mt-8 p-5 pb-5 bg-card rounded shadow overflow-hidden">
                    <div class="flex flex-row-reverse justify-between ">
                        <span>
                            <button mat-raised-button [routerLink]="['..']">
                                <span class="inline-flex items-center">
                                    <mat-icon svgIcon="feather:x"></mat-icon>
                                </span>
                            </button>
                        </span>
                        <span>
                            <p class="text-lg font-medium">Product Information</p>

                            <p class="text-secondary mt-3">Set your basic product details here.It will be your products
                                primary details.</p>
                        </span>
                    </div>
                    <mat-divider class="mt-5 mb-5"></mat-divider>




                    <div class="flex mb-2">
                        <mat-form-field class="flex-auto  fuse-mat-emphasized-affix fuse-mat-rounded gt-xs:pr-3">
                            <mat-label>Name</mat-label>
                            <div class="prefix-container mr-3"><strong>HUBA</strong> </div>
                            <input [ngClass]="formFieldHelpers" oninput="this.value = this.value.toUpperCase()" required
                                matInput name="productName" id="productName" placeholder="Product Name"
                                formControlName="productName" class="elevated-input" />



                            <mat-icon class="icon-size-4" matPrefix svgIcon="drive_file_rename_outline"></mat-icon>
                            <!-- <mat-error class="m-1 ml-5 mt-2"
                                *ngIf="productForm?.get('productName')?.invalid && productForm?.get('productName')?.touched">
                                <ng-container *ngIf="productForm?.get('productName')?.hasError('required')">
                                    Product Name is required.
                                </ng-container>
                                <ng-container *ngIf="productForm?.get('productName')?.hasError('minlength')">
                                    Product Name must be at least 3 characters long.<br>
                                </ng-container>
                                <ng-container *ngIf="productForm?.get('productName')?.hasError('pattern')">
                                    Product Name must start with a capital letter and end with a lowercase letter, and
                                    contain no numeric values.<br>
                                </ng-container>
                            </mat-error> -->

                        </mat-form-field>
                    </div>



                    <div class="flex mb-2 ">

                        <mat-form-field style="max-width:500px"
                            class="flex-auto fuse-mat-emphasized-affix fuse-mat-rounded ">
                            <mat-label>SKU</mat-label>
                            <input [ngClass]="formFieldHelpers" id="sku" name="sku"
                                oninput="this.value = this.value.toUpperCase()" required matInput
                                placeholder="SKU HUB-LJ-001" formControlName="sku" [readonly]="SkuStatus"
                                class="elevated-input" />

                            <mat-icon *ngIf="SkuStatus === true" class="icon-size-4"
                                matTooltip="Click this to edit the field" matTooltipPosition="above" style="color:red;"
                                matPrefix svgIcon="heroicons_solid:lock-closed" (click)="toggleReadOnly()"></mat-icon>

                            <mat-icon *ngIf="SkuStatus === false" class="icon-size-4"
                                matTooltip="Click this to make the field readonly" matTooltipPosition="above"
                                style="color: green;" matPrefix svgIcon="heroicons_solid:lock-open"
                                (click)="toggleReadOnly()"></mat-icon>




                            <mat-hint class="m-1 ml-4 mt-2">
                                *Auto generated (H-PN-Y-M-GEN-COL-FAB)
                            </mat-hint>
                            <mat-error class="m-1 ml-5 mt-2"
                                *ngIf="productForm?.get('sku')?.invalid && productForm?.get('sku')?.touched">
                                <ng-container *ngIf="productForm?.get('sku')?.hasError('required')">
                                    SKU is required.
                                </ng-container>

                            </mat-error>
                        </mat-form-field>






                        <mat-form-field style="max-width: 200px"
                            class="flex-auto fuse-mat-emphasized-affix fuse-mat-rounded  ml-3">
                            <mat-label>Year </mat-label>
                            <ng-container *ngIf="productForm.get('year').value !== 'others'; else otherInputss">
                                <mat-select placeholder="year" name="year" [formControlName]="'year'"
                                    class="elevated-input">
                                    <mat-option value="2022">2020</mat-option>

                                    <mat-option value="2022">2021</mat-option>

                                    <mat-option value="2022">2022</mat-option>

                                    <mat-option value="2022">2023</mat-option>
                                    <mat-option value="2023">2024</mat-option>
                                    <mat-option value="2024">2025</mat-option>
                                    <mat-option value="2025">2026</mat-option>
                                    <mat-option value="2026">2027</mat-option>
                                    <mat-option value="2027">2028</mat-option>
                                    <mat-option value="2028">2029</mat-option>
                                    <mat-option value="2029">2030</mat-option>
                                    <mat-option value="2030">2031</mat-option>
                                    <mat-option value="2031">2032</mat-option>
                                    <mat-option value="2032">2033</mat-option>
                                    <mat-option value="2033">2034</mat-option>
                                </mat-select>

                            </ng-container>


                            <mat-icon class="icon-size-4" matPrefix svgIcon="date_range"></mat-icon>


                        </mat-form-field>

                    </div>
                    <div class="flex mb-2">
                        <mat-form-field style="max-width: 600px"
                            class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix">
                            <mat-label class="ml-3">Collection</mat-label>

                            <input required style="max-height: 10px;" class="small-input-text" matInput type="text"
                                placeholder="Search Collections" [formControl]="collectionFormControl"
                                [class.selected-option]=" isCollectionSelected(selectedCollection)"
                                [readonly]='isFieldReadOnly' [invalid]="(filteredCollection$ | async)?.length === 0 "
                                [matAutocomplete]="auto" />

                            <button *ngIf="isFieldReadOnly" mat-icon-button (click)="makeFieldEditable()">
                                <mat-icon class="icon-size-4" svgIcon="feather:edit"></mat-icon>
                            </button>


                            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayOption" position="below"
                                (opened)="onAutocompleteOpened()" disableOptionCentering
                                (optionSelected)="selectOption($event.option.value)" overlapTrigger="false"
                                [panelClass]="'custom-autocomplete-panel'">

                                <mat-option *ngIf="loading$ | async">
                                    <div class="loading-container">
                                        <div class="spinner"></div>
                                        <span class="ml-5" style="font-size: 14px; font-family: Montserrat; ">
                                            Loading options please wait !!
                                        </span>
                                    </div>
                                </mat-option>




                                <mat-option style="height: fit-content;"
                                    *ngIf="!(filteredCollection$ | async)?.length && !(loading$ | async)">
                                    <strong style="color: red; font-size: smaller; font-family:Montserrat">No Collection
                                        Found</strong>
                                    <br /><span></span>
                                </mat-option>

                                <mat-option style="height: fit-content"
                                    style="font-size: small; font-family: Montserrat"
                                    *ngFor="let collection of filteredCollection$ | async" [value]="collection">
                                    <div class="autocomplete-option">
                                        <span class="example-option-name">{{ collection.collection_name }}</span>
                                    </div>
                                </mat-option>


                            </mat-autocomplete>


                            <mat-icon class="icon-size-4" matPrefix *ngIf="!isFieldReadOnly" svgIcon="dry_cleaning">
                            </mat-icon>

                            <mat-icon *ngIf="isFieldReadOnly" class="icon-size-4" matPrefix [svgIcon]="'verified'"
                                style="font-size: 24px; color: green;"></mat-icon>


                        </mat-form-field>


                    </div>

                    <div class="flex mb-2">
                        <mat-form-field style="max-width: 900px;" class="flex-auto">
                            <mat-label>Description</mat-label>
                            <mat-icon class="icon-size-5" matPrefix svgIcon="feather:file-text"></mat-icon>

                            <textarea [ngxSummernote]="config" [ngClass]="formFieldHelpers" style="height: 100px;"
                                matInput placeholder="Description" formControlName="description"
                                class="elevated-input"></textarea>

                        </mat-form-field>
                    </div>

                    <div class="flex mb-2">
                        <mat-form-field style="max-width: 600px"
                            class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix">
                            <mat-label class="ml-3">Fabric</mat-label>

                            <input required class="small-input-text" matInput type="text" placeholder="Search Fabric"
                                [formControl]="FabricFormControl"
                                [class.selected-option]="isFabricselected(selectedFabric)"
                                [readonly]='isFieldReadOnlyFabric' [invalid]="(filteredFabric$ | async)?.length === 0 "
                                [matAutocomplete]="auto1" />

                            <button *ngIf="isFieldReadOnlyFabric" mat-icon-button (click)="makeFieldEditableFabric()">
                                <mat-icon class="icon-size-4" svgIcon="feather:edit"></mat-icon>
                            </button>

                            <mat-autocomplete #auto1="matAutocomplete" [displayWith]="displayOptionFabric"
                                position="below" (optionSelected)="selectOptionFabric($event.option.value)"
                                [panelClass]="'custom-autocomplete-panel'">

                                <mat-option *ngIf="loading$ | async">
                                    <div class="loading-container">
                                        <div class="spinner"></div>
                                        <span class="ml-5" style="font-size: 14px; font-family: Montserrat;">
                                            Loading options please wait !!
                                        </span>
                                    </div>
                                </mat-option>

                                <mat-option *ngIf="(filteredFabric$ | async)?.length === 0 ">
                                    <strong style="color: #FF0000; font-size: smaller; font-family: Montserrat;">No
                                        Fabric Found</strong>
                                </mat-option>
                                <mat-option *ngFor="let fabric of filteredFabric$ | async" [value]="fabric">
                                    <div class="autocomplete-option">
                                        <span class="example-option-name">{{ fabric.name }}</span>
                                    </div>
                                </mat-option>

                            </mat-autocomplete>

                            <mat-icon class="icon-size-4" matPrefix *ngIf="!isFieldReadOnlyFabric"
                                svgIcon="dry_cleaning"></mat-icon>

                            <mat-icon *ngIf="isFieldReadOnlyFabric" class="icon-size-4" matPrefix [svgIcon]="'verified'"
                                style="font-size: 24px; color: green;"></mat-icon>
                        </mat-form-field>



                    </div>







                    <div class="flex mb-2">
                        <label class="switch">
                            <input type="checkbox" (change)="onCheckboxChange($event)"
                                [matTooltip]="isActives ? 'If you deactivate it your values will be erased' : null">
                            <div>
                                <span class="text-secondary">Add More Fabric Options</span>
                            </div>
                        </label>




                        <div *ngIf="showFabricOptions" class="flex-auto mt-1 ml-5" style="max-width: 200px;">

                            <mat-checkbox formControlName="secondary_fabric_c" class="example-margin"
                                (change)="secondary = $event.checked"
                                style="font-size:small; font-family:Montserrat ;">Secondary Fabric
                            </mat-checkbox>
                        </div>


                        <div *ngIf="showFabricOptions" class="flex-auto mt-1" style="max-width: 200px;">

                            <mat-checkbox formControlName="inner_fabric_c"
                                style="font-size:small; font-family:Montserrat ;" (change)="inner = $event.checked"
                                class="example-margin">Inner Fabric</mat-checkbox>
                        </div>




                    </div>
                    <p *ngIf="secondary" class="text-secondary mt-3 mb-3">
                    </p>

                    <div *ngIf="secondary" class="flex mb-2">


                        <mat-form-field class="flex-auto fuse-mat-emphasized-affix fuse-mat-rounded ">
                            <mat-label>Secondary Fabric</mat-label>
                            <mat-chip-list #chipListS>
                                <mat-chip *ngFor="let fabric of secondaryFabric"
                                    (removed)="removeSecondaryFabric(fabric)" class="custom-chip">
                                    {{fabric.name}}
                                    <mat-icon matChipRemove svgIcon="feather:x" *ngIf="removable"></mat-icon>
                                </mat-chip>


                                <input matInput [formControl]="secondaryFabricControl"
                                    placeholder="New Secondary Material..." [matChipInputFor]="chipListS"
                                    #secondaryFabricInput [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matAutocomplete]="auto" [matChipInputAddOnBlur]="addOnBlur"
                                    (click)="keepDropdownOpen = true">



                            </mat-chip-list>
                            <div class="autocomplete-container">

                                <mat-autocomplete #auto="matAutocomplete"
                                    (optionSelected)="onSecondaryFabricSelected($event)">
                                    <div cdkScrollable [cdkScrollable] style="max-height: 200px; overflow-y: auto;">

                                        <mat-option *ngIf="loading$ | async">
                                            <div class="loading-container">
                                                <div class="spinner"></div>
                                                <span class="ml-5"
                                                    style="font-size: 14px; font-family: Montserrat; color: #333;">
                                                    Loading options please wait !!
                                                </span>
                                            </div>
                                        </mat-option>
                                        <mat-option style="font-size: small; font-family: Montserrat;"
                                            [disabled]="isSecondaryFabricDisabled(fabric)"
                                            *ngFor="let fabric of filteredSecondaryFabric | async" [value]="fabric">
                                            {{ fabric.name }}
                                        </mat-option>



                                    </div>
                                </mat-autocomplete>
                            </div>

                            <mat-icon class="icon-size-4" matPrefix svgIcon="dry_cleaning"></mat-icon>

                        </mat-form-field>
                    </div>



                    <div *ngIf="inner" class="flex mb-2 ">



                        <mat-form-field class="flex-auto fuse-mat-emphasized-affix fuse-mat-rounded ">
                            <mat-label>Inner Fabric</mat-label>
                            <mat-chip-list #chipListI>
                                <mat-chip *ngFor="let fabric of innerFabric" (removed)="removeInnerFabric(fabric)"
                                    class="custom-chip">
                                    {{fabric.name}}
                                    <mat-icon matChipRemove svgIcon="feather:x" *ngIf="removable"></mat-icon>
                                </mat-chip>


                                <input matInput [formControl]="innerFabricControl" placeholder="New Inner Material..."
                                    [matChipInputFor]="chipListI" #innerFabricInput
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matAutocomplete]="auto1"
                                    [matChipInputAddOnBlur]="addOnBlur" (click)="keepDropdownOpen = true">



                            </mat-chip-list>
                            <div class="autocomplete-container">

                                <mat-autocomplete #auto1="matAutocomplete"
                                    (optionSelected)="onInnerFabricSelected($event)">
                                    <div cdkScrollable [cdkScrollable] style="max-height: 200px; overflow-y: auto;">

                                        <mat-option style="font-size:small;font-family:Montserrat;"
                                            *ngFor="let fabric of filteredInnerFabric | async" [value]="fabric"
                                            [disabled]="isInnerFabricDisabled(fabric)">
                                            {{ fabric.name }}
                                        </mat-option>


                                    </div>
                                </mat-autocomplete>
                            </div>

                            <mat-icon class="icon-size-4" matPrefix svgIcon="dry_cleaning"></mat-icon>

                        </mat-form-field>

                    </div>


                </div>



                <div class="flex flex-col mt-5 p-5 pb-5 bg-card shadow rounded overflow-hidden">

                    <p class="text-lg font-medium">
                        More About Product </p>

                    <p class="text-secondary mt-3">
                        In this section you can state the weight of the product .
                        This is very benfical for determining base pricce for shipping.

                    </p>

                    <mat-divider class="mt-5 mb-5"></mat-divider>


                    <div class="flex mb-2">
                        <mat-form-field style="max-width:350px;"
                            class="flex-auto fuse-mat-emphasized-affix fuse-mat-rounded ">
                            <mat-icon matPrefix class="icon-size-4" svgIcon="line_weight"></mat-icon>

                            <mat-label>
                                Product Weight
                            </mat-label>

                            <input [ngClass]="formFieldHelpers" type="text"
                                onkeypress="return event.charCode>=48 && event.charCode<=57" [disableArrowUpDownWheel]
                                required matInput placeholder="Product Weight" formControlName="product_weight"
                                mask="separator" thousandSeparator="," class="elevated-input" />

                            <mat-select [ngClass]="formFieldHelpers" [value]="'g'" name="weight_alis"
                                [formControlName]="'weight_alis'" matSuffix>
                                <mat-option [value]="'kg'">kg</mat-option>
                                <mat-option [value]="'g'">g</mat-option>
                            </mat-select>

                            <mat-hint class="mt-2 ml-2">
                                Approximate Average shirt for male weighs 150 300 g.
                            </mat-hint>

                        </mat-form-field>

                    </div>
                    <mat-divider class="mt-3 mb-1"></mat-divider>

                    <!-- Image upload field -->
                    <div class="mb-4">
                        <label class="block  font-medium text-gray-700 mb-2">Size Chart</label>
                        <p class="text-secondary mt-3">Upload size chart for your product by clicking the button below.</p>

                        <input type="file" #fileInput (change)="onFileSelected($event)" accept="image/*" hidden>

                        <button type="button" mat-raised-button color="primary" class="mt-2" (click)="triggerFileInput()">Upload Image</button>
                    </div>

                    <!-- Image preview card -->
                    <div *ngIf="imagePreview" class="mt-4 bg-white p-4 rounded shadow-md relative">
                        <p class="text-sm font-medium text-gray-700">Image Preview:</p>
                        <button mat-icon-button class="absolute top-0 right-0" (click)="clearImage()">
                            <mat-icon>close</mat-icon>
                        </button>
                        <img [src]="imagePreview" alt="Image Preview"
                            class="mt-2 max-h-64 object-contain cursor-pointer" (click)="toggleFullScreen($event)">
                    </div>
                </div>



                <div class="flex flex-col mt-8 p-8 pb-5 bg-card shadow rounded overflow-hidden">
                    <p class="text-lg font-medium  " style="font-family: Montserrat">Pricing</p>

                    <p class="text-secondary">
                        Manage Pricing for your product.
                    </p>
                    <mat-divider class="mt-8 mb-5"></mat-divider>
                    <div class="layout2">



                        <div class="flex mb-1">
                            <mat-form-field style="max-width: 300px"
                                class="flex-auto fuse-mat-emphasized-affix fuse-mat-rounded ">
                                <mat-label>Price</mat-label>
                                <input [ngClass]="formFieldHelpers" type="text"
                                    onkeypress="return event.charCode>=48 && event.charCode<=57"
                                    [disableArrowUpDownWheel] required matInput placeholder="Price"
                                    formControlName="basePrice" mask="separator" thousandSeparator=","
                                    class="elevated-input" />
                                <mat-icon class="icon-size-4" type="text" class="icon-size-4" matPrefix svgIcon="">रु
                                </mat-icon>

                                <mat-error class="m-1 ml-5 mt-2"
                                    *ngIf="productForm?.get('basePrice')?.invalid && productForm?.get('basePrice')?.touched">
                                    <ng-container *ngIf="productForm?.get('basePrice')?.hasError('required')">
                                        Product price is required.
                                    </ng-container>
                                    <ng-container *ngIf="productForm?.get('basePrice')?.hasError('pattern')">
                                        Product price is to low.<br>
                                    </ng-container>
                                </mat-error>
                            </mat-form-field>
                        </div>




                    </div>


                    <div class="flex mb-2">
                        <mat-form-field style="max-width: 300px; margin-right: 30px;"
                            class="flex-auto fuse-mat-emphasized-affix fuse-mat-rounded ">
                            <mat-label>Cost of goods</mat-label>
                            <input type="text" onkeypress="return event.charCode>=48 && event.charCode<=57"
                                [disableArrowUpDownWheel] matInput placeholder="basePrice"
                                formControlName="cost_of_goods" class="elevated-input" />
                            <mat-icon class="icon-size-4" type="text" class="icon-size-4" matPrefix svgIcon="">रु
                            </mat-icon>
                        </mat-form-field>

                        <mat-form-field style="max-width: 300px;margin-right: 30px;"
                            class="flex-auto fuse-mat-emphasized-affix fuse-mat-rounded ">
                            <mat-label>Profit</mat-label>
                            <input type="text" onkeypress="return event.charCode>=48 && event.charCode<=57"
                                [disableArrowUpDownWheel] matInput placeholder="basePrice" formControlName="profit"
                                class="elevated-input" />
                            <mat-icon class="icon-size-4" type="text" class="icon-size-4" matPrefix svgIcon="">रु
                            </mat-icon>
                        </mat-form-field>

                        <mat-form-field style="max-width: 300px;margin-right: 30px;"
                            class="flex-auto fuse-mat-emphasized-affix fuse-mat-rounded ">
                            <mat-label>Margin</mat-label>
                            <input type="text" onkeypress="return event.charCode>=48 && event.charCode<=57"
                                [disableArrowUpDownWheel] matInput placeholder="basePrice" formControlName="margin"
                                class="elevated-input" />
                            <mat-icon class="icon-size-4" type="text" class="icon-size-4" matPrefix svgIcon="">रु
                            </mat-icon>
                        </mat-form-field>
                    </div>


                </div>




                <div class="flex flex-col mt-8 p-8 pb-5 bg-card rounded shadow overflow-hidden">
                    <div class="flex justify-between">
                        <div>
                            <p class="text-lg font-medium">Product Options</p>
                            <p class="text-secondary">
                                Does your product come in different options, like size color or material? Add them here.
                            </p>




                            <div style="max-width: 100px;margin-top:50px;">

                                <button mat-stroked-button color="primary" type="submit" class="submit-button"
                                    type="button" (click)="openProductDetailOption()"
                                    [disabled]="sizeDataPushed && colorDataPushed">

                                    <mat-icon class="icon-size-4" matPrefix svgIcon="feather:plus">

                                    </mat-icon>
                                    Add Product Option
                                </button>
                            </div>
                        </div>


                    </div>

                    <mat-divider class="mt-5 mb-2"></mat-divider>

                    <mat-selection-list #shoes [multiple]="false">
                        <mat-list-option *ngFor="let shoe of typesOfShoes" [value]="shoe">
                            <div class="shoe-item">
                                <div class="shoe-info">
                                    <span class="shoe-name">{{ shoe.name }}</span>
                                    <span style="font-size: small; font-family: Montserrat;" class="shoe-values">
                                        {{ shoe.values.join('&nbsp;&nbsp;&nbsp;') }}
                                    </span>
                                </div>

                                <div class="shoe-actions" *ngIf="typesOfShoes.length > 0">
                                    <button mat-icon-button (click)="deleteShoe(shoe)">
                                        <mat-icon class="icon-size-4" matPrefix svgIcon="feather:trash"></mat-icon>
                                    </button>
                                </div>
                            </div>
                        </mat-list-option>
                    </mat-selection-list>




                    <mat-slide-toggle *ngIf="show_ppu_toogle" class="mb-3 mt-3" formControlName="varients_toggel">
                        <p class="text-secondary">Manage pricing and inventory for
                            each product variant</p>
                    </mat-slide-toggle>


                </div>


                <div class="flex flex-col mt-8 p-8 pb-5 bg-card rounded shadow overflow-hidden">
                    <p class="text-lg font-medium">Varients</p>
                    <p class="text-secondary">
                        Manage your Varients here
                    </p>
                    <div class="flex items-center">
                        <button mat-icon-button color="primary" matTooltip="Edit" (click)="openProductVariations(false)"
                            type="button">
                            <mat-icon svgIcon="heroicons_solid:pencil-alt"></mat-icon>
                        </button>
                    </div>

                    <mat-divider class="mt-5"></mat-divider>
                    <div class="">
                        <div class="flex flex-auto overflow-hidden">
                            <!-- Products list -->
                            <div class="flex flex-col flex-auto sm:mb-18 overflow-hidden sm:overflow-y-auto">
                                <div class="mat-elevation-z8" style="max-height: 300px; overflow-y: auto;">
                                    <table mat-table [dataSource]="dataSource" matSort class="mat-table-varients">
                                        <!-- Header Row -->
                                        <ng-container matColumnDef="Varient">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Varient Name </th>
                                            <td mat-cell *matCellDef="let row"> {{ row.varient }} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="SKU">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header> SKU </th>
                                            <td mat-cell *matCellDef="let row"> {{ row.sku }} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="Size">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Size </th>
                                            <td mat-cell *matCellDef="let row"> {{ row.size }} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="Color">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Color </th>
                                            <td mat-cell *matCellDef="let row"> {{ row.color }} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="Price">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Price-Difference
                                            </th>
                                            <td mat-cell *matCellDef="let row"> {{ row.price }} </td>
                                        </ng-container>

                                        <!-- Header Row Definition -->
                                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

                                        <!-- Data Row Definition -->
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                                    </table>
                                </div>

                            </div>


                        </div>






                    </div>
                </div>




                <div class="flex flex-col mt-8 p-8 pb-5 bg-card rounded shadow overflow-hidden">
                    <p class="text-lg font-medium">Pre-Order </p>
                    <div class="flex" style="align-items: center;">
                        <p class="text-secondary">Let customers buy this product before it’s released or when it’s
                            out of stock.</p>
                        <mat-slide-toggle class="ml-5" formControlName="pre_order_toggel"></mat-slide-toggle>
                    </div>





                    <mat-divider class="mt-8 mb-6"></mat-divider>
                    <div class="flex items-center">

                    </div>

                
                    <!-- Conditionally show the stock form field based on the value of the toggle -->
                    <ng-container *ngIf="productForm.get('pre_order_toggel').value">
                        <p class="text-m font-medium mt-4">PRE-ORDER LIMIT
                        </p>

                        <p class="text-secondary mb-5">Limit the total number of items available for pre-order. If
                            this product has variants, the limit will apply to each one individually..</p>

                        <button mat-icon-button color="primary" matTooltip="Edit" (click)="openProductVariations(true)"
                            type="button">
                            <mat-icon svgIcon="heroicons_solid:pencil-alt"></mat-icon>
                        </button>
                        <table mat-table [dataSource]="dataSource" matSort class="mat-table">
                            <!-- Waist Column -->
                            <ng-container matColumnDef="Varient">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Varient Name
                                </th>
                                <td mat-cell *matCellDef="let row"> {{ row.varient }} </td>
                            </ng-container>

                            <!-- Size Column -->
                            <ng-container matColumnDef="SKU">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> SKU
                                </th>
                                <td mat-cell *matCellDef="let row"> {{ row.sku }} </td>
                            </ng-container>


                            <!-- Size Column -->
                            <ng-container matColumnDef="Size">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Size
                                </th>
                                <td mat-cell *matCellDef="let row"> {{ row.size }} </td>
                            </ng-container>

                            <!-- Hips Column -->
                            <ng-container matColumnDef="Color">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Color
                                </th>
                                <td mat-cell *matCellDef="let row"> {{ row.color }} </td>
                            </ng-container>

                            <!-- Bust Column -->


                            <!-- <ng-container matColumnDef="Stock">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Inital Stock </th>
                                <td mat-cell *matCellDef="let row"> {{ row.stock }} </td>
                            </ng-container> -->

                            <!-- Actions Column -->


                            <tr mat-header-row *matHeaderRowDef="displayedColumnss"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumnss"></tr>
                        </table>

                        <div *ngIf="isStockMissing()">
                            <p class="text-on-warn mt-3">Please provide stock information for all pre-order variants.
                            </p>
                        </div>

                    </ng-container>





                </div>






                <div class="flex justify-end mt-5">
                    <button mat-raised-button color="primary" type="submit" class="submit-button"
                        (click)="submitProductInfo()">
                        Save
                    </button>
                </div>


            </div>




        </form>
    </div>

    <!-- Right-side Cards -->
    <div class="right-side-cards">


        <fuse-card class="flex flex-col mt-8 p-8 w-80 pb-5 filter-list">

            <div class="flex items-center justify-between">
                <p class="text-lg font-medium"
                    style="display: flex; align-items: center; justify-content: space-between; flex: 1;">
                    <span>Category</span>
                </p>
                <button matTooltip="View Category Options" matTooltipPosition="above"
                    [matMenuTriggerFor]="selectOptionsMenu">
                    <mat-icon class="icon-size-4" svgIcon="feather:list"></mat-icon>
                </button>
            </div>



            <mat-divider class="mt-3 mb-5"></mat-divider>

            <!-- Search bar -->
            <input type="text" placeholder="Search category" [(ngModel)]="searchText"
                class="p-2 border rounded-full mb-2">
            <mat-divider class="mt-3 mb-2"></mat-divider>

            <!-- Category list with pagination and scroll -->
            <div class="category-list" style="max-height: 200px; overflow-y: auto;">



                <div *ngFor="let category of filteredCategories" class="category-checkbox">
                    <label style="font-size:small; font-family: Montserrat;">
                        <mat-checkbox [(ngModel)]="category.selected" [value]="category.name"
                            (change)="onCategoryChange(category)" class="checkbox"></mat-checkbox>
                        {{ category.name }}
                    </label>
                </div>


            </div>

            <!-- Pagination controls -->
            <div class="pagination-controls flex items-center justify-between mt-3">
                <button mat-icon-button (click)="prevPage()" [disabled]="currentPage === 1">
                    <mat-icon>chevron_left</mat-icon>
                </button>
                <span>{{ currentPage }} / {{ totalPages }}</span>
                <button mat-icon-button (click)="nextPage()" [disabled]="currentPage === totalPages">
                    <mat-icon>chevron_right</mat-icon>
                </button>
            </div>
            <mat-menu #selectOptionsMenu="matMenu" style="position: absolute; top: 40px; right: 0;font-size:small;">
                <button mat-menu-item style="font-size:small ;" (click)="selectAllCategoriesOnPage()">Select All on
                    Page</button>
                <button mat-menu-item style="font-size:small ;" (click)="selectAllCategories()">Select All
                    Categories</button>
                <button mat-menu-item style="font-size:small ;" (click)="resetToDefault()">Reset to Default</button>

            </mat-menu>
        </fuse-card>





        <!-- Gender -->

        <div class="flex flex-col mt-3 p-8 pb-5 bg-card rounded shadow overflow-hidden">
            <p class="text-lg font-medium" style="display: flex; align-items: center;">
                Gender <mat-icon style="margin-left: auto;" svgIcon="supervised_user_circle"></mat-icon>
            </p>



            <mat-divider class="mt-3 mb-5"></mat-divider>

            <div class="category-nav-bar">
                <div *ngFor="let gender of genders" class="category-radio">
                    <input type="radio" [id]="gender.name" [name]="'gender'" [(ngModel)]="selected_gender"
                        [value]="gender.name" (change)="onGenderChange()" />

                    <label [for]="gender.name" style="margin-left: 10px; font-size: small;font-family: Montserrat;"> {{
                        gender.name}}</label>

                </div>
            </div>
        </div>


        <!-- Gender ENd -->

    </div>