// wm-can-leave.component.ts

import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-wm-can-leave',
  templateUrl: './wm-can-leave.component.html',
  styles: [
    /* language=SCSS */
    `
      .fuse-confirmation-dialog-panel {
        @screen md {
          @apply w-128;
        }

        .mat-dialog-container {
          padding: 0 !important;
        }
      }
    `
  ]
})

export class WmCanLeaveComponent implements OnInit {

  unsavedChanges: boolean = false; // Variable to track unsaved changes

  constructor(
    public matDialogRef: MatDialogRef<WmCanLeaveComponent>,
  ) { }

  ngOnInit(): void { }

  // WmCanLeaveComponent
  markChangesAsUnsaved() {
    this.unsavedChanges = true;
  }

  markChangesAsSaved() {
    this.unsavedChanges = false;
  }

  // WmCanLeaveComponent
  onCancelClick(): void {
    // Close the dialog without saving changes
    this.matDialogRef.close('cancelled');
  }

  onStayClick(): void {
    // Close the dialog and mark changes as saved
    this.markChangesAsSaved();
    this.matDialogRef.close('confirmed');
  }

  onLeaveAnywayClick(): void {
    // Close the dialog and mark changes as unsaved
    this.markChangesAsUnsaved();
    this.matDialogRef.close('Leave');
  }
}
