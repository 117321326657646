import { Route } from '@angular/router';
import { ListProductComponent } from './list-product/list-product.component';
import { CreateProductComponent } from './create-product/create-product.component';
import { DetailProductComponent } from './detail-product/detail-product.component';
import { UnsavedChangesGuard } from 'app/page-detect/wm-can-leave/detect-changes.guard';

export const ProductRoutes: Route[] = [
   
        {
            path     : '',
            component: ListProductComponent,
        },
        {
            path         : 'NEW',
            component    : CreateProductComponent,
            canDeactivate: [UnsavedChangesGuard], 

           
        },
        {
            path         : ':id',
            component    :DetailProductComponent,
          
          
        },{
            path         : 'NEW/:id',
            component    :CreateProductComponent,
          
          
        },

]
