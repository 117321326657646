import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { products } from '../products.type';
import { Observable } from 'rxjs';
import { productsService } from '../products.service';
import { take } from 'rxjs/operators';
import Swal from 'sweetalert2';

export interface ProductData {
  productId: string;
  productName?: string;
  variants: boolean;
  variation_count: number;
  sku?: string;

  basePrice?: number;
  created?: any;
  thumbnail?: string;
}

@Component({
  selector: 'app-list-product',
  templateUrl: './list-product.component.html',
  styleUrls: ['./list-product.component.scss'],
})
export class ListProductComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = [
    'ProductName',
    "created",
    'sku',
    'size',
    'color',
    'Prize',
    'actions',
  ];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  productss: products[];
  dataSource: MatTableDataSource<ProductData>;
  editMode: boolean = false;
  products$: Observable<products[]>;
  flashMessage: 'success' | 'error' | null = null;
  isLoading: boolean = true;

  constructor(
    private _router: Router,
    private _productService: productsService,
    private _changeDetectorRef: ChangeDetectorRef,
  ) {

  }

  ngOnInit() {
    this.loadProducts();
  }

  ngAfterViewInit() {

  }


  





  deleteVendorsCredit(productId: string) {
    // Prompt the user for confirmation before deleting the employee
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("im here  ")
        this._productService.deleteproducts(productId)

          .subscribe((isDeleted) => {
            if (!isDeleted) {
              Swal.fire("Error!", "Cannot Delete");
              return;
            }

            // Display a success message
            Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            ).then(() => {
              this.loadProducts();
            });
          });
      }
    });
  }


  showDetails(productId: string): void {
    this._router.navigate(['apps/products', productId]);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  createProduct() {
    this._router.navigate(['apps/products', 'NEW']);
  }
  getStatusColor(status: string): string {
    if (status === 'pending') {
      return 'yellow';
    } else if (status === 'completed') {
      return 'green';
    } else if (status === 'failed') {
      return 'red';
    }
    return '';
  }

  loadProducts() {
    this.products$ = this._productService.getproducts().pipe(take(1));
    this.products$.subscribe((products) => {
      this.productss = products['data'];
      this.dataSource = new MatTableDataSource<ProductData>(
        this.productss.map((products) => {
          const baseUrl = 'http://103.147.227.9:2/8081';
          const pipedDate = products.created.split('T')[0]; // Extracting only the date part

          return {
            productId: products.id,
            productName: products.productName,
            variants: products.variants,
            variation_count: products.variation_count,
            color: products.color,
            size: products.size,
            sku: products.sku,
            basePrice: products.basePrice,
            created: pipedDate, // Using the extracted date
            active: products.active,
            stock: products.stock,
            thumbnail: baseUrl + products.thumbnail,
          };
        })

      );

      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.isLoading = false;

    });
  }
  
 
  toggleAdditionalInfo(row: any): void {
    // Toggle the showAdditionalInfo property for the clicked row
    row.showAdditionalInfo = !row.showAdditionalInfo;
}
  
}

/** Builds and returns a new User. */
