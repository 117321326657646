import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { CreditNotesService } from '../credit-notes.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-refund-popup',
  templateUrl: './refund-popup.component.html',
  styleUrls: ['./refund-popup.component.scss']
})
export class RefundPopupComponent implements OnInit, OnDestroy {
  vendorsCredit: FormGroup;
  credi_note: any;
  credit_noteTotal: any;
  creditNotesSubscription: Subscription;
  paymentOptions = [
    "SIDDHARTHA BANK FONEPAY - HEADQUARTER",
    "SIDDHARTHA BANK FONEPAY - JHAMSIKHEL",
    "NIC ASIA BANK FONEPAY - HEADQUARTER",
    "NIC ASIA BANK FONEPAY",
];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _matDialog: MatDialogRef<RefundPopupComponent>,
    private _fb: FormBuilder,
    private datePipe: DatePipe,
    private _refund_service: CreditNotesService,
    private _router: Router,
    private _cdr: ChangeDetectorRef
  ) {}

  
  ngOnInit(): void {
    this.vendorsCredit = this._fb.group({
      refund_date: [null, Validators.required],
      payment_type: [null, Validators.required],
      reference: [null],
      amount: [
        null,
        [Validators.required, Validators.max(this.credit_noteTotal)]
      ],
      description: [null],
    });

    // Fetch the credit notes total when the component is initialized
    this.getCreditNotesTotal(this.data.id);

    this.creditNotesSubscription = this.vendorsCredit.valueChanges.subscribe((formValue) => {
      this.updateCreditNoteTotal(formValue.amount);
      this._cdr.detectChanges(); // Trigger change detection

    });
  }

  ngOnDestroy() {
    if (this.creditNotesSubscription) {
      this.creditNotesSubscription.unsubscribe();
    }
  }

  getCreditNotesTotal(id: any) {
    this._refund_service.getSalesOrderById(id).subscribe((data) => {
      this.credi_note = data;
      this.credit_noteTotal = data.total;
      this.updateMaxValidator(); // Update max validator when credit_noteTotal changes
    });
  }

  updateCreditNoteTotal(amount: number) {
    const newBalance: number = this.credit_noteTotal - amount;
    this.credit_noteTotal = Math.max(newBalance, 0);

    this.updateMaxValidator(); // Update max validator when credit_noteTotal changes
  }

  updateMaxValidator() {
    // Update the validation state of the 'amount' field
    this.vendorsCredit.get('amount').setValidators([
      Validators.required,
      Validators.max(this.credit_noteTotal)
    ]);
    this.vendorsCredit.get('amount').updateValueAndValidity();
  }



  async saveRefund() {
    if (!this.vendorsCredit.valid) {
      this.handleInvalidForm();
      return;
    }

    const refundData = this.prepareRefundData();

    try {
      const response = await this._refund_service.createRefund(refundData);
      if (response.success) {
        this.showSuccessToast('Refund created.');
        this._router.navigate(['apps/credit-notes']);
      } else {
        this.handleApiErrors(response.errors);
      }
    } catch (error) {
      this.handleErrorToast(error.message);
    } finally {
      this._matDialog.close({ saved: true });
    }
  }

  prepareRefundData() {
    const refundData = { ...this.vendorsCredit.value };
    refundData.refund_date = this.datePipe.transform(this.vendorsCredit.get('refund_date')?.value, 'yyyy-MM-dd');
    refundData.credit_note = this.data.id;
    return refundData;
  }

  handleInvalidForm() {
    this.showErrorToast('Invalid form data', 'Please fill in all the required fields correctly.');
    this._router.navigate(['apps/credit-notes']);
  }

  handleApiErrors(errors) {
    const errorMessage = JSON.stringify(errors);
    this.showErrorToast('Error creating Refund', errorMessage);
  }

  showSuccessToast(message: string) {
    this.showToast('success', message);
  }

  handleErrorToast(message: string) {
    this.showToast('error', `Error creating Refund: ${message}`);
  }

  showErrorToast(title: string, text: string) {
    this.showToast('error', title, text);
  }

  showToast(icon: any, title: string, text?: string) {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: icon,
      title: title,
      text: text,
    });
  }


}
