import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { RestockProductComponent } from './restock-product/restock-product.component';
import { ListRestockComponent } from './list-restock/list-restock.component';
import { CreateRestockComponent } from './create-restock/create-restock.component';
import { DetailRestockComponent } from './detail-restock/detail-restock.component';
import { MaterialModule } from 'app/modules/material/material.module';
import { RouterModule } from '@angular/router';
import { RestockEntryRoutes } from './restock-product.routing';

import { MAT_DATE_FORMATS } from '@angular/material/core';


@NgModule({
  declarations: [
    RestockProductComponent,
    ListRestockComponent,
    CreateRestockComponent,
    DetailRestockComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(RestockEntryRoutes),

    MaterialModule
  ],
  providers   : [
    {
        provide : MAT_DATE_FORMATS,
        useValue: {
            parse  : {
                dateInput: "YYYY-MM-DD"
            },
            display: {
                dateInput         : 'YYYY-MM-DD',
                monthYearLabel    : 'MMM YYYY',
                dateA11yLabel     : 'LL',
                monthYearA11yLabel: 'MMMM YYYY'
                
            }
        }
    },
    DatePipe,
    
  ],
})
export class RestockProductModule { }
