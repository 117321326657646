<div class="flex justify-around items-center h-15 text-white rounded-t-lg" style="background-color:#c8bd67;">
    <h2 class="mt-1.5 text-lg font-semibold mt-5">Shipped Packages</h2>
    
    <div class="flex items-center">
        <button [matMenuTriggerFor]="menus" class="mr-3">
            <mat-icon svgIcon="feather:menu" class="mt-1.5 text-white"></mat-icon>
        </button>
        <mat-checkbox [(ngModel)]="selectAll" (change)="toggleSelectAll()" class="mb-1.5"></mat-checkbox>
    </div>

    <mat-menu #menus="matMenu" xPosition="before">
        <button mat-menu-item (click)="openPackages()">
            <mat-icon svgIcon="feather:briefcase"></mat-icon>
Delivery        </button>
    </mat-menu>
</div>




  
<mat-card>

    <mat-card-content class="p-5">
      <mat-form-field class="w-full">
          <mat-icon matPrefix [svgIcon]="'heroicons_outline:search'"></mat-icon>
          <input matInput [autocomplete]="'off'" (keyup)="applyFilter($event)" [placeholder]="'Search Sales Order'" />
      </mat-form-field>
  
      <!-- Main -->
      <ng-container *ngFor="let data of filteredPackageData">
  
          <fuse-card class="flex max-w-80 w-full filter-info mt-3" (click)="showDetails(data.id)" matRipple>
  
              <div class="flex flex-col flex-auto  p-6">
  
                  <div class="text-lg font-semibold leading-tight">{{ data.salesOrder.Customers.firstName }}
                      {{data.salesOrder.Customers.lastName}}</div>
                  <div class="text-md text-secondary"> NPR {{ data.salesOrder.total }}</div>
                  <div class="text-md text-green-600 mt-3">{{ data.salesOrder.sales_order_num }} </div>
                  <div class="text-md text-yellow-600 ">{{ data.packageSlip }} </div>
  
                  <div class="text-md text-secondary mt-3"> {{ data.date }}</div>
  
              </div>
  
  
  
              <div class="flex flex-auto mt-1 justify-end align-right">
                  <button mat-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
                      <mat-icon svgIcon="feather:more-vertical"></mat-icon>
                  </button>
                  <mat-menu #menu="matMenu" xPosition="before">
                      <button mat-menu-item (click)="openDelivaryPopup(data.id)">
                          <mat-icon svgIcon="feather:briefcase"></mat-icon>
                          Delivery
                      </button>
                  </mat-menu>
              </div>
  
  
  
  
              <mat-checkbox class="m-3" (click)=" $event.stopPropagation();" [(ngModel)]="data.isSelected" (change)="toggleRowSelection(data);" ></mat-checkbox> 
  
  
          </fuse-card>
      </ng-container>
  
  </mat-card-content>
  <!-- ... your existing content ... -->

<mat-paginator [length]="filteredPackageData.length"
[pageSize]="pageSize"
[pageSizeOptions]="pageSizeOptions"
(page)="onPageChange($event)">
</mat-paginator>

  </mat-card>
