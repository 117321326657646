<mat-dialog-content class="dialog-content">
  <form [formGroup]="productOption_popup" (ngSubmit)="submitForm(productOption_popup.value)">

    <!-- Avatar -->
    <!-- Avatar code goes here -->

    <!-- Personal Information Form -->
    <div>
      <div class="prose prose-sm max-w-4xl">
      </div>

      <div class="flex flex-col mt-8 p-8 pb-5 bg-card rounded shadow overflow-hidden">
        <p class="text-lg font-medium">Add Material Option</p>
        <p class="text-secondary">
          </p>
        <mat-divider class="mt-8 mb-10"></mat-divider>

        <div class="flex">
          <mat-form-field style="max-width: 500px" class="flex-auto">
            <mat-label>Select an option</mat-label>
            <mat-select name="option_name" [formControlName]="'option_name'" (selectionChange)="pointOfsales()">
              <mat-option [disabled]="submittedOptions.includes('Size')" value="Size">Secondary Material</mat-option>
              <mat-option value="Color" [disabled]="optionSubmissionService.isOptionSubmitted('Color')">Inner Material</mat-option>
              <mat-option value="Colors" [disabled]="optionSubmissionService.isOptionSubmitted('Color')">Outer Material</mat-option>
            </mat-select>
          
          </mat-form-field>
          <div>
            <mat-button-toggle-group name="fontStyle" aria-label="Font Style">

            </mat-button-toggle-group>

          </div>

        </div>



        <div class="flex">
          <mat-form-field *ngIf="showColor" class="example-chip-list flex-auto">
            <mat-label>Type in Inner Material  for this option </mat-label>
            <mat-chip-list #chipList aria-label="color selection">
              <mat-chip *ngFor="let color of colors" (removed)="remove(color)">
                {{ color }}
                <button matChipRemove [attr.aria-label]="'remove ' + color">
                  <mat-icon [svgIcon]="'feather:x-circle'"></mat-icon></button>

              </mat-chip>
              <input placeholder="New color..." #colorInput [formControl]="colorsCtrl" [matAutocomplete]="auto"
                [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="add($event)">
            </mat-chip-list>
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
              <mat-option *ngFor="let color of filteredcolors | async" [value]="color">
                {{ color }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>



        <div class="flex">
          <mat-form-field *ngIf="showSizes" class="example-chip-list flex-auto">
            <mat-label>Type in Secondary Material for this option </mat-label>
            <mat-chip-list #chipList aria-label="color selection">
              <mat-chip *ngFor="let sizes of sizes" (removed)="removeSizes(sizes)">
                {{ sizes }}
                <button matChipRemove [attr.aria-label]="'remove ' + sizes">
                  <mat-icon [svgIcon]="'feather:x-circle'"></mat-icon></button>

              </mat-chip>
              <input placeholder="New Secondary Material..." #sizesInput [formControl]="sizesCtrl" [matAutocomplete]="auto1"
                [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="addSizes($event)">
            </mat-chip-list>
            <mat-autocomplete #auto1="matAutocomplete" (optionSelected)="selectedSizes($event)">
              <mat-option *ngFor="let sizes of filteredsizes | async" [value]="sizes">
                {{ sizes }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

        </div>



        <div class="flex items-center justify-end mt-10">
          <button class="px-6 ml-3" mat-flat-button type="submit" color="primary">
            Save
          </button>
          <button (click)="cancel()" class="px-6 ml-3" mat-flat-button type="button" color="error">
            Cancel
          </button>
        </div>
      </div>
    </div>

  </form>
</mat-dialog-content>