import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Observable, Subject } from 'rxjs';
import { take, } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import Swal from 'sweetalert2';
import { DetailRestockService } from '../detail-restock.service';
import { RestockProduct } from '../restock-product.type';
@Component({
  selector: 'app-list-restock',
  templateUrl: './list-restock.component.html',
  styleUrls: ['./list-restock.component.scss']
})
export class ListRestockComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = ['id', 'date', 'product', 'size', 'color', 'stock', 'created_by'];

  dataSource: MatTableDataSource<RestockProduct>;
  initalSheet: RestockProduct[];
  initalSheet$: Observable<RestockProduct[]>;


  selectedInital: string;
  initalStockFilter: string;


  private _unsubscribeAll: Subject<any> = new Subject<any>();

  _activatedRoute: any;
  isLoading = true;
  constructor(

    private _inventoryService: DetailRestockService,
    private _router: Router,
  ) { }

  ngOnInit(): void {

    this.loadData();
  }

  applyFilters() {
    this.isLoading = true;


    this.initalStockFilter = this.selectedInital ? this.selectedInital.trim().toLowerCase() : '';

    this.loadData();


  }

  loadData() {
    this.initalSheet$ = this._inventoryService
      .getRestockEntry()
      .pipe(take(1));
    this.initalSheet$.subscribe((inital) => {
      this.initalSheet = inital['data'];

      this.dataSource = new MatTableDataSource<RestockProduct>(

        this.initalSheet
          .map((inital) => {
            // Map nested properties of products array
            const product = inital.products;
            const pipedDate = inital.date.split('T')[0]; // Extracting only the date part


            return {
              id: inital.id,
              date: pipedDate,
              productName: product.base_product.productName, // Replace 'productName' with the actual property name
              size: product.size,
              color: product.color,
              restock_entry: inital.restock_entry,
              isPreOrder: product.base_product.pre_order_toggel,
              created_by:inital.created_by.username,



            };
          })
          .filter((item) => {
          
            if (this.initalStockFilter === 'pre order inital') {

              return item.isPreOrder === true;

            } else if (this.initalStockFilter === 'product inital') {
              return item.isPreOrder === false;
            } else {
              return true;
            }

          })
      );

      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.isLoading = false;
    });



  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  /**
   * On destroy
   */



  delteInital(id: any) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this! This will only delete the record won't revert the stock changes",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {

      if (result.isConfirmed) {
        this._inventoryService.deleteRestockEntry(id)

          .subscribe((isDeleted) => {
            this.loadData();


            if (!isDeleted) {
              Swal.fire("Error!", "Cannot Delete");
              return;
            }

            // Display a success message
            Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            )
          });
      }
    });
  }


  createInital() {
    this._router.navigate(['apps/restock-product', 'NEW']);

  }


  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
