import { Component, OnDestroy, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SalesOrderService } from '../sales-order.service';
import { PackagePopupComponent } from 'app/packages/package-popup/package-popup.component';
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { copyImageToClipboard } from 'copy-image-clipboard'
import html2canvas from 'html2canvas';
import { data } from 'cypress/types/jquery';


@Component({
    selector: 'app-detail',
    templateUrl: './detail.component.html',
    styleUrls: ['./detail.component.scss'],
})
export class SalesOrderDetailComponent implements OnInit, OnDestroy {
    @ViewChild('screenshotElement') screenshotElement!: ElementRef;

    productId: string;
    extractedChanges: any[] = [];
    initialProducts: any[] = [];
    modifiedProducts: any[] = [];
    mod_product: boolean = false;
    showAdditionalInfo: boolean = false;
    showChanges: boolean = false;
    exchangesAndReturns: { number: string, total: number, date: any }[] = [];
    payment:any;
    vendor: any;
    dropdownHidden: boolean = true;
    salesHitory: any;
    constructor(
        private route: ActivatedRoute,
        private productService: SalesOrderService,
        private dialog: MatDialog
    ) { }

    goBackOnPreviousWin = () => {
        window.history.back()
    }

    ngOnInit(): void {
        this.route.paramMap.subscribe((params) => {
            this.productId = params.get('id');
            this.fetchProductDetails(this.productId);
            this.fetchSalesHistory(this.productId);
            this.getPaymentHistory('salesOrder',this.productId)
        });
    }

    fetchProductDetails(productId: string): void {
        this.productService.getSalesOrderById(productId).subscribe(
            (data) => {
                this.vendor = data;
                this.initialProducts = this.vendor.products;

                this.initialProducts.forEach(product => {
                    const hasBundleId = !!product.product_bundle?.id;
                    product.isBundle = hasBundleId;
                    product.displayName = hasBundleId ? product.product_bundle.bundle_name : product.product.base_product.productName;
                    product.displayDescription = hasBundleId ? product.product_bundle.bundle_description : product.product.base_product.description;
                });

                console.log('Initial products:', this.initialProducts);
            },
            (error) => {
                console.error('Error fetching initial product details:', error);
            }
        );
    }
    fetchSalesHistory(productId: string): void {
        this.productService.getSalesOrderHistory(productId).subscribe(
            (data) => {
                this.salesHitory = data.data;

                this.extratSalesHistory(this.salesHitory.history)
                console.log("here",this.salesHitory.exchanges)
                this.fetchExchangesReturn(this.salesHitory?.exchanges, this.salesHitory?.returns)
            },
            (error) => {
                console.error('Error fetching product details:', error);
            }
        );
    }

    fetchExchangesReturn(exchange: any, salesReturn: any) {
        if (exchange) {
            exchange.map((data)=>{
                this.exchangesAndReturns.push({ number: data.exchange_number, total: data.total, date: data.date });
            });
           
        }
        if (salesReturn) {
            if (Array.isArray(salesReturn)) {
                salesReturn.forEach((record: any) => {


                    this.exchangesAndReturns.push({ number: record.salesReturnNumber, total: record.total, date: record.date });

                })
            }

        }
    }
    

    extratSalesHistory(history: any) {

        this.extractedChanges = [];

        if (Array.isArray(history)) {
            history.forEach((record: any) => {
                const products = record.new_snapshot

                const extractedProducts = products.products

                const extracted = this.extractChanges(record.changes, record.updated_at, record.updated_by, extractedProducts);
                this.extractedChanges = this.extractedChanges.concat(extracted);
            });

        } else {
            console.warn('History is not an array:', history);
        }
    }

    extractChanges(changes: any, updatedAt: string, updatedBy: number, products: any): any[] {

        const extractedChanges = [];

        const modifiedBy = changes.modified_by ? changes.modified_by.new : null;


        const updatedAtDate = new Date(updatedAt);
        const options = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false };
        const updatedAtFormatted = updatedAtDate.toLocaleString('en-US'); // Adjust the formatting as needed

        // Log the full `changes` object for debugging

        // Extract the other changes
        for (const key in changes) {
            if (changes.hasOwnProperty(key) && key !== 'modified_by') {
                const change = changes[key];
                if (change && typeof change === 'object' && 'old' in change && 'new' in change) {
                    extractedChanges.push({
                        key: key,
                        oldValue: change.old,
                        newValue: change.new,
                        modifiedBy: modifiedBy,
                        updatedAt: updatedAtFormatted, // Use the formatted date and time
                        updatedBy: updatedBy
                    });
                } else {
                    console.warn(`Skipping key ${key} as it does not contain 'old' and 'new' properties`);
                }
            }
        }
        this.modifiedProducts.push(products)
        console.log(changes)
        this.compareAndIdentifyMissingProduct();

        return extractedChanges;
    }

    compareAndIdentifyMissingProduct(): void {
        const modifiedProducts = this.modifiedProducts[0] || [];
        const initialProducts = this.initialProducts || [];
    
        // Helper function to normalize rate
        const normalizeRate = (rate: string | number): number => {
            if (typeof rate === 'string') {
                return parseFloat(rate.replace(/[^\d.-]/g, ''));
            }
            return rate;
        };
    
        // Find all modified products that have a different quantity or normalized rate
        const modifiedOrNewProducts = modifiedProducts.filter(modifiedProduct => {
            const initialProduct = initialProducts.find(initialProduct => initialProduct.product.id === modifiedProduct.product.id);
            return (
                !initialProduct ||
                initialProduct.quantity !== modifiedProduct.quantity ||
                normalizeRate(initialProduct.rate) !== normalizeRate(modifiedProduct.rate)
            );
        });
    
        // Find all missing product IDs
        const modifiedProductIds = modifiedProducts.map(product => product.product.id);
        const initialProductIds = initialProducts.map(product => product.product.id);
        const missingProductIds = modifiedProductIds.filter(id => !initialProductIds.includes(id));
    
        this.mod_product = true;
    
        if (!Array.isArray(this.vendor?.mod_products)) {
            this.vendor!.mod_products = [];
        }
    
        // Process modified or new products
        modifiedOrNewProducts.forEach(modifiedProduct => {
            const existingProductIndex = this.vendor.mod_products.findIndex(product => product.product.id === modifiedProduct.product.id);
    
            if (existingProductIndex === -1) {
                this.vendor.mod_products.push(modifiedProduct);
            } else {
                this.vendor.mod_products[existingProductIndex] = modifiedProduct;
            }
    
            const hasBundleId = !!modifiedProduct.product_bundle?.id;
            modifiedProduct.isBundle = hasBundleId;
            modifiedProduct.displayName = hasBundleId ? modifiedProduct.product_bundle.bundle_name : modifiedProduct.product.base_product.productName;
            modifiedProduct.displayDescription = hasBundleId ? modifiedProduct.product_bundle.bundle_description : modifiedProduct.product.base_product.description;
        });
    
        // Process missing products
        missingProductIds.forEach(missingProductId => {
            const missingProduct = modifiedProducts.find(product => product.product.id === missingProductId);
    
            if (missingProduct) {
                const existingProductIndex = this.vendor.mod_products.findIndex(product => product.product.id === missingProductId);
    
                if (existingProductIndex === -1) {
                    this.vendor.mod_products.push(missingProduct);
                }
    
                const hasBundleId = !!missingProduct.product_bundle?.id;
                missingProduct.isBundle = hasBundleId;
                missingProduct.displayName = hasBundleId ? missingProduct.product_bundle.bundle_name : missingProduct.product.base_product.productName;
                missingProduct.displayDescription = hasBundleId ? missingProduct.product_bundle.bundle_description : missingProduct.product.base_product.description;
            }
        });
    }
    
    
    

    printPage() {
        // Select the buttons you want to hide
        const buttonsToHide = document.querySelectorAll('button');

        // Hide the buttons before printing
        buttonsToHide.forEach((button) => {
            button.style.display = 'none';
        });

        // Perform the printing
        window.print();

        // Show the buttons again after printing (delayed to ensure they show after the print dialog)
        setTimeout(() => {
            buttonsToHide.forEach((button) => {
                button.style.display = 'block'; // or 'inline' depending on the button's original display property
            });
        }, 1000); // Adjust the delay as needed
    }


    getPaymentHistory(type: string, id: any): void {
        this.productService.getPaymentByTypeAndId(type, id).subscribe(
            (response: any[]) => {
                this.payment = response.map(payment => {
                    let date = '';
                    let time = '';
                    
                    if (payment.date) {
                        const dateTime = new Date(payment.date);
                        if (!isNaN(dateTime.getTime())) {
                            date = dateTime.toISOString().split('T')[0]; // Get date part
                            time = dateTime.toISOString().split('T')[1].split('.')[0]; // Get time part, removing milliseconds
                        } else {
                            console.warn('Invalid date format:', payment.date);
                        }
                    } else {
                        console.warn('Missing date field in payment:', payment);
                    }
    
                    console.log(date, "date");
                    console.log(time, "time");
    
                    return {
                        is_fully_paid: payment.is_fully_paid,
                        id: payment.id,
                        total_received: payment.total_received,
                        payment_method: payment.payment_method,
                        date: date,
                        time: time,
                        remaining_balance: payment.remaining_balance,
                    };
                });
            },
            (error) => {
                console.error('Error fetching payment history:', error);
            }
        );
    }
    
    


    getThumbnailUrl(thumbnail: string): string {
        const baseUrl = 'http://103.147.227.9:2/8081'; // Your base URL here
        return baseUrl + thumbnail;
    }
    toggleDropdown() {
        this.dropdownHidden = !this.dropdownHidden;
    }

    // on package click this open the package dialog box
    openPackagepopup(saleOderId: any) {
        const dialogRef = this.dialog.open(PackagePopupComponent, {
            width: '800px',
            data: { saleOderId }, // Adjust the width as per your requirements
            // Pass the selected vendor as data to the modal
        });

        dialogRef.afterClosed().subscribe((result) => {
            // Handle any actions after the modal is closed
        });
    }


    toggleAdditionalInfo() {
        this.showAdditionalInfo = !this.showAdditionalInfo;
    }

    toggleChanges() {
        this.showChanges = !this.showChanges;
    }

    ngOnDestroy(): void { }

    screenshot() {
        const element = this.screenshotElement.nativeElement;
    
        if (!element) {
          console.error('Element not found');
          return;
        }
    
        console.log(element,"here")
    
        // Apply temporary styling to ensure visibility and correct rendering
    
        // Use a longer timeout to ensure everything is fully rendered
        
        setTimeout(() => {
    
          html2canvas(element, {
    
          })
            .then(canvas => {
              const dataURL = canvas.toDataURL('image/png');
    
              const anchor = document.createElement('a');
              anchor.style.display = 'none';
              anchor.href = dataURL;
    
              copyImageToClipboard(anchor.href)
                .then(() => {
    
                  const Toast = Swal.mixin({
                    toast: true,
                    position: "top",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                      toast.onmouseenter = Swal.stopTimer;
                      toast.onmouseleave = Swal.resumeTimer;
                    }
                  });
                  Toast.fire({
                    icon: "success",
                    title: "Copied to the clipboard"
                  });
                })
                .catch((e) => {
                  console.log('Error: ', e.message)
                })
    
    
    
              document.body.removeChild(anchor);
    
    
    
            })
            .catch(error => {
              console.error('Error capturing screenshot:', error);
    
              // Rests
            });
        });
      }
    
}
