import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { Contact } from 'app/modules/admin/apps/contacts/contacts.types';
import { server_env } from 'config';

@Injectable({
    providedIn: 'root'
})
export class ContactsService
{
    // Private
    private _contact: BehaviorSubject<Contact | null> = new BehaviorSubject(null);
    private _contacts: BehaviorSubject<Contact[] | null> = new BehaviorSubject(null);

    public url  = `${server_env.URL}api/v1/customer/`
    public urls = `${server_env.URL}api/v1/customer-report/`
    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient)
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for contact
     */
    get contact$(): Observable<Contact>
    {
        return this._contact.asObservable();
    }

    /**
     * Getter for contacts
     */
    get contacts$(): Observable<Contact[]>
    {
        return this._contacts.asObservable();
    }

   

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get contacts
     */

    getContacts(): Observable<Contact[]>
    {
        return this._httpClient.get<Contact[]>(`${this.url}`).pipe(
            tap((contacts:any) => {
         
                this._contacts.next(contacts.data);
                
                for (contacts in contacts.data) {
                  
                }
            })
        );
    }

    downloadExcelReport(id: number): Observable<any> {
        const url = `${this.url}excel-report/${id}/`;
        return this._httpClient.get(url, { responseType: 'blob' });
      }
    
      downloadPdfReport(id: number): Observable<any> {
        const url = `${this.url}pdf-report/${id}/`;
        return this._httpClient.get(url, { responseType: 'blob' });
      }

    /**
     * Search contacts with given query
     *
     * @param query
     */
    searchContacts(query: string): Observable<Contact[]>
    {
        return this._httpClient.get<Contact[]>('api/apps/contacts/search', {
            params: {query}
        }).pipe(
            tap((contacts) => {
                this._contacts.next(contacts);
            })
        );
    }

    /**
     * Get contact by id
     */
    getContactById(id: string): Observable<Contact>
    {

        const requestUrl= this.url + id;

        return this._httpClient.get<Contact[]>(`${requestUrl}/`).pipe(
            take(1),
            map((contact:any) => {
                this._contact.next(contact.data);

                // Return the contact
                return contact.data;
            }),
            switchMap((contact) => {

                if ( !contact )
                {
                    return throwError('Could not found contact with id of ' + id + '!');
                }

                return of(contact);
            })
        );
    }

    /**
     * Create contact
     */
    createContact(): Observable<Contact>
    {
        return this.contacts$.pipe(
            take(1),
            switchMap(contacts => this._httpClient.post<Contact>(`${this.url}`, {}).pipe(
                map((newContact) => {

                    // Update the contacts with the new contact
                    this._contacts.next([newContact, ...contacts]);

                    // Return the new contact
                    return newContact;
                })
            ))
        );
    }

    /**
     * create customer
     * @param data 
     * @returns 
     */

    createCustomer(data) :Promise<any>{
        
        return new Promise((resolve , reject)=>{
        
            const requestUrl= this.url;
            
            this._httpClient.post<any>(`${requestUrl}` , data).subscribe(response=>{
               return resolve(response);
            }, error =>{
                return reject(error)
            });

        });

    }
  /**
 * Update Contact
 *
 * @param id
 * @param contactData
 */
  updateContact(id: string, contactData: any): Observable<Contact> {
  
    return this.contacts$.pipe(
        take(1),
        switchMap(Contacts =>
            this._httpClient.put(`${this.url}${id}/`, contactData).pipe(
                map((updatedContact) => {
                 
                    // Find the index of the updated Contact
                    const index = Contacts.findIndex(item => item.id === id);
                    // Update the Contact
                    Contacts[index] = updatedContact;
                   
                    // Update the Contacts
                    this._contacts.next(Contacts);

                    // Return the updated Contact
                    return updatedContact;
                }),
                switchMap(updatedContact =>
                    this.contact$.pipe(
                        take(1),
                        filter(item => item && item.id === id),
                        tap(() => {
                            // Update the Contact if it's selected
                            this._contact.next(updatedContact);
                        })
                    )
                )
            )
        )
    );
}

    /**
     * Delete the contact
     *
     * @param id
     */
    deleteContact(id: string): Observable<boolean>
    {
        return this.contacts$.pipe(
            take(1),
            switchMap(contacts => this._httpClient.delete(`${this.url}${id}/`, ).pipe(
                map((isDeleted: boolean) => {

                    // Find the index of the deleted contact
                    const index = contacts.findIndex(item => item.id === id);

                    // Delete the contact
                    contacts.splice(index, 1);

                    // Update the contacts
                    this._contacts.next(contacts);

                    // Return the deleted status
                    return isDeleted;
                })
            ))
        );
    }


    /**
     * Get contact by id
     */
    getReportById(id: string): Observable<any>
    {

        const requestUrl= this.urls + id;

        return this._httpClient.get<any[]>(`${requestUrl}/`).pipe(
            take(1),
            map((report:any) => {

                return report;
            }),
            switchMap((report) => {

                if ( !report )
                {
                    return throwError('Could not found contact with id of ' + id + '!');
                }

                return of(report);
            })
        );
    }

  
}
