import { Route } from '@angular/router';
import { ListpackagesComponent } from './listpackages/listpackages.component';
import { CreatepackagesComponent } from './createpackages/createpackages.component';
import { DetailpackagesComponent } from './detailpackages/detailpackages.component';


export const PackagesRoutes: Route[] = [
   
        {
            path     : '',
            component : ListpackagesComponent,
        },
        {
            path         : 'NEW',
            component    : CreatepackagesComponent,
           
        },
        {
            path         : ':id',
            component    :DetailpackagesComponent,
          
          
        },
]
