import { Directive, Input, ViewContainerRef, ComponentFactoryResolver, ComponentRef, OnInit } from '@angular/core';
import { InvoiceComponent } from './invoice/sales-order-invoice'; 
import { SalesOrderService } from './sales-order.service';
import { SalesOrder } from './sales-order.type'; // Import your SalesOrder model/interface

@Directive({
  selector: '[appInvoiceRenderer]'
})
export class InvoiceRendererDirective implements OnInit {
  @Input() ids: any[] = [];

  constructor(
    private viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private productService: SalesOrderService
  ) {}

  ngOnInit(): void {
    this.ids.forEach(id => {
      this.productService.getSalesOrderById(id).subscribe(
        (data: SalesOrder) => { // Assuming SalesOrder is the correct type
          const factory = this.componentFactoryResolver.resolveComponentFactory(InvoiceComponent);
          const componentRef: ComponentRef<InvoiceComponent> = this.viewContainerRef.createComponent(factory);
          componentRef.instance.vendor = [data]; // Wrap data in an array if vendors expects an array
        },
        (error) => {
          console.error('Error fetching product details:', error);
        }
      );
    });
  }
}
