import { Component, OnInit, ViewChild, OnDestroy, Input } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ShippedPopupComponent } from 'app/packages/shipped-popup/shipped-popup.component';
import { forkJoin } from 'rxjs';
import Swal from 'sweetalert2';
import { PackagesOderService } from 'app/packages/packages.service';

interface packageData {
    customerName: any;
    SlaesOrderNumber: any;
    total: any;
    packagedDate: any;
    PackageCode: any;
    id: number;
    salesOrder: string;
    infulencerOrder: string;
    date: Date;
    packageSlip: string
    shipSlip: string
    isPackaged: boolean
    isShipped: boolean;
    isDelivared: boolean;
}

@Component({
    selector: 'fuse-pack-not-shipped',
    templateUrl: './pack-not-shipped.component.html',
    styleUrls: ['./pack-not-shipped.component.scss']
})
export class fusePackNotShippedComponent implements OnInit, OnDestroy {
    customerName: any;
    SlaesOrderNumber: any;
    total: any;
    packagedDate: any;
    PackageCode: any;
    packageId: any;
    filteredPackageData: any[] = [];
    pageSizeOptions: number[] = [5, 10, 25]; // Options for items per page
    pageSize: number = 5; // Default items per page
    pageIndex: number = 0; // Default page index
    selectedRowIds: number[] = [];

    selectAll: boolean = false;

    @Input() dataTableSource: MatTableDataSource<packageData>
    displayedColumns: string[] = [
        'Package Code',
        'SaleOrderNo',
        'Date',
        'isComfirmed',
        'actions',
    ];

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    dataSource: MatTableDataSource<packageData>;


    constructor(
        private _router: Router,
        private dialog: MatDialog,
        private _packageService: PackagesOderService
    ) { }


    ngOnInit(): void {
        if (this.dataTableSource) {
            this.getPackagess()
        }
    }

    // Handler for page change event
    onPageChange(event: any): void {
        this.pageSize = event.pageSize;
        this.pageIndex = event.pageIndex;
    }

    // Calculate the current page of data based on the pageSize and pageIndex
    getPaginatedData(): any[] {
        const startIndex = this.pageIndex * this.pageSize;
        const endIndex = startIndex + this.pageSize;
        return this.filteredPackageData.slice(startIndex, endIndex);
    }
    getPackagess = (): void => {
        this.filteredPackageData = this.dataTableSource['data'].filter((row) => (
            row.isPackaged && !row.isShipped && !row.isDelivared
        ));
        this.patchFilteredData(this.filteredPackageData);
    }

    toggleSelectAll(): void {
        this.selectedRowIds = []; // Clear the selected IDs array
        this.filteredPackageData.forEach(data => {
            data.isSelected = this.selectAll;
            if (this.selectAll) {
                this.selectedRowIds.push(data.id); // Add the ID to the selected IDs array
            }

        });

    }

    toggleRowSelection(data): void {
        if (this.selectedRowIds.includes(data.id)) {
            if (!data.isSelected) {
                const index = this.selectedRowIds.indexOf(data.id);
                if (index !== -1) {
                    this.selectedRowIds.splice(index, 1); // Remove the ID from the selected IDs array
                    this.selectAll = this.filteredPackageData.every(row => row.isSelected);
                }
            }
        } else {
            if (data.isSelected) {
                this.selectedRowIds.push(data.id); // Add the ID to the selected IDs array
            }
        }
    }


    patchFilteredData(filteredData: any[]): void {
        // You can update other parts of your application here using a loop
        // For example:
        for (const data of filteredData) {
            const lastname = data.salesOrder.Customers.lastName;

            this.customerName = data.salesOrder.Customers.firstName + " " + lastname;

            this.SlaesOrderNumber = data.salesOrder.sales_order_num;
            this.total = data.salesOrder.total;
            this.packagedDate = data.date;
            this.PackageCode = data.packageSlip;
            this.packageId = data.id;
        }
    }
    ngAfterViewInit() {
        setTimeout(() => {
            if (this.dataSource) {
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
            }
        }, 400);
    }

    showDetails(id: any) {
        this._router.navigate(['apps/packages', id]);
    }

    openPackage(id: any) {
        const dialogRef = this.dialog.open(ShippedPopupComponent, {
            width: '800px',
            data: { id }, // Adjust the width as per your requirements
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result && result.saved === true) {
                this.reloadComponent()

            } else {
            }
        });
    }

    reloadComponent() {
        // Get the current route URL and navigate to it
        this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this._router.navigate(['apps/packages']);
        });
    }


    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    ngOnDestroy(): void {
        // clearTimeout(this.delayTimeout);
        // throw new Error('Method not implemented.');
    }

    async openPackages() {
        try {
            const ids = this.selectedRowIds.map(id => id.toString());
            if (ids.length === 0) {
                Swal.fire({
                    icon: 'error',
                    title: 'Package Order Shipment',
                    text: "Error Shipping the Packages",
                });

                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer);
                        toast.addEventListener('mouseleave', Swal.resumeTimer);
                    },
                });

                Toast.fire({
                    icon: 'error',
                    title: `Select Package to be Shipped`,
                });
                return;
            }

            const allPackageCollection = [];

            // Use Promise.all to wait for all asynchronous operations to complete
            await Promise.all(ids.map(async (id) => {
                const data = await this._packageService.getPackageOderByID(id).toPromise();
                allPackageCollection.push(data);
            }));

            // After all requests are completed, call creatingPackages and reloadComponent
            this.creatingPackages(allPackageCollection);
            this.reloadComponent();



        } catch (e) {
            console.log(e, "e");
        }
    }

    creatingPackages = async (allPackageCollection) => {
        const rows = allPackageCollection.map((data: any) => {
            const packageOrder = data.packageOrder;
            const packageDate = data.packageDate;
            const salesOrder = data.salesOrder.id;
            const isPackaged = data.isPackaged; // set isPackage to true as it is confirmed
            const isShipped = true; // set isPackage to true as it is confirmed
            const infulencerOrder = ''; // while creating the salesOder package, influencer order is empty
            const id = data.id
            const product = data.product
            return {
                packageDate,
                // shipOrder,
                packageOrder,
                salesOrder,
                isPackaged,
                isShipped,
                infulencerOrder,
                id,
                product
            };
        });

        for (const row of rows) {
            const packageResult = await this.createPackageOrder(row.id, row);
            if (packageResult.success) {
                // Show success notification using Swal
                Swal.fire({
                    icon: 'success',
                    title: 'Package Order shipped!',
                    text: packageResult.message,
                });

                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer);
                        toast.addEventListener('mouseleave', Swal.resumeTimer);
                    },
                });

                Toast.fire({
                    icon: 'success',
                    title: `${row.packageOrder} Package shipped Success`,
                });
            } else {
                // Show error notification using Swal
                Swal.fire({
                    icon: 'error',
                    title: 'Error shipping Package',
                    text: JSON.stringify(packageResult.errors),
                });
            }
        }

    }

    async createPackageOrder(id: any, packageData: any): Promise<any> {
        try {
            const response = await this._packageService.updatePackageOrder(id, packageData);
            if (response.success) {
                return { success: true, message: `Package Order created successfully.` };
            } else {
                return { success: false, errors: response.errors };
            }
        } catch (error) {
            throw new Error(`Error creating Package Order: ${error.message}`);
        }
    }
}



