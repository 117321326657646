import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inventroy-sheet',
  templateUrl: './inventroy-sheet.component.html',
  styleUrls: ['./inventroy-sheet.component.scss']
})
export class InventroySheetComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
