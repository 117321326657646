<div class="w-full h-full">

    <mat-drawer-container class="flex-auto h-full  dark:bg-transparent">


        <mat-drawer class="w-full md:w-160 dark:bg-gray-900 p-5" [mode]="drawerMode" [opened]="false" [position]="'end'"
            [disableClose]="true" #matDrawer>
            <app-customer-detial-mat-drawer [id]="selectedCustomer?.id" (closeDrawerEvent)="onDrawerClose()">
            </app-customer-detial-mat-drawer>
        </mat-drawer>
        <mat-drawer-content>

            <form [formGroup]="salesOrderForm" class="rounded shadow-lg h-full"
                (keydown.enter)="$event.preventDefault()">


                <div *ngIf="salesOrderForm" class="p-8">
                    <div class="flex justify-end">
                        <button mat-raised-button [routerLink]="['..']">
                            <span class="inline-flex items-center">
                                <mat-icon svgIcon="feather:x"></mat-icon>
                            </span>
                        </button>
                    </div>

                    <div class="flex flex-col mt-8">
                        <p class="text-lg font-medium" style="font-family: Montserrat">
                            Basic information
                        </p>
                        <p class="text-secondary" style="font-family: Montserrat">
                            Set your Sales Order Here
                        </p>
                        <mat-divider class="mt-8 mb-10"></mat-divider>





                        <div class="flex mb-4">
                            <mat-form-field style="max-width: 600px"
                                class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix">
                                <mat-label class="ml-3">Customer</mat-label>
                                <input style="max-height: 10px;" class="small-input-text" #customerInput required
                                    matInput type="text" placeholder="Search Customers" [formControl]="CustomerControl"
                                    aria-label="Customers" [matAutocomplete]="auto" [readonly]='isFieldReadOnly'
                                    [class.selected-option]="isCustomerSelected(selectedCustomer)"
                                    [invalid]="(filteredCustomers$ | async)?.length === 0 && formSubmitted" />

                                <button *ngIf="isFieldReadOnly" mat-icon-button (click)="makeFieldEditable()">
                                    <mat-icon class="icon-size-4" svgIcon="feather:edit"></mat-icon>
                                </button>

                                <button type="button" *ngIf="isFieldReadOnly" class="ml-3" matTooltip="View Sales Order"
                                    (click)="openDrawer()" style="display: flex; align-items: center;">
                                    <mat-icon class="icon-size-4" svgIcon="heroicons_outline:eye"></mat-icon>
                                </button>
                                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayOption"
                                    (optionSelected)="selectOption($event.option.value)">
                                    <mat-option *ngIf="formSubmitted" (click)="openCustomerPopup()">


                                        Add Customer<mat-icon svgIcon="feather:plus"></mat-icon>
                                    </mat-option>

                                    <mat-option style="height: fit-content;"
                                        *ngIf="(filteredCustomers$ | async)?.length === 0 && formSubmitted">
                                        <strong style="color: red;">NO CUSTOMER FOUND</strong> <br /><span>You can add
                                            customer,
                                            By clicking on button above</span>
                                    </mat-option>


                                    <mat-option style="height: fit-content"
                                        *ngFor="let customer of filteredCustomers$ | async" [value]="customer">
                                        <div style="display: flex; align-items: center">
                                            <span class="example-option-name">{{ customer.firstName }} {{
                                                customer.lastName}} (HUB{{customer.id}})</span>
                                        </div>
                                    </mat-option>

                                </mat-autocomplete>
                                <mat-icon class="icon-size-4" matPrefix [svgIcon]="'heroicons_solid:user'"></mat-icon>



                            </mat-form-field>
                        </div>




                        <div class="flex mb-2">
                            <mat-form-field style="max-width: 600px"
                                class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix">
                                <mat-label class="ml-3">Sales Order#</mat-label>
                                <input readonly matInput placeholder="Sales Order Number" required
                                    name="SalesOrerNumber" [formControlName]="'sales_order_num'"
                                    class="elevated-input small-input-text" [value]="" />
                                <mat-icon class="icon-size-4" matPrefix [svgIcon]="'feather:hash'"></mat-icon>
                            </mat-form-field>
                        </div>

                        <mat-divider class="mt-3 mb-5"></mat-divider>
                        <!-- Point of sales  -->
                        <div class="flex mb-2">
                            <mat-form-field style="max-width: 600px"
                                class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix">
                                <mat-label class="ml-3">Point of Sales</mat-label>
                                <mat-select [ngClass]="formFieldHelpers" placeholder="Point Of Sales"
                                    (selectionChange)="pointOfsales()" name="pos" [formControlName]="'pos'"
                                    class="elevated-input" required>
                                    <mat-option value="Instagram">INSTAGRAM</mat-option>
                                    <mat-option value="Facebook">FACEBOOK</mat-option>
                                    <mat-option value="Website">WEBSITE</mat-option>
                                    <mat-option value="Office Vist">OFFICE VISIT</mat-option>
                                </mat-select>
                                <mat-icon class="icon-size-4" matPrefix [svgIcon]="'point_of_sale'"></mat-icon>

                                <mat-error class="m-1 ml-5 mt-2"
                                    *ngIf="salesOrderForm?.get('pos')?.invalid && salesOrderForm?.get('pos')?.touched">
                                    <ng-container *ngIf="salesOrderForm?.get('pos')?.hasError('required')">
                                        POS is required.
                                    </ng-container>
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="flex mb-2" *ngIf="showSocailLink">
                            <mat-form-field style="max-width: 600px"
                                class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix">
                                <mat-label>Email</mat-label>
                                <input [ngClass]="formFieldHelpers" matInput placeholder="Email" type="email"
                                    name="email" [formControlName]="'email'" class="elevated-input small-input-text" />
                                <mat-icon class="icon-size-4" matPrefix [svgIcon]="'feather:hash'"></mat-icon>
                            </mat-form-field>
                        </div>

                        <div class="flex mb-2" *ngIf="showEmail">
                            <mat-form-field [ngClass]="formFieldHelpers" style="max-width: 600px"
                                class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix">
                                <mat-label class="ml-3">Social Link</mat-label>
                                <input matInput placeholder="Social Link" name="social_link"
                                    aria-label="social-media-link" [formControlName]="'soical_link'"
                                    class="elevated-input small-input-text" />
                                <mat-icon class="icon-size-4" matPrefix [svgIcon]="'feather:hash'"></mat-icon>
                            </mat-form-field>
                        </div>

                        <div class="flex mb-2" *ngIf="showPaymentMethod">
                            <mat-form-field style="max-width: 600px"
                                class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix">
                                <mat-label class="ml-3">Payment Type </mat-label>
                                <mat-select [ngClass]="formFieldHelpers" placeholder="Payment Method" name="paymentType"
                                    [formControlName]="'payment_type'" class="elevated-input">
                                    <mat-option *ngFor="let option of paymentOptions" [value]="option">{{ option
                                        }}</mat-option>
                                </mat-select>
                                <mat-icon class="icon-size-4" matPrefix [svgIcon]="'payments'"></mat-icon>
                                <mat-error class="m-1 ml-5 mt-2"
                                    *ngIf="salesOrderForm?.get('payment_type')?.invalid && salesOrderForm?.get('payment_type')?.touched">
                                    <ng-container *ngIf="salesOrderForm?.get('payment_type')?.hasError('required')">
                                        Payment Type is required.
                                    </ng-container>
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <!-- Shipping area Form  -->

                        <div class="flex mb-2" *ngIf="showShippingArea">
                            <mat-form-field style="max-width: 600px"
                                class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix">
                                <mat-label class="ml-3">Shipping Area </mat-label>
                                <mat-select [ngClass]="formFieldHelpers" placeholder="Shipping Area"
                                    name="Shipping Area" (selectionChange)="addressField()"
                                    [formControlName]="'shipping_area'" class="elevated-input"
                                    [(ngModel)]="selectedShippingType">
                                    <mat-option value="Inside Valley">INSIDE VALLEY</mat-option>
                                    <mat-option value="Outside Valley">OUTSIDE VALLEY</mat-option>
                                    <mat-option value="International">INTERNATIONAL</mat-option>
                                </mat-select>
                                <mat-icon class="icon-size-4" matPrefix [svgIcon]="'feather:truck'"></mat-icon>

                                <mat-error class="m-1 ml-5 mt-2"
                                    *ngIf="salesOrderForm?.get('shipping_area')?.invalid && salesOrderForm?.get('shipping_area')?.touched">
                                    <ng-container *ngIf="salesOrderForm?.get('shipping_area')?.hasError('required')">
                                        Shipping Area is required.
                                    </ng-container>
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="flex mb-2" *ngIf="selectedShippingType === 'Inside Valley'">
                            <mat-form-field style="max-width: 600px"
                                class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix">
                                <mat-label class="ml-3">District</mat-label>
                                <mat-select placeholder="Select District" name="District"
                                    [formControl]="districtControl" class="elevated-input small-input-text">
                                    <mat-option *ngFor="let district of ['KATHMANDU', 'LALITPUR', 'BHAKTAPUR']"
                                        [value]="district">
                                        {{ district }}
                                    </mat-option>
                                </mat-select>
                                <mat-icon class="icon-size-4" matPrefix [svgIcon]="'location_city'"></mat-icon>
                            </mat-form-field>

                        </div>


                        <div class="flex mb-2" *ngIf="selectedShippingType == 'Outside Valley'">

                            <mat-form-field style="max-width: 600px "
                                class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix">
                                <mat-label class="ml-3">District</mat-label>
                                <input matInput placeholder="District" name="District" [formControl]="districtControl"
                                    [readonly]="isFieldReadOnly_outside_val" class="elevated-input small-input-text"
                                    [matAutocomplete]="auto" />

                                <button *ngIf="isFieldReadOnly_outside_val" mat-icon-button
                                    (click)="makeFieldEditable_outside_val()">
                                    <mat-icon class="icon-size-4" svgIcon="feather:edit"></mat-icon>
                                </button>

                                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onDistrictSelected($event)">
                                    <mat-option style="font-family: Montserrat"
                                        *ngFor="let district of filteredDistricts | async"
                                        [value]="district.toUpperCase()">
                                        <span style="font-family: Montserrat">{{ district.toUpperCase() }}</span>
                                    </mat-option>
                                </mat-autocomplete>
                                <mat-icon class="icon-size-4" matPrefix [svgIcon]="'location_city'"></mat-icon>
                            </mat-form-field>

                        </div>


                        <div class="flex mb-2" *ngIf="showDistrict">
                            <mat-form-field style="max-width: 600px"
                                class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix">
                                <mat-label class="ml-3">Delivery Address</mat-label>
                                <input matInput placeholder="Delivery Address" [formControlName]="'address'"
                                    name="address" class="elevated-input small-input-text"
                                    ngx-google-places-autocomplete [options]="options"
                                    (onAddressChange)="handleAddressChange($event)" value="formattedaddress" />
                                <mat-icon class="icon-size-4" matPrefix [svgIcon]="'location_city'"></mat-icon>
                            </mat-form-field>
                        </div>


                        <div class="flex mb-2" *ngIf="showShippinginvalley">
                            <mat-form-field style="max-width: 600px"
                                class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix">
                                <mat-label class="ml-3">Shipping Carrier </mat-label>
                                <ng-container
                                    *ngIf="salesOrderForm.get('shipping_carrier_invalley').value !== 'others'; else otherInputss">
                                    <mat-select [ngClass]="formFieldHelpers" placeholder="Shipping Carrier"
                                        name="shipping_carrier_invalley" (selectionChange)="addressField()"
                                        [formControlName]="'shipping_carrier_invalley'" class="elevated-input">
                                        <mat-option value="Dash logistics">DASH LOGISTICS</mat-option>
                                        <mat-option value="In Driver">IN DRIVER</mat-option>
                                        <mat-option value="Pathao">PATHAO</mat-option>
                                        <mat-option value="others">OTHERS</mat-option>
                                    </mat-select>

                                </ng-container>

                                <ng-template #otherInputss>
                                    <input [ngClass]="formFieldHelpers" matInput placeholder="Other Shipping Carrier"
                                        formControlName="shipping_carrier_invalley_others"
                                        class="elevated-input small-input-text fuse-mat-rounded fuse-mat-emphasized-affix"
                                        name="others" />
                                    <mat-icon-button class="icon-size-4" (click)="toggleInputss()">
                                        <mat-icon [svgIcon]="'feather:skip-back'"></mat-icon>
                                    </mat-icon-button>
                                </ng-template>



                                <mat-icon class="icon-size-4" matPrefix [svgIcon]="'feather:truck'"></mat-icon>

                            </mat-form-field>
                        </div>


                        <div class="flex mb-2" *ngIf="showShippingOutvalley">
                            <mat-form-field style="max-width: 600px"
                                class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix">
                                <mat-label class="ml-3">Shipping Carrier Out Valley</mat-label>
                                <mat-icon class="icon-size-4" matPrefix [svgIcon]="'feather:truck'"></mat-icon>
                                <ng-container
                                    *ngIf="salesOrderForm.get('shipping_carrier_outvalley').value !== 'others'; else otherInputs">
                                    <mat-select [ngClass]="formFieldHelpers" placeholder="shipping Carrier"
                                        name="shipping_carrier_outvalley" (selectionChange)="addressField()"
                                        [formControlName]="'shipping_carrier_outvalley'"
                                        class="elevated-input small-input-text">
                                        <mat-option value="Dash logistics">DASH LOGISTICS</mat-option>
                                        <mat-option value="Dash logistics (Air)">DASH LOGISTICS(AIR)</mat-option>

                                        <mat-option value="By Air">BY AIR</mat-option>
                                        <mat-option value="others">OTHERS</mat-option>
                                    </mat-select>


                                </ng-container>

                                <ng-template #otherInputs>


                                    <input matInput placeholder="Other Shipping Carrier" formControlName="others_out"
                                        class="elevated-input small-input-text fuse-mat-rounded fuse-mat-emphasized-affix"
                                        name="others" />
                                    <mat-icon-button class="icon-size-4" (click)="toggleInputs()">
                                        <mat-icon [svgIcon]="'feather:skip-back'"></mat-icon>
                                    </mat-icon-button>
                                </ng-template>
                            </mat-form-field>
                        </div>



                        <div class="flex mb-2" *ngIf="shwoShippingInternational">
                            <mat-form-field style="max-width: 600px"
                                class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix">
                                <mat-label class="ml-3">Shipping Carrier International</mat-label>

                                <ng-container
                                    *ngIf="salesOrderForm.get('shipping_carrier_int').value !== 'others'; else otherInput">
                                    <mat-select [ngClass]="formFieldHelpers" placeholder="Shipping Carrier"
                                        name="shipping_carrier_int" formControlName="shipping_carrier_int"
                                        class="elevated-input">
                                        <mat-option value="DASH LOGISTICS">DASH LOGISTICS</mat-option>

                                        <mat-option value="ARAMEX">ARAMEX</mat-option>
                                        <mat-option value="others">OTHERS</mat-option>
                                    </mat-select>
                                </ng-container>

                                <ng-template #otherInput>

                                    <input [ngClass]="formFieldHelpers" matInput placeholder="Other Shipping Carrier"
                                        formControlName="others"
                                        class="elevated-input fuse-mat-rounded fuse-mat-emphasized-affix"
                                        name="others" />
                                    <mat-icon-button class="icon-size-4" (click)="toggleInputBack()">
                                        <mat-icon [svgIcon]="'feather:skip-back'"></mat-icon>
                                    </mat-icon-button>

                                </ng-template>

                                <mat-icon class="icon-size-4" matPrefix [svgIcon]="'feather:truck'"></mat-icon>
                            </mat-form-field>
                        </div>



                        <div class="flex mb-2" *ngIf="showOthers">
                            <mat-form-field style="max-width: 600px"
                                class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix">
                                <mat-label class="ml-3">Others </mat-label>
                                <input [ngClass]="formFieldHelpers" matInput placeholder="Others" name="others"
                                    [formControlName]="'others'" class="elevated-input small-input-text" />
                                <mat-icon class="icon-size-4" matPrefix [svgIcon]="'location_city'"></mat-icon>
                            </mat-form-field>
                        </div>

                        <div class="flex mb-2" *ngIf="showCountry">
                            <mat-form-field style="max-width: 600px"
                                class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix">
                                <mat-label class="ml-3">Country</mat-label>
                                <input [ngClass]="formFieldHelpers" matInput placeholder="Country" name="country"
                                    [formControl]="countryControl" class="elevated-input small-input-text"
                                    [matAutocomplete]="auto" />
                                <mat-autocomplete #auto="matAutocomplete">
                                    <mat-option style="font-family: Montserrat" *ngFor="
                                    let country of filteredCountries | async
                                " [value]="country">
                                        <span style="font-family: Montserrat">{{
                                            country
                                            }}</span>
                                    </mat-option>
                                </mat-autocomplete>
                                <mat-icon class="icon-size-4" matPrefix [svgIcon]="'location_city'"></mat-icon>
                            </mat-form-field>
                        </div>

                        <div class="flex mb-2" *ngIf="showDeliveryDetails">
                            <mat-form-field style="max-width: 600px; margin: 0"
                                class="fuse-mat-textarea flex-auto fuse-mat-rounded fuse-mat-emphasized-affix">
                                <mat-label>Delivery Details</mat-label>
                                <textarea [ngClass]="formFieldHelpers" matInput placeholder="Details"
                                    [formControlName]="'delivery_detail'" name="delivery_detail" [rows]="3"
                                    class="elevated-input small-input-text"></textarea>
                                <mat-icon class="icon-size-4" matPrefix
                                    [svgIcon]="'heroicons_solid:menu-alt-2'"></mat-icon>
                            </mat-form-field>
                        </div>

                        <mat-divider class="mt-3 mb-5"></mat-divider>
                        <!-- Ends here -->

                        <div class="flex mb-2">
                            <mat-form-field style="max-width: 600px"
                                class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix">
                                <mat-label class="ml-3">Reference</mat-label>
                                <input [ngClass]="formFieldHelpers" matInput placeholder="Refrences" name="refrences"
                                    [formControlName]="'refrences'" class="elevated-input small-input-text" />
                                <mat-icon class="icon-size-4" matPrefix [svgIcon]="'feather:hash'"></mat-icon>
                            </mat-form-field>
                        </div>
                        <div class="flex mb-2">
                            <mat-form-field style="max-width: 600px"
                                class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix">
                                <mat-label class="ml-3">Delivery Number</mat-label>
                                <input required [ngClass]="formFieldHelpers" matInput placeholder="Delivery Number"
                                    name="Delivery Number" [formControlName]="'delivery_number'"
                                    class="elevated-input small-input-text" pattern="^[0-9-]*$"
                                    (input)="onInput($event)" />
                                <mat-icon class="icon-size-4" matPrefix [svgIcon]="'feather:phone'"></mat-icon>
                            </mat-form-field>


                        </div>
                        <!-- Additional Fields -->
                        <div class="flex mb-2">
                            <mat-form-field style="max-width: 600px"
                                class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix">
                                <mat-label class="ml-3">Sales Order Date</mat-label>
                                <input [ngClass]="formFieldHelpers" matInput [matDatepicker]="picker2"
                                    placeholder="Sales order Date" name="slaes_order_date"
                                    [formControlName]="'slaes_order_date'" class="elevated-input small-input-text"
                                    required (dateChange)="onSalesOrderDateChange($event)" />

                                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                <mat-datepicker #picker2></mat-datepicker>
                                <mat-icon class="icon-size-4" matPrefix
                                    [svgIcon]="'heroicons_solid:calendar'"></mat-icon>
                            </mat-form-field>
                        </div>

                        <div class="flex mb-2" *ngIf="showExpectedShippingDate">
                            <mat-form-field style="max-width: 600px"
                                class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix">
                                <mat-label class="ml-3">Expected Shipment Date</mat-label>
                                <input [ngClass]="formFieldHelpers" matInput [matDatepicker]="picker3"
                                    placeholder="Expected Shipment Date" name="expected_shippmentDate"
                                    [formControlName]="'sales_order_shippmentDate'"
                                    class="elevated-input small-input-text" (dateChange)="
                                formatDate(
                                    $event.value,
                                    'yyyy-MM-dd',
                                    'sales_order_shippmentDate'
                                )
                            " />
                                <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                                <mat-datepicker #picker3></mat-datepicker>

                                <mat-icon class="icon-size-4" matPrefix
                                    [svgIcon]="'heroicons_solid:calendar'"></mat-icon>
                            </mat-form-field>
                        </div>

                        <div class="flex mb-2" *ngIf="showDeliveryType">
                            <mat-form-field style="max-width: 600px"
                                class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix">
                                <mat-label class="ml-3">Delivery Type</mat-label>
                                <mat-select [ngClass]="formFieldHelpers" placeholder="Delivery Type" name="Tax"
                                    [formControlName]="'delivery_type'" class="elevated-input" required>
                                    <mat-option value="Normal">NORMAL</mat-option>
                                    <mat-option value="Express">EXPRESS</mat-option>
                                </mat-select>
                                <mat-icon class="icon-size-4" matPrefix [svgIcon]="'delivery_dining'"></mat-icon>
                            </mat-form-field>
                        </div>
                        <mat-divider class="mt-4 mb-5"></mat-divider>

                        <div class="flex mb-2">
                            <mat-form-field style="max-width: 600px"
                                class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix">
                                <mat-label class="ml-3">Salesperson</mat-label>
                                <input [ngClass]="formFieldHelpers" matInput placeholder="Sales Person" name="Tax"
                                    [formControlName]="'sales_person'" class="elevated-input small-input-text"
                                    [value]="user.username.toUpperCase()" readonly>
                                <mat-icon class="icon-size-4" matPrefix [svgIcon]="'feather:user'"></mat-icon>
                            </mat-form-field>
                        </div>



                        <!-- Add Products -->

                        <mat-divider class="mt-8 mb-3"></mat-divider>



                        <ng-container
                            *ngFor="let product of salesOrderForm.get('products')['controls']; let i = index; let first = first; let last = last; trackBy: trackByFn">
                            <mat-checkbox *ngIf="!active[i]" class="mat-checkbox mb-2 p-1"
                                [ngClass]="{'selected': filterByBundle[i]}" (change)="toggleFilterByBundle(i)">Bundle
                            </mat-checkbox>

                            <div class="flex" [ngClass]="{'fadeInOut': animationFlags[i]}">
                                <!-- Log the values -->


                                <ng-container *ngIf="filterByBundle[i]">
                                    <div class="form-field-container">

                                        <mat-form-field
                                            class="fuse-mat-no-subscript w-200  fuse-mat-rounded fuse-mat-emphasized-affix ">

                                            <mat-label *ngIf="first">Product Bundle</mat-label>
                                            <mat-error *ngIf="match_stock[i]">
                                                No stock </mat-error>
                                            <input [readonly]="active[i]" class="small-input-text" style="height: 50px"
                                                matInput [formControl]="product.get('product')"
                                                placeholder="Type  Bundle Name" required [matAutocomplete]="auto"
                                                [ngStyle]="product.style"
                                                [attr.disabled]="product.disabled ? true : null" (input)="
                                        filter($event.target.value)
                                    " (focus)="filterProductBundle($event.target.value)" />

                                            <mat-icon *ngIf="active[i]" class="icon-size-5" matSuffix
                                                [svgIcon]="'verified'"
                                                style="font-size: 24px; color: green;"></mat-icon>


                                            <mat-autocomplete #auto="matAutocomplete" [displayWith]="
                                displayProductBundleOption.bind(this)
                                        " (optionSelected)="
                                        selectOptionProductBundle(
                                                $event.option.value,
                                                i
                                            )
                                        ">
                                                <mat-option
                                                    style="height: auto; display: flex; align-items: center; padding: 4px 16px;"
                                                    *ngFor="let option of filteredProductBundleOptions$ | async; let i = index"
                                                    [value]="option" [ngStyle]="option.style"
                                                    [disabled]="option.disabled">

                                                    <div style="
                                                    display: flex;
                                                    align-items: center;
                                                ">

                                                        <span class="example-option-name">{{
                                                            option.name
                                                            }}</span>
                                                        <span class="example-option-sku">
                                                            - {{ option.description }}</span>
                                                    </div>
                                                </mat-option>

                                                <mat-option style="
                                                cursor: pointer;
                                                height: fit-content;
                                            " (click)="openProductDetails()" *ngIf="
                                                (filteredProductBundleOptions$ | async)
                                                    ?.length === 0
                                            " [disabled]="true">
                                                    No options found
                                                    <br />
                                                    <mat-icon svgIcon="feather:plus">
                                                    </mat-icon>
                                                    Add Product
                                                </mat-option>
                                            </mat-autocomplete>



                                        </mat-form-field>

                                        <mat-expansion-panel class="w-120 mt-1 ml-3 mb-2" *ngIf="active[i]">
                                            <mat-expansion-panel-header>
                                                <mat-panel-title style="font-size: smaller; font-family: Montserrat;">
                                                    Products in Bundle
                                                </mat-panel-title>
                                            </mat-expansion-panel-header>
                                            <div class="m-2 align-bottom">
                                                <ul>
                                                    <!-- List items for each product -->
                                                    <li style="font-size: smaller; font-family: Montserrat;"
                                                        *ngFor="let product of name[i]; let j = index">
                                                        <div class="divider"></div>
                                                        <strong>{{ product.name }}</strong> - Size: {{ product.size }} -
                                                        Color: {{ product.color }} - Rate: {{ product.rate }} - Qty: {{
                                                        product.quantity }}
                                                    </li>
                                                </ul>
                                            </div>
                                        </mat-expansion-panel>

                                    </div>
                                </ng-container>




                                <ng-container *ngIf="!filterByBundle[i]">
                                    <mat-form-field
                                        class="fuse-mat-no-subscript w-200  fuse-mat-rounded fuse-mat-emphasized-affix mr-2">

                                        <mat-label *ngIf="first">Product</mat-label>



                                        <input [ngClass]="formFieldHelpers" [readonly]="productActive[i]"
                                            class="small-input-text" style="height: 50px" matInput
                                            [formControl]="product.get('product')"
                                            placeholder="Type SKU or Product Name" required [matAutocomplete]="auto"
                                            [ngStyle]="product.style" [attr.disabled]="product.disabled ? true : null"
                                            (input)="
                                    filterOptionsProduct($event.target.value)"
                                            (focus)="filterOptionsProduct($event.target.value)"
                                            [id]="'product-input-' + i" />





                                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="
                        displayProductOption.bind(this)
                    " (optionSelected)="
                        selectOptionProduct(
                            $event.option.value,
                            i
                        )
                    ">
                                            <mat-option
                                                style="height: auto; display: flex; align-items: center; padding: 4px 16px;"
                                                *ngFor="let option of filteredProductOptions$ | async; let i = index"
                                                [value]="option" [ngStyle]="option.style" [disabled]="option.disabled">

                                                <div style="
                                display: flex;
                                align-items: center;
                            ">

                                                    <span class="example-option-name">{{
                                                        option.productName
                                                        }}</span>
                                                    <span class="example-option-sku">
                                                        - {{ option.sku }}</span>
                                                </div>
                                            </mat-option>

                                            <mat-option style="
                            cursor: pointer;
                            height: fit-content;
                        " (click)="openProductDetails()" *ngIf="
                            (filteredProductOptions$ | async)
                                ?.length === 0
                        " [disabled]="true">
                                                No options found
                                                <br />
                                                <mat-icon svgIcon="feather:plus">
                                                </mat-icon>
                                                Add Product
                                            </mat-option>
                                        </mat-autocomplete>

                                        {{product_details[i]}}




                                        <button type="button" matSuffix *ngIf="productActive[i]" mat-icon-button
                                            (click)="makeFieldEditableProduct(i)">


                                            <mat-icon *ngIf="productActive[i]" class="icon-size-5"
                                                [svgIcon]="'feather:edit'"
                                                style="font-size: 24px; color: red;"></mat-icon>
                                        </button>

                                    </mat-form-field>


                                </ng-container>

                                <ng-container *ngIf="!active[i]">
                                    <mat-form-field
                                        class="fuse-mat-no-subscript w-50 fuse-mat-rounded fuse-mat-emphasized-affix mr-2">
                                        <mat-label *ngIf="first">Size</mat-label>
                                        <mat-icon class="icon-size-4" matPrefix svgIcon="format_size"></mat-icon>
                                        <mat-select matInput [formControl]="product.get('size')" placeholder="Size"
                                            class="h-20" (selectionChange)="onSelectionChange(i, true)">
                                            <mat-option *ngFor="let size of sizes[i]" [value]="size.id"
                                                [disabled]="size.disabled">{{ size.name }}</mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="match_stock[i]">No stock</mat-error>
                                    </mat-form-field>

                                    <mat-form-field
                                        class="fuse-mat-no-subscript w-50 fuse-mat-rounded fuse-mat-emphasized-affix mr-2">
                                        <mat-label *ngIf="first">Color</mat-label>
                                        <mat-icon class="icon-size-4" matPrefix svgIcon="color_lens"></mat-icon>
                                        <mat-error *ngIf="match_stock[i]">No stock</mat-error>
                                        <mat-select matInput [formControl]="product.get('color')" placeholder="Color"
                                            class="h-20" (selectionChange)="onSelectionChange(i, true)">
                                            <mat-option *ngFor="let color of colors[i]" [value]="color.id"
                                                [disabled]="color.disabled">{{ color.name }}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </ng-container>


                                <ng-container *ngIf="active[i]">
                                    <!-- Render a readonly input or display the selected value when active is true -->
                                    <mat-form-field
                                        class="fuse-mat-no-subscript w-50 fuse-mat-rounded fuse-mat-emphasized-affix mr-2">
                                        <mat-icon class="icon-size-4" matPrefix svgIcon="format_size"></mat-icon>

                                        <mat-label *ngIf="first">Size</mat-label>
                                        <input class="small-input-text" matInput [formControl]="product.get('size')"
                                            placeholder="Size" style="height: 50px" class="small-input-text" readonly />


                                    </mat-form-field>

                                    <mat-form-field
                                        class="fuse-mat-no-subscript w-50 fuse-mat-rounded fuse-mat-emphasized-affix mr-2">
                                        <mat-label *ngIf="first">Color</mat-label>
                                        <mat-icon class="icon-size-4" matPrefix svgIcon="color_lens"></mat-icon>

                                        <input class="small-input-text" matInput [formControl]="product.get('color')"
                                            placeholder="Color" style="height: 50px" readonly />
                                    </mat-form-field>
                                </ng-container>


                                <div class="form-field-container">
                                    <mat-form-field [ngClass]="formFieldHelpers"
                                        class="fuse-mat-no-subscript w-25 fuse-mat-rounded fuse-mat-emphasized-affix mr-2">
                                        <mat-label *ngIf="first">Quantity</mat-label>

                                        <mat-icon class="icon-size-4" matPrefix
                                            svgIcon="mat_solid:production_quantity_limits">
                                        </mat-icon>
                                        <input class="small-input-text" style="height: 50px" matInput type="text"
                                            onkeypress="return event.charCode>=48 && event.charCode<=57"
                                            [formControl]="product.get('quantity')" placeholder="Quantity"
                                            [max]="current_stock[i]" />

                                        <mat-error *ngIf="quantityExceedsStock(i)">
                                            Quantity cannot exceed available stock.
                                        </mat-error>
                                    </mat-form-field>

                                    <!-- Display current_stock below the form field -->
                                    <div *ngIf="active[i]" class="current-stock-container m-2">
                                        <span [ngClass]="{
                                        'chip chip--success': current_stock[i] > 10,
                                        'chip chip--warning': current_stock[i] <= 10 && current_stock[i] > 5,
                                        'chip chip--error': current_stock[i] <= 5
                                    }" style="line-height: 16px;">

                                            <i style="font-size: 11px; margin-top: -2px;" class="chip__label">
                                                <strong>Available stock: {{ current_stock[i] }} </strong>
                                            </i>
                                        </span>
                                    </div>
                                </div>


                                <mat-form-field [ngClass]="formFieldHelpers"
                                    class="fuse-mat-no-subscript w-50 fuse-mat-rounded fuse-mat-emphasized-affix mr-2 ">
                                    <mat-label *ngIf="first">Rate</mat-label>

                                    <input [readonly]="active[i]" class="small-input-text" readonly style="height: 50px"
                                        matInput type="text"
                                        onkeypress="return event.charCode>=48 && event.charCode<=57"
                                        [formControl]="product.get('rate')" placeholder="Rate" />
                                    <span matPrefix>रु</span>
                                </mat-form-field>

                                <mat-form-field [ngClass]="formFieldHelpers"
                                    class="fuse-mat-no-subscript w-50 fuse-mat-rounded fuse-mat-emphasized-affix ">
                                    <mat-label *ngIf="first">Amount</mat-label>
                                    <input readonly class="small-input-text" style="height: 50px" matInput type="text"
                                        onkeypress="return event.charCode>=48 && event.charCode<=57"
                                        [formControl]="product.get('amount')" placeholder="Amount" />
                                    <span matPrefix>रु</span>
                                </mat-form-field>

                                <!-- Remove product -->
                                <ng-container *ngIf="!(first && last)">
                                    <div class="flex items-center w-10 pl-2">
                                        <button mat-icon-button type="button" (click)="removeProductField(i)"
                                            matTooltip="Remove">
                                            <mat-icon class="icon-size-4"
                                                [svgIcon]="'heroicons_solid:trash'"></mat-icon>
                                        </button>
                                    </div>
                                </ng-container>



                            </div>



                        </ng-container>

                        <div class="inline-flex items-center mt-1 -ml-4 py-2 px-4">
                            <mat-icon matPrefix class="hidden sm:flex icon-size-4" [svgIcon]="'heroicons_solid:tag'">
                            </mat-icon>
                            <span (click)="addProductField()"
                                class="ml-2 font-medium text-secondary cursor-pointer hover:underline">Add a
                                field</span>
                        </div>

                        <div class="product-total-feedback-wrap">
                            <div class="feedback-content">
                                <mat-form-field style="min-width: 500px; margin: 0"
                                    class="fuse-mat-textarea flex-auto fuse-mat-rounded fuse-mat-emphasized-affix">
                                    <mat-label>Customer Notes</mat-label>
                                    <textarea matInput placeholder="write something" [formControlName]="'notes'"
                                        name="notes" [rows]="3" class="elevated-input small-input-text"></textarea>
                                    <mat-icon class="icon-size-4" matPrefix
                                        [svgIcon]="'heroicons_solid:menu-alt-2'"></mat-icon>
                                </mat-form-field>





                            </div>


                            <div class="mt-5 form-card w-180 ">
                                <mat-card class="grid grid-cols-1 gap-3">
                                    <div class="sub-total-row flex mb-4">
                                        <div class="label-container">
                                            <mat-label><strong>Sub-Total</strong>
                                            </mat-label>
                                        </div>
                                        <mat-form-field [ngClass]="formFieldHelpers"
                                            class="input-container price-input-wrap fuse-mat-rounded fuse-mat-emphasized-affix">
                                            <input readonly type="text"
                                                onkeypress="return event.charCode>=48 && event.charCode<=57" matInput
                                                placeholder="Sub Total" required name="sub_total"
                                                [formControlName]="'sub_total'"
                                                class="elevated-input small-input-text" />

                                            <span matPrefix>रु</span>
                                            <span matSuffix>.00</span>

                                        </mat-form-field>
                                        <div class="typed-text">
                                            <strong>
                                                {{
                                                salesOrderForm.get("sub_total").value
                                                }}
                                                <span matSuffix>.00</span>
                                            </strong>
                                        </div>
                                    </div>

                                    <div class="sub-total-row flex mb-4">
                                        <div class="label-container">
                                            <mat-label>Discount </mat-label>
                                        </div>
                                        <mat-form-field [ngClass]="formFieldHelpers"
                                            class="input-container price-input-wrap fuse-mat-rounded fuse-mat-emphasized-affix">
                                            <input type="text"
                                                onkeypress="return event.charCode>=48 && event.charCode<=57" matInput
                                                placeholder="Discount" required name="discount"
                                                [formControlName]="'discount'"
                                                class="elevated-input small-input-text" />
                                            <span matPrefix>रु</span>

                                            <mat-select [formControlName]="'discountSuffix'" matSuffix>
                                                <mat-option [value]="'%'">%</mat-option>
                                                <mat-option [value]="'rs'">रु</mat-option>
                                            </mat-select>

                                        </mat-form-field>

                                        <div class="typed-text">
                                            {{ salesOrderForm.get("discount").value }}
                                            <ng-container *ngIf="
                                            salesOrderForm.get('discountSuffix')
                                                .value === '%'
                                        ">
                                                <app-percentage-icon matPrefix>
                                                    %</app-percentage-icon>
                                            </ng-container>
                                            <ng-container *ngIf="
                                            salesOrderForm.get('discountSuffix')
                                                .value === 'rs'
                                        ">
                                                <app-rs-icon matPrefix>.00 रु</app-rs-icon>
                                            </ng-container>
                                        </div>
                                    </div>

                                    <div class="sub-total-row flex mb-4">
                                        <div class="label-container bold-label">
                                            <mat-label> Shipping</mat-label>
                                        </div>
                                        <mat-form-field [ngClass]="formFieldHelpers"
                                            class="input-container price-input-wrap fuse-mat-rounded fuse-mat-emphasized-affix">
                                            <input type="text"
                                                onkeypress="return event.charCode>=48 && event.charCode<=57" matInput
                                                placeholder="shipping_charges" name="shupping_charges"
                                                [formControlName]="'shipping_charges'"
                                                class="elevated-input small-input-text" />
                                            <span matPrefix>रु</span>

                                            <mat-select [formControlName]="'adjustmentSuffix'" matSuffix>

                                            </mat-select>
                                        </mat-form-field>
                                        <div class="typed-text">
                                            {{ salesOrderForm.get("shipping_charges").value }}

                                            <app-rs-icon matPrefix>.00</app-rs-icon>
                                        </div>
                                    </div>

                                    <div class="sub-total-row flex mb-4">
                                        <div class="label-container bold-label">
                                            <mat-label> Adjustment</mat-label>
                                        </div>
                                        <mat-form-field [ngClass]="formFieldHelpers"
                                            class="input-container price-input-wrap fuse-mat-rounded fuse-mat-emphasized-affix">
                                            <input type="text"
                                                onkeypress="return event.charCode>=48 && event.charCode<=57" matInput
                                                placeholder="Adjustment" required name="adjustment"
                                                [formControlName]="'adjustment'"
                                                class="elevated-input small-input-text" />
                                            <span matPrefix>रु</span>

                                            <mat-select [formControlName]="'adjustmentSuffix'" matSuffix>
                                                <mat-option [value]="'%'">
                                                    %</mat-option>
                                                <mat-option [value]="'rs'">रु</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <div class="typed-text">
                                            {{ salesOrderForm.get("adjustment").value }}
                                            <ng-container *ngIf="
                                            salesOrderForm.get(
                                                'adjustmentSuffix'
                                            ).value === '%'
                                        ">
                                                <app-percentage-icon matPrefix>
                                                    %</app-percentage-icon>
                                            </ng-container>
                                            <ng-container *ngIf="
                                            salesOrderForm.get(
                                                'adjustmentSuffix'
                                            ).value === 'rs'
                                        ">
                                                <app-rs-icon matPrefix>.00 रु</app-rs-icon>
                                            </ng-container>
                                        </div>
                                    </div>

                                    <div class="sub-total-row flex mb-4">
                                        <div class="label-container">
                                            <mat-label><strong> Total NPR</strong></mat-label>
                                        </div>
                                        <mat-form-field [ngClass]="formFieldHelpers"
                                            class="input-container price-input-wrap fuse-mat-rounded fuse-mat-emphasized-affix">
                                            <input readonly matInput placeholder="Total" type="text"
                                                onkeypress="return event.charCode>=48 && event.charCode<=57" required
                                                name="total" [formControlName]="'total'"
                                                class="elevated-input  small-input-text" />
                                            <span matPrefix>रु</span>
                                            <span matSuffix>.00</span>
                                        </mat-form-field>
                                        <div class="typed-text">
                                            <strong>
                                                <span matPrefix>रु</span>
                                                {{ salesOrderForm.get("total").value }}
                                                <span matSuffix>.00</span>
                                            </strong>
                                        </div>
                                    </div>



                                </mat-card>
                            </div>
                        </div>
                        <div class="flex mb-2">
                            <mat-form-field style="max-width: 500px"
                                class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix">
                                <mat-label class="ml-3">Promo Code</mat-label>

                                <input style="max-height: 10px;" class="small-input-text" #promoCodeInput matInput
                                    type="text" placeholder="Search Promo Code" [formControl]="PromoCodeControl"
                                    aria-label="PromoCode" [matAutocomplete]="auto1" [readonly]='isFieldReadOnlyPromo'
                                    [class.selected-option]="isPromoSelected(selectedPromo)"
                                    [invalid]="(filteredPromoCode$ | async)?.length === 0 && formSubmitted" />



                                <button *ngIf="isFieldReadOnlyPromo" mat-icon-button (click)="makePromoCodeEditable()">
                                    <mat-icon class="icon-size-4" svgIcon="feather:edit"></mat-icon>
                                </button>

                                <mat-autocomplete #auto1="matAutocomplete" [displayWith]="displayOptionP"
                                    (optionSelected)="selectOptionP($event.option.value)">
                                    <mat-option style="height: fit-content"
                                        *ngFor="let promoCode of filteredPromoCode$ | async" [value]="promoCode"
                                        [disabled]="promoCode.isDisabled">
                                        <div style="display: flex; align-items: center">
                                            <span class="example-option-name">{{ promoCode.name }}</span>
                                        </div>
                                    </mat-option>
                                </mat-autocomplete>
                                <mat-icon class="icon-size-4" matPrefix [svgIcon]="'image_aspect_ratio'"></mat-icon>
                            </mat-form-field>

                        </div>


                        <div class="flex mb-2">
                            <mat-form-field *ngIf="isFieldReadOnly" style="max-width: 500px"
                                class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix">
                                <mat-label class="ml-3">Customer Voucher</mat-label>

                                <input style="max-height: 10px;" class="small-input-text" #voucherInput matInput
                                    type="text" placeholder="Search Voucher Code" [formControl]="VoucherControl"
                                    aria-label="Voucher" [matAutocomplete]="auto3" [readonly]='isFieldReadOnlyVoucher'
                                    [class.selected-option]="isVoucherSelected(selectedPromo)"
                                    [invalid]="(filteredVoucher$ | async)?.length === 0 && formSubmitted" />




                                <button *ngIf="isFieldReadOnlyVoucher" mat-icon-button (click)="makeVoucherEditable()">
                                    <mat-icon class="icon-size-4" svgIcon="feather:edit"></mat-icon>
                                </button>

                                <mat-autocomplete #auto3="matAutocomplete" [displayWith]="displayOptionV"
                                    (optionSelected)="selectOptionVoucher($event.option.value)">
                                    <mat-option style="height: fit-content"
                                        *ngFor="let voucher of filteredVoucher$ | async" [value]="voucher"
                                        [disabled]="voucher.isDisabled">

                                        <div style="display: flex; align-items: center">
                                            <span class="example-option-name">{{ voucher.voucher_order_num }}</span>
                                        </div>
                                    </mat-option>


                                </mat-autocomplete>

                                <mat-icon class="icon-size-4" matPrefix [svgIcon]="'feather:hash'"></mat-icon>
                            </mat-form-field>

                        </div>

                        <div class="flex mb-2">
                            <div *ngIf="selectedVoucher" class="voucher-details">


                                <div class="flex">
                                    <mat-form-field [ngClass]="formFieldHelpers"
                                        class="input-container price-input-wrap fuse-mat-rounded fuse-mat-emphasized-affix">
                                        <input type="text" onkeypress="return event.charCode>=48 && event.charCode<=57"
                                            matInput placeholder="Discount" required name="Voucher applied"
                                            [formControl]="discountPercentageControl" (input)="onDiscountChange()"
                                            [formControlName]="'discount'" class="elevated-input small-input-text" />
                                        <span matPrefix>रु</span>

                                        <mat-select [formControlName]="'discountSuffixVoucherApplied'" matSuffix>
                                            <mat-option [value]="'%'">%</mat-option>
                                            <mat-option [value]="'rs'">रु</mat-option>
                                        </mat-select>

                                        <mat-hint>
                                            <div class="voucher-credit">
                                                Remaining Credit:<br> <span class="discount">{{ appliedAmount }} applied
                                                    / {{
                                                    selectedVoucher.total - appliedAmount }} remaining</span>
                                            </div>
                                        </mat-hint>

                                        <!-- Display error message -->
                                        <mat-error *ngIf="salesOrderForm.get('voucher').hasError('invalidDiscount')">
                                            The discount cannot exceed the total amount.
                                        </mat-error>
                                    </mat-form-field>

                                </div>



                                <span class="chip chip--success" style="line-height: 16px;">

                                    <i style="font-size: 11px; margin-top: -2px;" class="chip__label">
                                        <strong>Discount Applied: {{ discountPercentageControl.value }} </strong>


                                    </i>

                                </span>


                            </div>
                        </div>






                        <!-- Remove product -->

                        <div class="flex items-center justify-start mt-10">

                            <button style="margin: 5px" (click)="sumbitSalesOrder()"
                                class="px-6 py-2 rounded text-white bg-red-500 hover:bg-red-600" mat-flat-button
                                type="submit">
                                Save
                            </button>

                            <button style="margin: 5px" [routerLink]="['..']"
                                class="px-6 py-2 rounded text-white bg-blue-500 hover:bg-blue-600" mat-flat-button
                                color="'accent'">
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </form>

        </mat-drawer-content>
    </mat-drawer-container>
</div>