import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { fuseAnimations } from '@fuse/animations';

import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { VendorCreditService } from '../vendors-credit.service';
import { DatePipe } from '@angular/common';


@Component({
    selector: 'app-listvendors-credit',
    templateUrl: './listvendors-credit.component.html',
    styleUrls: ['./listvendors-credit.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: fuseAnimations,
})
export class ListvendorsCreditComponent implements OnInit, AfterViewInit {
    displayedColumns: string[] = ['id', 'name', 'date', 'sku', 'created_by', 'actions'];

    @ViewChild('matSelectTrigger') matSelectTrigger: ElementRef;
  
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    dataSource_category: MatTableDataSource<any>;
    dataSource_collections: MatTableDataSource<any>;
    dataSource_size: MatTableDataSource<any>;
    dataSource_color: MatTableDataSource<any>;
    dataSource_fabrics: MatTableDataSource<any>;

    selectedTable: string = 'EXPENSE_HEAD';

    flashMessage: 'success' | 'error' | null = null;
    isLoading: boolean = true;
    currentDataSource: MatTableDataSource<any>;



    constructor(

        private _router: Router,
        private cdr: ChangeDetectorRef,
        private expenseService: VendorCreditService,
        private datePipe: DatePipe  // Add this

    ) {


    }

    ngOnInit() {
        this.initializeTable();
        this.loadTables();  // Make sure this runs after the table is initialized
      
    
    }
    
    ngAfterViewInit() {


    }


    getCellStyle(column: string): { [key: string]: string } {
        if (column === 'id') {
            return { 'font-weight': 'bold' };
        } else if (column === 'date') {
            return { 'font-style': 'italic' };
        } else {
            return {}; // No additional styles for other columns
        }
    }



    refreshProductList() {

    }



  
    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.currentDataSource.filter = filterValue.trim().toLowerCase();

        if (this.currentDataSource.paginator) {
            this.currentDataSource.paginator.firstPage();
        }
    }


    createProduct() {
        this._router.navigate(['apps/vendors-credit', 'NEW']);



    }
    initializeTable() {
        this.currentDataSource = new MatTableDataSource<any>();
        this.currentDataSource.paginator = this.paginator;
        this.currentDataSource.sort = this.sort;
        this.displayedColumns = ['id', 'name', 'created',  'created_by', 'actions'];
    }
    

    loadTables(): void {
        this.isLoading = true;
    
        if (this.selectedTable === 'EXPENSE_HEAD') {
            this.expenseService.getExpenseHead().subscribe(data => {
                this.currentDataSource.data = this.processData(data['data']);
                this.isLoading = false;
                this.cdr.detectChanges();  // Force Angular to detect changes
            });
        } else if (this.selectedTable === 'EXPENSE_CATEGORY') {
            this.expenseService.getExpenseCategory().subscribe(data => {
                this.currentDataSource.data = this.processData(data['data']);
                this.isLoading = false;
                this.cdr.detectChanges();  // Detect changes for consistency
            });
        }
    }
    
    private processData(data: any[]): any[] {
        return data.map(item => {
            console.log(item.created)

            if (item.created) {
                item.created = this.datePipe.transform(item.created, 'yyyy-MM-dd');
                console.log(item.created)

            }
            // Extract 'created_by.username'
            if (item.created_by && item.created_by.username) {
                item.created_by = item.created_by.username;
            }
            return item;
        }).sort((a, b) => {
            // Ensure the data is sorted by date in descending order
            return new Date(b.created).getTime() - new Date(a.created).getTime();
        });
    }
  

 
      deleteEntry(row: any): void {
        if (this.selectedTable === 'EXPENSE_HEAD') {
          this.expenseService.deleteExpenseHead(row.id).subscribe(isDeleted => {
            if (isDeleted) {
              this.loadTables();  // Reload the table data after deletion
            }
          });
        } else if (this.selectedTable === 'EXPENSE_CATEGORY') {
          this.expenseService.deleteExpenseCategory(row.id).subscribe(isDeleted => {
            if (isDeleted) {
              this.loadTables();  // Reload the table data after deletion
            }
          });
        }
      }
    







    ViewEntry(id: any) {
     
          
    }
}

/** Builds and returns a new User. */
