<!-- vendorsCredit form -->

<div class="w-full h-full">
    <form
        [formGroup]="vendorsCredit"
        class="bg-gray-100 rounded shadow-lg h-full"
        (keydown.enter)="$event.preventDefault()"
    >
        <!-- Avatar -->
        <!-- Avatar code goes here -->

        <!-- Personal Information Form -->
        <div *ngIf="vendorsCredit" class="p-8">
            <div class="flex justify-end">
                <button mat-raised-button [routerLink]="['..']">
                    <span class="inline-flex items-center">
                        <mat-icon svgIcon="feather:x"></mat-icon>
                    </span>
                </button>
            </div>

            <div class="flex flex-col mt-8">
                <p class="text-lg font-medium" style="font-family: Montserrat">
                    Basic information
                </p>
                <p class="text-secondary" style="font-family: Montserrat">
                    Set your Sales Order Here
                </p>
                <mat-divider class="mt-8 mb-10"></mat-divider>
                <div class="flex mb-4">
                    <mat-form-field style="max-width: 600px" class="flex-auto">
                        <mat-label>Influencer</mat-label>
                        <input
                            matInput
                            type="text"
                            placeholder="Search Influencer"
                            [formControl]="CustomerControl"
                            aria-label="Customers"
                            [matAutocomplete]="auto"
                        />
                        <mat-autocomplete
                            #auto="matAutocomplete"
                            [displayWith]="displayOption"
                            (optionSelected)="selectOption($event.option.value)"
                        >
                            <mat-option
                                style="height: fit-content"
                                *ngFor="
                                    let customer of filteredCustomers$ | async
                                "
                                [value]="customer"
                            >
                                <div style="display: flex; align-items: center">
                                    <img
                                        alt=""
                                        class="example-option-img"
                                        src="assets/Avatar_male2.png"
                                        height="25"
                                        style="
                                            margin-right: 8px;
                                            flex-shrink: 0;
                                        "
                                    />
                                    <span class="example-option-name">{{
                                        customer.firstName
                                    }}</span>
                                    <span class="example-option-sku">
                                        - {{ customer.lastName }}</span
                                    >
                                </div>
                            </mat-option>
                            <mat-option (click)="openVendorDetails()">
                                Add Customer<mat-icon
                                    svgIcon="feather:plus"
                                ></mat-icon>
                            </mat-option>

                            <mat-option
                                style="height: fit-content"
                                *ngIf="
                                    (filteredCustomers$ | async)?.length === 0
                                "
                                [disabled]="true"
                            >
                                NO CUSTOMER FOUND
                                <br />
                            </mat-option>
                        </mat-autocomplete>
                        <mat-icon
                            class="icon-size-5"
                            matPrefix
                            [svgIcon]="'heroicons_solid:user'"
                        ></mat-icon>
                    </mat-form-field>
                </div>

                <div class="flex mb-2">
                    <mat-form-field style="max-width: 600px" class="flex-auto">
                        <mat-label>Sales Order#</mat-label>
                        <input
                            matInput
                            placeholder="Credit Notes"
                            required
                            name="lastName"
                            [formControlName]="'sales_order_num'"
                            class="elevated-input"
                            [value]=""
                        />
                        <mat-icon
                            class="icon-size-5"
                            matPrefix
                            [svgIcon]="'feather:hash'"
                        ></mat-icon>
                    </mat-form-field>
                </div>
                <mat-divider class="mt-5 mb-10"></mat-divider>
                <!-- Point of sales  -->
                <div class="flex mb-2">
                    <mat-form-field style="max-width: 600px" class="flex-auto">
                        <mat-label>Point of Sales</mat-label>
                        <mat-select
                            placeholder="Items Rates Are"
                            (selectionChange)="pointOfsales()"
                            name="pos"
                            [formControlName]="'pos'"
                            class="elevated-input"
                            required
                        >
                            <mat-option value="Instagram">INSTAGRAM</mat-option>
                            <mat-option value="Facebook">FACEBOOK</mat-option>
                            <mat-option value="Website">WEBSITE</mat-option>
                            <mat-option value="Store Vist"
                                >STORE VISIT</mat-option
                            >
                        </mat-select>
                        <mat-icon
                            class="icon-size-5"
                            matPrefix
                            [svgIcon]="'point_of_sale'"
                        ></mat-icon>
                    </mat-form-field>
                </div>

                <div class="flex mb-2" *ngIf="showSocailLink">
                    <mat-form-field style="max-width: 600px" class="flex-auto">
                        <mat-label>Email</mat-label>
                        <input
                            matInput
                            placeholder="Email"
                            name="email"
                            [formControlName]="'email'"
                            class="elevated-input"
                        />
                        <mat-icon
                            class="icon-size-5"
                            matPrefix
                            [svgIcon]="'feather:hash'"
                        ></mat-icon>
                    </mat-form-field>
                </div>

                <div class="flex mb-2" *ngIf="showEmail">
                    <mat-form-field style="max-width: 600px" class="flex-auto">
                        <mat-label>Social Link</mat-label>
                        <input
                            matInput
                            placeholder="Social Link"
                            name="email"
                            [formControlName]="'soical_link'"
                            class="elevated-input"
                        />
                        <mat-icon
                            class="icon-size-5"
                            matPrefix
                            [svgIcon]="'feather:hash'"
                        ></mat-icon>
                    </mat-form-field>
                </div>

                <div class="flex mb-2" *ngIf="showPaymentMethod">
                    <mat-form-field style="max-width: 600px" class="flex-auto">
                        <mat-label>Payment Type </mat-label>
                        <mat-select
                            placeholder="Items Rates Are"
                            name="Tax"
                            [formControlName]="'payment_type'"
                            class="elevated-input"
                        >
                            <mat-option value="CASH ON DELIVERY"
                                >CASH ON DELIVERY</mat-option
                            >
                            <mat-option value="ASHIM ESEWA"
                                >ASHIM ESEWA</mat-option
                            >
                            <mat-option value="DANISH ESEWA"
                                >DANISH ESEWA</mat-option
                            >
                            <mat-option value="HUBA NIC ASIA"
                                >HUBA NIC ASIA
                            </mat-option>
                            <mat-option value="ASHIM SIDDHARTA BANK"
                                >ASHIM SIDDHARTA BANK</mat-option
                            >
                        </mat-select>
                        <mat-icon
                            class="icon-size-5"
                            matPrefix
                            [svgIcon]="'payments'"
                        ></mat-icon>
                    </mat-form-field>
                </div>

                <!-- Shipping area Form  -->

                <div class="flex mb-2" *ngIf="showShippingArea">
                    <mat-form-field style="max-width: 600px" class="flex-auto">
                        <mat-label>Shipping Area </mat-label>
                        <mat-select
                            placeholder="Items Rates Are"
                            name="Tax"
                            (selectionChange)="addressField()"
                            [formControlName]="'shipping_area'"
                            class="elevated-input"
                        >
                            <mat-option value="Inside Valley"
                                >INSIDE VALLEY</mat-option
                            >
                            <mat-option value="Outside Valley"
                                >OUTSIDE VALLEY</mat-option
                            >
                            <mat-option value="International"
                                >INTERNATIONAL</mat-option
                            >
                        </mat-select>
                        <mat-icon
                            class="icon-size-5"
                            matPrefix
                            [svgIcon]="'feather:truck'"
                        ></mat-icon>
                    </mat-form-field>
                </div>

                <div class="flex mb-2" *ngIf="showDistrict">
                    <mat-form-field style="max-width: 600px" class="flex-auto">
                        <mat-label>District</mat-label>
                        <input
                            matInput
                            placeholder="District"
                            name="District"
                            [formControl]="districtControl"
                            class="elevated-input"
                            [matAutocomplete]="auto"
                        />
                        <mat-autocomplete #auto="matAutocomplete">
                            <mat-option
                                style="font-family: Montserrat"
                                *ngFor="
                                    let district of filteredDistricts | async
                                "
                                [value]="district.toUpperCase()"
                            >
                                <span style="font-family: Montserrat">{{
                                    district.toUpperCase()
                                }}</span>
                            </mat-option>
                        </mat-autocomplete>
                        <mat-icon
                            class="icon-size-5"
                            matPrefix
                            [svgIcon]="'location_city'"
                        ></mat-icon>
                    </mat-form-field>
                </div>

                <div class="flex mb-2" *ngIf="showDistrict">
                    <mat-form-field style="max-width: 600px" class="flex-auto">
                        <mat-label>Delivery Address</mat-label>
                        <input
                            matInput
                            placeholder="Delivery Address"
                            [formControlName]="'address'"
                            class="elevated-input"
                            ngx-google-places-autocomplete
                            [options]="options"
                            (onAddressChange)="handleAddressChange($event)"
                        />
                        {{ formattedaddress }}
                        <mat-icon
                            class="icon-size-5"
                            matPrefix
                            [svgIcon]="'location_city'"
                        ></mat-icon>
                    </mat-form-field>
                </div>

                <div class="flex mb-2" *ngIf="showShippinginvalley">
                    <mat-form-field style="max-width: 600px" class="flex-auto">
                        <mat-label>Shipping Carrier </mat-label>
                        <mat-select
                            placeholder="Items Rates Are"
                            name="shipping_carrier_invalley"
                            (selectionChange)="addressField()"
                            [formControlName]="'shipping_carrier_invalley'"
                            class="elevated-input"
                        >
                            <mat-option value="Dash logistics">DASH</mat-option>
                            <mat-option value="House of delivery"
                                >HOUSE OF DELIVERY</mat-option
                            >
                            <mat-option value="In Driver">IN DRIVER</mat-option>
                            <mat-option value="Pathao">PATHAO</mat-option>
                            <mat-option value="others">OTHERS</mat-option>
                        </mat-select>
                        <mat-icon
                            class="icon-size-5"
                            matPrefix
                            [svgIcon]="'feather:truck'"
                        ></mat-icon>
                    </mat-form-field>
                </div>

                <div class="flex mb-2" *ngIf="showShippingOutvalley">
                    <mat-form-field style="max-width: 600px" class="flex-auto">
                        <mat-label>Shipping Carrier Out Valley</mat-label>
                        <mat-select
                            placeholder="Items Rates Are"
                            name="shipping_carrier_outvalley"
                            (selectionChange)="addressField()"
                            [formControlName]="'shipping_carrier_outvalley'"
                            class="elevated-input"
                        >
                            <mat-option value="Dash logistics"
                                >Dash Logistics</mat-option
                            >
                            <mat-option value="House of delivery"
                                >HOUSE OF DELIVERY</mat-option
                            >
                            <mat-option value="Courier">Courier</mat-option>
                            <mat-option value="others">Others</mat-option>
                        </mat-select>
                        <mat-icon
                            class="icon-size-5"
                            matPrefix
                            [svgIcon]="'feather:truck'"
                        ></mat-icon>
                    </mat-form-field>
                </div>

                <div class="flex mb-2" *ngIf="shwoShippingInternational">
                    <mat-form-field style="max-width: 600px" class="flex-auto">
                        <mat-label>Shipping Carrier International</mat-label>
                        <mat-select
                            placeholder="Items Rates Are"
                            name="shipping_carrier_int"
                            (selectionChange)="addressField()"
                            [formControlName]="'shipping_carrier_int'"
                            class="elevated-input"
                        >
                            <mat-option value="ARAMEX">ARAMEX</mat-option>
                            <mat-option value="others">OTHERS</mat-option>
                        </mat-select>
                        <mat-icon
                            class="icon-size-5"
                            matPrefix
                            [svgIcon]="'feather:truck'"
                        ></mat-icon>
                    </mat-form-field>
                </div>

                <div class="flex mb-2" *ngIf="showCountry">
                    <mat-form-field style="max-width: 600px" class="flex-auto">
                        <mat-label>Country</mat-label>
                        <input
                            matInput
                            placeholder="Country"
                            name="coountry"
                            [formControlName]="'country'"
                            class="elevated-input"
                        />
                        <mat-icon
                            class="icon-size-5"
                            matPrefix
                            [svgIcon]="'location_city'"
                        ></mat-icon>
                    </mat-form-field>
                </div>

                <mat-divider class="mt-3 mb-5"></mat-divider>
                <!-- Ends here -->

                <div class="flex mb-2">
                    <mat-form-field style="max-width: 600px" class="flex-auto">
                        <mat-label>Reference</mat-label>
                        <input
                            matInput
                            placeholder="OrderNumber"
                            name="email"
                            [formControlName]="'refrences'"
                            class="elevated-input"
                        />
                        <mat-icon
                            class="icon-size-5"
                            matPrefix
                            [svgIcon]="'feather:hash'"
                        ></mat-icon>
                    </mat-form-field>
                </div>
                <!-- Additional Fields -->
                <div class="flex mb-2">
                    <mat-form-field style="max-width: 600px" class="flex-auto">
                        <mat-label>Sales Order Date</mat-label>
                        <input
                            matInput
                            [matDatepicker]="picker2"
                            placeholder="Sales order Date"
                            name="slaes_order_date"
                            [formControlName]="'slaes_order_date'"
                            class="elevated-input"
                            mask="00/00/0000"
                            required
                            (dateChange)="
                                formatDate(
                                    $event.value,
                                    'yyyy-MM-dd',
                                    'sales_order_shippmentDate'
                                )
                            "
                        />
                        <mat-datepicker-toggle
                            matSuffix
                            [for]="picker2"
                        ></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                        <mat-icon
                            class="icon-size-5"
                            matPrefix
                            [svgIcon]="'heroicons_solid:calendar'"
                        ></mat-icon>
                    </mat-form-field>
                </div>
                <div class="flex mb-2">
                    <mat-form-field style="max-width: 600px" class="flex-auto">
                        <mat-label>Expected Shipment Date</mat-label>
                        <input
                            matInput
                            [matDatepicker]="picker3"
                            placeholder="VendorCreditDate"
                            name="expected_shippmentDate"
                            [formControlName]="'sales_order_shippmentDate'"
                            class="elevated-input"
                            mask="00/00/0000"
                            required
                            (dateChange)="
                                formatDate(
                                    $event.value,
                                    'yyyy-MM-dd',
                                    'sales_order_shippmentDate'
                                )
                            "
                        />
                        <mat-datepicker-toggle
                            matSuffix
                            [for]="picker3"
                        ></mat-datepicker-toggle>
                        <mat-datepicker #picker3></mat-datepicker>

                        <mat-icon
                            class="icon-size-5"
                            matPrefix
                            [svgIcon]="'heroicons_solid:calendar'"
                        ></mat-icon>
                    </mat-form-field>
                </div>
                <div class="flex mb-2">
                    <mat-form-field style="max-width: 600px" class="flex-auto">
                        <mat-label>Payment Terms</mat-label>
                        <mat-select
                            placeholder="Items Rates Are"
                            name="Tax"
                            [formControlName]="'payment_terms'"
                            class="elevated-input"
                            required
                        >
                            <mat-option value="Tax Exclusive"
                                >TAX EXCLUSIVE</mat-option
                            >
                            <mat-option value="TAX INCLUSIVE"
                                >TAX INCLUSIVE</mat-option
                            >
                        </mat-select>
                        <mat-icon
                            class="icon-size-5"
                            matPrefix
                            [svgIcon]="'heroicons_solid:translate'"
                        ></mat-icon>
                    </mat-form-field>
                </div>
                <div class="flex mb-2">
                    <mat-form-field style="max-width: 600px" class="flex-auto">
                        <mat-label>Delivery Type</mat-label>
                        <mat-select
                            placeholder="Items Rates Are"
                            name="Tax"
                            [formControlName]="'delivery_type'"
                            class="elevated-input"
                            required
                        >
                            <mat-option value="Normal">NORMAL</mat-option>
                            <mat-option value="Express">EXPRESS</mat-option>
                        </mat-select>
                        <mat-icon
                            class="icon-size-5"
                            matPrefix
                            [svgIcon]="'delivery_dining'"
                        ></mat-icon>
                    </mat-form-field>
                </div>
                <mat-divider class="mt-4 mb-5"></mat-divider>

                <div class="flex mb-2">
                    <mat-form-field style="max-width: 600px" class="flex-auto">
                        <mat-label>Salesperson</mat-label>
                        <mat-select
                            placeholder="Sales Person"
                            name="Tax"
                            [formControlName]="'sales_person'"
                            class="elevated-input"
                            required
                        >
                            <mat-option value="ASHIM">ASHIM</mat-option>
                            <mat-option value="OTHERS">SOMEONEELSE</mat-option>
                        </mat-select>
                        <mat-icon
                            class="icon-size-5"
                            matPrefix
                            [svgIcon]="'feather:user'"
                        ></mat-icon>
                    </mat-form-field>
                </div>

                <!-- Add Products -->

                <mat-divider class="mt-8 mb-3"></mat-divider>

                <ng-container s formArrayName="products">
                    <ng-container
                        *ngFor="
                            let product of vendorsCredit.get('products')
                                .controls;
                            let i = index;
                            let first = first;
                            let last = last;
                            trackBy: trackByFn
                        "
                    >
                        <div
                            class="flex"
                            style="padding: auto"
                            [formGroupName]="i"
                        >
                            <mat-form-field
                                class="fuse-mat-no-subscript"
                                style="min-width: 600px"
                            >
                                <ng-container
                                    *ngIf="!selectedProductThumbnail[i]"
                                >
                                    <mat-icon
                                        class="mr-2 icon-size-10"
                                        svgIcon="mat_solid:photo"
                                    ></mat-icon>
                                </ng-container>
                                <ng-container
                                    *ngIf="selectedProductThumbnail[i]"
                                >
                                    <img
                                        alt=""
                                        [class]="selectedProductThumbnailClass"
                                        [src]="selectedProductThumbnail[i]"
                                        style="
                                            margin-right: 8px;
                                            flex-shrink: 0;
                                        "
                                    />
                                </ng-container>
                                <mat-label *ngIf="first">Product</mat-label>
                                <input
                                    style="height: 50px"
                                    matInput
                                    formControlName="product"
                                    placeholder="Type SKU or Product Name"
                                    required
                                    [matAutocomplete]="auto"
                                    (input)="
                                        filterOptionsProduct(
                                            $event.target.value
                                        )
                                    "
                                    (focus)="
                                        filterOptionsProduct(
                                            $event.target.value
                                        )
                                    "
                                />

                                <mat-autocomplete
                                    #auto="matAutocomplete"
                                    [displayWith]="
                                        displayProductOption.bind(this)
                                    "
                                    (optionSelected)="
                                        selectOptionProduct(
                                            $event.option.value,
                                            i
                                        )
                                    "
                                >
                                    <mat-option
                                        style="
                                            height: auto;
                                            min-height: 32px;
                                            display: flex;
                                            align-items: center;
                                            padding: 4px 16px;
                                        "
                                        *ngFor="
                                            let option of filteredProductOptions$
                                                | async;
                                            let i = index
                                        "
                                        [value]="option"
                                    >
                                        <div
                                            style="
                                                display: flex;
                                                align-items: center;
                                            "
                                        >
                                            <img
                                                alt=""
                                                class="example-option-img"
                                                src="{{ option.thumbnail }}"
                                                height="25"
                                                style="
                                                    margin-right: 8px;
                                                    flex-shrink: 0;
                                                "
                                            />
                                            <span class="example-option-name">{{
                                                option.name
                                            }}</span>
                                            <span class="example-option-sku">
                                                - {{ option.sku }}</span
                                            >
                                        </div>
                                    </mat-option>

                                    <mat-option
                                        style="
                                            cursor: pointer;
                                            height: fit-content;
                                        "
                                        (click)="openProductDetails()"
                                        *ngIf="
                                            (filteredProductOptions$ | async)
                                                ?.length === 0
                                        "
                                        [disabled]="true"
                                    >
                                        No options found
                                        <br />
                                        <mat-icon svgIcon="feather:plus">
                                        </mat-icon>
                                        Add Product
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>

                            <mat-form-field
                                class="fuse-mat-no-subscript"
                                style="min-width: 200px"
                            >
                                <mat-label *ngIf="first">Quantity</mat-label>
                                <mat-icon
                                    svgIcon="mat_solid:production_quantity_limits"
                                ></mat-icon>
                                <input
                                    style="height: 50px"
                                    matInput
                                    type="text" onkeypress="return event.charCode>=48 && event.charCode<=57"
                                    formControlName="quantity"
                                    placeholder="Quantity"
                                />
                            </mat-form-field>
                            <mat-form-field
                                [ngClass]="formFieldHelpers"
                                class="fuse-mat-no-subscript"
                                style="min-width: 200px"
                            >
                                <mat-label *ngIf="first">Rate</mat-label>

                                <input
                                    style="height: 50px"
                                    matInput
                                    type="text" onkeypress="return event.charCode>=48 && event.charCode<=57"
                                    formControlName="rate"
                                    placeholder="Rate"
                                />
                                <span matPrefix>रु</span>
                                <span matSuffix>.00</span>
                            </mat-form-field>
                            <mat-form-field
                                [ngClass]="formFieldHelpers"
                                class="fuse-mat-no-subscript"
                                style="min-width: 200px"
                            >
                                <mat-label *ngIf="first">Tax</mat-label>
                                <input
                                    style="height: 50px"
                                    matInput
                                    type="text" onkeypress="return event.charCode>=48 && event.charCode<=57"
                                    formControlName="tax"
                                    placeholder="Tax"
                                />
                                <span matPrefix>रु </span>
                                <span matSuffix>.00</span>
                            </mat-form-field>
                            <mat-form-field
                                [ngClass]="formFieldHelpers"
                                class="fuse-mat-no-subscript"
                                style="min-width: 200px"
                            >
                                <mat-label *ngIf="first">Amount</mat-label>
                                <input
                                    style="height: 50px"
                                    matInput
                                    type="text" onkeypress="return event.charCode>=48 && event.charCode<=57"
                                    [disableControl]="disable"
                                    formControlName="amount"
                                    placeholder="Amount"
                                />
                                <span matPrefix>रु</span>
                                <span matSuffix>.00</span>
                            </mat-form-field>

                            <!-- Remove product -->
                            <ng-container *ngIf="!(first && last)">
                                <div
                                    class="flex items-center w-10 pl-2"
                                    [ngClass]="{ 'mt-6': first }"
                                >
                                    <button
                                        mat-icon-button
                                        (click)="removeProductField(i)"
                                        matTooltip="Remove"
                                    >
                                        <mat-icon
                                            class="icon-size-5"
                                            [svgIcon]="'heroicons_solid:trash'"
                                        ></mat-icon>
                                    </button>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                </ng-container>

                <div class="inline-flex items-center mt-2 -ml-4 py-2 px-4">
                    <mat-icon
                        matPrefix
                        class="hidden sm:flex icon-size-5"
                        [svgIcon]="'heroicons_solid:tag'"
                    ></mat-icon>
                    <span
                        (click)="addProductField()"
                        class="ml-2 font-medium text-secondary cursor-pointer hover:underline"
                        >Add a product</span
                    >
                </div>

                <!-- End product -->

                <div class="product-total-feedback-wrap">
                    <div class="feedback-content">
                        <mat-form-field
                            style="min-width: 500px; margin: 0"
                            class="fuse-mat-textarea flex-auto"
                        >
                            <mat-label>Customer Notes</mat-label>
                            <textarea
                                matInput
                                placeholder="Social Media"
                                [formControlName]="'socialMedia'"
                                name="socialMedia"
                                [rows]="3"
                                class="elevated-input"
                            ></textarea>
                            <mat-icon
                                class="icon-size-5"
                                matPrefix
                                [svgIcon]="'heroicons_solid:menu-alt-2'"
                            ></mat-icon>
                        </mat-form-field>
                    </div>
                </div>

                <!-- Remove product -->

                <div class="flex items-center justify-start mt-10">
                    <div
                        class="save-btn-wrap rounded text-white bg-red-500 hover:bg-red-600"
                    >
                        <button
                            style="margin: 5px"
                            (click)="submitVendorsInfo()"
                            class="px-6 py-2"
                            mat-flat-button
                            type="submit"
                            color="'accent'"
                        >
                            Save
                        </button>
                        <div class="dropdown">
                            <button
                                (click)="toggleDropdown()"
                                class="dropdown-toggle rounded text-white bg-red-500 hover:bg-red-600"
                                mat-flat-button
                            >
                                <mat-icon
                                    class="icon-size-1"
                                    svgIcon="mat_solid:arrow_drop_up"
                                ></mat-icon>
                            </button>
                            <div id="dropdown-menu" class="dropdown-menu">
                                <a href="#" onclick="saveAsDraft()"
                                    >Save as Draft</a
                                >
                                <a href="#" onclick="saveAndPrint()"
                                    >Save and Print</a
                                >
                            </div>
                        </div>
                    </div>

                    <button
                        style="margin: 5px"
                        (click)="sumbitVendorsInfo()"
                        class="px-6 py-2 rounded text-white bg-red-500 hover:bg-red-600"
                        mat-flat-button
                        color="'accent'"
                    >
                        Save as Draft
                    </button>
                    <button
                        [routerLink]="['..']"
                        class="px-6 py-2 rounded text-white bg-blue-500 hover:bg-blue-600"
                        mat-flat-button
                        color="'accent'"
                    >
                        Cancel
                    </button>
                </div>

                
            </div>
        </div>
    </form>
</div>
