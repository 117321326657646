import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PackagesHistoryComponent } from './packages-history/packages-history.component';
import { ListpackagesHistoryComponent } from './listpackages-history/listpackages-history.component';
import { CreatepackagesHistoryComponent } from './createpackages-history/createpackages-history.component';
import { DetailpackagesHistoryComponent } from './detailpackages-history/detailpackages-history.component';
import { RouterModule } from '@angular/router';
import {  PackagesHistoryRoutes } from './packages.history.routing';
import { MaterialModule } from 'app/modules/material/material.module';



@NgModule({
  declarations: [
    PackagesHistoryComponent,
    ListpackagesHistoryComponent,
    CreatepackagesHistoryComponent,
    DetailpackagesHistoryComponent
  ],
  imports: [
    RouterModule.forChild(PackagesHistoryRoutes),
    MaterialModule,
    CommonModule
  ]
})
export class PackagesHistoryModule { }
