import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { SalesOrderListComponent } from './list/list.component';
import { MaterialModule } from 'app/modules/material/material.module';

import { MAT_DATE_FORMATS } from '@angular/material/core';
import { SalesOrderDetailComponent } from './detail/detail.component';
import { SalesOrderCreateComponent } from './sales-order-create/sales-order-create.component';
import { SalesOrderComponent } from './sales-order.component';
import { RouterModule } from '@angular/router';
import { SalesRoutes } from './sales-order.routing';
import { SalesOrderPopupComponent } from './sales-order-popup/sales-order-popup.component';
import { CustomSelectModule } from 'app/custom-select/custom-select.module';
import { SalesOrderProductPopupComponent } from './sales-order-product-popup/sales-order-product-popup.component';
import { InvoiceComponent } from './invoice/sales-order-invoice';
import { PrintingService } from './invoice/sales-order-invoice.service';
import { InvoiceRendererDirective } from './invoice-renderer.directive';
import { SelectableDirective } from './readonly.directive';
import { PartialUpdateComponent } from './partial-update/partial-update.component';
import { CustomerDetialMatDrawerComponent } from './customer-detial-mat-drawer/customer-detial-mat-drawer.component';
@NgModule({
  declarations: [
    SalesOrderComponent,
    SalesOrderListComponent,
    SalesOrderDetailComponent,
    SalesOrderCreateComponent,
    SalesOrderPopupComponent,
    SalesOrderProductPopupComponent,
    InvoiceComponent,
    InvoiceRendererDirective,
    SelectableDirective,
    PartialUpdateComponent,
    CustomerDetialMatDrawerComponent,

    


    

  ],
  imports: [
    RouterModule.forChild(SalesRoutes),
    MaterialModule,
    CommonModule,
    CustomSelectModule,
  



    
   
  ],
  providers   : [
    {
        provide : MAT_DATE_FORMATS,
        useValue: {
            parse  : {
                dateInput: "YYYY-MM-DD"
            },
            display: {
                dateInput         : 'YYYY-MM-DD',
                monthYearLabel    : 'MMM YYYY',
                dateA11yLabel     : 'LL',
                monthYearA11yLabel: 'MMMM YYYY'
                
            }
        }
    },
    DatePipe,
    PrintingService
],

})
export class SalesOrderModule { }
