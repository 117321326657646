import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import { server_env } from 'config';
import {  HttpHeaders } from '@angular/common/http';

@Injectable()
export class AuthService {

    public url = "";
    public urls = "";
     _authenticated: boolean = false;

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _userService: UserService,
    ) {


        this.url = `${server_env.URL}login/`;
        this.urls = `${server_env.URL}custom/refresh/`;


    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string) {
        if (!token) { console.info("Invalid Token || Unavailable Token "); return; }
        localStorage.setItem('accessToken', token);
        // Set the access token in the cookie
        this.setAuthCookie(token);
    }

    get accessToken(): string {
        return localStorage.getItem('accessToken') ?? '';
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any> {
        return this._httpClient.post('api/auth/forgot-password', email);
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(password: string): Observable<any> {
        return this._httpClient.post('api/auth/reset-password', password);
    }

    signIn(credentials: { username: string; password: string }): Observable<any> {
        if (this._authenticated) {
            return throwError('User is already logged in.');
        }

        return this._httpClient.post((`${this.url}`), credentials).pipe(
            switchMap((response: any) => {
                this.accessToken = response.accessToken;
                this._authenticated = true;
                this._userService.user = response.user;
                return of(response);
            })
        );
    }

    signInUsingToken(): Observable<any> {
        return this._httpClient.post<any>(`${this.urls}`, {
            accessToken: this.accessToken
        }).pipe(
            catchError(() => of(false)),
            switchMap((response: any) => {
                this.accessToken = response.accessToken;
                this._authenticated = true;
                this._userService.user = response.user;
                return of(true);
            })
        );
    }


    renewAccessToken(): Observable<any> {
        return this._httpClient.post<any>(`${server_env.URL}custom/refresh/`, {
            accessToken: this.accessToken
        }).pipe(
            catchError(() => of(false)),
            switchMap((response: any) => {
                this.accessToken = response.accessToken;
                this._authenticated = true;
                this._userService.user = response.user;
                return this.checkAuthentication();
            }),
            catchError(() => of(false)),
            switchMap((response: any) => {
                if (response === true) {
                    return of(true);
                } else {
                    return throwError('Session login failed');
                }
            })
        );
    }

    check(): Observable<boolean> {
        if (this._authenticated) {
            return of(true);
        }

        if (!this.accessToken) {
            return of(false);
        }

        if (AuthUtils.isTokenExpired(this.accessToken)) {
            return of(false);
        }

        return this.signInUsingToken();
    }
    /**
     * Check if the user has the required roles
     *
     * @param requiredRoles Array of roles required for access
     * @returns boolean indicating whether the user has the required roles
     */
    hasRoles(requiredRoles: string[]): boolean {
        const userRoles = this._userService.user?.roles || [];
        return requiredRoles.every(role => userRoles.includes(role));
    }

    // Cookie handling
    private setAuthCookie(token: string): void {
        document.cookie = `auth_data=${token}; secure; HttpOnly; SameSite=Strict`;
    }

    // Check authentication
    private checkAuthentication(): Observable<boolean> {
        return this._httpClient.get<any>(`${server_env.URL}api/check-authentication/`);
    }

    /**
     * Sign out
     */
signOut(): Observable<any> {
    // Retrieve the access token from local storage
    const accessToken = localStorage.getItem('accessToken');

    if (!accessToken) {
        // If no token is found, handle the case accordingly
        console.error('No access token found for logout.');
        return of(false);
    }

    // Set up headers with the access token
    const headers = new HttpHeaders().set('Authorization', `Bearer ${accessToken}`);

    // Return the observable and handle possible errors
    return this._httpClient.post<any>(`${server_env.URL}logout/`, {}, { headers }).pipe(
        map(() => {
            // Log successful logout
            console.log('User successfully logged out.');
            localStorage.removeItem('accessToken');
            this.removeAuthCookie();

            this._userService.clearUser();
            this._authenticated = false;
            return true;
        }),
        catchError((error) => {
            // Log the error message
            console.error('Error during sign-out:', error);
            return of(false);
        })
    );
}


    // Method to remove the auth cookie
    private removeAuthCookie(): void {
        document.cookie = 'auth_data=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: { name: string; email: string; password: string; company: string }): Observable<any> {
        return this._httpClient.post('api/auth/sign-up', user);
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any> {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

}
