import { Route } from "@angular/router";
import { SalesLogsComponent } from "./sales-logs.component";
import { SalesLogsResolver } from "./sales-logs-resolvers";

export const SalesLogsRoutes :Route[]  = [
    {
        path:'',
        component:SalesLogsComponent,
        resolve: {
            salesLogs: SalesLogsResolver
          }
    }
]