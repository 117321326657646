import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, } from 'rxjs';
import { take } from 'rxjs/operators';
import { ProductCollection } from '../product-group.type';
import Swal from 'sweetalert2';
import { productsGroupService } from '../product-group.service';
export interface CollectionData {


  id: string;
  name: string;


}



@Component({
  selector: 'app-listproduct-group',
  templateUrl: './listproduct-group.component.html',
  styleUrls: ['./listproduct-group.component.scss'],
})
export class ListproductGroupComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = ['id', 'name', 'actions'];

  dataSource: MatTableDataSource<CollectionData>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  collection: ProductCollection[];

  colection$: Observable<ProductCollection[]>;

  constructor(

    private _collection: productsGroupService,
  ) {

  }

  ngOnInit(): void {

  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }



  deltecollection(id: any) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {

      if (result.isConfirmed) {
        this._collection.deleteproducts(id)

          .subscribe((isDeleted) => {
          
            this.loadCollections();
            if (!isDeleted) {
              Swal.fire("Error!", "Cannot Delete");
              return;
            }

            // Display a success message
            Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            )
          });
      }
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  loadCollections(){
    this.colection$ = this._collection
    .getproducts()
    .pipe(take(1));
    this.colection$.subscribe((collections) => {
      this.collection = collections['data'];

      this.dataSource = new MatTableDataSource<CollectionData>(

        this.collection
          .map((collection) => {

            return {
              id: collection.id,
              name: collection.collection_name


            };
          })
      );
    });
  }

  
}  