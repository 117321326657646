import {
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import {
    FormBuilder,
    FormGroup,
    Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { Vendor } from 'app/modules/Vendor/vendors.type';
import Swal from 'sweetalert2';
import { VendorsExpenseService } from '../vendors-expense.service';
import { VendorCreditService } from 'app/vendors-credit/vendors-credit.service';
import { formatDate } from '@angular/common';
export interface VendorOption {
    id: string;
    firstname?: string;
    avatar?: string;
}
@Component({
    selector: 'app-createvendors-expense',
    templateUrl: './createvendors-expense.component.html',
    styleUrls: ['./createvendors-expense.component.scss'],
})
export class CreatevendorsExpenseComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    vendorsCredit: FormGroup;
    successMessage: string;
    errorMessage: string;
    expenseHeads: any[] = [];
    expenseCategories: any[] = [];
    vendors: Vendor[];
    imagePreview: string | ArrayBuffer | null;
    private _subscription: Subscription;
    Tost = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener(
                'mouseenter',
                Swal.stopTimer
            );
            toast.addEventListener(
                'mouseleave',
                Swal.resumeTimer
            );
        },
    });

    @ViewChild('fileInput') fileInput: ElementRef;
    expenseOrderNum: number;


    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _vendorExpenseService: VendorsExpenseService,
        private _formBuilder: FormBuilder,
        private _expenseService: VendorCreditService,
    ) {


        this._subscription = this._vendorExpenseService.getExpense_number().subscribe(
            (response: any) => {
                if (response.success && response.data !== undefined) {
                    this.expenseOrderNum = response.data;

                    // Now, you can update your form control
                    this.vendorsCredit.controls['expense_number'].setValue(`EXP-0-${this.expenseOrderNum}-A`);
                } else {
                    console.error('Invalid response format:', response);
                }
            },
            (error) => {
                console.error('Error fetching sales order numbers:', error);
            }
        );
    }

    ngOnInit() {
        this.vendorsCredit = this._formBuilder.group({
            expense_number: ['EXP-0-'],
            expense_date: ['', Validators.required],
            contact_person: ['', Validators.required],
            expense_head: [''],
            expense_category: [''],
            expense_for: [''],
            expense_description: [''],
            total: ['', Validators.required],
            is_credit: [false],
            payment_method: [''],
            credit_valid_date: [''],
            expense_type: [''],
            bill_photo: [null], // Initialize as null
            otherExpenseType: '',

        });
        this.imagePreview = null;

        this.vendorsCredit.get('expense_type').valueChanges.subscribe((value) => {
            if (value === 'other') {
                this.vendorsCredit.get('otherExpenseType').enable();
            } else {
                this.vendorsCredit.get('otherExpenseType').disable();
                this.vendorsCredit.get('otherExpenseType').reset();
            }
        });

        this.vendorsCredit.get('expense_date').valueChanges.subscribe((value) => {
            if (value) {
                const formattedDate = formatDate(value, 'yyyy-MM-dd', 'en-US');
                this.vendorsCredit.get('expense_date').setValue(formattedDate, { emitEvent: false });
            }
        });
        this.loadExpenseHeads();
        this.loadExpenseCategories();
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    loadExpenseHeads(): void {
        this._expenseService.getExpenseHead().subscribe(data => {
            const main_data = data['data']

            this.expenseHeads = main_data.map((item: any) => ({ id: item.id, name: item.name }));
        });
    }
    handleOtherExpenseTypeChange() {
        const otherExpenseTypeValue = this.vendorsCredit.get('otherExpenseType').value;
        if (otherExpenseTypeValue) {
            this.vendorsCredit.get('expense_type').setValue('other');
        }
    }
    triggerFileInput(): void {
        this.fileInput.nativeElement.click();
    }

    onFileSelected(event: Event): void {
        const file = (event.target as HTMLInputElement).files?.[0];
        if (file) {
            // Patch the form control with the selected file
            this.vendorsCredit.patchValue({ bill_photo: file });
            this.vendorsCredit.get('bill_photo')?.updateValueAndValidity();

 
            // Create a FileReader to preview the image
            const reader = new FileReader();
            reader.onload = () => {
                this.imagePreview = reader.result as string;
            };
            reader.readAsDataURL(file);
        } else {
        }
    }


    clearImage(): void {
        this.imagePreview = null;
        this.vendorsCredit.patchValue({ bill_photo: null });
        this.vendorsCredit.get('bill_photo')?.updateValueAndValidity();
    }

    toggleFullScreen(event: MouseEvent): void {
        const target = event.target as HTMLElement;
        if (!document.fullscreenElement) {
            target.requestFullscreen().catch(err => console.log(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`));
        } else {
            document.exitFullscreen();
        }
    }

    loadExpenseCategories(): void {
        this._expenseService.getExpenseCategory().subscribe(data => {
            const main_data = data['data']
            this.expenseCategories = main_data.map((item: any) => ({ id: item.id, name: item.name }));
        });
    }

    sumbitVendorsInfo() {
        if (this.vendorsCredit.valid) {
            const vendorData = { ...this.vendorsCredit.value };

            const formData = new FormData();
            const formValue = this.vendorsCredit.value;
            for (const key in formValue) {
                if (formValue.hasOwnProperty(key)) {
                    const value = formValue[key];

                    if (value !== null && value !== undefined && value !== '') {
                        if (!Array.isArray(value) || value.length > 0) {
                            formData.append(key, value);
                        }
                    }
                }
            }

            const billPhotoFile = this.vendorsCredit.get('bill_photo')?.value;
            if (billPhotoFile instanceof File) {
                formData.append('bill_photo', billPhotoFile);
            }

            this._vendorExpenseService
                .createVendorsExpenses(formData)
                .then((response) => {
                    if (response.success) {

                        this.Tost.fire({
                            icon: 'success',
                            title: `${vendorData.vendors_creditDate} Expense Order created!!`,
                        });

                        this.vendorsCredit.reset();
                        this._router.navigate(['apps/vendors-expenses'], {
                            relativeTo: this._activatedRoute,
                        });
                    } else {
                        const errorMessages = response.errors;

                        this.Tost.fire({
                            icon: 'error',
                            title: 'Error creating Expense Order',
                            text: JSON.stringify(errorMessages),
                        });
                    }
                })
                .catch((error) => {
                    // Hide the loading indicator
                    

                    this.Tost.fire({
                        icon: 'error',
                        title: `Error creating Vendors Order: ${error.message}`,
                    });
                });
        } else {
            // Form is not valid, handle accordingly
            // For example, you can show a toast message or perform other actions
           
            this.Tost.fire({
                icon: 'error',
                title: 'Invalid form data',
                text: 'Please fill in all the required fields correctly.',
            });
        }
    }



    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------




}
