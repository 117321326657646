<ng-container *ngIf="product_bundle" class="mt-10 ml-15 md:ml-50 lg:ml-90">

    <ng-container *ngIf="!editMode">

        <div class="absolute inset-0 min-w-0 text-center overflow-auto print:overflow-visible" cdkScrollable>




            <div class="w-full inline-block p-6 sm:p-10 text-left print:p-0">
                <div
                    class="w-full  p-16 rounded-2xl shadow bg-card print:w-auto print:p-0 print:rounded-none print:shadow-none print:bg-transparent">

                    <div class="flex items-center">
                        <div class="grid grid-rows-2 place-items-center ">

                            <div class="grid-cols-2  place-items-center">


                                <button mat-button type="button" matTooltip="Close" class="hide-on-print"
                                    [routerLink]="['..']">
                                    <span class="inline-flex items-center">
                                        <mat-icon svgIcon="feather:x"></mat-icon>
                                    </span>
                                </button>

                                <button mat-button type="button" class="hide-on-print" (click)="printPage()">
                                    <span class="inline-flex items-center">
                                        <mat-icon svgIcon="feather:file"></mat-icon>
                                    </span>
                                </button>



                            </div>
                        </div>
                    </div>


                    <div class="flex items-start">

                        <div class="grid grid-rows-2 place-items-start ">
                            <div class="grid auto-cols-max grid-flow-col gap-x-5">

                                <div class="pl-4 border-l text-md">
                                    <div class="font-medium">Name: {{ product_bundle.bundle_name }}
                                    </div>
                                    <div>Description :{{product_bundle.bundle_description}}</div>
                                    <div>Created at : {{product_bundle.created}}</div>
                                    <div>Updated at : {{product_bundle.updated}}</div>
                                </div>
                            </div>

                        </div>






                    </div>




                    <div class="grid grid-cols-12 gap-x-1 mt-16">

                        <div class="col-span-6 font-medium text-md text-secondary">PRODUCT</div>
                        <div class="col-span-2 font-medium text-md text-right text-secondary">SIZE</div>
                        <div class="col-span-2 font-medium text-md text-right text-secondary">COLOR</div>


                        <!-- Divider -->
                        <div class="col-span-12 my-4 border-b"></div>

                        <!-- Products Loop -->
                        <ng-container *ngFor="let product of product_bundle.products"
                            class="col-span-12 flex items-center">
                            <!-- Product Avatar (Image) -->
                            <div class="col-span-6">
                                <div class="text-lg font-medium">{{ product.product.productName }}</div>
                                <div class="mt-2 text-md text-secondary">{{ product.product.description }}</div>
                            </div>

                            <div class="col-span-2 self-center text-right">{{ product.product.size }}</div>
                            <div class="col-span-2 self-center text-right">{{ product.product.color }}</div>

                            <!-- Adjusted col-span to 2 for TOTAL -->

                            <!-- Divider -->
                            <div class="col-span-12 my-4 border-b"></div>
                        </ng-container>

                        <!-- Spacer -->
                        <div class="col-span-12 mt-16"></div>

                        <!-- Subtotal -->

                        <!-- Divider -->
                        <div class="col-span-12 my-3 border-b"></div>

                        <!-- Total -->

                    </div>



                    <div class="mt-16">

                        <div class="flex items-start mt-4">
                            <img class="flex-0 w-20 mt-2" src="assets/images/logo/logo.png">
                            <div class="ml-6 text-sm text-secondary">

                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </div>
    </ng-container>



</ng-container>