import { Route } from '@angular/router';
import { ListPreOrdersComponent } from './list-pre-orders/list-pre-orders.component';
import { CreatePreOrdersComponent } from './create-pre-orders/create-pre-orders.component';
import { DetailPreOrdersComponent } from './detail-pre-orders/detail-pre-orders.component';
import { UnsavedChangesGuard } from 'app/page-detect/wm-can-leave/detect-changes.guard';



export const PreOrderRouting: Route[] = [
   
        {
            path     : '',
            component : ListPreOrdersComponent,
        },
        {
            path         : 'NEW',
            component    : CreatePreOrdersComponent,
            canDeactivate: [UnsavedChangesGuard], 

           
        },
        {
            path         : ':id',
            component    :DetailPreOrdersComponent,
          
          
        },
]
