<!-- vendorsCredit form -->

<div class="w-full h-full w-full">
    <form [formGroup]="vendorsCredit" class=" rounded shadow-lg h-full"
    
    (keydown.enter)="$event.preventDefault()"
    >
        <!-- Avatar -->
        <!-- Avatar code goes here -->

        <!-- Personal Information Form -->
        <div *ngIf="vendorsCredit" class="p-8">
            <div class="prose prose-sm max-w-3xl">
                <!-- HTML template -->
            </div>
            <div class="flex justify-end">
                <button mat-raised-button [routerLink]="['..']">
                    <span class="inline-flex items-center">
                        <mat-icon svgIcon="feather:x"></mat-icon>
                    </span>
                </button>
            </div>

            <div class="flex flex-col mt-8">
                <p class="text-lg font-medium">Basic information</p>
                <p class="text-secondary">Create Items or services which can account for Expense or payments depending on the situation</p>
                <mat-divider class="mt-8 mb-10"></mat-divider>

                <!-- Type: Goods/Service Checkbox -->
                <div class="flex mb-4">
                    <mat-radio-group formControlName="type">
                      <mat-radio-button style="margin-right: 20px;" value="Goods">Goods</mat-radio-button>
                      <mat-radio-button value="Service">Service</mat-radio-button>
                    </mat-radio-group>
                  </div>

                <!-- Name -->
                <div class="flex mb-4">
                    <mat-form-field style="max-width: 600px" class="flex-auto">
                        <mat-label>Name</mat-label>
                        <input matInput type="text" class="elevated-input" placeholder="Add Name"
                            formControlName="name" />
                        <mat-icon class="icon-size-5" matPrefix [svgIcon]="'feather:user'"></mat-icon>
                    </mat-form-field>
                </div>

                <!-- SKU -->
                <div class="flex mb-4">
                    <mat-form-field style="max-width: 600px" class="flex-auto">
                        <mat-label>SKU</mat-label>
                        <input matInput type="text" class="elevated-input" placeholder="Add SKU"
                            formControlName="sku" />
                        <mat-icon class="icon-size-5" matPrefix [svgIcon]="'feather:hash'"></mat-icon>

                    </mat-form-field>
                </div>

                <!-- Unit -->
                <div class="flex mb-4">
                    <mat-form-field style="max-width: 600px" class="flex-auto">
                        <mat-label>Unit</mat-label>
                        <input matInput type="text" placeholder="Add Unit" formControlName="unit" />
                        <mat-select matSuffix formControlName="unit_type">
                            <mat-option [value]="'cm'">cm</mat-option>
                            <mat-option [value]="'in'">in</mat-option>

                        </mat-select>
                        <mat-icon class="icon-size-5" matPrefix [svgIcon]="'feather:hash'"></mat-icon>
                    </mat-form-field>
                </div>

                <mat-checkbox formControlName="type">Returnable Item</mat-checkbox>

                <mat-divider class="mt-5 mb-5"></mat-divider>

                <div class="flex mb-4">
                    <!-- Left Column: Fields up to UPC -->
                    <div class="flex-auto pr-4">
                  
                      <!-- Dimensions (Length X Width X Height) -->
                      <div class="flex mb-4">
                        <mat-form-field style="min-width:600px" class="mr-4">
                  
                          <mat-label>Dimensions (Height x Width x Length)</mat-label>
                          <div class="flex items-center">
                            <input matInput type="text" placeholder="Height" class="mr-2" />
                            <input matInput type="text" placeholder="Width" class="mx-2" />
                            <input matInput type="text" placeholder="Length" class="ml-2" />
                          </div>
                          <mat-select matSuffix formControlName="dimensions_type">
                            <mat-option [value]="'cm'">cm</mat-option>
                            <mat-option [value]="'in'">in</mat-option>
                          </mat-select>
                          <mat-icon class="icon-size-5 mt-2" matPrefix [svgIcon]="'feather:hash'"></mat-icon>
                        </mat-form-field>
                      </div>
                  
                      <!-- Manufacturer -->
                      <div class="flex mb-4">
                        <mat-form-field style="max-width: 600px" class="mr-4">
                          <mat-label>Manufacturer</mat-label>
                          <input matInput type="text" placeholder="Add Manufacturer" formControlName="manufacturer" />
                          <mat-icon class="icon-size-5" matPrefix [svgIcon]="'feather:user'"></mat-icon>
                        </mat-form-field>
                      </div>
                  
                    </div>
                  
                    <!-- EAN -->
                    <div class="flex-auto pl-4">
                  
                      <!-- Weight -->
                      <div class="flex mb-4">
                        <mat-form-field style="max-width: 600px" class="mr-4">
                          <mat-label>Weight</mat-label>
                          <input matInput type="text" placeholder="Add Weight" formControlName="weight" />
                          <mat-select matSuffix formControlName="weight_type">
                            <mat-option [value]="'kg'">kg</mat-option>
                            <mat-option [value]="'g'">g</mat-option>
                            <mat-option [value]="'lg'">lg</mat-option>
                            <mat-option [value]="'oz'">oz</mat-option>
                          </mat-select>
                          <mat-icon class="icon-size-5" matPrefix [svgIcon]="'feather:user'"></mat-icon>
                        </mat-form-field>
                      </div>
                  
                      <!-- Brand -->
                      <div class="flex mb-4">
                        <mat-form-field class="w-100">
                          <mat-label>Brand</mat-label>
                          <input matInput type="text" placeholder="Add Brand" formControlName="brand" />
                          <mat-icon class="icon-size-5" matPrefix [svgIcon]="'feather:user'"></mat-icon>
                        </mat-form-field>
                      </div>
                  
                    </div>
                  </div>
                  
                  


                <mat-divider class="mt-5 mb-4"></mat-divider>
                <!-- Sales Information -->

                <mat-checkbox class="mb-2" formControlName="salesInformation">Sales Information</mat-checkbox>
                <div class="flex mb-4">
                    <!-- Left Column: Fields up to UPC -->
                    <div class="flex-auto pr-4">
                        <!-- Selling Price (NPR) -->
                        <div class="flex mb-4">
                            <mat-form-field style="max-width: 600px" class="flex-auto">
                                <mat-label>Selling Price (NPR)</mat-label>
                                <input matInput type="text" onkeypress="return event.charCode>=48 && event.charCode<=57" placeholder="Add Selling Price"
                                    formControlName="sellingPrice"   [required]="vendorsCredit.get('salesInformation').value"/>
                                <mat-icon class="icon-size-5" matPrefix [svgIcon]="'feather:hash'"></mat-icon>
                            </mat-form-field>

                        </div>

                        <!-- Account for Sales -->
                        <div class="flex mb-4">
                            <mat-form-field style="max-width: 600px" class="flex-auto">
                                <mat-label>Account (Sales)</mat-label>
                                <mat-select placeholder="Items Rates Are" name="Tax" formControlName="accountSales"
                                    class="elevated-input"  [required]="vendorsCredit.get('salesInformation').value">
                                                                    <mat-option value="Sales">Sales</mat-option>

                                </mat-select>


                                <mat-icon class="icon-size-5" matPrefix [svgIcon]="'feather:user'"></mat-icon>
                            </mat-form-field>
                        </div>

                        <!-- Description for Sales -->
                        <div class="flex mb-4">
                            <mat-form-field style="max-width: 600px" class="flex-auto">
                                <mat-label>Description (Sales)</mat-label>
                                <input matInput type="text" placeholder="Add Description"
                                    formControlName="descriptionSales" />
                                <mat-icon class="icon-size-5" matPrefix [svgIcon]="'feather:user'"></mat-icon>
                            </mat-form-field>
                        </div>

                        <!-- Tax for Sales -->
                        <div class="flex mb-4">
                            <mat-form-field style="max-width: 600px" class="flex-auto">
                                <mat-label>Tax (Sales)</mat-label>
                                <input matInput type="text" placeholder="Add Tax" formControlName="taxSales" />
                                <mat-icon class="icon-size-5" matPrefix [svgIcon]="'feather:user'"></mat-icon>
                            </mat-form-field>
                        </div>
                        
                    </div>


                    <!-- Purchase Information -->
                    <div class="flex-auto pl-4">
                        <mat-checkbox class="mb-2" formControlName="purchaseInformation">Purchase Information</mat-checkbox>

                        <!-- Cost Price (NPR) -->
                        <div class="flex mb-4">
                            <mat-form-field style="max-width: 600px" class="flex-auto">
                                <mat-label>Cost Price (NPR)</mat-label>
                                <input matInput type="text" onkeypress="return event.charCode>=48 && event.charCode<=57" placeholder="Add Cost Price"
                                    formControlName="costPrice"  [required]="vendorsCredit.get('purchaseInformation').value" />
                                <mat-icon class="icon-size-5" matPrefix [svgIcon]="'feather:user'"></mat-icon>
                            </mat-form-field>
                        </div>

                        <!-- Account for Purchase -->
                        <div class="flex mb-4">
                            <mat-form-field style="max-width: 600px" class="flex-auto">
                                <mat-label>Account (Purchase)</mat-label>
                                <mat-select placeholder="Items Rates Are" name="Tax" formControlName="accountPurchase"
                                    class="elevated-input" [required]="vendorsCredit.get('purchaseInformation').value">


                                    <mat-option value="Expense">Expense</mat-option>
                                  
                                   
                                </mat-select>
                                <mat-icon class="icon-size-5" matPrefix [svgIcon]="'feather:user'"></mat-icon>
                            </mat-form-field>
                        </div>

                        <!-- Description for Purchase -->
                        <div class="flex mb-4">
                            <mat-form-field style="max-width: 600px" class="flex-auto">
                                <mat-label>Description (Purchase)</mat-label>
                                <input matInput type="text" placeholder="Add Description"
                                    formControlName="descriptionPurchase" />
                                <mat-icon class="icon-size-5" matPrefix [svgIcon]="'feather:user'"></mat-icon>
                            </mat-form-field>
                        </div>

                        <!-- Tax for Purchase -->
                        <div class="flex mb-4">
                            <mat-form-field style="max-width: 600px" class="flex-auto">
                                <mat-label>Tax (Purchase)</mat-label>
                                <input matInput type="text" placeholder="Add Tax" formControlName="taxPurchase" />
                                <mat-icon class="icon-size-5" matPrefix [svgIcon]="'feather:user'"></mat-icon>
                            </mat-form-field>
                        </div>

                        <!-- Preferred Vendor -->
                        <div class="flex mb-4 max-w-2xl">
                            <mat-form-field style="max-width: 600px;" class="flex-auto">
                                <mat-label>Vendors</mat-label>
                                <input style="font-size: small;" matInput type="text" placeholder="Search Vendors" [formControl]="vendorControl"
                                    aria-label="Vendors" [matAutocomplete]="auto" />
                                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayOption"
                                    (optionSelected)="selectOption($event.option.value)">
                                    <mat-option style="height: fit-content"
                                        *ngFor="let vendor of filteredVendor$ | async" [value]="vendor">
                                        <div style="display: flex; align-items: center">
                                            <img alt="" class="example-option-img" src="assets/Avatar_male2.png"
                                                height="25" style="
                                                    margin-right: 8px;
                                                    flex-shrink: 0;
                                                " />
                                            <span class="example-option-name">{{
                                                vendor.firstName
                                                }}</span>
                                            <span class="example-option-sku">
                                                - {{ vendor.lastName }}</span>
                                        </div>
                                    </mat-option>
                                    <mat-option style="font-size:small ;" (click)="openVendorDetails()">
                                        Add Vendor<mat-icon svgIcon="feather:plus"></mat-icon>
                                    </mat-option>

                                    <mat-option style="height: fit-content;font-size:small ;"
                                        *ngIf="(filteredVendor$ | async)?.length === 0" [disabled]="true">
                                        NO VENDOR FOUND
                                        <br />
                                    </mat-option>
                                </mat-autocomplete>
                                <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:user'"></mat-icon>
                            </mat-form-field>
                        </div>



                        <!-- Description -->

                    </div>
                    

                </div>
                <p class="text-lg font-medium mt-4">Create Inventory Entry</p>
                <p class="text-secondary">You cannot enable/disable inventory tracking once you've created transactions for this item
           
                </p>
                <br>
                <mat-checkbox formControlName="inventory_type">Track Inventory for this item</mat-checkbox>
                <mat-divider class="mt-8 mb-5"></mat-divider>
                
                <!-- Content to be conditionally displayed -->
                <div *ngIf="vendorsCredit.get('inventory_type').value">
                  <!-- Your content here -->
              
                    <div class="flex mb-4">
                        <!-- <mat-form-field style="max-width: 600px" class="flex-auto">
                            <mat-label>Inventory Account</mat-label>
                            <input matInput type="text" class="elevated-input" placeholder="Inventory Account"
                                formControlName="inventory_account" />
                            <mat-icon class="icon-size-5" matPrefix [svgIcon]="'feather:user'"></mat-icon>
                        </mat-form-field>    -->
                    </div>
    
                    <!-- SKU -->
                    <div class="flex mb-4">
                        <mat-form-field style="max-width: 600px;margin-right:30px;" class="flex-auto">
                            <mat-label>Inital Stock</mat-label>
                            <input matInput type="text" class="elevated-input" placeholder="Opening Stock"
                                formControlName="opening_stock" />
                            <mat-icon class="icon-size-5" matPrefix [svgIcon]="'feather:hash'"></mat-icon>
    
                        </mat-form-field>
                        <!-- <mat-form-field style="max-width: 600px;" class="flex-auto mr-5">
                            <mat-label>Openeing Stock Rate per unit</mat-label>
                            <input matInput type="stock_r_p_r" placeholder="Add Unit" formControlName="unit" />
                           
                            <mat-icon class="icon-size-5" matPrefix [svgIcon]="'feather:hash'"></mat-icon>
                        </mat-form-field> -->
                    </div>
    
                    <!-- Unit -->
                    <!-- <div class="flex mb-4">
                        <mat-form-field style="max-width: 600px" class="flex-auto">
                            <mat-label>Reorder Point</mat-label>
                            <input matInput type="text" placeholder="rendor point" formControlName="rendor_point" />
                           
                            <mat-icon class="icon-size-5" matPrefix [svgIcon]="'feather:hash'"></mat-icon>
                        </mat-form-field>
    
                 
                    </div> -->
                </div>
               

               


                <mat-divider class="mt-5 mb-5"></mat-divider>

                <div class="flex items-center justify-start mt-10">
                    <button  style="margin-right: 10px;" (click)="sumbitVendorsInfo()"
                        class="px-6 py-2 rounded text-white bg-blue-500 hover:bg-blue-600" mat-flat-button
                        [color]="'primary'">
                        Save
                    </button>
                 
                        <button [routerLink]="['..']"
                            class="px-6 py-2 rounded text-white bg-blue-500 hover:bg-blue-600" mat-flat-button
                            [color]="'primary'">
                            Cancle
                        </button>
                </div>
            </div>

        </div>
    </form>
</div>