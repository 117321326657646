<div *ngIf="dataSource" class="container">
  <div class="fuse-pack-not-shipped">
    <fuse-pack-not-shipped [dataTableSource]=dataSource></fuse-pack-not-shipped>
  </div>
  <!-- ####################### -->
  <!-- table for the shipped package list -->
  <!-- ######################## -->
  <div class="fuse-shipped-package">
    <fuse-shipped-package [dataTableSource]=dataSource></fuse-shipped-package>
  </div>
  <!--#############  -->
  <!-- table for delivered packages  -->
  <!--#############  -->
  <div class="fuse-delivered-package">
    <fuse-delivered-package [dataTableSource]=dataSource> </fuse-delivered-package>
  </div>
</div>

<app-loader *ngIf="!dataSource"></app-loader>

