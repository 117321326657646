
    <p class="text-lg font-medium">Product Bundle</p>
    <p class="text-secondary mt-2">Set your bundle description here, The base product for the bundle has already been
        selected.<br>

        Add details like color and size and its quantity the total price will be amounted as it's rate in sales Order's
        product section .
    </p>
    <mat-divider class="mt-3 mb-4"></mat-divider>


    <form [formGroup]="productBundlePopUpForm" class="rounded shadow-lg h-full"
        (keydown.enter)="$event.preventDefault() "  (ngSubmit)="submitForm(productBundlePopUpForm.value)">


        <ng-container
            *ngFor="let product of productBundlePopUpForm.get('products')['controls']; let i = index; let first = first; let last = last; trackBy: trackByFn">

            <div class="flex mb-4" [ngClass]="{'fadeInOut': animationFlags[i]}">

                <mat-form-field class="fuse-mat-no-subscript w-200 fuse-mat-rounded fuse-mat-emphasized-affix mr-2">

                    <mat-label *ngIf="first">Product</mat-label>
                    <mat-error *ngIf="match_stock[i]">
                        No stock </mat-error>
                    <input [readonly]="true" class="small-input-text" style="height: 50px" matInput
                        [formControl]="product.get('product')" placeholder="Type SKU or Product Name" required
                        [matAutocomplete]="auto" [ngStyle]="product.style"
                        [attr.disabled]="product.disabled ? true : null" (input)="
                    filterOptionsProduct($event.target.value)
                " (focus)="filterOptionsProduct($event.target.value)" />

                    <mat-icon *ngIf="true" class="icon-size-5" matSuffix [svgIcon]="'verified'"
                        style="font-size: 24px; color: green;"></mat-icon>


                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="
                        displayProductOption.bind(this)
                    " (optionSelected)="
                        selectOptionProduct(
                            $event.option.value,
                            i
                        )
                    ">
                        <mat-option style="height: auto; display: flex; align-items: center; padding: 4px 16px;"
                            *ngFor="let option of filteredProductOptions$ | async; let i = index" [value]="option"
                            [ngStyle]="option.style" [disabled]="option.disabled">

                            <div style="
                                display: flex;
                                align-items: center;
                            ">

                                <span class="example-option-name">{{
                                    option.productName
                                    }}</span>
                                <span class="example-option-sku">
                                    - {{ option.sku }}</span>
                            </div>
                        </mat-option>

                        <mat-option style="
                            cursor: pointer;
                            height: fit-content;
                        " *ngIf="
                            (filteredProductOptions$ | async)
                                ?.length === 0
                        " [disabled]="true">
                            No options found
                            <br />
                            <mat-icon svgIcon="feather:plus">
                            </mat-icon>
                            Add Product
                        </mat-option>
                    </mat-autocomplete>

                    {{product_details[i]}}


                </mat-form-field>


                <ng-container *ngIf="!active[i]">
                    <!-- Render the mat-select when active is false -->
                    <mat-form-field class="fuse-mat-no-subscript w-75 fuse-mat-rounded fuse-mat-emphasized-affix mr-2">
                        <mat-label *ngIf="first">Size</mat-label>
                        <mat-icon matPrefix svgIcon="mat_solid:production_quantity_limits"></mat-icon>
                        <mat-error *ngIf="match_stock[i]">
                            No stock </mat-error>
                        <mat-select matInput [formControl]="product.get('size')" placeholder="Size" class="h-20"
                            (selectionChange)="onSelectionChange(i, true)">
                            <mat-option *ngFor="let size of sizes[i]" [value]="size.id">{{ size.name }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="match_stock[i]">
                            No stock </mat-error>
                    </mat-form-field>

                    <mat-form-field class="fuse-mat-no-subscript w-75 fuse-mat-rounded fuse-mat-emphasized-affix mr-2">
                        <mat-label *ngIf="first">Color</mat-label>
                        <mat-icon matPrefix svgIcon="mat_solid:production_quantity_limits"></mat-icon>
                        <mat-error *ngIf="match_stock[i]">
                            No stock </mat-error>
                        <mat-select matInput [formControl]="product.get('color')" placeholder="Color" class="h-20"
                            (selectionChange)="onSelectionChange(i, true)">
                            <mat-option *ngFor="let color of colors[i]" [value]="color.id">{{ color.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </ng-container>

                <ng-container *ngIf="active[i]">
                    <!-- Render a readonly input or display the selected value when active is true -->
                    <mat-form-field class="fuse-mat-no-subscript w-75 fuse-mat-rounded fuse-mat-emphasized-affix mr-2">
                        <mat-icon matPrefix svgIcon="mat_solid:production_quantity_limits"></mat-icon>

                        <mat-label *ngIf="first">Size</mat-label>
                        <input class="small-input-text" matInput [formControl]="product.get('size')" placeholder="Size"
                            style="height: 50px" class="small-input-text" readonly />


                    </mat-form-field>

                    <mat-form-field class="fuse-mat-no-subscript w-75 fuse-mat-rounded fuse-mat-emphasized-affix mr-2">
                        <mat-label *ngIf="first">Color</mat-label>
                        <mat-icon matPrefix svgIcon="mat_solid:production_quantity_limits"></mat-icon>

                        <input class="small-input-text" matInput [formControl]="product.get('color')"
                            placeholder="Color" style="height: 50px" readonly />
                    </mat-form-field>
                </ng-container>




                <div class="form-field-container">
                    <mat-form-field class="fuse-mat-no-subscript w-25 fuse-mat-rounded fuse-mat-emphasized-affix mr-2">
                        <mat-label *ngIf="first">Quantity</mat-label>

                        <mat-icon matPrefix svgIcon="mat_solid:production_quantity_limits"></mat-icon>
                        <input class="small-input-text" style="height: 50px" matInput type="text" onkeypress="return event.charCode>=48 && event.charCode<=57"
                            [formControl]="product.get('quantity')" placeholder="Quantity" [max]="current_stock[i]" />

                        <mat-error *ngIf="quantityExceedsStock(i)">
                            Quantity cannot exceed available stock.
                        </mat-error>
                    </mat-form-field>

                    <!-- Display current_stock below the form field -->
                    <div *ngIf="active[i]" class="current-stock-container m-2">
                        <span [ngClass]="{
                        'chip chip--success': current_stock[i] > 10,
                        'chip chip--warning': current_stock[i] <= 10 && current_stock[i] > 5,
                        'chip chip--error': current_stock[i] <= 5
                    }" style="line-height: 16px;">

                            <i style="font-size: 11px; margin-top: -2px;" class="chip__label">
                                <strong>Available stock: {{ current_stock[i] }} </strong>
                            </i>
                        </span>
                    </div>
                </div>


                <mat-form-field [ngClass]="formFieldHelpers" class="fuse-mat-no-subscript w-75 fuse-mat-rounded fuse-mat-emphasized-affix mr-2">
                    <mat-label *ngIf="first">Rate</mat-label>

                    <input [readonly]="active[i]" class="small-input-text" readonly style="height: 50px" matInput
                    type="text" onkeypress="return event.charCode>=48 && event.charCode<=57" [formControl]="product.get('rate')" placeholder="Rate" />
                    <span matPrefix>रु</span>
                    <span matSuffix>.00</span>
                </mat-form-field>

                <mat-form-field [ngClass]="formFieldHelpers" class="fuse-mat-no-subscript w-75 fuse-mat-rounded fuse-mat-emphasized-affix mr-2">
                    <mat-label *ngIf="first">Amount</mat-label>
                    <input readonly class="small-input-text" style="height: 50px" matInput type="text" onkeypress="return event.charCode>=48 && event.charCode<=57"
                        [formControl]="product.get('amount')" placeholder="Amount" />
                    <span matPrefix>रु</span>
                    <span matSuffix>.00</span>
                </mat-form-field>

                <!-- Remove product -->
                <ng-container *ngIf="!(first && last)">
                    <div class="flex items-center w-10 pl-2">
                        <button mat-icon-button type="button" (click)="removeProductField(i)" matTooltip="Reset">
                            <mat-icon svgIcon="mat_outline:autorenew"></mat-icon>
                        </button>
                    </div>
                </ng-container>



            </div>



        </ng-container>



     <div class="product-total-feedback-wrap mt-5 mb-3 ">
            <div class="mt-5 form-card w-200 ">
                <mat-card >
                  
                            <div class="sub-total-row flex mb-4">
                                <div class="label-container">
                                    <mat-label><strong>Sub-Total</strong>
                                    </mat-label>
                                </div>
                                <mat-form-field [ngClass]="formFieldHelpers"
                                    class="input-container price-input-wrap fuse-mat-rounded fuse-mat-emphasized-affix">
                                    <input readonly type="text"
                                        onkeypress="return event.charCode>=48 && event.charCode<=57" matInput
                                        placeholder="Sub Total" required name="sub_total"
                                        [formControlName]="'sub_total'"
                                        class="elevated-input small-input-text" />

                                    <span matPrefix>रु</span>
                                    <span matSuffix>.00</span>

                                </mat-form-field>
                                <div class="typed-text">
                                    <strong>
                                        {{
                                        productBundlePopUpForm.get("sub_total").value
                                        }}
                                        <span matSuffix>.00</span>
                                    </strong>
                                </div>
                            </div>

                            <div class="sub-total-row flex mb-4">
                                <div class="label-container">
                                    <mat-label>Discount </mat-label>
                                </div>
                                <mat-form-field [ngClass]="formFieldHelpers"
                                    class="input-container price-input-wrap fuse-mat-rounded fuse-mat-emphasized-affix">
                                    <input type="text"
                                        onkeypress="return event.charCode>=48 && event.charCode<=57" matInput
                                        placeholder="Discount" required name="discount"
                                        [formControlName]="'discount'"
                                        class="elevated-input small-input-text" />
                                    <span matPrefix>रु</span>

                                    <mat-select [formControlName]="'discountSuffix'" matSuffix>
                                        <mat-option [value]="'%'">%</mat-option>
                                        <mat-option [value]="'rs'">रु</mat-option>
                                    </mat-select>

                                </mat-form-field>

                                <div class="typed-text">
                                    {{ productBundlePopUpForm.get("discount").value }}
                                    <ng-container *ngIf="
                                    productBundlePopUpForm.get('discountSuffix')
                                        .value === '%'
                                ">
                                        <app-percentage-icon matPrefix>
                                            %</app-percentage-icon>
                                    </ng-container>
                                    <ng-container *ngIf="
                                    productBundlePopUpForm.get('discountSuffix')
                                        .value === 'rs'
                                ">
                                        <app-rs-icon matPrefix>.00 रु</app-rs-icon>
                                    </ng-container>
                                </div>
                            </div>

                        

                            <div class="sub-total-row flex mb-4">
                                <div class="label-container bold-label">
                                    <mat-label> Adjustment</mat-label>
                                </div>
                                <mat-form-field [ngClass]="formFieldHelpers"
                                    class="input-container price-input-wrap fuse-mat-rounded fuse-mat-emphasized-affix">
                                    <input type="text"
                                        onkeypress="return event.charCode>=48 && event.charCode<=57" matInput
                                        placeholder="Adjustment" required name="adjustment"
                                        [formControlName]="'adjustment'"
                                        class="elevated-input small-input-text" />
                                    <span matPrefix>रु</span>

                                    <mat-select [formControlName]="'adjustmentSuffix'" matSuffix>
                                        <mat-option [value]="'%'">
                                            %</mat-option>
                                        <mat-option [value]="'rs'">रु</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div class="typed-text">
                                    {{ productBundlePopUpForm.get("adjustment").value }}
                                    <ng-container *ngIf="
                                    productBundlePopUpForm.get(
                                        'adjustmentSuffix'
                                    ).value === '%'
                                ">
                                        <app-percentage-icon matPrefix>
                                            %</app-percentage-icon>
                                    </ng-container>
                                    <ng-container *ngIf="
                                    productBundlePopUpForm.get(
                                        'adjustmentSuffix'
                                    ).value === 'rs'
                                ">
                                        <app-rs-icon matPrefix>.00 रु</app-rs-icon>
                                    </ng-container>
                                </div>
                            </div>

                            <div class="sub-total-row flex mb-4">
                                <div class="label-container">
                                    <mat-label><strong> Total NPR</strong></mat-label>
                                </div>
                                <mat-form-field [ngClass]="formFieldHelpers"
                                    class="input-container price-input-wrap fuse-mat-rounded fuse-mat-emphasized-affix">
                                    <input readonly matInput placeholder="Total" type="text"
                                        onkeypress="return event.charCode>=48 && event.charCode<=57" required
                                        name="total" [formControlName]="'total'"
                                        class="elevated-input  small-input-text" />
                                    <span matPrefix>रु</span>
                                    <span matSuffix>.00</span>
                                </mat-form-field>
                                <div class="typed-text">
                                    <strong>
                                        <span matPrefix>रु</span>
                                        {{ productBundlePopUpForm.get("total").value }}
                                        <span matSuffix>.00</span>
                                    </strong>
                                </div>
                            </div>



                </mat-card>
            </div>
        </div>

        <button class="px-6 py-2 mt-5 mb-5 rounded text-white bg-red-500 hover:bg-red-600" mat-flat-button type="submit">
            Save
        </button>
    </form>






