<div [ngClass]="{'dark:flex': isDarkMode}"
  class="sm:absolute sm:inset-0 flex flex-col flex-auto min-w-0 sm:overflow-hidden bg-card dark:bg-transparent">


  <!-- Header -->
  <div class="relative flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between py-8 px-6 md:px-8 border-b">
    <!-- Loader -->
    <div class="absolute inset-x-0 bottom-0" *ngIf="isLoading">
      <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
    </div>
    <!-- Title -->
    <div class="text-4xl font-bold tracking-tight">INVENTORY SHEET</div>
    <!-- Actions -->
    <div class="flex flex-shrink-0 items-center mt-6 sm:mt-0 sm:ml-4">
      <!-- Search -->


      <mat-form-field class="fuse-mat-rounded fuse-mat-no-subscript min-w-50 mr-2">
        <mat-icon matPrefix [svgIcon]="'heroicons_outline:search'"></mat-icon>
        <input matInput [autocomplete]="'off'" (keyup)="applyFilter($event)" [placeholder]="'Search Inventory'" />
      </mat-form-field>
      <button [color]="isFilterApplied ? 'accent' : 'primary'" class="mr-3" mat-flat-button (click)="detailFiltering()">
        <mat-icon matTooltip="Advance Filtering" [svgIcon]="'heroicons_outline:search'"></mat-icon>
        <span class="">{{ isFilterApplied ? 'Clear Filtering' : 'Detail Filtering' }}</span>
      </button>


      <button [color]="'primary'" mat-flat-button (click)="exportInventoryExcel()">
        <mat-icon matTooltip="Export your tabel to excel" [svgIcon]="'heroicons_outline:clipboard'"></mat-icon>
        <span class="">Export </span>
      </button>


    </div>


  </div>




  <!-- Main -->
  <div class="flex flex-auto overflow-hidden">
    <!-- Products list -->



    <div class="flex flex-col flex-auto sm:mb-18 overflow-hidden sm:overflow-y-auto">
      <div class="mat-elevation-z8">
        <ng-container *ngIf="!isLoading && dataSourceProductVariants.data.length === 0; else productsTable">
          <div class="no-data-message">
            <span>No data found</span>
          </div>
        </ng-container>

        <ng-template #productsTable>

          <table mat-table [dataSource]="dataSourceProductVariants" matSort matPaginator class="inventory-table" id="inventory-table">
            <!-- Columns for Product Variants -->

            <ng-container *ngFor="let column of prodcutVarientsdisplayedColumns" [matColumnDef]="column">
              <th mat-header-cell style="color:white;" *matHeaderCellDef mat-sort-header>{{ column }}</th>

              <td mat-cell *matCellDef="let row" [style.width]="getColumnWidth(column)">
                <ng-container *ngIf="column !== 'Price' && column !== 'Stock' && column !== 'Name'">

                  <span class="ml-3" [ngStyle]="getStyleForColumn(column, row)">{{ row[column] }}</span>

                </ng-container>

                <ng-container *ngIf="column === 'Price'">
                  <span class="ml-3" [ngStyle]="getStyleForColumn(column, row)"> रू {{ row[column] }}</span>
                </ng-container>

                <ng-container *ngIf="column === 'Name'">
                  <span [ngStyle]="getStyleForColumn(column, row)" class="name-container">
                     {{ row[column] }}
                    <ng-container *ngIf="!(row.restock && row.PreOrder)">
                      <mat-slide-toggle [(ngModel)]="row.PreOrder" (click)="onToggleChange(row, $event)"
                        [disabled]="!isToggleClickable">
                        <div class="chip chip--success" *ngIf="row.PreOrder" style="line-height: 16px;">
                          <i style="font-size: 11px; margin-top: -2px;" class="chip__label">
                            <strong>Pre-Order</strong>
                          </i>
                        </div>
                      </mat-slide-toggle>
                    </ng-container>
                    <div class="chip chip--success ml-5" *ngIf="row.restock && row.PreOrder" style="line-height: 16px;">
                      <i style="font-size: 11px; margin-top: -2px;" class="chip__label">
                        <strong>Restock Pre-Order</strong>
                      </i>
                    </div>
                    <div class="dot mr-2" [ngStyle]="{ 'background-color': row.restock ? 'green' : 'red' }"
                      [matTooltip]="getTooltipText(row.restock)">
                    </div>
                  </span>
                </ng-container>




                <ng-container *ngIf="column === 'Stock'">
                  <div class="hidden lg:flex items-center">
                    <span class="ml-3" [ngStyle]="getStyleForColumn(column, row)"> {{ row[column] }}</span>

                    <div [appStockIndicator]="row.Stock"></div>
                  </div>
                </ng-container>

                <ng-container *ngIf="column === 'Actions'">
                  <div class="hidden lg:flex items-center">
                    <span class="ml-3 mb-1">
                      <button mat-icon-button (click)="viewProductVariation(row.id)">
                        <mat-icon class="icon-size-4" svgIcon="feather:eye"></mat-icon>
                      </button>
                    </span>
                  </div>
                </ng-container>



              </td>
            </ng-container>




            <!-- Header and Row Definitions -->
            <tr mat-header-row *matHeaderRowDef="prodcutVarientsdisplayedColumns;"></tr>
            <tr mat-row *matRowDef="let row; columns: prodcutVarientsdisplayedColumns"></tr>
          </table>

          <mat-paginator #paginator [length]="totalProducts" [pageSize]="100"
            [pageSizeOptions]="[100, 500, 1000, 10000]" showFirstLastButtons (page)="onPageChange($event)">
          </mat-paginator>  
        </ng-template>

      </div>
    </div>
  </div>
</div>