import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, OnInit, Output, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FuseCardComponent } from '@fuse/components/card';
import { ConfirmPopupComponent } from 'app/confirm-popup/confirm-popup.component';
import { COMMA, ENTER } from '@angular/cdk/keycodes';


import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { map, startWith, take } from 'rxjs/operators';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { OptionSubmissionService } from './product.options.service';
import Swal from 'sweetalert2';
import { SizeService } from 'app/size/size.service';
import { ColorService } from 'app/colors/color.service';
import { Color } from 'app/colors/color.type';
import { Size } from 'app/size/size.type';

export interface User {
  name: string;
}

@Component({
  selector: 'app-product-options-popup',
  templateUrl: './product-options-popup.component.html',
  styleUrls: ['./product-options-popup.component.scss']
})
export class ProductOptionsPopupComponent implements OnInit {
  @Output() formSubmitted: EventEmitter<any> = new EventEmitter<any>();
  productOption_popup: FormGroup;
  addOnBlur = true;
  @ViewChild('fuseCard') fuseCard: FuseCardComponent;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  colorsCtrl = new FormControl('');
  sizesCtrl = new FormControl('');
  filteredcolors: Observable<string[]>;
  selectedOption: string = 'color'; // Set a default option
  showColor: boolean = false;
  showSizes: boolean = false;
  selectedColor: string = "";
  allcolors: any[] = ['Red', 'Blue', 'White',];
  allsizes: any[] = ['XL', 'X', "Small", "L", "S", "M"];
  sizes: any[] = [];
  colors: any[] = [];

  submittedOptions: string[] = [];
  colors$: Observable<Color[]>;
  sizes$: Observable<Size[]>;
  // Initialize with an empty string
  filteredsizes: Observable<string[]>; // Add filtered sizes
  @ViewChild('colorInput') colorsInput: ElementRef<HTMLInputElement>;
  @ViewChild('sizesInput') sizesInput: ElementRef<HTMLInputElement>;
  @ViewChild('container') container: ElementRef<HTMLInputElement>;
  // Add this property to your component class
  disableColorOption: boolean = false;
  disableSizeOption: boolean = false;
  isShaking: boolean = false;

  announcer = inject(LiveAnnouncer);
  constructor(
    public optionSubmissionService: OptionSubmissionService,
    private _matDialog: MatDialogRef<ConfirmPopupComponent>,
    private _formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _size: SizeService,
    private _color: ColorService,

  ) {



  }

  getColorAndSize = () => {
    this.colors$ = this._color.getColors().pipe(take(1));
    this.sizes$ = this._size.getSizes().pipe(take(1));

    this.colors$.subscribe((colors) => {
      const data = colors['data'];

      this.allcolors = data.map((color) => {
        return {
          name: color.name,
          id: color.id,
          sku: color.sku,
        }

      });
    });

    this.sizes$.subscribe((sizes) => {
      const data = sizes['data'];
      this.allsizes = data.map((size) =>{
        return{
          name:size.name,
          id: size.id,
          sku: size.sku,
        }

      } );
    });
  }

  filterColorAndSize = () => {

    this.filteredcolors = this.colorsCtrl.valueChanges.pipe(
      startWith(null),
      map((colors: string | null) => (colors ? this._filter_chips(colors) : this.allcolors.slice())),
    );



    this.filteredsizes = this.sizesCtrl.valueChanges.pipe(
      startWith(null),
      map((size: string | null) => (size ? this._filter_chips_sizes(size) : this.allsizes.slice())),
    );
  }

  ngOnInit(): void {

    this.getColorAndSize();
    this.filterColorAndSize();

    this.productOption_popup = this._formBuilder.group({
      option_name: [''],
      color: this.colorsCtrl,
      sku: [''],
      sizes: this.sizesCtrl,

    });

    this.selectedOption = 'color';
  }



  onOptionSelected(event: MatAutocompleteSelectedEvent): void {
    const selectedColorValue = event.option.value;
    const selectedColorName = event.option.value.name;

    if (!this.colors.includes(selectedColorValue)) {

      this.colors.push(selectedColorValue);
    }

    this.colorsInput.nativeElement.value = '';
  }


  isColorDisabled(color: string): boolean {
    return this.colors.includes(color);
  }

  isSizeDisabled(sizes: string): boolean {
    return this.sizes.includes(sizes);
  }



  removeSizes(sizes: string): void {
    const index = this.sizes.indexOf(sizes);

    if (index >= 0) {
      this.sizes.splice(index, 1);

      this.announcer.announce(`Removed ${sizes}`);
    }
  }

  remove(colors: string): void {
    const index = this.colors.indexOf(colors);

    if (index >= 0) {
      this.colors.splice(index, 1);

      this.announcer.announce(`Removed ${colors}`);
    }
  }



  selectedSizes(event: MatAutocompleteSelectedEvent): void {
    const selectedSize = event.option.value;

    if (!this.sizes.includes(selectedSize)) {
      this.sizes.push(selectedSize);
    }
    this.sizesInput.nativeElement.value = '';
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const selectedColor = event.option.value;
    const selecteColorName = event.option.value.name;


    if (!this.colors.includes(selectedColor)) {
      this.colors.push(selectedColor);
    }
    this.colorsInput.nativeElement.value = '';

  }



  private _filter_chips(value: any): any[] {
    const filterValue = typeof value === 'string' ? value.toLowerCase() : '';
    return this.allcolors.filter(colors => colors.name.toLowerCase().includes(filterValue));
  }

  private _filter_chips_sizes(value: any): any[] {
    const filterValue = typeof value === 'string' ? value.toLowerCase() : '';
    return this.allsizes.filter(sizes => sizes.name.toLowerCase().includes(filterValue));
  }


  submitForm(formData: any): void {
    if (this.productOption_popup.valid) {
      const selectedOption = formData.option_name;
      if (!this.optionSubmissionService.isOptionSubmitted(selectedOption)) {
        this.optionSubmissionService.submitOption(selectedOption);

        // Disable the "Color" option after submitting it
        if (selectedOption === 'Color') {
          this.disableColorOption = true; // Add this property to your component
        }
        if (selectedOption === 'Size') {
          this.disableSizeOption = true; // Add this property to your component
        }

        // Check the selected option and update the form data accordingly
        if (selectedOption === 'Color') {
          let colorNames = [];
          let colorSKus = [];

          this.colors.forEach((color) => {

            colorNames.push(color.name);
            colorSKus.push(color.sku);
          });

          formData.sizes = null; // Clear the sizes data if not needed
          formData.sku = colorSKus;
          formData.color = colorNames;


        } else if (selectedOption === 'Size') {
          let sizeNames = [];
          let sizeSku = [];

          this.sizes.forEach((size =>{

            sizeNames.push(size.name);
            sizeSku.push(size.sku);
          }));


          formData.sku = sizeSku;
          formData.sizes = sizeNames;
          formData.color = null; 
        }

        // Emit the form data
        this.formSubmitted.emit(formData);

        // Close the modal
        this._matDialog.close();
      } else {
        // Option has already

        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener(
              'mouseenter',
              Swal.stopTimer
            );
            toast.addEventListener(
              'mouseleave',
              Swal.resumeTimer
            );
          },
        });


        Toast.fire({
          icon: 'error',
          title: `This option is already selcted`,
        });
      }
    } else {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener(
            'mouseenter',
            Swal.stopTimer
          );
          toast.addEventListener(
            'mouseleave',
            Swal.resumeTimer
          );
        },
      });


      Toast.fire({
        icon: 'error',
        title: `The options cannot be blank `,
      });

    }

  }


  // Emit the form data
  stopWheelEventPropagation(event: WheelEvent): void {
    event.stopPropagation();
  }

  cancel(): void {
    this._matDialog.close();
  }


  isColorOptionSelected(color: string): boolean {
    return this.colors.includes(color);
  }


  pointOfsales(): void {
    const type = this.productOption_popup.controls['option_name'].value;

    if (type === 'Color' && !this.submittedOptions.includes('Color')) {
      this.showColor = true;
      this.showSizes = false;
    } else if (type === 'Size' && !this.submittedOptions.includes('Size')) {
      this.showSizes = true;
      this.showColor = false;
    }
  }



  vibrateComponent(): void {
    this.isShaking = true;

    setTimeout(() => {
      this.isShaking = false;
    }, 500);
  }
}