
import { AfterViewInit,  Component, OnInit, ViewChild} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';


import { MatTableDataSource } from '@angular/material/table';
import { ColorService } from '../color.service';
import { Color } from '../color.type';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import Swal from 'sweetalert2';

export interface ColorData {
  id: string;
  name: string;




}


@Component({
  selector: 'app-list-colors',
  templateUrl: './list-colors.component.html',
  styleUrls: ['./list-colors.component.scss']
})
export class ListColorsComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = [ 'id', 'name',  'actions'];

  dataSource: MatTableDataSource<ColorData>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  isLoading: boolean = true;

  colors: Color[];

  colors$: Observable<Color[]>;

  constructor(
  private  _color:ColorService
   
  ) { 

   
  }

  ngOnInit(): void {

    this.loadColors();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }


  deleteColor(id:any){

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
  }).then((result) => {

      if (result.isConfirmed) {
          this._color.deleteColor(id)

              .subscribe((isDeleted) => {
                  
                this.loadColors();

                  if (!isDeleted) {
                      Swal.fire("Error!", "Cannot Delete");
                      return;
                  }

                  // Display a success message
                  Swal.fire(
                      'Deleted!',
                      'Your file has been deleted.',
                      'success'
                  )
              });
      }
  });

  
  }
 
  
  
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


  loadColors(){
    this.colors$ = this._color
    .getColors()
    .pipe(take(1));
    this.colors$.subscribe((colors) => {
      this.colors = colors['data'];

      this.dataSource = new MatTableDataSource<ColorData>(

        this.colors
          .map((color) => {
            // Map nested properties of products array

            return {
              id: color.id,
              name: color.name


            };
          })
      );
      this.isLoading = false;

    });

  }

  }


