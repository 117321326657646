import { Route } from '@angular/router';

import { ListprPackagesCreditNotesComponent } from './listpr-packages-credit-notes/listpr-packages-credit-notes.component';
import { CreateprPackagesCreditNotesComponent } from './createpr-packages-credit-notes/createpr-packages-credit-notes.component';
import { DetailprPackagesCreditNotesComponent } from './detailpr-packages-credit-notes/detailpr-packages-credit-notes.component';


export const PrPackagesCreditNotesRoutes: Route[] = [
   
        {
            path     : '',
            component : ListprPackagesCreditNotesComponent,
        },
        {
            path         : 'NEW',
            component    : CreateprPackagesCreditNotesComponent,
           
        },
        {
            path         : ':id',
            component    :DetailprPackagesCreditNotesComponent,
          
          
        },
]
