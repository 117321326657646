import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[disableArrowUpDownWheel]',
})


export class DisableArrowUpDownWheelDirective {
  constructor(private el: ElementRef) {
    let input: HTMLInputElement = el.nativeElement;
    // disable the scroll wheel
    input.onwheel = () => {
      return false;
    };
    // disable the up/down arrow keys
    input.onkeydown = (event: KeyboardEvent) => {
      if (event.key === 'ArrowDown' || event.key === 'ArrowUp') return false;
      else return true;
    };
  }
}