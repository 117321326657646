import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import {
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { ProductPopupComponent } from '../products-popup/product-popup.component';
import { COMMA, ENTER, O, S } from '@angular/cdk/keycodes';
import { map, pluck, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { productsService } from '../products.service';
import { ProductOptionsPopupComponent } from '../product-options-popup/product-options-popup.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ProductVarientsComponent } from '../product-varients/product-varients.component';
import { productCategoryService } from 'app/product-categories/product-categories.service';
import { productCategories } from 'app/product-categories/product-categories.type';
import { OptionSubmissionService } from '../product-options-popup/product.options.service';
import { DatePipe, formatDate } from '@angular/common';
import { InventoryEntryService } from 'app/inventory-entry/inventory-entry.service';
import { productsGroupService } from 'app/product-group/product-group.service';
import { ProductCollection, productGroup } from 'app/product-group/product-group.type';
import { FabricService } from 'app/product-fabric/fabric-service.service';
import { SkuCreationService } from '../product-varients/sku-creation.service';
import { HasUnsavedChanges } from 'app/page-detect/wm-can-leave/detect-changes.guard';


export interface Fabric {
    name: string;
    id?: any;
    sku?: any;
}

interface ProductVariant {
    id?: any;
    varient?: string;
    size: number;
    color: number;
    price?: number;
    stock?: number;
    status: string;
    sku?: string;
    visibility: string;
    active?: string;
}
export interface CollectionOption {
    id: string;
    collection_name?: string;
}

let SIZE_CREDITS_DATA: ProductVariant[] = [

];



let SIZE_CREDITS_DATAS: ProductVariant[] = [

];

@Component({
    selector: 'app-create-product',
    templateUrl: './create-product.component.html',
    styleUrls: ['./create-product.component.scss'],

})
export class CreateProductComponent implements OnInit, OnDestroy, HasUnsavedChanges {
    Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
        },
    });

    config: any = {
        airMode: false,
        tabDisable: true,
        popover: {
            table: [
                ['add', ['addRowDown', 'addRowUp', 'addColLeft', 'addColRight']],
                ['delete', ['deleteRow', 'deleteCol', 'deleteTable']]
            ],
            image: [
                ['image', ['resizeFull', 'resizeHalf', 'resizeQuarter', 'resizeNone']],
                ['float', ['floatLeft', 'floatRight', 'floatNone']],
                ['remove', ['removeMedia']]
            ],
            link: [['link', ['linkDialogShow', 'unlink']]],
            air: [
                [
                    'font',
                    [
                        'bold',
                        'italic',
                        'underline',
                        'strikethrough',
                        'superscript',
                        'subscript',
                        'clear'
                    ]
                ]
            ]
        },
        height: '200px',
        uploadImagePath: '/api/upload',
        toolbar: [
            ['misc', ['undo', 'redo']],
            [
                'font',
                [
                    'bold',
                    'italic',
                    'underline',
                    'strikethrough',
                    'superscript',
                    'subscript',
                    'clear'
                ]
            ],
            ['fontsize', ['fontname', 'fontsize', 'color']],
            ['para', ['style0', 'ul', 'ol', 'paragraph', 'height']],
            ['insert', ['table', 'picture', 'link', 'video', 'hr']],
            ['customButtons', ['testBtn']]
        ]
    };


    @ViewChild('fruitInput') fruitInput: ElementRef<HTMLInputElement>;
    filteredCollection$: Observable<ProductCollection[]>;
    filteredFabric$: Observable<Fabric[]>;
    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true;
    secondary: boolean = false;
    SkuStatus: boolean = true;
    inner: boolean = false;
    outter: boolean = false;
    showFabricOptions: boolean = false;
    displayedColumns: string[] = ['Varient', 'Size', "Color", "SKU", 'Price'];
    displayedColumnss: string[] = ['Varient', 'Size', "Color", "SKU"];
    variations: any[] = [];
    variationss = [];
    pageSize: number = 5;
    currentPage: number = 1;
    searchText: string = '';
    totalPages: number = 0;
    isActives: boolean = false;
    dataSource: MatTableDataSource<ProductVariant>;
    dataSources: MatTableDataSource<ProductVariant>;
    sizeDataPushed = false;
    colorDataPushed = false;
    combinedVariations = [];
    updatedVariations = [];
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    stock: boolean = false;
    productForm: FormGroup;
    successMessage: string;
    errorMessage: string;
    separatorKeysCodes: number[] = [ENTER, COMMA];
    categoriesCTR = new FormControl('');
    filteredCat: Observable<string[]>;
    prodcutsCtr: string[] = ['MALE'];
    thumbnailURL: string;
    imageUrls: string[] = [];
    selectedColor: string;
    showsaleprice: boolean = false;
    showDiscount: boolean = false;
    collectionFormControl: FormControl = new FormControl();
    selectedCollection: ProductCollection | null = null;
    FabricFormControl: FormControl = new FormControl();
    selectedFabric: productGroup | null = null;
    vendorControl: FormControl = new FormControl();
    showBarcodeImage: boolean = false;
    isChecked = true;
    newCategory: string = '';
    on_sale: boolean = false;
    show_ppu: boolean = false;
    show_vairents: boolean = false;
    selectedCategories: string[] = [];
    show_ppu_toogle: boolean = false;
    sizes: any;
    color: any;
    showActions: boolean = false;
    public isEditPopupOpen = false;
    showImageCard: boolean = false;
    Id: string;
    category$: Observable<productCategories[]>;
    category: productCategories[];
    secondaryFabric: any[] = [];
    secondaryFabircOptions: any[] = [];
    filteredSecondaryFabric: Observable<string[]>;
    secondaryFabricControl = new FormControl('');
    innerFabric: any[] = [];
    innerFabricOptions: any[] = [];
    filteredInnerFabric: Observable<string[]>;
    innerFabricControl = new FormControl('');
    collection$: Observable<ProductCollection[]>;
    fabrics$: Observable<Fabric[]>;
    @ViewChild('secondaryFabricInput') secondaryFabricInput: ElementRef<HTMLInputElement>;
    @ViewChild('innerFabricInput') innerFabricInput: ElementRef<HTMLInputElement>;
    generatedSku: string = '';
    collectionSku: string = '';
    fabricSku: string = '';
    imagePreview: string | ArrayBuffer | null;


    collection: ProductCollection[];
    _selected_gender: string = '';
    public keepDropdownOpen = false;

    private destroy$ = new Subject<void>();

    editorDisabled = false;
    loading$ = new BehaviorSubject<boolean>(false);

    selectedSecondaryFabrics: Set<string> = new Set<string>();
    selectedInnerFabrics: Set<string> = new Set<string>();
    selected_alis: string = 'g';

    showOthers: boolean = false;

    secondaryFabrics: Fabric[] = [

    ];
    innerFabrics: Fabric[] = [

    ];
    outerFabrics: Fabric[] =
        [

        ];

    typesOfShoes = [


    ];

    categories = [

    ];

    displayedCategories: any[] = [];


    genders = [
        {

            "name": "Male"
        },
        {

            "name": "Female"
        },
        {

            "name": "Unisex"
        }
    ];

    showAddCategoryField: boolean = false;
    todayDate: string;

    isFieldReadOnly = false;
    isFieldReadOnlyFabric = false;
    subSku: any;
    @ViewChild('fileInput') fileInput: ElementRef;


    subSkuSize: any;
    constructor(
        private _formBuilder: FormBuilder,
        private elementRef: ElementRef,
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private dialog: MatDialog,
        private productService: productsService,
        private cdRef: ChangeDetectorRef,
        private _categorService: productCategoryService,
        private optionSubmissionService: OptionSubmissionService,
        private datePipe: DatePipe,
        private _itemsService: InventoryEntryService,
        private _collectionService: productsGroupService,
        private _fabricService: FabricService,
        private skuCreationService: SkuCreationService,
        private elRef: ElementRef,

    ) {

        this.dataSource = new MatTableDataSource<ProductVariant>(SIZE_CREDITS_DATA);
        this.dataSource.data = SIZE_CREDITS_DATA;

        this.dataSources = new MatTableDataSource<ProductVariant>(SIZE_CREDITS_DATAS);
        this.dataSources.data = SIZE_CREDITS_DATAS;

        this.collection$ = this._collectionService.getproducts().pipe(take(1));
        this.fabrics$ = this._fabricService.getfabrics().pipe(take(1));



        this.fabrics$.subscribe((fabric) => {
            const data = fabric['data'];

            this.innerFabricOptions = data.map((fabric) => {
                return { id: fabric.id, name: fabric.name };
            });

            this.secondaryFabircOptions = data.map((fabric) => {
                return { id: fabric.id, name: fabric.name };
            });


        })

        this.collection$.subscribe((collection) => {
            const data = collection['data'];
        });



    }


    ngOnDestroy(): void {
        this.optionSubmissionService.clearSubmittedOptions();
        SIZE_CREDITS_DATA = [];
        SIZE_CREDITS_DATAS = [];

        this.destroy$.next();
        this.destroy$.complete();

        this.dataSource.data = SIZE_CREDITS_DATA;
        this.dataSources.data = SIZE_CREDITS_DATAS;
        this.skuCreationService.resetSkuCreationState();



    }
    hasUnsavedChanges(): boolean {
        return this.productForm.dirty;
    }

    canDeactivate(): boolean {
        return !this.productForm.dirty || confirm('You have unsaved changes. Are you sure you want to leave?');
    }


    filterInnerSecondaryFabrics() {
        this.filteredSecondaryFabric = this.secondaryFabricControl.valueChanges.pipe(
            startWith(null),
            map((fabricName: string | null) =>
                fabricName ? this._filter_secondaryFabric(fabricName) : this.secondaryFabircOptions.slice()
            ),
        );

        this.filteredInnerFabric = this.innerFabricControl.valueChanges.pipe(
            startWith(null),
            map((fabricName: string | null) =>
                fabricName ? this._filter_innerFabric(fabricName) : this.innerFabricOptions.slice()
            ),
        );
    }

    private _filter_secondaryFabric(value: any): any[] {
        const filterValue = typeof value === 'string' ? value.toLowerCase() : '';
        return this.secondaryFabircOptions.filter(fabric => fabric.name.toLowerCase().includes(filterValue));
    }

    private _filter_innerFabric(value: string): any[] {
        const filterValue = typeof value === 'string' ? value.toLowerCase() : '';
        return this.innerFabricOptions.filter(fabric => fabric.name.toLowerCase().includes(filterValue));
    }




    selectAllCategoriesOnPage() {
        this.filteredCategories.forEach(category => category.selected = true);
    }

    // Function to select all categories across all pages
    selectAllCategories() {
        // Assuming categories is the array containing all categories, not just the ones on the current page
        this.categories.forEach(category => category.selected = true);
    }
    resetToDefault() {
        this.filteredCategories.forEach(category => category.selected = false);
    }


    getAllCategoriesDetails = () => {

        this.category$ = this._categorService.getproducts().pipe(take(1));
        this.category$.subscribe((category) => {
            this.category = category['data'];
            this.categories = this.category.map((category) => {
                return {
                    name: category.category_name,
                    id: category.id,
                    selected: false
                };

            });

            this.displayedCategories = this.categories.slice(0, this.pageSize);
            this.totalPages = Math.ceil(this.categories.length / 5);


        });

    }
    get selected_gender(): string {
        return this._selected_gender;
    }

    set selected_gender(value: string) {
        this._selected_gender = value;
        this.generateSKU();
    }

    get filteredCategories() {
        const startIndex = (this.currentPage - 1) * this.pageSize;
        const endIndex = startIndex + this.pageSize;

        return this.categories.filter(category =>
            category.name.toLowerCase().includes(this.searchText.toLowerCase())
        ).slice(startIndex, endIndex);
    }








    ngOnInit() {
        // Get the vendors
        this.getAllCategoriesDetails()
        this.filterInnerSecondaryFabrics();

        this.productForm = this._formBuilder.group({
            productName: ['', [Validators.minLength(3), Validators.required]],
            on_sale: [false],
            sku: ['', Validators.required],
            description: [''],
            collection: [this.collectionFormControl],
            year: [''],
            fabric: [this.FabricFormControl],
            secondary_fabric: [this.secondaryFabricControl],
            secondary_fabric_c: [false],
            inner_fabric: [this.innerFabricControl],
            inner_fabric_c: [false],
            product_weight: [''],
            weight_alis: ['g', Validators.required],
            basePrice: ['', [Validators.required, Validators.pattern(/^[1-9]\d{2,}$|^[1-9]\d{3,}$/)]],
            margin: [''],
            cost_of_goods: [0],
            profit: [''],
            discount: [''],
            salePrice: [''],
            show_ppu: [false],
            size: [],
            color: [],
            varients_toggel: [''],
            pre_order_toggel: [false],
            expected_release_date: [''],
            gender: [''],
            category: [''],
            size_chart: [''],
        });
        this.imagePreview = null;



        this.onGenderChange();

        this.productForm.get('productName').valueChanges.subscribe(() => {
            this.generateSKU();
        });


        this.productForm.get('collection').valueChanges.subscribe(() => {
            this.generateSKU();

        })




        this.filteredCollection$ = this.collectionFormControl.valueChanges.pipe(
            startWith(''),
            switchMap((value) => this.filterOptions(value)),
            takeUntil(this.destroy$)

        );




        this.filteredFabric$ = this.FabricFormControl.valueChanges.pipe(
            startWith(''),
            switchMap((value) => this.filterOptionsFabric(value)),
            takeUntil(this.destroy$)

        );


        this.productForm.get('varients_toggel')?.valueChanges.subscribe((value) => {
            if (value === true) {
                this.openProductVariations();
            }
        });



        const first_date = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
        const current_date = new Date(first_date)

        current_date.setDate(current_date.getDate() + 1)

        this.todayDate = this.datePipe.transform(current_date, 'yyyy-MM-dd')

        this.productForm.get('basePrice').valueChanges.subscribe(() => {
            this.updateProfitAndMargin();

        });

        this.productForm.get('cost_of_goods').valueChanges.subscribe(() => {
            this.updateProfitAndMargin();
        });
    }
    onSecondaryFabricSelected(event: MatAutocompleteSelectedEvent) {
        const selectedFabric = event.option.value;
        const selectedFabricName = event.option.value.name;

        if (!this.secondaryFabric.some(fabric => fabric.id === selectedFabric.id)) {
            // Check if the id is not already present in the array before pushing
            this.secondaryFabric.push({ id: selectedFabric.id, name: selectedFabric.name });
            this.selectedSecondaryFabrics.add(selectedFabricName);

        }

        this.secondaryFabricInput.nativeElement.value = ''; // Clear the input after selection
    }


    onInnerFabricSelected(event: MatAutocompleteSelectedEvent) {
        const selectedFabric = event.option.value;
        const selectedFabricName = event.option.value.name;

        if (!this.innerFabric.some(fabric => fabric.id === selectedFabric.id)) {
            // Check if the id is not already present in the array before pushing
            this.innerFabric.push({ id: selectedFabric.id, name: selectedFabric.name });
            this.selectedInnerFabrics.add(selectedFabricName);

        }

        this.innerFabricInput.nativeElement.value = ''; // Clear the input after selection
    }

    isSecondaryFabricDisabled(fabric: any): boolean {
        const fabricId = typeof fabric === 'string' ? fabric : fabric.id;
        return this.secondaryFabric.some(f => f.id === fabricId);
      }
      
      isInnerFabricDisabled(fabric: any): boolean {
        const fabricId = typeof fabric === 'string' ? fabric : fabric.id;
        return this.innerFabric.some(f => f.id === fabricId);
      }

    removeSecondaryFabric(sizes: string): void {
        const index = this.secondaryFabric.indexOf(sizes);

        if (index >= 0) {
            this.secondaryFabric.splice(index, 1);

        }

    }
    toggleReadOnly() {
        this.SkuStatus = !this.SkuStatus; // Toggle the status
    }
    removeInnerFabric(sizes: string): void {
        const index = this.innerFabric.indexOf(sizes);

        if (index >= 0) {
            this.innerFabric.splice(index, 1);

        }
    }

    triggerFileInput(): void {
        this.fileInput.nativeElement.click();
    }

    onFileSelected(event: Event): void {
        const file = (event.target as HTMLInputElement).files?.[0];
        if (file) {
            this.productForm.patchValue({ size_chart: file }); // Update size_chart control with the selected file
            this.productForm.get('size_chart')?.updateValueAndValidity(); // Ensure the form control is marked as touched/dirty

            const reader = new FileReader();
            reader.onload = () => {
                this.imagePreview = reader.result;
            };
            reader.readAsDataURL(file);
        }
    }

    clearImage(): void {
        this.imagePreview = null;
        this.productForm.patchValue({ size_chart: null }); // Clear the size_chart control value
        this.productForm.get('size_chart')?.updateValueAndValidity();
    }

    toggleFullScreen(event: MouseEvent): void {
        const target = event.target as HTMLElement;
        if (!document.fullscreenElement) {
            target.requestFullscreen().catch(err => console.log(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`));
        } else {
            document.exitFullscreen();
        }
    }
    onAutocompleteOpened() {
        const autocompletePanel = this.elRef.nativeElement.querySelector('.mat-autocomplete-panel');

        if (autocompletePanel) {
            const panelRect = autocompletePanel.getBoundingClientRect();
            const windowHeight = window.innerHeight;

            // Scroll down only if there is not enough space at the bottom
            if (windowHeight - panelRect.bottom < 0) {
                window.scrollBy(0, panelRect.bottom - windowHeight + 20); // You can adjust the offset (20 in this example)
            }
        }
    }


    toggleInputss(): void {
        this.productForm.get('collection').setValue('WINTER');
    }

    toggleInputs(): void {
        this.productForm.get('year').setValue('2024');
    }

    add(event: MatChipInputEvent): void {
        const value = (event.value || '').trim();

        // Add our fruit
        if (value) {
            this.prodcutsCtr.push(value);
        }

        // Clear the input value
        event.chipInput!.clear();

        this.categoriesCTR.setValue(null);
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        this.prodcutsCtr.push(event.option.viewValue);
        this.fruitInput.nativeElement.value = '';
        this.categoriesCTR.setValue(null);
    }
    nextPage() {
        const startIndex = this.currentPage * this.pageSize;
        this.displayedCategories = this.filteredCategories;
        this.currentPage++;
    }

    prevPage() {
        this.currentPage--;
        const startIndex = (this.currentPage - 1) * this.pageSize;
        this.displayedCategories = this.filteredCategories;
    }
    openProductDetails(): void {
        const dialogRef = this.dialog.open(ProductPopupComponent, {
            width: '500px', // Adjust the width as per your requirements
            // Pass the selected vendor as data to the modal
        });

        dialogRef.afterClosed().subscribe((result) => {



        });
    }

    toggleEditPopup(): void {
        this.isEditPopupOpen = !this.isEditPopupOpen;
    }


    private scrollToMessages(): void {
        const messagesElement =
            this.elementRef.nativeElement.querySelector('#messages');
        if (messagesElement) {
            messagesElement.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    }




    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    onCategoryChange(category: any) {
        if (category.selected) {
            this.selectedCategories.push(category.name);
        } else {
            const index = this.selectedCategories.indexOf(category.name);
            if (index !== -1) {
                this.selectedCategories.splice(index, 1);
            }
        }
    }


    isStockMissing(): boolean {
        return this.dataSource.data.some(row => !row.stock);
    }

    async submitProductInfo() {
        try {
            //
            if (this.productForm.valid) {


                this.productForm.patchValue({ gender: this._selected_gender });
                this.productForm.patchValue({ category: this.selectedCategories.join(',') });

                const customer2 = this.collectionFormControl.value;
                const collection_id = customer2.id;
                this.productForm.patchValue({ collection: collection_id });

                const fabric = this.FabricFormControl.value;
                const fabric_id = fabric.id;
                this.productForm.patchValue({ fabric: fabric_id });

                const secondaryFabricData = this.secondaryFabric.map(fabric => ({ id: fabric.id }));
                const innerFabricData = this.innerFabric.map(fabric => ({ id: fabric.id }));
                
                const secondary_ids: number[] = [];
                const inner_ids: number[] = [];

                // Populate secondary_ids
                secondaryFabricData.forEach((item: any) => {
                    secondary_ids.push(item.id);
                });

                // Populate inner_ids
                innerFabricData.forEach((item: any) => {
                    inner_ids.push(item.id);
                });



                this.productForm.patchValue({
                    secondary_fabric: secondary_ids,
                    inner_fabric: inner_ids
                });

                Swal.fire({
                    title: "Loading!",
                    html: "Creating .",
                    timerProgressBar: true,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });

                const formData = new FormData();
                const formValue = this.productForm.value;

                for (const key in formValue) {
                    if (formValue.hasOwnProperty(key)) {
                      const value = formValue[key];
                  
                      // Check for any empty value (including arrays, strings, null, undefined)
                      if (value !== null && value !== undefined && value !== '') {
                        // Additional check for empty arrays (optional)
                        if (!Array.isArray(value) || value.length > 0) {
                          // Send the key and non-empty value
                          formData.append(key, value);
                        }
                      }
                    }
                  }

            // Append the size chart file to FormData
            const sizeChartFile = this.productForm.get('size_chart')?.value;
            if (sizeChartFile) {
                formData.append('size_chart', sizeChartFile); // Ensure the file is appended with the correct key
            }

            // Send FormData to the server
            const productResponse = await this.productService.createproductss(formData);
            if (productResponse.success) {
                const idMatch = productResponse.data.match(/'id':\s*(\d+)/);
                var mainProductId = 0
                if (idMatch) {
                    mainProductId = parseInt(idMatch[1], 10);
                    console.log("data", mainProductId)

                    const createdVariants = [];

                    for (let i = 0; i < this.variationss.length; i++) {
                        const variationData = this.variationss[i];
                        const base_product = mainProductId;

                        const stockValue = 0;



                        const pre_order_val = this.productForm.get('pre_order_toggel').value;
                        const variantData = {
                            "size": variationData.size !== undefined ? variationData.size : 0,
                            'base_product': base_product,
                            "basePrice": variationData.price !== null && variationData.price !== "null" ? parseFloat(variationData.price) : 0,
                            "sku": variationData.sku !== undefined && variationData.sku !== "undefined" ? variationData.sku : 0,
                            "color": variationData.color !== undefined && variationData.color !== "undefined" ? variationData.color : 0,
                            "stock": stockValue,
                            "status": variationData.status !== undefined && variationData.status !== "undefined" ? variationData.status : 0,
                            "productName": variationData.productName !== undefined && variationData.productName !== "undefined" ? variationData.productName : 0,
                            "active": pre_order_val !== undefined && pre_order_val !== 'undefined' ? pre_order_val : false,
                            "preOrder": pre_order_val !== undefined && pre_order_val !== 'undefined' ? pre_order_val : false,

                        };





                        const variantResponse = await this.productService.createProductVarients(variantData);

                        if (variantResponse.success) {
                            createdVariants.push(variantResponse.data);



                        } else {
                            console.error('Error creating variant:', variantResponse.errors);
                        }
                    }






                    this.Toast.fire({
                        icon: 'success',
                        title: `Product created!!`,
                    });

                    this.optionSubmissionService.clearSubmittedOptions();
                    SIZE_CREDITS_DATA = [];
                    SIZE_CREDITS_DATAS = [];

                    this.dataSource.data = SIZE_CREDITS_DATA;
                    this.dataSources.data = SIZE_CREDITS_DATAS;
                    this.productForm.reset();

                    this._router.navigate(['../'], {
                        relativeTo: this._activatedRoute,
                    });
                } else {
                    console.log("ID not found in the response data.");
                }
            } else {
                Swal.close();

                const errorMessages = productResponse.errors;



                this.Toast.fire({
                    icon: 'error',
                    title: 'Error',
                    text: JSON.stringify(errorMessages),
                });
            }
        } else {


            this.Toast.fire({
                icon: 'error',
                title: 'Invalid form data',
                text: 'Please fill in all the required fields correctly.',
            });
        }
    } catch(error) {


        this.Toast.fire({
            icon: 'error',
            title: `Error creating Product: ${error.message}`,
        });
    }
}

filterOptionsCollection(value: string): Observable < ProductCollection[] > {


    if(typeof value !== 'string') {
    return of([]); // Return an empty array if value is not a string
}

const filterValues = value.trim().toLowerCase().split(' ');

return this.collection$.pipe(
    map((data) => {

        if (Array.isArray(data)) {
            return data.filter((collectionItem) =>
                filterValues.some((filterValue) =>
                    collectionItem.collection_name.toLowerCase().includes(filterValue),

                )
            );
        } else {
            console.error('Collection data is not available:', data);
            return [];
        }
    })
);
    }


displayOption(collection: ProductCollection): string {

    return collection ? collection.collection_name : '';
}


selectOption(option: ProductCollection) {
    if (!option) {
        return;
    }
    this.collectionFormControl.setValue(option);


    this.collectionFormControl.updateValueAndValidity();
    this.selectedCollection = option;
    this.collectionSku = option.sku

    this.generateSKU();
    this.isFieldReadOnly = true;



}

onGenderChange() {
}


// -----------------------------------------------------------------------------------------------------
// @ Private methods
// -----------------------------------------------------------------------------------------------------

filterOptionsFabric(value: string): Observable < Fabric[] > {
    if(typeof value !== 'string') {
    return of([]);
}

// Set loading to true
this.loading$.next(true);

const filterValue = value.toLowerCase();
return this.fabrics$.pipe(
    map((fabric) => {
        if (Array.isArray(fabric['data'])) {
            return fabric['data'].filter(
                (fabricItem) =>
                    fabricItem.name.toLowerCase().includes(filterValue),
            );
        } else {
            console.error('Fabric data is not available:', fabric);
            return [];
        }
    }),
    // Reset loading when the search is complete
    tap(() => this.loading$.next(false)),
);
    }



displayOptionFabric(fabric: Fabric): string {

    return fabric ? fabric.name : '';
}


selectOptionFabric(option: Fabric) {
    if (!option) {
        return;
    }
    this.FabricFormControl.setValue(option);
    this.FabricFormControl.updateValueAndValidity();
    this.selectedFabric = option;
    this.fabricSku = option.sku;
    this.generateSKU();
    this.isFieldReadOnlyFabric = true;



}




// -----------------------------------------------------------------------------------------------------
// @ Private methods
// -----------------------------------------------------------------------------------------------------
filterOptions(value: string): Observable < ProductCollection[] > {
    if(typeof value !== 'string') {
    return of([]);
}

// Set loading to true
this.loading$.next(true);

const filterValue = value.toLowerCase();
return this.collection$.pipe(
    pluck('data'),
    map((collection) => {
        if (Array.isArray(collection)) {
            return collection.filter(
                (item) => item.collection_name.toLowerCase().includes(filterValue)
            );
        } else {
            console.error('Collection data is not available:', collection);
            return [];
        }
    }),
    // Reset loading when the search is complete
    tap(() => this.loading$.next(false)),
);
    }



getFabricString(): string {
    const secondaryFabric = this.productForm.get('secondary_fabric').value || '';
    const innerFabric = this.productForm.get('inner_fabric').value || '';
    const outterFabric = this.productForm.get('outter_fabric').value || '';

    return [secondaryFabric, innerFabric, outterFabric].filter(Boolean).join(', ');
}

getVariationsData(variationsFormArray: FormArray) {

    const variationsData = {
        variations: variationsFormArray.value,
    };
    return variationsData;
}

generateSKU() {
    let fabricInital = "";
    let collectionInital = "";

    const productName = this.productForm.get('productName').value;

    const getTwoWords = (str: string): string => {
        if (!str || str.trim() === '') {
            return '';
        }

        const words = str.split(' ');

        if (words.length < 2) {
            return '';
        }

        return words.slice(0, 2).map(word => word.charAt(0)).join('');
    };

    const nameInitials = getTwoWords(productName ? productName : "");
    const currentDate = new Date();
    const currentYear = formatDate(currentDate, 'yy', 'en-US');
    const currentMonth = formatDate(currentDate, 'MM', 'en-US');
    const genderInitial = this._selected_gender ? this._selected_gender.toString().slice(0, 3) : '';

    try {
        if (this.fabricSku) {
            fabricInital = this.fabricSku ? this.fabricSku.toLocaleString() : "";

        }
        if (this.collectionSku) {
            collectionInital = this.collectionSku ? this.collectionSku.toLocaleString() : '';

        }

    } catch (e) {
        console.error("blank Value")
    }




    const sku = `H${nameInitials}-${currentYear}-${currentMonth}-${genderInitial}-${collectionInital}-${fabricInital}`;

    this.generatedSku = sku.toUpperCase();

    this.productForm.patchValue({ sku: sku.toUpperCase() });
    this.cdRef.markForCheck();
}












updateProfitAndMargin() {
    const price = this.productForm.get('basePrice').value;
    const costOfGoods = this.productForm.get('cost_of_goods').value;

    if (!isNaN(price) && !isNaN(costOfGoods)) {
        const profit = price - costOfGoods;
        const margin = (profit / price) * 100;

        this.productForm.patchValue({
            profit: profit.toFixed(2),
            margin: margin.toFixed(2),
        });
    }
}


formatDate(date: Date, format: string, controlName: string) {
    const formattedDate = this.datePipe.transform(date, format);
    this.productForm.get(controlName)?.setValue(formattedDate);
}



updateSalePrice() {
    const isOnSale = this.productForm.get('on_sale').value;
    const basePrice = parseFloat(this.productForm.get('basePrice').value);
    const discount = parseFloat(this.productForm.get('discount').value);

    if (isOnSale && !isNaN(basePrice) && !isNaN(discount)) {
        const salePrice = basePrice - (basePrice * discount) / 100;
        this.productForm.patchValue({
            salePrice: salePrice.toFixed(2),
        });
    } else {
        this.productForm.patchValue({
            salePrice: '',
        });
    }
}






deleteShoe(shoe: any): { item: any, type: string } | null {
    const index = this.typesOfShoes.indexOf(shoe);

    if (index !== -1) {
        const deletedShoe = this.typesOfShoes.splice(index, 1)[0];
        if (deletedShoe.name === 'Color') {
            this.colorDataPushed = false;
            this.color = []; // Clear color data
        } else if (deletedShoe.name === 'Size') {
            this.sizeDataPushed = false;
            this.sizes = []; // Clear size data
        }
        // Delete the option using the service
        this.optionSubmissionService.deleteOption(deletedShoe.name);

        // Clear variations associated with the deleted shoe
        this.combinedVariations = [];

        // Update SIZE_CREDITS_DATA and SIZE_CREDITS_DATAS
        SIZE_CREDITS_DATA = [];
        SIZE_CREDITS_DATAS = [];

        // Subscribe to the form data observable to get updated data
        const formDataSubscription = this.optionSubmissionService.getFormDataObservable().subscribe(updatedFormData => {
            // Unsubscribe to avoid memory leaks
            formDataSubscription.unsubscribe();


            if (this.color.length > 0 && this.sizes.length > 0) {
                this.color.forEach(color => {
                    this.sizes.forEach(size => {
                        const variation = {
                            color: color,
                            size: size,
                        };

                        const newVariation = {
                            varient: `${color} - ${size}`,
                            size: size,
                            color: color,
                            sku: `${this.generatedSku}`,
                            status: 'inactive',
                            visibility: 'hidden' // You can set visibility as needed
                        };

                        this.combinedVariations.push(variation);
                        SIZE_CREDITS_DATA.push(newVariation);
                        SIZE_CREDITS_DATAS.push(newVariation);
                    });
                });

                this.dataSource.data = SIZE_CREDITS_DATA;
                this.dataSources.data = SIZE_CREDITS_DATAS;

                // Optionally, you can manually trigger change detection
                this.cdRef.detectChanges();

                // Replace the existing typesOfShoes array with the combinedVariations array
                this.typesOfShoes = this.combinedVariations;
            }

            // ... continue with the rest of your deleteShoe logic if needed
        });

        return { item: deletedShoe, type: deletedShoe.name };
    }

    return null;
}







openProductDetailOption(): void {
    const dialogRef = this.dialog.open(ProductOptionsPopupComponent, {
        width: '800px',
        height: '400px;'
    });

    dialogRef.componentInstance.formSubmitted.subscribe((formData) => {
        this.productForm.patchValue({
            color: formData.color,
            size: formData.sizes,
        });

        if (formData.color) {
            this.typesOfShoes.push({
                name: formData.option_name,
                values: formData.color,
                sku: formData.sku,
            });
            this.colorDataPushed = true;
            this.color = formData.color;
        }

        if (formData.sizes) {
            this.typesOfShoes.push({
                name: formData.option_name,
                values: formData.sizes,
                sku: formData.sku,
            });
            this.sizeDataPushed = true;
            this.sizes = formData.sizes;
        }

        if (this.colorDataPushed && this.sizeDataPushed) {
            this.show_ppu_toogle = true;
            const colorString: string = this.color.join(',');
            const sizeString: string = this.sizes.join(',');

            Swal.fire({
                title: "Loading!",
                html: "Please wait .",
                timerProgressBar: true,
                didOpen: () => {
                    Swal.showLoading();
                },
            });

            this.productForm.patchValue({
                color: colorString,
                size: sizeString
            });

            const colorVariations = this.typesOfShoes.find(variation => variation.name === 'Color');
            const sizeVariations = this.typesOfShoes.find(variation => variation.name === 'Size');

            if (colorVariations && sizeVariations) {


                colorVariations.values.forEach(color => {
                    sizeVariations.values.forEach(size => {
                        const colorSku = this.getColorSku(colorVariations, color);
                        const sizeSku = this.getSizeSku(sizeVariations, size);

                        const combinedSku = `${this.generatedSku}-${colorSku}-${sizeSku}`;

                        const newVariation = {
                            varient: `${color} - ${size}`,
                            size: size,
                            color: color,
                            sku: combinedSku,
                            stock: 0,
                            price: 0,
                            status: 'inactive',
                            visibility: 'hidden'
                        };

                        this.combinedVariations.push(newVariation);
                        SIZE_CREDITS_DATA.push(newVariation);
                        SIZE_CREDITS_DATAS.push(newVariation);
                        this.cdRef.detectChanges();
                    });
                });

                this.dataSource.data = SIZE_CREDITS_DATA;
                this.dataSources.data = SIZE_CREDITS_DATAS;

                this.cdRef.detectChanges();
                this.productForm.get('varients_toggel')?.setValue(true, { emitEvent: false });

                this.openProductVariations();

                Swal.close();
            }
        }

        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener(
                    'mouseenter',
                    Swal.stopTimer
                );
                toast.addEventListener(
                    'mouseleave',
                    Swal.resumeTimer
                );
            },
        });

        Toast.fire({
            icon: 'success',
            title: ` Variants created!! `,
        });
    });

    dialogRef.afterClosed().subscribe((result) => {

    });
}


openProductVariations(showAssumedInventory: boolean = false): Promise < any > {
    return new Promise((resolve) => {
        const dialogRef = this.dialog.open(ProductVarientsComponent, {
            width: '3000px',
            height: '2000px',
            data: {

                variations: this.combinedVariations,
                showAssumedInventory: showAssumedInventory

            } // Pass the current combinedVariations data
        });

        dialogRef.componentInstance.formSubmitted.subscribe((formData) => {
            if (formData) {
                // Check if formData.varients is an array
                if (Array.isArray(formData.varients)) {
                    for (let i = 0; i < formData.varients.length; i++) {
                        if (i < this.combinedVariations.length) {

                            this.combinedVariations[i] = {
                                id: i, // Use the array index as the identifier
                                size: formData.varients[i].size,
                                color: formData.varients[i].color,
                                status: formData.varients[i].status,
                                stock: formData.varients[i].stock,
                                sku: formData.varients[i].sku,
                                active: false,
                                price: formData.varients[i].price,
                                visibility: 'hidden'
                            };

                            SIZE_CREDITS_DATA[i] = {
                                id: i,
                                size: formData.varients[i].size,
                                color: formData.varients[i].color,
                                status: formData.varients[i].status,
                                varient: `${formData.varients[i].color} - ${formData.varients[i].size}`,
                                stock: formData.varients[i].stock,
                                price: formData.varients[i].price,
                                sku: formData.varients[i].sku,
                                active: false[i],
                                visibility: 'hidden'
                            };
                            this.dataSource.data = SIZE_CREDITS_DATA;
                            this.variationss = formData.varients

                            this.cdRef.detectChanges();

                        } else {
                            console.error(`Index ${i} is out of bounds for this.combinedVariations and SIZE_CREDITS_DATA`);
                        }
                    }
                } else {
                    console.error('formData.varients is not an array:', formData.varients);
                }

                this.cdRef.detectChanges();

                resolve(formData);
            } else {
                resolve(null);
            }


        });
    });
}




remove(fabric: Fabric): void {
    const index = this.secondaryFabrics.indexOf(fabric);

    if(index >= 0) {
    this.secondaryFabrics.splice(index, 1);
}
    }

// Function to retrieve color SKU
getColorSku(colorVariations: any, color: string): string {
    const colorIndex = colorVariations.values.indexOf(color);
    return colorIndex !== -1 ? colorVariations.sku[colorIndex] : '';
}

// Function to retrieve size SKU
getSizeSku(sizeVariations: any, size: string): string {
    const sizeIndex = sizeVariations.values.indexOf(size);
    return sizeIndex !== -1 ? sizeVariations.sku[sizeIndex] : '';
}


makeFieldEditable() {
    this.isFieldReadOnly = false;
    this.collectionFormControl.enable(); // Enable the form control
    this.collectionFormControl.setValue(''); // Clear the value of the form control
}
makeFieldEditableFabric() {
    this.isFieldReadOnlyFabric = false;
    this.FabricFormControl.enable(); // Enable the form control
    this.FabricFormControl.setValue(''); // Clear the value of the form control
}

isCollectionSelected(selectedCollection: ProductCollection): boolean {



    return this.collectionFormControl.value && selectedCollection && this.collectionFormControl.value.id === selectedCollection.id;
}

isFabricselected(selectedFabric: Fabric): boolean {

    return this.FabricFormControl.value && selectedFabric && this.FabricFormControl.value.id === selectedFabric.id;
}




removeOuterFabric(fabric: Fabric): void {
    const index = this.outerFabrics.indexOf(fabric);

    if(index >= 0) {
    this.outerFabrics.splice(index, 1);
}
    }


addCategory() {
    try {
        if (this.newCategory.trim() !== '') {
            if (!this.categories.some(category => category.name === this.newCategory)) {
                const data = {
                    category_name: this.newCategory,
                    products: []
                };

                this._categorService.createproductss(data)
                    .then(response => {
                        if (response && response.success) {
                            const newlyCreatedCategory = { name: response.data.category_name, id: response.data.id, selected: response.success };
                            this.categories.push(newlyCreatedCategory);

                            // Manually set the selected state after adding a new category
                            this.onCategoryChange(newlyCreatedCategory);

                            // Show success SweetAlert
                            Swal.fire('Success', 'Category added successfully!', 'success');
                        } else {
                            // Show error SweetAlert for unexpected response format
                            Swal.fire('Error', 'Unexpected response format', 'error');
                            // Handle unexpected response format
                        }
                    })
                    .catch(error => {
                        // Show error SweetAlert for service call error
                        Swal.fire('Error', 'Error from service call: ' + error.message, 'error');
                        // Handle the error from the service call
                    });

                // Clear the input field
                this.newCategory = '';
            }
        }
    } catch (error) {
        // Show error SweetAlert for unexpected errors
        Swal.fire('Error', 'Unexpected error: ' + error.message, 'error');
        // Handle any unexpected errors
    }
}


toggleAddCategoryField() {
    this.showAddCategoryField = !this.showAddCategoryField;
}

toggleFabricOptions(): void {
    this.showFabricOptions = !this.showFabricOptions;
    this.secondary = false;
    this.inner = false;
    this.outter = false;
    this.isActives = true;
}
onCheckboxChange(event: any): void {
    if(event.target.checked) {
    this.toggleFabricOptions();
} else {

    this.showFabricOptions = !this.showFabricOptions;
    this.secondary = false;
    this.inner = false;

    // Assuming you have a form group, you can reset the form controls
    this.productForm.get('secondary_fabric_c')?.setValue(false);
    this.productForm.get('inner_fabric_c')?.setValue(false);
    this.isActives = false;
}

    }

}