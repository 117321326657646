import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { SalesLogsComponent } from './sales-logs.component';
import { RouterModule } from '@angular/router';
import { SalesLogsRoutes } from './sales-logs.routing';
import { MaterialModule } from 'app/modules/material/material.module';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { PartialPayComponent } from './partial-pay/partial-pay.component';



@NgModule({
  declarations: [

    SalesLogsComponent,
     PartialPayComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule.forChild(SalesLogsRoutes)
  ],
  providers   : [
    {
        provide : MAT_DATE_FORMATS,
        useValue: {
            parse  : {
                dateInput: "YYYY-MM-DD"
            },
            display: {
                dateInput         : 'YYYY-MM-DD',
                monthYearLabel    : 'MMM YYYY',
                dateA11yLabel     : 'LL',
                monthYearA11yLabel: 'MMMM YYYY'
                
            }
        }
    },
    DatePipe,
],
})
export class SalesLogsModule { }
