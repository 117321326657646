<div class="flex flex-col w-full">
    <!-- Edit mode -->

    <ng-container>
        <!-- Header -->
        
        <div
        class="flex items-center justify-end w-full max-w-3xl mx-auto pt-6"
    >
        <button
            mat-icon-button
            [matTooltip]="'Close'"
            [routerLink]="['../']"
        >
            <mat-icon
                class="text-white"
                [svgIcon]="'heroicons_outline:x'"
            ></mat-icon>
        </button>
    </div>
        <!-- vendors form -->
        <div
            class="relative flex flex-col flex-auto items-center px-6 sm:px-12"
            id="messages"
        >
            <div class="w-full max-w-3xl">
                <form
                    [formGroup]="vendorsForm"
                    (ngSubmit)="submitvendorsInfo()"
                >
                    <!-- Avatar -->
                    <!-- Avatar code goes here -->

                    <!-- Personal Information Form -->
                    <div *ngIf="vendorsForm">
                        <div class="prose prose-sm max-w-3xl"></div>
                  

                        <div
                            class="flex flex-col mt-8 p-8 pb-5 bg-card rounded shadow overflow-hidden"
                        >
                            <p class="text-lg font-medium">Basic information</p>
                            <p class="text-secondary">
                                Set your Basic vendors Details
                            </p>
                            <mat-divider class="mt-8 mb-10"></mat-divider>
                            <div class="flex">
                                <mat-form-field class="flex-auto mr-3">
                                    <mat-label>First name</mat-label>
                                    <input
                                    required
                                        matInput
                                        [placeholder]="'First Name'"
                                        name="firstName"
                                        [formControlName]="'firstName'"
                                    />
                                    <mat-icon
                                        class="icon-size-5"
                                        matPrefix
                                        [svgIcon]="'heroicons_solid:user'"
                                    ></mat-icon>
                                </mat-form-field>
                                <mat-form-field class="flex-auto">
                                    <mat-label>Last name</mat-label>
                                    <input
                                    required

                                        matInput
                                        [placeholder]="'Last Name'"
                                        name="lastName"
                                        [formControlName]="'lastName'"
                                    />
                                    <mat-icon
                                        class="icon-size-5"
                                        matPrefix
                                        [svgIcon]="'heroicons_solid:user-circle'"
                                    ></mat-icon>
                                </mat-form-field>
                            </div>
                            <div class="flex">
                                
                            </div>
                            <div class="flex">
                              <mat-form-field class="flex-auto">
                                  <mat-label>Contact Person</mat-label>
                                  <input
                                  required

                                      matInput
                                      [placeholder]="'Contact Persona'"
                                      name="contact person"
                                      [formControlName]="'contact_person'"
                                  />
                                  <mat-icon
                                      class="icon-size-5"
                                      matPrefix
                                      [svgIcon]="'heroicons_solid:user-group'"
                                  ></mat-icon>
                              </mat-form-field>
                          </div>

                          <div class="mt-4">

                            <ng-container
                              *ngFor="let email of vendorsForm.get('emails')['controls']; let i = index; let first = first; let last = last; trackBy: trackByFn">
                              <div class="flex">
                                <mat-form-field class="fuse-mat-no-subscript flex-auto">
                                  <mat-label *ngIf="first">Email</mat-label>
                                  <mat-icon matPrefix class="hidden sm:flex icon-size-5"
                                    [svgIcon]="'heroicons_solid:mail'"></mat-icon>
                                  <input required matInput name="emails.email" [formControl]="email.get('email')"
                                    [placeholder]="'Email address'" [spellcheck]="false">
                                </mat-form-field>
                                <mat-form-field class="fuse-mat-no-subscript flex-auto w-full max-w-24 sm:max-w-40 ml-2 sm:ml-4">
                                  <mat-label *ngIf="first">Label</mat-label>
                                  <mat-icon matPrefix class="hidden sm:flex icon-size-5"
                                    [svgIcon]="'heroicons_solid:tag'"></mat-icon>
                                  <input matInput name="emails.label" [formControl]="email.get('label')" [placeholder]="'Label'">
                                </mat-form-field>
                                <!-- Remove email -->
                                <ng-container *ngIf="!(first && last)">
                                  <div class="flex items-center w-10 pl-2" [ngClass]="{'mt-6': first}">
                                    <button class="w-8 h-8 min-h-8" mat-icon-button (click)="removeEmailField(i)"
                                      matTooltip="Remove">
                                      <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:trash'"></mat-icon>
                                    </button>
                                  </div>
                                </ng-container>
                              </div>
                            </ng-container>
            
                            <div class="group inline-flex items-center mt-2 -ml-4 py-2 px-4 rounded cursor-pointer"
                              (click)="addEmailField()">
                              <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:plus-circle'"></mat-icon>
                              <span class="ml-2 font-medium text-secondary group-hover:underline">Add an email address</span>
                            </div>
                          </div>

                        <mat-form-field class="flex-auto gt-xs:pl-3">
                            <mat-label>Phone number</mat-label>
                            <input
                            required

                                matInput
                                [placeholder]="'Phone Number'"
                                name="number"
                                [formControlName]="'phoneNumbers'"
                                type="text" onkeypress="return event.charCode>=48 && event.charCode<=57"
                            />
                            <span matPrefix>+977-/</span>
                            <mat-icon
                                class="icon-size-5"
                                matPrefix
                                [svgIcon]="'heroicons_solid:phone'"
                            ></mat-icon>
                        </mat-form-field>
                        </div>

                        <div
                            class="flex flex-col mt-8 p-8 pb-5 bg-card shadow rounded overflow-hidden"
                        >
                            <p class="text-lg font-medium">Profile</p>
                            <p class="text-secondary">
                                Add more information about the vendors .
                            </p>
                            <mat-divider class="mt-8 mb-10"></mat-divider>
                            <div class="flex flex-col gt-xs:flex-row">
                                <mat-form-field class="flex-auto gt-xs:pr-3">
                                    <mat-label>Vat Number</mat-label>
                                    <input
                                        matInput
                                        required
                                        [placeholder]="'Vat no of the vendor'"
                                        name="vatNO"
                                        [formControlName]="'vat_number'"
                                        autocomplete="off"
                                    />
                                    <mat-icon
                                        class="icon-size-5"
                                        matPrefix
                                        [svgIcon]="
                                            'heroicons_solid:document-duplicate'
                                        "
                                    ></mat-icon>
                                </mat-form-field>
                                <mat-form-field class="flex-auto gt-xs:pr-3">
                                  <mat-label>Pan Number</mat-label>
                                  <input
                                      matInput
                                      required
                                      [placeholder]="'Pan no of the vendor'"
                                      name="panNum"
                                      [formControlName]="'pan_number'"
                                      autocomplete="off"
                                  />
                                  <mat-icon
                                      class="icon-size-5"
                                      matPrefix
                                      [svgIcon]="
                                          'heroicons_solid:document-text'
                                      "
                                  ></mat-icon>
                              </mat-form-field>
                           
                            </div>
                        
                        </div>
                        <div class="flex items-center justify-end mt-4">
                        <button
                            class="px-6 ml-3"
                            mat-flat-button
                            [color]="'primary'"
                            [disabled]="vendorsForm.invalid"
                        >
                            Save
                        </button>
                    </div>
                    </div>
                </form>
            </div>
        </div>
    </ng-container>
</div>

<!-- Add a success alert -->

<!-- Stepper for Billing and Shipping Address -->
