<div *ngIf="vendor" class="mt-10 ml-15 md:ml-50 lg:ml-90">



    <div class="absolute inset-0 min-w-0 text-center overflow-auto print:overflow-visible" cdkScrollable>

        
        <!-- Main -->
        <div class="w-full inline-block p-6 sm:p-10 text-left print:p-0">

            <!-- Invoice -->
            <div
                class="w-full  p-16 rounded-2xl shadow bg-card print:w-auto print:p-0 print:rounded-none print:shadow-none print:bg-transparent">
                
                <div class="flex items-center">
                    <div class="grid grid-rows-2 place-items-center ">
                        
                        <div class="grid-cols-2  place-items-center">

                       
                    <button mat-button type="button"  matTooltip="Close" class="hide-on-print" [routerLink]="['..']">
                        <span class="inline-flex items-center">
                            <mat-icon svgIcon="feather:x"></mat-icon>
                        </span>
                    </button>
                    
                    <button mat-button type="button" class="hide-on-print"  (click)="printPage()">
                        <span class="inline-flex items-center">
                            <mat-icon svgIcon="feather:file"></mat-icon>
                        </span>
                        </button>


                
                </div>
                </div>
                </div>
                <div class="flex items-start">
                    
                    <div class="grid grid-rows-2 place-items-start ">
                        <div class="grid auto-cols-max grid-flow-col gap-x-5">
                            <div class="place-self-center w-24">
                                <img class="w-24" src="assets/Avatar_male1.png">
                            </div>
                            <div class="pl-4 border-l text-md">
                                <div class="font-medium"> {{ vendor?.salesOrder?.Customers.firstName }} {{ vendor?.salesOrder?.Customers.lastName
                                    }}
                                </div>
                                <div>{{vendor?.salesOrder?.Customers.gender}}</div>
                                <div>{{vendor?.salesOrder?.Customers.socialMedia }}</div>
                                <div>+977-{{vendor?.salesOrder?.Customers.phoneNumbers}}</div>
                                <div *ngFor="let email of vendor?.salesOrder?.Customers.emails">
                                    <div>{{ email.email}}</div>
                                </div>
                                <div *ngFor="let email of vendor?.salesOrder?.Customers.billingAddress">
                                    <div>{{email.type}} {{email.city}} {{ email.address}}</div>
                                </div>

                                <div *ngFor="let email of vendor?.salesOrder?.Customers.shippingArea">
                                    <div>{{email.type}} {{email.city}} {{ email.address}}</div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="grid grid-cols-2 gap-x-4 gap-y-1 ml-auto">
                       
                        <div class="justify-self-end text-2xl tracking-tight text-secondary">Package Number</div>
                        <div class="text-2xl">{{vendor?.packageOrder}}
                            <span class="font-bold" *ngIf="vendor?.isPackaged && !vendor?.isShipped && !vendor?.isDelivared"> Packaged</span>
                            <span class="font-bold" *ngIf="vendor?.isPackaged && vendor?.isShipped && !vendor?.isDelivared"> Shipped</span>
                            <span class="font-bold" *ngIf="vendor?.isPackaged && vendor?.isShipped && vendor?.isDelivared "> Delivered</span>
                        </div> 
                        <div class="justify-self-end text-2xl tracking-tight text-secondary" *ngIf="vendor?.shipOrder">shipment Number</div>
                        <div class="text-2xl">{{vendor?.shipOrder}} </div> 
                        <mat-divider class="right-4" *ngIf="vendor?.isPackaged &&!vendor?.isShipped"></mat-divider>
                        <div class="justify-self-end font-medium tracking-tight text-secondary" *ngIf="vendor?.isPackaged && !vendor?.isShipped && !vendor?.isDelivared ">Package Date</div>
                        <div class="justify-self-end font-medium tracking-tight text-secondary" *ngIf="vendor?.isPackaged && vendor?.isShipped && !vendor?.isDelivared ">Shipment Date</div>
                        <div class="justify-self-end font-medium tracking-tight text-secondary" *ngIf="vendor?.isPackaged && vendor?.isShipped && vendor?.isDelivared ">Delivary Date</div>
                        <div class="font-medium">{{vendor?.packageDate}}</div>
                        <div class="justify-self-end font-medium tracking-tight text-secondary">Expected Shipment Date
                        </div>
                        <div class="font-medium">{{vendor?.salesOrder?.sales_order_shippmentDate}}</div>
                        <div class="justify-self-end font-medium tracking-tight text-secondary" *ngIf="vendor.data">Order Return Date
                        </div>
                        <div class="font-medium" *ngIf="vendor.data">{{vendor?.date}}</div>
                        <div class="justify-self-end font-medium tracking-tight text-secondary" *ngIf="vendor.rfr">Reason For Return
                        </div>
                        <div class="font-medium" *ngIf="vendor.rfr" >{{vendor?.reasonForReturn}}</div>
                        <div class="justify-self-end font-medium tracking-tight text-secondary">TOTAL </div>
                        <div class="font-medium">रू {{vendor?.salesOrder?.total}}</div>
                        <div class="justify-self-end font-medium tracking-tight text-secondary">Status </div>
                        <div class="font-medium">{{vendor?.salesOrder?.status}}</div>
                    </div>
                    
                  
                   

                    <div class="grid grid-cols-2 gap-x-4 gap-y-1 ml-auto">
                       
                        <div class="justify-self-end text-3xl tracking-tight text-secondary">Sales Order</div>
                        <div class="text-3xl">{{vendor?.salesOrder?.sales_order_num}} </div> 
                        <div class="justify-self-end font-medium tracking-tight text-secondary">Created Date</div>
                        <div class="font-medium">{{vendor?.salesOrder?.slaes_order_date}}</div>
                        <div class="justify-self-end font-medium tracking-tight text-secondary">Expected Shipment Date
                        </div>
                        <div class="font-medium">{{vendor?.salesOrder?.sales_order_shippmentDate}}</div>
                        <div class="justify-self-end font-medium tracking-tight text-secondary" *ngIf="vendor.data" >Order Return Date
                        </div>
                        <div class="font-medium" *ngIf="vendor.data" >{{vendor?.date}}</div>
                        <div class="justify-self-end font-medium tracking-tight text-secondary" *ngIf="vendor.rfr" >Reason For Return
                        </div>
                        <div class="font-medium" *ngIf="vendor.rfr" >{{vendor?.reasonForReturn}}</div>
                        <div class="justify-self-end font-medium tracking-tight text-secondary">TOTAL </div>
                        <div class="font-medium">रू {{vendor?.salesOrder?.total}}</div>
                        <div class="justify-self-end font-medium tracking-tight text-secondary">Status </div>
                        <div class="font-medium">{{vendor?.salesOrder?.status}}</div>
                    </div>
                  

                </div>

                <mat-divider class="m-2"></mat-divider>

                <div class="flex items-start">
                    <div class="grid grid-cols-6 w-auto  gap-y-3">
                        <div class="  tracking-tight text-secondary"> <strong>POS:</strong></div>
                        <ng-container class="text-secondary" *ngIf="vendor?.salesOrder?.pos">{{ vendor?.salesOrder?.pos }}</ng-container>
                        <ng-container *ngIf="!vendor?.salesOrder?.refrences">NULL</ng-container>

                        <div class="justify-self-start font-medium tracking-tight text-secondary"><strong>Social Link:</strong>
                        </div>
                        <a class=" text-link" matTooltip="@customerInstagram" *ngIf="vendor?.salesOrder?.soical_link" target="_blank" href="https://www.instagram.com/{{vendor?.salesOrder?.soical_link}}">{{ vendor?.salesOrder?.soical_link
                            }}</a>
                        <div *ngIf="!vendor?.salesOrder?.refrences">NULL</div>

                        <div class="justify-self-start  tracking-tight text-secondary"><strong>Shipping Area:</strong>
                        </div>
                        <div class="justify-self-start" *ngIf="vendor?.salesOrder?.shipping_area == 'Inside Valley'">
                            {{ vendor?.salesOrder?.shipping_area }},{{vendor?.salesOrder?.district}},{{vendor?.salesOrder?.address}}</div>
                        <div *ngIf="!vendor?.salesOrder?.shipping_area">NULL</div>

                        <div class="justify-self-start  tracking-tight text-secondary"><strong>Payment Type:</strong>
                        </div>
                        <div class="justify-self-start " *ngIf="vendor?.salesOrder?.payment_type">{{
                            vendor?.salesOrder?.payment_type }}</div>
                        <div *ngIf="!vendor?.salesOrder?.payment_type">NULL</div>

                        <div class="justify-self-start  tracking-tight text-secondary"><strong>Delivery Type:</strong>
                        </div>
                        <div class="justify-self-start" *ngIf="vendor?.salesOrder?.delivery_type">{{
                            vendor?.salesOrder?.delivery_type }}</div>

                        <div class="justify-self-start   text-secondary"><strong>Shipping Carrier
                                :</strong></div>
                        <div class="" *ngIf="vendor?.salesOrder?.shipping_carrier_invalley">{{
                            vendor?.salesOrder?.shipping_carrier_invalley }}</div>
                        <div class="" *ngIf="vendor?.salesOrder?.shipping_carrier_outvalley">{{
                            vendor?.salesOrder?.shipping_carrier_outvalley }}</div>
                        <div class="" *ngIf="vendor?.salesOrder?.shipping_carrier_int">{{ vendor?.salesOrder?.shipping_carrier_int }}</div>
                        <div class="" *ngIf="vendor?.salesOrder?.shipping_carrier_invalley_others">{{
                            vendor?.salesOrder?.shipping_carrier_invalley_others }}</div>
                        <div class="" *ngIf="vendor?.salesOrder?.shipping_carrier_int">{{ vendor?.salesOrder?.shipping_carrier_int }}</div>
                        <div class="text-secondary"><strong>Reference:</strong></div>
                        <div *ngIf="vendor?.salesOrder?.refrences">{{vendor?.salesOrder?.refrences}}</div>
                        <div *ngIf="!vendor?.salesOrder?.refrences">NULL</div>

                        <div class="text-secondary"><strong>SalesPerson:</strong></div>
                        <div class="" *ngIf="vendor?.salesOrder?.sales_person">{{ vendor?.salesOrder?.sales_person }}</div>
                        <div *ngIf="!vendor?.salesOrder?.sales_person">-</div>
                    </div>

                </div>


                <div class="grid grid-cols-12 gap-x-1 mt-16">
                    <!-- Columns -->
                    <!-- Columns -->
                    <div class="col-span-6 font-medium text-md text-secondary">PRODUCT</div>
                    <div class="col-span-2 font-medium text-md text-right text-secondary">RATE</div>
                    <div class="col-span-2 font-medium text-md text-right text-secondary">QTY</div>
                    <div class="col-span-2 font-medium text-md text-right text-secondary">TOTAL</div>

                    <!-- Divider -->
                    <div class="col-span-12 my-4 border-b"></div>

                    <!-- Products Loop -->
                    <ng-container *ngFor="let product of vendor?.salesOrder?.products" class="col-span-12 flex items-center">
                        <!-- Product Avatar (Image) -->
                        <div class="col-span-6">
                            <div class="col-span-2">
                                <img [src]="getThumbnailUrl(product.product.thumbnail)"
                                    alt="{{ product.product.productName }}" class="w-12 h-12 rounded-full">
                            </div>
                            <div class="col-span-10">
                                <div class="text-lg font-medium">{{ product.product.productName }}</div>
                                <div class="mt-2 text-md text-secondary">{{ product.product.description }}</div>
                            </div>
                        </div>

                        <div class="col-span-2 self-center text-right">रू {{ product.rate }}</div>
                        <div class="col-span-2 self-center text-right">{{ product.quantity }}</div>
                        <div class="col-span-2 self-center text-right">रू {{ product.amount }}</div>
                        <!-- Adjusted col-span to 2 for TOTAL -->

                        <!-- Divider -->
                        <div class="col-span-12 my-4 border-b"></div>
                    </ng-container>

                    <!-- Spacer -->
                    <div class="col-span-12 mt-16"></div>

                    <!-- Subtotal -->
                    <div class="col-span-10 self-center font-medium tracking-tight text-secondary">SUBTOTAL</div>
                    <div class="col-span-2 text-right text-lg">रू {{ vendor?.salesOrder?.sub_total }}</div>

                    <!-- Divider -->
                    <div class="col-span-12 my-3 border-b"></div>

                    <!-- Shipping Charges -->
                    <div class="col-span-10 self-center font-medium tracking-tight text-secondary">ADJUSTMENT
                    </div>
                    <div class="col-span-2 text-right text-lg">रू {{ vendor?.salesOrder?.adjustment }}</div>

                    <!-- Divider -->
                    <div class="col-span-12 my-3 border-b"></div>

                    <!-- Discount -->
                    <div class="col-span-10 self-center font-medium tracking-tight text-secondary">DISCOUNT</div>
                    <div class="col-span-2 text-right text-lg">रू {{ vendor?.salesOrder?.discount }}</div>

                    <!-- Divider -->
                    <div class="col-span-12 my-3 border-b"></div>

                    <!-- Total -->
                    <div class="col-span-10 self-center text-2xl font-medium tracking-tight text-secondary">TOTAL</div>
                    <div class="col-span-2 text-right text-2xl font-medium">रू {{ vendor?.salesOrder?.total }}</div>
                </div>



                <div class="mt-16">
                    <div class="font-medium">Notes To the Customer.</div>
                    <div class="flex items-start mt-4">
                        <img class="flex-0 w-20 mt-2" src="assets/images/logo/logo.png">
                        <div class="ml-6 text-sm text-secondary">
                            {{vendor?.salesOrder?.notes}}
                        </div>
                    </div>
                </div>

            </div>

        </div>

    </div>
</div>