<div *ngIf="vendor" class="mt-10 ml-15 md:ml-50 lg:ml-90">


    <div class="absolute inset-0 min-w-0 text-center overflow-auto print:overflow-visible" cdkScrollable>


        <div #screenshotElement  class="w-full inline-block p-6 sm:p-10 text-left print:p-0">

            <div
                class="w-full  p-16 rounded-2xl  bg-card print:w-auto print:p-0 print:rounded-none print: print:bg-transparent">

              


                <div class="flex items-start">

                    <div class="grid grid-rows-2 place-items-start ">
                        <div class="grid auto-cols-max grid-flow-col gap-x-5">

                            <div class="pl-4text-md">
                                <img class="flex-0 w-20 mt-2" src="assets/images/logo/logo.png">



                                <div class="font-medium">
                                    customercare@hubanepal.com
                                </div>





                                <div class="tracking-tight mr-3">
                                    <div> Saraswatinagar
                                        Kathmandu,<br>
                                        Nepal</div>

                                </div>


                            </div>
                        </div>

                    </div>




                    <div class="grid grid-cols-2 gap-x-4 gap-y-1 ml-auto">

                        <div class="justify-self-end text-4xl tracking-tight text-secondary">Sales Order</div>
                        <div class="text-4xl">{{vendor?.sales_order_num}}</div>
                        <div class="justify-self-end font-medium tracking-tight text-secondary">Created Date</div>
                        <div class="font-medium">{{vendor?.slaes_order_date}}</div>
                        <div class="justify-self-end font-medium tracking-tight text-secondary">Expected Shipment Date
                        </div>
                        <div class="font-medium">{{vendor?.sales_order_shippmentDate}}</div>
                        <div class="justify-self-end font-medium tracking-tight text-secondary">Total </div>
                        <div class="font-medium">रू {{vendor?.total}}</div>
                        <div class="justify-self-end font-medium tracking-tight text-secondary">Payment terms </div>
                        <div class="font-medium">{{vendor?.payment_type}}</div>
                    </div>

                </div>
                To - {{ vendor?.Customers?.firstName }} {{ vendor?.Customers?.lastName
                }}



                <div class="flex mt-5">
                    <table class="w-full border-collapse border border-silver">
                        <!-- Table Header -->
                        <thead>
                            <tr>
                                <th class="border border-silver p-2 text-left font-medium text-lg ">PRODUCT</th>
                                <th class="border border-silver p-2 text-center font-medium text-lg">SIZE</th>
                                <th class="border border-silver p-2 text-center font-medium text-lg">COLOR</th>
                                <th class="border border-silver p-2 text-center font-medium text-lg">RATE</th>
                                <th class="border border-silver p-2 text-center font-medium text-lg">QTY</th>
                                <th class="border border-silver p-2 text-center font-medium text-lg">AMOUNT</th>
                            </tr>
                        </thead>

                        <!-- Table Body for Products -->
                        <tbody>
                            <tr *ngFor="let product of vendor.products" class="border-b border-silver">
                                <td class="border-r border-silver p-2">
                                    <div class="text-md font-medium">
                                        <ng-container *ngIf="product.isBundle; else productInfo">
                                            {{ product?.product_bundle?.bundle_name }}
                                        </ng-container>
                                        <ng-template #productInfo>
                                            {{ product?.displayName }}
                                        </ng-template>
                                    </div>
                                    <div class="mt-2 text-md text-secondary">
                                        <ng-container *ngIf="product.isBundle; else productDescription">
                                            {{ product?.product_bundle.bundle_description }}
                                        </ng-container>
                                        <ng-template #productDescription>
                                            {{ product?.displayDescription }}
                                        </ng-template>
                                    </div>
                                </td>
                                <td class="border-r border-silver p-2 text-center text-md">{{ product?.product.size }}
                                </td>
                                <td class="border-r border-silver p-2 text-center text-md">{{ product?.product.color }}
                                </td>
                                <td class="p-2 text-center border-r border-silver text-md">रू {{ product?.rate }}</td>
                                <td class="border-r border-silver p-2 text-center text-md"><strong>{{ product?.quantity
                                        }} pcs</strong></td>
                                <td class="p-2 text-center text-md"><strong>रू {{ product?.amount }}</strong></td>
                            </tr>

                            <!-- Table Body for Modified Products -->
                            <tr *ngFor="let product of vendor.mod_products" class="border-b border-silver">
                                <td class="border-r border-silver p-2 dim">
                                    <div class="text-md font-medium">
                                        <ng-container *ngIf="product?.isBundle; else productInfo">
                                            {{ product?.product_bundle?.bundle_name }}
                                        </ng-container>
                                        <ng-template #productInfo>
                                            {{ product?.displayName }}
                                        </ng-template>
                                    </div>
                                    <div class="mt-2 text-md text-secondary">
                                        <ng-container *ngIf="product?.isBundle; else productDescription">
                                            {{ product?.product_bundle?.bundle_description }}
                                        </ng-container>
                                        <ng-template #productDescription>
                                            {{ product?.displayDescription }}
                                        </ng-template>
                                    </div>
                                </td>
                                <td class="border-r border-silver p-2 text-center dim text-md">{{ product?.product?.size
                                    }}</td>
                                <td class="border-r border-silver p-2 text-center dim text-md">{{
                                    product?.product?.color }}</td>
                                <td class="p-2 text-center border-r border-silver dim text-md">रू {{ product?.rate }}
                                </td>
                                <td class="border-r border-silver p-2 text-center dim text-md">{{ product?.quantity }}
                                    pcs
                                </td>
                                <td class="p-2 text-center dim text-md">रू {{ product?.amount }}</td>
                            </tr>
                        </tbody>

                        <!-- Subtotal -->
                        <tfoot class="">
                            <tr>
                                <td colspan="5" class=" border-silver p-2 text-right font-medium text-md">
                            
                                        SUBTOTAL
                                </td>
                                <td class="border border-silver p-2 text-right text-md"><strong>
                                        रू {{ vendor?.sub_total }}
                                    </strong></td>
                            </tr>

                            <!-- Adjustment -->
                            <tr>
                                <td colspan="5" class=" border-silver p-2 text-right font-medium text-md">
                                        ADJUSTMENT
                                </td>
                                <td class="border border-silver p-2 text-right text-md"><strong>
                                        {{ vendor?.adjustmentSuffix | uppercase }} {{ vendor?.adjustment }}
                                    </strong></td>
                            </tr>

                            <!-- Discount -->
                            <tr>
                                <td colspan="5" class=" border-silver p-2 text-right font-medium text-md">
                                        DISCOUNT
                                </td>
                                <td class="border border-silver p-2 text-right text-md"><strong>
                                        {{ vendor?.discountSuffix }} {{ vendor?.discount }}
                                    </strong></td>
                            </tr>

                            <tr>
                                <td colspan="5" class=" border-silver p-2 text-right font-medium text-md">
                                        SHIPPING CHARGES
                                </td>
                                <td class="border border-silver p-2 text-right text-md"><strong>
                                        {{ vendor?.shipping_charges }}
                                    </strong></td>
                            </tr>




                            <!-- Total -->
                            <tr>
                                <td colspan="5" class=" border-silver p-2 text-right font-medium text-md">
                                        TOTAL
                                </td>
                                <td class="border border-silver p-2 font-medium text-right text-md"><strong>
                                        रू {{ vendor?.total }}
                                    </strong></td>
                            </tr>
                        </tfoot>
                    </table>
                </div>





                <div class="mt-16">
                    <div class="font-medium">Notes To the Customer: {{vendor?.notes}}
                        <div class="tracking-light">

                        </div>
                    </div>

                </div>


            </div>



        </div>


    </div>
</div>