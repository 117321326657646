import { Injectable } from '@angular/core';
import { AuthService } from '../auth.service';
import { UserMockApi } from 'app/mock-api/common/user/api';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  constructor(
    private authService: AuthService,
    private _userMockApi: UserMockApi
  ) {}

  async withRoleCheck(requiredRoles: string[], action: () => Promise<void>): Promise<void> {
    const user = this._userMockApi.getUser();  // Fetch the user directly from UserMockApi
    const userRoles = user.roles || [];  // Ensure roles are an array

    if (!this.authService._authenticated || !this.hasRequiredRoles(userRoles, requiredRoles)) {
      Swal.fire({
        icon: 'error',
        title: 'Access Denied',
        text: 'You do not have permission to perform this action.',
      });
      return;
    }

    try {
      await action();
    } catch (error) {
      console.error('Error executing action:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An unexpected error occurred while performing the action.',
      });
    }
  }

  private hasRequiredRoles(userRoles: string[], requiredRoles: string[]): boolean {
    if (!requiredRoles || requiredRoles.length === 0) {
      return true;
    }
    // Check if any required role is present in user roles
    return requiredRoles.some(role => userRoles.includes(role));
  }
}
