import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductBundleComponent } from './product-bundle/product-bundle.component';
import { ListProductBundleComponent } from './list-product-bundle/list-product-bundle.component';
import { DetailProductBundleComponent } from './detail-product-bundle/detail-product-bundle.component';
import { CreateProductBundleComponent } from './create-product-bundle/create-product-bundle.component';
import { MaterialModule } from 'app/modules/material/material.module';
import { RouterModule } from '@angular/router';
import { ProductBundleRoute } from './product-bundle.routing';
import { MatDialogModule } from '@angular/material/dialog';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { NgxColorsModule } from 'ngx-colors';
import { ProductBundlePopupComponent } from 'app/product-bundle-popup/product-bundle-popup.component';
@NgModule({
    declarations: [
        ProductBundleComponent,
        ListProductBundleComponent,
        DetailProductBundleComponent,
        CreateProductBundleComponent,
        ProductBundlePopupComponent,
    ],
    imports: [
        RouterModule.forChild(ProductBundleRoute),
        CommonModule,
        MaterialModule,
        MatDialogModule,
        MatAutocompleteModule,
        NgxColorsModule,
    ],
})
export class ProductBundleModule {}
