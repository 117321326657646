import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { CreditNotesService } from '../credit-notes.service';
import { Refund } from '../credit-notes.type';
import { take } from 'rxjs/operators';

export interface RefudData {
    date: string;
    amount: number;

}

@Component({
    selector: 'app-detail-credit-notes',
    templateUrl: './detail-credit-notes.component.html',
    styleUrls: ['./detail-credit-notes.component.scss'],
})
export class DetailCreditNotesComponent implements OnInit {
    displayedColumns: string[] = [
        'date',
        'amount',
        'remaning_amount'

    ];
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    refund$: Observable<Refund[]>;

    panelOpenState = false;

    productId: string;
    vendor: any; // Replace 'any' with the actual type of your product
    refund: any;
    refund_total: any;
    dataSource: MatTableDataSource<RefudData>;
    length: any;

    constructor(
        private route: ActivatedRoute,
        private productService: CreditNotesService // Replace with your actual product service
    ) { }

    ngOnInit(): void {
        this.route.paramMap.subscribe((params) => {
            this.productId = params.get('id');
            this.fetchProductDetails(this.productId);
        });

        this.refund$ = this.productService.getRefundHistories(this.productId).pipe(take(1));
        this.refund$.subscribe((salesorder) => {
            this.refund = salesorder['data'];
            this.refund_total = this.refund[0].total

            this.length = this.refund.length;
            this.dataSource = new MatTableDataSource<RefudData>(
                this.refund.map((salesOrder) => {
                    return {
                        date: salesOrder.refund_date,
                        amount: salesOrder.refunded_amount,
                        remaining_amount: salesOrder.remaining_amount
                    };
                })


            );
        });
    }

    determineVoucherType(): string {
        if (this.vendor?.salesReturn) {
            const id = this.vendor?.salesReturn.id;

            const Returnlink = `/apps/sales-return/${id}`
            return Returnlink

        } else if (this.vendor?.salesExchange) {
            const id = this.vendor?.salesExchange.id;

            const Returnlink = `/apps/exchanges/${id}`
            return Returnlink
        } else {
            return '';
        }
    }

    fetchProductDetails(productId: string): void {
        // Use your product service to fetch the product details based on the provided ID
        this.productService.getSalesOrderById(productId).subscribe(
            (data) => {
                this.vendor = data;
                // this.product.thumbnail = this.getThumbnailUrl(data.thumbnail); // Update the thumbnail URL
            },
            (error) => {
                console.error('Error fetching product details:', error);
            }
        );


    } printPage() {
        // Select the buttons you want to hide
        const buttonsToHide = document.querySelectorAll('button');

        // Hide the buttons before printing
        buttonsToHide.forEach((button) => {
            button.style.display = 'none';
        });

        // Perform the printing
        window.print();

        // Show the buttons again after printing (delayed to ensure they show after the print dialog)
        setTimeout(() => {
            buttonsToHide.forEach((button) => {
                button.style.display = 'block'; // or 'inline' depending on the button's original display property
            });
        }, 1000); // Adjust the delay as needed
    }


    // getRefundHistory(credit_notes_id: string) {
    //     this.productService.getRefundHistories(credit_notes_id).subscribe((data) => {
    //         const creditnotesId = data.credit_note
    //         this.refund = data

    //     })
    // }

    goBack = () => {
        window.history.back();
    }

    hasVoucherPattern(): boolean {
        if (this.vendor && this.vendor.voucher_description) {
          const pattern = /SO-\d+/; // Regular expression to match "SO-" followed by digits
          return pattern.test(this.vendor.voucher_description);
        }
        return false;
      }
    
      getVoucherNumberFromDescription(): string {
        if (this.vendor && this.vendor.voucher_description) {
          const pattern = /SO-\d+/; // Regular expression to match "SO-" followed by digits
          const match = this.vendor.voucher_description.match(pattern);
          if (match) {
            return match[0]; // Return the matched voucher number
          }
        }
        return ''; // Return an empty string if no match is found
      }
}
