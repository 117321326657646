import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { CUSTOM_ELEMENTS_SCHEMA, Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { productCategoryService } from 'app/product-categories/product-categories.service';
import { productCategories } from 'app/product-categories/product-categories.type';
import { Observable } from 'rxjs';
import { map, startWith, take } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { ProductOptionsPopupComponent } from '../product-options-popup/product-options-popup.component';
import { OptionSubmissionService } from '../product-options-popup/product.options.service';
import { ProductVarientsComponent } from '../product-varients/product-varients.component';
import { productsService } from '../products.service';
import { server_env } from 'config';


export interface Fabric {
  name: string;
}


interface ProductVariant {
  id?: any;
  varient?: string;
  size: number;
  color: number;
  price?: number;
  stock?: number;
  status?: string;
  sku?: string;
  visibility: string;
}


let SIZE_CREDITS_DATA: ProductVariant[] = [

  // ... other variants
];

let SIZE_CREDITS_DATAS: ProductVariant[] = [

  // ... other variants
];


@Component({
  selector: 'app-detail-product',
  templateUrl: './detail-product.component.html',
  styleUrls: ['./detail-product.component.scss'],
})
export class DetailProductComponent implements OnInit {
  typesOfShoes = [


  ];
  genders = [
    {

      "name": "Male"
    },
    {

      "name": "Female"
    },
    {

      "name": "Unisex"
    }
  ];

  secondaryFabrics: Fabric[] = [

  ];
  innerFabrics: Fabric[] = [

  ];
  outerFabrics: Fabric[] =
    [

    ];

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  sizes: any;
  color: any;
  secondary: boolean = false;
  inner: boolean = false;
  outter: boolean = false;
  showFabricOptions: boolean = false;
  productId: string;
  product: any;
  varaints: any;
  productForm: FormGroup;
  displayedColumns: string[] = ['Varient', 'Size', "Color", 'Price', "SKU"];
  displayedColumnss: string[] = ['Varient', 'Size', "Color", "SKU", "Stock"];
  variations: any[] = [];
  variationss = [];
  dataSource: MatTableDataSource<ProductVariant>;
  sizeDataPushed = false;
  colorDataPushed = false;
  combinedVariations = [];
  updatedVariations = [];
  dataSources: MatTableDataSource<ProductVariant>;
  selected_gender: any;
  showOthers: boolean = false;


  categories = [
    { name: 'Street', selected: false },
    { name: 'Category 2', selected: false },
    { name: 'Category 3', selected: false }
  ];
  isChecked = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  categoriesCTR = new FormControl('');
  filteredCat: Observable<string[]>;
  prodcutsCtr: any;
  fruitInput: any;
  allproducts: any;
  newCategory: string = '';
  showAddCategoryField: boolean = false;
  id: string;
  show_ppu_toogle: boolean;
  displayedCategories: any[] = [];
  pageSize: number = 5;
  currentPage: number = 1;
  searchText: string = '';
  totalPages: number = 0;
  weight_alis : string = '';
  thumbnail: string;
  category$: Observable<productCategories[]>;
  category: productCategories[];
  isLoading: boolean = true;
  constructor(
    private _activatedRoute: ActivatedRoute,
    private productService: productsService, // Replace with your actual product service
    private _formBuilder: FormBuilder,
    private dialog: MatDialog,
    private cdRef: ChangeDetectorRef,
    private _router: Router,
    private _categorService: productCategoryService,
    private optionSubmissionService: OptionSubmissionService,



  ) {


    this.filteredCat = this.categoriesCTR.valueChanges.pipe(
      startWith(null),
      map((fruit: string | null) =>
        fruit ? this._filter(fruit) : this.allproducts.slice()
      )
    );

    this.dataSource = new MatTableDataSource<ProductVariant>(SIZE_CREDITS_DATA);
    this.dataSource.data = SIZE_CREDITS_DATA;

    this.dataSources = new MatTableDataSource<ProductVariant>(SIZE_CREDITS_DATAS);
    this.dataSources.data = SIZE_CREDITS_DATAS;
  }

  get filteredCategories() {
    const startIndex = (this.currentPage - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;

    return this.categories.filter(category =>
      category.name.toLowerCase().includes(this.searchText.toLowerCase())
    ).slice(startIndex, endIndex);
  }


  ngOnInit(): void {

    this.category$ = this._categorService.getproducts().pipe(take(1));
    this.category$.subscribe((category) => {
      this.category = category['data'];



      this.categories = this.category.map((category) => {
        return {
          name: category.category_name,
          id: category.id,
          selected: false
        };
      });

      this.displayedCategories = this.categories.slice(0, this.pageSize);
      this.totalPages = Math.ceil(this.categories.length / 5);
    });



    this._activatedRoute.paramMap.subscribe(params => {
      this.productId = params.get('id');
      this.fetchProductDetails(this.productId);
      this.getVarients(this.productId);

    });


    this.productForm = this._formBuilder.group({
      productName: ['', Validators.required],
      on_sale: [false],
      sku: ['', Validators.required],
      description: [''],
      basePrice: [''],
      margin: [''],
      cost_of_goods: [0],
      profit: [''],
      material: [''],
      fabric: [''],
      secondary_fabric: [''],
      secondary_fabric_c: [false],
      product_weight: [0],
      product_weight_alis: [''],
      inner_fabric: [''],
      outter_fabric: [''],
      inner_fabric_c: [false],
      outter_fabric_c: [false],
      discount: [''],
      salePrice: [''],
      show_ppu: [false],
      size: [],
      color: [],
      varients_toggel: [''],
      pre_order_toggel: [false],
      expected_release_date: [''],
      collection: [''],
      year: [''],
      year_others: [''],
      gender: ['']
    });

  }

  toggleFullScreen(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    if (!document.fullscreenElement) {
        target.requestFullscreen().catch(err => console.log(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`));
    } else {
        document.exitFullscreen();
    }
}
  toggleInputss(): void {
    this.productForm.get('collection').setValue('WINTER');
  }
  toggleInputs(): void {
    this.productForm.get('year').setValue('2023');
  }


  fetchProductDetails(productId: string): void {
    // Use your product service to fetch the product details based on the provided ID
    this.productService.getproductsById(productId).subscribe(
      (data) => {
        this.product = data;
        this.id = data.id
        this.showFabricOptions = data?.varients_toggel;
        this.secondary = data?.secondary_fabric !== "";
        this.inner = data?.inner_fabric !== "";
        this.outter = data?.outter_fabric !== "";
        const genderValueFromData = data?.gender;
        const categoryValueFormDatat = data?.category;
        const collection_data = data?.collection;
        const secondary_fab = data?.secondary_fabric;
        const inner_fab = data?.inner_fabric;
        const secondary_name = secondary_fab?.map(fabric => fabric?.name).join(', ');
        const inner_name = inner_fab.map(fabric => fabric?.name).join(', ');
        const selectedCategories = categoryValueFormDatat?.split(',');
        this.thumbnail = server_env.URL_MEDIA+ data.size_chart;
      

        this.categories.forEach(category => {
          category.selected = selectedCategories?.includes(category?.name);
        });

        const selectedGenderObject = this.genders.find(gender => gender?.name === genderValueFromData);
        this.selected_gender = selectedGenderObject ? selectedGenderObject.name : null;
        if (data.collection !== 'others') {
          this.productForm.patchValue({
            collection: collection_data?.collection_name,


          })
        }

        this.productForm.patchValue({
          productName: data.productName,
          on_sale: data.on_sale,
          sku: data.sku,
          fabric: data.fabric.name,
          basePrice: data.basePrice,
          margin: data.margin,
          cost_of_goods: data.cost_of_goods,
          profit: data.profit,
          material: data.material,
          discount: data.discount,
          salePrice: data.salePrice,
          show_ppu: data.show_ppu,
          expected_release_date: data.expected_release_date,
          varients_toggel: data.varients_toggel,
          pre_order_toggel: data.pre_order_toggel,
          size: data.size,
          color: data.color,
          secondary_fabric_c: data.secondary_fabric_c,
          inner_fabric_c: data.inner_fabric_c,
          outter_fabric_c: data.outter_fabric_c,
          description: data.description,
          gender: data.gender,
          secondary_fabric: secondary_name,
          inner_fabric: inner_name,
          year: data.year,
          year_others: data.year_others,
          product_weight: data.product_weight,


        });

        this.weight_alis = data.weight_alis;

        if (data.secondary_fabric !== "" && data.secondary_fabric.length > 0) {
          this.secondaryFabrics = this.createChips(data.secondary_fabric);
        } else {
          this.showFabricOptions = false;
          this.secondary = false;
        }
        
        if (data.inner_fabric !== "" && data.inner_fabric.length > 0) {
          this.innerFabrics = this.createChips(data.inner_fabric);
        } else {
          this.showFabricOptions = false;
          this.inner = false;
        }
        


        if (data.color) {
          const colorsArray = data.color.split(',');

          this.typesOfShoes.push({
            name: "Color",
            values: colorsArray,
          });
        }

        this.colorDataPushed = true;
        this.optionSubmissionService.submitOption("color");

        if (data.size) {
          const sizesArray = data.size.split(',');

          this.typesOfShoes.push({
            name: "Size",
            values: sizesArray,
          });
        }
        this.sizeDataPushed = true;


        this.optionSubmissionService.submitOption("size");

        const colorString: string = this.color && this.color.length > 0 ? this.color.join(',') : '';

        const sizeString: string = this.sizes && this.sizes.length > 0 ? this.sizes.join(',') : '';


        this.productForm.patchValue({
          color: colorString,
          size: sizeString
        });



        this.typesOfShoes.forEach(shoeType => {

        });

      });
      
  }
  createChips(fabricString: string): any[] {
    if (typeof fabricString !== 'string') {
      fabricString = '';
    }

    const fabricNames = fabricString
      .split(',')
      .map(value => value.trim())
      .filter(value => value !== "")
      .map(name => ({ name }));

    return fabricNames;
  }



  getVarients(id: string) {
    this.productService.getProductVariations(id).subscribe((data) => {
      this.variations = data.data.map(item => {
        const newVariation = {
          id: item.id,
          varient: item.productName,
          size: item.size,
          color: item.color,
          status: item.status,
          price: item.basePrice,
          sku: item.sku,
          stock: item.stock,




        };
        return newVariation;
      });


      this.combinedVariations = [...this.variations]; // Use spread operator to create a new array

      SIZE_CREDITS_DATA = [...this.variations]; // Use spread operator to create a new array
      this.dataSource.data = SIZE_CREDITS_DATA

      SIZE_CREDITS_DATAS = [...this.variations]; // Use spread operator to create a new array
      this.dataSources.data = SIZE_CREDITS_DATAS;
      this.isLoading = false;


    });
  }


  nextPage() {
    const startIndex = this.currentPage * this.pageSize;
    this.displayedCategories = this.filteredCategories;
    this.currentPage++;
  }

  prevPage() {
    this.currentPage--;
    const startIndex = (this.currentPage - 1) * this.pageSize;
    this.displayedCategories = this.filteredCategories;
  }




  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our fruit
    if (value) {
      this.prodcutsCtr.push(value);
    }

    // Clear the input value
    event.chipInput!.clear();

    this.categoriesCTR.setValue(null);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.prodcutsCtr.push(event.option.viewValue);
    this.fruitInput.nativeElement.value = '';
    this.categoriesCTR.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allproducts.filter((fruit) =>
      fruit.toLowerCase().includes(filterValue)
    );
  }



  toggleAddCategoryField() {
    this.showAddCategoryField = !this.showAddCategoryField;
  }


  deleteShoe(shoe: any): { item: any, type: string } | null {
    const index = this.typesOfShoes.indexOf(shoe);

    if (index !== -1) {
      const deletedShoe = this.typesOfShoes.splice(index, 1)[0];
      const type = deletedShoe.values === shoe.name ? 'color' : 'size';


      if (type === 'color') {
        this.colorDataPushed = false;
        this.color = []; // Clear color data


      } else if (type === 'size') {
        this.sizeDataPushed = false;
        this.sizes = []; // Clear size data



      }

      // Delete the option using the service
      this.optionSubmissionService.deleteOption(deletedShoe.name);

      // Clear variations associated with the deleted shoe
      this.combinedVariations = [];

      // Update SIZE_CREDITS_DATA and SIZE_CREDITS_DATAS
      SIZE_CREDITS_DATA = [];
      SIZE_CREDITS_DATAS = [];

      // Subscribe to the form data observable to get updated data
      const formDataSubscription = this.optionSubmissionService.getFormDataObservable().subscribe(updatedFormData => {
        // Unsubscribe to avoid memory leaks
        formDataSubscription.unsubscribe();

        // Handle the updated form data here

        // Create new variations with the remaining color or size
        if (this.color.length > 0 && this.sizes.length > 0) {
          this.color.forEach(color => {
            this.sizes.forEach(size => {
              const variation = {
                color: color,
                size: size,
                // Add other properties like price difference, variant price, etc.
              };

              const newVariation = {
                varient: `${color}${size}`,
                size: size,
                color: color,
                sku: "SK01",
                status: 'inactive',
                visibility: 'hidden' // You can set visibility as needed
              };

              this.combinedVariations.push(variation);
              SIZE_CREDITS_DATA.push(newVariation);
              SIZE_CREDITS_DATAS.push(newVariation);
            });
          });

          this.dataSource.data = SIZE_CREDITS_DATA;
          this.dataSources.data = SIZE_CREDITS_DATAS;

          // Optionally, you can manually trigger change detection
          this.cdRef.detectChanges();

          // Replace the existing typesOfShoes array with the combinedVariations array
          this.typesOfShoes = this.combinedVariations;
        }

        // ... continue with the rest of your deleteShoe logic if needed
      });

      return { item: deletedShoe, type: type };
    }

    return null;
  }







  openProductDetailOption(): void {

    const dialogRef = this.dialog.open(ProductOptionsPopupComponent, {
      width: '800px',
      height: '400px;'

    });
    dialogRef.componentInstance.formSubmitted.subscribe((formData) => {
      this.productForm.patchValue({
        color: formData.color,
        size: formData.sizes,
        // ... map other fields here
      });
      // Call your service method here
      if (formData.color) {
        this.typesOfShoes.push({
          name: formData.option_name,
          values: formData.color, // Assuming sizes is an array

        });
        this.colorDataPushed = true;
        this.color = formData.color;

      }

      if (formData.sizes) {
        this.typesOfShoes.push({
          name: formData.option_name,
          values: formData.sizes // Assuming color is an array

        });
        this.sizeDataPushed = true;
        this.sizes = formData.sizes;
      }
      // Assuming this.color and this.sizes are arrays containing color and size values respectively

      // Convert arrays to comma-separated strings
      const colorString: string = this.color.join(',');
      const sizeString: string = this.sizes.join(',');

      // Patch the form values with the comma-separated strings
      this.productForm.patchValue({
        color: colorString,
        size: sizeString
      });


      if (this.colorDataPushed && this.sizeDataPushed) {
        this.show_ppu_toogle = true;
        const colorVariations = this.typesOfShoes.find(variation => variation.name === 'Color');
        const sizeVariations = this.typesOfShoes.find(variation => variation.name === 'Size');


        if (colorVariations && sizeVariations) {
          colorVariations.values.forEach(color => {
            sizeVariations.values.forEach(size => {
              const variation = {
                color: color,
                size: size,
                // Add other properties like price difference, variant price, etc.
              };


              const newVariation = {
                varient: `${color}${size}`,
                size: size,
                color: color,
                sku: "SK01",
                stock: 0,
                status: 'inactive',
                visibility: 'hidden' // You can set visibility as needed
              };

              this.combinedVariations.push(variation);
              SIZE_CREDITS_DATA.push(newVariation);
              SIZE_CREDITS_DATAS.push(newVariation);
              // Manually trigger change detection
              this.cdRef.detectChanges();
            });
          });

          this.dataSource.data = SIZE_CREDITS_DATA;
          this.dataSources.data = SIZE_CREDITS_DATAS;


          // Optionally, you can manually trigger change detection
          this.cdRef.detectChanges();
          this.productForm.get('varients_toggel')?.setValue(true, { emitEvent: false });
          this.openProductVariations();

        }
      }


      const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener(
            'mouseenter',
            Swal.stopTimer
          );
          toast.addEventListener(
            'mouseleave',
            Swal.resumeTimer
          );
        },
      });

      Toast.fire({
        icon: 'success',
        title: ` Vairents   created!! `,
      });
      // Handle the response from the service if needed
    })






    dialogRef.afterClosed().subscribe((result) => {

    });
  }


  openProductVariations(showAssumedInventory: boolean = false): Promise<any> {
    return new Promise((resolve) => {
      const dialogRef = this.dialog.open(ProductVarientsComponent, {
        width: '3000px',
        height: '2000px',
        data: {
          variations: this.combinedVariations,
          showAssumedInventory: showAssumedInventory
        }
      });

      dialogRef.componentInstance.formSubmitted.subscribe((formData) => {
        if (formData && Array.isArray(formData.varients)) {
          // Initialize this.variationss with an empty array
          this.variationss = [];

          formData.varients.forEach((variant, index) => {
            // Use the original product ID if variant.id is undefined
            const id = variant.id !== undefined ? variant.id : this.combinedVariations[index].id;

            if (index < this.combinedVariations.length) {
              this.combinedVariations[index] = this.createVariationObject(variant, id);
              SIZE_CREDITS_DATA[index] = this.createSizeCreditsObject(variant, id);

              // Push the data into this.variationss
              this.variationss.push({
                id: id, // Use the original product ID
                size: variant.size,
                color: variant.color,
                status: variant.status,
                stock: variant.stock === "" ? 0 : variant.stock, // Set to zero if stock is an empty string
                sku: variant.sku,
                price: variant.price,
                productName: variant.productName,
                active: variant.active
              });
            } else {
              console.error(`Index ${index} is out of bounds for this.combinedVariations and SIZE_CREDITS_DATA`);
            }
          });

          // Update the MatTableDataSource with the modified data
          this.dataSource.data = [...SIZE_CREDITS_DATA];

          // Manually trigger change detection
          this.cdRef.detectChanges();

          // Resolve with the formData
          resolve(formData);
        } else {
          console.error('formData.varients is not an array or formData is undefined:', formData);

          // Resolve with null if no variation data
          resolve(null);
        }
      });
    });
  }

  private createVariationObject(variant: any, id: number): any {
    return {
      id: id,
      size: variant.size,
      color: variant.color,
      status: variant.status,
      stock: variant.stock,
      sku: variant.sku,
      price: variant.price,
      visibility: 'hidden'
    };
  }

  private createSizeCreditsObject(variant: any, id: number): any {
    return {
      id: id,
      size: variant.size,
      color: variant.color,
      status: variant.status,
      varient: `${variant.color}${variant.size}`,
      stock: variant.stock,
      price: variant.price,
      sku: variant.sku,
      visibility: 'hidden'
    };
  }


  addSecondaryFabric(event: MatChipInputEvent): void {
    const input = event.input;
    const value = (event.value || '').trim();

    if (value) {
      // Add fabric to the secondaryFabrics array
      this.secondaryFabrics.push({ name: value });

      // Update the form control value
      const secondaryFabricControl = this.productForm.get('secondary_fabric');
      const fabricNames = this.secondaryFabrics.map(fabric => fabric.name);

      // Set the form control value with comma-separated fabric names
      secondaryFabricControl.setValue(fabricNames.join(', '));
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }



  remove(fabric: Fabric): void {
    const index = this.secondaryFabrics.indexOf(fabric);

    if (index >= 0) {
      this.secondaryFabrics.splice(index, 1);
    }
  }


  addInnerFabric(event: MatChipInputEvent): void {
    const input = event.input;
    const value = (event.value || '').trim();

    if (value) {
      // Add fabric to the innerFabrics array
      this.innerFabrics.push({ name: value });

      // Update the form control value
      const innerFabricControl = this.productForm.get('inner_fabric');
      const fabricNames = this.innerFabrics.map(fabric => fabric.name);

      // Set the form control value with comma-separated fabric names
      innerFabricControl.setValue(fabricNames.join(', '));
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }


  removeInnerFabric(fabric: Fabric): void {
    const index = this.innerFabrics.indexOf(fabric);

    if (index >= 0) {
      this.innerFabrics.splice(index, 1);
    }
  }

  addOuterFabric(event: MatChipInputEvent): void {
    const input = event.input;
    const value = (event.value || '').trim();

    if (value) {
      // Add fabric to the outerFabrics array
      this.outerFabrics.push({ name: value });

      // Update the form control value
      const outerFabricControl = this.productForm.get('outter_fabric');
      const fabricNames = this.outerFabrics.map(fabric => fabric.name);

      // Set the form control value with comma-separated fabric names
      outerFabricControl.setValue(fabricNames.join(', '));
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  removeOuterFabric(fabric: Fabric): void {
    const index = this.outerFabrics.indexOf(fabric);

    if (index >= 0) {
      this.outerFabrics.splice(index, 1);
    }
  }



  toggleFabricOptions(): void {
    this.showFabricOptions = !this.showFabricOptions;
    this.secondary = false;
    this.inner = false;
    this.outter = false;
  }

}
