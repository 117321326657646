
import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { Router } from '@angular/router';
import { productsService } from 'app/products/products.service';
import { products } from 'app/products/products.type';

import { Observable, of, forkJoin, BehaviorSubject } from 'rxjs';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';

import {
  map,
  switchMap,
  take,
} from 'rxjs/operators';
import { InventoryEntryService } from 'app/inventory-entry/inventory-entry.service';
import { productVaraitons } from 'app/productVariations/productVariations.service';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StockSubmissionService } from '../stock-submission-service.service';
import { productVaraitonService } from 'app/productVariations/productVarations.type';
@Component({
  selector: 'app-initalstock-popup',
  templateUrl: './initalstock-popup.component.html',
  styleUrls: ['./initalstock-popup.component.scss']
})
export class InitalstockPopupComponent implements OnInit {
  @ViewChild('scrollframe', { static: false }) scrollFrame: ElementRef;
  @ViewChildren('item') itemElements: QueryList<any>;
  filteredProductOptions$: Observable<products[]>;
  matchedVariationIds: Set<string> = new Set();

  selectedItems: string[] = [];
  availableItems: string[] = ['Option 1', 'Option 2', 'Option 3', 'Option 4'];
  drawerMode: 'over' | 'side' = 'side';
  drawerOpened: boolean = true;
  intial_stock_entry_form: FormGroup;
  successMessage: string;
  errorMessage: string;
  selectedProductThumbnail: string[] = [];
  selectedProductThumbnailClass: string;

  genderOptions: { label: string, value: string }[] = [];
  vendorControl: FormControl = new FormControl();
  cardValue: any;
  quantity_adjustment: boolean = true;
  value_adjustment: boolean = true;
  loading$ = new BehaviorSubject<boolean>(false);
  configForm: FormGroup;


  mappedVariations: any;
  sizes: any[] = [];
  colors: any[] = [];
  active: boolean[] = [];
  animationFlags: boolean[] = [];
  todayDate: string;
  private scrollContainer: any;
  private isNearBottom = true;
  current_stock: any[] = [];
  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
  });

  constructor(

    private _formBuilder: FormBuilder,
    private elementRef: ElementRef,
    private _router: Router,
    private _productService: productsService,
    private _itemsService: InventoryEntryService,
    private cd: ChangeDetectorRef,
    private datePipe: DatePipe,
    private _fuseConfirmationService: FuseConfirmationService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<InitalstockPopupComponent>,
    private stockSubmissionService: StockSubmissionService,
    private _productVariationService: productVaraitonService

  ) { }

  ngOnInit(): void {
    console.log("the data info and type", this.data)

    this.configForm = this._formBuilder.group({
      title: 'Info',
      message: '<span class="font-medium">Are you sure you want to proceed?</span> Please be aware that once you submit this information, it cannot be reverted. Are you sure you want to finalize it? Proceed carefully.',
      icon: this._formBuilder.group({
        show: true,
        name: 'heroicons_outline:exclamation',
        color: 'info'
      }),
      actions: this._formBuilder.group({
        confirm: this._formBuilder.group({
          show: true,
          label: 'Proceed',
          color: 'accent'
        }),
        cancel: this._formBuilder.group({
          show: true,
          label: 'Cancel',
          color: 'warn'

        })
      }),
      dismissible: true
    });



    const first_date = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    const current_date = new Date(first_date)

    current_date.setDate(current_date.getDate() + 1)

    this.todayDate = this.datePipe.transform(current_date, 'yyyy-MM-dd');

    this.intial_stock_entry_form = this._formBuilder.group({
      mode_of_adujstment: ['QuantityAdjustment'],
      date: [this.todayDate], // Patch today's date into the 'date' control

      products: this._formBuilder.array([]),







    });

    this.filteredProductOptions$ = of([]);
    const selectedProduct = this.data;

    const productArray = this.intial_stock_entry_form.get('products') as FormArray;

    // Clear existing controls before adding new ones
    productArray.clear();

    const numberOfProducts = selectedProduct.length;
    this.animationFlags = Array(numberOfProducts).fill(false);
    this.active = Array(numberOfProducts).fill(false);



    (this.intial_stock_entry_form.get('products') as FormArray).clear();
    const initialProductCount = 1;

    this.active = Array(initialProductCount).fill(false);






    const productGroup = this._formBuilder.group({
      id: [selectedProduct.BASE_PRODUCT],
      product: [selectedProduct.product, [Validators.required]],
      stock: [0],
      rate: [selectedProduct.product.basePrice],
      amount: [0],
      color: [selectedProduct.color],
      size: [selectedProduct.size],
    });

    productGroup.patchValue({
      id: selectedProduct.BASE_PRODUCT,
      product: selectedProduct.product,
      stock: 0,
      rate: selectedProduct.product.basePrice,
      amount: 0,
      color: selectedProduct.color,
      size: selectedProduct.size,
    });

    this.selectOptionProduct(productGroup.get('product').value, 0);

    productArray.push(productGroup);
  }





  selectChange = (event: any) => {
    const key: string = event.key;
    this.cardValue[key] = [...event.data];
  };

  openVendorDetails() {
  }

  ngAfterViewInit(): void {
    this.scrollContainer = this.scrollFrame.nativeElement;
    this.itemElements.changes.subscribe((_) => this.onItemElementsChanged());
  }

  private scrollToMessages(): void {
    const messagesElement = this.elementRef.nativeElement.querySelector('#messages');
    if (messagesElement) {
      messagesElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }



  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }



  openConfirmationDialog(): Observable<boolean> {
    const dialogRef = this._fuseConfirmationService.open(this.configForm.value);

    return dialogRef.afterClosed().pipe(
      map(result => {
        return result === 'confirmed';
      })
    );
  }


  async sumbitInitalStock() {


    if (this.intial_stock_entry_form.valid) {
      const products = this.intial_stock_entry_form.get('products') as FormArray;

      // Calculate the total stock from the 'stock' field
      const totalStock = products.controls.reduce((sum, productControl) => {
        const stock = parseInt(productControl.get('stock').value, 10) || 0;
        return sum + stock;
      }, 0);



      let truex = true;
      let falsex = false;



      const confirmationResult = await this.openConfirmationDialog().toPromise();

      if (confirmationResult) {
        const customerData = { ...this.intial_stock_entry_form.value };

        for (let i = 0; i < products.length; i++) {
          const productData = products.at(i).value;

          try {
            // Fetch current stock for the product
            const currentStock = this.current_stock[i];



            // Validate the change in stock
            const changeInStock = parseInt(productData.stock, 10);

            // Update productsVariants
            const updatedProduct = {
              product: productData.id,
              size: productData.size,
              color: productData.color,
              basePrice: productData.price,
              base_product: productData.product.id,
              stock: currentStock + changeInStock,  // Always add to the current stock
              active: truex,
              preOrder: falsex,
              restock: truex,
            };

            console.log("changein stock", changeInStock, "current stock", currentStock, "product.stock", productData.stock, "updated product stock", updatedProduct.stock)

            if (updatedProduct.stock < 0) {
              updatedProduct.active = false;
              updatedProduct.preOrder = true;
              updatedProduct.restock = false;

              const assumedStock = {
                base_product: productData.id, // Assuming 'product' field contains an object with 'id'
                stock: productData.stock,
            };
            

              await this._productVariationService.createAssumedStock(assumedStock);
            }

            console.log(updatedProduct.active, updatedProduct.preOrder, updatedProduct.restock)

            await this._productService.updateproductsVarients(productData.id, updatedProduct);

            // Create inventory entry
            const inventoryData = {
              date: customerData.date,
              products: productData.id,
              initial_entry: productData.stock,
            };
            await this._itemsService.createInventoryEntrys(inventoryData);
            this.stockSubmissionService.announceStockSubmission();

          } catch (error) {
            // Handle error
            const errorMessage = error.message || 'Error updating Product.';
            this.Toast.fire({
              icon: 'error',
              title: errorMessage,
            });
            return; // Stop further processing in case of an error
          }
        }

        // Notify success
        this.Toast.fire({
          icon: 'success',
          title: 'Initial Stock Created.',
        });

        this.intial_stock_entry_form.reset();
        this.dialogRef.close();

        this._router.navigate(['apps/inventory-sheet']);
      } else {
        // Notify cancellation
        this.Toast.fire({
          icon: 'error',
          title: 'Operation canceled by user',
        });
      }
    } else {
      // Notify invalid form
      this.Toast.fire({
        icon: 'error',
        title: 'Invalid form data',
        text: 'Please fill in all the required fields correctly.',
      });
    }
  }


  addProductField(): void {
    const productFormGroup = this._formBuilder.group({
      product: ['', [Validators.required]],

      id: [''],

      size: ['', Validators.required],
      rate: [''],
      quantity: [''],
      stock: ['', Validators.required],
      color: ['', Validators.required],
      amount: [''],
    });

    const productArray = this.intial_stock_entry_form.get('products') as FormArray;
    productArray.push(productFormGroup);

    const index = productArray.length - 1;

  }


  removeProductField(index: number): void {
    const productArray = this.intial_stock_entry_form.get('products') as FormArray;
    const existingFormGroup = productArray.at(index) as FormGroup;

    const removedId = existingFormGroup.get('id').value;
    this.matchedVariationIds.delete(removedId);

    this.active.splice(index, 1);
    this.animationFlags.splice(index, 1);


    this.sizes.splice(index, 1);
    this.colors.splice(index, 1);

    productArray.removeAt(index);

    this.cd.markForCheck();
  }







  filterOptionsProduct(value: string): void {
    if (typeof value !== 'string') {
      this.filteredProductOptions$ = of([]);
      return;
    }

    this._productService.getproducts().pipe(
      take(1),
      switchMap((products) => {
        if (!Array.isArray(products['data'])) {
          return of([]);
        }

        const productObservables = products['data'].map((product) => {
          return this._productService.getProductVariations(product.id).pipe(
            map((variations) => ({
              product,
              variations: variations.data,
            }))
          );
        });

        return forkJoin(productObservables);
      }),
      map((productsWithVariations) => {
        const filteredProducts = productsWithVariations
          .map(({ product, variations }) => {
            const productId = product.id;

            const allDisabled = variations.every((variation) => {
              const isNotActive = !variation.active;
              const isSelected = this.matchedVariationIds.has(variation.id);
              return isNotActive || isSelected;
            });

            const disabled = allDisabled;

            return {
              product: productId,
              id: productId,
              productName: product.productName,
              sku: product.sku,
              basePrice: product.basePrice,
              size: product.size,
              color: product.color,
              description: product.description,
              disabled: disabled,
            };
          })
          .filter((filteredProduct) =>
            filteredProduct.productName.toLowerCase().includes(value.toLowerCase()) ||
            (filteredProduct.sku && filteredProduct.sku.toString().toLowerCase().includes(value.toLowerCase()))
          );

        return filteredProducts;
      })
    ).subscribe((filteredProducts) => {
      this.filteredProductOptions$ = of(filteredProducts);
    });
  }




  filtersOptionsProduct(productId: string, index: number): void {
    this._productService.getProductVariations(productId).subscribe((variations) => {
      this.mappedVariations = variations.data.map((variation) => ({
        id: variation.id,
        size: variation.size,
        color: variation.color,
        price: variation.basePrice,
        base_product: productId,
        stock: variation.stock,
        active: variation.active,
        description: variation.base_product.description,
        category: variation.base_product.category,
        gender: variation.base_product.gender,
      }));
      this.onSelectionChange(index);






    });

  }

  updateSizesAndColors(index: number, sizes: any[], colors: any[]): void {
    this.sizes[index] = sizes;
    this.colors[index] = colors;
  }




  onSelectionChange(index: number): void {
    const productArray = this.intial_stock_entry_form.get('products') as FormArray;
    const existingFormGroup = productArray.at(index) as FormGroup;

    if (existingFormGroup) {
      const selectedSizeId = existingFormGroup.get('size').value;
      const selectedColorId = existingFormGroup.get('color').value;

      const selectedSizeColorCombo = `${selectedSizeId}${selectedColorId}`;
      const matchingOriginalVariation = this.mappedVariations?.find(variation => {
        const variationSizeColorCombo = `${variation.size}${variation.color}`;
        return variationSizeColorCombo === selectedSizeColorCombo;
      });

      if (matchingOriginalVariation) {
        existingFormGroup.get('amount').patchValue(matchingOriginalVariation.price);
        existingFormGroup.get('stock').patchValue(matchingOriginalVariation.stock);
        this.current_stock[index] = matchingOriginalVariation.stock;

        existingFormGroup.get('size').patchValue(matchingOriginalVariation.size);
        existingFormGroup.get('color').patchValue(matchingOriginalVariation.color);
        existingFormGroup.get('id').patchValue(matchingOriginalVariation.id)
        this.matchedVariationIds.add(matchingOriginalVariation.id);


        this.active[index] = true;


        this.animationFlags[index] = true;


        setTimeout(() => {
          this.animationFlags[index] = false;
        }, 1000)


        console.log("Matching variation found for the selected size and color:", matchingOriginalVariation);
      } else {
        console.log("No matching original variation found for the selected size and color.");
      }
    }
  }


  selectOptionProduct(option: products, index: number): void {

    if (option && !option.isOutOfStock) {
      const productFormGroup = this._formBuilder.group({
        id: [''],
        product: [option, [Validators.required]],
        stock: [option.stock],
        size: [],
        color: [],
      });
      this.filtersOptionsProduct(option.id, index)


      const productArray = this.intial_stock_entry_form.get('products') as FormArray;

    }
  }



  displayProductOption(product: productVaraitons): string {
    if (product) {
      const productName = product.productName || '';
      const productPrice = product.sku
        ? ' - ' + product.sku.toString()
        : '';

      return productName + productPrice;
    }
    return '';
  }

  private onItemElementsChanged(): void {
    if (this.isNearBottom) {
      this.scrollToBottom();
    }
  }

  private scrollToBottom(): void {
    this.scrollContainer.scroll({
      top: this.scrollContainer.scrollHeight,
      left: 0,
      behavior: 'smooth',
    });
  }

  private isUserNearBottom(): boolean {
    const threshold = 150;
    const position =
      this.scrollContainer.scrollTop + this.scrollContainer.offsetHeight;
    const height = this.scrollContainer.scrollHeight;
    return position > height - threshold;
  }

  scrolled(event: any): void {
    this.isNearBottom = this.isUserNearBottom();
  }

  hasUnsavedChanges(): boolean {
    return this.intial_stock_entry_form.dirty;
  }

  canDeactivate(): boolean {
    return !this.intial_stock_entry_form.dirty || confirm('You have unsaved changes. Are you sure you want to leave?');
  }
}

