import { Directive, Input, ElementRef, Renderer2, OnInit } from '@angular/core';

@Directive({
  selector: '[appStockIndicator]'
})
export class StockIndicatorDirective implements OnInit {
  @Input('appStockIndicator') stockLevel: number;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    const indicatorHtml = this.getStockIndicatorHtml(this.stockLevel);
    this.renderer.setProperty(this.el.nativeElement, 'innerHTML', indicatorHtml);
  }

  private getStockIndicatorHtml(stockLevel: number): string {
    let indicatorHtml = '';
  
    if (stockLevel >= 5) {
      indicatorHtml = `
    
          <div class="flex items-end ml-2 w-1 h-4 bg-green-400 rounded overflow-hidden"><div class="flex w-full h-2/4 bg-green-200"></div>  </div>
        </div>
   
      `;
    } else if (stockLevel < 1) {
      indicatorHtml = `
    
          <div class="flex items-end ml-2 w-1 h-4 bg-red-200 rounded overflow-hidden"><div class="flex w-full h-2/4 bg-red-600"></div>  </div>
       
      `;
    } else if (stockLevel < 3) {
      indicatorHtml = `
    
          <div class="flex items-end ml-2 w-1 h-4 bg-orange-200 rounded overflow-hidden"><div class="flex w-full h-2/4 bg-orange-400"></div>  </div>
     
      `;
    }
  
    return indicatorHtml;
  }
  
}
