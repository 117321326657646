
import { AfterViewInit,   ChangeDetectionStrategy,   Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

import { MatTableDataSource } from '@angular/material/table';
import { SizeService } from '../size.service';
import { Size } from '../size.type';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import Swal from 'sweetalert2';

export interface SizeData {
  id: string;
  name: string;

}

const SIZE_CREDITS_DATA: SizeData[] = [

];

@Component({
  selector: 'app-list-size',
  templateUrl: './list-size.component.html',
  styleUrls: ['./list-size.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,


})
export class ListSizeComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = ['id','name', 'actions'];

  dataSource: MatTableDataSource<SizeData>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  sizes: Size[];
  isLoading :boolean = true;
  sizes$: Observable<Size[]>;

  constructor(
   
    private _size: SizeService,
  ) {
    
  }

  ngOnInit(): void {
  this.fetchSizes();
  }

  ngAfterViewInit() {

  }



  delteSize(id:any) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
  }).then((result) => {

      if (result.isConfirmed) {
          this._size.deleteSize(id)

              .subscribe((isDeleted) => {
                  this.sizes$ = this._size
                      .getSizes()
                      .pipe(take(1));
                  this.sizes$.subscribe((size) => {
                      this.sizes = size['data'];

                      this.dataSource = new MatTableDataSource<SizeData>(

                          this.sizes
                             
                              .map((size) => {
                                  // Map nested properties of products array
                                 
                                  return {
                                    id: size.id,
                                    name: size.name
                      
                      
                                  };
                              })
                      );
                  });

                  if (!isDeleted) {
                      Swal.fire("Error!", "Cannot Delete");
                      return;
                  }

                  // Display a success message
                  Swal.fire(
                      'Deleted!',
                      'Your file has been deleted.',
                      'success'
                  )
              });
      }
  });
  }



  showDetails() {

  }


  fetchSizes() {
    this.isLoading = true;
    this.sizes$ = this._size.getSizes().pipe(take(1));
  
    this.sizes$.subscribe((sizes) => {
      this.sizes = sizes['data'];
  
      this.dataSource = new MatTableDataSource<SizeData>(
        this.sizes.map((size) => {
          return {
            id: size.id,
            name: size.name,
          };
        })
      );
  
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
  
      this.isLoading = false;
    });
  }
  

  


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
