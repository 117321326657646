import { Route } from '@angular/router';


import { ListinventroyAdjustmentComponent } from './listinventroy-adjustment/listinventroy-adjustment.component';
import { CreateinventroyAdjustmentComponent } from './createinventroy-adjustment/createinventroy-adjustment.component';
import { DetailinventroyAdjustmentComponent } from './detailinventroy-adjustment/detailinventroy-adjustment.component';
import { UnsavedChangesGuard } from 'app/page-detect/wm-can-leave/detect-changes.guard';



export const InventoryAdjustmentRoutes: Route[] = [
   
        {
            path     : '',
            component: ListinventroyAdjustmentComponent,
        },
        {
            path         : 'NEW',
            component    : CreateinventroyAdjustmentComponent,
            canDeactivate:[UnsavedChangesGuard]
           
        },
        {
            path         : ':id',
            component    :DetailinventroyAdjustmentComponent,
          
          
        },
]
