import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { PrPackagesCreditNotesComponent } from './pr-packages-credit-notes/pr-packages-credit-notes.component';
import { ListprPackagesCreditNotesComponent } from './listpr-packages-credit-notes/listpr-packages-credit-notes.component';
import { DetailprPackagesCreditNotesComponent } from './detailpr-packages-credit-notes/detailpr-packages-credit-notes.component';
import { CreateprPackagesCreditNotesComponent } from './createpr-packages-credit-notes/createpr-packages-credit-notes.component';
import { MaterialModule } from 'app/modules/material/material.module';
import { RouterModule } from '@angular/router';
import { PrPackagesCreditNotesRoutes } from './pr-packages-credit-notes.routing,';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { PrPackagesCreditNotesPopupComponent } from './pr-packages-credit-notes-popup/pr-packages-credit-notes-popup.component';
import { PrPackagesProductCreditNotesPopupComponent } from './pr-packages-product-credit-notes-popup/pr-packages-product-credit-notes-popup.component';



@NgModule({
  declarations: [
    PrPackagesCreditNotesComponent,
    ListprPackagesCreditNotesComponent,
    DetailprPackagesCreditNotesComponent,
    CreateprPackagesCreditNotesComponent,
    PrPackagesCreditNotesPopupComponent,
    PrPackagesProductCreditNotesPopupComponent
  ],
  imports: [
    RouterModule.forChild(PrPackagesCreditNotesRoutes),
    MaterialModule,
    CommonModule,
    MatDialogModule
  ],providers   : [
    {
        provide : MAT_DATE_FORMATS,
        useValue: {
            parse  : {
                dateInput: "YYYY-MM-DD"
            },
            display: {
                dateInput         : 'YYYY-MM-DD',
                monthYearLabel    : 'MMM YYYY',
                dateA11yLabel     : 'LL',
                monthYearA11yLabel: 'MMMM YYYY'
                
            }
        }
    },
    DatePipe
]
})
export class PrPackagesCreditNotesModule { }
