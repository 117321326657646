import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { productVaraitonService } from 'app/productVariations/productVarations.type';

@Injectable({
    providedIn: 'root'
})
export class InventoryResolver implements Resolve<any>
{
    /**
     * Constructor
     */
    constructor(private _productVariations: productVaraitonService

    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        const id = route.paramMap.get('id');
        console.log(id,"here at resolve")
        return forkJoin({
          chartData: this._productVariations.getItemsReport(id),
          variationData: this._productVariations.getproductVaraitonById(id),
          stockHistoryData:this._productVariations.getStockReport(id),


        }).pipe(
          map(data => ({
            chartData: data.chartData,
            variationData:data.variationData,
            stockHistoryData:data.stockHistoryData
          }))
        );
      }
    
}

