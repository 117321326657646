
import { Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { ActivatedRoute, Router } from '@angular/router';
import { Vendor } from 'app/modules/Vendor/vendors.type';
import { VendorService } from 'app/modules/Vendor/vendorsservice';
import { VendorpopupComponent } from 'app/vendors-credit/vendorpopup/vendorpopup.component';


import { Observable, of } from 'rxjs';
import { startWith, debounceTime, switchMap, map, take } from 'rxjs/operators';


import Swal from 'sweetalert2';
import { InventoryEntryService } from '../inventory-entry.service';


export interface VendorOption {
  id: string;
  firstname?: string;
  avatar?: string;
}

@Component({


  selector: 'app-createinventory-entry',
  templateUrl: './createinventory-entry.component.html',
  styleUrls: ['./createinventory-entry.component.scss']
})
export class


  CreateinventoryEntryComponent implements OnInit {
  vendorsCount: number;
  selectedVendor: Vendor;
  vendors: Vendor[];
  showInventory = false;
  vendors$: Observable<Vendor[]>;
  vendorControl: FormControl = new FormControl();
  filteredVendor$: Observable<Vendor[]>;

  selectedItems: string[] = [];
  availableItems: string[] = ['Option 1', 'Option 2', 'Option 3', 'Option 4'];
  drawerMode: 'over' | 'side' = 'side';
  drawerOpened: boolean = true;
  vendorsCredit: FormGroup;
  successMessage: string;
  errorMessage: string;

  genderOptions: { label: string, value: string }[] = [];

  cardValue: any;

  constructor(

    private _formBuilder: FormBuilder,
    private elementRef: ElementRef,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _vendorsService: VendorService,
    private dialog: MatDialog,
    private _inventoryEntryService: InventoryEntryService

  ) {

    this.vendors$ = this._vendorsService.getVendors().pipe(take(1));

    this.vendors$.subscribe((vendor) => {
      vendor['data'];
    });
  }

  ngOnInit() {
    // Get the vendors

    this.filteredVendor$ = this.vendorControl.valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      switchMap((value) => this.filterOptions(value))
    );

    this.vendorsCredit = this._formBuilder.group({
        type: [''], // Goods/Service Checkbox
        name: [''],
        sku: [''],
        unit: [''],
        unit_type: ['cm'],
        dimensions: [''],
        dimensions_type: ['cm'],
        manufacturer: [''],
        upc: [''],
        ean: [''],
        weight: [''],
        weight_type: ['kg'],
        brand: [''],
        mpn: [''],
        isbn: [''],
        sellingPrice: [''],

        accountSales: [''],
        descriptionSales: [''],
        taxSales: [''],
        costPrice: [''],
        accountPurchase: [''],
        descriptionPurchase: [''],
        taxPurchase: [''],
        vendor: this.vendorControl,
        description: [''],
        merchandiseInventory: [''],
        accountsPayable: [''],
        wagesExpense: [''],
        costofgoodsSold: [''],
        inventory_type: [false],
        inventory_account: [''],
        opening_stock: [''],
        stock_r_p_r: [''],
        rendor_point: [''],
        salesInformation: [false],
        purchaseInformation: [false],
    });



  }

  selectChange = (event: any) => {
    const key: string = event.key;
    this.cardValue[key] = [...event.data];
  };

  openVendorDetails(): void {
    const dialogRef = this.dialog.open(VendorpopupComponent, {
      width: '600px',
      height: '95vh',
      panelClass: 'custom-dialog-container', // Add a custom class for styling
      data: this.selectedVendor, // Pass the selected vendor as data to the dialog
    });

    dialogRef.componentInstance.formSubmitted.subscribe((formData) => {
      // Call your service method here
      // console.log(formData, '...//');

      this._vendorsService
        .createVendors(formData)

        .then((response) => {
          const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener(
                'mouseenter',
                Swal.stopTimer
              );
              toast.addEventListener(
                'mouseleave',
                Swal.resumeTimer
              );
            },
          });

          Toast.fire({
            icon: 'success',
            title: ` ${formData.first_name} Vendor  created!! `,
          });
          // Handle the response from the service if needed
        })
        .catch((error) => {
          const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener(
                'mouseenter',
                Swal.stopTimer
              );
              toast.addEventListener(
                'mouseleave',
                Swal.resumeTimer
              );
            },
          });

          Toast.fire({
            icon: 'error',
            title: `'Error creating Vendor: ' ${error.message};`,
          });
        });
    });
  }


  private scrollToMessages(): void {
    const messagesElement = this.elementRef.nativeElement.querySelector('#messages');
    if (messagesElement) {
      messagesElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }



  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }





  sumbitVendorsInfo() {
    if (this.vendorsCredit.valid) {
      const vendorData = { ...this.vendorsCredit.value };
      const vendor = this.vendorsCredit.get('vendor')?.value;
      const name = this.vendorsCredit.get('name')?.value;
      vendorData.vendor = vendor ? vendor.id : null;


      this._inventoryEntryService
        .createInventoryEntrys(vendorData)
        .then((response) => {
          if (response.success) {
            const Toast = Swal.mixin({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener(
                  'mouseenter',
                  Swal.stopTimer
                );
                toast.addEventListener(
                  'mouseleave',
                  Swal.resumeTimer
                );
              },
            });

            Toast.fire({
              icon: 'success',
              title: `${name} Created`,
            });

            this.vendorsCredit.reset();
            this._router.navigate(['../'], {
              relativeTo: this._activatedRoute,
            });
          } else {
            const errorMessages = response.errors;
            // Handle API errors here
            // Display error messages or perform necessary actions
            // For example, you can show a toast message with the error details
            const Toast = Swal.mixin({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener(
                  'mouseenter',
                  Swal.stopTimer
                );
                toast.addEventListener(
                  'mouseleave',
                  Swal.resumeTimer
                );
              },
            });

            Toast.fire({
              icon: 'error',
              title: 'Error creating Vendors Credit',
              text: JSON.stringify(errorMessages),
            });
          }
        })
        .catch((error) => {
          // Hide the loading indicator
          const Toast = Swal.mixin({
            toast: true,
            width: '20rem',
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener(
                'mouseenter',
                Swal.stopTimer
              );
              toast.addEventListener(
                'mouseleave',
                Swal.resumeTimer
              );
            },
          });

          Toast.fire({
            icon: 'error',
            title: `Error creating InventoryEntry: ${error.message}`,
          });
        });
    } else {
      // Form is not valid, handle accordingly
      // For example, you can show a toast message or perform other actions
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer);
          toast.addEventListener('mouseleave', Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: 'error',
        title: 'Invalid form data',
        text: 'Please fill in all the required fields correctly.',
      });
    }
  }

  filterOptions(value: string): Observable<Vendor[]> {
    if (typeof value !== 'string') {
      return of([]); // Return an empty array if value is not a string
    }

    const filterValue = value.toLowerCase();
    return this.vendors$.pipe(
      map((vendor) => {
        if (Array.isArray(vendor['data'])) {
          return vendor['data'].filter(
            (vendor) =>
              vendor.firstName &&
              vendor.firstName.toLowerCase().includes(filterValue)
          );
        } else {
          console.error('Data is not there', vendor);
          return [];
        }
      })
    );
  }

  displayOption(customer: Vendor): string {
    const last_name = customer?.lastName || '';
    const first_name = customer?.firstName || '';

    return first_name && last_name ? first_name + ' ' + last_name : '';
  }

  selectOption(option: Vendor) {
    if (option) {
      this.vendorControl.setValue(option);
      this.vendorControl.updateValueAndValidity();
    }
  }
}



