
import { Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { ActivatedRoute, Router } from '@angular/router';


import { Subject } from 'rxjs';
import Swal from 'sweetalert2';


@Component({


  selector: 'app-createinventroy-sheet',
  templateUrl: './createinventroy-sheet.component.html'
})
export class


  CreateinventroySheetComponent implements OnInit {

  selectedItems: string[] = [];
  availableItems: string[] = ['Option 1', 'Option 2', 'Option 3', 'Option 4'];
  drawerMode: 'over' | 'side' = 'side';
  drawerOpened: boolean = true;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  intial_stock_form: FormGroup;
  successMessage: string;
  errorMessage: string;

  genderOptions: { label: string, value: string }[] = [];
  vendorControl: FormControl = new FormControl();
  cardValue: any;

  constructor(

    private _formBuilder: FormBuilder,
    private elementRef: ElementRef,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,

  ) {

  }

  ngOnInit() {
    // Get the vendors



    this.intial_stock_form = this._formBuilder.group({
      productName: [''],
      productNumber: [''],
      costPerItem: [''],
      stockQuantity: [''],
      reorderQuantity: [''],
      description: [''],






    });


  }

  selectChange = (event: any) => {
    const key: string = event.key;
    this.cardValue[key] = [...event.data];
  };

  openVendorDetails() {
  }



  private scrollToMessages(): void {
    const messagesElement = this.elementRef.nativeElement.querySelector('#messages');
    if (messagesElement) {
      messagesElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }



  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }





  sumbitsizeInfo() {
    if (this.intial_stock_form.valid) {
      // Show the loading indicator


      const customerData = { ...this.intial_stock_form.value };




      const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })

      Toast.fire({
        icon: 'success',
        title: ` ${customerData.fit} Size created!! `
      })
      this.intial_stock_form.reset();

      this._router.navigate(['../'], { relativeTo: this._activatedRoute });


      error => {
        // Hide the loading indicator


        if (error && error.message) {

          const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
          })

          Toast.fire({
            icon: 'error',
            title: `'Error creating Size ' ${error.message};`
          })
        } else {

          const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
          })

          Toast.fire({
            icon: 'error',
            title: 'Error creating Vendors Credit'
          })
        }


      }

    }
  }
}