import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-createpr-packages-return',
  templateUrl: './createpr-packages-return.component.html',
  styleUrls: ['./createpr-packages-return.component.scss']
})
export class CreateprPackagesReturnComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
