import { Injectable } from '@angular/core';
import { assign, cloneDeep } from 'lodash-es';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import { HttpClient } from '@angular/common/http';
import { server_env } from 'config';

@Injectable({
    providedIn: 'root'
})
export class UserMockApi {
    private _user: any = {};
    private _initialized = false;

    constructor(
        private _fuseMockApiService: FuseMockApiService,
        private _httpClient: HttpClient
    ) {
        this.initialize();
        this.registerHandlers();
    }

    private initialize(): void {
        this._httpClient.get(`${server_env.URL}api/current_user/`).subscribe(user => {
            this._user = user;
            this._initialized = true;
        });
    }

    getUser(): any {
        return this._user;
    }

    registerHandlers(): void {
        this._fuseMockApiService.onGet('api/common/user').reply(() => {
            if (!this._initialized) {
                return [500, { message: 'Mock API not initialized yet' }];
            }
            return [200, cloneDeep(this._user)];
        });

        this._fuseMockApiService.onPatch('api/common/user').reply(({ request }) => {
            if (!this._initialized) {
                return [500, { message: 'Mock API not initialized yet' }];
            }

            const user = cloneDeep(request.body.user);
            this._user = assign({}, this._user, user);
            return [200, cloneDeep(this._user)];
        });
    }
}
