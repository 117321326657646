<div class="sm:absolute sm:inset-0 flex flex-col flex-auto min-w-0 sm:overflow-hidden bg-card dark:bg-transparent">
    <!-- Header -->
    <div
        class="relative flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between py-8 px-6 md:px-8 border-b">
        <!-- Loader -->
        <div class="absolute inset-x-0 bottom-0" *ngIf="isLoading">
            <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
        </div>
        <!-- Title -->
        <div class="text-4xl font-bold tracking-tight">
            Expense Master <mat-icon svgIcon="mat_outline:checkroom"></mat-icon>
        </div>
        <!-- Actions -->
        <div class="flex flex-shrink-0 items-center mt-6 sm:mt-0 sm:ml-4">
            <!-- Search -->

            <button class="ml-4 mr-4" mat-flat-button [color]="'primary'" (click)="createProduct()">
                <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
                <span class="ml-2 mr-1">Add</span>
            </button>


        </div>

    </div>

    <!-- Main -->
    <div class="flex flex-auto overflow-hidden">

        <div class="flex flex-col flex-auto sm:mb-18 overflow-hidden sm:overflow-y-auto">
            <div class="flex flex-shrink-0 items-center mt-6 sm:mt-0 sm:ml-4">

                <div class="flex flex-shrink-0 items-center ml-3 mt-3">
                    <mat-form-field
                        class="fuse-mat-dense fuse-mat-rounded fuse-mat-emphasized-affix fuse-mat-no-subscript min-w-100">
                        <mat-icon matPrefix [svgIcon]="'heroicons_outline:search'"></mat-icon>
                        <input matInput [autocomplete]="'off'" (keyup)="applyFilter($event)"
                            [placeholder]="'Search Expense Master'" />
                    </mat-form-field>

                    <!-- Add product button -->
                    <mat-form-field style="min-width: 300px;"
                        class="flex-auto ml-3 mt-4 fuse-mat-rounded fuse-mat-emphasized-affix">
                        <mat-select id="selectedTable" [(ngModel)]="selectedTable" (ngModelChange)="loadTables()"
                            placeholder="Select Table" disableOptionCentering panelClass="dropdown-panel"
                            style="font-size: smaller; font-family:Montserrat">

                            <mat-option value="EXPENSE_HEAD"
                                style="font-size: smaller;font-family:Montserrat">EXPENSE HEAD</mat-option>
                            <mat-option value="EXPENSE_CATEGORY" style="font-size: smaller;font-family:Montserrat">EXPENSE CATEGORY
                            </mat-option>

                        </mat-select>
                        <mat-icon class="icon-size-4" matPrefix [svgIcon]="'feather:more-horizontal'"></mat-icon>
                    </mat-form-field>


                </div>


            </div>


            <div class="mat-elevation-z8">
                <table id="" mat-table [dataSource]="currentDataSource" matSort matPaginator
                   >
                    <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ column | titlecase}}</th>
                        <td mat-cell style="font-size: smaller;" *matCellDef="let row"
                            [ngStyle]="column === 'id' ? {'font-weight': 'bold' } : null ">

                            {{ column === 'actions' ? '' : row[column] }}
                            <button *ngIf="column === 'actions'" mat-icon-button matTooltip="Void"
                                (click)="deleteEntry(row)">
                                <mat-icon class="icon-size-4" svgIcon="heroicons_outline:trash"></mat-icon>
                            </button>
                            
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>




                <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>
            </div>
        </div>
    </div>
</div>