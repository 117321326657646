<div *ngIf="vendor" class="mt-10 ml-10 md:ml-50 lg:ml-90">



    <div class="absolute inset-0 min-w-0 text-center overflow-auto print:overflow-visible" cdkScrollable>
        <div class="container mx-auto p-6 sm:py-8 sm:px-10 border-b bg-card dark:bg-transparent hide-on-print">
            <!-- Header and Action Buttons -->
            <div class="flex flex-col sm:flex-row items-center justify-between">
                <div class="flex-1 min-w-0 flex items-center">
                    <button mat-button type="button" matTooltip="Close" class="hide-on-print" (click)="goBack()">


                        <mat-icon svgIcon="feather:x"></mat-icon>
                    </button>
                    <button mat-button type="button" class="hide-on-print" (click)="printPage()">
                        <mat-icon svgIcon="feather:file"></mat-icon>
                    </button>
                    <button mat-button type="button" class="hide-on-print" (click)="screenshot()">
                        <mat-icon svgIcon="camera"></mat-icon>
                    </button>
                </div>
                <div class="text-lg text-primary-500 font-medium whitespace-nowrap">{{ vendor?.status }}</div>
            </div>

            <!-- Vendor Information -->
            <div class="mt-4">
                <h2 class="text-3xl md:text-4xl font-extrabold tracking-tight leading-7 sm:leading-10 truncate">
                    EX{{ vendor?.id }}-A
                </h2>
                <h2 class="text-3xl md:text-4xl font-extrabold tracking-tight leading-7 sm:leading-10 truncate">
                    NPR {{ vendor?.total }}
                </h2>
                <div class="text-secondary leading-tight mt-1" *ngIf="vendor?.created_by?.username">
                    Created By: {{ vendor.created_by.username }}
                </div>
            </div>

            <!-- Collapsible Additional Information -->
            <div class="mt-6" *ngIf="showAdditionalInfo">
                <!-- Tables Container -->
                <div class="flex flex-wrap">
                    <!-- Vendor Information Table -->
                    <div class="w-full md:w-1/2 p-2">
                        <div class="overflow-hidden border border-gray-300 rounded-lg">
                            <table class="min-w-full">
                                <tbody>
                                    <tr class="border-b border-gray-300">
                                        <td class="px-4 py-2 text-left"><strong>Point of Sales</strong></td>
                                        <td class="px-4 py-2 text-left">
                                            <ng-container *ngIf="vendor.pos; else notAvailable">{{ vendor.pos
                                                }}</ng-container>
                                            <ng-template #notAvailable>--</ng-template>
                                        </td>
                                    </tr>
                                    <tr class="border-b border-gray-300">
                                        <td class="px-4 py-2 text-left"><strong>Social Media Link</strong></td>
                                        <td class="px-4 py-2 text-left">
                                            <ng-container *ngIf="vendor.social_link; else notAvailable">
                                                <a class="text-link" target="_blank"
                                                    href="https://www.instagram.com/{{ vendor.social_link }}">{{
                                                    vendor.social_link }}</a>
                                            </ng-container>
                                            <ng-template #notAvailable>--</ng-template>
                                        </td>
                                    </tr>

                                    <tr class="border-b border-gray-300">
                                        <td class="px-4 py-2 text-left"><strong>Notes</strong></td>
                                        <td class="px-4 py-2 text-left">
                                            {{vendor.notes}}
                                        </td>
                                    </tr>


                                    <tr class="border-b border-gray-300">
                                        <td class="px-4 py-2 text-left"><strong>Shipping Area</strong></td>
                                        <td class="px-4 py-2 text-left">
                                            <ng-container *ngIf="vendor.shipping_area">{{ vendor.shipping_area }}, {{
                                                vendor.district
                                                }},<br>{{ vendor.address }}</ng-container>
                                            <ng-container *ngIf="!vendor.shipping_area">--</ng-container>
                                        </td>
                                    </tr>


                                    <tr class="border-b border-gray-300">
                                        <td class="px-4 py-2 text-left"><strong>Delivery Number</strong></td>
                                        <td class="px-4 py-2 text-left">
                                            <ng-container *ngIf="vendor.delivery_number">{{ vendor.delivery_number }},
                                            </ng-container>
                                            <ng-container *ngIf="!vendor.delivery_number">--</ng-container>
                                        </td>
                                    </tr>
                                    <tr class="border-b border-gray-300">
                                        <td class="px-4 py-2 text-left"><strong>Payment Type</strong></td>
                                        <td class="px-4 py-2 text-left">
                                            <ng-container *ngIf="vendor.payment_type">{{ vendor.payment_type
                                                }}</ng-container>
                                            <ng-container *ngIf="!vendor.payment_type">--</ng-container>
                                        </td>
                                    </tr>
                                    <tr class="border-b border-gray-300">
                                        <td class="px-4 py-2 text-left"><strong>Delivery Type</strong></td>
                                        <td class="px-4 py-2 text-left">
                                            <ng-container *ngIf="vendor.delivery_type">{{ vendor.delivery_type
                                                }}</ng-container>
                                            <ng-container *ngIf="!vendor.delivery_type">--</ng-container>
                                        </td>
                                    </tr>
                                    <tr class="border-b border-gray-300">
                                        <td class="px-4 py-2 text-left"><strong>Shipping Carrier</strong></td>
                                        <td class="px-4 py-2 text-left">
                                            <ng-container *ngIf="vendor.shipping_carrier_invalley">{{
                                                vendor.shipping_carrier_invalley
                                                }}</ng-container>
                                            <ng-container *ngIf="vendor.shipping_carrier_outvalley">{{
                                                vendor.shipping_carrier_outvalley
                                                }}</ng-container>
                                            <ng-container *ngIf="vendor.shipping_carrier_int">{{
                                                vendor.shipping_carrier_int }}</ng-container>
                                            <ng-container *ngIf="vendor.shipping_carrier_invalley_others">{{
                                                vendor.shipping_carrier_invalley_others }}</ng-container>
                                            <ng-container
                                                *ngIf="!vendor.shipping_carrier_invalley && !vendor.shipping_carrier_outvalley && !vendor.shipping_carrier_int && !vendor.shipping_carrier_invalley_others">--</ng-container>
                                        </td>
                                    </tr>
                                    <tr class="border-b border-gray-300">
                                        <td class="px-4 py-2 text-left"><strong>Sales Person</strong></td>
                                        <td class="px-4 py-2 text-left">
                                            <ng-container *ngIf="vendor.created_by.username">{{
                                                vendor.created_by.username
                                                }}</ng-container>
                                            <ng-container *ngIf="!vendor.created_by.username">--</ng-container>
                                        </td>
                                    </tr>


                                </tbody>
                            </table>
                        </div>
                    </div>

                    <!-- Exchanges and Returns Table -->
                    <div class="w-full md:w-1/2 p-2">
                        <div class="overflow-hidden border border-gray-300 rounded-lg">
                            <table class="min-w-full">
                                <tbody>




                                    <tr class="border-b border-gray-300">
                                        <td class="px-4 py-2 text-left"><strong>Customer</strong></td>
                                        <td class="px-4 py-2 text-left">
                                            <ng-container *ngIf="vendor?.customer.id">


                                                <a [routerLink]="['/apps/contacts', vendor?.customer.id]"
                                                    class="text-bold text-md">{{vendor?.customer.firstName}}
                                                    {{vendor?.customer.lastName}} <strong>
                                                        (HUB{{vendor?.customer.id}})
                                                    </strong></a>


                                            </ng-container>

                                            <ng-container *ngIf="!vendor?.customer.id">--</ng-container>
                                        </td>
                                    </tr>
                                    <tr class="border-b border-gray-300">
                                        <td class="px-4 py-2 text-left"><strong>Status</strong></td>
                                        <td class="px-4 py-2 text-left">
                                            <ng-container *ngIf="vendor.status">


                                                {{ vendor.status
                                                }}


                                            </ng-container>
                                            <ng-container *ngIf="!vendor.status">--</ng-container>
                                        </td>
                                    </tr>
                                    <tr class="border-b border-gray-300">
                                        <td class="px-4 py-2 text-left"><strong>Orignal Bill </strong></td>
                                        <td class="px-4 py-2 text-left">
                                            <ng-container *ngIf="vendor?.sales_order?.sales_order_num">



                                                <a [routerLink]="['/apps/sales-order', vendor?.sales_order.id]"
                                                    class="text-bold text-md mr-2">{{vendor?.sales_order?.sales_order_num}}</a>

                                                <span class="chip chip--major-warning m;">
                                                    <i style="font-size: 11px; margin-top: -2px; "
                                                        matTooltip="Exchanges" matTooltipPosition="above"
                                                        class="chip__label">
                                                        <strong>Exchanged</strong>
                                                    </i>
                                                </span>
                                            </ng-container>
                                            <ng-container
                                                *ngIf="!vendor?.sales_order?.sales_order_num">--</ng-container>
                                        </td>
                                    </tr>

                                    <tr class="border-b border-gray-300">
                                        <td class="px-4 py-2 text-left"><strong>Credit Note </strong></td>
                                        <td class="px-4 py-2 text-left">
                                            <ng-container *ngIf="salesCredit?.voucher_order_num">

                                                <a [routerLink]="['/apps/credit-notes', salesCredit?.id]"
                                                    class="text-bold text-md">{{salesCredit?.voucher_order_num}}</a>
                                            </ng-container>
                                            <ng-container *ngIf="!salesCredit?.voucher_order_num">--</ng-container>
                                        </td>
                                    </tr>

                                    <tr class="border-b border-gray-300">
                                        <td class="px-4 py-2 text-left"><strong>Remaining Credit </strong></td>
                                        <td class="px-4 py-2 text-left">
                                            <ng-container *ngIf="salesCredit?.voucher_order_num">

                                                <a [routerLink]="['/apps/credit-notes', salesCredit?.id]"
                                                    class="text-secondary text-md">{{salesCredit?.total}}</a>
                                            </ng-container>
                                            <ng-container *ngIf="!salesCredit?.total">--</ng-container>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>



                <!-- Payment Information Table -->




            </div>

            <!-- Toggle Button for Additional Information -->
            <button mat-icon-button class="mt-6" (click)="toggleAdditionalInfo()">
                <mat-icon>{{ showAdditionalInfo ? 'expand_less' : 'expand_more' }}</mat-icon>
            </button>
        </div>


        <!-- Main -->
        <div #screenshotElement class="w-full inline-block p-6 sm:p-10 text-left print:p-0">


            <!-- Invoice -->
            <div
                class="w-full  p-16 rounded-2xl shadow bg-card print:w-auto print:p-0 print:rounded-none print:shadow-none print:bg-transparent">


                <div class="flex items-start">

                    <div class="grid grid-rows-1 place-items-start ">
                        <div class="grid auto-cols-max grid-flow-col gap-x-5">

                            <div class="pl-4text-md">
                                <img class="flex-0 w-20 mt-2" src="assets/images/logo/logo.png">



                                <div class="font-medium">
                                    customercare@hubanepal.com
                                </div>





                                <div class="tracking-tight mr-3">
                                    <div> Saraswatinagar
                                        Kathmandu,<br>
                                        Nepal</div>

                                </div>


                            </div>
                        </div>

                    </div>






                    <div class="grid grid-cols-2 gap-x-4 gap-y-1 ml-auto mt-16">

                        <div class="justify-self-end text-4xl tracking-tight text-secondary">Exchange Order</div>
                        <div class="text-4xl">{{vendor?.exchange_number}}

                        </div>
                        <div class="justify-self-end font-medium tracking-tight text-secondary">Created Date</div>
                        <div class="font-medium">{{vendor?.sales_order?.slaes_order_date}}</div>
                        <div class="justify-self-end font-medium tracking-tight text-secondary">Orginal Bill Number
                        </div>
                        <div class="font-medium"> {{vendor?.sales_order?.sales_order_num}}</div>
                        <div class="justify-self-end font-medium tracking-tight text-secondary">Exchange Created Date
                        </div>
                        <div class="font-medium">{{vendor?.date}}</div>

                        <div class="justify-self-end font-medium tracking-tight text-secondary">Reason For Return
                        </div>
                        <div class="font-medium">{{vendor?.reasonForReturn}}</div>
                        <div class="justify-self-end font-medium tracking-tight text-secondary">TOTAL </div>
                        <div class="font-medium">रू {{vendor?.remainingAmount}}</div>

                    </div>

                </div>

                To - {{ vendor.sales_order.Customers.firstName }} {{ vendor.sales_order.Customers.lastName
                }}

                <div class="flex mt-5">
                    <table class="w-full border-collapse border border-silver">
                        <!-- Table Header -->
                        <thead>
                            <tr>
                                <th class="border border-silver p-2 text-left font-medium text-lg">PRODUCT</th>
                                <th class="border border-silver p-2 text-center font-medium text-lg">SIZE</th>
                                <th class="border border-silver p-2 text-center font-medium text-lg">COLOR</th>
                                <th class="border border-silver p-2 text-center font-medium text-lg">RATE</th>
                                <th class="border border-silver p-2 text-center font-medium text-lg">QTY</th>
                                <th class="border border-silver p-2 text-center font-medium text-lg">AMOUNT</th>
                            </tr>
                        </thead>

                        <!-- Table Body -->
                        <tbody>
                            <!-- Original Products -->
                            <ng-container *ngFor="let product of vendor?.saleProduct">
                                <tr class="border-b border-silver original-product">
                                    <td class="border-r border-silver p-2">
                                        <div class="text-md font-medium">{{ product.sale_product}}
                                        </div>
                                    </td>
                                    <td class="border-r border-silver p-2 text-center text-md">{{ product.sale_size
                                        }}</td>
                                    <td class="border-r border-silver p-2 text-center text-md">{{ product.sale_color
                                        }}</td>
                                    <td class="p-2 text-center border-r border-silver text-md">रू {{ product.sale_rate
                                        }}
                                    </td>
                                    <td class="border-r border-silver p-2 text-center text-md"><strong>{{
                                            product.sale_quantity }} pcs</strong></td>
                                    <td class="p-2 text-center text-md"><strong>रू {{ product.sale_amount }}</strong>
                                    </td>
                                </tr>
                            </ng-container>

                            <!-- Exchanged Products -->
                            <ng-container *ngFor="let product of vendor?.exchange_products">
                                <tr class="border-b border-silver exchanged-product">
                                    <td class="border-r border-silver p-2">
                                        <div class="text-md font-medium">{{ product.product.base_product.productName }}
                                            (EXCHANGED)</div>
                                        <div class="mt-2 text-md text-secondary">{{ product.product.description }}</div>
                                    </td>
                                    <td class="border-r border-silver p-2 text-center text-md">{{ product.product.size
                                        }}</td>
                                    <td class="border-r border-silver p-2 text-center text-md">{{ product.product.color
                                        }}</td>
                                    <td class="p-2 text-center border-r border-silver text-md">रू {{ product.rate }}
                                    </td>
                                    <td class="border-r border-silver p-2 text-center text-md">{{ product.quantity }}
                                        pcs</td>
                                    <td class="p-2 text-center text-md">रू {{ product.amount }}</td>
                                </tr>
                            </ng-container>
                        </tbody>

                        <!-- Totals Section -->
                        <tfoot>
                            <!-- Subtotal -->
                            <tr>
                                <td colspan="5" class="border-silver p-2 text-right font-medium text-md">
                                    SUBTOTAL
                                </td>
                                <td class="border border-silver p-2 text-right text-md">
                                    <strong>रू {{ vendor.sub_total }}</strong>
                                </td>
                            </tr>

                            <!-- Adjustment -->
                            <tr>
                                <td colspan="5" class="border-silver p-2 text-right font-medium text-md">
                                    ADJUSTMENT
                                </td>
                                <td class="border border-silver p-2 text-right text-md">
                                    <strong>{{ vendor.adjustmentSuffix | uppercase }} {{ vendor.adjustment }}</strong>
                                </td>
                            </tr>

                            <!-- Discount -->
                            <tr>
                                <td colspan="5" class="border-silver p-2 text-right font-medium text-md">
                                    DISCOUNT
                                </td>
                                <td class="border border-silver p-2 text-right text-md">
                                    <strong>{{ vendor.discountSuffix }} {{ vendor.discount }}</strong>
                                </td>
                            </tr>

                            <!-- Original Total -->
                            <tr>
                                <td colspan="5" class="border-silver p-2 text-right font-medium text-md">
                                    OLD TOTAL
                                </td>
                                <td class="border border-silver p-2 font-medium text-right text-md">
                                    <strong>रू {{ vendor.totalAmount }}</strong>
                                </td>
                            </tr>

                            <!-- Total (Original) -->
                            <tr>
                                <td colspan="5" class="border-silver p-2 text-right font-medium text-md">
                                    TOTAL (ORIGINAL)
                                </td>
                                <td class="border border-silver p-2 font-medium text-right text-md">
                                    <strong>रू {{ vendor.total }}</strong>
                                </td>
                            </tr>

                            <!-- New Total After Exchange -->
                            <tr>
                                <td colspan="5" class="border-silver p-2 text-right font-medium text-md">
                                    NEW TOTAL AFTER EXCHANGE
                                </td>
                                <td class="border border-silver p-2 font-medium text-right text-md">
                                    <strong>रू {{ vendor.remainingAmount }}</strong>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>




            </div>

        </div>

    </div>
</div>