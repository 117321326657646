<!-- salesOrderForm form -->

<div class="w-full h-full">
    <form [formGroup]="salesOrderForm" class="rounded shadow-lg h-full" (keydown.enter)="$event.preventDefault()">
        <!-- Avatar -->
        <!-- Avatar code goes here -->

        <!-- Personal Information Form -->
        <div *ngIf="salesOrderForm" class="p-8">
            <div class="flex justify-end">
                <button mat-raised-button [routerLink]="['..']">
                    <span class="inline-flex items-center">
                        <mat-icon svgIcon="feather:x"></mat-icon>
                    </span>
                </button>
            </div>

            <div class="flex flex-col mt-8">
                <p class="text-lg font-medium" style="font-family: Montserrat">
                    Basic information
                </p>
                <p class="text-secondary" style="font-family: Montserrat">
                    Set your Credit Notes Detial Here
                </p>
                <mat-divider class="mt-8 mb-10"></mat-divider>





                <div class="flex mb-4">
                    <mat-form-field style="max-width: 600px"
                        class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix">
                        <mat-label>Customer</mat-label>
                        <input style="max-height: 10px;" class="small-input-text" #customerInput required matInput
                            type="text" placeholder="Search Customers" [formControl]="CustomerControl"
                            aria-label="Customers" [matAutocomplete]="auto"
                            [class.selected-option]="isCustomerSelected(selectedCustomer)" [readonly]="isFieldReadOnly"
                            [invalid]="(filteredCustomers$ | async)?.length === 0 && formSubmitted" />

                        <button *ngIf="isFieldReadOnly" mat-icon-button (click)="makeFieldEditable()">
                            <mat-icon class="icon-size-4" svgIcon="feather:edit"></mat-icon>
                        </button>

                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayOption"
                            (optionSelected)="selectOption($event.option.value)">
                            <mat-option style="height: fit-content" *ngFor="let customer of filteredCustomers$ | async"
                                [value]="customer">
                                <div style="display: flex; align-items: center">
                                    <span class="example-option-name">{{ customer.firstName }} {{
                                        customer.lastName}}</span>
                                </div>
                            </mat-option>
                            <mat-option style="height: fit-content;"
                                *ngIf="(filteredCustomers$ | async)?.length === 0 && formSubmitted">
                                <strong style="color: red;">NO CUSTOMER FOUND</strong> <br /><span>You can add customer,
                                    By clicking on below button</span>
                            </mat-option>
                            <mat-option *ngIf="formSubmitted" (click)="openCustomerPopup()">
                                Add Customer<mat-icon svgIcon="feather:plus"></mat-icon>
                            </mat-option>

                        </mat-autocomplete>
                        <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:user'"></mat-icon>
                    </mat-form-field>
                </div>




                <div class="flex mb-2" >
                    <mat-form-field style="max-width: 600px"
                        class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix">
                        <mat-label>Voucher Number#</mat-label>

                        <input readonly matInput placeholder="Voucher Number" [required]="'filterviaVoucher'" name="Voucher Number"
                            [formControlName]="'voucher_order_num'" class="elevated-input small-input-text"
                            [value]="" />
                        <mat-icon class="icon-size-4" matPrefix [svgIcon]="'feather:hash'"></mat-icon>
                    </mat-form-field>
                </div>

                <mat-divider class="mt-5 mb-10"></mat-divider>
                <!-- Point of sales  -->

             
                <div class="flex mb-2">
                    <mat-form-field style="max-width: 600px"
                        class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix">
                        <mat-label class="ml-3">Sales Order Date</mat-label>
                        <input [ngClass]="formFieldHelpers" matInput [matDatepicker]="picker2"
                            placeholder="Sales order Date" name="slaes_order_date"
                            [formControlName]="'voucher_order_date'" class="elevated-input small-input-text" required
                            (dateChange)="onSalesOrderDateChange($event)" />

                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                        <mat-icon class="icon-size-4" matPrefix [svgIcon]="'heroicons_solid:calendar'"></mat-icon>
                    </mat-form-field>
                </div>
                <ng-container >
                    <div class="flex mb-2">

                        <mat-form-field style="max-width: 600px"
                            class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix">
                            <mat-label>Voucher Type</mat-label>
                            <mat-select placeholder="Voucher Type"  name="voucher_type"
                                [formControlName]="'voucher_type'" class="elevated-input" [required]="'filterviaVoucher'" >
                                <mat-option value="Store Credit">STORE CREDIT</mat-option>
                                <mat-option value="Normal Voucher">ADMIN REQUEST</mat-option>
                            </mat-select>
                            <mat-icon class="icon-size-4" matPrefix [svgIcon]="'point_of_sale'"></mat-icon>
                        </mat-form-field>

                    </div>

                    <mat-form-field [ngClass]="formFieldHelpers" style="max-width: 600px"
                        class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix">
                        <input  matInput placeholder="Total" type="text"
                            onkeypress="return event.charCode>=48 && event.charCode<=57" [required]="'filterviaVoucher'"  name="total"
                            [formControlName]="'total'" class="elevated-input  small-input-text" />
                        <span matPrefix>रु</span>
                        <span matSuffix>.00</span>
                        <mat-label> Total Voucher Amount</mat-label>

                    </mat-form-field>

                    <div class="flex mb-2">

                        <mat-form-field style="max-width: 500px; margin: 0"
                            class="fuse-mat-textarea flex-auto fuse-mat-rounded fuse-mat-emphasized-affix">
                            <mat-label>Voucher description</mat-label>
                            <textarea matInput placeholder="write something" [formControlName]="'notes'" name="notes"
                                [rows]="3" class="elevated-input small-input-text"></textarea>
                            <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:menu-alt-2'"></mat-icon>
                        </mat-form-field>
                    </div>
                  
                    <div class="flex mb-2">
                        <mat-form-field style="max-width: 600px"
                            class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix">
                            <mat-label>Salesperson</mat-label>
                            <input matInput placeholder="Sales Person" name="Tax" [formControlName]="'sales_person'"
                                class="elevated-input small-input-text" [value]="user.username.toUpperCase()" readonly>
                            <mat-icon class="icon-size-5" matPrefix [svgIcon]="'feather:user'"></mat-icon>
                        </mat-form-field>
                    </div>

                </ng-container>
                <!-- Remove product -->

                <div class="flex items-center justify-start mt-10">

                    <button style="margin: 5px" (click)="sumbitSalesOrder()"
                        class="px-6 py-2 rounded text-white bg-red-500 hover:bg-red-600" mat-flat-button type="submit">
                        Save
                    </button>

                    <button style="margin: 5px" [routerLink]="['..']"
                        class="px-6 py-2 rounded text-white bg-blue-500 hover:bg-blue-600" mat-flat-button
                        color="'accent'">
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>