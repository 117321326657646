import { NgModule } from '@angular/core';
import { CommonModule,DatePipe } from '@angular/common';
import { ExchangesComponent } from './exchanges/exchanges.component';
import { CreateExchangesComponent } from './create-exchanges/create-exchanges.component';
import { ListExchangesComponent } from './list-exchanges/list-exchanges.component';
import { DetailExchangesComponent } from './detail-exchanges/detail-exchanges.component';
import { MaterialModule } from 'app/modules/material/material.module';
import { RouterModule } from '@angular/router';
import { ExchangesRoutes } from './exchanges.routing';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDialogModule } from '@angular/material/dialog';
import { CustomSelectModule } from 'app/custom-select/custom-select.module';
import { FuseDrawerModule } from '@fuse/components/drawer';



@NgModule({
  declarations: [
    ExchangesComponent,
    CreateExchangesComponent,
    ListExchangesComponent,
    DetailExchangesComponent
  ],
  imports: [
    RouterModule.forChild(ExchangesRoutes),
    CommonModule,
    MaterialModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    CustomSelectModule,
    CommonModule,
    FuseDrawerModule
  ],
  providers:[
    DatePipe
  ]
})
export class ExchangesModule { }
