

  import { Component, OnInit,ViewChild, OnDestroy,Input } from '@angular/core';
  import { MatPaginator } from '@angular/material/paginator';
  import { MatSort } from '@angular/material/sort';
  import { MatTableDataSource } from '@angular/material/table';
  import { PackageType } from 'app/packages/package.type';
  import { Observable } from 'rxjs';
  import { Router } from '@angular/router';
  import { PackagesOderService } from 'app/packages/packages.service';
  
  interface packageData {
    id: number;
    salesOrder: string;
    infulencerOrder: string;
    date: Date;
    packageSlip: string
    shipSlip: string
    isPackaged: boolean
    isShipped: boolean;
    isDelivared: boolean;
 
  }
  @Component({
    selector: 'fuse-delivered-package',
    templateUrl: './delivered-package.component.html',
    styleUrls: ['./delivered-package.component.scss']
  })
  export class fuseDeliveredPackageComponent implements OnInit,OnDestroy {

    
    @Input() dataTableSource: MatTableDataSource<packageData>;
    @Input() title: string

        displayedColumns: string[] = [
            'Package Code',
            "SaleOrderNo",
            'Date',
            'isComfirmed',
            'actions',
        ];
    
        @ViewChild(MatPaginator) paginator: MatPaginator;
        @ViewChild(MatSort) sort: MatSort;
        packageOders: PackageType[];
        dataSource: MatTableDataSource<packageData>
        filteredPackageData: any[] = [];

        pkgsOrder$: Observable<PackageType[]>;
        isLoading: boolean = false;
        dropdownHidden: boolean = true;
        shipdropdownHidden: boolean = true;
        customerName: any;
        SlaesOrderNumber: any;
        total: any;
        packagedDate: any;
        PackageCode: any;
        packageId:any;
        pageSizeOptions: number[] = [5, 10, 25]; // Options for items per page
        pageSize: number = 5; // Default items per page
        pageIndex: number = 0; // Default page index
      
        constructor(
            private _router: Router,
        ) { }
    
        ngOnInit(): void {
            if(this.dataTableSource){
               this.getPackagess()
            }
    
        }
        // Handler for page change event
  onPageChange(event: any): void {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
  }

  // Calculate the current page of data based on the pageSize and pageIndex
  getPaginatedData(): any[] {
    const startIndex = this.pageIndex * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    return this.filteredPackageData.slice(startIndex, endIndex);
  }
    
        getPackagess = () => {
            this.filteredPackageData = this.dataTableSource['data'].filter((row)=>(row.isPackaged && row.isShipped  && row.isDelivared));
            this.patchFilteredData(this.filteredPackageData);

         
        }
        patchFilteredData(filteredData: any[]): void {
        // You can update other parts of your application here using a loop
        // For example:
        for (const data of filteredData) {
            const lastname = data.salesOrder.Customers.lastName;

            this.customerName = data.salesOrder.Customers.firstName + " "+ lastname;

            this.SlaesOrderNumber = data.salesOrder.sales_order_num;
            this.total = data.salesOrder.total;
            this.packagedDate = data.date;
            this.PackageCode = data.packageSlip;
            this.packageId = data.id;
        }
    }
        ngAfterViewInit() {
            setTimeout(() => {
                if (this.dataSource) {
                    this.dataSource.paginator = this.paginator;
                    this.dataSource.sort = this.sort;
                }
            }, 400);
        }
    
        showDetails(id: any) {
            this._router.navigate(['apps/packages', id]);
        }
    
        applyFilter(event: Event) {
            const filterValue = (event.target as HTMLInputElement).value;
            this.dataSource.filter = filterValue.trim().toLowerCase();
    
            if (this.dataSource.paginator) {
                this.dataSource.paginator.firstPage();
            }
        }
    
        ngOnDestroy(): void {
            // clearTimeout(this.delayTimeout);
            // throw new Error('Method not implemented.');
        }
    }
    
    
    
    
