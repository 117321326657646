import { Route } from '@angular/router';
import { ListrestockEntryComponent } from './listrestock-entry/listrestock-entry.component';
import { CreaterestockEntryComponent } from './createrestock-entry/createrestock-entry.component';
import { DetailrestockEntryComponent } from './detailrestock-entry/detailrestock-entry.component';
import { UnsavedChangesGuard } from 'app/page-detect/wm-can-leave/detect-changes.guard';





export const RestockEntryRoutes: Route[] = [
   
        {
            path     : '',
            component: ListrestockEntryComponent,
        },
        {
            path         : 'NEW',
            component    : CreaterestockEntryComponent,
            canDeactivate: [UnsavedChangesGuard], 

           
        },
        {
            path         : ':id',
            component    :DetailrestockEntryComponent,
          
          
        },
]
