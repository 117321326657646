<div *ngIf="vendor" class="mt-10 ml-15 md:ml-50 lg:ml-90">



    <div class="absolute inset-0 min-w-0 text-center overflow-auto print:overflow-visible" cdkScrollable>


        <div class="w-full inline-block p-6 sm:p-10 text-left print:p-0">

            <div
                class="w-full  p-16 rounded-2xl shadow bg-card print:w-auto print:p-0 print:rounded-none print:shadow-none print:bg-transparent">

                <div class="flex items-center">
                    <div class="grid grid-rows-2 place-items-center ">

                        <div class="grid-cols-2  place-items-center">

                            <button mat-button type="button" matTooltip="Close" class="hide-on-print"
                                (click)="goBackOnPreviousWin()">
                                <span class="inline-flex items-center">
                                    <mat-icon svgIcon="feather:x"></mat-icon>
                                </span>
                            </button>

                            <button mat-button type="button" class="hide-on-print" (click)="printPage()">
                                <span class="inline-flex items-center">
                                    <mat-icon svgIcon="feather:file"></mat-icon>
                                </span>
                            </button>



                        </div>
                    </div>
                </div>
                <div class="flex items-start">

                    <div class="grid grid-rows-2 place-items-start ">
                        <div class="grid auto-cols-max grid-flow-col gap-x-5">

                            <div class="pl-4text-md">
                                <div class="font-medium"> {{ vendor.Customers.firstName }} {{ vendor.Customers.lastName
                                    }}
                                </div>
                                <div>{{vendor.Customers.gender}}</div>
                                <div>{{vendor.Customers.socialMedia }}</div>
                                <ng-container *ngFor="let number of vendor.Customers.phoneNumbers"
                                    class="col-span-12 flex items-center">

                                    <div> {{number.label}} +977-{{number.number}}</div>
                                </ng-container>

                                <div *ngFor="let email of vendor.Customers.emails">
                                    <div>{{ email.email}}</div>
                                </div>

                                
                                <div *ngFor="let email of vendor.Customers.billingAddress">
                                    <div>{{email.type}} {{email.city}} {{ email.address}}</div>
                                </div>

                                <div *ngFor="let email of vendor.Customers.shippingArea">
                                    <div>{{email.type}} {{email.city}} {{ email.address}}</div>
                                </div>
                            </div>
                        </div>

                    </div>




                    <div class="grid grid-cols-2 gap-x-4 gap-y-1 ml-auto">

                        <div class="justify-self-end text-4xl tracking-tight text-secondary">Pre Order</div>
                        <div class="text-4xl">{{vendor.sales_order_num}}</div>
                        <div class="justify-self-end font-medium tracking-tight text-secondary">Created Date</div>
                    
                        <div class="font-medium">{{vendor.sales_order_shippmentDate}}</div>
                        <div class="justify-self-end font-medium tracking-tight text-secondary">TOTAL </div>
                        <div class="font-medium">रू {{vendor.total}}</div>
                        <div class="justify-self-end font-medium tracking-tight text-secondary">Status </div>
                        <div class="font-medium">{{vendor.status}}</div>
                    </div>

                </div>


                <div class="flex items-start">

                    <div class="grid grid-rows-1 place-items-start">
                        <div class="grid auto-cols-max grid-flow-col gap-x-5">

                            <div class="pl-4text-md">
                                <div class=" tracking-tight">
                                    <strong class="mr-3">Point of sales </strong>
                                    <ng-container class="text-secondary" *ngIf="vendor.pos">{{ vendor.pos }}
                                    </ng-container>
                                    <ng-container *ngIf="!vendor.refrences">--</ng-container>

                                </div>


                                <div class="font-medium tracking-tight">
                                    <strong class="mr-3">Social Media Link </strong>
                                    <a class="text-link" *ngIf="vendor.soical_link" target="_blank"
                                        href="https://www.instagram.com/{{vendor.soical_link}}">@{{ vendor.soical_link
                                        }}</a>
                                    <div *ngIf="!vendor.refrences">--</div>

                                </div>


                                <div class="tracking-tight mr-3">
                                    <strong class="mr-3">Shipping Area </strong>
                                    <div *ngIf="vendor.shipping_area">{{ vendor.shipping_area }},
                                        {{vendor.district}},<br>
                                        {{vendor.address}}</div>
                                    <div *ngIf="!vendor.shipping_area">--</div>

                                </div>


                                <div class="tracking-tight">
                                    <strong class="mr-3">Payment Type </strong>

                                    <ng-container class="text-secondary " *ngIf="vendor.payment_type">{{
                                        vendor.payment_type }}</ng-container>
                                    <ng-container class="text-secondary" *ngIf="!vendor.payment_type">--</ng-container>
                                </div>


                                <div class="tracking-tight ">
                                    <strong class="mr-3">Delivery Type </strong>
                                    <ng-container class="text-secondary" *ngIf="vendor.delivery_type">{{
                                        vendor.delivery_type }}</ng-container>
                                    <ng-container class="text-secondary" *ngIf="!vendor.refrences">--</ng-container>
                                </div>


                                <div class="tracking-light">
                                    <strong class="mr-3">Shipping Carrier </strong>

                                    <ng-container class="text-secondary" *ngIf="vendor.shipping_carrier_invalley">{{
                                        vendor.shipping_carrier_invalley }}</ng-container>
                                    <ng-container class="text-secondary" *ngIf="vendor.shipping_carrier_outvalley">{{
                                        vendor.shipping_carrier_outvalley}}</ng-container>
                                    <ng-container class="text-secondary" *ngIf="vendor.shipping_carrier_int">{{
                                        vendor.shipping_carrier_int }}</ng-container>
                                    <ng-container class="text-secondary"
                                        *ngIf="vendor.shipping_carrier_invalley_others">
                                        {{vendor.shipping_carrier_invalley_others }}</ng-container>
                                    <ng-container class="text-secondary" *ngIf="vendor.shipping_carrier_int">{{
                                        vendor.shipping_carrier_int }}</ng-container>
                                    <ng-container class="text-secondary" *ngIf="!vendor.refrences">--</ng-container>

                                </div>

                                <div class="tracking-light">
                                    <strong class="mr-3">SalesPerson </strong>
                                    <ng-container class="text-secondary" *ngIf="vendor.sales_person">{{
                                        vendor.sales_person }}</ng-container>
                                    <ng-container class="text-secondary" *ngIf="!vendor.refrences">-</ng-container>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>


                <div class="grid grid-cols-12 gap-x-1  mt-10">
                    <!-- Columns -->
                    <div class="col-span-6 font-medium text-md text-secondary">PRODUCT</div>
                    <div class="col-span-2 font-medium text-md text-right text-secondary">SIZE</div>
                    <div class="col-span-2 font-medium text-md text-right text-secondary">COLOR</div>
                    <div class="col-span-1 font-medium text-md text-right text-secondary">QTY</div>
                    <div class="col-span-1 font-medium text-md text-right text-secondary">TOTAL</div>

                    <!-- Divider -->
                    <div class="col-span-12 my-4 border-b"></div>

                    <!-- Products Loop -->
                    <ng-container *ngFor="let product of vendor.products" class="col-span-12 flex items-center">
                        <!-- Product Avatar (Image) -->
                        <div class="col-span-6">
                            <div class="text-lg font-medium">
                                <!-- Check if it is a bundle or product -->
                                <ng-container *ngIf="product.isBundle; else productInfo">
                                    {{ product.product_bundle.bundle_name }}
                                </ng-container>
                                <ng-template #productInfo>
                                    {{ product.displayName }}
                                </ng-template>
                            </div>
                            <div class="mt-2 text-md text-secondary">
                                <!-- Check if it is a bundle or product -->
                                <ng-container *ngIf="product.isBundle; else productDescription">
                                    {{ product.product_bundle.bundle_description }}
                                </ng-container>
                                <ng-template #productDescription>
                                    {{ product.displayDescription }}
                                </ng-template>
                            </div>
                        </div>

                        <div class="col-span-2 self-center text-right">{{ product.product.size }}</div>
                        <div class="col-span-2 self-center text-right">{{ product.product.color }}</div>
                        <div class="col-span-1 self-center text-right">{{ product.quantity }}</div>
                        <div class="col-span-1 self-center text-right">रू {{ product.amount }}</div>

                        <!-- Divider -->
                        <div class="col-span-12 my-4 border-b"></div>
                    </ng-container>


                    <!-- Spacer -->
                    <div class="col-span-12 mt-16"></div>

                    <!-- Subtotal -->
                    <div class="col-span-10 self-center font-medium tracking-tight text-secondary">SUBTOTAL</div>
                    <div class="col-span-2 text-right text-lg">रू {{ vendor.sub_total }}</div>

                    <!-- Divider -->
                    <div class="col-span-12 my-3 border-b"></div>

                    <!-- Shipping Charges -->
                    <div class="col-span-10 self-center font-medium tracking-tight text-secondary">ADJUSTMENT
                    </div>
                    <div class="col-span-2 text-right text-lg">रू {{ vendor.adjustment }}</div>

                    <!-- Divider -->
                    <div class="col-span-12 my-3 border-b"></div>

                    <!-- Discount -->
                    <div class="col-span-10 self-center font-medium tracking-tight text-secondary">DISCOUNT</div>
                    <div class="col-span-2 text-right text-lg">रू {{ vendor.discount }}</div>

                    <!-- Divider -->
                    <div class="col-span-12 my-3 border-b"></div>

                    <!-- Total -->
                    <div class="col-span-10 self-center text-2xl font-medium tracking-tight text-secondary">TOTAL</div>
                    <div class="col-span-2 text-right text-2xl font-medium">रू {{ vendor.total }}</div>
                </div>



                <div class="mt-16">
                    <div class="font-medium">Notes To the Customer: {{vendor.notes}}
                    </div>
                    <div class="flex items-start mt-4">
                        <img class="flex-0 w-20 mt-2" src="assets/images/logo/logo.png">
                        <div class="ml-6 text-sm text-secondary">
                        </div>
                    </div>
                </div>

            </div>

        </div>

    </div>
</div>