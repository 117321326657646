import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SalesOrderNumberService {
  private currentSalesOrderNum: number = 1;

  constructor() {
    // Load the value from storage if available
    const savedValue = parseInt(localStorage.getItem('currentSalesOrderNum'));
    if (!isNaN(savedValue)) {
      this.currentSalesOrderNum = savedValue;
    }
  }

  getCurrentSalesOrderNum(): number {
    return this.currentSalesOrderNum;
  }

  incrementSalesOrderNum(): void {
    this.currentSalesOrderNum++;
    localStorage.setItem('currentSalesOrderNum', this.currentSalesOrderNum.toString());
  }
}
