import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { PrPackagesComponent } from './pr-packages/pr-packages.component';
import { ListprPackagesComponent } from './listpr-packages/listpr-packages.component';
import { CreateprPackagesComponent } from './createpr-packages/createpr-packages.component';
import { DetailprPackagesComponent } from './detailpr-packages/detailpr-packages.component';
import { MaterialModule } from 'app/modules/material/material.module';
import { RouterModule } from '@angular/router';
import { PrPackagesRoute } from './pr-packages.routing';
import { PrPackagesPopupComponent } from './pr-packages-popup/pr-packages-popup.component';
import { PrPackagesProductPopupComponent } from './pr-packages-product-popup/pr-packages-product-popup.component';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';



@NgModule({
  declarations: [
    PrPackagesComponent,
    ListprPackagesComponent,
    CreateprPackagesComponent,
    DetailprPackagesComponent,
    PrPackagesPopupComponent,
    PrPackagesProductPopupComponent
  ],
  imports: [
    RouterModule.forChild(PrPackagesRoute),
    MaterialModule,
    CommonModule,
    MatDialogModule,
    MatAutocompleteModule
  ], providers   : [
    {
        provide : MAT_DATE_FORMATS,
        useValue: {
            parse  : {
                dateInput: "YYYY-MM-DD"
            },
            display: {
                dateInput         : 'YYYY-MM-DD',
                monthYearLabel    : 'MMM YYYY',
                dateA11yLabel     : 'LL',
                monthYearA11yLabel: 'MMMM YYYY'
                
            }
        }
    },
    DatePipe
]
})
export class PrPackagesModule { }
