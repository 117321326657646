import {
    Component,
    OnDestroy,
    OnInit,
} from '@angular/core';
import {
    FormBuilder,
    FormGroup,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { Observable } from 'rxjs';
import {
    take,
} from 'rxjs/operators';
import Swal from 'sweetalert2';
import { VendorCreditService } from '../vendors-credit.service';

export interface VendorOption {
    id: string;
    firstname?: string;
    avatar?: string;
}
@Component({
    selector: 'app-createvendors-credit',
    templateUrl: './createvendors-credit.component.html',
    styleUrls: ['./createvendors-credit.component.scss'],
})
export class CreatevendorsCreditComponent implements OnInit {
    isLoading = false;

    horizontalStepperForm: UntypedFormGroup;
    expenseHeadForm: FormGroup;
    expenseCategoryForm: FormGroup;
   
    color$: Observable<any[]>;
    size$: Observable<any[]>;
  
    size: any;
    colors: any;
   
    step1Form: FormGroup;
    step2Form: FormGroup;
  
    formFieldHelpers: any;
  
  
  
  
    constructor(
  
      private _formBuilder: FormBuilder,
        private _expenseService: VendorCreditService,
  
    ) {
  
    }
  
    ngOnInit() {
  
  
      this.expenseHeadForm = this._formBuilder.group({
        name: ['', Validators.required],
  
      });
  
      this.expenseCategoryForm = this._formBuilder.group({
        name: ['', Validators.required],
  
      });
  
    
  
  
  
  
  
  
    }
  
    saveExpenseHead() {
      this.isLoading = true;
  
      // Get the form data
      const formData = this.expenseHeadForm.value;
  
      // Check if color name and SKU already exist
      this._expenseService.getExpenseHead().pipe(take(1)).subscribe(
        (colors) => {
          this.colors = colors['data'];
  
          const colorNameExists = this.colors.some((color) => color.name === formData.name);
  
          if (colorNameExists) {
            this.showErrorMessage('Expense HEAD name already exists!');
            this.isLoading = false;
         
          } else {
            this.createColor(formData);
          }
        },
        (error) => {
          console.error('Error checking EXPENSE HEAD existence', error);
          this.showErrorMessage('Error checking EXPENSE HEAD existence');
          this.isLoading = false;
        }
      );
    }
  
    saveExpenseCategory() {
      this.isLoading = true;
      this.size$ = this._expenseService
        .getExpenseCategory()
        .pipe(take(1));
    
      this.size$.subscribe(
        (size) => {
          this.size = size['data'];
          const formData = this.expenseCategoryForm.value;
    
          const nameExists = this.size.some((size) => size.name === formData.name);
    
          if (nameExists) {
            this.showErrorMessage('EXPENSE CATEGORY name already exists!');
            this.isLoading = false;
          
          } else {
            this.createSize(formData);
          }
        },
        (error) => {
          console.error('Error checking EXPENSE CATEGORY existence', error);
          this.showErrorMessage('Error EXPENSE CATEGORY existence');
          this.isLoading = false;
        }
      );
    }
    
  
  
  
  
  
    private createColor(formData: any) {
      // Create the color
      this._expenseService.createExpenseHead(formData).then(
        () => {
          this.showSuccessMessage('Expense HEAD has been created!!');
          this.isLoading = false;
          this.expenseHeadForm.reset();
        },
        (error) => {
          // Error while creating color, show error message
          this.showErrorMessage(`Error creating EXPENSE HEAD: ${error.message}`);
          this.isLoading = false;
        }
      );
    }
  
    private createSize(formData: any) {
      this._expenseService.createExpenseCategory(formData).then(
        () => {
          // Color created successfully, show success message
          this.showSuccessMessage('EXPENSE CATEGORY has been created!!');
          this.isLoading = false;
          this.expenseCategoryForm.reset();
        },
        (error) => {
          // Error while creating color, show error message
          this.showErrorMessage(`Error creating EXPENSE CATEGORY: ${error.message}`);
          this.isLoading = false;
        }
      );
    }
  
   
  
    private showSuccessMessage(message: string) {
      // Using SweetAlert to show success message
      Swal.fire({
          title: message,
          width: 600,
          padding: "3em",
          color: "#fff",
          background: "#fff url(/assets/free2.png)", // Adjusting image to fit properly
          backdrop: `
              rgba(0,123,0,0.4) 
          `
      });
  }
  
  private showErrorMessage(message: string) {
      // Using SweetAlert to show error message
      Swal.fire({
          title: message,
          width: 600,
          padding: "3em",
          color: "#fff",
          background: "#fff url(/assets/free.png)", // Adjusting image to fit properly
          backdrop: `
              rgba(123,0,0,0.4) 
          `
      });
  }
  
  
  
    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
      return item.id || index;
    }
  
  
  
  
  }
  
  
