import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FuseCardComponent } from '@fuse/components/card';
import { ConfirmPopupComponent } from 'app/confirm-popup/confirm-popup.component';



@Component({
  selector: 'app-pr-packages-popup',
  templateUrl: './pr-packages-popup.component.html',
  styleUrls: ['./pr-packages-popup.component.scss']
})
export class PrPackagesPopupComponent implements OnInit {
  @Output() formSubmitted: EventEmitter<any> = new EventEmitter<any>();

  customer_popup: FormGroup;

  @ViewChild('fuseCard') fuseCard: FuseCardComponent;

  constructor(
    private _matDialog: MatDialogRef<ConfirmPopupComponent>,
    private _formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.customer_popup = this._formBuilder.group({
      firstName: [''],
      lastName: [''],
      gender: [''],
      emails: [''],
      phone_number: [''],
    });
  } 

  submitForm(formData: any): void {
    
    // Perform any required form validation or data processing
   
    

    
    // Emit the form data
    this.formSubmitted.emit(formData);
    
    // Close the modal
    this._matDialog.close();
  }
    // Emit the form data
    

  cancel(): void {
    this._matDialog.close();
  }
}
