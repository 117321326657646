import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { VendorsPayment } from './vendors-payment.type';
import axios, { AxiosResponse } from 'axios';
import { data } from 'cypress/types/jquery';
import { server_env } from 'config';

@Injectable({
    providedIn: 'root',
})
export class VendorsPaymentService {
    // Private
    private _VendorsPayment: BehaviorSubject<VendorsPayment | null> =
        new BehaviorSubject(null);
    private _VendorsPaymentss: BehaviorSubject<VendorsPayment[] | null> =
        new BehaviorSubject(null);
        private _paymentorder_numbers: BehaviorSubject<any[] | null> =
        new BehaviorSubject(null);
    public url = `${server_env.URL}api/v1/vendorPayment/`;
    public url2 = `${server_env.URL}api/v1/vendorPayments/`;

    public url3 = `${server_env.URL}api/v1/vendorPayment_n`;

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors

    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for contact
     */
    get VendorsPayment$(): Observable<VendorsPayment> {
        return this._VendorsPayment.asObservable();
    }

    /**
     * Getter for contacts
     */
    get VendorsPayments$(): Observable<VendorsPayment[]> {
        return this._VendorsPaymentss.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get contacts
     */

    getVendorsPayment(): Observable<VendorsPayment[]> {
        return this._httpClient.get<VendorsPayment[]>(`${this.url}`).pipe(
            tap((VendorsPayment: any) => {
                console.log();
                this._VendorsPaymentss.next(VendorsPayment.data);

                for (VendorsPayment in VendorsPayment.data) {
                }
            })
        );
    } 
     getPayment_number(): Observable<any[]> {
        return this._httpClient.get<any>(`${this.url3}`).pipe(
            tap((number: any) => {
                this._paymentorder_numbers.next(number.data);
    
            })
        );
    }   
     PartialupdateExpense(id: string | number, contact: any): Promise<any> {
        return new Promise((resolve, reject) => {
            const requestUrl = `${this.url}${id}/`;
    
            this._httpClient.patch<any>(requestUrl, contact).subscribe(
                (response) => {
                    resolve(response); // Resolves the promise with the response data
                },
                (error) => {
                    reject(error); // Rejects the promise with the error
                }
            );
        });
    }

    /**
     * Get contact by id
     */
    getVendorsPaymentById(id: string): Observable<VendorsPayment> {
        const requestUrl = this.url + id;

        return this._httpClient.get<VendorsPayment[]>(`${requestUrl}`).pipe(
            take(1),
            map((VendorsPayment: any) => {
                this._VendorsPayment.next(VendorsPayment.data);

                // Return the contact
                return VendorsPayment.data;
            }),
            switchMap((VendorsPayment) => {
                if (!VendorsPayment) {
                    return throwError(
                        'Could not found contact with id of ' + id + '!'
                    );
                }

                return of(VendorsPayment);
            })
        );
    }

    /**
     * Create contact
     */
    // createVendorsPayment(): Observable<VendorsPayment>
    // {
    //     return this.VendorsPayment$.pipe(
    //         take(1),
    //         switchMap(VendorsPayment => this._httpClient.post<VendorsPayment>(`${this.url}`, {}).pipe(
    //             map((newVendorsPayment) => {

    //                 // Update the contacts with the new contact
    //                 this._VendorsPaymentss.next([newVendorsPayment, ...VendorsPayment]);

    //                 // Return the new contact
    //                 return newVendorsPayment;
    //             })
    //         ))
    //     );
    // }

    /**
     * create customer
     * @param data
     * @returns
     */

    createVendorsPayments(data): Promise<any> {
        return new Promise((resolve, reject) => {
            const requestUrl = this.url;

            this._httpClient.post<any>(`${requestUrl}`, data).subscribe(
                (response) => {
                    return resolve(response);
                },
                (error) => {
                    return reject(error);
                }
            );
        });
    }
        /**
     * create customer
     * @param data
     * @returns
     */

        createVendorsPaymentss(data): Promise<any> {
            return new Promise((resolve, reject) => {
                const requestUrl = this.url2;
    
                this._httpClient.post<any>(`${requestUrl}`, data).subscribe(
                    (response) => {
                        return resolve(response);
                    },
                    (error) => {
                        return reject(error);
                    }
                );
            });
        }
    /**
     * Update contact
     *
     * @param id
     * @param contact
     */
    updateVendorsPayment(
        id: string,
        contact: VendorsPayment
    ): Observable<VendorsPayment> {
        return this.VendorsPayments$.pipe(
            take(1),
            switchMap((salesordder) =>
                this._httpClient
                    .put<VendorsPayment>(`${this.url}${id}/`, {
                        id,
                        contact,
                    })
                    .pipe(
                        map((updatedVendorsPayment) => {
                            // Find the index of the updated contact
                            const index = salesordder.findIndex(
                                (item) => item.id === id
                            );

                            // Update the contact
                            salesordder[index] = updatedVendorsPayment;

                            // Update the contacts
                            this._VendorsPaymentss.next(salesordder);

                            // Return the updated contact
                            return updatedVendorsPayment;
                        }),
                        switchMap((updatedVendorsPayments) =>
                            this.VendorsPayment$.pipe(
                                take(1),
                                filter((item) => item && item.id === id),
                                tap(() => {
                                    // Update the contact if it's selected
                                    this._VendorsPayment.next(
                                        updatedVendorsPayments
                                    );

                                    // Return the updated contact
                                    return updatedVendorsPayments;
                                })
                            )
                        )
                    )
            )
        );
    }

    /**
     * Delete the contact
     *
     * @param id
     */
    deleteVendorsPayment(id: string): Observable<boolean> {
        return this.VendorsPayments$.pipe(
            take(1),
            switchMap((VendorsPayment) =>
                this._httpClient.delete(`${this.url}${id}/`).pipe(
                    map((isDeleted: boolean) => {
                        // Find the index of the deleted contact
                        const index = VendorsPayment.findIndex(
                            (item) => item.id === id
                        );

                        // Delete the contact
                        VendorsPayment.splice(index, 1);

                        // Update the contacts
                        this._VendorsPaymentss.next(VendorsPayment);

                        // Return the deleted status
                        return isDeleted;
                    })
                )
            )
        );
    }

    async fetchData(): Promise<string[]> {
        try {
            const response: AxiosResponse = await axios.get(
                'https://nepallocation.com.np/api/v1/district/list',
                {
                    headers: {
                        Authorization: `Bearer AFKw8mI-vtgRq-M2i1qDvIGS`,
                        'Content-Type': 'application/json',
                    },
                }
            );

            const districtNames = response.data.data.data.map(
                (district) => district.name
            );
            console.log(districtNames, 'districtNames');
            return districtNames;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
}
