import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { ActivatedRoute, Router } from '@angular/router';

import { map, take, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import Swal from 'sweetalert2';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ProductPopupComponent } from 'app/products/products-popup/product-popup.component';
import { productCategoryService } from '../product-categories.service';
import { productVaraitons } from 'app/productVariations/productVariations.service';
import { productsService } from 'app/products/products.service';
import { products } from 'app/products/products.type';

@Component({
    selector: 'app-createproduct-categories',
    templateUrl: './createproduct-categories.component.html',
    styleUrls: ['./createproduct-categories.component.scss'],
})
export class CreateproductCategoriesComponent implements OnInit {
    @ViewChild('fruitInput') fruitInput: ElementRef<HTMLInputElement>;
    drawerMode: 'over' | 'side' = 'side';
    drawerOpened: boolean = true;
    vendorsCredit: FormGroup;
    successMessage: string;
    errorMessage: string;
    exampleForm: FormGroup;
    // thumbnail Variables
    selectedProductThumbnail: string[] = [];
    selectedProductThumbnailClass: string;
    filteredProductOptions$: Observable<productVaraitons[]>;
    categoriesCTR = new FormControl('');
    filteredCat: Observable<string[]>;
    allproducts: string[] = [
        'FEMALE',
        'STREETWEAR',
        'BAD AND BOUJEE',
        '90zz',
        'RETRO',
    ];
    prodcutsCtr: string[] = ['MALE'];
    thumbnailFile: File | null;

    thumbnailURL: string;
    imageUrls: string[] = [];
    cardValue: any;
    genderOptions: { label: string; value: string }[] = [];
    vendorControl: FormControl = new FormControl();


    mappedVariations: any;
    sizes: any[] = []; // Initialize with empty array
    colors: any[] = []; // Initialize with empty array
    active: boolean[] = [];
    animationFlags: boolean[] = [];

    constructor(
        private _formBuilder: FormBuilder,
        private elementRef: ElementRef,
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _productService: productsService,
        private dialog: MatDialog,
        private productService: productCategoryService,
        private cd: ChangeDetectorRef,

    ) {
        const products$: Observable<productVaraitons[]> = this._productService
            .getproducts()
            .pipe(
                take(1),
                map((response) => response)
            );
        products$.subscribe((products) => {
        });
    }

    ngOnInit() {
        // Get the vendors

        this.vendorsCredit = this._formBuilder.group({
            category_name: ['', Validators.required],
            category_description: ['', Validators.required],
            category_sku: [''],

            products: this._formBuilder.array([]),


        });

        this.filteredProductOptions$ = of([]);
        // Clear the products FormArray
        (this.vendorsCredit.get('products') as FormArray).clear();

        const initialProductCount = 1;


        // Loop to create the product form controls
        for (let i = 0; i < initialProductCount; i++) {
            const productGroup = this._formBuilder.group({
                id:[''],

                product: ['',],
                color: ['rgb(79, 195, 255)',],
                size: ['',],
            });
            const productArrayN = this.vendorsCredit.get(
                'products'
            ) as FormArray;
            productArrayN.push(productGroup);

            for (let i = 0; i < productArrayN.length; i++) {
                this.animationFlags[i] = false;
            };
    
            this.active = Array(initialProductCount).fill(false);
        }
    }




    addProductField(): void {
        const productFormGroup = this._formBuilder.group({
            id:[''],

            product: ['',],
            size: ['',],
            rate: [''],
            quantity: [''],
            color: ['',],
            amount: [''],
        });

        const productArray = this.vendorsCredit.get('products') as FormArray;
        productArray.push(productFormGroup);

        const index = productArray.length - 1;

    }


    filterOptionsProduct(value: string): void {
        if (typeof value !== 'string') {
            this.filteredProductOptions$ = of([]);
            return;
        }
        this.filteredProductOptions$ = this._productService.getproducts().pipe(
            take(1),
            map((response) => response),
            tap((products) =>
                console.log()
            ), // Log the products array before filtering
            map((products) => {
                if (Array.isArray(products['data'])) {
                    const filteredProducts = products['data']
                        .filter(
                            (product) =>
                                product.productName
                                    ?.toLowerCase()
                                    .includes(value.toLowerCase()) ||
                                (product.sku &&
                                    product.size
                                        .toString()
                                        .toLowerCase()
                                        .includes(value.toLowerCase()))
                        )
                        .map((product) => {
                            return {
                                id:product.id,
                                product: product.id,
                                productName: product.productName,
                                sku: product.sku,
                                size: product.size,
                                color: product.color,
                                basePrice: product.basePrice,

                            };
                        });
                    // Log the filtered products array
                    return filteredProducts;

                } else {

                    return [];
                }
            })
        );
    }


    onSelectionChange(index: number): void {
        const productArray = this.vendorsCredit.get('products') as FormArray;
        const existingFormGroup = productArray.at(index) as FormGroup;

        if (existingFormGroup) {
            const selectedProductId = existingFormGroup.get('product').value.id;
            const selectedSizeId = existingFormGroup.get('size').value;
            const selectedColorId = existingFormGroup.get('color').value;

            const selectedSizeColorCombo = `${selectedSizeId}${selectedColorId}`;
            const matchingOriginalVariation = this.mappedVariations?.find(variation => {
                const variationSizeColorCombo = `${variation.size}${variation.color}`;
                return variationSizeColorCombo === selectedSizeColorCombo;
            });

            if (matchingOriginalVariation) {
                existingFormGroup.get('size').patchValue(matchingOriginalVariation.size);
                existingFormGroup.get('color').patchValue(matchingOriginalVariation.color);
                existingFormGroup.get('id').patchValue(matchingOriginalVariation.id)


                this.active[index] = true;


                // Set the animation flag to true for the specific form field
                this.animationFlags[index] = true;

                // Reset the animation flag after 3 seconds (adjust the duration as needed)
                setTimeout(() => {
                    this.animationFlags[index] = false;
                }, 3000)

                // Call your function to update the form based on the selected product
                console.log("Matching variation found for the selected size and color:", matchingOriginalVariation);
            } else {
                console.log("No matching original variation found for the selected size and color.");
            }
        }
    }

    productVariationsArray: { [productId: string]: any[] } = {};

    filtersOptionsProduct(productId: string, index: number): void {
        this._productService.getProductVariations(productId).subscribe(variations => {
            this.mappedVariations = variations.data.map(variation => {
                return {
                    id: variation.id,
                    size: variation.size,
                    color: variation.color,
                    price: variation.basePrice
                };
            });

            const sizeToColorMap = {};
            this.mappedVariations.forEach(variation => {
                const { size, color, id } = variation;
                if (!sizeToColorMap[size]) {
                    sizeToColorMap[size] = [];
                }
                sizeToColorMap[size].push({ id, color });
            });

            // Update sizes array with size options for the specific index
            const sizes = Object.keys(sizeToColorMap).map(size => ({ id: size, name: size }));

            // Update colors array with the correct data for the specific index
            const firstSize = sizes[0]?.id;
            const colors = sizeToColorMap[firstSize]?.map(color => ({ id: color.color, name: color.color })) || [];

            // Save the sizes and colors in your component, associated with the specific index
            this.sizes[index] = sizes;
            this.colors[index] = colors;

            // Manually trigger change detection if needed
            this.cd.detectChanges();
        });
    }


    selectOptionProduct(option: products, index: number): void {

        if (option && !option.isOutOfStock) {
            const productFormGroup = this._formBuilder.group({
                id: [''],
                product: [option, [Validators.required]],
                
                size: [],
                color: [],
            });
            this.filtersOptionsProduct(option.id, index)


            const productArray = this.vendorsCredit.get('products') as FormArray;
     
        }
    }


    
    displayProductOption(product: productVaraitons): string {
        if (product) {
            const productName = product.productName || '';
            const productPrice = product.sku
                ? ' - ' + product.sku.toString()
                : '' + product.productName.toString();

            return productName + productPrice;
        }
        return '';
    }


    removeProductField(index: number): void {
        const productArray = this.vendorsCredit.get('products') as FormArray;
        this.active[index] = false;
        productArray.removeAt(index);


    }

    add(event: MatChipInputEvent): void {
        const value = (event.value || '').trim();

        // Add our fruit
        if (value) {
            this.prodcutsCtr.push(value);
        }

        // Clear the input value
        event.chipInput!.clear();

        this.categoriesCTR.setValue(null);
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        this.prodcutsCtr.push(event.option.viewValue);
        this.fruitInput.nativeElement.value = '';
        this.categoriesCTR.setValue(null);
    }

    private _filter(value: string): string[] {
        const filterValue = value.toLowerCase();

        return this.allproducts.filter((fruit) =>
            fruit.toLowerCase().includes(filterValue)
        );
    }

    selectChange = (event: any) => {
        const key: string = event.key;
        this.cardValue[key] = [...event.data];
    };

    openProductDetails(): void {
        const dialogRef = this.dialog.open(ProductPopupComponent, {
            width: '500px', // Adjust the width as per your requirements
            // Pass the selected vendor as data to the modal
        });

        dialogRef.afterClosed().subscribe((result) => {
            // Handle any actions after the modal is closed
        });
    }
    onThumbnailChange(event: any) {
        const files = event.target.files;
        if (files && files.length) {
            const reader = new FileReader();
            reader.onload = () => {
                this.thumbnailURL = reader.result as string;
            };
            reader.readAsDataURL(files[0]);
        }
    }

    onImagesChange(event: any) {
        const files = event.target.files;
        if (files && files.length) {
            for (let i = 0; i < files.length; i++) {
                const reader = new FileReader();
                reader.onload = () => {
                    this.imageUrls.push(reader.result as string);
                };
                reader.readAsDataURL(files[i]);
            }
        }
    }

    openVendorDetails() { }

    private scrollToMessages(): void {
        const messagesElement =
            this.elementRef.nativeElement.querySelector('#messages');
        if (messagesElement) {
            messagesElement.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    sumbitsizeInfo() {
        // console.log(this.vendorsCredit.value, 'category');

        if (this.vendorsCredit.valid) {
            const formData = new FormData();

            if (this.thumbnailFile instanceof File) {
                formData.append(
                    'thumbnail',
                    this.thumbnailFile,
                    this.thumbnailFile.name
                );
            }

            for (const key of Object.keys(this.vendorsCredit.value)) {
                const formValue = this.vendorsCredit.value[key];

                if (Array.isArray(formValue)) {
                    for (const file of formValue) {
                        if (file instanceof File) {
                            formData.append(key, file, file.name);
                        }
                    }
                } else if (formValue instanceof File) {
                    formData.append(key, formValue, formValue.name);
                } else {
                    formData.append(key, formValue);
                }
            }
            const customerData = { ...this.vendorsCredit.value };
            const products = this.vendorsCredit.get('products') as FormArray;
            const updatedProducts = products.value.map((item: any) => ({
                product: item.id,
                size: item.size,
                color: item.color,

            }));

            customerData.products = updatedProducts;
            this.productService
                .createproductss(customerData)
                .then((response) => {
                    if (response.success) {
                        const Toast = Swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener(
                                    'mouseenter',
                                    Swal.stopTimer
                                );
                                toast.addEventListener(
                                    'mouseleave',
                                    Swal.resumeTimer
                                );
                            },
                        });

                        Toast.fire({
                            icon: 'success',
                            title: `Product Categories created!!`,
                        });

                        this.vendorsCredit.reset();
                        this._router.navigate(['../'], {
                            relativeTo: this._activatedRoute,
                        });
                        // console.log(response, 'response');
                    } else {
                        const errorMessages = response.errors;
                        // Handle API errors here
                        // Display error messages or perform necessary actions
                        // For example, you can show a toast message with the error details
                        const Toast = Swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener(
                                    'mouseenter',
                                    Swal.stopTimer
                                );
                                toast.addEventListener(
                                    'mouseleave',
                                    Swal.resumeTimer
                                );
                            },
                        });

                        Toast.fire({
                            icon: 'error',
                            title: 'Error',
                            text: JSON.stringify(errorMessages),
                        });
                    }
                })
                .catch((error) => {
                    // Hide the loading indicator
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener(
                                'mouseenter',
                                Swal.stopTimer
                            );
                            toast.addEventListener(
                                'mouseleave',
                                Swal.resumeTimer
                            );
                        },
                    });

                    Toast.fire({
                        icon: 'error',
                        title: `Error creating Product: ${error.message}`,
                    });
                });
        } else {
            // Form is not valid, handle accordingly
            // For example, you can show a toast message or perform other actions
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer);
                    toast.addEventListener('mouseleave', Swal.resumeTimer);
                },
            });

            Toast.fire({
                icon: 'error',
                title: 'Invalid form data',
                text: 'Please fill in all the required fields correctly.',
            });
        }
    }
}
