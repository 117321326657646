<mat-dialog-content [class.shake-top]="isShaking" #container class="dialog-content">
  <!-- Your dialog content goes here -->
  <form [formGroup]="productOption_popup" (ngSubmit)="submitForm(productOption_popup.value)">

    <div>


      <div class="flex flex-col mt-2 p-8 pb-5  bg-card rounded  overflow-hidden">
        <p class="text-lg font-medium mb-1" style="font-family:Montserrat;">Add Product Option</p>
        <p class="text-secondary font-small" style="font-family: Montserrat;">
          Product options are different versions of a single product, in different sizes
          and colors.
        </p>
        <mat-divider class="mt-3 mb-3"></mat-divider>

        <div class="flex">
          <mat-form-field style="max-width: 500px" class="flex-auto">
            <mat-label style="font-family: Montserrat;">Select an option</mat-label>
            <mat-select style="font-family: Montserrat;" name="option_name" [formControlName]="'option_name'"
              (selectionChange)="pointOfsales()">
              <mat-option style="font-family: Montserrat;"
                [disabled]="optionSubmissionService.isOptionSubmitted('Size')" value="Size">Size</mat-option>
              <mat-option style="font-family: Montserrat;" value="Color"
                [disabled]="optionSubmissionService.isOptionSubmitted('Color')">Color
              </mat-option>
            </mat-select>

          </mat-form-field>
          <div>
            <mat-button-toggle-group name="fontStyle" aria-label="Font Style">

            </mat-button-toggle-group>

          </div>

        </div>


        <div class="flex">

          <mat-form-field *ngIf="showColor" class="example-chip-list">
            <mat-label style="font-family: Montserrat;">Type in Color for this option </mat-label>
            <mat-chip-list #chipList aria-label="color selection">
              <mat-chip *ngFor="let color of colors" (removed)="remove(color)" class="custom-chip">
                {{ color.name}}
                <button matChipRemove [attr.aria-label]="'remove ' + color">
                  <mat-icon [svgIcon]="'feather:x-circle'"></mat-icon>
                </button>
              </mat-chip>

              
              <input required style="font-size:small;font-family:Montserrat;" placeholder="Select color..." #colorInput
                [formControl]="colorsCtrl" [matAutocomplete]="auto" [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="false"
                
                >
              

            </mat-chip-list>
            <div class="autocomplete-container">

              <mat-autocomplete #auto="matAutocomplete" debug="true" (optionSelected)="onOptionSelected($event)">
                <div cdkScrollable [cdkScrollable] style="max-height: 200px; overflow-y: auto;">
                  <mat-option style="font-size:small;font-family:Montserrat;"
                    *ngFor="let color of filteredcolors | async" [value]="color" [disabled]="isColorDisabled(color)">
                    {{ color.name }}
                  </mat-option>


                </div>
              </mat-autocomplete>
            </div>
          </mat-form-field>


        </div>



        <div class="flex">
          <mat-form-field *ngIf="showSizes" class="example-chip-list">
            <mat-label style="font-family: Montserrat;">Type in Sizes for this option </mat-label>
            <mat-chip-list #chipList aria-label="color selection">
              <mat-chip *ngFor="let sizes of sizes" (removed)="removeSizes(sizes)" class="custom-chip">
                {{ sizes.name }}
                <button matChipRemove [attr.aria-label]="'remove ' + sizes">
                  <mat-icon [svgIcon]="'feather:x-circle'"></mat-icon>
                </button>

              </mat-chip>
              <!-- For Sizes -->
              <input  [ngClass]="formFieldHelpers" required style="font-size:small;font-family:Montserrat;" placeholder="New sizes..." #sizesInput
                [formControl]="sizesCtrl" [matAutocomplete]="auto1" [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="false">

            </mat-chip-list>
            <mat-autocomplete  #auto1="matAutocomplete" (optionSelected)="selectedSizes($event)">
              <div cdkScrollable [cdkScrollable] style="max-height: 200px; overflow-y: auto;">

                <mat-option  style="font-size:small;font-family:Montserrat;" *ngFor="let sizes of filteredsizes | async"
                  [value]="sizes" [disabled]="isSizeDisabled(sizes)">
                  {{ sizes.name }}
                </mat-option>
              </div>
            </mat-autocomplete>
          </mat-form-field>

        </div>



        <div class="flex items-center justify-end mt-10">
          <button class="px-6 ml-3" mat-flat-button type="submit" color="primary">
            Save
          </button>
          <button (click)="cancel()" class="px-6 ml-3" mat-flat-button type="button" color="error">
            Cancel
          </button>
        </div>
      </div>
    </div>

  </form>
</mat-dialog-content>