<!-- THis is up  -->


<div *ngIf="vendor" class="mt-15 ml-15 md:ml-50 lg:ml-90">


    <div class="absolute inset-0 min-w-0 text-center overflow-auto print:overflow-visible" cdkScrollable>


        <div class="ml-10 mt-5 mr-10">
            <mat-accordion>
              <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div class="justify-self-end font-medium tracking-tight text-secondary">
                      Approved - {{ vendor.is_approved }}
                      <span style="color:rgb(53, 139, 87)"></span>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
          
                <!-- Table Layout -->
                <table class="custom-table">
                  <tr>
                    <th>Approved By</th>
                    <td> {{ approved_user }}</td>
                  </tr>
                  <tr>
                    <th>Approved Date</th>
                    <td> {{ approved_expense_date }}</td>
                  </tr>
                  <tr>
                    <th>Approved Payment Method</th>
                    <td> {{ approved_payment_method }}</td>
                  </tr>
                </table>
              </mat-expansion-panel>
            </mat-accordion>
          </div>



        <div class="w-full inline-block p-6 sm:p-10 text-left print:p-0">
            <!-- Invoice -->
            <div
                class="w-full p-16 rounded-2xl shadow bg-card print:w-auto print:p-0 print:rounded-none print:shadow-none print:bg-transparent">
                <div class="flex items-center">
                    <div class="grid grid-rows-2 place-items-center">
                        <div class="grid-cols-2 place-items-center">
                            <button mat-button type="button" matTooltip="Close" class="hide-on-print"
                                (click)="goBack()">
                                <span class="inline-flex items-center">
                                    <mat-icon svgIcon="feather:x"></mat-icon>
                                </span>
                            </button>
                            <button mat-button type="button" class="hide-on-print" (click)="printPage()">
                                <span class="inline-flex items-center">
                                    <mat-icon svgIcon="feather:file"></mat-icon>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
                <!-- Main -->
                <div class="flex items-start">
                    <div class="grid grid-rows-2 place-items-start">
                        <div class="grid auto-cols-max grid-flow-col gap-x-5">
                            <div class="pl-4text-md">
                                <a [routerLink]="['/apps/vendors', vendor.vendor.id]" class="font-medium">
                                    VHUB{{vendor.vendor.id}}
                                </a>


                                <div class="font-medium"> {{vendor.vendor.contact_person}} - {{ vendor.vendor.firstName
                                    }} {{ vendor.vendor.lastName
                                    }}
                                </div>


                                <div>{{vendor.vendor.company_address }}</div>
                                <div>{{vendor.vendor.phoneNumbers }}</div>

                                <div *ngFor="let email of vendor.vendor.emails">
                                    <div>{{ email.email}}</div>
                                </div>
                                <div *ngFor="let email of vendor.vendor.billingAddress">
                                    <div>{{email.type}} {{email.city}} {{ email.address}}</div>
                                </div>


                            </div>

                        </div>
                        <div class="mt-10 text-xl tracking-light">About Payment</div>
                        <div class="text-sm text-secondary">
                            <label class="font-bold text-primary">Payment Type: </label><br>
                            <span *ngIf="vendor.expense_type" class="font-semibold"> {{ vendor.expense_type | uppercase
                                }}</span>
                            <span *ngIf="!vendor.expense_type && vendor.otherExpenseType" class="font-semibold">{{
                                vendor.otherExpenseType | uppercase }}</span>
                            <span *ngIf="!vendor.expense_type && !vendor.otherExpenseType"
                                class="font-semibold text-red-500">Not Specified</span>
                        </div>

                        <div class="mt-2">
                            <span class="font-bold text-primary">
                             Description:<br>
                            </span>
                            <span class="font-semibold">
                                {{ vendor.expense_description }}
                            </span>
                        </div>


                        <!-- Image preview card -->
                        <div class="mt-4 bg-white p-4 rounded shadow-md ">
                            <label class="block font-medium text-gray-700 mb-2">Bill Image</label>
                            <p class="text-sm font-medium text-gray-700">Image Preview:</p>

                            <div class="w-50 h-50 overflow-hidden bg-gray-100 flex items-center justify-center">
                                <img [src]="thumbnail" alt="Image Preview"
                                    class="max-h-full max-w-full object-contain cursor-pointer"
                                    (click)="toggleFullScreen($event)">
                            </div>
                        </div>

                    </div>
                    <div class="grid grid-cols-2 gap-x-4 gap-y-1 ml-auto">
                        <div class="justify-self-end text-4xl tracking-tight text-secondary">Vendor Payment</div>
                        <div class="text-4xl">{{ vendor.expense_number }}</div>
                        <div class="justify-self-end font-medium tracking-tight text-secondary">Created Date</div>
                        <div class="font-medium">{{ vendor.expense_date }}</div>
                        <div class="justify-self-end font-medium tracking-tight text-secondary">Total</div>
                        <div class="font-medium">रू {{ vendor.total }}</div>
                        <div class="justify-self-end font-medium tracking-tight text-secondary">Expense Head</div>
                        <div class="font-medium">{{ vendor.expense_head }}</div>
                        <div class="justify-self-end font-medium tracking-tight text-secondary">Expense Category</div>
                        <div class="font-medium">{{ vendor.expense_category }}</div>
                        <div class="justify-self-end font-medium tracking-tight text-secondary">Payment Method</div>
                        <div class="font-medium">{{ vendor.payment_method | uppercase }}</div>

                        <!-- Credit Tag -->
                        <div *ngIf="vendor.is_credit" class="justify-self-end font-medium tracking-tight text-red-500">
                            Credit
                        </div>
                        <div *ngIf="vendor.is_credit" class="font-medium text-red-500">
                            Credit Valid Date: {{ vendor.credit_valid_date }}
                        </div>
                    </div>
                </div>
                <div class="mt-16">
                    <div class="flex items-start mt-4">
                        <img class="flex-0 w-20 mt-2" src="assets/images/logo/logo.png">
                        <div class="ml-6 mt-8 text-sm text-secondary">
                            <i>
                                {{ vendor.expense_number }} - @{{ vendor.created_by.username }} -- {{
                                vendor.expense_date }}
                            </i>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
</div>