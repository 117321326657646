<div class="sm:absolute sm:inset-0 flex flex-col flex-auto min-w-0 sm:overflow-hidden bg-card dark:bg-transparent">
    <!-- Header -->
    <div
        class="relative flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between py-8 px-6 md:px-8 border-b">

        <div class="absolute inset-x-0 bottom-0" *ngIf="isLoading">
            <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
        </div>

        <div class="text-4xl font-bold tracking-tight " style='font-family:"Montserrat";'>
            Sale Logs

        </div>

        <div class="flex flex-shrink-0 items-center mt-6 sm:mt-0 sm:ml-4">




            <button [color]="'primary'" class="ml-2" mat-flat-button (click)="multipleDelivery()">
                <mat-icon matTooltip="Multiple Delivery" [svgIcon]="'heroicons_outline:truck'"></mat-icon>
                <span class="ml-2 mr-1">Delivery </span>
            </button>


            <button [color]="'primary'" class="ml-2" mat-flat-button (click)="cancleShippingMultiple()">
                <mat-icon matTooltip="Multiple cancellation" [svgIcon]="'heroicons_outline:x-circle'"></mat-icon>
                <span class="ml-2 mr-1">Cancel </span>
            </button>

        </div>

    </div>
    <div class="flex flex-shrink-0 items-center mt-6 sm:mt-0 sm:ml-4">


        <div>


            <button [matMenuTriggerFor]="menu" mat-flat-button [color]="'primary'">
                Action
                <mat-icon svgIcon="feather:chevron-down"></mat-icon>
            </button>

            <mat-menu #menu>
                <button (click)="DeliverySheet()" class="text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                    mat-menu-item>
                    Download Excel </button>


            </mat-menu>
        </div>

        <div class="flex flex-shrink-0 items-center ml-3 mt-3">
            <mat-form-field class="fuse-mat-rounded fuse-mat-emphasized-affix">
                <input matInput [matDatepicker]="picker" placeholder="Select a date" [(ngModel)]="selectedDate"
                    (ngModelChange)="applyFilters()">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>



            <mat-form-field style="min-width: 200px;" class="flex-auto ml-3 fuse-mat-rounded fuse-mat-emphasized-affix">
                <mat-select [(ngModel)]="selectedShippingArea" (ngModelChange)="applyFilters()"
                    placeholder="Shipping Area">
                    <mat-option value="">----</mat-option>
                    <mat-option value="Inside Valley">INSIDE VALLEY</mat-option>
                    <mat-option value="Outside Valley">OUTSIDE VALLEY</mat-option>
                    <mat-option value="International">INTERNATIONAL</mat-option>
                </mat-select>
                <mat-icon class="icon-size-4" matPrefix [svgIcon]="'feather:globe'"></mat-icon>
            </mat-form-field>

            <mat-form-field style="min-width: 200px;" class="flex-auto ml-3 fuse-mat-rounded fuse-mat-emphasized-affix">
                <mat-select [(ngModel)]="selectedDeliveryType" (ngModelChange)="applyFilters()"
                    placeholder="Delivery Type">
                    <mat-option value="">----</mat-option>
                    <mat-option value="Normal">Normal</mat-option>
                    <mat-option value="Express">Express</mat-option>
                </mat-select>

                <mat-icon class="icon-size-4" matPrefix [svgIcon]="'feather:truck'"></mat-icon>
            </mat-form-field>

            <mat-form-field style="min-width: 200px;" class="flex-auto ml-3 fuse-mat-emphasized-affix fuse-mat-rounded">
                <mat-select [(ngModel)]="selectedStatusType" (ngModelChange)="applyFilters()" placeholder="Status">
                    <mat-option value="">----</mat-option>
                    <mat-option value="Delivered">Delivered</mat-option>
                    <mat-option value="Shipped">Shipped</mat-option>
                    <mat-option value="Started">Started</mat-option>


                </mat-select>

                <mat-icon class="icon-size-4" matPrefix [svgIcon]="'feather:info'"></mat-icon>
            </mat-form-field>
            <mat-form-field style="min-width: 200px;" class="flex-auto ml-3 fuse-mat-emphasized-affix fuse-mat-rounded">
                <mat-select [(ngModel)]="selectedType" (ngModelChange)="applyFilters()" placeholder="Type">
                    <mat-option value="">----</mat-option>
                    <mat-option value="SalesOrder">Sales Order</mat-option>
                    <mat-option value="Exchanged">Exchanged</mat-option>
                    <mat-option value="Partial Exchange">Partial Exchange</mat-option>
                    <mat-option value="CreditNotes">Credit Notes</mat-option>
                    <mat-option value="PreOrder">Pre Order</mat-option>
                    <mat-option value="Sales Returned">Sales Returned</mat-option>
                    <mat-option value="Partial Return">Partial Return</mat-option>
                    <mat-option value="Exchange Order">Exchange Order</mat-option>
                    <mat-option value="Sales Return Order">Sales Return Order</mat-option>
                    <mat-option value="Partial Return Order">Partial Return Order</mat-option>
                    <mat-option value="Partial Exchange Order">Partial Exchange Order</mat-option>
                </mat-select>
                <mat-icon class="icon-size-4" matPrefix [svgIcon]="'feather:info'"></mat-icon>
            </mat-form-field>
            <!-- Search Bar -->
            <mat-form-field style="min-width: 200px;" class="flex-auto ml-3 fuse-mat-emphasized-affix fuse-mat-rounded">
                <input matInput placeholder="Search" [(ngModel)]="searchTerm" (ngModelChange)="applyFilters()">
                <mat-icon class="icon-size-4" matPrefix [svgIcon]="'feather:search'"></mat-icon>
            </mat-form-field>

        </div>

    </div>

    <!-- Main -->
    <div class="flex flex-auto overflow-hidden">

        <!-- Products list -->
        <div class="flex flex-col flex-auto sm:mb-18 overflow-hidden sm:overflow-y-auto">
            <div class="mat-elevation-z8">
                <table id="excel-table" #salesOrderTabel mat-table [dataSource]="dataSource" matSort matPaginator
                    class="mat-table">
                    <!-- CustomerName Column -->
                    <ng-container matColumnDef="select">
                        <th mat-header-cell *matHeaderCellDef>
                            <mat-checkbox (change)="$event ? toggleAllRows() : null"
                                [checked]="selection.hasValue() && isAllSelected()"
                                [indeterminate]="selection.hasValue() && !isAllSelected()"
                                [aria-label]="checkboxLabel()">
                            </mat-checkbox>
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <mat-checkbox (click)="$event.stopPropagation()"
                                (change)="$event ? updateSelectedRowIds(row) : null"
                                [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
                            </mat-checkbox>
                        </td>
                    </ng-container>


                    <ng-container matColumnDef="SaleNumber">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            S.N
                        </th>
                        <mat-icon svgIcon="heroicons_outline:user"></mat-icon>
                        <div></div>
                        <td mat-cell *matCellDef="let row">
                            <i><u>{{ row.saleNumber }}</u></i>
                        </td>
                    </ng-container>




                    <ng-container matColumnDef="CustomerName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Name
                        </th>
                        <td mat-cell *matCellDef="let row" class="cursor-pointer">
                            <div class="avatar" (click)="showDetails(row.id)">

                                {{ row.customerName }}
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="CustomerId">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Customer ID
                        </th>
                        <td mat-cell *matCellDef="let row" class="cursor-pointer">
                            <div class="avatar" (click)="showDetails(row.id)">

                                <strong>
                                    HUB{{ row.customerId }}
                                </strong>
                            </div>
                        </td>
                    </ng-container>


                    <ng-container matColumnDef="Shipping_Date">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Date
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <div class="status">
                                <i><u>
                                        {{ row.shippingDate }}

                                    </u>
                                </i>
                            </div>
                        </td>
                    </ng-container>

                    <!-- Status Column -->
                    <ng-container matColumnDef="Status">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Status
                        </th>
                        <td mat-cell *matCellDef="let row">
                            {{ row.status }}

                            <div *ngIf="row.type === 'SalesOrder'">
                                <span class="chip chip--success">
                                    <i style="font-size: 11px; margin-top: -2px;" matTooltip="SalesOrder"
                                        matTooltipPosition="above" class="chip__label">
                                        <strong>{{ row.type }}</strong>
                                    </i>
                                </span>
                            </div>

                            <div *ngIf="row.type === 'Exchanged'">
                                <span class="chip chip--major-warning">
                                    <i style="font-size: 11px; margin-top: -2px;" matTooltip="Exchanges"
                                        matTooltipPosition="above" class="chip__label">
                                        <strong>{{ row.type }}</strong>
                                    </i>
                                </span>
                            </div>

                            <div *ngIf="row.type === 'Partial Exchange'">
                                <span class="chip chip--major-warning">
                                    <i style="font-size: 11px; margin-top: -2px;" matTooltip="Partial Exchanges"
                                        matTooltipPosition="above" class="chip__label">
                                        <strong>{{ row.type }}</strong>
                                    </i>
                                </span>
                            </div>

                            <div *ngIf="row.type === 'CreditNotes'">
                                <span class="chip chip--warning">
                                    <i style="font-size: 11px; margin-top: -2px;" matTooltip="CreditNotes"
                                        matTooltipPosition="above" class="chip__label">
                                        <strong>{{ row.type }}</strong>
                                    </i>
                                </span>
                            </div>

                            <div *ngIf="row.type === 'PreOrder'">
                                <span class="chip chip--major-warning">
                                    <i style="font-size: 11px; margin-top: -2px;" matTooltip="PreOrder"
                                        matTooltipPosition="above" class="chip__label">
                                        <strong>{{ row.type }}</strong>
                                    </i>
                                </span>
                            </div>
                            <div *ngIf="row.type === 'InfluencerOrder'">
                                <span class="chip chip--major-warning">
                                    <i style="font-size: 11px; margin-top: -2px;" matTooltip="PreOrder"
                                        matTooltipPosition="above" class="chip__label">
                                        <strong>{{ row.type }}</strong>
                                    </i>
                                </span>
                            </div>

                            <div *ngIf="row.type === 'Sales Returned'">
                                <span class="chip chip--error">
                                    <i style="font-size: 11px; margin-top: -2px;" matTooltip="SalesReturned"
                                        matTooltipPosition="above" class="chip__label">
                                        <strong>{{ row.type }}</strong>
                                    </i>
                                </span>
                            </div>

                            <div *ngIf="row.type === 'Partial Return'">
                                <span class="chip chip--error">
                                    <i style="font-size: 11px; margin-top: -2px;" matTooltip="Partial Return"
                                        matTooltipPosition="above" class="chip__label">
                                        <strong>{{ row.type }}</strong>
                                    </i>
                                </span>
                            </div>

                            <div *ngIf="row.type === 'Exchange Order'">
                                <span class="chip chip--major-warning">
                                    <i style="font-size: 11px; margin-top: -2px;" matTooltip="Exchange Order"
                                        matTooltipPosition="above" class="chip__label">
                                        <strong>{{ row.type }}</strong>
                                    </i>
                                </span>
                            </div>

                            <div *ngIf="row.type === 'Sales Return Order'">
                                <span class="chip chip--error">
                                    <i style="font-size: 11px; margin-top: -2px;" matTooltip="Sales Return Order"
                                        matTooltipPosition="above" class="chip__label">
                                        <strong>{{ row.type }}</strong>
                                    </i>
                                </span>
                            </div>

                            <div *ngIf="row.type === 'Partial Return Order'">
                                <span class="chip chip--error">
                                    <i style="font-size: 11px; margin-top: -2px;" matTooltip="Partial Return Order"
                                        matTooltipPosition="above" class="chip__label">
                                        <strong>{{ row.type }}</strong>
                                    </i>
                                </span>
                            </div>

                            <div *ngIf="row.type === 'Partial Exchange Order'">
                                <span class="chip chip--major-warning">
                                    <i style="font-size: 11px; margin-top: -2px;" matTooltip="Partial Exchange Order"
                                        matTooltipPosition="above" class="chip__label">
                                        <strong>{{ row.type }}</strong>
                                    </i>
                                </span>
                            </div>
                        </td>

                    </ng-container>


                    <ng-container matColumnDef="Shipping Area">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Shipping Area
                        </th>
                        <mat-icon svgIcon="heroicons_outline:user"></mat-icon>
                        <div></div>
                        <td mat-cell *matCellDef="let row">
                            {{ row.shipping_area ? row.shipping_area:"--"}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="Shipping Carrier">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Shipping Carrier
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        {{ row.shipping_carrier ? row.shipping_carrier : '--' }}
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div style="font-size: smaller;" *ngIf="row.order_reference_id || row.tracking_code">
                                    <p><strong>Order Reference ID:</strong> {{ row.order_reference_id ?
                                        row.order_reference_id : '--' }}</p>
                                    <p><strong>Tracking Code:</strong> {{ row.tracking_code ? row.tracking_code : '--'
                                        }}</p>
                                </div>
                            </mat-expansion-panel>
                        </td>
                    </ng-container>




                    <ng-container matColumnDef="paid_status">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            <span>Payment Status</span>
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <ng-container *ngIf="row.partial_pay; else showPaidStatus">
                                <span class="chip chip--success">
                                    <i style="font-size: 11px; margin-top: -2px;" matTooltip="Partial Payment"
                                        matTooltipPosition="above" class="chip__label">
                                        <strong>Partial Payment</strong>
                                    </i>
                                </span>
                            </ng-container>
                            <ng-template #showPaidStatus>
                                <ng-container [ngSwitch]="row.paid">
                                    <span *ngSwitchCase="true" class="chip chip--success">
                                        <i style="font-size: 11px; margin-top: -2px;" matTooltip="PAID"
                                            matTooltipPosition="above" class="chip__label">
                                            <strong>PAID</strong>
                                        </i>
                                    </span>
                                    <span *ngSwitchDefault class="chip chip--error">
                                        <i style="font-size: 11px; margin-top: -2px;" matTooltip="NOT PAID"
                                            matTooltipPosition="above" class="chip__label">
                                            <strong>NOT PAID</strong>
                                        </i>
                                    </span>
                                </ng-container>
                            </ng-template>

                        </td>

                    </ng-container>


                    <ng-container matColumnDef="Delivery Type">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Delivery Type
                        </th>
                        <td mat-cell *matCellDef="let row">
                            {{ row.delivery_type ? row.delivery_type: "--" }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="Payment-Type">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Payment Type
                        </th>
                        <td mat-cell *matCellDef="let row">
                            {{ row.payment_type ? row.payment_type: "--" }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="total">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Total Price
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <div class="status">
                                NPR <strong>
                                    {{ row.totalCost }}
                                </strong>
                            </div>
                        </td>
                    </ng-container>

                    <!-- Actions Column -->
                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef>Actions</th>
                        <td mat-cell *matCellDef="let row">
                            <button mat-icon-button matTooltip="View" (click)="showDetails(row.id,row.main_type)">
                                <mat-icon class="icon-size-4" svgIcon="heroicons_outline:eye"></mat-icon>
                            </button>

                        </td>
                    </ng-container>
                    <!-- create    -->
                    <ng-container matColumnDef="create">
                        <th mat-header-cell *matHeaderCellDef>Create</th>
                        <td mat-cell *matCellDef="let row">

                            <button mat-icon-button mat-flat-button *ngIf="row.status !== 'Sales Order Delivered'"
                                [matMenuTriggerFor]="menu"
                                class="rounded-md bg-transparent border-none px-2 hover:bg-gray-100">
                                <mat-icon class="icon-size-4" svgIcon="feather:chevron-down"></mat-icon>
                            </button>

                            <mat-menu #menu class="rounded bg-white flex flex-col items-center shadow-md ">

                                <!-- <button *ngIf="
                                row.status === 'Sales Order Shipped' ||
                                row.status === 'Shipped Exchange Order' ||
                                row.status === 'Sales Order Delivered and Credited'
                                " (click)="PartialPay(row.id)"
                                    class="text-sm text-gray-700 font-medium py-2 px-4 hover:bg-gray-100 transition-colors duration-300 ease-in-out"
                                    mat-menu-item>
                                    Partial Payment
                                </button> -->

                                <button *ngIf="
                                            row.status === 'Sales Order Shipped' ||
                                            row.status === 'Shipped Exchange Order' ||
                                            row.status === 'Sales Order Delivered and Credited'
                                            " (click)="openDeliverypopup(row.rowId,row.saleNumber,row)"
                                    class=" w-full text-sm text-gray-700 font-medium py-2 px-4 hover:bg-gray-100 transition-colors duration-300 ease-in-out"
                                    mat-menu-item>
                                    {{ row.status === 'Sales Order Delivered and Credited' ? "Paid" : "Delivery" }}
                                </button>

                                <button *ngIf="
                                row.status === 'Sales Order Shipped' ||
                                row.status === 'Shipped Exchange Order' ||
                                row.status === 'Sales Order Delivered and Credited'
                                " (click)="singleCancle(row.id,row.main_type,row.payment_type)"
                                    class=" w-full text-sm text-gray-700 font-medium py-2 px-4 hover:bg-gray-100 transition-colors duration-300 ease-in-out"
                                    mat-menu-item>
                                    {{ row.status === 'Sales Order Delivered and Credited' ? "Paid" : "Cancle Shipping"
                                    }}
                                </button>
                            </mat-menu>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>
                <!-- Footer Row for Statistics -->
                <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
                <tr class="total-row">
                    <td colspan="6" class="total-cell">Total:</td>
                    <td class="total-cell"> NPR </td>

                </tr>



                <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>
            </div>
        </div>
    </div>
</div>