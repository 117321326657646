import { ChangeDetectionStrategy, Component, ComponentFactoryResolver, ElementRef, Injector, ViewChild, ViewEncapsulation } from '@angular/core';
import { PrintingService } from './invoice/sales-order-invoice.service';
import { InvoiceComponent } from './invoice/sales-order-invoice';

@Component({
    selector       : 'sales-order',
    templateUrl    : './sales-order.component.html',
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SalesOrderComponent
{
    @ViewChild('printFrame', { static: false }) printFrame: ElementRef;
    private printingService: PrintingService;
    private componentFactoryResolver: ComponentFactoryResolver;
    private injector: Injector;
    /**
     * Constructor
     */
    constructor()
    {
    }

}