<!-- vendorsCredit form -->

<div class="w-full h-full">
    <form [formGroup]="vendorsCredit" class=" rounded shadow-lg h-full" (keydown.enter)="$event.preventDefault()">


        <mat-drawer-container class="flex-auto h-full  dark:bg-transparent">




            <mat-drawer class="w-full md:w-160 dark:bg-gray-900 p-5" [mode]="drawerMode" [opened]="false"
                [position]="'end'" [disableClose]="true" #matDrawer>


                <div class="flex justify-end">
                    <button mat-raised-button (click)="closeDrawer()">
                        <span class="inline-flex items-center">
                            <mat-icon svgIcon="feather:x"></mat-icon>
                        </span>
                    </button>
                </div>
                <div class="flex flex-col mt-8">
                    <p class="text-lg font-medium" style="font-family: Montserrat">
                        Sales Order Information </p>
                    <p class="text-secondary mt-2" style="font-family: Montserrat">

                        The content provided pertains to sales order information. You have the option to maintain the
                        existing details or modify them accordingly for exchange-related information. </p>
                    <div class="text-container"
                        style="display: flex; justify-content: space-between; white-space: nowrap;">
                        <p class="text-secondary mt-3">
                            <span class="icon">
                            </span>
                            Created by: {{salesPerson}}
                        </p>
                        <p class="text-secondary mt-3">
                            <span>
                            </span>
                            Date: {{created}} Time: {{formattedTime}}
                        </p>
                    </div>



                    <mat-divider class="mt-1 mb-10"></mat-divider>

                    <ng-container *ngIf="vendorsCredit">



                        <div class="flex mb-2">
                            <mat-form-field style="max-width: 600px" class="flex-auto">
                                <mat-label>Point of Sales</mat-label>
                                <mat-select [ngClass]="formFieldHelpers" placeholder="Point Of Sales"
                                    (selectionChange)="pointOfsales()" name="pos" [formControlName]="'pos'"
                                    class="elevated-input" required>
                                    <mat-option value="Instagram">INSTAGRAM</mat-option>
                                    <mat-option value="Facebook">FACEBOOK</mat-option>
                                    <mat-option value="Website">WEBSITE</mat-option>
                                    <mat-option value="Store Vist">STORE VISIT</mat-option>
                                </mat-select>
                                <mat-icon class="icon-size-4" matPrefix [svgIcon]="'point_of_sale'"></mat-icon>

                                <mat-error class="m-1 ml-5 mt-2"
                                    *ngIf="vendorsCredit?.get('pos')?.invalid && vendorsCredit?.get('pos')?.touched">
                                    <ng-container *ngIf="vendorsCredit?.get('pos')?.hasError('required')">
                                        POS is required.
                                    </ng-container>
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="flex mb-2" *ngIf="showSocailLink">
                            <mat-form-field style="max-width: 600px" class="flex-auto">
                                <mat-label>Email</mat-label>
                                <input [ngClass]="formFieldHelpers" matInput placeholder="Email" type="email"
                                    name="email" [formControlName]="'email'" class="elevated-input small-input-text" />
                                <mat-icon class="icon-size-4" matPrefix [svgIcon]="'feather:hash'"></mat-icon>
                            </mat-form-field>
                        </div>

                        <div class="flex mb-2" *ngIf="showEmail">
                            <mat-form-field [ngClass]="formFieldHelpers" style="max-width: 600px" class="flex-auto">
                                <mat-label>Social Link</mat-label>
                                <input matInput placeholder="Social Link" name="social_link"
                                    aria-label="social-media-link" [formControlName]="'soical_link'"
                                    class="elevated-input small-input-text" />
                                <mat-icon class="icon-size-4" matPrefix [svgIcon]="'feather:hash'"></mat-icon>
                            </mat-form-field>
                        </div>

                        <div class="flex mb-2" *ngIf="showPaymentMethod">
                            <mat-form-field style="max-width: 600px" class="flex-auto">
                                <mat-label>Payment Type </mat-label>
                                <mat-select [ngClass]="formFieldHelpers" placeholder="Payment Method" name="paymentType"
                                    [formControlName]="'payment_type'" class="elevated-input">
                                    <mat-option *ngFor="let option of paymentOptions" [value]="option">{{ option }}
                                    </mat-option>
                                </mat-select>
                                <mat-icon class="icon-size-4" matPrefix [svgIcon]="'payments'"></mat-icon>
                                <mat-error class="m-1 ml-5 mt-2"
                                    *ngIf="vendorsCredit?.get('payment_type')?.invalid && vendorsCredit?.get('payment_type')?.touched">
                                    <ng-container *ngIf="vendorsCredit?.get('payment_type')?.hasError('required')">
                                        Payment Type is required.
                                    </ng-container>
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <!-- Shipping area Form  -->

                        <div class="flex mb-2" *ngIf="showShippingArea">
                            <mat-form-field style="max-width: 600px" class="flex-auto">
                                <mat-label>Shipping Area </mat-label>
                                <mat-select [ngClass]="formFieldHelpers" placeholder="Shipping Area"
                                    name="shipping_area" (selectionChange)="addressField()"
                                    [formControlName]="'shipping_area'" class="elevated-input">
                                    <mat-option [value]="'Inside Valley'">INSIDE VALLEY</mat-option>
                                    <mat-option [value]="'Outside Valley'">OUTSIDE VALLEY</mat-option>
                                    <mat-option [value]="'International'">INTERNATIONAL</mat-option>
                                </mat-select>
                                <mat-icon class="icon-size-4" matPrefix [svgIcon]="'feather:truck'"></mat-icon>

                                <mat-error class="m-1 ml-5 mt-2"
                                    *ngIf="vendorsCredit?.get('shipping_area')?.invalid && vendorsCredit?.get('shipping_area')?.touched">
                                    <ng-container *ngIf="vendorsCredit?.get('shipping_area')?.hasError('required')">
                                        Shipping Area is required.
                                    </ng-container>
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="flex mb-2" *ngIf="selectedShippingType === 'Inside Valley'">
                            <mat-form-field style="max-width: 600px" class="flex-auto">
                                <mat-label>District</mat-label>
                                <mat-select placeholder="Select District" name="District"
                                    [formControl]="districtControl" class="elevated-input small-input-text">
                                    <mat-option *ngFor="let district of ['KATHMANDU', 'LALITPUR', 'BHAKTAPUR']"
                                        [value]="district">
                                        {{ district }}
                                    </mat-option>
                                </mat-select>
                                <mat-icon class="icon-size-4" matPrefix [svgIcon]="'location_city'"></mat-icon>
                            </mat-form-field>

                        </div>


                        <div class="flex mb-2" *ngIf="selectedShippingType == 'Outside Valley'">
                            <mat-form-field style="max-width: 600px" class="flex-auto">
                                <mat-label>District</mat-label>
                                <input matInput placeholder="District" name="District" [formControl]="districtControl"
                                    [readonly]="isFieldReadOnly_outside_val" class="elevated-input small-input-text"
                                    [matAutocomplete]="auto" />

                                <button *ngIf="isFieldReadOnly_outside_val" mat-icon-button
                                    (click)="makeFieldEditable_outside_val()">
                                    <mat-icon class="icon-size-4" svgIcon="feather:edit"></mat-icon>
                                </button>

                                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onDistrictSelected($event)">
                                    <mat-option style="font-family: Montserrat"
                                        *ngFor="let district of filteredDistricts | async"
                                        [value]="district.toUpperCase()">
                                        <span style="font-family: Montserrat">{{ district.toUpperCase() }}</span>
                                    </mat-option>
                                </mat-autocomplete>
                                <mat-icon class="icon-size-4" matPrefix [svgIcon]="'location_city'"></mat-icon>
                            </mat-form-field>

                        </div>


                        <div class="flex mb-2" *ngIf="showDistrict">
                            <mat-form-field style="max-width: 600px" class="flex-auto">
                                <mat-label>Delivery Address</mat-label>
                                <input matInput placeholder="Delivery Address" [formControlName]="'address'"
                                    name="address" class="elevated-input small-input-text"
                                    ngx-google-places-autocomplete [options]="options"
                                    (onAddressChange)="handleAddressChange($event)" value="formattedaddress" />
                                <mat-icon class="icon-size-4" matPrefix [svgIcon]="'location_city'"></mat-icon>
                            </mat-form-field>
                        </div>

                        <div class="flex mb-2">
                            <mat-form-field style="max-width: 600px"
                                class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix">
                                <mat-label class="ml-3">Delivery Number</mat-label>
                                <input required [ngClass]="formFieldHelpers" matInput placeholder="Delivery Number"
                                    name="Delivery Number" [formControlName]="'delivery_number'"
                                    class="elevated-input small-input-text" pattern="^[0-9-]*$"
                                    (input)="onInput($event)" />
                                <mat-icon class="icon-size-4" matPrefix [svgIcon]="'feather:phone'"></mat-icon>
                            </mat-form-field>
                        </div>

                        <div class="flex mb-2" *ngIf="showShippinginvalley">
                            <mat-form-field style="max-width: 600px" class="flex-auto">
                                <mat-label>Shipping Carrier </mat-label>
                                <ng-container
                                    *ngIf="vendorsCredit.get('shipping_carrier_invalley').value !== 'others'; else otherInputss">
                                    <mat-select [ngClass]="formFieldHelpers" placeholder="Shipping Carrier"
                                        name="shipping_carrier_invalley" (selectionChange)="addressField()"
                                        [formControlName]="'shipping_carrier_invalley'" class="elevated-input">
                                        <mat-option value="Dash logistics">DASH</mat-option>
                                        <mat-option value="House of delivery">HOUSE OF DELIVERY</mat-option>
                                        <mat-option value="In Driver">IN DRIVER</mat-option>
                                        <mat-option value="Pathao">PATHAO</mat-option>
                                        <mat-option value="others">OTHERS</mat-option>
                                    </mat-select>

                                </ng-container>

                                <ng-template #otherInputss>
                                    <input [ngClass]="formFieldHelpers" matInput placeholder="Other Shipping Carrier"
                                        formControlName="shipping_carrier_invalley_others"
                                        class="elevated-input small-input-text" name="others" />
                                    <mat-icon-button class="icon-size-4" (click)="toggleInputss()">
                                        <mat-icon [svgIcon]="'feather:skip-back'"></mat-icon>
                                    </mat-icon-button>
                                </ng-template>



                                <mat-icon class="icon-size-4" matPrefix [svgIcon]="'feather:truck'"></mat-icon>

                            </mat-form-field>


                        </div>


                        <div class="flex mb-2" *ngIf="showShippingOutvalley">
                            <mat-form-field style="max-width: 600px" class="flex-auto">
                                <mat-label>Shipping Carrier Out Valley</mat-label>

                                <ng-container
                                    *ngIf="vendorsCredit.get('shipping_carrier_outvalley').value !== 'others'; else otherInputs">
                                    <mat-select [ngClass]="formFieldHelpers" placeholder="shipping Carrier"
                                        name="shipping_carrier_outvalley" (selectionChange)="addressField()"
                                        [formControlName]="'shipping_carrier_outvalley'"
                                        class="elevated-input small-input-text">
                                        <mat-option value="Dash logistics">DASH LOGISTICS</mat-option>
                                        <mat-option value="House of delivery">HOUSE OF DELIVERY</mat-option>
                                        <mat-option value="Courier">COURIER</mat-option>
                                        <mat-option value="others">OTHERS</mat-option>
                                    </mat-select>


                                </ng-container>

                                <ng-template #otherInputs>


                                    <input matInput placeholder="Other Shipping Carrier" formControlName="others_out"
                                        class="elevated-input small-input-text" name="others" />
                                    <mat-icon-button class="icon-size-4" (click)="toggleInputs()">
                                        <mat-icon [svgIcon]="'feather:skip-back'"></mat-icon>
                                    </mat-icon-button>
                                </ng-template>
                            </mat-form-field>
                        </div>



                        <div class="flex mb-2" *ngIf="shwoShippingInternational">
                            <mat-form-field style="max-width: 600px" class="flex-auto">
                                <mat-label>Shipping Carrier International</mat-label>

                                <ng-container
                                    *ngIf="vendorsCredit.get('shipping_carrier_int').value !== 'others'; else otherInput">
                                    <mat-select [ngClass]="formFieldHelpers" placeholder="Shipping Carrier"
                                        name="shipping_carrier_int" formControlName="shipping_carrier_int"
                                        class="elevated-input">
                                        <mat-option value="ARAMEX">ARAMEX</mat-option>
                                        <mat-option value="others">OTHERS</mat-option>
                                    </mat-select>
                                </ng-container>

                                <ng-template #otherInput>

                                    <input [ngClass]="formFieldHelpers" matInput placeholder="Other Shipping Carrier"
                                        formControlName="others" class="elevated-input" name="others" />
                                    <mat-icon-button class="icon-size-4" (click)="toggleInputs()">
                                        <mat-icon [svgIcon]="'feather:skip-back'"></mat-icon>
                                    </mat-icon-button>
                                    <div class="flex mb-2">
                                        <mat-form-field style="max-width: 600px"
                                            class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix">
                                            <mat-label class="ml-3">Exchange Reason</mat-label>
                                            <input matInput placeholder="Reason For Exchange" name="Reson For Retunr"
                                                [formControlName]="'rfr'" class="elevated-input" required />
                                            <mat-icon class="icon-size-4" matPrefix
                                                [svgIcon]="'location_city'"></mat-icon>
                                        </mat-form-field>
                                    </div>
                                </ng-template>

                                <mat-icon class="icon-size-4" matPrefix [svgIcon]="'feather:truck'"></mat-icon>
                            </mat-form-field>
                        </div>



                        <div class="flex mb-2" *ngIf="showOthers">
                            <mat-form-field style="max-width: 600px" class="flex-auto">
                                <mat-label>Others </mat-label>
                                <input [ngClass]="formFieldHelpers" matInput placeholder="Others" name="others"
                                    [formControlName]="'others'" class="elevated-input small-input-text" />
                                <mat-icon class="icon-size-4" matPrefix [svgIcon]="'location_city'"></mat-icon>
                            </mat-form-field>
                        </div>

                        <div class="flex mb-2" *ngIf="showCountry">
                            <mat-form-field style="max-width: 600px" class="flex-auto">
                                <mat-label>Country</mat-label>
                                <input [ngClass]="formFieldHelpers" matInput placeholder="Country" name="country"
                                    [formControl]="countryControl" class="elevated-input small-input-text"
                                    [matAutocomplete]="auto" />
                                <mat-autocomplete #auto="matAutocomplete">
                                    <mat-option style="font-family: Montserrat" *ngFor="
                            let country of filteredCountries | async
                        " [value]="country">
                                        <span style="font-family: Montserrat">{{
                                            country
                                            }}</span>
                                    </mat-option>
                                </mat-autocomplete>
                                <mat-icon class="icon-size-4" matPrefix [svgIcon]="'location_city'"></mat-icon>
                            </mat-form-field>
                        </div>

                        <div class="flex mb-2" *ngIf="showDeliveryDetails">
                            <mat-form-field style="max-width: 600px; margin: 0" class="fuse-mat-textarea flex-auto">
                                <mat-label>Delivery Details</mat-label>
                                <textarea [ngClass]="formFieldHelpers" matInput placeholder="Details"
                                    [formControlName]="'delivery_detail'" name="delivery_detail" [rows]="3"
                                    class="elevated-input small-input-text"></textarea>
                                <mat-icon class="icon-size-4" matPrefix [svgIcon]="'heroicons_solid:menu-alt-2'">
                                </mat-icon>
                            </mat-form-field>
                        </div>



                        <!-- Ends here -->

                        <div class="flex mb-2">
                            <mat-form-field style="max-width: 600px" class="flex-auto">
                                <mat-label>Reference</mat-label>
                                <input [ngClass]="formFieldHelpers" matInput placeholder="Refrences" name="refrences"
                                    [formControlName]="'refrences'" class="elevated-input small-input-text" />
                                <mat-icon class="icon-size-4" matPrefix [svgIcon]="'feather:hash'"></mat-icon>
                            </mat-form-field>
                        </div>
                        <!-- Additional Fields -->


                        <div class="flex mb-2" *ngIf="showExpectedShippingDate">
                            <mat-form-field style="max-width: 600px" class="flex-auto">
                                <mat-label>Expected Shipment Date</mat-label>
                                <input [ngClass]="formFieldHelpers" matInput [matDatepicker]="picker3"
                                    placeholder="Expected Shipment Date" name="expected_shippmentDate"
                                    [formControlName]="'sales_order_shippmentDate'"
                                    class="elevated-input small-input-text" (dateChange)="
                        formatDate(
                            $event.value,
                            'yyyy-MM-dd',
                            'sales_order_shippmentDate'
                        )
                    " />
                                <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                                <mat-datepicker #picker3></mat-datepicker>

                                <mat-icon class="icon-size-4" matPrefix [svgIcon]="'heroicons_solid:calendar'">
                                </mat-icon>
                            </mat-form-field>
                        </div>

                        <div class="flex mb-2" *ngIf="showDeliveryType">
                            <mat-form-field style="max-width: 600px" class="flex-auto">
                                <mat-label>Delivery Type</mat-label>
                                <mat-select [ngClass]="formFieldHelpers" placeholder="Delivery Type" name="Tax"
                                    [formControlName]="'delivery_type'" class="elevated-input" required>
                                    <mat-option value="Normal">NORMAL</mat-option>
                                    <mat-option value="Express">EXPRESS</mat-option>
                                </mat-select>
                                <mat-icon class="icon-size-4" matPrefix [svgIcon]="'delivery_dining'"></mat-icon>
                            </mat-form-field>
                        </div>

                        <div class="flex">
                            <button (click)="patchValues()" style="margin: 5px"
                                class="px-6 py-2 rounded text-white bg-red-500 hover:bg-red-600" mat-flat-button
                                type="submit">
                                Patch
                            </button>
                        </div>



                    </ng-container>
                </div>


            </mat-drawer>

            <mat-drawer-content>


                <!-- Personal Information Form -->
                <div *ngIf="vendorsCredit" class="p-8">
                    <div class="prose prose-sm max-w-3xl">
                        <!-- HTML template -->
                    </div>
                    <div class="flex justify-end">
                        <button mat-raised-button [routerLink]="['..']">
                            <span class="inline-flex items-center">
                                <mat-icon svgIcon="feather:x"></mat-icon>
                            </span>
                        </button>
                    </div>
                    <div class="flex flex-col mt-8">
                        <p class="text-lg font-medium" style="font-family: Montserrat">
                            Basic information
                        </p>
                        <p class="text-secondary" style="font-family: Montserrat">
                            Set your Sales Exchange Detail Here
                        </p>
                        <mat-divider class="mt-8 mb-10"></mat-divider>

                        <div class="flex">

                            <mat-checkbox class="mat-checkbox mb-2 p-1" [ngClass]="{'selected': redelivered}"
                                (change)="toggleFilter()">Re Exchange
                            </mat-checkbox>

                        </div>
                        <ng-container *ngIf="!redelivered">

                            <div class="flex">

                                <mat-form-field style="max-width: 600px"
                                    class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix">
                                    <mat-label class="ml-3">Sales Order Number</mat-label>
                                    <input matInput type="text" placeholder="Select Sales Order Num"
                                        [formControl]="SaleOrderNumberControl" aria-label="SalesOrderNumber"
                                        [matAutocomplete]="auto1" required [readonly]="isFieldReadOnly" />
                                    <button type="button" *ngIf="isFieldReadOnly" mat-icon-button
                                        (click)="makeFieldEditable()">
                                        <mat-icon class="icon-size-4" svgIcon="feather:edit"></mat-icon>
                                    </button>
                                    <button type="button" *ngIf="isFieldReadOnly" class="ml-3"
                                        matTooltip="View Sales Order" (click)="openDrawer()"
                                        style="display: flex; align-items: center;">
                                        <mat-icon class="icon-size-4" svgIcon="heroicons_outline:eye"></mat-icon>
                                    </button>

                                    <mat-autocomplete #auto1="matAutocomplete" [displayWith]="displaySalesOrderOption"
                                        (optionSelected)="
                                selectSalesOrderOption($event.option.value)
                            ">
                                        <mat-option style="height: fit-content" *ngFor="
                                    let salesorder of filteredSalesOrder$
                                        | async
                                " [value]="salesorder">
                                            <div style="display: flex; align-items: center">
                                                <span class="example-option-name">{{
                                                    salesorder.sales_order_num
                                                    }}</span>
                                            </div>
                                        </mat-option>
                                    </mat-autocomplete>
                                    <mat-icon class="icon-size-4" matPrefix [svgIcon]="'feather:hash'"></mat-icon>
                                </mat-form-field>

                            </div>
                        </ng-container>

                        <ng-container *ngIf="redelivered">

                            <mat-form-field style="max-width: 600px"
                                class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix">
                                <mat-label class="ml-3">Re Exchange</mat-label>
                                <input matInput type="text" placeholder="Select Exchange number"
                                    [formControl]="ExchangeNumberControl" aria-label="Exchange Number"
                                    [matAutocomplete]="auto2" [readonly]="isFieldReadOnly" />
                                <button type="button" *ngIf="isFieldReadOnlyExchange" mat-icon-button
                                    (click)="makeFieldEditableExchange()">
                                    <mat-icon class="icon-size-4" svgIcon="feather:edit"></mat-icon>
                                </button>

                                <mat-autocomplete #auto2="matAutocomplete" [displayWith]="displayexchangesOption"
                                    (optionSelected)="selectExchangeOption($event.option.value)">
                                    <mat-option style="height: fit-content" *ngFor="
                                    let exchange of filteredExchangeOrders$
                                        | async
                                " [value]="exchange">
                                        <div style="display: flex; align-items: center">
                                            <span class="example-option-name">{{
                                                exchange.exchange_number
                                                }}</span>
                                        </div>
                                    </mat-option>
                                </mat-autocomplete>
                                <mat-icon class="icon-size-4" matPrefix [svgIcon]="'feather:hash'"></mat-icon>
                            </mat-form-field>
                        </ng-container>

                        <ng-container *ngIf="redelivered">

                            <mat-form-field style="max-width: 600px"
                                class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix">
                                <mat-label class="ml-3">Pre Order</mat-label>
                                <input matInput type="text" placeholder="Select Exchange number"
                                    [formControl]="ExchangeNumberControl" aria-label="Exchange Number"
                                    [matAutocomplete]="auto2" [readonly]="isFieldReadOnly" />
                                <button type="button" *ngIf="isFieldReadOnlyExchange" mat-icon-button
                                    (click)="makeFieldEditableExchange()">
                                    <mat-icon class="icon-size-4" svgIcon="feather:edit"></mat-icon>
                                </button>

                                <mat-autocomplete #auto2="matAutocomplete" [displayWith]="displayexchangesOption"
                                    (optionSelected)="selectExchangeOption($event.option.value)">
                                    <mat-option style="height: fit-content" *ngFor="
                                    let exchange of filteredExchangeOrders$
                                        | async
                                " [value]="exchange">
                                        <div style="display: flex; align-items: center">
                                            <span class="example-option-name">{{
                                                exchange.exchange_number
                                                }}</span>
                                        </div>
                                    </mat-option>
                                </mat-autocomplete>
                                <mat-icon class="icon-size-4" matPrefix [svgIcon]="'feather:hash'"></mat-icon>
                            </mat-form-field>
                        </ng-container>



                        <mat-divider class="mt-2 mb-4"></mat-divider>










                        <div class="flex mb-4">
                            <mat-form-field style="max-width: 600px"
                                class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix">
                                <mat-label class="ml-3">Customer</mat-label>
                                <input matInput type="text" placeholder="Search Customers"
                                    [formControlName]="'customerControl'" aria-label="Customers" readonly />
                                <mat-icon class="icon-size-4" matPrefix [svgIcon]="'heroicons_solid:user'"></mat-icon>
                            </mat-form-field>
                        </div>




                        <div class="flex flex-col gt-xs:flex-row">
                            <mat-form-field class="flex-auto gt-xs:pl-3 fuse-mat-rounded fuse-mat-emphasized-affix"
                                style="max-width: 600px">
                                <mat-label class="ml-3">Choose a date</mat-label>
                                <input matInput [matDatepicker]="picker" [max]="maxDate" formControlName="date"
                                    name="date" />
                                <mat-hint class="ml-3">MM/DD/YYYY</mat-hint>
                                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                        </div>

                        <ng-container *ngIf="enablePartialExchange" class="flex mb-4">
                            <mat-checkbox formControlName="partial_exchange">Enable Partial Exchange</mat-checkbox>
                        </ng-container>


                        <mat-divider class="mt-8 mb-3"></mat-divider>


                        <ng-container class="flex mb-4 mt-2" formArrayName="saleProduct">
                            <ng-container *ngFor="
                            let product of vendorsCredit.get('saleProduct')['controls'];
                            let i = index;
                            let first = first;
                            let last = last;
                            trackBy: trackByFn
                        ">

                                <div class="flex mb-2" style="padding: auto" [formGroupName]="i">
                                    <ng-container *ngIf="vendorsCredit.get('partial_exchange').value" class="flex mt-4">
                                        <mat-checkbox formControlName="is_selected"
                                            (change)="calculateTotalApplied()"></mat-checkbox>
                                    </ng-container>
                                    <mat-form-field class="fuse-mat-no-subscript fuse-mat-rounded w-200 mr-2">
                                        <mat-label class="ml-3" *ngIf="first">Product</mat-label>
                                        <input style="height: 50px" matInput formControlName="sale_product"
                                            placeholder="Type SKU or Product Name" readonly />
                                    </mat-form-field>
                                    <mat-form-field
                                        class="fuse-mat-no-subscript w-75 fuse-mat-rounded fuse-mat-emphasized-affix mr-2">
                                        <mat-label class="ml-3" *ngIf="first">Color</mat-label>
                                        <input style="height: 50px" matInput formControlName="sale_color"
                                            placeholder="Color" readonly />

                                        <mat-icon class="icon-size-4" matPrefix svgIcon="color_lens"></mat-icon>

                                    </mat-form-field>
                                    <mat-form-field
                                        class="fuse-mat -no-subscript w-75 fuse-mat-rounded fuse-mat-emphasized-affix mr-2">
                                        <mat-label class="ml-3" *ngIf="first">Size</mat-label>
                                        <input style="height: 50px" matInput formControlName="sale_size"
                                            placeholder="Size" readonly />
                                        <mat-icon class="icon-size-4" matPrefix svgIcon="format_size"></mat-icon>

                                    </mat-form-field>


                                    <mat-form-field class="w-50 fuse-mat-rounded fuse-mat-emphasized-affix mr-2">
                                        <mat-label class="ml-3" *ngIf="first">Quantity</mat-label>
                                        <mat-icon matPrefix svgIcon="mat_solid:production_quantity_limits"></mat-icon>
                                        <input style="height: 50px" matInput type="text"
                                            onkeypress="return event.charCode>=48 && event.charCode<=57"
                                            formControlName="sale_quantity" (change)="validateQuantity(i)"
                                            placeholder="Quantity" />
                                        <mat-error
                                            *ngIf="product.get('sale_quantity').hasError('invalidQuantity')">Invalid
                                            quantity.</mat-error>
                                        <mat-error *ngIf="product.get('sale_quantity').hasError('exceedStock')">Quantity
                                            cannot exceed current stock.</mat-error>
                                        <mat-error
                                            *ngIf="product.get('sale_quantity').hasError('negativeQuantity')">Quantity
                                            cannot be less than zero.</mat-error> </mat-form-field>

                                    <mat-form-field [ngClass]="formFieldHelpers"
                                        class="fuse-mat-no-subscript w-75 fuse-mat-rounded  fuse-mat-emphasized-affix mr-2">
                                        <mat-label class="ml-3" *ngIf="first">Rate</mat-label>

                                        <input style="height: 50px" matInput type="text"
                                            onkeypress="return event.charCode>=48 && event.charCode<=57"
                                            formControlName="sale_rate" placeholder="Rate" readonly />
                                        <span matPrefix>रु</span>
                                    </mat-form-field>

                                    <mat-form-field [ngClass]="formFieldHelpers"
                                        class="fuse-mat-no-subscript w-75 fuse-mat-rounded fuse-mat-emphasized-affix mr-2">
                                        <mat-label class="ml-3" *ngIf="first">Amount</mat-label>
                                        <input style="height: 50px" matInput type="text"
                                            onkeypress="return event.charCode>=48 && event.charCode<=57"
                                            formControlName="sale_amount" placeholder="Amount" readonly />
                                        <span matPrefix>रु</span>
                                    </mat-form-field>

                                </div>
                            </ng-container>
                        </ng-container>

                        <mat-divider class="mt-6 mb-3"></mat-divider>

                        <mat-form-field style="max-width:500px;" class="fuse-mat-rounded fuse-mat-emphasized-affix">
                            <mat-label class="ml-3">Total Amount</mat-label>
                            <input style="height: 50px;" matInput type="text"
                                onkeypress="return event.charCode>=48 && event.charCode<=57"
                                formControlName="totalAmount" placeholder="Total Amount" readonly />
                            <span matPrefix>रु</span>
                            <span matSuffix>.00</span>
                        </mat-form-field>

                        <mat-divider class="mt-8 mb-3"></mat-divider>
                        <span class="text-secondary mt-5">Choose a Product for Exchange</span>
                        <mat-divider class="mt-7 mb-3"></mat-divider>

                        <ng-container
                            *ngFor="let product of vendorsCredit.get('products')['controls']; let i = index; let first = first; let last = last; trackBy: trackByFn">


                            <div class="flex" [ngClass]="{'fadeInOut': animationFlags[i]}">
                                <!-- Log the values -->


                                <ng-container *ngIf="filterByBundle[i]">
                                    <mat-form-field
                                        class="fuse-mat-no-subscript w-200 fuse-mat-rounded fuse-mat-emphasized-affix ">

                                        <mat-label *ngIf="first">Product Bundle</mat-label>
                                        <mat-error *ngIf="match_stock[i]">
                                            No stock </mat-error>
                                        <input [readonly]="active[i]" class="small-input-text" style="height: 50px"
                                            matInput [formControl]="product.get('product')"
                                            placeholder="Type  Bundle Name" required [matAutocomplete]="auto"
                                            [ngStyle]="product.style" [attr.disabled]="product.disabled ? true : null"
                                            (input)="
                                        filter($event.target.value)
                                    " (focus)="filterProductBundle($event.target.value)" />

                                        <mat-icon *ngIf="active[i]" class="icon-size-4" matSuffix [svgIcon]="'verified'"
                                            style="font-size: 24px; color: green;"></mat-icon>


                                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="
                                displayProductBundleOption.bind(this)
                                        " (optionSelected)="
                                        selectOptionProductBundle(
                                                $event.option.value,
                                                i
                                            )
                                        ">
                                            <mat-option
                                                style="height: auto; display: flex; align-items: center; padding: 4px 16px;"
                                                *ngFor="let option of filteredProductBundleOptions$ | async; let i = index"
                                                [value]="option" [ngStyle]="option.style" [disabled]="option.disabled">

                                                <div style="
                                                    display: flex;
                                                    align-items: center;
                                                ">

                                                    <span class="example-option-name">{{
                                                        option.name
                                                        }}</span>
                                                    <span class="example-option-sku">
                                                        - {{ option.description }}</span>
                                                </div>
                                            </mat-option>

                                            <mat-option style="
                                                cursor: pointer;
                                                height: fit-content;
                                            " (click)="openProductDetails()" *ngIf="
                                                (filteredProductBundleOptions$ | async)
                                                    ?.length === 0
                                            " [disabled]="true">
                                                No options found
                                                <br />
                                                <mat-icon svgIcon="feather:plus">
                                                </mat-icon>
                                                Add Product
                                            </mat-option>
                                        </mat-autocomplete>


                                        <div *ngIf="active[i]" class="current-stock-container m-2">
                                            <span class="chip chip--success" style="line-height: 16px;">

                                                <i style="font-size: 11px; margin-top: -2px;" class="chip__label">
                                                    <strong>Products in bundle: {{ name[i][0] }} , {{ name[i][1] }}
                                                    </strong>


                                                </i>

                                            </span>


                                        </div>

                                    </mat-form-field>


                                </ng-container>




                                <ng-container *ngIf="!filterByBundle[i]">
                                    <mat-form-field
                                        class="fuse-mat-no-subscript w-200 fuse-mat-rounded fuse-mat-emphasized-affix mr-3">

                                        <mat-label *ngIf="first">Product</mat-label>
                                        <mat-error *ngIf="match_stock[i]">
                                            No stock </mat-error>

                                        <input [ngClass]="formFieldHelpers" [readonly]="productActive[i]"
                                            class="small-input-text" style="height: 50px" matInput
                                            [formControl]="product.get('product')"
                                            placeholder="Type SKU or Product Name" required [matAutocomplete]="auto"
                                            [ngStyle]="product.style" [attr.disabled]="product.disabled ? true : null"
                                            (input)="
                                    filterOptionsProduct($event.target.value)"
                                            (focus)="filterOptionsProduct($event.target.value)"
                                            [id]="'product-input-' + i" />


                                        <mat-icon *ngIf="active[i]" class="icon-size-4" matSuffix [svgIcon]="'verified'"
                                            style="font-size: 24px; color: green;"></mat-icon>


                                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="
                        displayProductOption.bind(this)
                    " (optionSelected)="
                        selectOptionProduct(
                            $event.option.value,
                            i
                        )
                    ">
                                            <mat-option
                                                style="height: auto; display: flex; align-items: center; padding: 4px 16px;"
                                                *ngFor="let option of filteredProductOptions$ | async; let i = index"
                                                [value]="option" [ngStyle]="option.style" [disabled]="option.disabled">

                                                <div style="
                                display: flex;
                                align-items: center;
                            ">

                                                    <span class="example-option-name">{{
                                                        option.productName
                                                        }}</span>
                                                    <span class="example-option-sku">
                                                        - {{ option.sku }}</span>
                                                </div>
                                            </mat-option>

                                            <mat-option style="
                            cursor: pointer;
                            height: fit-content;
                        " (click)="openProductDetails()" *ngIf="
                            (filteredProductOptions$ | async)
                                ?.length === 0
                        " [disabled]="true">
                                                No options found
                                                <br />
                                                <mat-icon svgIcon="feather:plus">
                                                </mat-icon>
                                                Add Product
                                            </mat-option>
                                        </mat-autocomplete>

                                        {{product_details[i]}}




                                        <button type="button" matSuffix *ngIf="productActive[i]" mat-icon-button
                                            (click)="makeFieldEditableProduct(i)">


                                            <mat-icon *ngIf="productActive[i]" class="icon-size-5"
                                                [svgIcon]="'feather:edit'"
                                                style="font-size: 24px; color: red;"></mat-icon>
                                        </button>
                                    </mat-form-field>


                                </ng-container>

                                <ng-container *ngIf="!active[i]">
                                    <mat-form-field
                                        class="fuse-mat-no-subscript w-75 fuse-mat-rounded fuse-mat-emphasized-affix mr-3">
                                        <mat-label *ngIf="first">Size</mat-label>
                                        <mat-icon class="icon-size-4" matPrefix svgIcon="format_size"></mat-icon>
                                        <mat-error *ngIf="match_stock[i]">No stock</mat-error>
                                        <mat-select matInput [formControl]="product.get('size')" placeholder="Size"
                                            class="h-20" (selectionChange)="onSelectionChange(i, true)">
                                            <mat-option *ngFor="let size of sizes[i]" [value]="size.id"
                                                [disabled]="size.disabled">{{ size.name }}</mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="match_stock[i]">No stock</mat-error>
                                    </mat-form-field>

                                    <mat-form-field
                                        class="fuse-mat-no-subscript w-75 fuse-mat-rounded fuse-mat-emphasized-affix mr-3">
                                        <mat-label *ngIf="first">Color</mat-label>
                                        <mat-icon class="icon-size-4" matPrefix svgIcon="color_lens"></mat-icon>
                                        <mat-error *ngIf="match_stock[i]">No stock</mat-error>
                                        <mat-select matInput [formControl]="product.get('color')" placeholder="Color"
                                            class="h-20" (selectionChange)="onSelectionChange(i, true)">
                                            <mat-option *ngFor="let color of colors[i]" [value]="color.id"
                                                [disabled]="color.disabled">{{ color.name }}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </ng-container>


                                <ng-container *ngIf="active[i]">
                                    <!-- Render a readonly input or display the selected value when active is true -->
                                    <mat-form-field
                                        class="fuse-mat-no-subscript w-75 fuse-mat-rounded fuse-mat-emphasized-affix mr-3">
                                        <mat-icon class="icon-size-4" matPrefix svgIcon="format_size"></mat-icon>

                                        <mat-label *ngIf="first">Size</mat-label>
                                        <input class="small-input-text" matInput [formControl]="product.get('size')"
                                            placeholder="Size" style="height: 50px" class="small-input-text" readonly />


                                    </mat-form-field>

                                    <mat-form-field class="fuse-mat-no-subscript w-75 mr-3">
                                        <mat-label *ngIf="first">Color</mat-label>
                                        <mat-icon class="icon-size-4" matPrefix svgIcon="color_lens"></mat-icon>

                                        <input class="small-input-text" matInput [formControl]="product.get('color')"
                                            placeholder="Color" style="height: 50px" readonly />
                                    </mat-form-field>
                                </ng-container>




                                <div class="form-field-container">
                                    <mat-form-field class=" w-25 fuse-mat-rounded fuse-mat-emphasized-affix mr-3">
                                        <mat-label *ngIf="first">Quantity</mat-label>

                                        <mat-icon class="icon-size-4" matPrefix
                                            svgIcon="mat_solid:production_quantity_limits">
                                        </mat-icon>
                                        <input class="small-input-text" style="height: 50px" matInput type="text"
                                            onkeypress="return event.charCode>=48 && event.charCode<=57"
                                            [formControl]="product.get('quantity')" placeholder="Quantity"
                                            [max]="current_stock[i]" />

                                        <mat-error *ngIf="quantityExceedsStock(i)">
                                            Quantity cannot exceed available stock.
                                        </mat-error>
                                    </mat-form-field>

                                    <!-- Display current_stock below the form field -->
                                    <div *ngIf="active[i]" class="current-stock-container m-2">
                                        <span [ngClass]="{
                                        'chip chip--success': current_stock[i] > 10,
                                        'chip chip--warning': current_stock[i] <= 10 && current_stock[i] > 5,
                                        'chip chip--error': current_stock[i] <= 5
                                    }" style="line-height: 16px;">

                                            <i style="font-size: 11px; margin-top: -2px;" class="chip__label">
                                                <strong>Available stock: {{ current_stock[i] }} </strong>
                                            </i>
                                        </span>
                                    </div>
                                </div>


                                <mat-form-field [ngClass]="formFieldHelpers"
                                    class="fuse-mat-no-subscript w-75 fuse-mat-rounded fuse-mat-emphasized-affix mr-3 ">
                                    <mat-label *ngIf="first">Rate</mat-label>

                                    <input [readonly]="active[i]" class="small-input-text" readonly style="height: 50px"
                                        matInput type="text"
                                        onkeypress="return event.charCode>=48 && event.charCode<=57"
                                        [formControl]="product.get('rate')" placeholder="Rate" />
                                    <span matPrefix>रु</span>
                                </mat-form-field>

                                <mat-form-field [ngClass]="formFieldHelpers"
                                    class="fuse-mat-no-subscript w-75 fuse-mat-rounded fuse-mat-emphasized-affix mr-3">
                                    <mat-label *ngIf="first">Amount</mat-label>
                                    <input readonly class="small-input-text" style="height: 50px" matInput type="text"
                                        onkeypress="return event.charCode>=48 && event.charCode<=57"
                                        [formControl]="product.get('amount')" placeholder="Amount" />
                                    <span matPrefix>रु</span>
                                </mat-form-field>

                                <!-- Remove product -->
                                <ng-container *ngIf="!(first && last)">
                                    <div class="flex items-center w-10 pl-2">
                                        <button mat-icon-button type="button" (click)="removeProductField(i)"
                                            matTooltip="Remove">
                                            <mat-icon class="icon-size-4" [svgIcon]="'heroicons_solid:trash'">
                                            </mat-icon>
                                        </button>
                                    </div>
                                </ng-container>



                            </div>



                        </ng-container>



                        <div class="inline-flex items-center mt-1 -ml-4 py-2 px-4">
                            <mat-icon matPrefix class="hidden sm:flex icon-size-4" [svgIcon]="'heroicons_solid:tag'">
                            </mat-icon>
                            <span (click)="addProductField()"
                                class="ml-2 font-medium text-secondary cursor-pointer hover:underline">Add a
                                field</span>



                        </div>

                        <!-- End product -->
                        <div class="mt-5 form-card " style="max-width: 700px; padding: 15px">
                            <mat-card class="">
                                <div class="sub-total-row flex mb-4">
                                    <div class="label-container">
                                        <mat-label><strong>Sub-Total</strong>
                                        </mat-label>
                                    </div>
                                    <mat-form-field [ngClass]="formFieldHelpers"
                                        class="input-container price-input-wrap fuse-mat-rounded fuse-mat-emphasized-affix">
                                        <input type="text" onkeypress="return event.charCode>=48 && event.charCode<=57"
                                            matInput placeholder="Sub Total" required name="sub_total"
                                            [formControlName]="'sub_total'" class="elevated-input" />

                                        <span matPrefix>रु</span>
                                        <span matSuffix>.00</span>
                                    </mat-form-field>
                                    <div class="typed-text">
                                        <strong>
                                            {{
                                            vendorsCredit.get("sub_total").value
                                            }}
                                            <span matSuffix>.00</span>
                                        </strong>
                                    </div>
                                </div>

                                <div class="sub-total-row flex mb-4">
                                    <div class="label-container">
                                        <mat-label>Discount </mat-label>
                                    </div>
                                    <mat-form-field [ngClass]="formFieldHelpers"
                                        class="input-container fuse-mat-rounded fuse-mat-emphasized-affix">
                                        <input type="text" onkeypress="return event.charCode>=48 && event.charCode<=57"
                                            matInput placeholder="Discount" required name="discount"
                                            [formControlName]="'discount'" class="elevated-input" />
                                        <span matPrefix>रु</span>
                                        <mat-select [formControlName]="'discountSuffix'" matSuffix>
                                            <mat-option [value]="'%'">%</mat-option>
                                            <mat-option [value]="'rs'">रु</mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                    <div class="typed-text">
                                        {{ vendorsCredit.get("discount").value }}
                                        <ng-container *ngIf="
                                            vendorsCredit.get('discountSuffix')
                                                .value === '%'
                                        ">
                                            <app-percentage-icon matPrefix>
                                                %</app-percentage-icon>
                                        </ng-container>
                                        <ng-container *ngIf="
                                            vendorsCredit.get('discountSuffix')
                                                .value === 'rs'
                                        ">
                                            <app-rs-icon matPrefix>.00 रु</app-rs-icon>
                                        </ng-container>
                                    </div>
                                </div>

                                <div class="sub-total-row flex mb-4">
                                    <div class="label-container bold-label">
                                        <mat-label> Adjustment</mat-label>
                                    </div>
                                    <mat-form-field [ngClass]="formFieldHelpers"
                                        class="input-container fuse-mat-rounded fuse-mat-emphasized-affix">
                                        <input type="text" onkeypress="return event.charCode>=48 && event.charCode<=57"
                                            matInput placeholder="Adjustment" required name="adjustment"
                                            [formControlName]="'adjustment'" class="elevated-input" />
                                        <span matPrefix>रु</span>
                                        <mat-select [formControlName]="'adjustmentSuffix'" matSuffix>
                                            <mat-option [value]="'%'">
                                                %</mat-option>
                                            <mat-option [value]="'rs'">रु</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <div class="typed-text">
                                        {{ vendorsCredit.get("adjustment").value }}
                                        <ng-container *ngIf="
                                            vendorsCredit.get(
                                                'adjustmentSuffix'
                                            ).value === '%'
                                        ">
                                            <app-percentage-icon matPrefix>
                                                %</app-percentage-icon>
                                        </ng-container>
                                        <ng-container *ngIf="
                                            vendorsCredit.get(
                                                'adjustmentSuffix'
                                            ).value === 'rs'
                                        ">
                                            <app-rs-icon matPrefix>.00 रु</app-rs-icon>
                                        </ng-container>
                                    </div>
                                </div>

                                <div class="sub-total-row flex mb-4">
                                    <div class="label-container">
                                        <mat-label><strong> Total NPR</strong></mat-label>
                                    </div>
                                    <mat-form-field [ngClass]="formFieldHelpers"
                                        class="input-container fuse-mat-rounded fuse-mat-emphasized-affix">
                                        <input matInput placeholder="Total" type="text"
                                            onkeypress="return event.charCode>=48 && event.charCode<=57" required
                                            name="total" [formControlName]="'total'" class="elevated-input" />
                                        <span matPrefix>रु</span>
                                        <span matSuffix>.00</span>
                                    </mat-form-field>
                                    <div class="typed-text">
                                        <strong>
                                            <span matPrefix>रु</span>
                                            {{ vendorsCredit.get("total").value }}
                                            <span matSuffix>.00</span>
                                        </strong>
                                    </div>
                                </div>
                            </mat-card>
                        </div>

                        <mat-divider class="mt-8 mb-10"></mat-divider>
                        <mat-form-field style="max-width:500px;" class="fuse-mat-rounded fuse-mat-emphasized-affix">
                            <mat-label class="ml-3">New Total Balance</mat-label>
                            <input style="height: 50px; font-weight: bold;" matInput type="number"
                                [value]="absoluteRemainingAmount" placeholder="New Balance Amount" readonly />
                            <span matPrefix>रु</span>
                            <span matSuffix>.00</span>
                            <ng-container *ngIf="vendorsCredit.get('remainingAmount').value !== null">
                                <span
                                    [ngClass]="{'icon-credit': vendorsCredit.get('remainingAmount').value < 0, 'icon-debit': vendorsCredit.get('remainingAmount').value >= 0}">
                                    <!-- You can replace the content of these spans with appropriate icons -->
                                    <span matPrefix
                                        matTooltip="This amount will be the new total for the exchange order"
                                        *ngIf="vendorsCredit.get('remainingAmount').value < 0">
                                        <mat-icon style="color:rgb(52, 253, 186);" svgIcon="credit_score"></mat-icon>
                                    </span>
                                    <span matPrefix
                                        matTooltip="The remaining amount will be converted to credit note for the customer."
                                        *ngIf="vendorsCredit.get('remainingAmount').value >= 0">
                                        <mat-icon style="color: burlywood;" svgIcon="money_off"></mat-icon>
                                    </span>
                                </span>
                            </ng-container>
                        </mat-form-field>


                        <mat-divider class="mt-8 mb-3"></mat-divider>


                        <div class="flex mb-2">
                            <mat-form-field style="max-width: 600px"
                                class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix">
                                <mat-label class="ml-3">Exchange Reason </mat-label>
                                <ng-container
                                    *ngIf="vendorsCredit.get('exchange_type').value !== 'others'; else otherExchangeInputss">
                                    <mat-select [ngClass]="formFieldHelpers" placeholder="Exchange Reason" required
                                        name="Exchanges reason" [formControlName]="'rfr'" class="elevated-input">
                                        <mat-option value="SIZE EXCHANGE">SIZE EXCHANGE</mat-option>
                                        <mat-option value="COLOR EXCHANGE">COLOR EXCHANGE</mat-option>
                                        <mat-option value="DAMANGE EXCHANGE">DAMANGE EXCHANGE</mat-option>
                                        <mat-option value="PRODUCT EXCHANGE">PRODUCT EXCHANGE</mat-option>
                                        <mat-option value="others">OTHERS</mat-option>
                                    </mat-select>

                                </ng-container>

                                <ng-template #otherExchangeInputss>
                                    <input matInput placeholder="Reason For Exchange" name="Reson For Retunr"
                                        [formControlName]="'exchange_type'" class="elevated-input" />
                                    <mat-icon-button class="icon-size-4" (click)="toggleInputsExchange()">
                                        <mat-icon [svgIcon]="'feather:skip-back'"></mat-icon>
                                    </mat-icon-button>
                                </ng-template>



                                <mat-icon class="icon-size-4" matPrefix [svgIcon]="'feather:truck'"></mat-icon>

                            </mat-form-field>
                        </div>


                        <div class="flex mb-2">
                            <mat-form-field style="max-width: 600px"
                                class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix">
                                <mat-label class="ml-3">Salesperson</mat-label>
                                <input [ngClass]="formFieldHelpers" matInput placeholder="Sales Person" name="Tax"
                                    [formControlName]="'sales_person'" class="elevated-input small-input-text"
                                    [value]="user.username.toUpperCase()" readonly>
                                <mat-icon class="icon-size-4" matPrefix [svgIcon]="'feather:user'"></mat-icon>
                            </mat-form-field>
                        </div>

                        <!-- sdsa product -->

                        <div class="flex items-center justify-start mt-10">
                            <button style="margin: 5px" (click)="submitVendorsInfo()"
                                class="px-6 py-2 rounded text-white bg-red-500 hover:bg-red-600" mat-flat-button
                                type="submit">
                                Save
                            </button>

                            <button [routerLink]="['..']"
                                class="px-6 py-2 rounded text-white bg-blue-500 hover:bg-blue-600 ml-5" mat-flat-button
                                color="'accent'">
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </mat-drawer-content>

        </mat-drawer-container>
    </form>

</div>