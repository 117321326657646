import { ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {
    FormArray,
    FormBuilder,
    FormGroup,
    Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { map,  switchMap, take } from 'rxjs/operators';
import { Observable,  of, forkJoin } from 'rxjs';
import Swal from 'sweetalert2';
import { productBundleService } from '../product-bundle.service';
import { productVaraitons } from 'app/productVariations/productVariations.service';
import { productsService } from 'app/products/products.service';
import { products } from 'app/products/products.type';
import { HasUnsavedChanges } from 'app/page-detect/wm-can-leave/detect-changes.guard';

@Component({
    selector: 'app-create-product-bundle',
    templateUrl: './create-product-bundle.component.html',
    styleUrls: ['./create-product-bundle.component.scss'],
})
export class CreateProductBundleComponent implements OnInit ,HasUnsavedChanges {
    produtBundleForm: FormGroup; 
    filteredProductOptions$: Observable<productVaraitons[]>;
    mappedVariations: any;
    sizes: any[] = []; 
    colors: any[] = []; 
    active: boolean[] = [];
    animationFlags: boolean[] = [];
    matchedVariationIds: Set<string> = new Set();
    current_stock: any[] = [];
    product_details: any[] = [];
    match_stock: boolean[] = [];
    productVariationsArray: { [productId: string]: any[] } = {};


    constructor(
        private _formBuilder: FormBuilder,
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private productBundleService: productBundleService,
        private _productServices: productsService,
        private cd: ChangeDetectorRef,


    ) {
       

        const products$: Observable<products[]> = this._productServices
            .getproducts()
            .pipe(
                take(1),
                map((response) => response)
            );
        products$.subscribe((products) => {
        });
    }

    ngOnInit() {

        this.produtBundleForm = this._formBuilder.group({
            bundle_name: [''],
            bundle_description: [''],
            products: this._formBuilder.array([]),
      

        });

        (this.produtBundleForm.get('products') as FormArray).clear();
        const initialProductCount = 2;
        const productArray = this.produtBundleForm.get('products') as FormArray;
        for (let i = 0; i < productArray.length; i++) {
            this.animationFlags[i] = false;
        };
        this.active = Array(initialProductCount).fill(false);
        for (let i = 0; i < initialProductCount; i++) {
            const productGroup = this._formBuilder.group({
                id:[''],
                product: ['', [Validators.required]],
               
            });


            (this.produtBundleForm.get('products') as FormArray).push(
                productGroup
            );
        }
    
     
    }

      /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
       trackByFn(index: number, item: any): any {
        return item.id || index;
    }




    addProductField(): void {
        const productFormGroup = this._formBuilder.group({
            id:[''],
            product: ['',],
         
        });

        const productArray = this.produtBundleForm.get('products') as FormArray;
        productArray.push(productFormGroup);

        const index = productArray.length - 1;

    }

    
   filterOptionsProduct(value: string): void {
    if (typeof value !== 'string') {
      this.filteredProductOptions$ = of([]);
      return;
    }
  
    this._productServices.getproducts().pipe(
      take(1),
      switchMap((products) => {
        if (!Array.isArray(products['data'])) {
          return of([]);
        }
  
        const productObservables = products['data'].map((product) => {
          return this._productServices.getProductVariations(product.id).pipe(
            map((variations) => ({
              product,
              variations: variations.data,
            }))
          );
        });
  
        // Combine all product observables into a single observable
        return forkJoin(productObservables);
      }),
      map((productsWithVariations) => {
        return productsWithVariations.map(({ product, variations }) => {
          const productId = product.id;
  
          // Check if any variation has stock
          const hasStock = variations.some((variation) => variation.stock > 0);
  
     
  
          const isOutOfStock = !hasStock ;
          const productStyle = isOutOfStock ? { color: 'red' } : {};
          const disabled = isOutOfStock;
  
          return {
            product: productId,
            id: productId,
            productName: product.productName,
            sku: product.sku,
            isOutOfStock: isOutOfStock,
            style: productStyle,
            description: product.description,
            disabled: disabled,
          };
        });
      })
    ).subscribe((filteredProducts) => {
      this.filteredProductOptions$ = of(filteredProducts);
    });
  }
  

    // Add this method to handle size and color updates

    displayProductOption(product: productVaraitons): string {
        if (product) {
            const productName = product.productName || '';
            const productPrice = product.sku
                ? ' - ' + product.sku.toString()
                : '' + product.productName.toString();

            return productName + productPrice;
        }
        return '';
    }


    removeProductField(index: number): void {
        const productArray = this.produtBundleForm.get('products') as FormArray;
        const existingFormGroup = productArray.at(index) as FormGroup;
      
        const removedId = existingFormGroup.get('id').value;
        this.matchedVariationIds.delete(removedId);
      
        this.active.splice(index, 1);
        this.animationFlags.splice(index, 1);
        this.match_stock.splice(index, 1);
        this.current_stock.splice(index, 1);
        this.product_details.splice(index, 1);
      
        this.sizes.splice(index, 1);
        this.colors.splice(index, 1);
      
        productArray.removeAt(index);
      
        this.cd.markForCheck();
      }


  

         

    selectOptionProduct(option: products, index: number): void {

        if (option && !option.isOutOfStock) {
            const productFormGroup = this._formBuilder.group({
                id: [option.id],
                product: [option, [Validators.required]],
                quantity: [1],
                rate: [option.basePrice, ''],
                amount: [''],
                size: [],
                color: [],
            });
            this.active[index] = true;
            
            const productArray = this.produtBundleForm.get('products') as FormArray;
            const existingFormGroup = productArray.at(index) as FormGroup;
            existingFormGroup.get('id').patchValue(option.id);



     
        }
    }
   


    sumbitProductBundleForm() {

        if (this.produtBundleForm.valid) {
           
            const productBundleData = { ...this.produtBundleForm.value };
            const products = this.produtBundleForm.get('products') as FormArray;
            const updatedProducts = products.value.map((item: any) => ({
                product: item.id,
               

            }));
            
            productBundleData.products = updatedProducts;
            this.productBundleService
                .createproductss(productBundleData)
                .then((response) => {
                    if (response.success) {
                        const Toast = Swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener(
                                    'mouseenter',
                                    Swal.stopTimer
                                );
                                toast.addEventListener(
                                    'mouseleave',
                                    Swal.resumeTimer
                                );
                            },
                        });

                        Toast.fire({
                            icon: 'success',
                            title: `Product Bundle  created!!`,
                        });

                        this.produtBundleForm.reset();
                        this._router.navigate(['../'], {
                            relativeTo: this._activatedRoute,
                        });
                    } else {
                        const errorMessages = response.errors;
                        
                        const Toast = Swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener(
                                    'mouseenter',
                                    Swal.stopTimer
                                );
                                toast.addEventListener(
                                    'mouseleave',
                                    Swal.resumeTimer
                                );
                            },
                        });

                        Toast.fire({
                            icon: 'error',
                            title: 'Error',
                            text: JSON.stringify(errorMessages),
                        });
                    }
                })
                .catch((error) => {
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener(
                                'mouseenter',
                                Swal.stopTimer
                            );
                            toast.addEventListener(
                                'mouseleave',
                                Swal.resumeTimer
                            );
                        },
                    });

                    Toast.fire({
                        icon: 'error',
                        title: `Error creating Product: ${error.message}`,
                    });
                });
        } else {
        
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer);
                    toast.addEventListener('mouseleave', Swal.resumeTimer);
                },
            });

            Toast.fire({
                icon: 'error',
                title: 'Invalid form data',
                text: 'Please fill in all the required fields correctly.',
            });
        }
    }
    hasUnsavedChanges(): boolean {
        return this.produtBundleForm.dirty;
      }
    
      canDeactivate(): boolean {
        return !this.produtBundleForm.dirty || confirm('You have unsaved changes. Are you sure you want to leave?');
      }
    
}
