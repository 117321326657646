import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject, BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { User } from 'app/core/user/user.types';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    private _user: ReplaySubject<User> = new ReplaySubject<User>(1);
    private _userRoles: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

    constructor(private _httpClient: HttpClient) {}

    set user(value: User) {
        this._user.next(value);
        this._userRoles.next(value.roles || []);
    }

    get user$(): Observable<User> {
        return this._user.asObservable();
    }

    get userRoles$(): Observable<string[]> {
        return this._userRoles.asObservable();
    }

    get(): Observable<User>
    {
        return this._httpClient.get<User>('api/common/user').pipe(
            tap((user) => {
                this._user.next(user);
            })
        );
    }

    update(user: User): Observable<any> {
        return this._httpClient.patch<User>('api/common/user', { user }).pipe(
            tap(response => {
                this.user = response;
            })
        );
    }

    clearUser(): void {
        this._user.next(null);
        this._userRoles.next([]);

    }
}
