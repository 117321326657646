import { Route } from '@angular/router';

import { ListvendorsCreditComponent } from 'app/vendors-credit/listvendors-credit/listvendors-credit.component';
import { CreatevendorsCreditComponent } from 'app/vendors-credit/createvendors-credit/createvendors-credit.component';
import { DetailvendorsCreditComponent } from 'app/vendors-credit/detailvendors-credit/detailvendors-credit.component';



export const VendorsCreditRouting: Route[] = [
   
        {
            path     : '',
            component:  ListvendorsCreditComponent,
        },
        {
            path         : 'NEW',
            component    : CreatevendorsCreditComponent,
           
        },
        {
            path         : ':id',
            component    :DetailvendorsCreditComponent,
          
          
        },
]
