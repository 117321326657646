<div class="form-container">
  <form [formGroup]="productForm" (ngSubmit)="submitForm(productForm.value)">
    <div class="flex flex-col mt-1 p-4 pb-3 bg-card rounded  overflow-hidden">
      <p class="text-lg font-medium">Product Variants</p>
      <p class="text-secondary">
        Based on your product options, these are the different versions of your product that customers can buy.
      </p>
      <mat-divider class="mt-4 mb-4"></mat-divider>

      <ng-container
        *ngFor="let varients of productForm.get('varients')['controls']; let i = index; let first = first; let last = last; trackBy: trackByFn">
        <div class="flex items-center space-x-4">

          <mat-form-field class="flex-auto fuse-mat-rounded" style="max-width:180px;">
            <mat-label>Variant Name</mat-label>
            <input type="text" matInput placeholder="Variant Name" [formControl]="varients.get('productName')"
              readonly />
            <mat-icon class="icon-size-4" matPrefix svgIcon="feather:user"></mat-icon>
          </mat-form-field>

          <mat-form-field class="flex-auto fuse-mat-rounded" style="max-width:180px;">
            <mat-label>Color</mat-label>
            <input class="elevated-input" type="text" required matInput [formControl]="varients.get('color')" readonly>
            <mat-icon class="icon-size-4" matPrefix svgIcon="color_lens"></mat-icon>

          </mat-form-field>

          <mat-form-field class="flex-auto fuse-mat-rounded " style="max-width:120px;">
            <mat-label>Size</mat-label>
            <input type="text" matInput placeholder="Size" [formControl]="varients.get('size')" readonly />
            <mat-icon class="icon-size-4" matPrefix svgIcon="format_size"></mat-icon>
          </mat-form-field>

          <mat-form-field class="flex-auto fuse-mat-rounded">
            <mat-label>SKU</mat-label>
            <input style="font-size: small;" type="text" required matInput placeholder="SKU" [readonly]="SkuStatus"
              [formControl]="varients.get('sku')" />

            <mat-icon *ngIf="SkuStatus === true" class="icon-size-4" matTooltip="Click this to edit the field"
              matTooltipPosition="above" style="color:red;" matPrefix svgIcon="heroicons_solid:lock-closed"
              (click)="toggleReadOnly()"></mat-icon>

            <mat-icon *ngIf="SkuStatus === false" class="icon-size-4" matTooltip="Click this to make the field readonly"
              matTooltipPosition="above" style="color: green;" matPrefix svgIcon="heroicons_solid:lock-open"
              (click)="toggleReadOnly()"></mat-icon>


          </mat-form-field>
<!-- 
          <mat-form-field class="flex-auto fuse-mat-rounded" style="max-width:130px;" *ngIf="data.showAssumedInventory">
            <mat-label>Inventory</mat-label>
            <input type="text" onkeypress="return event.charCode>=48 && event.charCode<=57" [disableArrowUpDownWheel]
              required matInput placeholder="Stock" [formControl]="varients.get('stock')" />
            <mat-icon class="icon-size-4" matPrefix svgIcon="feather:package"></mat-icon>
          </mat-form-field> -->

          <mat-form-field  class="flex-auto fuse-mat-rounded" style="max-width:130px;" *ngIf="!data.showAssumedInventory">
            <mat-label>Price Difference</mat-label>
            <input type="text" onkeypress="return event.charCode>=48 && event.charCode<=57" [disableArrowUpDownWheel]
              matInput placeholder="Base Price" [formControl]="varients.get('price')" />
            <mat-icon class="icon-size-4" matPrefix svgIcon="feather:dollar-sign"></mat-icon>
          </mat-form-field>
        </div>
      </ng-container>
    </div>

    <div class="flex items-center justify-end mt-4">
      <button class="px-4 ml-2" mat-flat-button type="submit" color="primary">Save</button>
      <button (click)="cancel()" class="px-4 ml-2" mat-flat-button type="button" color="error">Cancel</button>
    </div>
  </form>
</div>