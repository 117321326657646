import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { Vendor, Country, Tag } from 'app/modules/Vendor/vendors.type';
import { server_env } from 'config';

@Injectable({
    providedIn: 'root'
})
export class VendorService {
    // Private
    private _Vendor: BehaviorSubject<Vendor | null> = new BehaviorSubject(null);
    private _Vendors: BehaviorSubject<Vendor[] | null> = new BehaviorSubject(null);
    private _countries: BehaviorSubject<Country[] | null> = new BehaviorSubject(null);
    private _tags: BehaviorSubject<Tag[] | null> = new BehaviorSubject(null);
    public url = `${server_env.URL}api/v1/vendors/`

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for Vendor
     */
    get Vendor$(): Observable<Vendor> {
        return this._Vendor.asObservable();
    }

    /**
     * Getter for Vendors
     */
    get Vendors$(): Observable<Vendor[]> {
        return this._Vendors.asObservable();
    }

    /**
     * Getter for countries
     */
    get countries$(): Observable<Country[]> {
        return this._countries.asObservable();
    }

    /**
     * Getter for tags
     */
    get tags$(): Observable<Tag[]> {
        return this._tags.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get Vendors
     */

    getVendors(): Observable<Vendor[]> {
        return this._httpClient.get<Vendor[]>(`${this.url}`).pipe(
            tap((Vendors: any) => {
                this._Vendors.next(Vendors.data);
            })
        );
    }

    /**
     * Search Vendors with given query
     *
     * @param query
     */
    searchVendors(query: string): Observable<Vendor[]> {
        return this._httpClient.get<Vendor[]>('api/apps/vendorss/search', {
            params: { query }
        }).pipe(
            tap((Vendors) => {
                this._Vendors.next(Vendors);
            })
        );
    }
    // Fetch vendor report as JSON
    // Fetch vendor report as JSON
    getVendorReport(id: number): Promise<any> {
        const url = `${this.url}${id}/report/`;
        return this._httpClient.get<any>(url).toPromise();
    }

    // Fetch vendor report as PDF
    getVendorReportPDF(id: number): Observable<Blob> {
        const url = `${this.url}${id}/report/pdf/`;
        return this._httpClient.get(url, {
            responseType: 'blob', // 'blob' for binary data
            headers: new HttpHeaders().set('Content-Type', 'application/pdf'),
        });
    }

    // Fetch vendor report as Excel
    getVendorReportExcel(id: number): Observable<Blob> {
        const url = `${this.url}${id}/report/excel/`;
        return this._httpClient.get(url, {
            responseType: 'blob', // 'blob' for binary data
            headers: new HttpHeaders().set('Content-Type', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'),
        });
    }
    /**
     * Get Vendor by id
     */
    getVendorById(id: string): Observable<Vendor> {

        const requestUrl = this.url + id;

        return this._httpClient.get<Vendor[]>(`${requestUrl}`).pipe(
            take(1),
            map((Vendor: any) => {
                this._Vendor.next(Vendor.data);

                // Return the Vendor
                return Vendor.data;
            }),
            switchMap((Vendor) => {

                if (!Vendor) {
                    return throwError('Could not found Vendor with id of ' + id + '!');
                }

                return of(Vendor);
            })
        );
    }

    /**
     * Create Vendor
     */
    createVendor(): Observable<Vendor> {
        return this.Vendors$.pipe(
            take(1),
            switchMap(Vendors => this._httpClient.post<Vendor>(`${this.url}`, {}).pipe(
                map((newVendor) => {

                    // Update the Vendors with the new Vendor
                    this._Vendors.next([newVendor, ...Vendors]);

                    // Return the new Vendor
                    return newVendor;
                })
            ))
        );
    }

    /**
     * create vendors
     * @param data 
     * @returns 
     */

    createVendors(data): Promise<any> {

        return new Promise((resolve, reject) => {

            const requestUrl = this.url;

            this._httpClient.post<any>(`${requestUrl}`, data).subscribe(response => {
                return resolve(response);
            }, error => {
                return reject(error)
            });

        });

    }

    /**
    * Update Contact
    *
    * @param id
    * @param vendorData
    */
    updateVendor(id: string, vendorData: any): Observable<Vendor> {

        return this.Vendors$.pipe(
            take(1),
            switchMap(Contacts =>
                this._httpClient.put(`${this.url}${id}/`, vendorData).pipe(
                    map((updatedContact) => {

                        // Find the index of the updated Contact
                        const index = Contacts.findIndex(item => item.id === id);
                        // Update the Contact
                        Contacts[index] = updatedContact;

                        // Update the Contacts
                        this._Vendors.next(Contacts);

                        // Return the updated Contact
                        return updatedContact;
                    }),
                    switchMap(updatedContact =>
                        this.Vendor$.pipe(
                            take(1),
                            filter(item => item && item.id === id),
                            tap(() => {
                                // Update the Contact if it's selected
                                this._Vendor.next(updatedContact);
                            })
                        )
                    )
                )
            )
        );
    }
    /**
     * Delete the Vendor
     *
     * @param id
     */
    deleteVendor(id: string): Observable<boolean> {
        return this.Vendors$.pipe(
            take(1),
            switchMap(Vendors => this._httpClient.delete(`${this.url}${id}/`,).pipe(
                map((isDeleted: boolean) => {

                    // Find the index of the deleted Vendor
                    const index = Vendors.findIndex(item => item.id === id);

                    // Delete the Vendor
                    Vendors.splice(index, 1);

                    // Update the Vendors
                    this._Vendors.next(Vendors);

                    // Return the deleted status
                    return isDeleted;
                })
            ))
        );
    }

    /**
     * Get countries
     */
    getCountries(): Observable<Country[]> {
        return this._httpClient.get<Country[]>('api/apps/contacts/countries').pipe(
            tap((countries) => {
                this._countries.next(countries);
            })
        );
    }

    /**
     * Get tags
     */
    getTags(): Observable<Tag[]> {
        return this._httpClient.get<Tag[]>('api/apps/contacts/tags').pipe(
            tap((tags) => {
                this._tags.next(tags);
            })
        );
    }

    /**
     * Create tag
     *
     * @param tag
     */
    createTag(tag: Tag): Observable<Tag> {
        return this.tags$.pipe(
            take(1),
            switchMap(tags => this._httpClient.post<Tag>('api/apps/contacts/tag', { tag }).pipe(
                map((newTag) => {

                    // Update the tags with the new tag
                    this._tags.next([...tags, newTag]);

                    // Return new tag from observable
                    return newTag;
                })
            ))
        );
    }

    /**
     * Update the tag
     *
     * @param id
     * @param tag
     */
    updateTag(id: string, tag: Tag): Observable<Tag> {
        return this.tags$.pipe(
            take(1),
            switchMap(tags => this._httpClient.patch<Tag>('api/apps/contacts/tag', {
                id,
                tag
            }).pipe(
                map((updatedTag) => {

                    // Find the index of the updated tag
                    const index = tags.findIndex(item => item.id === id);

                    // Update the tag
                    tags[index] = updatedTag;

                    // Update the tags
                    this._tags.next(tags);

                    // Return the updated tag
                    return updatedTag;
                })
            ))
        );
    }

    /**
     * Delete the tag
     *
     * @param id
     */
    deleteTag(id: string): Observable<boolean> {
        return this.tags$.pipe(
            take(1),
            switchMap(tags => this._httpClient.delete('api/apps/contacts/tag', { params: { id } }).pipe(
                map((isDeleted: boolean) => {

                    // Find the index of the deleted tag
                    const index = tags.findIndex(item => item.id === id);

                    // Delete the tag
                    tags.splice(index, 1);

                    // Update the tags
                    this._tags.next(tags);

                    // Return the deleted status
                    return isDeleted;
                }),
                filter(isDeleted => isDeleted),
                switchMap(isDeleted => this.Vendors$.pipe(
                    take(1),
                    map((Vendors) => {

                        // Iterate through the Vendors
                        Vendors.forEach((Vendor) => {

                            const tagIndex = 1;

                            // If the Vendor has the tag, remove it
                            if (tagIndex > -1) {

                            }
                        });

                        // Return the deleted status
                        return isDeleted;
                    })
                ))
            ))
        );
    }

    /**
     * Update the avatar of the given Vendor
     *
     * @param id
     * @param avatar
     */
    uploadAvatar(id: string, avatar: File): Observable<Vendor> {
        return this.Vendors$.pipe(
            take(1),
            switchMap(Vendors => this._httpClient.post<Vendor>('api/apps/contacts/avatar', {
                id,
                avatar
            }, {
                headers: {
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    'Content-Type': avatar.type
                }
            }).pipe(
                map((updatedVendor) => {

                    // Find the index of the updated Vendor
                    const index = Vendors.findIndex(item => item.id === id);

                    // Update the Vendor
                    Vendors[index] = updatedVendor;

                    // Update the Vendors
                    this._Vendors.next(Vendors);

                    // Return the updated Vendor
                    return updatedVendor;
                }),
                switchMap(updatedVendor => this.Vendor$.pipe(
                    take(1),
                    filter(item => item && item.id === id),
                    tap(() => {

                        // Update the Vendor if it's selected
                        this._Vendor.next(updatedVendor);

                        // Return the updated Vendor
                        return updatedVendor;
                    })
                ))
            ))
        );
    }
}
