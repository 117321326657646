import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,



    ElementRef,


    EventEmitter,


    OnDestroy,
    OnInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { SalesOrder } from '../sales-order.type';
import { Observable } from 'rxjs';
import { SalesOrderService } from '../sales-order.service';
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { SelectionModel } from '@angular/cdk/collections';

import * as ExcelJS from 'exceljs';
import { DatePipe } from '@angular/common';
import { InvoiceComponent } from '../invoice/sales-order-invoice';
import { forkJoin } from 'rxjs';
import { PackagesOderService } from 'app/packages/packages.service';
import { PartialUpdateComponent } from '../partial-update/partial-update.component';
import jsPDF from 'jspdf';
import { RoleService } from 'app/core/auth/guards/role.service';
import html2canvas from 'html2canvas';

export interface SalesOrderData {
    customerId: string;
    customerName: string;
    shipping_area: string;
    delivery_type: string;
    saleNumber: string;
    shipping_carrier?: string;
    status: string;
    shippingDate: any;
    products: any;
    totalCost: number;
    isExpanded: boolean;
}

@Component({
    selector: 'app-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss'],
    providers: [InvoiceComponent],



})
export class SalesOrderListComponent
    implements OnInit, AfterViewInit, OnDestroy {
    displayedColumns: string[] = [
        "select",
        'CustomerId',
        'CustomerName',
        'SaleNumber',
        'Status',
        'Shipping_Date',
        "Delivery Type",
        "Shipping Area",
        "Shipping Carrier",
        'total',
        'actions',
        'create',
    ];
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChildren(InvoiceComponent) invoiceComponents: QueryList<InvoiceComponent>;
    @ViewChildren('printableContent') printableContent: QueryList<ElementRef>;

    @Output() pdfGenerated = new EventEmitter<jsPDF>();

    vendors: any[] = [];
    pdf: jsPDF;
    requiredRoles: string[] = ["Manager", "Operation Manager", "HR"];  // Define the roles allowed to execute the function
    requiredRolesCreation: string[] = ["Manager", "Communication Manager", "HR"];
    requiredRolesCreationAll: string[] = ["Manager", "Communication Manager", "Operation Manager", "HR"];
    // Define the roles allowed to execute the function
    salesOrders: any;
    dataSource: MatTableDataSource<SalesOrderData>;
    @ViewChild('salesOrderTabel') salesOrderTabel: ElementRef;
    salesorder$: Observable<SalesOrder[]>;

    isLoading = true;
    dropdownHidden: boolean = true;

    fileName = 'SalesOderSheet.xlsx';


    checked = false;
    selectedDate: any;
    selection = new SelectionModel<SalesOrderData>(true, []);
    totalRows: number = 0;
    selectedShippingArea: string;
    selectedDeliveryType: string;
    selectedRowIds: any[] = [];
    counter: number = 0;
    selectAll: boolean = false;

    items: any[] = [
        { checked: false, /* other properties */ },
        { checked: false, /* other properties */ },
        // ...
    ];
    formattedSelectedDate: string;
    constructor(
        private _router: Router,
        private _salesorderService: SalesOrderService,
        private dialog: MatDialog,
        private datePipe: DatePipe,
        private _packageService: PackagesOderService,
        private route: ActivatedRoute,
        private roleService: RoleService,
        private cdr: ChangeDetectorRef

    ) {

    }




    ngOnInit(): void {

        this.route.data.subscribe((data: { salesOrders: SalesOrder[] }) => {
            this.salesOrders = data.salesOrders;
            this.loadSalesOrderData();
        });

    }
    ngAfterViewInit(): void {

        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }
    loadSalesOrders(): void {
        this.route.data.subscribe((data: { salesOrders: SalesOrder[] }) => {
            this.salesOrders = data.salesOrders;
            this.loadSalesOrders();
        });
    }

    refreshSalesOrders(): void {
        this._salesorderService.getSalesOrder().subscribe(
            (salesOrders: SalesOrder[]) => {
                this.salesOrders = salesOrders;
                this.loadSalesOrderData();
            },
            error => {
                console.error('Error refreshing sales orders', error);
            }
        );
    }
    async loadInvoices() {
        this.vendors = [];

        for (const id of this.selectedRowIds) {
            try {
                const data = await this._salesorderService.getSalesOrderById(id).toPromise();
                this.vendors.push(data);
            } catch (error) {
                console.error('Error fetching sales order:', error);
            }
        }

        // After data is loaded, update the invoice components
        setTimeout(() => this.updateInvoiceComponents(), 0);
    }

    updateInvoiceComponents() {
        this.invoiceComponents.forEach((invoiceComponent, index) => {
            if (this.vendors[index]) {
                invoiceComponent.vendor = this.vendors[index];
            }
        });

        this.cdr.detectChanges();

        this.generateAndPrintPdfs();
    }

    async generateAndPrintPdfs() {
        // Access the elements inside ViewChildren (since you have a dynamic list)
        const elements = this.printableContent.toArray();
    
        // Remove 'hidden-print' class from each element
        elements.forEach(element => {
          element.nativeElement.classList.remove('hidden-print');
        });
    
        const componentsArray = this.invoiceComponents.toArray();
        const combinedPdf = new jsPDF();
    
        for (let i = 0; i < componentsArray.length; i++) {
          const invoiceComponent = componentsArray[i];
          const element = invoiceComponent.getInvoiceElement();
    
          try {
            const canvas = await html2canvas(element);
            const imgData = canvas.toDataURL('image/png');
            const pdfWidth = combinedPdf.internal.pageSize.getWidth();
            const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
    
            if (i === 0) {
              combinedPdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
            } else {
              combinedPdf.addPage();
              combinedPdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
            }
          } catch (error) {
            console.error('Error generating PDF:', error);
          }
        }
    
        const pdfBlob = combinedPdf.output('blob');
        const pdfUrl = URL.createObjectURL(pdfBlob);
    
        // Create iframe for printing
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = pdfUrl;
        document.body.appendChild(iframe);
        iframe.contentWindow?.print();
    
        // Re-add 'hidden-print' class after printing
        elements.forEach(element => {
          element.nativeElement.classList.add('hidden-print');
        });
      }
    



    delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }






    multiplePackage() {
        this.roleService.withRoleCheck(this.requiredRoles, async () => {

            const ids = this.selectedRowIds;
            const currentDate = new Date().toISOString().split('T')[0];
            let paid = false;
            const requests = ids.map(id => this._salesorderService.getSalesOrderById(id));

            // Show loading dialog
            Swal.fire({
                title: 'Processing...',
                html: 'Please wait while the package orders are being created.',
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading();
                }
            });

            forkJoin(requests).subscribe(
                async (results: any[]) => {
                    const rows: any[] = [];

                    results.forEach(data => {
                        const productsData = data.products.map((product: any) => {
                            return {
                                productName: product.product?.productName,
                                quantity: product.quantity,
                                amount: product.amount,
                                size: product.product?.size,
                                color: product.product?.color,
                            };
                        });

                        const salesOrderNumber = data.sales_order_num;
                        const shippingArea = data.shipping_area;
                        const address = data.address;
                        const deliveryType = data.delivery_type;
                        const customerName = data.Customers.firstName + ' ' + data.Customers.lastName;
                        const customerRemarks = data.notes;
                        const paymentType = data.payment_type;
                        const packageOrder = this.generatePackageCode();
                        const packageDate = currentDate;
                        const total = data.total;
                        const total_remaining_total = data.total;
                        const salesOrder = data.id;
                        const isPackaged = true;
                        const isShipped = true;

                        if (paymentType !== 'CASH ON DELIVERY') {
                            paid = true;
                        }
                        const influencerOrder = '';

                        const productRows = productsData.map(product => {
                            return `
                            Product Name: ${product.productName} <br>
                            Quantity: ${product.quantity} <br>
                            Size: ${product.size} Color: ${product.color}
                        `;
                        }).join('<br><br>');

                        // Patch shipping carrier based on the shipping area, with fallback
                        let shippingCarrier = '';

                        // Check for 'others' fields first
                        if (data.shipping_carrier_invalley_others) {
                            shippingCarrier = data.shipping_carrier_invalley_others;
                        } else if (data.others_out) {
                            shippingCarrier = data.others_out;
                        } else {
                            // Fallback to specific shipping area cases
                            switch (shippingArea) {
                                case 'Inside Valley':
                                    shippingCarrier = data.shipping_carrier_invalley || '';
                                    break;
                                case 'Outside Valley':
                                    shippingCarrier = data.shipping_carrier_outvalley || '';
                                    break;
                                case 'International':
                                    shippingCarrier = data.shipping_carrier_int || '';
                                    break;
                                default:
                                    shippingCarrier = ''; // or handle a default case if needed
                                    break;
                            }
                        }
                        rows.push({
                            packageDate,
                            salesOrder,
                            isPackaged,
                            isShipped,
                            influencerOrder,
                            salesOrderNumber,
                            shippingArea,
                            address,
                            deliveryType,
                            total_remaining_total,
                            customerName,
                            paymentType,
                            packageOrder,
                            customerRemarks,
                            shippingCarrier,
                            total,
                            paid,
                            products: productRows,
                        });
                    });

                    try {
                        const results = await Promise.all(rows.map(row => this.createPackageOrder(row)));

                        const failedCreations = results.filter(result => !result.success);

                        if (failedCreations.length > 0) {
                            // Show error notification if there are any failed creations
                            Swal.fire({
                                icon: 'error',
                                title: 'Error Creating Package Orders',
                                text: failedCreations.map(result => JSON.stringify(result.errors)).join('\n'),
                            });
                        } else {
                            // Show success notification if all creations succeeded
                            Swal.fire({
                                icon: 'success',
                                title: 'Package Orders Created!',
                                text: 'All selected sales orders have been shipped successfully!',
                            });
                        }

                        // Redirect to sales logs
                        this._router.navigate(['apps/sales-logs']);

                    } catch (error) {
                        console.error('Error creating package orders:', error);
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: 'An unexpected error occurred while creating package orders.',
                        });
                    } finally {
                        Swal.close();
                    }
                },
                (error) => {
                    console.error('Error fetching sales order details:', error);
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'An error occurred while fetching sales order details.',
                    });
                }
            );
        });
    }



    async singlePackage(orderId: any) {
        this.roleService.withRoleCheck(this.requiredRoles, async () => {
            try {
                const currentDate = new Date().toISOString().split('T')[0];

                // Fetch the data for a single sales order and subscribe to the observable
                this._salesorderService.getSalesOrderById(orderId).subscribe(
                    async (data: any) => {


                        let paid = false;
                        const salesOrderNumber = data.sales_order_num;
                        const shippingArea = data.shipping_area;
                        const address = data.address;
                        const deliveryType = data.delivery_type;
                        const customerName = data.Customers.firstName + ' ' + data.Customers.lastName;
                        const customerRemarks = data.notes;
                        const paymentType = data.payment_type;
                        const packageOrder = this.generatePackageCode();
                        const packageDate = currentDate;
                        const total = data.total;
                        const total_remaining_total = data.total;

                        if (paymentType != 'CASH ON DELIVERY') {
                            paid = true;
                        }

                        const salesOrder = data.id;
                        const isPackaged = true; // set isPackaged to true as it is confirmed
                        const isShipped = true; // set isShipped to true as it is confirmed
                        const influencerOrder = ''; // while creating the salesOrder package, influencer order is empty

                        let shippingCarrier = '';

                        if (data.shipping_carrier_invalley_others) {
                            shippingCarrier = data.shipping_carrier_invalley_others;
                        } else if (data.others_out) {
                            shippingCarrier = data.others_out;
                        } else {
                            // Fallback to specific shipping area cases
                            switch (shippingArea) {
                                case 'Inside Valley':
                                    shippingCarrier = data.shipping_carrier_invalley || '';
                                    break;
                                case 'Outside Valley':
                                    shippingCarrier = data.shipping_carrier_outvalley || '';
                                    break;
                                case 'International':
                                    shippingCarrier = data.shipping_carrier_int || '';
                                    break;
                                default:
                                    shippingCarrier = ''; // or handle a default case if needed
                                    break;
                            }
                        }

                        // Add data to the row object
                        const row = {
                            packageDate,
                            salesOrder,
                            isPackaged,
                            isShipped,
                            influencerOrder,
                            salesOrderNumber,
                            shippingCarrier,
                            shippingArea,
                            address,
                            deliveryType,
                            customerName,
                            paymentType,
                            packageOrder,
                            customerRemarks,
                            total,
                            paid,
                            total_remaining_total,
                        };

                        // Create the package order
                        const packageResult = await this.createPackageOrder(row);

                        if (packageResult.success) {
                            // Show success notification using Swal
                            Swal.fire({
                                icon: 'success',
                                title: 'Package Order Created!',
                                text: packageResult.message,
                            });

                            const Toast = Swal.mixin({
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 3000,
                                timerProgressBar: true,
                                didOpen: (toast) => {
                                    toast.addEventListener('mouseenter', Swal.stopTimer);
                                    toast.addEventListener('mouseleave', Swal.resumeTimer);
                                },
                            });

                            Toast.fire({
                                icon: 'success',
                                title: `Selected Sales Order has been shipped!!`,
                            });
                        } else {
                            // Show error notification using Swal
                            Swal.fire({
                                icon: 'error',
                                title: 'Error Creating Package Order',
                                text: JSON.stringify(packageResult.errors),
                            });
                        }

                        this._router.navigate(['apps/sales-logs']);
                    },
                    (error) => {
                        console.error('Error fetching sales order details:', error);
                    }
                );
            } catch (error) {
                console.error('Error fetching or processing sales order details:', error);
            }
        });
    }




    async DeliverySheet() {
        this.roleService.withRoleCheck(this.requiredRoles, async () => {

            const ids = this.selectedRowIds;
            const currentDate = new Date().toISOString().split('T')[0];

            const requests = ids.map(id => this._salesorderService.getSalesOrderById(id));

            try {
                const results = await forkJoin(requests).toPromise();

                const rows = results.map(data => {
                    const productsData = data.products.map((product: any) => {
                        return `${product.product?.base_product.productName} (Qty: ${product.quantity}, Size: ${product.product?.size}, Color: ${product.product?.color})`;
                    }).join('\n');

                    const salesOrderNumber = data.sales_order_num;
                    const shippingArea = data.shipping_area;
                    const address = data.address;
                    const deliveryType = data.delivery_type;
                    const customerName = `${data.Customers.firstName} ${data.Customers.lastName}`;
                    const customerRemarks = data.notes;
                    const paymentType = data.payment_type;
                    const packageOrder = this.generatePackageCode();
                    const packageDate = currentDate;
                    const total = data.total;

                    const salesOrder = data.id;
                    const isPackaged = true;
                    const influencerOrder = '';

                    return {
                        packageDate,
                        salesOrder,
                        isPackaged,
                        influencerOrder,
                        salesOrderNumber,
                        shippingArea,
                        address,
                        deliveryType,
                        customerName,
                        paymentType,
                        packageOrder,
                        customerRemarks,
                        total,
                        products: productsData,
                    };
                });
                const headers = ['Date', 'Sales-Order-Number', 'Shipment-Area', "Address", 'Delivery-Type', 'Customer-Name', 'Payment-Type', 'Remarks', 'Products'];

                // Create a new workbook and worksheet
                const workbook = new ExcelJS.Workbook();
                const worksheet = workbook.addWorksheet('Sheet1');

                // Define styles for header, data cells, and footer
                const headerStyle = {
                    font: { size: 13, bold: true, color: { argb: 'FFFFFF' } },
                    fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '000000' } },
                    alignment: { horizontal: 'center' },
                    border: { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } },
                    padding: { top: 5, bottom: 5, left: 5, right: 5 },
                };
                const BigheaderStyle = {
                    font: { size: 20, bold: true, color: { argb: 'FFFFFF' } },
                    fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '000000' } },
                    alignment: { horizontal: 'left' },
                    border: { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } },
                    padding: { top: 5, bottom: 5, left: 5, right: 5 },
                };

                const dataCellStyle = {
                    font: { size: 12 },
                    alignment: { horizontal: 'left' },
                    border: { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } },
                    padding: { top: 5, bottom: 5, left: 5, right: 5 },
                };

                // Add big header with spaces below
                const bigHeaderCellStart = worksheet.getCell('A1');
                const bigHeaderCellEnd = worksheet.getCell('K5'); // Add 2 extra rows for spaces below
                bigHeaderCellStart.value = 'Delivery Sheet -HUBA';
                bigHeaderCellStart.font = { size: 25, bold: true };
                bigHeaderCellStart.alignment = { horizontal: 'center' };
                worksheet.mergeCells(bigHeaderCellStart.address, bigHeaderCellEnd.address);
                Object.assign(bigHeaderCellStart, BigheaderStyle);

                // Add headers to the worksheet with padding and borders
                worksheet.addRow([]); // Add a blank row as space after big header
                worksheet.addRow(headers).eachCell((cell) => {
                    Object.assign(cell, headerStyle);
                });
                // Add data to the worksheet with padding and borders
                rows.forEach(row => {
                    const rowData = [
                        row.packageDate,
                        row.salesOrderNumber,
                        row.shippingArea,
                        row.address,
                        row.deliveryType,
                        row.customerName,
                        row.paymentType,
                        row.customerRemarks,
                        row.products,
                    ];
                    worksheet.addRow(rowData).eachCell((cell) => {
                        Object.assign(cell, dataCellStyle);
                    });
                });

                // Ensure minimum column width based on headers
                headers.forEach((header, columnIndex) => {
                    const headerWidth = header.length;
                    worksheet.getColumn(columnIndex + 1).width = Math.max(headerWidth + 3, 10); // Set minimum width of 10 characters
                });

                // Automatically adjust column widths based on cell content
                worksheet.columns.forEach((column) => {
                    column.eachCell({ includeEmpty: true }, (cell) => {
                        const cellContentWidth = cell.value ? String(cell.value).length : 0;
                        column.width = Math.max(cellContentWidth + 5, column.width || 15); // Set minimum width of 10 characters
                    });
                });

                // Add footer with spaces and padding, borders, and fill color
                worksheet.addRow([]);
                worksheet.addRow([]);
                const footerRow = worksheet.addRow(['Powered By HUBA']);
                footerRow.eachCell((cell) => {
                    const footerStyle = {
                        font: { size: 12, bold: true, color: { argb: 'FFFFFF' } },
                        fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '000000' } },
                        alignment: { horizontal: 'center' },
                        border: { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } },
                        padding: { top: 5, bottom: 5, left: 5, right: 5 },
                    };
                    Object.assign(cell, footerStyle);
                });
                // Create a blob and initiate file download
                workbook.xlsx.writeBuffer().then((buffer) => {
                    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    const url = URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = 'GroupSales-Order.xlsx';
                    a.click();
                });

            } catch (error) {
                console.error('Error occurred while processing data:', error);
            }
        });
    }





    async createPackageOrder(packageData: any): Promise<any> {
        try {
            const response = await this._packageService.createPackageOrder(packageData);
            if (response.success) {
                return { success: true, message: `${packageData.packageOrder} Package Order created successfully.` };
            } else {
                return { success: false, errors: response.errors };
            }
        } catch (error) {
            throw new Error(`Error creating Package Order: ${error.message}`);
        }
    }

    generatePackageCode(): string {
        this.counter++;
        const randomPart = Array.from(
            { length: 8 },
            () => Math.random().toString(36).toUpperCase()[2]
        ).join('');
        const packageCode = `PKG-${String(this.counter).padStart(
            4,
            '0'
        )}-${randomPart}`;
        return packageCode;
    }


    formatSelectedDate(event: Date) {
        if (event) {
            this.selectedDate = this.datePipe.transform(event, 'yyyy-MM-dd');
        } else {
            this.selectedDate = null;
        }
    }

    updateSelectedRowIds(row: any): void {
        const index = this.selectedRowIds.indexOf(row.id);

        if (index >= 0) {
            // If the ID is already in the array, remove it
            this.selectedRowIds.splice(index, 1);
        } else {
            // If the ID is not in the array, add it
            this.selectedRowIds.push(row.id);
        }

    }

    isAllSelected() {
        if (!this.dataSource || !this.dataSource.data) {
            // If dataSource or dataSource.data is undefined or null, return false
            return false;
        }

        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected > 0 && numSelected === numRows;
    }


    /** Selects all rows if they are not all selected; otherwise clear selection. */
    toggleAllRows() {
        if (this.isAllSelected()) {
            this.selection.clear();
            this.selectedRowIds = []; // Clear selectedRowIds when deselecting all
        } else {
            this.dataSource.data.forEach(row => {
                this.selection.select(row); // Select all rows in the dataSource
                this.updateSelectedRowIds(row); // Update selectedRowIds for each row
            });
        }
    }

    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: SalesOrderData): string {
        if (!row) {
            const result = `${this.isAllSelected() ? 'deselect' : 'select'} all`;
            return result;
        }
        const result = `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.customerId + 1}`;
        return result;
    }


    trackByProduct(product): string {
        return product.id;
    }


    calculateTotal(): number {
        if (this.dataSource && this.dataSource.data) {
            return this.dataSource.data.reduce((total, row) => total + row.totalCost, 0);
        }
        return 0;
    }





    deleteVendorsCredit(id: any) {

        this.roleService.withRoleCheck(this.requiredRolesCreation, async () => {

            // Prompt the user for confirmation before deleting the employee
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {

                if (result.isConfirmed) {
                    this._salesorderService.deleteSalesOrder(id)

                        .subscribe((isDeleted) => {

                            this.loadSalesOrderData();


                            if (!isDeleted) {
                                Swal.fire("Error!", "Cannot Delete");
                                return;
                            }

                            // Display a success message
                            Swal.fire(
                                'Deleted!',
                                'Your file has been deleted.',
                                'success'
                            )
                        });
                }
            });
        });
    }







    showDetails(id: any) {
        this._router.navigate(['apps/sales-order', id]);
    }







    isCollapsed(row: SalesOrderData): boolean {
        return !row.isExpanded;
    }

    toggleCollapse(row: SalesOrderData): void {
        row.isExpanded = !row.isExpanded;
    }
    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    applyFilters() {
        // Get the filter strings for shipping area and delivery type
        const shippingAreaFilter = this.selectedShippingArea ? this.selectedShippingArea.trim().toLowerCase() : '';
        const deliveryTypeFilter = this.selectedDeliveryType ? this.selectedDeliveryType.trim().toLowerCase() : '';
        const selectedDateFilter = this.selectedDate ? this.datePipe.transform(this.selectedDate, 'yyyy-MM-dd') : '';

        // Apply filter only if either shipping area, delivery type, or date is selected
        if (shippingAreaFilter || deliveryTypeFilter || selectedDateFilter) {
            // Set the custom filter function
            this.dataSource.filterPredicate = (data) => {
                const shippingAreaMatch = data.shipping_area.toLowerCase().includes(shippingAreaFilter);
                const deliveryTypeMatch = data.delivery_type.toLowerCase().includes(deliveryTypeFilter);
                const dateMatch = data.shippingDate.includes(selectedDateFilter); // Assuming shippingDate is in 'YYYY-MM-DD' format

                return shippingAreaMatch && deliveryTypeMatch && dateMatch;
            };

            const combinedFilterString = `${shippingAreaFilter} ${deliveryTypeFilter} ${selectedDateFilter}`;

            // Apply the combined filter
            this.dataSource.filter = combinedFilterString;
        } else {
            this.dataSource.filter = '';
        }
    }

    async changeShippingCarrier(id: any, shippingCarrier: any): Promise<any> {
        this.roleService.withRoleCheck(this.requiredRolesCreationAll, async () => {

            return new Promise((resolve, reject) => {
                const dialogRef = this.dialog.open(PartialUpdateComponent, {
                    width: '1000px',
                    height: '400px',
                    data: {
                        shippingAreaTodisplay: shippingCarrier,
                    }
                });

                dialogRef.componentInstance.formSubmitted.subscribe(async (formData) => {
                    if (formData) {
                        const partialupdatedSalesOrder = {};

                        Object.entries(formData).forEach(([key, value]) => {
                            // Check if the key starts with 'shipping_carrier_' and the value is not empty
                            if (key.startsWith('shipping_carrier_') && value !== '') {
                                // No need to extract the carrier name, use the key directly
                                partialupdatedSalesOrder[key] = value;
                            }
                        });

                        try {
                            const response = await this._salesorderService.PartialupdateSalesOrder(id, partialupdatedSalesOrder);

                            if (response) {
                                await this.refreshSalesOrders();  // Ensure this is an async function

                                Swal.fire({
                                    icon: 'success',
                                    title: 'Sales Order has been updated!',
                                    text: "Sales Order shipping carrier has been updated",
                                });

                                resolve(response);  // Resolve the promise after data is loaded
                            } else {
                                throw new Error('No response received');
                            }
                        } catch (error) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Error has occurred',
                                text: "Sales Order shipping carrier has not been updated",
                            });
                            reject(error);  // Reject the promise in case of error
                        } finally {
                            this.isLoading = false;  // Ensure loading state is reset
                        }
                    }
                });
            });
        });
    }



    createSalesExchange() {
        this.roleService.withRoleCheck(this.requiredRolesCreation, async () => {

            this._router.navigate(['apps/sales-order', 'NEW']);

        });
    }
    getStatusColor(status: string): string {
        if (status === 'pending') {
            return 'yellow';
        } else if (status === 'completed') {
            return 'green';
        } else if (status === 'failed') {
            return 'red';
        }
        return '';
    }
    ngOnDestroy(): void {
        // throw new Error('Method not implemented.');
    }


    loadSalesOrderData(): void {
        if (!this.salesOrders || !this.salesOrders) {
            console.error("salesOrders or salesOrders.data is undefined or null");
            return;
        }

        const salesOrdersData = this.salesOrders;


        this.totalRows = salesOrdersData.length;

        const filteredSalesOrders = salesOrdersData.filter(salesOrder => {
            return salesOrder.status === 'Started Sales Order';
        });


        this.dataSource = new MatTableDataSource<any>(
            filteredSalesOrders.map((salesOrder) => {
                const productsInfo = salesOrder.products.map((product) => ({
                    productName: product.product?.productName,
                    quantity: product.quantity,
                }));

                let shippingCarrier;
                if (salesOrder.shipping_area === 'Inside Valley') {
                    shippingCarrier = salesOrder.shipping_carrier_invalley;
                } else if (salesOrder.shipping_area === 'Outside Valley') {
                    shippingCarrier = salesOrder.shipping_carrier_outvalley;
                } else if (salesOrder.shipping_area === 'International') {
                    shippingCarrier = salesOrder.shipping_carrier_int;
                }

                return {
                    customerId: salesOrder.Customers.id,
                    customerName: `${salesOrder.Customers.firstName} ${salesOrder.Customers.lastName}`,
                    saleNumber: salesOrder.sales_order_num,
                    shippingDate: salesOrder.slaes_order_date,
                    status: salesOrder.status,
                    delivery_type: salesOrder.delivery_type,
                    shipping_carrier: shippingCarrier,
                    shipping_area: salesOrder.shipping_area,
                    products: productsInfo,
                    isExpanded: false,
                    totalCost: salesOrder.total,
                    id: salesOrder.id,
                    productTotal: salesOrder.sub_total,
                    productquantity: productsInfo.reduce((acc, prod) => acc + prod.quantity, 0),
                };
            })
        );

        this.isLoading = false;
        this.calculateTotal();
    }





}

/** Builds and returns a new User. */
