import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { fuseAnimations } from '@fuse/animations';

import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
export interface UserData {
  id: string;
  VendorsName: string;
  CreditNote: string;
  OrderNumber: string;
  VendorCreditDate: string;
}

const VENDORS_CREDIT_DATA: UserData[] = [
  {
    id: '1',
    VendorsName: 'Vendor 1',
    CreditNote: 'Credit Note 1',
    OrderNumber: 'Order 123',
    VendorCreditDate: '2022-01-01',
  },
  {
    id: '2',
    VendorsName: 'Vendor 2',
    CreditNote: 'Credit Note 2',
    OrderNumber: 'Order 456',
    VendorCreditDate: '2022-02-02',
  },
  {
    id: '3',
    VendorsName: 'Vendor 3',
    CreditNote: 'Credit Note 3',
    OrderNumber: 'Order 45',
    VendorCreditDate: '2022-02-03',
  },
  {
    id: '4',
    VendorsName: 'Vendor 4',
    CreditNote: 'Credit Note 4',
    OrderNumber: 'Order 789',
    VendorCreditDate: '2022-03-04',
  },
  {
    id: '5',
    VendorsName: 'Vendor 5',
    CreditNote: 'Credit Note 5',
    OrderNumber: 'Order 1011',
    VendorCreditDate: '2022-04-05',
  },
  {
    id: '6',
    VendorsName: 'Vendor 6',
    CreditNote: 'Credit Note 6',
    OrderNumber: 'Order 1213',
    VendorCreditDate: '2022-05-06',
  },
  {
    id: '7',
    VendorsName: 'Vendor 7',
    CreditNote: 'Credit Note 7',
    OrderNumber: 'Order 1415',
    VendorCreditDate: '2022-06-07',
  },
  {
    id: '8',
    VendorsName: 'Vendor 8',
    CreditNote: 'Credit Note 8',
    OrderNumber: 'Order 1617',
    VendorCreditDate: '2022-07-08',
  },
  {
    id: '9',
    VendorsName: 'Vendor 9',
    CreditNote: 'Credit Note 9',
    OrderNumber: 'Order 1819',
    VendorCreditDate: '2022-08-09',
  },
  {
    id: '10',
    VendorsName: 'Vendor 10',
    CreditNote: 'Credit Note 10',
    OrderNumber: 'Order 2021',
    VendorCreditDate: '2022-09-10',
  },
];




@Component({
 
  selector: 'app-listpr-packages-credit-notes',
  templateUrl: './listpr-packages-credit-notes.component.html',
  styleUrls: ['./listpr-packages-credit-notes.component.scss'],
  encapsulation  : ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations     : fuseAnimations

})
export class ListprPackagesCreditNotesComponent implements OnInit, AfterViewInit {

  
  displayedColumns: string[] = ['VendorsName', 'CreditNote', 'OrderNumber', 'VendorCreditDate', 'actions'];

  dataSource: MatTableDataSource<UserData>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  flashMessage: 'success' | 'error' | null = null;
  isLoading: boolean = false;

  VendorsCreditForm: UntypedFormGroup;
  private _activatedRoute: any;
  

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _router : Router,
    private _formBuilder: UntypedFormBuilder,
    
  ) {


    // Assign the data to the data source for the table to render
    this.dataSource = new MatTableDataSource(VENDORS_CREDIT_DATA);

   }

  ngOnInit(): void {
    this.VendorsCreditForm = this._formBuilder.group({
      id               : [''],
      category         : [''],
      name             : ['', [Validators.required]],
      description      : [''],
      tags             : [[]],
      sku              : [''],
      barcode          : [''],
      brand            : [''],
      vendor           : [''],
      stock            : [''],
      reserved         : [''],
      cost             : [''],
      basePrice        : [''],
      taxPercent       : [''],
      price            : [''],
      weight           : [''],
      thumbnail        : [''],
      images           : [[]],
      currentImageIndex: [0], // Image index that is currently being viewed
      active           : [false]
  });
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  
createVendorsCredit(){
  this._router.navigate(['apps/pr-packages-creditNotes', 'NEW'], {relativeTo: this._activatedRoute});

}
updateVendorsCredit(){

}
deleteVendorsCredit(){

}
showDetails(){
  
}


applyFilter(event: Event) {
  const filterValue = (event.target as HTMLInputElement).value;
  this.dataSource.filter = filterValue.trim().toLowerCase();

  if (this.dataSource.paginator) {
    this.dataSource.paginator.firstPage();
  }
}
}

/** Builds and returns a new User. */



