import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../auth.service';
import { UserMockApi } from 'app/mock-api/common/user/api';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private _userMockAPi: UserMockApi,
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    
    const requiredRoles = next.data.required_roles as string[];
    const user = this._userMockAPi.getUser();  // Fetch the user directly from UserMockApi
    const userRoles = user.roles || [];  // Ensure roles are an array

  

    if (!this.authService._authenticated) {
      this.router.navigate(['/login']);
      return of(false);
    }

    if (this.isStringArray(userRoles) && this.hasRequiredRoles(userRoles, requiredRoles)) {
      return of(true);
    } else {
      this.router.navigate(['/402-no-permission']);
      return of(false);
    }
  }

  private isStringArray(value: any): value is string[] {
    return Array.isArray(value) && value.every(item => typeof item === 'string');
  }

  private hasRequiredRoles(userRoles: string[], requiredRoles: string[]): boolean {
    if (!requiredRoles || requiredRoles.length === 0) {
      return true;
    }
    // Check if any required role is present in user roles
    return requiredRoles.some(role => userRoles.includes(role));
  }
}
