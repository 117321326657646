
<!-- intial_stock_form form -->

<div class="w-full h-full w-full">
    <form
        [formGroup]="intial_stock_form"
        class=" rounded shadow-lg h-full"
    >
 
        <div *ngIf="intial_stock_form" class="p-8">
           
            <div class="flex justify-end">
                <button mat-raised-button [routerLink]="['..']">
                    <span class="inline-flex items-center">
                        <mat-icon svgIcon="feather:x"></mat-icon>
                    </span>
                </button>
            </div>

            <div class="flex flex-col mt-8">
                <p class="text-lg font-medium">Basic information</p>
                <p class="text-secondary mt-3">Set your inventory sheet</p>
                <mat-divider class="mt-5 mb-5"></mat-divider>
                
                <div class="flex mb-4"
                >
                    <mat-form-field style="max-width: 600px" class="flex-auto">
                        <mat-label>Product Number</mat-label>
                        <input
                            matInput
                            type="text"
                            placeholder="Add Product Number"
                            formControlName="productNumber"
                            aria-label="Customers"
                        />

                        <mat-icon
                            class="icon-size-5"
                            matPrefix
                            [svgIcon]="'heroicons_solid:user'"
                        ></mat-icon>
                    </mat-form-field>
                </div>
                <div class="flex mb-4">
                    <mat-form-field style="max-width: 600px" class="flex-auto">
                        <mat-label>Product Name</mat-label>
                        <input
                            matInput
                            type="text"
                            placeholder="Add Product Name"
                            formControlName="productName"
                            aria-label="Customers"
                        />

                        <mat-icon
                            class="icon-size-5"
                            matPrefix
                            [svgIcon]="'heroicons_solid:user'"
                        ></mat-icon>
                    </mat-form-field>
                </div>
                <div class="flex mb-4">
                    <mat-form-field style="max-width: 600px" class="flex-auto">
                        <mat-label>Cost Per Item</mat-label>
                        <input
                            matInput
                            type="text"
                            placeholder="Cost Per Item"
                            formControlName="costPerItem"
                            aria-label="Customers"
                        />

                        <mat-icon
                            class="icon-size-5"
                            matPrefix
                            [svgIcon]="'heroicons_solid:user'"
                        ></mat-icon>
                    </mat-form-field>
                </div>
                <div class="flex mb-4">
                    <mat-form-field style="max-width: 600px" class="flex-auto">
                        <mat-label>Stock Quantity</mat-label>
                        <input
                            matInput
                            type="text"
                            placeholder="Stock Quantity"
                            formControlName="stockQuantity"
                            aria-label="Customers"
                        />

                        <mat-icon
                            class="icon-size-5"
                            matPrefix
                            [svgIcon]="'heroicons_solid:user'"
                        ></mat-icon>
                    </mat-form-field>
                </div>
                <div class="flex mb-4">
                    <mat-form-field style="max-width: 600px" class="flex-auto">
                        <mat-label>Re-Order Quantity</mat-label>
                        <input
                            matInput
                            type="text"
                            placeholder="Re-OrderQuantity"
                            formControlName="reorderQuantity"
                            aria-label="Customers"
                        />

                        <mat-icon
                            class="icon-size-5"
                            matPrefix
                            [svgIcon]="'heroicons_solid:user'"
                        ></mat-icon>
                    </mat-form-field>
                </div>
              
                <div class="flex mb-2">
                    <mat-form-field
                        style="max-width: 800px"
                        class="flex-auto"
                    >
                        <mat-label>Description</mat-label>
                        <textarea
                            matInput
                            placeholder="Description"
                            formControlName="description"
                            class="elevated-input"
                        ></textarea>
                        <mat-icon
                            class="icon-size-5"
                            matPrefix
                            svgIcon="feather:file-text"
                        ></mat-icon>
                    </mat-form-field>
                </div>
                <!-- Additional Fields -->

                <!-- <div class="flex mb-4">
            <mat-form-field class="flex-auto">
              <mat-label>Influencer phone</mat-label>
              <input matInput placeholder="(000) 000-000-0000" name="phone" [formControlName]="'phoneNumbers'"
                class="elevated-input" mask="(000) 000-000-0000" required maskDirective />
              <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:phone'"></mat-icon>
            </mat-form-field>
          </div> -->
                <!-- <div class="flex mb-4">
                    <mat-form-field class="flex-auto">
                        <mat-label></mat-label>
                        <mat-select placeholder="Cup Size" name="gender" [formControlName]="'cup_size'"
                            class="elevated-input" required>
                            <mat-option value="A">A</mat-option>
                            <mat-option value="B">B</mat-option>
                            <mat-option value="C">C</mat-option>
                            <mat-option value="D">D</mat-option>

                        </mat-select>
                        <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:translate'"></mat-icon>
                    </mat-form-field>
                </div> -->

                <!-- <div class="flex mb-4">
                    <mat-form-field class="flex-auto">
                        <mat-label>Height</mat-label>
                        <input matInput placeholder="Height" required name="sub_total" [formControlName]="'height'"
                            class="elevated-input" />
                        <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:mail'"></mat-icon>
                    </mat-form-field>
                </div>
                <div class="flex mb-4">
                    <mat-form-field class="flex-auto">
                        <mat-label>Length</mat-label>
                        <input matInput placeholder="Length" required name="sub_total" [formControlName]="'length'"
                            class="elevated-input" />
                        <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:mail'"></mat-icon>
                    </mat-form-field>
                </div>
                <div class="flex mb-4">
                    <mat-form-field class="flex-auto">
                        <mat-label>Fit</mat-label>
                        <input matInput placeholder="Fit" required name="type" [formControlName]="'fit'"
                            class="elevated-input" />
                        <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:mail'"></mat-icon>
                    </mat-form-field>
                </div> -->



                <!-- Add Product Bundle Details here -->

                <div class="flex items-center justify-end mt-10">
                    <button
                        (click)="sumbitsizeInfo()"
                        class="px-6 py-2 rounded text-white bg-blue-500 hover:bg-blue-600"
                        mat-flat-button
                        [color]="'primary'"
                    >
                        Save
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>
