import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { server_env } from 'config';
import {
  ApexAxisChartSeries,
  ApexDataLabels,
  ApexFill,
  ApexYAxis,
  ApexXAxis,
  ApexStroke,
  ApexLegend,
  ApexChart
} from "ng-apexcharts";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  fill: ApexFill;
  legend: ApexLegend;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  colors: any;
  height: 500,
};

export type ChartOptionsScatterPlot = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
};

@Component({
  selector: 'app-detailinventroy-sheet',
  templateUrl: './detailinventroy-sheet.component.html',
  styleUrls: ['./detailinventroy-sheet.component.scss']
})
export class DetailinventroySheetComponent implements OnInit {
  detail: any;
  report: any;
  history: any;
  stockHistory: any;
  dataChartProduct: any;
  id: any;
  sizeChartUrl: string;
  showChart: boolean = false;
  public chartMonths: Partial<ChartOptions>;
  public chartYears: Partial<ChartOptions>;
  public commonOptions: Partial<ChartOptions> = {
    dataLabels: {
      enabled: false
    },
    fill: {
      opacity: 1,
      type: "solid"
    },
    xaxis: {
      type: "datetime"
    },
    stroke: {
      width: 0,
      curve: "smooth"
    },
    chart: {
      type: 'line',
      height: '100%',
    }
  };



  constructor(
    private route: ActivatedRoute,
  ) {

    this.route.data.subscribe((data) => {
      this.dataChartProduct = data.task.chartData.sales_data;
      this.stockHistory = data.task.stockHistoryData;
      this.report = data.task.chartData;
      this.history = data.task.chartData.activities;
      this.detail = data.task.variationData;
      const url = this.detail.base_product.size_chart;
      this.sizeChartUrl = `${server_env.URL_MEDIA}${url}`;





    });




  }

  ngOnInit(): void {

    if (this.dataChartProduct) {
      this.initCharts();


    }



  }


  public initCharts(): void {
    // Check if dataChartProduct exists
    if (!this.dataChartProduct) {
      console.error('dataChartProduct is not defined');
      return;
    }

    console.log('Data for today:', this.dataChartProduct.today);
    console.log('Data for this year:', this.dataChartProduct.this_year);

    // Patching data for chartMonths
    this.chartMonths = {
      series: [
        {
          name: "sales",
          data: this.dataChartProduct.today?.map((item) => ({ x: item.x, y: item.y })) || []
        }
      ],
      chart: {
        id: "chartMonths",
        type: "area",
        height: 290,
        width: 390,
        background: "#F6F8FA",
        toolbar: {
          show: false,
          autoSelected: "pan"
        },
        events: {
          // Your event handlers
        }
      },
      colors: ["#FF7F00"],
      yaxis: {
        show: false,
        tickAmount: 3
      }
    };

    // Patching data for chartYears
    this.chartYears = {
      series: [
        {
          name: "commits",
          data: this.dataChartProduct.this_year?.map((item) => ({ x: item.x, y: item.y })) || []
        }
      ],
      chart: {
        height: 290,
        width: 390,
        type: "area",
        background: "#F6F8FA",
        toolbar: {
          autoSelected: "selection"
        },
        brush: {
          enabled: true,
          target: "chartMonths"
        },
        selection: {
          enabled: true,
          xaxis: {
            min: new Date("26 Jan 2014").getTime(),
            max: new Date("29 Mar 2015").getTime()
          }
        }
      },
      colors: ["#7BD39A"],
      legend: {
        position: "top",
        horizontalAlign: "left"
      }
    };
  }



}
