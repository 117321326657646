import { Route } from '@angular/router';
import { ListprPackagesComponent } from './listpr-packages/listpr-packages.component';
import { CreateprPackagesComponent } from './createpr-packages/createpr-packages.component';
import { DetailprPackagesComponent } from './detailpr-packages/detailpr-packages.component';
import { SalesOrderResolver } from './pr.packages.resolver';




export const PrPackagesRoute: Route[] = [
   
        {
            path     : '',
            component : ListprPackagesComponent,
            resolve: {
                salesOrders: SalesOrderResolver
              }
        },
        {
            path         : 'NEW',
            component    : CreateprPackagesComponent,
           
        },
        {
            path         : ':id',
            component    :DetailprPackagesComponent,
          
          
        },
]
