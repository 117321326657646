import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FuseCardComponent } from '@fuse/components/card';
import { ConfirmPopupComponent } from 'app/confirm-popup/confirm-popup.component';
import { PackagesOderService } from '../packages.service';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { Observable, throwError } from 'rxjs';
import { PackageType } from '../package.type';

@Component({
    selector: 'app-shipped-popup',
    templateUrl: './shipped-popup.component.html',
    styleUrls: ['./shipped-popup.component.scss'],
})
export class ShippedPopupComponent implements OnInit {
    showMessage: boolean = false;
    formCancel: boolean = true;
    packageGroup: FormGroup;
    packageOrderCode: string;
    counter: number = 0;
    vendor: any;
    saleOderId: string;

    @ViewChild('fuseCard') fuseCard: FuseCardComponent;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _matDialog: MatDialogRef<ConfirmPopupComponent>,
        private _fb: FormBuilder,
        private datePipe: DatePipe,
        private _packageService: PackagesOderService,
        private _router: Router
    ) {
        this.saleOderId = this.data.id;
        this.fetchProductDetails(this.saleOderId);
    }

    ngOnInit(): void {
        //recently added this
        this.generatePackageCode();

        this.packageGroup = this._fb.group({
            shipOrder: new FormControl(this.generatePackageCode()),
            packageOrder: new FormControl(''),
            packageDate: new FormControl(new Date(), [Validators.required]),
            products: this._fb.array([this.productArray()]),
        });
    }
    productArray() {
        return this._fb.group({
            productName: new FormControl(''),
            quantity: new FormControl(''),
            amount: new FormControl(''),
        });
    }

    generatePackageCode(): string {
        this.counter++;
        const randomPart = Array.from(
            { length: 6 },
            () => Math.random().toString(36).toUpperCase()[2]
        ).join('');
        const packageCode = `SHIP-${String(this.counter).padStart(
            4,
            '0'
        )}-${randomPart}`;
        return packageCode;
    }

    fetchProductDetails(id: string): void {
        // Use your product service to fetch the product details based on the provided ID
        this._packageService.getPackageOderByID(id).subscribe(
            (data) => {
                this.vendor = data;
                this.addProductValue(this.vendor);
                this.packageGroup
                    .get('packageOrder')
                    .patchValue(this.vendor.packageOrder);
                this.packageGroup.setControl(
                    'products',
                    this.setProduct(this.vendor.salesOrder.products)
                );

                // Iterate through the received data and patch the values into the form controls

                // this.product.thumbnail = this.getThumbnailUrl(data.thumbnail); // Update the thumbnail URL
            },

            (error) => {
                console.error('Error fetching product details:', error);
            }
        );
    }
    setProduct(product: any[]): FormArray {
        const productFormArray: any = new FormArray([]);
        if (!product) {
            throwError('Product Unavailable');
        }
        product.forEach((element) => {
            productFormArray.push(
                this._fb.group({
                    productName: element.product.productName,
                    quantity: element.quantity,
                    amount: element.amount,
                })
            );
        });
        return productFormArray;
    }

    addProductValue(vendor: any) {
        if (!vendor) {
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer);
                    toast.addEventListener('mouseleave', Swal.resumeTimer);
                },
            });

            Toast.fire({
                icon: 'error',
                title: 'Error creating Shippment',
                text: JSON.stringify('Sorry, Unable to Create shippment'),
            });
            // uncomment this line when the data of the sales oder  is available

            this._matDialog.close(true);
        }

    }
    async save() {
        if (this.packageGroup.valid) {
            // Show the loading indicator
            const packageData = { ...this.packageGroup.value };
            const packageDatetoformat =
                this.packageGroup.get('packageDate')?.value;
            packageData.packageDate = this.datePipe.transform(
                packageDatetoformat,
                'yyyy-MM-dd'
            );
            packageData.salesOrder = this.vendor.salesOrder.id;
            packageData.packageOrder = this.vendor.packageOrder;
            packageData.isPackaged = this.vendor.isPackaged; //set isPackage to true as it is confirmed
            packageData.isShipped = true; //set isPackage to true as it is confirmed
            packageData.infulencerOrder = ''; // while creating the salesOder package, influncer oder is empty
            packageData.id = parseInt(this.saleOderId);

            // Update the contact on the server

            this._packageService
                .updatePackageOrder(packageData.id, packageData)
                .then((response) => {
                    if (response.success) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Success!',
                            text: `${packageData.packageOrder} order Shipped Successful.`,
                            showConfirmButton: false, // No OK button
                            backdrop: true, // Blurred background
                            allowOutsideClick: false, // Prevents clicking outside to close the modal
                            position: 'top-end', // Center the modal on the screen
                            toast: true, // Display as a toast (centered)
                            timer: 3000, // Auto-close after 3 seconds (adjust as needed)
                        });
                    } else {
                        const errorMessages = response.errors;
                        const Toast = Swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener(
                                    'mouseenter',
                                    Swal.stopTimer
                                );
                                toast.addEventListener(
                                    'mouseleave',
                                    Swal.resumeTimer
                                );
                            },
                        });

                        Toast.fire({
                            icon: 'error',
                            title: 'Error creating shippment',
                            text: JSON.stringify(errorMessages),
                        });
                    }
                })
                .catch((error) => {
                    // Hide the loading indicator
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener(
                                'mouseenter',
                                Swal.stopTimer
                            );
                            toast.addEventListener(
                                'mouseleave',
                                Swal.resumeTimer
                            );
                        },
                    });

                    Toast.fire({
                        icon: 'error',
                        title: `Error creating shippment : ${error.message}`,
                    });
                });
        } else {
            // Form is not valid, handle accordingly
            // For example, you can show a toast message or perform other actions
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer);
                    toast.addEventListener('mouseleave', Swal.resumeTimer);
                },
            });

            Toast.fire({
                icon: 'error',
                title: 'Invalid form data',
                text: 'Please fill in all the required fields correctly.',
            });
        }
        //  uncomment below line when you wrote all the task for save ()
        this._matDialog.close({ saved: true });
        this._router.navigate(['apps/packages']);
    }

    trackByFn(index: number, item: any): any {
        return item.id || index;
    }
}
