import { Route } from '@angular/router';


import { ListExchangesComponent } from './list-exchanges/list-exchanges.component';
import { CreateExchangesComponent } from './create-exchanges/create-exchanges.component';
import { DetailExchangesComponent } from './detail-exchanges/detail-exchanges.component';
import { UnsavedChangesGuard } from 'app/page-detect/wm-can-leave/detect-changes.guard';
import { ExchangeOrderResolver } from './exchanges-resolver';



export const ExchangesRoutes: Route[] = [
   
        {
            path     : '',
            component: ListExchangesComponent,
            resolve: {
                exchange: ExchangeOrderResolver
              }
        },
        {
            path         : 'NEW',
            component    : CreateExchangesComponent,
            canDeactivate:[UnsavedChangesGuard]
           
        },
        {
            path         : ':id',
            component    :DetailExchangesComponent,
          
          
        },
]
