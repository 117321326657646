import {
    AfterViewInit,
    Component,
    ElementRef,
    OnInit,
    ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

import { ActivatedRoute, Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { CreditNotes } from '../credit-notes.type';
import { forkJoin, Observable } from 'rxjs';
import { CreditNotesService } from '../credit-notes.service';
import { take } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material/dialog';
import { RefundPopupComponent } from '../refund-popup/refund-popup.component';
import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';
import { PackagesOderService } from 'app/packages/packages.service';
import * as ExcelJS from 'exceljs';
import { RoleService } from 'app/core/auth/guards/role.service';


export interface CredtiNotesData {
    customerId: string;
    customerName: string;
    status: string;
    voucher_order_num:string,
    totalCost: number;
    voucher_order_date?:any;
    voucher_type?:any;
}

@Component({
    selector: 'app-list-credit-notes',
    templateUrl: './list-credit-notes.component.html',
    styleUrls: ['./list-credit-notes.component.scss'],
})
export class ListCreditNotesComponent implements OnInit, AfterViewInit {
    displayedColumns: string[] = [
        "select",
        'CustomerId',
        'CustomerName',
        'SaleNumber',
        'history',
        'Status',
        'Shipping_Date',
        "Delivery Type",
        'total',
        'create',
    ];
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    creditNotes: CreditNotes[];
    dataSource: MatTableDataSource<CredtiNotesData>;
    requiredRoles: string[] = ["Manager", "Accountant"];  // Define the roles allowed to execute the function

    creditnotes$: Observable<CreditNotes[]>;
    flashMessage: 'success' | 'error' | null = null;
    isLoading: boolean = true;
    checked = false;
    selection = new SelectionModel<CredtiNotesData>(true, []);

    totalRows: number = 0;
    selectedShippingArea: string;
    selectedDeliveryType: string;
    selectedRowIds: any[] = [];
    selectedDate: any;
    counter:number = 0;
    vendor: CreditNotes;
    @ViewChild('printableContent') printableContent: ElementRef;

    constructor(
        private _router: Router,
        private _activatedRoute: ActivatedRoute,
        private _creditnotesService: CreditNotesService,
        private dialog: MatDialog,
        private datePipe: DatePipe,
        private _packageService: PackagesOderService,
        private roleService: RoleService,


    ) {
       
    }

    ngOnInit(): void {

        this.loadData();
     }

    ngAfterViewInit() {
        setTimeout(() => {
         
        }, 400);
    }

    updateVendorsCredit(data) {
        if (data) {
            const navigationExtras = {
                relativeTo: this._activatedRoute,
                state: { yourDataKey: data }
            };

            this._router.navigate(['../credit-notes/NEW'], navigationExtras);
        }



    }

    deleteVendorsCredit(id: any) {
        this.roleService.withRoleCheck(this.requiredRoles, async () => {

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                this._creditnotesService.deleteCreditNotes(id).subscribe((isDeleted) => {
                    this.creditnotes$ = this._creditnotesService
                        .getCreditNotes()
                        .pipe(take(1));
                        this.loadData();
                    if (!isDeleted) {
                        Swal.fire("Error!", "Cannot Delete");
                        return;
                    }
                    Swal.fire(
                        'Deleted!',
                        'Credit Note has been deleted.',
                        'success'
                    )
                })
            }
        });
    });
    }

    showDetails(creditNotesId: any) {
        
        if (creditNotesId !== undefined) {
            this._router.navigate(['apps/credit-notes', creditNotesId]);
        }
    }


    async multipleInvoice() {
        const ids = this.selectedRowIds;
        let combinedContent = ''; // Variable to accumulate content for all pages
    
        // Iterate over each selected ID
        for (const id of ids) {
            try {
                const data = await this._creditnotesService.getSalesOrderById(id).toPromise();
                this.vendor = data; // Set vendor data for the app-invoice component
    
                await this.delay(100);
    
                const printableContent = this.printableContent.nativeElement.innerHTML;
    
                combinedContent += `<div class="page-container">${printableContent}</div><div class="page-break"></div>`;
            } catch (error) {
                console.error('Error fetching sales order:', error);
            }
        }
    
        // Create a new div element for the combined content
        const combinedPageContent = document.createElement('div');
        combinedPageContent.innerHTML = combinedContent;
    
        // Append the new combined content to the document body
        document.body.appendChild(combinedPageContent);
    
        // Trigger print operation
        window.print();
    
        // Remove the appended content after printing to avoid duplication
        document.body.removeChild(combinedPageContent);
    
        // Wait for a longer time to ensure the print dialog opens and user interacts with it
        await this.delay(1000); // Adjust the delay as needed
    }

    
    delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }



  
    












    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }


    createSalesExchange() {
        this.roleService.withRoleCheck(this.requiredRoles, async () => {

        this._router.navigate(['apps/credit-notes', 'NEW']);
        });
    }



   updateSelectedRowIds(row: any): void {
        const index = this.selectedRowIds.indexOf(row.id);

        if (index >= 0) {
            // If the ID is already in the array, remove it
            this.selectedRowIds.splice(index, 1);
        } else {
            // If the ID is not in the array, add it
            this.selectedRowIds.push(row.id);
        }

        console.log('Updated selectedRowIds:', this.selectedRowIds);
    }

    isAllSelected() {
        if (!this.dataSource || !this.dataSource.data) {
            // If dataSource or dataSource.data is undefined or null, return false
            return false;
        }

        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected > 0 && numSelected === numRows;
    }


    /** Selects all rows if they are not all selected; otherwise clear selection. */
    toggleAllRows() {
        if (this.isAllSelected()) {
            this.selection.clear();
            this.selectedRowIds = []; // Clear selectedRowIds when deselecting all
        } else {
            this.dataSource.data.forEach(row => {
                this.selection.select(row); // Select all rows in the dataSource
                this.updateSelectedRowIds(row); // Update selectedRowIds for each row
            });
        }
    }

    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: CredtiNotesData): string {
        if (!row) {
            const result = `${this.isAllSelected() ? 'deselect' : 'select'} all`;
            return result;
        }
        const result = `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.customerId + 1}`;
        return result;
    }


    trackByProduct(index: number, product): string {
        return product.id;
    }

    applyFilters() {
        // Get the filter strings for shipping area and delivery type
        const shippingAreaFilter = this.selectedShippingArea ? this.selectedShippingArea.trim().toLowerCase() : '';
        const deliveryTypeFilter = this.selectedDeliveryType ? this.selectedDeliveryType.trim().toLowerCase() : '';
        const selectedDateFilter = this.selectedDate ? this.datePipe.transform(this.selectedDate, 'yyyy-MM-dd') : '';

        // Apply filter only if either shipping area, delivery type, or date is selected
        if (shippingAreaFilter || deliveryTypeFilter || selectedDateFilter) {
            // Set the custom filter function
            this.dataSource.filterPredicate = (data, filter) => {
              ;
                const dateMatch = data.voucher_order_date.includes(selectedDateFilter); // Assuming shippingDate is in 'YYYY-MM-DD' format

                return   dateMatch;
            };

            // Combine filter strings for logging
            const combinedFilterString = `${shippingAreaFilter} ${deliveryTypeFilter} ${selectedDateFilter}`;

            // Apply the combined filter
            this.dataSource.filter = combinedFilterString;
        } else {
            // Clear filter if neither shipping area, delivery type, nor date is selected
            this.dataSource.filter = '';
        }
    }

    openRefundPackage(id: any) {
        this.roleService.withRoleCheck(this.requiredRoles, async () => {

        const dialogRef = this.dialog.open(RefundPopupComponent, {
            width: '900px',
            data: { id },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result && result.saved === true) {
                this.loadData();
              
            } else {
            }
        });
    });
    
    }


    loadData(){
        // Assign the data to the data source for the table to render
        this.creditnotes$ = this._creditnotesService
        .getCreditNotes()
        .pipe(take(1));
    this.creditnotes$.subscribe((salesorder) => {
        this.creditNotes = salesorder['data'];
        this.dataSource = new MatTableDataSource<CredtiNotesData>(
            this.creditNotes.map((salesOrder) => {

                return {
                    customerId: salesOrder.Customers?.id, // Null check for Customers
                    customerName: salesOrder.Customers?.firstName,
                    voucher_order_num: salesOrder.voucher_order_num,
                    status: salesOrder.status,
                    total: salesOrder.total,
                    id: salesOrder.id,
                    history: salesOrder.history,
                    deliverd: salesOrder.isDelivered,
                    totalCost: salesOrder.total,
                    voucher_type:salesOrder.voucher_type,
                    voucher_order_date:salesOrder.voucher_order_date,
                };
            })
        );
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.isLoading=false;
    });
 
   }
}
