import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, 
 FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { Subscription } from 'rxjs';
import { VendorsPaymentService } from '../vendors-payment.service';

@Component({
  selector: 'app-vendors-payment',
  templateUrl: './vendors-payment.component.html',
  styleUrls: ['./vendors-payment.component.scss']
})
export class VendorsPaymentComponent implements OnInit {
  vendorsCredit: FormGroup;
  credi_note: any;
  credit_noteTotal: any;
  creditNotesSubscription: Subscription;
  paymentOptions = [
    "SIDDHARTHA BANK FONEPAY - HEADQUARTER",
    "SIDDHARTHA BANK FONEPAY - JHAMSIKHEL",
    "NIC ASIA BANK FONEPAY - HEADQUARTER",
    "NIC ASIA BANK FONEPAY",
  ];
  vendor_name: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { id: any; total: number,vendor_name:any },
    private _matDialog: MatDialogRef<VendorsPaymentComponent>,
    private _fb: FormBuilder,
    private datePipe: DatePipe,
    private _router: Router,
    private _cdr: ChangeDetectorRef,
    private _expenseOrderService:VendorsPaymentService,
  ) { }


  ngOnInit(): void {
    this.vendorsCredit = this._fb.group({
      approved_expense_date: [null, Validators.required],
      approved_payment_method: [null, Validators.required],
      approved_account:[true]
    });

    this.creditNotesSubscription = this.vendorsCredit.valueChanges.subscribe((formValue) => {
      this._cdr.detectChanges();

    });

  
    // Use the total value as needed
    if (this.data && this.data.total !== undefined) {
      this.credit_noteTotal = this.data.total;
      this.vendor_name = this.data.vendor_name
    } else {
    }
  }

  ngOnDestroy() {
    if (this.creditNotesSubscription) {
      this.creditNotesSubscription.unsubscribe();
    }
  }





  async saveRefund() {
    if (!this.vendorsCredit.valid) {
        this.handleInvalidForm();
        return;
    }

    const refundData = this.prepareRefundData();

    try {
        const response = await this._expenseOrderService.PartialupdateExpense(this.data.id, refundData);

        if (response && response.success) {
            this.showSuccessToast('Payment Approved.');
            this._router.navigate(['apps/vendors-payment']);
            this._matDialog.close({ saved: true });
        } else {
            this.handleErrorToast('Failed to approve payment.');
        }
    } catch (error) {
        this.handleErrorToast(error.message);
    }
}

  prepareRefundData() {
    const refundData = { ...this.vendorsCredit.value };
    refundData.approved_expense_date = this.datePipe.transform(this.vendorsCredit.get('approved_expense_date')?.value, 'yyyy-MM-dd');
    return refundData;
  }

  handleInvalidForm() {
    this.showErrorToast('Invalid form data', 'Please fill in all the required fields correctly.');
    this._router.navigate(['apps/vendors-expenses']);
  }

  handleApiErrors(errors) {
    const errorMessage = JSON.stringify(errors);
    this.showErrorToast('Error Approving Expense', errorMessage);
  }

  showSuccessToast(message: string) {
    this.showToast('success', message);
  }

  handleErrorToast(message: string) {
    this.showToast('error', `Error Approving Expense: ${message}`);
  }

  showErrorToast(title: string, text: string) {
    this.showToast('error', title, text);
  }

  showToast(icon: any, title: string, text?: string) {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: icon,
      title: title,
      text: text,
    });
  }


}
