import {
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import {
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, Subject, of, throwError } from 'rxjs';
import { debounceTime, map, startWith, take, takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';

import { SalesOrder } from 'app/sales-order/sales-order.type';
import { SalesReturnService } from '../sales-return.service';
import { SalesOrderService } from 'app/sales-order/sales-order.service';
import { DatePipe } from '@angular/common';
import { MatDrawer } from '@angular/material/sidenav';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { HasUnsavedChanges } from 'app/page-detect/wm-can-leave/detect-changes.guard';
import { CreditNotesService } from 'app/credit-notes/credit-notes.service';
export interface SalesOrderOption {
    sales_order_num: string;
}
@Component({
    selector: 'app-create-sales-return',
    templateUrl: './create-sales-return.component.html',
    styleUrls: ['./create-sales-return.component.scss'],
})
export class CreateSalesReturnComponent implements OnInit, OnDestroy, HasUnsavedChanges {
    @ViewChild('matDrawer', { static: true }) matDrawer: MatDrawer;
    enablePartialReturns: boolean = false;
    saleOrderIDToReturn: string
    currentSales: any;
    maxDate = new Date();
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    vendorsCredit: FormGroup;
    salesOrder$: Observable<SalesOrder[]>;
    selectedSalesOrder: SalesOrder;
    SaleOrderNumberControl: FormControl = new FormControl();
    filteredSalesOrder$: Observable<SalesOrder[]>;
    drawerMode: string;
    current_stock_sales: { [key: number]: number } = {}; // Store current stock for each product
    selectedSales: any;
    saleProduct: any;

    successMessage: string;
    errorMessage: string;
    isFieldReadOnly: boolean = false;
    Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener(
                'mouseenter',
                Swal.stopTimer
            );
            toast.addEventListener(
                'mouseleave',
                Swal.resumeTimer
            );
        },
    });

    productIds: number[] = [];
    selectedProductThumbnail: string
    selectedProductThumbnailClass: string;
    created: any;
    salesPerson: any;
    formattedTime: string;
    vendor: any;
    currentSalesOrderNum: any;
    salesOrderData: any[] = []; // Store the sales order data locally
    currentSalesNum: any;
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _formBuilder: FormBuilder,
        private _router: Router,
        private _salesreturnService: SalesReturnService,
        private _salesOrderService: SalesOrderService,
        private datePipe: DatePipe,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private cd: ChangeDetectorRef,
        private _creditNoteService: CreditNotesService,


    ) {
        // Sales Order Service Called for dynamic field


        this._salesOrderService.getSalesOrder_number().subscribe(
            (response: any) => {
                if (response.success && response.data !== undefined) {
                    this.currentSalesOrderNum = response.data;

                    this.currentSalesNum = (`SO-00${this.currentSalesOrderNum}-A`);
                } else {
                    console.error('Invalid response format:', response);
                }
            },
            (error) => {
                console.error('Error fetching sales order numbers:', error);
            }
        );
    }

    ngOnInit() {
        // Fetch and store sales order data on component initialization
        this._salesOrderService.getSalesOrder().pipe(take(1)).subscribe((salesOrder) => {
            if (Array.isArray(salesOrder['data'])) {
                this.salesOrderData = salesOrder['data'];
            } else {
                console.error('SalesOrder data is not available:', salesOrder);
            }
        });

        this.filteredSalesOrder$ = this.SaleOrderNumberControl.valueChanges.pipe(
            startWith(''),           // Start with an empty string to show all initially
            debounceTime(300),       // Debounce user input
            map((searchTerm) => this.filterSalesOrders(searchTerm)) // Filter using preloaded data
        );



        // Define Form fields
        this.vendorsCredit = this._formBuilder.group({
            sales_order_num: this.SaleOrderNumberControl,
            customerControl: new FormControl(),
            products: this._formBuilder.array([this.productArray()]),
            rfr: new FormControl("", Validators.required),
            enablePartialReturn: [false],
            customer: [''],
            total: [0],
            sub_total: [0],
            adjustment: [0],
            discount: [0],
            discountSuffix: [''],
            adjustmentSuffix: [''],
            return_products: this._formBuilder.array([]),

            date: new FormControl(new Date(), [Validators.required]),


        });

        this.matDrawer.openedChange.subscribe((opened) => {
            if (!opened) {

                this._changeDetectorRef.markForCheck();
            }
        });


        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {

                if (matchingAliases.includes('lg')) {
                    this.drawerMode = 'side';
                }
                else {
                    this.drawerMode = 'over';
                }

                this._changeDetectorRef.markForCheck();
            });


        const productArray = this.vendorsCredit.get('products') as FormArray;
        productArray.controls.forEach((control: FormGroup) => {
            control.get('returnSelection').valueChanges.subscribe(() => {
                this.calculateTotalApplied();
            });

        });

    }


    filterSalesOrders(searchTerm: string): any[] {
        // If the search term is empty, return all sales orders
        if (!searchTerm) {
            return this.salesOrderData;
        }

        // Otherwise, filter based on the search term
        const filterValue = searchTerm.toLowerCase();
        return this.salesOrderData.filter(order =>
            order.sales_order_num.toLowerCase().includes(filterValue) &&
            (
                order.status === 'Sales Order Delivered' ||
                order.status === 'Partial Returned Sales Order'
            )
        );
    }
    subscribeToFormChanges() {
        const productArray = this.vendorsCredit.get('products') as FormArray;

        productArray.controls.forEach((control: FormGroup) => {
            // Subscribe to sale_quantity changes
            control.get('sale_quantity')?.valueChanges.subscribe(() => {
                this.calculateTotalApplied();
            });

            // Subscribe to sale_rate changes
            control.get('sale_rate')?.valueChanges.subscribe(() => {
                this.calculateTotalApplied();
            });

            // Subscribe to returnSelection changes
            control.get('returnSelection')?.valueChanges.subscribe(() => {
                this.calculateTotalApplied();
            });
        });
    }
    hasUnsavedChanges(): boolean {
        return this.vendorsCredit.dirty;
    }

    canDeactivate(): boolean {
        return !this.vendorsCredit.dirty || confirm('You have unsaved changes. Are you sure you want to leave?');
    }

    openDrawer() {

        this._salesOrderService.getSalesOrderById(this.saleOrderIDToReturn).subscribe(
            (response: any) => {
                this.vendor = response;
                this.created = response.created;
                this.salesPerson = response.sales_person;

                const timestampString = response.created;
                const dateObject = new Date(timestampString);

                this.created = dateObject.toLocaleDateString(); // "1/2/2024" (adjust based on your locale)
                this.formattedTime = dateObject.toLocaleTimeString(); // "11:36:40 AM" (adjust based on your locale)
                this.cd.detectChanges();
                this.matDrawer.open();
            },
            (error) => {
                console.error('Error fetching sales order by ID', error);
                // Handle error if needed
            }
        );
    }

    closeDrawer() {
        this.matDrawer.close();
    }

    productArray() {
        return this._formBuilder.group({
            id: new FormControl,

            returnSelection: new FormControl(""),
            sale_product: new FormControl(''),

            sale_quantity: new FormControl(''),
            sale_rate: new FormControl(''),
            sale_color: new FormControl(''),
            sale_size: new FormControl(''),
            sale_amount: new FormControl(''),
            sale_index: new FormControl()
        });
    }

    setProduct(product: any[]): FormArray {
        const productFormArray = new FormArray([]);

        // Count the number of products
        const productCount = product.length;

        // Check if there are more than one product
        if (productCount > 1) {
            this.enablePartialReturns = true;
        }

        if (!product) {
            throwError('Product unavailable')
        }
        product.forEach
        product.forEach((element) => {
            productFormArray.push(
                this._formBuilder.group({
                    returnSelection: '',
                    id: element.product.id,
                    sale_product: element.product.base_product.productName,
                    sale_color: element.product.color,
                    sale_size: element.product.size,
                    sale_quantity: element.quantity,
                    sale_amount: element.amount,
                    sale_rate: element.rate,
                })
            );
            this.current_stock_sales[element.product.id] = element.quantity;

        });

        return productFormArray;
    }



    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }


    makeFieldEditable(salesNumberInput: HTMLInputElement) {
        this.isFieldReadOnly = false;
        this.selectedSalesOrder = null; // Assuming `selectedCustomer` is the variable holding the current customer value
        if (salesNumberInput) {
            salesNumberInput.value = ''; // Clear the input value
        }

        // Clear the value of the form control
        this.SaleOrderNumberControl.setValue('');
    }


    async createSalesOrder(customerData) {

        const response = await this._salesOrderService.createSalesOrders(customerData);
        return response

    }


    validateQuantity(index: number): void {
        const productFormArray = this.vendorsCredit.get('products') as FormArray;
        const productGroup = productFormArray.at(index) as FormGroup;

        const control = productGroup.get('sale_quantity');
        const enteredQuantity = parseInt(control.value, 10);
        const productId = productGroup.get('id').value;
        const currentStock = this.current_stock_sales[productId];

        if (isNaN(enteredQuantity)) {
            control.setErrors({ invalidQuantity: true });
        } else if (enteredQuantity > currentStock) {
            control.setErrors({ exceedStock: true });
        } else if (enteredQuantity <= 0) {
            control.setErrors({ negativeQuantity: true });
        } else {
            control.setErrors(null);
            this.calculateTotalApplied();
        }
    }

    async submitVendorsInfo() {
        if (!this.vendorsCredit.valid) {
            this.Toast.fire({
                icon: 'error',
                title: 'Invalid form data',
                text: 'Please fill in all the required fields correctly.',
            });
            return;
        }

        this.initializeCurrentSales();
        let customerData = this.prepareCustomerData();

        let updateSuccess = false;

        if (this.enablePartialReturns) {
            await this.handlePartialReturns(customerData);
            customerData.return_products = this.saleProduct;
            updateSuccess = true;

        } else {
            updateSuccess = await this.handleFullReturns(customerData);
            customerData.return_products = this.saleProduct;
            updateSuccess = true;

        }

        if (!updateSuccess) {
            this.Toast.fire({
                icon: 'error',
                title: 'Update failed',
                text: 'Unable to process the sales return, please try again.',
            });
            return;
        }

        try {
            const response = await this._salesreturnService.createSalesOrders(customerData);

            if (response.success) {
                await this.handlePaymentDue(response.data);
                this.showSuccessToast();
                this.resetFormAndNavigate();
            } else {
                this.showErrorToast(response.errors);
            }
        } catch (error) {
            console.error('Error in createSalesOrders:', error);
            this.handleApiErrors(error);
        }
    }

    prepareCustomerData() {
        const customerData = { ...this.vendorsCredit.value };
        customerData.reasonForReturn = this.vendorsCredit.get('rfr').value;
        customerData.date = this.datePipe.transform(this.vendorsCredit.get('date')?.value, 'yyyy-MM-dd');
        customerData.salesOrder = this.saleOrderIDToReturn;

        return customerData;
    }

    async handlePartialReturns(customerData: any) {
        try {

            const saleProduct = this.vendorsCredit.get('products') as FormArray;

            this.currentSales = this.currentSales || {};
            const updatedProductsSales: any[] = saleProduct.controls
                .filter((control: FormGroup) => control.get('returnSelection')?.value) // Filter by returnSelection
                .map((control: FormGroup) => ({
                    product: control.get('id').value,
                    quantity: control.get('sale_quantity').value,
                    rate: control.get('sale_rate').value,
                    amount: control.get('sale_amount').value,
                    color: control.get('sale_color').value,
                    size: control.get('sale_size').value,
                }));

            let hasChanges = false;

            const nonSelectedProducts = saleProduct.controls.filter((control: FormGroup) => {
                const isSelected = control.get('returnSelection')?.value;
                return !isSelected;  // Include non-selected products
            });

            const selectedProducts = saleProduct.controls.filter((control: FormGroup) => {
                const isSelected = control.get('returnSelection')?.value;
                return isSelected;
            });


            saleProduct.clear();

            selectedProducts.forEach((control: FormGroup) => {
                const updatedProduct = {
                    id: control.get('id').value,
                    sale_product: control.get('sale_product').value,
                    sale_quantity: control.get('sale_quantity').value,
                    sale_rate: control.get('sale_rate').value,
                    sale_amount: control.get('sale_amount').value,
                    sale_color: control.get('sale_color').value,
                    sale_size: control.get('sale_size').value,
                    returnSelection: true  // Mark as selected
                };
                saleProduct.push(this.createProductFormGroup(updatedProduct));
            });

            this.saleProduct = saleProduct.controls.map((control: FormGroup) => {
                return {
                    id: control.get('id').value,
                    sale_product: control.get('sale_product').value,
                    sale_quantity: control.get('sale_quantity').value,
                    sale_rate: control.get('sale_rate').value,
                    sale_amount: control.get('sale_amount').value,
                    sale_color: control.get('sale_color').value,
                    sale_size: control.get('sale_size').value,
                    returnSelection: control.get('returnSelection').value
                };
            });


            this.currentSales.products = []; // Initialize or reset currentSales.products

            updatedProductsSales.forEach((updatedProduct, index) => {
                const baseQuantity = this.current_stock_sales[updatedProduct.product];
                const activeQuantity = updatedProduct.quantity;
                const originalSalesQuantity = baseQuantity - activeQuantity;

                // Check if there's any change in the product's stock
                if (activeQuantity !== baseQuantity) {
                    hasChanges = true;

                    this.currentSales.products.push({
                        product: updatedProduct.product,
                        quantity: originalSalesQuantity,
                        rate: updatedProduct.rate,
                        amount: updatedProduct.amount,
                        color: updatedProduct.color,
                        size: updatedProduct.size,
                    });
                }
            });


            // If there are changes, merge nonSelectedProducts with the updated products
            if (hasChanges) {
                this.currentSales.products = [
                    ...this.currentSales.products, // Existing changed products
                    ...nonSelectedProducts.map((control: FormGroup) => ({
                        product: control.get('id').value,
                        quantity: control.get('sale_quantity').value,
                        rate: control.get('sale_rate').value,
                        amount: control.get('sale_amount').value,
                        color: control.get('sale_color').value,
                        size: control.get('sale_size').value,
                    })),
                ];
            } else {
                // If no changes, use nonSelectedProducts only
                this.currentSales.products = nonSelectedProducts.map((control: FormGroup) => ({
                    product: control.get('id').value,
                    quantity: control.get('sale_quantity').value,
                    rate: control.get('sale_rate').value,
                    amount: control.get('sale_amount').value,
                    color: control.get('sale_color').value,
                    size: control.get('sale_size').value,
                }));
            }


            this.calculateTotals(this.currentSales.products);

            // Ensure currentSales object is updated with other required fields
            this.currentSales.total = this.currentSales.total || 0;
            this.currentSales.sub_total = this.currentSales.sub_total || 0;
            this.currentSales.discount = this.selectedSales.discount || 0;
            this.currentSales.discountSuffix = this.selectedSales.discountSuffix || '';
            this.currentSales.adjustment = this.selectedSales.adjustment || 0;
            this.currentSales.adjustmentSuffix = this.selectedSales.adjustmentSuffix || '';
            this.currentSales.shipping_charges = this.selectedSales.shipping_charges || 0;
            this.currentSales.status = 'Partial Returned Sales Order';

            this.currentSales.is_partialExchange = true;


            try {
                const response = await this._salesOrderService.PartialupdateSalesOrder(this.saleOrderIDToReturn, this.currentSales);
                console.log('PartialupdateSalesOrder response:', response);
            } catch (error) {
                console.error('Error in PartialupdateSalesOrder:', error);
            }
        } catch (error) {
            console.error('Error handling partial returns:', error);
        }
    }

    createProductFormGroup(product: any): FormGroup {
        return new FormGroup({
            id: new FormControl(product.id),
            sale_product: new FormControl(product.sale_product),
            sale_quantity: new FormControl(product.sale_quantity),
            sale_rate: new FormControl(product.sale_rate),
            sale_amount: new FormControl(product.sale_amount),
            sale_color: new FormControl(product.sale_color),
            sale_size: new FormControl(product.sale_size),
            returnSelection: new FormControl(product.returnSelection),
        });
    }




    initializeCurrentSales() {
        if (!this.currentSales) {
            this.currentSales = {
                products: [],
            };
        }

        if (!this.currentSales.products) {
            this.currentSales.products = [];
        }

    }





    calculateSalesTotals(selectedProducts: any[]) {
        let totalAmount = 0;

        selectedProducts.forEach(selectedProduct => {
            totalAmount += selectedProduct.sale_amount || 0;
        });

        const { calculatedDiscount, calculatedAdjustment } = this.calculateDiscountAndAdjustment(totalAmount);

        const shipping = this.selectedSales.shipping_charges || 0;
        const updatedtotal = totalAmount - calculatedDiscount + calculatedAdjustment + shipping;

        this.currentSales.total = updatedtotal;
        this.currentSales.sub_total = totalAmount;
        this.currentSales.discount = this.selectedSales.discount;
        this.currentSales.discountSuffix = this.selectedSales.discountSuffix;
        this.currentSales.adjustment = this.selectedSales.adjustment;
        this.currentSales.adjustmentSuffix = this.selectedSales.adjustmentSuffix;
        this.currentSales.shipping_charges = this.selectedSales.shipping_charges;
        this.currentSales.status = "Partial Returned Sales Order";
        this.currentSales.is_partialReturn = true;
    }



    calculateDiscountAndAdjustment(totalAmount: number) {
        let calculatedDiscount = this.selectedSales.discount;
        let calculatedAdjustment = this.selectedSales.adjustment;

        if (this.selectedSales.discountSuffix === '%') {
            calculatedDiscount = (totalAmount * this.selectedSales.discount) / 100;
        }

        if (this.selectedSales.adjustmentSuffix === '%') {
            calculatedAdjustment = (totalAmount * this.selectedSales.adjustment) / 100;
        }

        if (this.selectedSales.adjustmentSuffix === '%' && this.selectedSales.discountSuffix === '%') {
            calculatedDiscount = (totalAmount * this.selectedSales.discount) / 100;
            const newTotal = totalAmount - calculatedDiscount;
            calculatedAdjustment = (newTotal * this.selectedSales.adjustment) / 100;
        }

        return { calculatedDiscount, calculatedAdjustment };
    }


    handleFullReturns(customerData: any) {
        customerData.discount = this.selectedSales.discount;
        customerData.discountSuffix = this.selectedSales.discountSuffix;
        customerData.adjustment = this.selectedSales.adjustment;
        customerData.adjustmentSuffix = this.selectedSales.adjustmentSuffix;
        customerData.shipping_charges = this.selectedSales.shipping_charges;
        customerData.salesOrder = this.saleOrderIDToReturn;

        // Prepare return products
        const returnProducts = customerData.products.map(product => ({

            sale_product: product.sale_product,
            sale_size: product.sale_size,
            sale_color: product.sale_color,
            sale_quantity: product.sale_quantity,
            sale_amount: product.sale_amount,
            sale_rate: product.sale_rate,
        }));

        this.saleProduct = returnProducts;

        return this.updateReturnProducts(); // Return the promise so we can await it in submitVendorsInfo
    }


    async updateReturnProducts(): Promise<boolean> {
        const saleProduct = this.vendorsCredit.get('products') as FormArray;
        const returnProductsData: any[] = saleProduct.controls.map((control: FormGroup) => ({
            product: control.get('id').value,
            product_name: control.get('sale_product').value,
            quantity: control.get('sale_quantity').value,
            rate: control.get('sale_rate').value,
            amount: control.get('sale_amount').value,
            color: control.get('sale_color').value,
            size: control.get('sale_size').value,
        }));

        let hasChanges = false;

        returnProductsData.forEach((updatedProduct, index) => {
            const baseQuantity = this.current_stock_sales[updatedProduct.product];
            const activeQuantity = updatedProduct.quantity;

            // Calculate original sales quantity based on current stock
            const originalSalesQuantity = baseQuantity - activeQuantity;

            if (activeQuantity !== baseQuantity) {
                hasChanges = true;

                if (!this.currentSales.products) {
                    this.currentSales.products = [];
                }

                this.currentSales.products[index] = {
                    product: updatedProduct.product,
                    quantity: originalSalesQuantity,
                    rate: updatedProduct.rate,
                    amount: updatedProduct.amount,
                    color: updatedProduct.color,
                    size: updatedProduct.size,
                };

                // Patch the values directly to this.saleProduct
                this.saleProduct[index] = {
                    sale_product: updatedProduct.product_name,
                    sale_quantity: activeQuantity,
                    sale_rate: updatedProduct.rate,
                    sale_amount: updatedProduct.amount,
                    sale_color: updatedProduct.color,
                    sale_size: updatedProduct.size,
                };

            }
        });

        // If changes were detected, update the sales order
        if (hasChanges) {
            this.calculateTotals(this.currentSales.products);

            this.currentSales = {
                products: this.currentSales.products,
                total: this.currentSales.total,
                sub_total: this.currentSales.sub_total,
                discount: this.selectedSales.discount,
                discountSuffix: this.selectedSales.discountSuffix,
                adjustment: this.selectedSales.adjustment,
                adjustmentSuffix: this.selectedSales.adjustmentSuffix,
                shipping_charges: this.selectedSales.shipping_charges,
                status: 'Partial Returned Sales Order',
                is_fullReturn: true
            };

            try {
                await this._salesOrderService.PartialupdateSalesOrder(this.saleOrderIDToReturn, this.currentSales);
                return true;
            } catch (error) {
                console.error("Failed to update the sales order:", error);
                return false;
            }
        }

        // If no changes were detected, you can call another function or just return false
        console.log("No changes detected, returning false.");
        return false;
    }



    calculateTotals(updatedProductsSales: any[]) {
        let totalAmount = 0;

        updatedProductsSales.forEach(selectedProduct => {
            // Calculate the new amount based on quantity and rate
            if (selectedProduct.quantity != null && selectedProduct.rate != null) {
                selectedProduct.amount = selectedProduct.quantity * selectedProduct.rate;
            }

            if (selectedProduct.amount != null) {
                totalAmount += selectedProduct.amount;
            } else {
                console.warn(`Product with id ${selectedProduct.product} has a null or undefined amount.`);
            }
        });

        let calculatedDiscount = this.selectedSales.discount;
        let calculatedAdjustment = this.selectedSales.adjustment;
        let shipping = this.selectedSales.shipping_charges || 0;

        if (this.selectedSales.discountSuffix === '%') {
            calculatedDiscount = (totalAmount * this.selectedSales.discount) / 100;
        }

        if (this.selectedSales.adjustmentSuffix === '%') {
            calculatedAdjustment = (totalAmount * this.selectedSales.adjustment) / 100;
        }

        if (this.selectedSales.adjustmentSuffix === '%' && this.selectedSales.discountSuffix === '%') {
            calculatedDiscount = (totalAmount * this.selectedSales.discount) / 100;
            const newTotal = totalAmount - calculatedDiscount;
            calculatedAdjustment = (newTotal * this.selectedSales.adjustment) / 100;
        }

        this.currentSales.total = totalAmount - calculatedDiscount + calculatedAdjustment + shipping;
        this.currentSales.sub_total = totalAmount;


    }


    async handlePaymentDue(responseData: any) {
        try {
            await this.paymentDue(responseData, responseData.id);
            Swal.close(); // Close the loading modal after paymentDue completes
            Swal.fire({
                icon: 'info',
                title: 'Success!',
                text: 'Credit Note processing completed successfully.',
                showConfirmButton: false,
                backdrop: true,
                allowOutsideClick: false,
                position: 'top-end',
                toast: true,
                timer: 3000
            });
        } catch (error) {
            console.error('Error handling payment due:', error);
        }
    }
    showSuccessToast() {
        this.Toast.fire({
            icon: 'success',
            title: 'Successfully Processed',
            text: 'The sales return has been processed successfully.',
        });
    }
    showErrorToast(errorMessage: string) {
        this.Toast.fire({
            icon: 'error',
            title: 'Failed to Process Return',
            text: `An error occurred: ${errorMessage}`,
        });
    } handleApiErrors(errors: any) {
        this.Toast.fire({
            icon: 'error',
            title: 'API Errors',
            text: `Failed to process return. Errors: ${errors}`,
        });
    }
    resetFormAndNavigate() {
        this.vendorsCredit.reset();
        this._router.navigateByUrl('/apps/sales-return');
    }



    saveAsDraft() {
        // Handle save as draft functionality
    }

    saveAndPrint() {
        // Handle save and print functionality
    }

    paymentDue = async (salesReturn, id) => {
        try {
            if (!salesReturn) {
                throw new Error('Invalid credit note');
            }

            const today = new Date();
            const formattedDate = today.toISOString().split('T')[0]; // Format: YYYY-MM-DD

            const customerData = {
                Customers: salesReturn.salesOrder.Customers.id,
                salesReturn: id,
                voucher_description: `This is a Sale return voucher for ${salesReturn.salesReturnNumber}`,
                voucher_type: "Sales Return Voucher",
                total: salesReturn.salesOrder.total,
                isVoucher: true,
                isCredited: true,
                voucher_order_date: formattedDate,

            };

            const resp = await this._creditNoteService.createSalesOrders(customerData);

            if (resp && resp.success) {
                return { success: true };
            } else {
                throw new Error('Creedit Note processing failed');
            }
        } catch (error) {
            console.error('Error in paymentDue:', error);
            throw error; // Re-throw the error to be caught by the calling code
        }
    };
    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    //SalesOrderNumberAutoselect
    filterSalesOrderOptions(value: string): Observable<SalesOrder[]> {
        if (typeof value !== 'string') {
            return of([]); // Return an empty array if value is not a string
        }

        const filterValue = value.toLowerCase();
        return this.salesOrder$.pipe(
            map((salesOrder) => {
                if (Array.isArray(salesOrder['data'])) {
                    return salesOrder['data'].filter((salesOrder) =>

                        salesOrder.sales_order_num
                            .toLowerCase()
                            .includes(filterValue)
                    );
                } else {
                    console.error(
                        'SalesOrder data is not available:',
                        salesOrder
                    );
                    return [];
                }
            })
        );
    }

    displaySalesOrderOption(salesOrder: SalesOrder): string {
        const sales_order_num = salesOrder?.sales_order_num || '';
        return sales_order_num;
    }


    selectSalesOrderOption(option: SalesOrder) {
        if (option) {
            this.selectedSales = option;
            this.saleOrderIDToReturn = option.id

            // Set the customerControl value based on the selected sales order's customer data
            const customer = option.Customers.firstName + " " + option.Customers.lastName;
            const customer_id = option.Customers.id;
            this.vendorsCredit.get('customerControl').patchValue(customer)
            this.isFieldReadOnly = true;
            this.vendorsCredit.get('customer').patchValue(customer_id);
            // Set the products in the products form array based on the selected sales order's products
            this.vendorsCredit.setControl('products', this.setProduct(option.products));
            this.vendorsCredit.get('total').patchValue(option.total);

            // Update the form validity (if needed)
            this.vendorsCredit.updateValueAndValidity();


            this._changeDetectorRef.detectChanges();
        }

    }

    calculateTotalApplied() {
        const { discount, discountSuffix, adjustment, adjustmentSuffix, shipping_charges, total: initialTotal } = this.selectedSales;
        const productArray = this.vendorsCredit.get('products') as FormArray;

        let subTotalAll = 0;
        let subTotalSelected = 0;
        let isAnyProductSelected = false;

        // Calculate the subtotal for all products and for selected products
        productArray.controls.forEach((control: FormGroup) => {
            const sale_quantity = control.get('sale_quantity')?.value || 0;
            const sale_rate = control.get('sale_rate')?.value || 0;
            const returnSelection = control.get('returnSelection')?.value || false;

            const amount = sale_quantity * sale_rate;
            control.get('sale_amount')?.setValue(amount, { emitEvent: false });

            subTotalAll += amount || 0;

            // Update subtotal for selected products
            if (returnSelection) {
                subTotalSelected += amount || 0;
                isAnyProductSelected = true;
            }


            control.updateValueAndValidity({ emitEvent: false });
        });

        let calculatedDiscount = discount;
        let calculatedAdjustment = adjustment;
        const shipping = shipping_charges || 0;

        let updatedTotal;

        // Calculate the updated total based on selection status
        if (isAnyProductSelected) {
            // Calculate total based on selected products
            if (discountSuffix === '%') {
                calculatedDiscount = (subTotalSelected * discount) / 100;
            }

            if (adjustmentSuffix === '%') {
                calculatedAdjustment = (subTotalSelected * adjustment) / 100;
            }

            if (adjustmentSuffix === '%' && discountSuffix === '%') {
                calculatedDiscount = (subTotalSelected * discount) / 100;
                const newTotal = subTotalSelected - calculatedDiscount;
                calculatedAdjustment = (newTotal * adjustment) / 100;
            }

            updatedTotal = subTotalSelected - calculatedDiscount + calculatedAdjustment + shipping;
        } else {

            updatedTotal = subTotalAll
        }

        // Set the updated total amount
        this.vendorsCredit.get('total')?.setValue(updatedTotal, { emitEvent: false });
        this.vendorsCredit.updateValueAndValidity();

        this.cd.detectChanges();
    }




    subscribeToChanges() {
        const productArray = this.vendorsCredit.get('products') as FormArray;

        productArray.controls.forEach((control: FormGroup) => {
            control.get('sale_quantity').valueChanges.subscribe(() => {

                this.calculateTotalApplied();
            });

            control.get('sale_rate').valueChanges.subscribe(() => {
                this.calculateTotalApplied();
            });

            control.get('returnSelection').valueChanges.subscribe(() => {
                this.calculateTotalApplied();
            });

            this._changeDetectorRef.detectChanges()
        });
    }



}
