<!-- Confirm delete template -->
<mat-dialog-content class="dialog-content">
    <form [formGroup]="packageGroup" class="bg-gray-100 rounded shadow-lg h-full"
        (keydown.enter)="$event.preventDefault()">
        <!-- Avatar -->
        <!-- Avatar code goes here -->

        <!-- Personal Information Form -->
        <div>
            <div class="prose prose-sm max-w-3xl"></div>
            <!-- HTML template -->

            <div class="flex flex-col mt-8 p-8 pb-5 bg-card rounded shadow overflow-hidden">
                <p class="text-lg font-medium">Product Delivered</p>
                <p class="text-secondary">New Product Delivered</p>
                <mat-divider class="mt-8 mb-10"></mat-divider>

                <div class="flex flex-col gt-xs:flex-row">
                    <mat-form-field class="flex-auto gt-xs:pl-3">
                        <mat-label>Order Number#</mat-label>
                        <input matInput [placeholder]="'packageSlip'"  name="packageOrder"
                            [formControlName]="'packageOrder'" class="elevated-input" [value]="" readonly />
                        <mat-icon class="icon-size-5" matPrefix [svgIcon]="'feather:hash'"></mat-icon>
                    </mat-form-field>
                </div>
          

                <div class="flex flex-col gt-xs:flex-row">
                    <mat-form-field class="flex-auto gt-xs:pl-3">
                        <mat-label>Choose a date</mat-label>
                        <input matInput [matDatepicker]="picker" formControlName="packageDate" name="packageDate" required/>
                        <mat-hint>MM/DD/YYYY</mat-hint>
                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div>

                
                <div class="flex items-center justify-end mt-10">
                    <button class="px-6 ml-3" mat-flat-button [color]="'primary'" (click)="save()">
                       {{ isCredited ? "Paid" : "Next"}}
                    </button>
                </div>
            </div>
        </div>
    </form>
</mat-dialog-content>