import { Route } from '@angular/router';
import { ListpackagesHistoryComponent } from './listpackages-history/listpackages-history.component';
import { CreatepackagesHistoryComponent } from './createpackages-history/createpackages-history.component';
import { DetailpackagesHistoryComponent } from './detailpackages-history/detailpackages-history.component';


export const PackagesHistoryRoutes: Route[] = [
   
        {
            path     : '',
            component : ListpackagesHistoryComponent,
        },
        {
            path         : 'NEW',
            component    : CreatepackagesHistoryComponent,
           
        },
        {
            path         : ':id',
            component    :DetailpackagesHistoryComponent,
          
          
        },
]
