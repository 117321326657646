<div class="sm:absolute sm:inset-0 flex flex-col flex-auto min-w-0 sm:overflow-hidden bg-card dark:bg-transparent">

    <!-- Header -->
    <div
        class="relative flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between py-8 px-6 md:px-8 border-b">
        <!-- Loader -->
        <div class="absolute inset-x-0 bottom-0" *ngIf="isLoading">
            <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
        </div>
        <!-- Title -->
        <div class="text-4xl font-bold tracking-tight">Promo Code <mat-icon
                svgIcon="heroicons_outline:academic-cap"></mat-icon>
        </div>
        <!-- Actions -->
        <div class="flex flex-shrink-0 items-center mt-6 sm:mt-0 sm:ml-4">
            <!-- Search -->
            <mat-form-field class="fuse-mat-dense fuse-mat-rounded  fuse-mat-no-subscript min-w-50">
                <mat-icon matPrefix [svgIcon]="'heroicons_outline:search'"></mat-icon>
                <input matInput [autocomplete]="'off'" (keyup)="applyFilter($event)"
                    [placeholder]="'Search Promo Code'">
            </mat-form-field>
            <!-- Add product button -->
            <button class="ml-4 mr-4" mat-flat-button [color]="'primary'" (click)="createPromoCode()">
                <mat-icon matTooltip="Add new Sales Order" [svgIcon]="'heroicons_outline:plus'"></mat-icon>
                <span class="ml-2 mr-1">Add</span>
            </button>


        </div>
    </div>

    <!-- Main -->
    <div class="flex flex-auto overflow-hidden">
        <!-- Products list -->
        <div class="flex flex-col flex-auto sm:mb-18 overflow-hidden sm:overflow-y-auto">
            <div class="mat-elevation-z8">
                <table mat-table [dataSource]="dataSource" matSort class="mat-table">
                    <!-- Waist Column -->

                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                        <td mat-cell *matCellDef="let row"> {{ row.id }} </td>
                    </ng-container>

                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                        <td mat-cell *matCellDef="let row"> {{ row.name }} </td>
                    </ng-container>

                    <ng-container matColumnDef="discount">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Discount </th>
                        <td mat-cell *matCellDef="let row"><strong>
                                {{ row.discount }} {{row.discount_alias}}
                            </strong> </td>
                    </ng-container>

                    <ng-container matColumnDef="date">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Expiry Date </th>
                        <td mat-cell *matCellDef="let row"> <i>
                                <u>
                                    {{ row.date }}
                                </u>
                            </i> </td>
                    </ng-container>

                    <!-- Actions Column -->
                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef> Actions </th>
                        <td mat-cell *matCellDef="let row">

                            <!-- <button mat-icon-button matTooltip="Delete" (click)="delteSize(row.id)">
                                <mat-icon class="icon-size-4" svgIcon="heroicons_outline:trash"></mat-icon>
                            </button> -->

                            <button mat-icon-button matTooltip="View" (click)="detailPromo(row.id)">
                                <mat-icon class="icon-size-4" svgIcon="heroicons_outline:eye"></mat-icon>
                            </button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>


                <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>
            </div>
        </div>
    </div>