import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { RestockEntryComponent } from './restock-entry/restock-entry.component';
import { CreaterestockEntryComponent } from './createrestock-entry/createrestock-entry.component';
import { ListrestockEntryComponent } from './listrestock-entry/listrestock-entry.component';
import { DetailrestockEntryComponent } from './detailrestock-entry/detailrestock-entry.component';
import { MaterialModule } from 'app/modules/material/material.module';
import {  RouterModule } from '@angular/router';
import { RestockEntryRoutes } from './restock-entry.routing';
import { MAT_DATE_FORMATS } from '@angular/material/core';



@NgModule({
  declarations: [
    RestockEntryComponent,
    CreaterestockEntryComponent,
    ListrestockEntryComponent,
    DetailrestockEntryComponent
  ],
  imports: [
    RouterModule.forChild(RestockEntryRoutes),
    CommonModule,
    MaterialModule
  
],
providers   : [
  {
      provide : MAT_DATE_FORMATS,
      useValue: {
          parse  : {
              dateInput: "YYYY-MM-DD"
          },
          display: {
              dateInput         : 'YYYY-MM-DD',
              monthYearLabel    : 'MMM YYYY',
              dateA11yLabel     : 'LL',
              monthYearA11yLabel: 'MMMM YYYY'
              
          }
      }
  },
  DatePipe,
  
],

})
export class RestockEntryModule { }
