import { Route } from '@angular/router';
import { ListvendorsPaymentComponent } from './listvendors-payment/listvendors-payment.component';
import { CreatevendorsPaymentComponent } from './createvendors-payment/createvendors-payment.component';
import { DetailvendorsPaymentComponent } from './detailvendors-payment/detailvendors-payment.component';




export const VendorsPaymentRoutes: Route[] = [
   
        {
            path     : '',
            component: ListvendorsPaymentComponent,
        },
        {
            path         : 'NEW',
            component    : CreatevendorsPaymentComponent,
           
        },
        {
            path         : ':id',
            component    :DetailvendorsPaymentComponent,
          
          
        },
]
