import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { SalesOrder } from './sales-order.type';
import axios, { AxiosResponse } from 'axios';
import { productVaraitons } from 'app/productVariations/productVariations.service';
import { server_env } from 'config';
@Injectable({
    providedIn: 'root',
})
export class SalesOrderService {
    // Private
    private _salesorder: BehaviorSubject<SalesOrder | null> =
        new BehaviorSubject(null);
    private _salesorderss: BehaviorSubject<SalesOrder[] | null> =
        new BehaviorSubject(null);
        private _salesorder_numbers: BehaviorSubject<any[] | null> =
        new BehaviorSubject(null);
        private _varaitons: BehaviorSubject<productVaraitons[] | null> =
        new BehaviorSubject(null);
        private _salesorderFiltered: BehaviorSubject<SalesOrder | null> =
        new BehaviorSubject(null);

    public url = `${server_env.URL}api/v1/salesOrder/`;
    public filteredurl = `${server_env.URL}api/v1/salesOrders/`;

    public url2 = `${server_env.URL}api/v1product-variations/`;    
    public url3 = `${server_env.URL}api/v1/salesOrder_n`;
    public url4 = `${server_env.URL}api/v1/salesOrder_update`;
    

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors

    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for contact
     */
    get salesOrder$(): Observable<SalesOrder> {
        return this._salesorder.asObservable();
    }

    /**
     * Getter for contacts
     */
    get salesOrders$(): Observable<SalesOrder[]> {
        return this._salesorderss.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get contacts
     */

    getSalesOrder(): Observable<SalesOrder[]> {
        return this._httpClient.get<SalesOrder[]>(`${this.url}`).pipe(
            tap((salesorder: any) => {
                this._salesorderss.next(salesorder.data);

                for (salesorder in salesorder.data) {
                   
                }
            })
        );
    }
    getSalesOrders(): Observable<SalesOrder[]> {
        return this._httpClient.get<SalesOrder[]>(`${this.filteredurl}`).pipe(
            tap((salesorder: any) => {
                this._salesorderFiltered.next(salesorder);

                for (salesorder in salesorder) {
                   
                }
            })
        );
    }

    getSalesOrder_number(): Observable<any[]> {
        return this._httpClient.get<any>(`${this.url3}`).pipe(
            tap((number: any) => {
                this._salesorder_numbers.next(number.data);
    
            })
        );
    }
    /**
     * Get contact by id
     */
    getSalesOrderById(id: any): Observable<SalesOrder> {
        const requestUrl = this.url + id;
        // console.log(requestUrl);
        // if you uncommend the below code then just below line should be commented and the line return data should be uncommmented
        // const data = this._httpClient.get<SalesOrder[]>(`${requestUrl}`).pipe(
        return this._httpClient.get<SalesOrder[]>(`${requestUrl}/`).pipe(
            take(1),
            map((salesorder: any) => {
                this._salesorder.next(salesorder.data);

                // Return the contact
                return salesorder.data;
            }),
            switchMap((salesorder) => {
                if (!salesorder) {
                    return throwError(
                        'Could not found contact with id of ' + id + '!'
                    );
                }

                return of(salesorder);
            })
        );
        // console.log(data);
        // return data;
    }
    getProductVariations(productId: string): Observable<any> {
        const requestUrl = `${server_env}api/v1/product-variations/${productId}/`
        return this._httpClient.get<any[]>(`${requestUrl}`).pipe(
            tap((variants: any) => {
                this._varaitons.next(variants.data);

                for (variants in variants.data) {
                }
            })
        );
    }

    getSalesOrderHistory(productId: string): Observable<any> {
        const requestUrl = `${server_env.URL}api/v1/salesOrder_history/${productId}/`
        return this._httpClient.get<any[]>(`${requestUrl}`).pipe(
            tap((variants: any) => {
                this._varaitons.next(variants.data);

                for (variants in variants.data) {
                }
            })
        );
    }

    
  getPaymentByTypeAndId(type: string, id: number): Observable<any[]> {
    const url = `${server_env.URL}api/v1/payment-by-type-and-id/${type}/${id}/`;
    return this._httpClient.get<any[]>(url);
  }
   
    /**
     * create customer
     * @param data
     * @returns
     */

    createSalesOrders(data): Promise<any> {
        return new Promise((resolve, reject) => {
            const requestUrl = this.url;

            this._httpClient.post<any>(`${requestUrl}`, data).subscribe(
                (response) => {
                    return resolve(response);
                },
                (error) => {
                    return reject(error);
                }
            );
        });
    }


    /**
     * Update contact
     *
     * @param id
     * @param contact
     */
    updateSalesOrder(id: string, contact: SalesOrder): Observable<SalesOrder> {
        return this.salesOrders$.pipe(
            take(1),
            switchMap((salesordder) =>
                this._httpClient
                    .put<SalesOrder>(`${this.url}${id}/`, {
                        id,
                        contact,
                    })
                    .pipe(
                        map((updatedSalesOrder) => {
                            // Find the index of the updated contact
                            const index = salesordder.findIndex(
                                (item) => item.id === id
                            );

                            // Update the contact
                            salesordder[index] = updatedSalesOrder;

                            // Update the contacts
                            this._salesorderss.next(salesordder);

                            // Return the updated contact
                            return updatedSalesOrder;
                        }),
                        switchMap((updatedSalesOrders) =>
                            this.salesOrder$.pipe(
                                take(1),
                                filter((item) => item && item.id === id),
                                tap(() => {
                                    // Update the contact if it's selected
                                    this._salesorder.next(updatedSalesOrders);

                                    // Return the updated contact
                                    return updatedSalesOrders;
                                })
                            )
                        )
                    )
            )
        );
    }


    /**
     * Update contact
     *
     * @param id
     * @param contact
     * @returns
     */
    
    PartialupdateSalesOrder(id, contact): Promise<any> {
        return new Promise((resolve, reject) => {
            const requestUrl = this.url4;

            this._httpClient.patch<any>(`${requestUrl}/${id}/`, contact).subscribe(
                (response) => {
                    return resolve(response);
                },
                (error) => {
                    return reject(error);
                }
            );
        });
    }
    

    /**
     * Delete the contact
     *
     * @param id
     */
    deleteSalesOrder(id: string): Observable<boolean> {
        return this.salesOrders$.pipe(
            take(1),
            switchMap((salesOrder) =>
                this._httpClient.delete(`${this.url}${id}/`).pipe(
                    map((isDeleted: boolean) => {
                        // Find the index of the deleted contact
                        const index = salesOrder.findIndex(
                            (item) => item.id === id
                        );

                        // Delete the contact
                        salesOrder.splice(index, 1);

                        // Update the contacts
                        this._salesorderss.next(salesOrder);

                        // Return the deleted status
                        return isDeleted;
                    })
                )
            )
        );
    }

    async fetchData(): Promise<string[]> {
        try {
            const response: AxiosResponse = await axios.get(
                'https://nepallocation.com.np/api/v1/district/list',
                {
                    headers: {
                        Authorization: `Bearer AFKw8mI-vtgRq-M2i1qDvIGS`,
                        'Content-Type': 'application/json',
                    },
                }
            );

            const districtNames = response.data.data.data.map(
                (district) => district.name
            );
            
            
            
            // console.log(districtNames, 'districtNames');

            return districtNames;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
}
