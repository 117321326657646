import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ProductsComponent } from './products/products.component';
import { ListProductComponent } from './list-product/list-product.component';
import { DetailProductComponent } from './detail-product/detail-product.component';
import { CreateProductComponent } from './create-product/create-product.component';
import { ProductPopupComponent } from './products-popup/product-popup.component';
import { MaterialModule } from 'app/modules/material/material.module';
import { RouterModule } from '@angular/router';
import { ProductRoutes } from './products.routing';
import { ProductOptionsPopupComponent } from './product-options-popup/product-options-popup.component';
import { ProductVarientsComponent } from './product-varients/product-varients.component';
import { UnsavedChangesDirective } from './changes_diretive.component';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { LoaderComponent as Loader } from 'app/spinner-loader/loader.component';
@NgModule({
    declarations: [
        ProductsComponent,
        ListProductComponent,
        DetailProductComponent,
        CreateProductComponent,
        ProductPopupComponent,
        ProductOptionsPopupComponent,
        ProductVarientsComponent,
        UnsavedChangesDirective,
    ],
    imports: [
        RouterModule.forChild(ProductRoutes),
        MaterialModule,
        Loader,
        CommonModule,


    ],
    providers: [
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: "YYYY-MM-DD"
                },
                display: {
                    dateInput: 'YYYY-MM-DD',
                    monthYearLabel: 'MMM YYYY',
                    dateA11yLabel: 'LL',
                    monthYearA11yLabel: 'MMMM YYYY'

                }
            }
        },
        DatePipe,
    ],
    exports: [UnsavedChangesDirective],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],


})
export class ProductsModule { }
