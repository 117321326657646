<div class="sm:absolute sm:inset-0 flex flex-col flex-auto min-w-0 sm:overflow-hidden bg-card dark:bg-transparent">
    <!-- Header -->
    <div
        class="relative flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between py-8 px-6 md:px-8 border-b">
        <!-- Loader -->
        <div class="absolute inset-x-0 bottom-0" *ngIf="isLoading">
            <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
        </div>
        <!-- Title -->
        <div class="text-4xl font-bold tracking-tight">
            Sales Return
        </div>
        <!-- Actions -->
        <div class="flex flex-shrink-0 items-center mt-6 sm:mt-0 sm:ml-4">
            <!-- Search -->
            <mat-form-field class="fuse-mat-rounded fuse-mat-no-subscript min-w-50">
                <mat-icon matPrefix [svgIcon]="'heroicons_outline:search'"></mat-icon>
                <input matInput [autocomplete]="'off'" (keyup)="applyFilter($event)"
                    [placeholder]="'Search Sales Order'" />
            </mat-form-field>
            <!-- Add product button -->
            <button class="ml-4 mr-4" mat-flat-button [color]="'primary'" (click)="createSalesExchange()">
                <mat-icon matTooltip="Add new Sales Order" [svgIcon]="'heroicons_outline:plus'"></mat-icon>
                <span class="ml-2 mr-1">Add</span>
            </button>

            <button [color]="'primary'" mat-flat-button (click)="exportexcel()">
                <mat-icon matTooltip="Export your tabel to excel" [svgIcon]="'heroicons_outline:clipboard'"></mat-icon>
                <span class="ml-2 mr-1">Export </span>
            </button>

        </div>

    </div>


    <!-- Main -->
    <div class="flex flex-auto overflow-hidden">
        <!-- Products list -->
        <div class="flex flex-col flex-auto sm:mb-18 overflow-hidden sm:overflow-y-auto">
            <div class="mat-elevation-z8">
                <table id="excel-table" #salesOrderTabel mat-table [dataSource]="dataSource" matSort matPaginator
                    class="mat-table">
                    <!-- CustomerName Column -->



                    <!-- SalesNumber Column -->
                    <ng-container matColumnDef="SaleReturnNumber">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            <strong>SR.N</strong>
                        </th>
                        <mat-icon svgIcon="heroicons_outline:user"></mat-icon>
                        <div></div>
                        <td mat-cell *matCellDef="let row">
                            <i><u>
                                    {{ row.saleReturnNumber }}

                                </u>
                            </i>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="SaleNumber">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            <strong>S.N</strong>
                        </th>
                        <mat-icon svgIcon="heroicons_outline:user"></mat-icon>
                        <div></div>
                        <td mat-cell *matCellDef="let row">
                            <i>
                                {{ row.saleNumber }}

                            </i>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="Cid">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Name
                        </th>
                        <td mat-cell *matCellDef="let row" class="cursor-pointer">
                            <i>

                                <strong>

                                    HUB {{ row.Cid }}
                                </strong>
                            </i>

                        </td>
                    </ng-container>
                    <ng-container matColumnDef="CustomerName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            ID
                        </th>
                        <td mat-cell *matCellDef="let row" class="cursor-pointer">
                            <div class="avatar" (click)="showDetails(row.id)">

                                <strong>
                                    {{ row.customerName }}
                                </strong>
                            </div>
                        </td>
                    </ng-container>
                    <!-- OrderShipped Date Column -->
                    <ng-container matColumnDef="Shipping_Date">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Order Shipped Date
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <div class="status" [ngClass]="getStatusColor(row)">
                                <i>
                                    <u>
                                        {{ row.shippingDate }}
                                    </u>
                                </i>
                            </div>
                        </td>
                    </ng-container>

                    <!-- reasons for return -->
                    <ng-container matColumnDef="rfr">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Reason for Return
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <div class="status" [ngClass]="getStatusColor(row)">
                                <i>
                                    {{ row.rfr }}
                                </i>
                            </div>
                        </td>
                    </ng-container>

                    <!-- Status Column -->
                    <ng-container matColumnDef="Status">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Status
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <div class="avatar">

                                {{ row.status }}
                            </div>
                        </td>
                    </ng-container>

                    <!-- OrderReturn Date Column -->
                    <ng-container matColumnDef="ReturnDate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Order Return Date
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <div class="status" [ngClass]="getStatusColor(row)">
                                <i>
                                    <u>
                                        {{ row.returningDate }}

                                    </u>
                                </i>
                            </div>
                        </td>
                    </ng-container>

                    isReceived

                    <ng-container matColumnDef="total">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Total Price
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <div class="status" [ngClass]="getStatusColor(row)">
                                रू <strong>
                                    {{ row.totalCost }}
                                </strong>
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="isReceived">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                          Return Received
                        </th>
                        <td mat-cell *matCellDef="let row">
                          <strong>
                            <span *ngIf="row.isReceived; else notReceived" class="received">
                              <mat-icon class="green-icon icon-size-4">thumb_up</mat-icon>
                            </span>
                            <ng-template #notReceived>
                              <mat-icon class="red-icon icon-size-4">close</mat-icon>
                            </ng-template>
                          </strong>
                        </td>
                      </ng-container>
                      


                    <!-- Actions Column -->
                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef>Actions</th>
                        <td mat-cell *matCellDef="let row">
                            <button class="icon-size-4 ml-2" mat-icon-button matTooltip="View"
                                (click)="showDetails(row.id)">
                                <mat-icon svgIcon="heroicons_outline:eye"></mat-icon>
                            </button>

                            <button *ngIf="!row.isReceived" mat-icon-button class="icon-size-4 m-2" matTooltip="Recive"
                                (click)="reciveProduct(row.id)">
                                <mat-icon svgIcon="feather:package"></mat-icon>
                            </button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>
                <!-- Footer Row for Statistics -->
                <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
                <tr class="total-row">
                    <td colspan="6" class="total-cell">Total:</td>
                    <td class="total-cell"> NPR {{ calculateTotal() }}</td>

                </tr>



                <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>
            </div>
        </div>
    </div>
</div>