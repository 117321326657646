import {

    Component,
    ElementRef,
    ChangeDetectorRef,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,

} from '@angular/core';
import {
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,

    Validators,
} from '@angular/forms';


import { ActivatedRoute, Router } from '@angular/router';

import { Observable, Subject, of, forkJoin } from 'rxjs';

import { ContactsService } from 'app/modules/admin/apps/contacts/contacts.service';
import { DatePipe } from '@angular/common';
import {
    debounceTime,
    map,
    startWith,
    switchMap,
    take,
    takeUntil,
    tap,
} from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { productVaraitonService } from 'app/productVariations/productVarations.type';
import Swal from 'sweetalert2';
import { Contact } from 'app/modules/admin/apps/contacts/contacts.types';
import { UserService } from 'app/core/user/user.service';
import { SalesOrderPopupComponent } from 'app/sales-order/sales-order-popup/sales-order-popup.component';
import { User } from 'app/core/user/user.types';

import { PreOrdersService } from '../pre-orders.service';
import { products } from 'app/products/products.type';
import { productsService } from 'app/products/products.service';
import { SalesOrderNumberService } from 'app/sales-order/sales-order-number.service';
import { contrydata, insideValleyData } from 'app/sales-order/sales-order-create/countries';
import { productBundleService } from 'app/product-bundle/product-bundle.service';
import { ProductBundlePopupComponent } from 'app/product-bundle-popup/product-bundle-popup.component';
import { productBundle } from 'app/product-bundle/product-bundle.type';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { HasUnsavedChanges } from 'app/page-detect/wm-can-leave/detect-changes.guard';


export interface CustomerOption {
    id: string;
    firstname?: string;
    avatar?: string;
}

export interface ProductOption {
    name?: string;
    stock?: number;
    reserved?: number;
    cost?: number;
    basePrice?: number;
    price?: number;

    images?: string[];
}

export interface Country {
    name: string;
    code: string;

}

@Component({
    selector: 'app-create-pre-orders',
    templateUrl: './create-pre-orders.component.html',
    styleUrls: ['./create-pre-orders.component.scss'],
})
export class CreatePreOrdersComponent implements OnInit, OnDestroy, HasUnsavedChanges {

    @ViewChild('customerInput') customerInput: ElementRef;

    mappedVariations: any;
    districtControl = new FormControl();
    filteredDistricts: Observable<string[]>;
    InsidefilteredDistricts: Observable<string[]>;
    districts: string[] = [];
    currentSalesOrderNum: number = 1;
    ngControl: any;


    sizes: any[] = []; // Initialize with empty array
    colors: any[] = []; // Initialize with empty array

    name: any[] = []; // Initialize with empty array
    quantity: any[] = []; // 


    current_stock: any[] = [];
    product_details: any[] = [];
    animationFlags: boolean[] = [];
    match_stock: boolean[] = [];
    filterByBundle: boolean[] = [];
    productActive:boolean[] = [];



    @Input() set disableControl(condition: boolean) {
        const action = condition ? 'disable' : 'enable';
        this.ngControl.control[action]();
    }
    customerId: number | undefined; // Property to store the selected customer ID

    productIds: number[] = []; // Property to store the selected product IDs
    stateCtrl = new FormControl('');
    formFieldHelpers: string[] = [''];
    drawerMode: 'over' | 'side' = 'side';
    drawerOpened: boolean = true;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    active: boolean[] = [];

    salesOrderForm: FormGroup;
    vendorsCount: number;
    selectedVendor: Contact;
    customers$: Observable<Contact[]>;
    CustomerControl: FormControl = new FormControl();
    // Declare an Observable to hold
    filteredCustomers$: Observable<Contact[]>;
    selectedCustomer: Contact | null = null;




    filteredProductOptions$: Observable<products[]>;
    filteredProductBundleOptions$: Observable<any[]>;

    usename: string = '';
    showAddressFields: boolean = false;
    showDeliveryType: boolean = false;
    showSocailLink: boolean = false;
    showEmail: boolean = false;
    showPaymentMethod: boolean = false;
    showShippingArea: boolean = false;
    showAddress: boolean = false;
    showShippinginvalley: boolean = false;
    showShippingOutvalley: boolean = false;
    showCountry: boolean = false;
    shwoShippingInternational: boolean = false;
    showDistrict: boolean = false;
    showOthers: boolean = false;
    showDeliveryDetails: boolean = false;
    formattedAddress = '';
    countryControl = new FormControl();
    countries: string[] = contrydata;
    isProductArrayReadOnly = false;
    isFieldReadOnly = false;

    isFieldReadOnly_outside_val = false;

    filteredCountries: Observable<string[]>;
    searchQuery: string;
    searchResults: any[];
    formSubmitted = true;
    productsFormArray: FormArray;
    productVariationsMap: { [productId: string]: any[] } = {};
    selectedSizeColorMap: { [productId: string]: { size: string, color: string } } = {};
    user: User;

    matchedVariationIds: Set<string> = new Set();
    matchedProductBundleIds: Set<string> = new Set();


    get sales_order_num() {
        return this._formBuilder.control(`SO-00${this.currentSalesOrderNum}-A`, Validators.required);
    }

    options: any = {
        componentRestrictions: { country: 'NP' },
        types: { city: 'Kathmandu' }

    };




    constructor(

        private _formBuilder: FormBuilder,
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _productBundleService: productBundleService,
        private dialog: MatDialog,
        private _customerService: ContactsService,
        private _userService: UserService,
        private _productService: productsService,
        private _salesOrderService: PreOrdersService,
        private datePipe: DatePipe,
        private _salesorderNumber: SalesOrderNumberService,
        private _productVaraitons: productVaraitonService,
        private cd: ChangeDetectorRef,

    ) {


        this.filteredCountries = this.countryControl.valueChanges.pipe(
            startWith(''),
            map((value) => this._filterCountries(value))
        );
        this.InsidefilteredDistricts = this.districtControl.valueChanges.pipe(
            startWith(''),
            map((value) => this._filterInsideDistricts(value))
        )
        this.customers$ = this._customerService.getContacts().pipe(take(1));

        this.customers$.subscribe((customers) => {
            const data = customers['data'];
        });

        const products$ = this._productVaraitons.getproductVaraiton().pipe(
            take(1),
            map((response) => response['data'])
        );

        products$.subscribe((data) => {
        });


    }

    ngOnInit() {
        this.fetchData();

        this._userService.user$
            .pipe((takeUntil(this._unsubscribeAll)))
            .subscribe((user: User) => {
                this.user = user;
            });

        this.salesOrderForm = this._formBuilder.group({

            Customers: [this.CustomerControl, Validators.required],
            sales_order_num: [
                `PRE-00${this.currentSalesOrderNum}-A`,
                Validators.required,
            ], // Initialize with the starting value
            order_number: [''],
            slaes_order_date: ['', Validators.required],
            sales_order_shippmentDate: [''],
            pos: [''],
            email: [''],
            soical_link: [''],
            delivery_type: [''],
            shipping_area: [''],
            payment_type: [''],
            payment_terms: [''],
            discountSuffix: ['%'],
            adjustmentSuffix: ['%'],
            district: this.districtControl,
            country: this.countryControl,
            shipping_carrier_invalley: [''],
            shipping_carrier_invalley_others: [''],
            shipping_carrier_outvalley: [''],
            shipping_carrier_int: [''],
            delivery_detail: [''],
            items_rate: [''],
            gender: [''],
            company: [''],
            products: this._formBuilder.array([]),

            address: [''],

            notes: [null],
            socialMedia: [null],
            sub_total: [0], // Initialize with a default value
            total: [0], // Initialize with a default value
            adjustment: [0],
            discount: [0],
            shipping_charges: [0],
            refrences: [''],
            sales_person: [''],
            others: [''],
            tags: [''],
            others_out: ['']
        });

        this.filteredProductOptions$ = of([]);

        const salesOrderDateControl = new FormControl(new Date());

        // Get the current date
        const currentDate = new Date();

        // Set the default value of the sales order date to today's date
        salesOrderDateControl.setValue(currentDate);

        // Convert the default date value to a formatted string
        const datePipe = new DatePipe('en-US');
        const defaultDateValue = datePipe.transform(currentDate, 'yyyy-MM-dd');

        // Update the form control name and value in the form group
        this.salesOrderForm.addControl(
            'slaes_order_date',
            salesOrderDateControl
        );

        this.salesOrderForm.get('slaes_order_date').setValue(defaultDateValue);
        // Add one day to the current date
        const tomorrow = new Date(currentDate.getTime() + 24 * 60 * 60 * 1000);
        this.salesOrderForm.get('sales_person').patchValue(this.user.username.toLocaleUpperCase())
        // Convert the tomorrow date value to a formatted string
        const tomorrowFormatted = datePipe.transform(tomorrow, 'YYYY-MM-DD');

        // Update the form control name and value in the form group
        this.salesOrderForm.addControl(
            'sales_order_shippmentDate',
            new FormControl(tomorrowFormatted, Validators.required)
        );
        this.salesOrderForm
            .get('sales_order_shippmentDate')
            .setValue(tomorrowFormatted);
        // Clear the products FormArray
        (this.salesOrderForm.get('products') as FormArray).clear();

        // Define the initial number of products
        const initialProductCount = 1;
        const productsArray = this.salesOrderForm.get('products') as FormArray;
        const numberOfProducts = productsArray.length;


        // After initializing your form array
        const productArray = this.salesOrderForm.get('products') as FormArray;
        for (let i = 0; i < productArray.length; i++) {
            this.animationFlags[i] = false;
            this.match_stock[i] = false;
            this.filterByBundle[i] = false;
            this.productActive[i] = false;

        };

        this.active = Array(numberOfProducts).fill(false);


        const subscribeToValueChanges = (productGroup: FormGroup) => {
            const rateControl = productGroup.get('rate');
            const quantityControl = productGroup.get('quantity');

            rateControl.valueChanges.subscribe(() => {
                this.calculateTotal();
            });

            quantityControl.valueChanges.subscribe(() => {
                this.calculateTotal();
            });


        };

        for (let i = 0; i < initialProductCount; i++) {
            const productGroup = this._formBuilder.group({
                id: [''],
                product_bundle: [''],
                bundle_id: [''],
                product: ['', [Validators.required]],
                quantity: ['', Validators.required],
                rate: ['', Validators.required],
                amount: [''],
                color: [''],
                size: [''],

            });

            subscribeToValueChanges(productGroup);

            (this.salesOrderForm.get('products') as FormArray).push(
                productGroup
            );
        }

        this.salesOrderForm.get('discount').valueChanges.subscribe(() => {
            this.calculateTotal();
        });
        this.salesOrderForm.get('discountSuffix').valueChanges.subscribe(() => {
            this.calculateTotal();
        });



        this.salesOrderForm.get('adjustment').valueChanges.subscribe(() => {
            this.calculateTotal();
        });
        this.salesOrderForm
            .get('adjustmentSuffix')
            .valueChanges.subscribe(() => {
                this.calculateTotal();
            });
            this.salesOrderForm
            .get('shipping_charges')
            .valueChanges.subscribe(() => {
                this.calculateTotal();
            });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    handleAddressChange(address: any) {
        if (!address) { return; }
        const addrName = address.name.split(",");
        const formattedAddr = address.formatted_address.split(",");
        if (formattedAddr.includes(...addrName)) {
            this.formattedAddress = address.formatted_address;
        } else {
            this.formattedAddress = `${address.name}, ${address.formatted_address}`;
        }
        this.salesOrderForm.get('address').patchValue(this.formattedAddress);
    }


    toggleFilterByBundle(index: number): void {
        this.filterByBundle[index] = !this.filterByBundle[index];
    }

    makeFieldEditable(customerInput: HTMLInputElement) {
        this.isFieldReadOnly = false;
        this.selectedCustomer = null; // Assuming `selectedCustomer` is the variable holding the current customer value
        if (customerInput) {
            customerInput.value = ''; // Clear the input value
        }

        // Clear the value of the form control
        this.CustomerControl.setValue('');
    }


    makeFieldEditable_outside_val(disctrict: HTMLInputElement) {
        this.isFieldReadOnly_outside_val = false;
        if (disctrict) {
            disctrict.value = ''; // Clear the input value
        }

        // Clear the value of the form control
        this.districtControl.setValue('');
    }


    openCustomerPopup(): void {
        const dialogRef = this.dialog.open(SalesOrderPopupComponent, {
            width: '500px',
        });

        dialogRef.componentInstance.formSubmitted.subscribe((formData) => {

            // Call your service method here
            this._customerService
                .createCustomer(formData)

                .then(async (response) => {

                    this.formSubmitted = false;

                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener(
                                'mouseenter',
                                Swal.stopTimer
                            );
                            toast.addEventListener(
                                'mouseleave',
                                Swal.resumeTimer
                            );
                        },
                    });

                    Toast.fire({
                        icon: 'success',
                        title: `Customer  created!! `,
                    });
                    await this.fetchData();
                    this.selectOption(response.data)
                    this.CustomerControl.markAsUntouched();
                    dialogRef.close();

                    // Handle the response from the service if needed
                })
                .catch((error) => {
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener(
                                'mouseenter',
                                Swal.stopTimer
                            );
                            toast.addEventListener(
                                'mouseleave',
                                Swal.resumeTimer
                            );
                        },
                    });

                    Toast.fire({
                        icon: 'error',
                        title: `'Error creating Customer: ' ${error.message};`,
                    });
                });
        });

    }

    openProductDetails(): void {

    }

    sumbitSalesOrder() {
        if (this.salesOrderForm.valid) {
            // Show the loading indicator
            const customerData = { ...this.salesOrderForm.value };
            const customer = this.salesOrderForm.get('Customers')?.value;
            customerData.Customers = customer ? customer.value.id : null;
            if (customerData.Customers === undefined) {
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top', // Set position to 'top'
                    customClass: {
                        popup: 'swal2-toast-top-center', // Center the toast
                    },
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener(
                            'mouseenter',
                            Swal.stopTimer
                        );
                        toast.addEventListener(
                            'mouseleave',
                            Swal.resumeTimer
                        );
                    },
                });

                Toast.fire({
                    icon: 'warning',
                    title: `Please select valid customer`,
                });
                return
            }
            const products = this.salesOrderForm.get('products') as FormArray;
            const updatedProducts = products.value.map((item: any) => {
                const hasBundleId = !!item.bundle_id; // Check if bundle_id has a value

                return {
                    product: hasBundleId ? '' : item.id, // Set product to an empty string if bundle_id has a value
                    product_bundle: hasBundleId ? item.bundle_id : item.product_bundle, // Set product_bundle to bundle_id if it has a value
                    quantity: item.quantity,
                    rate: item.rate,
                    amount: item.amount,
                };
            });

            customerData.products = updatedProducts;
            Swal.fire({
                title: "Loading",
                html: `
                    <div style="display: flex; flex-direction: column; align-items: center;">
                        <img src="assets/dragon.gif" style="width: 60px; height: 80px;" alt="loading"/>
                        <p style="margin: 10px 0 0 0; font-size: 14px; color: #666;">Creating, please wait...</p>
                    </div>
                `,
                timerProgressBar: true,
                didOpen: () => {
                    Swal.showLoading();
                },
            });

            // Update the contact on the server
            this._salesOrderService
                .createpreorders(customerData)
                .then((response) => {
                    if (response.success) {
                        this._salesorderNumber.incrementSalesOrderNum();

                        const Toast = Swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener(
                                    'mouseenter',
                                    Swal.stopTimer
                                );
                                toast.addEventListener(
                                    'mouseleave',
                                    Swal.resumeTimer
                                );
                            },
                        });

                        Toast.fire({
                            icon: 'success',
                            title: `${customerData.sales_order_num} Sales Order created!!`,
                        });

                        this.salesOrderForm.reset();
                        this._router.navigate(['../'], {
                            relativeTo: this._activatedRoute,
                        });
                    } else {
                        Swal.close();

                        const errorMessages = response.errors;

                        const Toast = Swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener(
                                    'mouseenter',
                                    Swal.stopTimer
                                );
                                toast.addEventListener(
                                    'mouseleave',
                                    Swal.resumeTimer
                                );
                            },
                        });

                        Toast.fire({
                            icon: 'error',
                            title: 'Error creating Sales Order ',
                            text: JSON.stringify(errorMessages),
                        });
                    }
                })
                .catch((error) => {
                    // Hide the loading indicator
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener(
                                'mouseenter',
                                Swal.stopTimer
                            );
                            toast.addEventListener(
                                'mouseleave',
                                Swal.resumeTimer
                            );
                        },
                    });

                    Toast.fire({
                        icon: 'error',
                        title: `Error creating Sales Order: ${error.message}`,
                    });
                });
        } else {
            Swal.close();

            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer);
                    toast.addEventListener('mouseleave', Swal.resumeTimer);
                },
            });

            Toast.fire({
                icon: 'error',
                title: 'Invalid form data',
                text: 'Please fill in all the required fields correctly.',
            });
        }
    }

    toggleDropdown() {
        const dropdownMenu = document.getElementById('dropdown-menu');
        if (dropdownMenu) {
            dropdownMenu.classList.toggle('show');
        }
    }



    saveAsDraft() {
    }

    saveAndPrint() {
    }




    // Your common utility function to check if data is an array
    isArrayData(data: any): data is any[] {
        return Array.isArray(data);
    }


    // Assuming you have a class with SalesOrderForm and ProductService and ProductBundleService instances

    // ... Your existing class members ...

    filterProductBundle(value: any): void {
        if (typeof value !== 'string') {
            this.filteredProductBundleOptions$ = of([]);
            return;
        }

        this._productBundleService.getproducts().pipe(
            switchMap((response) => {
                const bundles = response['data'];

                if (!Array.isArray(bundles) || bundles.length === 0) {
                    return of([]);
                }

                const filteredBundles = bundles.map((bundle) => {
                    const isBundleSelected = this.matchedProductBundleIds.has(bundle.id);

                    const bundleStyle = isBundleSelected ? { color: 'red' } : {};
                    const disabled = isBundleSelected;

                    return {
                        id: bundle.id,
                        name: bundle.bundle_name,
                        products: bundle.products,
                        description: bundle.bundle_description,
                        style: bundleStyle,
                        disabled: disabled,
                    };
                });

                return of(filteredBundles);
            })
        ).subscribe((filteredBundles) => {
            this.filteredProductBundleOptions$ = of(filteredBundles);
        });
    }



    getVendorPlaceholder(): string {
        return this.vendorsCount > 0
            ? 'Select a Customer'
            : 'No Customer available';
    }
    onSalesOrderDateChange(event: any) {
        const selectedDate: Date = event.value;

        const formattedDate = this.datePipe.transform(selectedDate, 'yyyy-MM-dd');

        this.salesOrderForm.get('slaes_order_date').setValue(formattedDate);

    }
    /**
      * Track by function for ngFor loops
      *
      * @param index
      * @param item
      */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    filterOptions(value: string): Observable<Contact[]> {
        if (typeof value !== 'string') {
            return of([]); // Return an empty array if value is not a string
        }
        this.formSubmitted = true;

        const filterValues = value.trim().toLowerCase().split(' ');

        return this.customers$.pipe(
            map((customers) => {
                if (Array.isArray(customers['data'])) {
                    return customers['data'].filter((customer) =>
                        this.matchesFilter(customer, filterValues)
                    );
                } else {
                    console.error('Customers data is not available:', customers);
                    return [];
                }
            })
        );
    }

    // Helper method to check if a name includes at least one part of the filter values
    private includesAnyPart(name: string, filterValues: string[]): boolean {
        return filterValues.some((filterValue) => name.includes(filterValue));
    }

    // Helper method to check if a customer matches the filter values
    private matchesFilter(customer: Contact, filterValues: string[]): boolean {
        // Trim and convert each part of the name to lowercase for a case-insensitive comparison
        const trimmedFirstName = customer.firstName?.trim().toLowerCase();
        const trimmedLastName = customer.lastName?.trim().toLowerCase();

        // Check if either first or last names contain at least one part of the filter values
        return (
            (trimmedFirstName && this.includesAnyPart(trimmedFirstName, filterValues)) ||
            (trimmedLastName && this.includesAnyPart(trimmedLastName, filterValues))
        );
    }


    displayOption(customer: Contact): string {
        const last_name = customer?.lastName || '';
        const first_name = customer?.firstName || '';
        return first_name && last_name ? first_name + ' ' + last_name : '';

    }

    selectOption(option: Contact) {
        if (!option) {
            return;
        }
        this.CustomerControl.setValue(option);
        this.CustomerControl.updateValueAndValidity();
        this.selectedCustomer = option;
        this.isFieldReadOnly = true;

    }


    isCustomerSelected(selectedCustomer: Contact): boolean {
        return this.CustomerControl.value && selectedCustomer && this.CustomerControl.value.id === selectedCustomer.id;
    }


    // Map the customer object to CustomerOption
    genderOptions(customer: Contact): CustomerOption[] {
        if (customer) {
            const mappedCustomer: CustomerOption = {
                id: customer.id,
                firstname: customer.firstName || '',
            };
            return [mappedCustomer];
        }
        return [];
    }

    addProductField(): void {
        const productFormGroup = this._formBuilder.group({
            id: [''],
            bundle_product: [''],
            bundle_id: [''],

            product: ['', [Validators.required]],
            quantity: ['', Validators.required],
            rate: ['', Validators.required],
            amount: [''],
            color: [''],
            size: [''],
        });

        const productArray = this.salesOrderForm.get('products') as FormArray;
        productArray.push(productFormGroup);

        const index = productArray.length - 1;
        this.setupProductFieldSubscriptions(productFormGroup, index);

        this.calculateTotal();
    }



    removeProductField(index: number): void {
        const productArray = this.salesOrderForm.get('products') as FormArray;
        const existingFormGroup = productArray.at(index) as FormGroup;

        // Remove the ID from the matchedVariationIds set
        const removedId = existingFormGroup.get('id').value;
        this.matchedVariationIds.delete(removedId);

        // Remove the values associated with the deleted product
        this.active.splice(index, 1);
        this.animationFlags.splice(index, 1);
        this.match_stock.splice(index, 1);
        this.current_stock.splice(index, 1);
        this.product_details.splice(index, 1);
        this.productActive.splice(index,1)

        // Remove sizes and colors associated with the deleted product
        this.sizes.splice(index, 1);
        this.colors.splice(index, 1);

        // Remove the form group from the form array
        productArray.removeAt(index);

        this.calculateTotal();
        this.cd.markForCheck();
    }


    setupProductFieldSubscriptions(
        productGroup: FormGroup,
        index: number
    ): void {
        const quantityControl = productGroup.get('quantity');
        const rateControl = productGroup.get('rate');

        quantityControl.valueChanges.subscribe(() => {
            this.calculateTotal();
        });

        rateControl.valueChanges.subscribe(() => {
            this.calculateTotal();
        });


    }

    calculateTotal(): void {
        const productArray = this.salesOrderForm.get('products') as FormArray;

        let subTotal = 0;
        productArray.controls.forEach((control: FormGroup) => {
            const quantity = control.get('quantity').value;
            const rate = control.get('rate').value;

            const amount = (quantity || 0) * (rate || 0);
            control.get('amount').setValue(amount);

            subTotal += amount || 0;
        });

        this.salesOrderForm.get('sub_total').setValue(subTotal);
        const discountSuffix = this.salesOrderForm.get('discountSuffix').value;
        const adjustmentSuffix =
            this.salesOrderForm.get('adjustmentSuffix').value;
            const discount = +this.salesOrderForm.get('discount').value || 0;

        const adjustment = +this.salesOrderForm.get('adjustment').value || 0;

        const shipping = +this.salesOrderForm.get('shipping_charges').value || 0;



        let calculatd_shipping = shipping
        let calculatedDiscount = discount;
        let calculatedAdjustment = adjustment;

        if (discountSuffix === '%') {
            calculatedDiscount = (subTotal * discount) / 100;
        } else if (discountSuffix === 'rs'){
            calculatedDiscount = discount;

        }

        if (adjustmentSuffix === '%') {
            calculatedAdjustment = (subTotal * adjustment) / 100;

        }else if (adjustmentSuffix === 'rs'){
            calculatedAdjustment = adjustment;

        }

        if (adjustmentSuffix == '%' && discountSuffix == '%') {
            calculatedDiscount = (subTotal * discount) / 100;
            const newTotal = subTotal - calculatedDiscount;

            calculatedAdjustment = (newTotal * adjustment) / 100;
        }

        const totalBeforeShipping = subTotal - calculatedDiscount + calculatedAdjustment;
        const total = totalBeforeShipping + calculatd_shipping;


        this.salesOrderForm.get('total').setValue(total);


    }





    toggleInputBack(): void {
        this.salesOrderForm.get('shipping_carrier_int').setValue('ARAMEX');
    }




    filterOptionsProduct(value: string): void {
        if (typeof value !== 'string') {
            this.filteredProductOptions$ = of([]);
            return;
        }

        this._productService.getPreOrderproducts().pipe(
            take(1),
            switchMap((products) => {
                if (!Array.isArray(products['data'])) {
                    return of([]);
                }

                const productObservables = products['data'].map((product) => {
                    return this._productService.getProductPreOrderVariations(product.id).pipe(
                        map((variations) => ({
                            product,
                            variations: variations.data,
                        }))
                    );
                });

                return forkJoin(productObservables);
            }),
            map((productsWithVariations) => {
                const filteredProducts = productsWithVariations
                    .map(({ product, variations }) => {
                        const productId = product.id;

                        const allDisabled = variations.every((variation) => {
                            const isPreOrder = !variation.preOrder;
                            const isSelected = this.matchedVariationIds.has(variation.id);
                            return  isSelected || isPreOrder;
                        });

                        const disabled = allDisabled;

                        return {
                            product: productId,
                            id: productId,
                            productName: product.productName,
                            sku: product.sku,
                            basePrice: product.basePrice,
                            size: product.size,
                            color: product.color,
                            description: product.description,
                            disabled: disabled,
                        };
                    })
                    .filter((filteredProduct) =>
                        filteredProduct.productName.toLowerCase().includes(value.toLowerCase()) ||
                        (filteredProduct.sku && filteredProduct.sku.toString().toLowerCase().includes(value.toLowerCase()))
                    );

                return filteredProducts;
            })
        ).subscribe((filteredProducts) => {
            this.filteredProductOptions$ = of(filteredProducts);
        });
    }
    filtersOptionsProduct(productId: string, index: number): void {
        this._productService.getProductVariations(productId).subscribe((variations) => {

            this.mappedVariations = variations.data.map((variation) => ({
                id: variation.id,
                size: variation.size,
                color: variation.color,
                price: variation.basePrice,
                base_product: productId,
                description: variation.base_product.description,
                category: variation.base_product.category,
                gender: variation.base_product.gender,
                preOrder: variation.preOrder,
            }));

            const sizeToColorMap = {};
            const allColorsDisabledMap = {};

            this.mappedVariations.forEach((variation) => {
                const { size, color, id, preOrder } = variation;

                if (!sizeToColorMap[size]) {
                    sizeToColorMap[size] = [];
                }

                const isSelected = this.matchedVariationIds.has(id);
                const style = isSelected ? { color: 'red' } : {};
                const disabled = isSelected || !preOrder;

                sizeToColorMap[size].push({
                    id,
                    color,
                    style,
                    disabled,
                    preOrder,
                });

                // Track if any color is still active for a size
                allColorsDisabledMap[size] = allColorsDisabledMap[size] || !isSelected;
            });

            const sizes = Object.keys(sizeToColorMap).map((size) => ({
                id: size,
                name: size,
                disabled: allColorsDisabledMap[size] && sizeToColorMap[size].every(color => color.disabled),
            }));

            const firstSize = sizes[0]?.id;
            const colors =
                sizeToColorMap[firstSize]?.map((color) => ({
                    id: color.color,
                    name: color.color,
                    style: color.style,
                    disabled: color.disabled,
                })) || [];

            this.updateSizesAndColors(index, sizes, colors);
        });
    }




    // Add this method to handle size and color updates
    updateSizesAndColors(index: number, sizes: any[], colors: any[]): void {
        this.sizes[index] = sizes;
        this.colors[index] = colors;
    }

    // Add this method to check if quantity exceeds stock
    quantityExceedsStock(index: number): boolean {
        const productArray = this.salesOrderForm.get('products') as FormArray;
        const existingFormGroup = productArray.at(index) as FormGroup;
        const quantityControl = existingFormGroup.get('quantity');

        return quantityControl.value > this.current_stock[index];
    }



    onSelectionChange(index: number): void {
        const productArray = this.salesOrderForm.get('products') as FormArray;
        const existingFormGroup = productArray.at(index) as FormGroup;

        if (existingFormGroup) {
            const selectedProductId = existingFormGroup.get('product').value.id;
            const selectedSizeId = existingFormGroup.get('size').value;

            // Enable all colors initially
            this.colors[index]?.forEach((color) => {
                color.disabled = false;
            });

            // Disable colors that are already selected for the selected size and have preOrder as false
            this.mappedVariations.forEach((variation) => {
                const { id, size, color, preOrder } = variation;

                if (size === selectedSizeId) {
                    const colorToDisable = this.colors[index]?.find(c => c.name === color);

                    if (colorToDisable) {
                        colorToDisable.disabled = !preOrder || this.matchedVariationIds.has(id);
                    }
                }
            });
            const selectedColorId = existingFormGroup.get('color').value;

            const selectedSizeColorCombo = `${selectedSizeId}${selectedColorId}`;
            const matchingOriginalVariation = this.mappedVariations?.find(variation => {
                const variationSizeColorCombo = `${variation.size}${variation.color}`;
                return variationSizeColorCombo === selectedSizeColorCombo;
            });

            if (matchingOriginalVariation) {
                if (matchingOriginalVariation.stock != 0) {
                    if (!this.matchedVariationIds.has(matchingOriginalVariation.id)) {
                        existingFormGroup.get('rate').patchValue(matchingOriginalVariation.price);
                        existingFormGroup.get('size').patchValue(matchingOriginalVariation.size);
                        existingFormGroup.get('color').patchValue(matchingOriginalVariation.color);
                        existingFormGroup.get('id').patchValue(matchingOriginalVariation.id);
                        this.active[index] = true;
                        this.current_stock[index] = matchingOriginalVariation.stock;
                        this.product_details[index] = matchingOriginalVariation.description;
                        this.animationFlags[index] = true;

                        // Add the ID to the matchedVariationIds set
                        this.matchedVariationIds.add(matchingOriginalVariation.id);

                        // Disable colors for the selected size
                        this.disableColorsForSelectedSize(index, selectedSizeId);
                        this.addProductField();

                        // Reset the animation flag after 3 seconds (adjust the duration as needed)
                        setTimeout(() => {
                            this.animationFlags[index] = false;
                        }, 3000);

                        // Call your function to update the form based on the selected product
                    } else {
                        // ... (existing code)
                    }
                } else {
                    // ... (existing code)
                }
            } else {
                console.log("No matching original variation found for the selected size and color.");
            }
        }
    }

    disableColorsForSelectedSize(index: number, selectedSizeId: string): void {
        // Disable colors for the selected size
        this.colors[index].forEach((color) => {
            color.disabled = color.id !== selectedSizeId;
        });

        // Update the UI
        this.updateSizesAndColors(index, this.sizes[index], this.colors[index]);
    }


    selectOptionProduct(option: products, index: number): void {




        if (option && !option.isOutOfStock) {
            const productFormGroup = this._formBuilder.group({
                id: [''],
                product: [option, [Validators.required]],
                quantity: [''],
                product_bundle: [''],
                bundle_id: [''],
                rate: [option.basePrice, ''],
                amount: [''],
                size: [],
                color: [],
            });
            this.productActive[index] = true;
            this.filtersOptionsProduct(option.id, index)


            const productArray = this.salesOrderForm.get('products') as FormArray;

        }
    }




    selectOptionProductBundle(option: productBundle, index: number): void {


        const selectedProducts = option.products.map(product => {
            return {
                product: product,

            };
        });
        console.log(selectedProducts)
        const dialogRef = this.dialog.open(ProductBundlePopupComponent, {
            width: '3000px',
            height: '750px',
            data: {
                selectedProducts: selectedProducts
            }
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result && result.total && result.otherData && result.otherData.products && Array.isArray(result.otherData.products)) {
                console.log("total", result.total);

                // Extract sizes, colors, product names, and quantities from the array of products
                const sizes = result.otherData.products.map((product) => product.size);
                const colors = result.otherData.products.map((product) => product.color);
                const name = result.otherData.products.map((product) => product.product);
                const product_name = name.map((product) => product.productName)
                this.name[index] = product_name;
                const quantities = result.otherData.products.map((product) => product.quantity);

                this.quantity[index] = quantities;


                // Create a reference to the form group
                const productFormGroup = this._formBuilder.group({
                    id: [''],
                    bundle_id: [option.id],
                    product: [option, [Validators.required]],
                    product_bundle: [option],
                    quantity: [''], // Directly bind to quantities
                    rate: [result.total, ''],
                    amount: [''],
                    size: [sizes.join(', ')], // Update with the combined size values
                    color: [colors.join(', ')], // Update with the combined color values
                });
                const productArray = this.salesOrderForm.get(
                    'products'
                ) as FormArray;
                const existingFormGroup = productArray.at(index) as FormGroup;

                if (existingFormGroup) {
                    existingFormGroup.patchValue(productFormGroup.value);

                }
                this.active[index] = true;
                this.animationFlags[index] = true;

                this.matchedProductBundleIds.add(option.id);

                this.cd.detectChanges();
            } else {
                console.error("Invalid data structure or missing required properties");
                console.log("result:", result);
                // Handle the case where the data structure is invalid or properties are missing
            }
        });





    }


    displayProductOption(product: products): string {
        if (product) {

            const productName = product.productName || '';
            const productPrice = product.sku
                ? ' - ' + product.sku.toString()
                : '';

            return productName + productPrice;

        }

        return '';
    }


    displayProductBundleOption(product: any): string {
        if (product) {
            console.log(product)
            const productName = product.name || '';
            const productPrice = product.description
                ? ' - ' + product.description.toString()
                : '';

            return productName + productPrice;

        }

        return '';
    }



    getFormFieldHelpersAsString(): string {
        return this.formFieldHelpers.join(' ');
    }

    pointOfsales(): void {
        const type = this.salesOrderForm.controls['pos'].value;
        // console.log(type, 'check if data is coming');

        if (type == 'Instagram' || type == 'Website' || type == 'Facebook') {
            this.showSocailLink = false;
            this.showEmail = true;
            this.showPaymentMethod = true;
            this.showShippingArea = true;
        } else if (type == 'Store Vist') {
            this.showEmail = false;
            this.showSocailLink = true;
            this.showPaymentMethod = true;
            this.showShippingArea = false;
        } else {
            this.showEmail = true;
            this.showSocailLink = true;
            this.showPaymentMethod = false;
            this.showShippingArea = false;
        }
    }

    addressField(): void {
        const type = this.salesOrderForm.controls['shipping_area'].value;
        const shipping = this.salesOrderForm.get('shipping_charges').value || 0;



        // console.log(type, 'addressField');
        if (type == 'Inside Valley') {
            this.showAddress = true;
            this.showShippinginvalley = true;
            this.showDistrict = true;
            this.showShippingOutvalley = false;
            this.showCountry = false;
            this.shwoShippingInternational = false;
            this.showDeliveryDetails = false;
        } else if (type == 'Outside Valley') {
            const currentShippingCharges = this.salesOrderForm.get('shipping_charges').value || 0;

            this.salesOrderForm.get('shipping_charges').patchValue(currentShippingCharges + 100);

            this.salesOrderForm.get('shipping_charges').setValidators([Validators.required]);
            this.showAddress = true;
            this.showShippinginvalley = false;
            this.showDistrict = true;
            this.showShippingOutvalley = true;
            this.showCountry = false;
            this.shwoShippingInternational = false;
            this.showDeliveryDetails = false;
        } else if (type == 'International') {
            this.salesOrderForm.get('shipping_charges').patchValue(0);
            this.salesOrderForm.get('shipping_charges').patchValue(0);

            this.showAddress = true;
            this.showShippinginvalley = false;
            this.showDistrict = false;
            this.showShippingOutvalley = false;
            this.showCountry = true;
            this.shwoShippingInternational = true;
            this.showDeliveryDetails = true;
        } else {
            this.salesOrderForm.get('shipping_charges').clearValidators();

        }
        this.salesOrderForm.get('shipping_charges').updateValueAndValidity();

    }


    showOtherss(): void {
        const type = this.salesOrderForm.controls['shipping_carrier_int'].value;
        if (type == 'others') {
            this.showOthers = true;
        } else {
            this.showOthers = false;
        }
    }

    private _filterCountries(value: string) {
        const filterValue = value.toLowerCase();
        return contrydata.filter((country) =>
            country.toLowerCase().includes(filterValue)
        );
    }

    private _filterInsideDistricts(value: string) {
        const filterValue = value.toLowerCase();
        return insideValleyData.filter((district) =>
            district.toLowerCase().includes(filterValue)
        );
    }


    formatDate(date: Date, format: string, controlName: string) {
        const formattedDate = this.datePipe.transform(date, format);
        this.salesOrderForm.get(controlName)?.setValue(formattedDate);
    }


    async fetchData() {
        try {

            this.filteredDistricts = this.districtControl.valueChanges.pipe(
                startWith(''),
                map((value) => this._filterDistricts(value))
            );

            this.filteredCustomers$ = this.CustomerControl.valueChanges.pipe(
                startWith(''),
                debounceTime(300),
                switchMap((value) => this.filterOptions(value))
            );
            this.currentSalesOrderNum = this._salesorderNumber.getCurrentSalesOrderNum()

            const districtNames = await this._salesOrderService.fetchData();
            this.districts = districtNames.sort();

        } catch (error) {
            // console.error(error);
        }
    }

    private _filterDistricts(value: string): string[] {
        const filterValue = value.toLowerCase();
        return this.districts.filter((district) =>
            district.toLowerCase().includes(filterValue)
        );
    }
    onDistrictSelected(event: MatAutocompleteSelectedEvent): void {
        this.isFieldReadOnly_outside_val = true;
    }


    toggleOtherInput(): void {
        this.salesOrderForm.get('shippingCarrier').setValue('OTHERS');
    }


    toggleInputs(): void {
        this.salesOrderForm.get('shipping_carrier_outvalley').setValue('Dash logistics');
    }


    toggleInputss(): void {
        this.salesOrderForm.get('shipping_carrier_invalley').setValue('Dash logistics');
    }

    hasUnsavedChanges(): boolean {
        return this.salesOrderForm.dirty;
    }

    canDeactivate(): boolean {
        return !this.salesOrderForm.dirty || confirm('You have unsaved changes. Are you sure you want to leave?');
    }

    makeFieldEditableProduct(index: number) {
        this.productActive[index] = false;
        const productInput = document.getElementById(`product-input-${index}`) as HTMLInputElement;
        if (productInput) {
            productInput.removeAttribute('readonly'); // Make the input field editable
            productInput.value = ''; // Clear the value of the input field
        }
    }
    
}