import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ExchangesService } from './exchanges.service';
import { Exchanges } from './exchanges.type';

@Injectable({
  providedIn: 'root'
})
export class ExchangeOrderResolver implements Resolve<Exchanges[]> {

  constructor(private salesOrderService: ExchangesService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Exchanges[]> {
    return this.salesOrderService.getSalesOrder().pipe(
      catchError((error) => {
        console.error('Error fetching sales orders', error);
        return of([]);
      })
    );
  }
}
