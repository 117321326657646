import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PromoCodeService } from '../promo-code.service';

@Component({
  selector: 'app-promo-code-detail',
  templateUrl: './promo-code-detail.component.html',
  styleUrls: ['./promo-code-detail.component.scss']
})
export class PromoCodeDetailComponent implements OnInit {
  promoId: string;
  products: any;
  vendor: any; // Replace 'any' with the actual type of your product
  constructor(

    private route: ActivatedRoute,
    private promoCodeServie: PromoCodeService, // Replace with your actual product service
  ) { }

  ngOnInit(): void {

    this.route.paramMap.subscribe((params) => {
      this.promoId = params.get('id');
      this.fetchPromoDetails(this.promoId);
    });
  }

  fetchPromoDetails(id: any) {
    this.promoCodeServie.getPromoCodeById(id).subscribe(
      (data) => {
        this.vendor = data;

        // Clear existing products
        this.products = [];

        // Iterate over each product variant
        this.vendor.products.forEach(product => {
          console.log(product.product.base_product.productName)

          this.products.push(product);
        });
      },
      (error) => {
        console.error('Error fetching product details:', error);
      }
    );
  }
}
