<div class="sm:absolute sm:inset-0 flex flex-col flex-auto min-w-0 sm:overflow-hidden bg-card dark:bg-transparent">
    <!-- Header -->
    <div
        class="relative flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between py-8 px-6 md:px-8 border-b">
        <!-- Loader -->
        <div class="absolute inset-x-0 bottom-0" *ngIf="isLoading">
            <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
        </div>
        <!-- Title -->
        <div class="text-4xl font-bold tracking-tight">
            Vendors-Payment
            <mat-icon svgIcon="heroicons_outline:academic-cap"></mat-icon>
        </div>
        <!-- Actions -->
        <div class="flex flex-shrink-0 items-center mt-6 sm:mt-0 sm:ml-4">
            <!-- Search -->
            <mat-form-field class="fuse-mat-dense fuse-mat-no-subscript min-w-50">
                <mat-icon matPrefix [svgIcon]="'heroicons_outline:search'"></mat-icon>
                <input matInput [autocomplete]="'off'" (keyup)="applyFilter($event)"
                    [placeholder]="'Search Vendors-Expense'" />
            </mat-form-field>
            <!-- Add product button -->
            <button class="ml-4" mat-flat-button [color]="'primary'" (click)="createVendorsCredit()">
                <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
                <span class="ml-2 mr-1">Add</span>
            </button>
        </div>
    </div>


    <!-- Main -->
    <div class="flex flex-auto overflow-hidden">
        <!-- Products list -->
        <div class="flex flex-col flex-auto sm:mb-18 overflow-hidden sm:overflow-y-auto">
            <div class="mat-elevation-z8">
                <table mat-table [dataSource]="dataSource" matSort class="mat-table">
                    <!-- Expense Number Column -->
                    <ng-container matColumnDef="expense_number">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Payment Number
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <strong>
                                {{ row.expense_number }}
                            </strong>
                        </td>
                    </ng-container>

                    <!-- Expense Date Column -->
                    <ng-container matColumnDef="expense_date">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Payment Date
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <i>
                                <u>
                                    {{ row.expense_date | date }}
                                </u>
                            </i>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="vendor_id">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            VID </th>
                        <td mat-cell *matCellDef="let row">
                            <i>
                                <u>
                                    VHUB{{ row.vendor_id }}
                                </u>
                            </i>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="vendor_name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Vendors Name </th>
                        <td mat-cell *matCellDef="let row">
                            <i>
                                <u>
                                    {{ row.vendor_name }}
                                </u>
                            </i>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="vendor_contact_person">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Cpmtact Person </th>
                        <td mat-cell *matCellDef="let row">
                            <i>
                                <u>
                                    {{ row.vendor_contact_person }}
                                </u>
                            </i>
                        </td>
                    </ng-container>

                    <!-- Expense Head Column -->
                    <ng-container matColumnDef="expense_head">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Expense Head
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <strong>
                                {{ row.expense_head }}
                            </strong>
                        </td>
                    </ng-container>

                    <!-- Expense Category Column -->
                    <ng-container matColumnDef="expense_category">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Expense Category
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <strong>
                                {{ row.expense_category }}
                            </strong>
                        </td>
                    </ng-container>

                    <!-- Expense For Column -->

                    <!-- Expense Description Column -->


                    <!-- Total Column -->
                    <ng-container matColumnDef="total">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Total
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <strong>
                                NPR {{ row.total }}
                            </strong>
                        </td>
                    </ng-container>

                    <!-- Expense Type Column -->
                    <ng-container matColumnDef="expense_type">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Payment Type
                        </th>
                        <td mat-cell *matCellDef="let row">
                            {{ row.expense_type | uppercase }}
                        </td>
                    </ng-container>
                    <!-- Expense Type Column -->
                    <ng-container matColumnDef="is_approved">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Payment Status
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <div *ngIf="row.is_approved">
                                <span class="chip chip--success">
                                    <i style="font-size: 11px; margin-top: -2px;" matTooltip="Expense is Approved"
                                        matTooltipPosition="above" class="chip__label">
                                        <strong>Approved </strong>
                                    </i>
                                </span>
                            </div>

                            <div *ngIf="!row.is_approved">
                                <span class="chip chip--error">
                                    <i style="font-size: 11px; margin-top: -2px;" matTooltip="Expense is Not Approved"
                                        matTooltipPosition="above" class="chip__label">
                                        <strong>Not Approved </strong>
                                    </i>
                                </span>
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="is_credit">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Is Credit
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <div *ngIf="row.is_credit">
                                <span class="chip chip--success">
                                    <i style="font-size: 11px; margin-top: -2px;" matTooltip="Expense is Approved"
                                        matTooltipPosition="above" class="chip__label">
                                        <strong>Credited </strong>
                                    </i>
                                </span>
                            </div>

                            <div *ngIf="!row.is_credit">
                                <span class="chip chip--error">
                                    <i style="font-size: 11px; margin-top: -2px;" matTooltip="Expense is Not Approved"
                                        matTooltipPosition="above" class="chip__label">
                                        <strong>Not Credited </strong>
                                    </i>
                                </span>
                            </div>
                        </td>
                    </ng-container>

                    <!-- Actions Column -->
                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef>Actions</th>
                        <td mat-cell *matCellDef="let row">
                            <button mat-icon-button matTooltip="View" (click)="showDetails(row.id)">
                                <mat-icon class="icon-size-4" svgIcon="heroicons_outline:eye"></mat-icon>
                            </button>
                           

                            <button *ngIf="!row.is_approved" mat-icon-button matTooltip="Approve Payments"
                                (click)="approvePayment(row.id)">
                                <mat-icon class="icon-size-4" svgIcon="heroicons_outline:plus-circle"></mat-icon>
                            </button>


                            <button *ngIf="row.is_approved && !row.approved_account" mat-icon-button matTooltip="Account Payment"
                                (click)="openRefundPackage(row.id,row.total,row.vendor_name)">
                                <mat-icon class="icon-size-4" svgIcon="heroicons_outline:cash"></mat-icon>
                            </button>

                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>

                <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>
            </div>
        </div>
    </div>
</div>