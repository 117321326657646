<div class="flex flex-col flex-auto min-w-0">

    <div
        class="flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between p-6 sm:py-8 sm:px-10 border-b bg-card dark:bg-transparent">
        <div class="flex-1 min-w-0">
            <!-- Breadcrumbs -->
            <div class="flex flex-wrap items-center font-medium">
                <div>
                    <a class="whitespace-nowrap text-primary-500">Product Detail</a>
                </div>
            </div>
            <!-- Title -->
            <div class="mt-2">
                <h2 class="text-3xl md:text-4xl font-extrabold tracking-tight leading-7 sm:leading-10 truncate">
                    {{ detail?.base_product.productName }}
                </h2>

                <h3 class="text-xl md:text-xl font-extrabold tracking-tight leading-7 sm:leading-10 truncate">
                    ({{ detail?.sku }}) </h3>
                <h5 class="text-xl md:text-xl font-extrabold tracking-tight leading-7 sm:leading-10 truncate">
                    {{ detail?.color }} -- {{detail?.size}} </h5>
            </div>
        </div>

        <div class="flex items-center">
            <div class="flex chip credit" matTooltip="{{ detail?.stockTooltip }}">
                {{ detail?.stock }}
            </div>
            <div *ngIf="detail?.active" class="chip green" matTooltip="Active">
                ✓
            </div>
            <div *ngIf="!detail?.active" class="chip paid" matTooltip="Not Active">
                ✠
            </div>
        </div>
    </div>

    <div class="flex-auto p-6 sm:p-10">
        <div class="flex flex-wrap items-start w-full -m-4 mb-5">
            <!-- Cards Section -->
            <fuse-card class="flex items-center max-w-80 w-full p-6 filter-info mb-4 mr-10 bg-orange-100">
                <mat-icon class="icon-size-12 mr-6" svgIcon="mat_outline:money"></mat-icon>

                <div class="flex flex-col">
                    <div class="text-2xl font-semibold leading-tight">{{report?.sales_count || 0 }}</div>
                    <div class="text-md text-secondary">Total Sales </div>
                </div>
            </fuse-card>

            <fuse-card
                class="flex items-center justify-between max-w-80 w-full p-6 filter-info mb-4 mr-10 bg-amber-100">
                <div class="flex flex-col">
                    <div class="text-2xl font-semibold leading-tight">{{report?.exchange_count || 0 }}</div>
                    <div class="text-md text-secondary">Total Exchange</div>
                </div>
                <mat-icon class="icon-size-12 ml-6" svgIcon="mat_outline:production_quantity_limits"></mat-icon>
            </fuse-card>

            <fuse-card class="flex items-center max-w-80 w-full p-6 filter-info mb-4 mr-10 bg-green-100">
                <mat-icon class="icon-size-12 mr-6" svgIcon="mat_outline:shopping_cart"></mat-icon>

                <div class="flex flex-col">
                    <div class="text-2xl font-semibold leading-tight">{{report?.sales_return_count || 0 }}</div>
                    <div class="text-md text-secondary">Total Return</div>
                </div>
            </fuse-card>

            <fuse-card class="flex items-center justify-between max-w-80 w-full p-6 filter-info mb-4 mr-4 bg-red-100">
                <div class="flex flex-col">
                    <div class="text-2xl font-semibold leading-tight">
                        {{ report?.total_stock_sold || 0 }}
                    </div>
                    <div class="text-md text-secondary">Total Stock Sold </div>
                </div>
                <mat-icon class="icon-size-12 ml-6" svgIcon="mat_outline:credit_card_off"></mat-icon>
            </fuse-card>

        </div>

        <!-- Table for Key-Value Pairs -->
        <div class="flex flex-wrap items-start w-full max-w-400 -m-4">



            <fuse-card
                class="flex flex-col md:flex-row justify-between max-w-80 md:max-w-160 w-full p-8 pb-4 filter-listing mr-2 mt-3">
                <div class="flex flex-col flex-auto order-2 md:order-1">
                    <div class="text-2xl font-semibold leading-tight">{{detail?.base_product.productName}}</div>
                    <div class="flex items-center leading-none mt-2 -ml-1">
                        <div class="flex items-center">
                            <mat-icon class="text-orange-500 icon-size-5" [svgIcon]="'mat_outline:star'"></mat-icon>
                            <mat-icon class="text-orange-500 icon-size-5" [svgIcon]="'mat_outline:star'"></mat-icon>
                            <mat-icon class="text-orange-500 icon-size-5" [svgIcon]="'mat_outline:star'"></mat-icon>
                            <mat-icon class="text-orange-500 icon-size-5" [svgIcon]="'mat_outline:star'"></mat-icon>
                            <mat-icon class="text-orange-500 icon-size-5"
                                [svgIcon]="'mat_outline:star_half'"></mat-icon>
                        </div>
                        <div class="text-secondary ml-2">4.5</div>
                        <div class="text-secondary mx-2">&bull;</div>
                        <div class="text-secondary"> {{ this.report?.total_stock_sold || 0 }}

                            Sold</div>
                    </div>
                    <div class="mt-6">
                        <div class="flex flex-wrap items-center text-secondary my-1">
                            <span class="mx-2">&bull;</span>

                            <span> {{detail?.basePrice}} रु</span>

                        </div>
                        <div class="flex flex-wrap items-center text-secondary my-1">
                            <span class="mx-2">&bull;</span>

                            <span>{{detail?.base_product?.product_weight}} {{detail?.base_product?.weight_alis}}</span>

                        </div>
                        <div class="flex items-center text-secondary my-1">
                            <!-- <span class="mx-2">&bull;</span> -->

                            <span>{{detail?.base_product?.description}}</span>
                        </div>


                        <div class="flex items-center text-secondary my-1">
                            <span class="mx-2">&bull;</span>

                            <span>{{detail?.base_product?.collection?.collection_name}}</span>
                        </div>
                        <div class="flex flex-wrap items-center text-secondary my-1">
                            <span class="mx-2">&bull;</span>

                            <span>{{detail?.base_product?.fabric?.name}}</span>

                        </div>
                        <div class="flex flex-wrap items-center text-secondary my-1">
                            <span class="mx-2">&bull;</span>

                            <span class="whitespace-nowrap">{{detail?.size}}-- {{detail?.color}}</span>

                        </div>
                    </div>

                    <div class="flex items-center mt-3 -mx-3">
                        <!-- <button class="px-3 mr-1" mat-button [color]="'primary'" [routerLink]="['./']">
                            Location
                        </button>
                        <button class="px-3" mat-button [color]="'primary'" [routerLink]="['./']">
                            Reviews
                        </button> -->
                    </div>
                </div>
                <div class="order-1 md:order-2 w-full md:w-40 md:ml-6 mb-8 md:mb-4 rounded-lg overflow-hidden">
                    <img class="w-full h-full object-cover" [src]="sizeChartUrl" alt="Card cover image">
                </div>
            </fuse-card>



            <div id="wrapper" class="mr-2 flex">
                <div id="chart-months">
                    <apx-chart [series]="chartMonths?.series" [chart]="chartMonths?.chart" [colors]="chartMonths?.colors"
                        [yaxis]="chartMonths?.yaxis" [dataLabels]="commonOptions?.dataLabels" [fill]="commonOptions?.fill"
                        [stroke]="commonOptions?.stroke" [xaxis]="commonOptions?.xaxis"></apx-chart>
                </div>




            </div>

            <div id="chart-years">

                <apx-chart [series]="chartYears?.series" [chart]="chartYears?.chart" [colors]="chartYears?.colors"
                    [legend]="chartYears?.legend" [dataLabels]="commonOptions?.dataLabels" [fill]="commonOptions?.fill"
                    [stroke]="commonOptions?.stroke" [xaxis]="commonOptions.xaxis"></apx-chart>
            </div>


        </div>

        <div class="flex flex-wrap items-start w-full -m-4 mt-5">

            <fuse-card class="flex flex-col max-w-80 w-full px-8 pt-6 pb-4 filter-list mt-3  ">
                <div class="flex items-center justify-between">
                    <div class="text-2xl font-semibold leading-tight">Top Sellers</div>
                    <div class="-mr-3">


                    </div>
                </div>
                <div class="flex flex-col mt-5">
                    <ng-container *ngFor="let customer of report?.top_customers">


                        <div class="flex items-center mt-6">

                            <div class="flex flex-col min-w-0">
                                <div class="font-medium leading-none">HB{{customer.Customers__id}}
                                    {{customer.Customers__firstName}} {{customer.Customers__lastName}}</div>
                                <div class="text-md leading-none text-secondary mt-1">
                                    <strong>
                                        Stock: {{customer.total_quantity}} /
                                    </strong><u>
                                        NPR {{customer.total_sales_amount}}
                                    </u>
                                </div>

                            </div>
                        </div>
                    </ng-container>

                </div>
                <div class="flex items-center mt-6 -mx-3">

                </div>
            </fuse-card>


            <fuse-card class="flex flex-col max-w-80 w-full px-8 pt-6 pb-4 filter-list mt-3 ml-10 ">
                <div class="flex items-center justify-between">
                    <div class="text-2xl font-semibold leading-tight">Top Sales Person</div>
                    <div class="-mr-3">


                    </div>
                </div>
                <div class="flex flex-col mt-5">
                    <ng-container *ngFor="let customer of report?.top_salesPerson">


                        <div class="flex items-center mt-6">

                            <div class="flex flex-col min-w-0">
                                <div class="font-medium leading-none">{{customer.created_by__username | uppercase}}
                                </div>
                                <div class="text-md leading-none text-secondary mt-1">
                                    <strong>
                                        Stock: {{customer.total_quantity}} /
                                    </strong><u>
                                        NPR {{customer.total_sales_amount}}
                                    </u>
                                </div>
                                <div class="text-md leading-none text-secondary mt-1"></div>

                            </div>
                        </div>
                    </ng-container>

                </div>
                <div class="flex items-center mt-6 -mx-3">

                </div>
            </fuse-card>

            <fuse-card class="flex flex-col max-w-80 w-full px-8 pt-6 pb-4 filter-list mt-3 ml-10 ">
                <div class="flex items-center justify-between">
                    <div class="text-2xl font-semibold leading-tight">Top Location</div>
                    <div class="-mr-3">


                    </div>
                </div>
                <div class="flex flex-col mt-5">
                    <ng-container *ngFor="let customer of report?.top_location">


                        <div class="flex items-center mt-6">

                            <div class="flex flex-col min-w-0">
                                <div class="font-medium leading-none">{{customer.address}}
                                </div>
                                <div class="text-md leading-none text-secondary mt-1">
                                    <strong>
                                        Stock: {{customer.total_quantity}} /
                                    </strong><u>
                                        NPR {{customer.total_sales_amount}}
                                    </u>
                                </div>

                            </div>
                        </div>
                    </ng-container>

                </div>
                <div class="flex items-center mt-6 -mx-3">

                </div>
            </fuse-card>

            <fuse-card class="flex flex-col max-w-80 w-full px-8 pt-6 pb-4 filter-list mt-3 ml-10 ">
                <div class="flex items-center justify-between">
                    <div class="text-2xl font-semibold leading-tight">Most Paid Account</div>
                    <div class="-mr-3">


                    </div>
                </div>
                <div class="flex flex-col mt-5">
                    <ng-container *ngFor="let customer of report?.payment_type">


                        <div class="flex items-center mt-6">

                            <div class="flex flex-col min-w-0">
                                <div class="font-medium leading-none">{{customer.payment_type}}
                                </div>
                                <div class="text-md leading-none text-secondary mt-1">
                                    <strong>
                                        Stock: {{customer.total_quantity}} /
                                    </strong><u>
                                        NPR {{customer.total_sales_amount}}
                                    </u>
                                </div>
                            </div>
                        </div>
                    </ng-container>

                </div>
                <div class="flex items-center mt-6 -mx-3">

                </div>
            </fuse-card>


        </div>
        <div class="overflow-x-auto mt-8">
            <div class="text-md text-primary-500">Activity</div>

            <table class="min-w-full divide-y divide-gray-200">
                <thead>
                    <tr>
                        <th
                            class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Customer
                        </th>
                        <th
                            class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Type
                        </th>
                        <th
                            class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Sale No
                        </th>
                        <th
                            class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Status
                        </th>
                        <th
                            class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Total
                        </th>
                        <th
                            class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Date
                        </th>
                        <th
                            class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Time
                        </th>
                        <th
                            class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Created By
                        </th>
                    </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                    <tr *ngFor="let activity of history?.activity_logs">
                        <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{{
                            activity?.customer_name }}</td>

                        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{{ activity?.activity_type }}
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{{ activity?.sales_order_num }}
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{{ activity?.status }}
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{{ activity?.total }}
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{{
                            activity?.created_date }}</td>
                        <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{{
                            activity?.created_time }}</td>
                        <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{{
                            activity?.created_by }}</td>
                    </tr>
                </tbody>
            </table>
        </div>


        <div class="overflow-x-auto mt-8">
            <div class="text-md text-primary-500">Stock History</div>

            <table class="min-w-full divide-y divide-gray-200">
                <thead>
                    <tr>
                        <th
                            class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Change Quantity
                        </th>
                        <th
                            class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Previous Stock
                        </th>
                        <th
                            class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Adjusted Stock </th>
                        <th
                            class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Reason
                        </th>
                        <th
                            class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Created By
                        </th>
                        <th
                            class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Date
                        </th>
                        <th
                            class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Time
                        </th>

                    </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                    <tr *ngFor="let stockHistory of stockHistory?.data">
                        <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{{
                            stockHistory?.change_quantity }}</td>

                        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{{ stockHistory?.previous_stock }}
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{{ stockHistory?.current_stock }}
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{{
                            stockHistory?.reason }}
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{{
                            stockHistory?.created_by }}
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{{ stockHistory?.date
                            }}
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{{ stockHistory?.date
                            }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div>


</div>