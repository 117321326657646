import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { productGroup } from './product-group.type';
import { HttpHeaders } from '@angular/common/http';
import { server_env } from 'config';

@Injectable({
    providedIn: 'root',
})
export class productsGroupService {
    // Private
    private _products: BehaviorSubject<productGroup | null> =
        new BehaviorSubject(null);
    private _productsss: BehaviorSubject<productGroup[] | null> =
        new BehaviorSubject(null);

    public url = `${server_env.URL}api/v1/collection/`;
    public urlReport = `${server_env.URL}api/v1/collections/`;


    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors

    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for contact
     */
    get products$(): Observable<productGroup> {
        return this._products.asObservable();
    }

    /**
     * Getter for contacts
     */
    get productss$(): Observable<productGroup[]> {
        return this._productsss.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get contacts
     */

    getproducts(): Observable<productGroup[]> {
        return this._httpClient.get<productGroup[]>(`${this.url}`).pipe(
            tap((products: any) => {
                console.log();
                this._productsss.next(products.data);

                for (products in products.data) {
                }
            })
        );
    }

    /**
     * Get contact by id
     */
    getproductsById(id: string): Observable<productGroup> {
        const requestUrl = this.urlReport + id;

        return this._httpClient.get<productGroup[]>(`${requestUrl}`).pipe(
            take(1),
            map((products: any) => {
                this._products.next(products);

                // Return the contact
                return products;
            }),
            switchMap((products) => {
                if (!products) {
                    return throwError(
                        'Could not found contact with id of ' + id + '!'
                    );
                }

                return of(products);
            })
        );
    }

 

    /**
     * create customer
     * @param data
     * @returns
     */

    createproductss(data: FormData): Promise<any> {
        const requestUrl = this.url;
        const headers = new HttpHeaders();

        // Remove the default 'Content-Type' header to allow Angular to automatically set the appropriate headers for multipart form data
        headers.delete('Content-Type');

        return this._httpClient
            .post<any>(requestUrl, data, { headers })
            .toPromise();
    }
    /**
     * Update contact
     *
     * @param id
     * @param contact
     */
  
     updateproducts(id: string, contanct: any): Observable<productGroup> {
     
       return this.productss$.pipe(
           take(1),
           switchMap(Contacts =>
               this._httpClient.put(`${this.url}${id}/`, contanct).pipe(
                   map((updatedContact) => {
                    
                       // Find the index of the updated Contact
                       const index = Contacts.findIndex(item => item.id === id);
                       // Update the Contact
                       Contacts[index] = updatedContact;
                      
                       // Update the Contacts
                       this._productsss.next(Contacts);
   
                       // Return the updated Contact
                       return updatedContact;
                   }),
                   switchMap(updatedContact =>
                       this.products$.pipe(
                           take(1),
                           filter(item => item && item.id === id),
                           tap(() => {
                               // Update the Contact if it's selected
                               this._products.next(updatedContact);
                           })
                       )
                   )
               )
           )
       );
   }

   
    /**
     * Delete the contact
     *
     * @param id
     */
    deleteproducts(id: string): Observable<boolean> {
        return this.productss$.pipe(
            take(1),
            switchMap((products) =>
                this._httpClient.delete(`${this.url}${id}/`).pipe(
                    map((isDeleted: boolean) => {
                        // Find the index of the deleted contact
                        const index = products.findIndex(
                            (item) => item.id === id
                        );

                        // Delete the contact
                        products.splice(index, 1);

                        // Update the contacts
                        this._productsss.next(products);

                        // Return the deleted status
                        return isDeleted;
                    })
                )
            )
        );
    }
}
