import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmPopupComponent } from 'app/confirm-popup/confirm-popup.component';

@Component({
  selector: 'app-advance-filtering',
  templateUrl: './advance-filtering.component.html',
  styleUrls: ['./advance-filtering.component.scss']
})
export class AdvanceFilteringComponent implements OnInit {
  @Output() formSubmitted: EventEmitter<any> = new EventEmitter<any>();

  advance_filtering: FormGroup;
  formFieldHelpers = {
    size: [],
    color: [],
    priceRanges: [],
    stockRanges: []
  };

  constructor(
    private _formBuilder: FormBuilder,
    private _matDialog: MatDialogRef<ConfirmPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.advance_filtering = this._formBuilder.group({
      size: [''],
      color: [''],
      price_range: [''],
      stock_range: ['']
    });

    if (this.data) {
      if (this.data.sizes) {
        this.formFieldHelpers.size = this.data.sizes;
      }

      if (this.data.colors) {
        this.formFieldHelpers.color = this.data.colors;
      }

      if (this.data.prices) {
        this.formFieldHelpers.priceRanges = this.data.prices;
      }

      if (this.data.stocks) {
        this.formFieldHelpers.stockRanges = this.data.stocks;
      }
    }
  }

  submitForm(formData: any): void {
    if (this.advance_filtering.valid) {
      // Emit the form data
      this.formSubmitted.emit(formData);

      // Close the modal
      this.closeDialog();
    } else {
      console.error("Form is not valid. Please check the input fields.");
    }
  }

  closeDialog() {
    this._matDialog.close({
      saved: true,
      selectedOptions: {
        type: this.advance_filtering.value.type,
        size: this.advance_filtering.value.size,
        color: this.advance_filtering.value.color,
        price_range: this.advance_filtering.value.price_range,
        stock_range: this.advance_filtering.value.stock_range
      }
    });
  }

  cancel(): void {
    this._matDialog.close();
  }
}
