<div class="form-container">
    <form [formGroup]="paritalUpdateForm" (ngSubmit)="submitForm(paritalUpdateForm.value)">
        <div class="flex flex-col mt-1 p-4 pb-3 bg-card rounded  overflow-hidden">
            <p class="text-lg font-medium">Shipping Carrier Update</p>
            <p class="text-secondary">

                You can update your shipping Carrier From here all the previous detial will stay the same.
            </p>
            <mat-divider class="mt-4 mb-4"></mat-divider>
            <div class="flex mb-2">
                <mat-form-field style="max-width: 600px" class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix">
                    <mat-label class="ml-3">Shipping Area </mat-label>
                    <!-- Replace mat-select with input field -->
                    <input readonly matInput placeholder="Shipping Area" name="shipping_area_input"
                           [ngClass]="{'error-input': paritalUpdateForm?.get('shipping_area')?.invalid && paritalUpdateForm?.get('shipping_area')?.touched}"
                           [formControl]="paritalUpdateForm.get('shipping_area')"
                           (input)="addressField()">
                    
                    <mat-icon class="icon-size-4" matPrefix [svgIcon]="'feather:truck'"></mat-icon>
                    
                    <mat-error class="m-1 ml-5 mt-2"
                               *ngIf="paritalUpdateForm?.get('shipping_area')?.invalid && paritalUpdateForm?.get('shipping_area')?.touched">
                        <ng-container *ngIf="paritalUpdateForm?.get('shipping_area')?.hasError('required')">
                            Shipping Area is required.
                        </ng-container>
                    </mat-error>
                </mat-form-field>
            </div>
            
            

            <div class="flex mb-2" *ngIf="showShippinginvalley">
                <mat-form-field style="max-width: 600px"
                    class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix">
                    <mat-label class="ml-3">Shipping Carrier </mat-label>
                    <ng-container
                        *ngIf="paritalUpdateForm.get('shipping_carrier_invalley').value !== 'others'; else otherInputss">
                        <mat-select [ngClass]="formFieldHelpers" placeholder="Shipping Carrier"
                            name="shipping_carrier_invalley" (selectionChange)="addressField()"
                            [formControlName]="'shipping_carrier_invalley'" class="elevated-input">
                            <mat-option value="Dash logistics">DASH</mat-option>
                            <mat-option value="House of delivery">HOUSE OF DELIVERY</mat-option>
                            <mat-option value="In Driver">IN DRIVER</mat-option>
                            <mat-option value="Pathao">PATHAO</mat-option>
                            <mat-option value="others">OTHERS</mat-option>
                        </mat-select>

                    </ng-container>

                    <ng-template #otherInputss>
                        <input [ngClass]="formFieldHelpers" matInput placeholder="Other Shipping Carrier"
                            formControlName="shipping_carrier_invalley_others"
                            class="elevated-input small-input-text fuse-mat-rounded fuse-mat-emphasized-affix"
                            name="others" />
                        <mat-icon-button class="icon-size-4" (click)="toggleInputss()">
                            <mat-icon [svgIcon]="'feather:skip-back'"></mat-icon>
                        </mat-icon-button>
                    </ng-template>



                    <mat-icon class="icon-size-4" matPrefix [svgIcon]="'feather:truck'"></mat-icon>

                </mat-form-field>
            </div>


            <div class="flex mb-2" *ngIf="showShippingOutvalley">
                <mat-form-field style="max-width: 600px"
                    class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix">
                    <mat-label class="ml-3">Shipping Carrier Out Valley</mat-label>
                    <mat-icon class="icon-size-4" matPrefix [svgIcon]="'feather:truck'"></mat-icon>
                    <ng-container
                        *ngIf="paritalUpdateForm.get('shipping_carrier_outvalley').value !== 'others'; else otherInputs">
                        <mat-select [ngClass]="formFieldHelpers" placeholder="shipping Carrier"
                            name="shipping_carrier_outvalley" (selectionChange)="addressField()"
                            [formControlName]="'shipping_carrier_outvalley'"
                            class="elevated-input small-input-text">
                            <mat-option value="Dash logistics">DASH LOGISTICS</mat-option>
                            <mat-option value="House of delivery">HOUSE OF DELIVERY</mat-option>
                            <mat-option value="Courier">COURIER</mat-option>
                            <mat-option value="others">OTHERS</mat-option>
                        </mat-select>


                    </ng-container>

                    <ng-template #otherInputs>


                        <input matInput placeholder="Other Shipping Carrier" formControlName="others_out"
                            class="elevated-input small-input-text fuse-mat-rounded fuse-mat-emphasized-affix"
                            name="others" />
                        <mat-icon-button class="icon-size-4" (click)="toggleInputs()">
                            <mat-icon [svgIcon]="'feather:skip-back'"></mat-icon>
                        </mat-icon-button>
                    </ng-template>
                </mat-form-field>
            </div>



            <div class="flex mb-2" *ngIf="shwoShippingInternational">
                <mat-form-field style="max-width: 600px"
                    class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix">
                    <mat-label class="ml-3">Shipping Carrier International</mat-label>

                    <ng-container
                        *ngIf="paritalUpdateForm.get('shipping_carrier_int').value !== 'others'; else otherInput">
                        <mat-select [ngClass]="formFieldHelpers" placeholder="Shipping Carrier"
                            name="shipping_carrier_int" formControlName="shipping_carrier_int"
                            class="elevated-input">
                            <mat-option value="ARAMEX">ARAMEX</mat-option>
                            <mat-option value="others">OTHERS</mat-option>
                        </mat-select>
                    </ng-container>

                    <ng-template #otherInput>

                        <input [ngClass]="formFieldHelpers" matInput placeholder="Other Shipping Carrier"
                            formControlName="others"
                            class="elevated-input fuse-mat-rounded fuse-mat-emphasized-affix" name="others" />
                        <mat-icon-button class="icon-size-4" (click)="toggleInernationalInput()">
                            <mat-icon [svgIcon]="'feather:skip-back'"></mat-icon>
                        </mat-icon-button>

                    </ng-template>

                    <mat-icon class="icon-size-4" matPrefix [svgIcon]="'feather:truck'"></mat-icon>
                </mat-form-field>
            </div>

            <div class="flex mb-2" *ngIf="showOthers">
                <mat-form-field style="max-width: 600px"
                    class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix">
                    <mat-label class="ml-3">Others </mat-label>
                    <input [ngClass]="formFieldHelpers" matInput placeholder="Others" name="others"
                        [formControlName]="'others'" class="elevated-input small-input-text" />
                    <mat-icon class="icon-size-4" matPrefix [svgIcon]="'location_city'"></mat-icon>
                </mat-form-field>
            </div>

        </div>

        <div class="flex items-center justify-end mt-4">
            <button class="px-4 ml-2" mat-flat-button type="submit" color="primary">Save</button>
            <button (click)="cancel()" class="px-4 ml-2" mat-flat-button type="button" color="error">Cancel</button>
          </div>

    </form>
</div>