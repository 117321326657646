<!-- promoCodeForm form -->

<div class="w-full h-full">
    <form [formGroup]="promoCodeForm" class="rounded shadow-lg h-full">

        <div *ngIf="promoCodeForm" class="p-8">
            <div class="prose prose-sm max-w-3xl">
            </div>
            <div class="flex justify-end">
                <button mat-raised-button [routerLink]="['..']">
                    <span class="inline-flex items-center">
                        <mat-icon svgIcon="feather:x"></mat-icon>
                    </span>
                </button>
            </div>

            <div class="flex flex-col mt-1">
                <p class="text-lg font-medium mb-2">Create Promo Code</p>
                <p class="text-secondary">Welcome to the Promo Code Page.<br> Here, you can create and apply promo codes
                    to singular product variants or entire product .</p>

                <mat-divider class="mt-8 mb-10"></mat-divider>



                <div class="flex mb-4">
                    <mat-form-field style="max-width: 600px"
                        class="flex-auto fuse-mat-emphasized-affix fuse-mat-rounded">
                        <mat-label>Promo Code Name</mat-label>
                        <input required matInput type="text" placeholder="ADD PROMO CODE NAME" formControlName="name"
                            aria-label="Customers" oninput="this.value = this.value.toUpperCase()" />

                        <mat-icon class="icon-size-4" matPrefix [svgIcon]="'feather:hash'"></mat-icon>
                    </mat-form-field>
                </div>



                <div class="flex mb-2">
                    <mat-form-field style="max-width: 600px"
                        class="flex-auto  fuse-mat-emphasized-affix fuse-mat-rounded">
                        <mat-label>Description</mat-label>
                        <textarea matInput placeholder="Description" formControlName="description"
                            class="elevated-input" style="height: 100px;"></textarea>
                        <mat-icon class="icon-size-4" matPrefix svgIcon="feather:file-text"></mat-icon>
                    </mat-form-field>
                </div>


                <div class="flex mb-2">

                    <mat-form-field [ngClass]="formFieldHelpers" style="max-width: 600px"
                        class="flex-auto  fuse-mat-emphasized-affix fuse-mat-rounded">
                        <mat-label>Discount </mat-label>

                        <input type="text" onkeypress="return event.charCode>=48 && event.charCode<=57" matInput
                            placeholder="Discount" required name="discount" [formControlName]="'discount'"
                            class="elevated-input small-input-text" />
                        <span matPrefix>रु</span>

                        <mat-select [formControlName]="'discount_alias'" matSuffix>
                            <mat-option [value]="'%'">%</mat-option>
                            <mat-option [value]="'rs'">रु</mat-option>
                        </mat-select>
                        <mat-icon class="icon-size-4" matPrefix svgIcon="feather:percent"></mat-icon>

                    </mat-form-field>
                </div>

                <div class="flex mb-2">
                    <mat-form-field style="max-width: 600px"
                        class="flex-auto  fuse-mat-emphasized-affix fuse-mat-rounded">
                        <mat-label>Expiry Date</mat-label>
                        <input [min]="todayDate" matInput [matDatepicker]="picker2" placeholder="Expiry Date"
                            name="disable_date" formControlName="disable_date" class="elevated-input small-input-text"
                            required />
                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                    </mat-form-field>
                </div>



                <!-- <div class="flex mb-2">
                    <label class="switch">
                        <input type="checkbox" (change)="onCheckboxChange($event)">
                        <div>
                            <span class="text-secondary ml-3">Is Active</span>
                        </div>
                    </label>
                </div> -->



                <!-- Add Product Bundle Details here -->
                <mat-divider class="mt-6 mb-4"></mat-divider>



                <ng-template matStepLabel> Select Product </ng-template>

                <ng-container s formArrayName="products" class="mb-3">


                    <ng-container *ngFor="
                                    let product of promoCodeForm.get('products')
                                        .controls;
                                    let i = index;
                                    let first = first;
                                    let last = last;
                                    trackBy: trackByFn
                                " #item>

                        <!-- <mat-checkbox [(ngModel)]="selectVariant[i]" [formControlName]="'isVarient'" class="mr-3 mb-3"
                            (change)="toggleVariant(i)">Select Variant</mat-checkbox> -->

                        <div class="flex mb-3" [ngClass]="{'fadeInOut': animationFlags[i]}" [formGroupName]="i">


                            <mat-form-field
                                class="fuse-mat-no-subscript w-200 fuse-mat-rounded mr-4   fuse-mat-emphasized-affix">

                                <mat-label *ngIf="first">Product</mat-label>
                                <input [ngClass]="formFieldHelpers" [readonly]="productActive[i]"
                                    class="small-input-text" style="height: 50px" matInput
                                    [formControl]="product.get('product')" placeholder="Type SKU or Product Name"
                                    required [matAutocomplete]="auto" [ngStyle]="product.style"
                                    [attr.disabled]="product.disabled ? true : null" (input)="
                                        filterOptionsProduct($event.target.value)"
                                    (focus)="filterOptionsProduct($event.target.value)" [id]="'product-input-' + i" />

                                <mat-icon *ngIf="active[i]" class="icon-size-5" matSuffix [svgIcon]="'verified'"
                                    style="font-size: 24px; color: green;"></mat-icon>


                                <mat-autocomplete #auto="matAutocomplete" [displayWith]="
                                                displayProductOption.bind(this)
                                            " (optionSelected)="
                                                selectOptionProduct(
                                                    $event.option.value,
                                                    i
                                                )
                                            ">
                                    <mat-option
                                        style="height: auto; display: flex; align-items: center; padding: 4px 16px;font-size: small;"
                                        *ngFor="let option of filteredProductOptions$ | async; let i = index"
                                        [value]="option" [ngStyle]="option.style" [disabled]="option.disabled"
                                        [matTooltip]="option.disabled ? 'Inital Entry Required! ' : null">

                                        <div style="
                                                        display: flex;
                                                        align-items: center;
                                                    ">

                                            <span class="example-option-name">{{
                                                option.productName
                                                }}</span>
                                            <span class="example-option-sku">
                                                - {{ option.sku }}</span>
                                        </div>
                                    </mat-option>

                                    <mat-option style="
                                                    cursor: pointer;
                                                    height: fit-content;
                                                " (click)="openProductDetails()" *ngIf="
                                                    (filteredProductOptions$ | async)
                                                        ?.length === 0
                                                " [disabled]="true">
                                        No options found
                                        <br />
                                        <mat-icon svgIcon="feather:plus">
                                        </mat-icon>
                                        Add Product
                                    </mat-option>
                                </mat-autocomplete>
                                <button type="button" matSuffix *ngIf="productActive[i]" mat-icon-button
                                    (click)="makeFieldEditableProduct(i)">


                                    <mat-icon *ngIf="productActive[i]" class="icon-size-5" [svgIcon]="'feather:edit'"
                                        style="font-size: 24px; color: red;"></mat-icon>
                                </button>
                            </mat-form-field>

                            <ng-container>

                                <ng-container *ngIf="!active[i]">
                                    <!-- Render the mat-select when active is false -->
                                    <mat-form-field
                                        class="fuse-mat-no-subscript w-75 fuse-mat-rounded fuse-mat-emphasized-affix ml-2">
                                        <mat-label *ngIf="first">Size</mat-label>
                                        <mat-icon class="icon-size-4" matPrefix svgIcon="format_size"></mat-icon>

                                        <mat-select matInput formControlName="size" placeholder="Size" class="h-20"
                                            (selectionChange)="onSelectionChange(i, true)">
                                            <mat-option
                                                style="height: auto; display: flex; align-items: center; padding: 4px 16px;font-size: small;"
                                                *ngFor="let size of sizes[i]" [value]="size.id"
                                                [disabled]="size.disabled">
                                                {{ size.name }}</mat-option>

                                        </mat-select>
                                    </mat-form-field>

                                    <mat-form-field
                                        class="fuse-mat-no-subscript w-75 fuse-mat-rounded fuse-mat-emphasized-affix ml-2">
                                        <mat-label *ngIf="first">Color</mat-label>
                                        <mat-icon class="icon-size-4" matPrefix svgIcon="color_lens"></mat-icon>

                                        <mat-select matInput formControlName="color" placeholder="Color" class="h-20"
                                            (selectionChange)="onSelectionChange(i, true)">
                                            <mat-option
                                                style="height: auto; display: flex; align-items: center; padding: 4px 16px;font-size: small;"
                                                *ngFor="let color of colors[i]" [disabled]="color.disabled"
                                                [value]="color.id">{{ color.name }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </ng-container>

                                <ng-container *ngIf="active[i]">
                                    <!-- Render a readonly input or display the selected value when active is true -->
                                    <mat-form-field
                                        class="fuse-mat-no-subscript w-75 fuse-mat-rounded fuse-mat-emphasized-affix ml-3">
                                        <mat-icon class="icon-size-4" matPrefix svgIcon="format_size"></mat-icon>

                                        <mat-label *ngIf="first">Size</mat-label>
                                        <input class="small-input-text" matInput formControlName="size"
                                            placeholder="Size" style="height: 50px" class="small-input-text" readonly />
                                    </mat-form-field>

                                    <mat-form-field
                                        class="fuse-mat-no-subscript w-75 fuse-mat-rounded fuse-mat-emphasized-affix ml-3">
                                        <mat-label *ngIf="first">Color</mat-label>
                                        <mat-icon class="icon-size-4" matPrefix svgIcon="color_lens"></mat-icon>

                                        <input class="small-input-text" matInput formControlName="color"
                                            placeholder="Color" style="height: 50px" readonly />
                                    </mat-form-field>


                                </ng-container>

                            </ng-container>





                            <!-- Remove product -->
                            <ng-container *ngIf="!(first && last)">
                                <div class="flex items-center w-10 pl-2" [ngClass]="{ 'mt-6': first }">
                                    <button mat-icon-button (click)="removeProductField(i)" matTooltip="Remove">
                                        <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:trash'"></mat-icon>
                                    </button>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                </ng-container>

                <div class="inline-flex items-center mt-2 -ml-4 py-2 px-4">
                    <mat-icon matPrefix class="hidden sm:flex icon-size-5" [svgIcon]="'heroicons_solid:tag'"></mat-icon>
                    <span (click)="addProductField()"
                        class="ml-2 font-medium text-secondary cursor-pointer hover:underline">Add a
                        product</span>
                </div>




                <div class="flex items-center justify-end mt-10">
                    <button (click)="sumbitPromoCode()"
                        class="px-6 py-2 rounded text-white bg-blue-500 hover:bg-blue-600" mat-flat-button
                        [color]="'primary'">
                        Save
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>