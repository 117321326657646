import { Route } from '@angular/router';
import { ListproductGroupComponent } from './listproduct-group/listproduct-group.component';
import { CreateproductGroupComponent } from './createproduct-group/createproduct-group.component';
import { DetailproductGroupComponent } from './detailproduct-group/detailproduct-group.component';
import { UnsavedChangesGuard } from 'app/page-detect/wm-can-leave/detect-changes.guard';




export const ProductGroupRoutes: Route[] = [
   
        {
            path     : '',
            component: ListproductGroupComponent,
        },
        {
            path         : 'NEW',
            component    :CreateproductGroupComponent,
            canDeactivate: [UnsavedChangesGuard], 

           
        },
        {
            path         : ':id',
            component    : DetailproductGroupComponent,
          
          
        },
]
