<div class="mt-10 ml-15 md:ml-50 lg:ml-90">

  

  <div class="absolute inset-0 min-w-0 text-center overflow-auto print:overflow-visible" cdkScrollable>

    <div class="container mx-auto p-6 sm:py-8 sm:px-10 border-b bg-card dark:bg-transparent hide-on-print">
      <!-- Header and Action Buttons -->
      <div class="flex flex-col sm:flex-row items-center justify-between">
        <div class="flex-1 min-w-0 flex items-center">
          <button mat-button type="button" matTooltip="Close" class="hide-on-print" (click)="goBackOnPreviousWin()">
            <mat-icon svgIcon="feather:x"></mat-icon>
          </button>
         
        </div>
        <div class="text-lg text-primary-500 font-medium whitespace-nowrap">{{ datac?.date }}</div>
      </div>

      <!-- Vendor Information -->
      <div class="mt-4">
        <h2 class="text-3xl md:text-4xl font-extrabold tracking-tight leading-7 sm:leading-10 truncate">
          CO{{ datac?.id }}-A
        </h2>

        <h2 class="text-3xl md:text-4xl font-extrabold tracking-tight leading-7 sm:leading-10 truncate">
          {{ datac?.name }}
        </h2>
        <div class="text-secondary leading-tight mt-1">
          SKU: {{ datac.sku }}
        </div>
        <div class="text-secondary leading-tight mt-1">
          Created By: {{ datac.created_by }}
        </div>
      </div>


    </div>




    <div class="container mx-auto p-6 sm:py-8 sm:px-10 border-b bg-card dark:bg-transparent hide-on-print">
     
      <!-- Count Cards -->
      <div class="count-cards">
        <mat-card class="count-card">
          <mat-card-title>Total Quantity</mat-card-title>
          <mat-card-content>{{ totalQuantity }}</mat-card-content>
        </mat-card>
        <mat-card class="count-card">
          <mat-card-title>Total Amount</mat-card-title>
          <mat-card-content>{{ totalAmount | currency }}</mat-card-content>
        </mat-card>
      </div>

      <!-- Product Data Table -->
      <div class="product-table">
        <div class="search-bar mt-2 flex-auto" >
          <mat-form-field class="flex fuse-mat-rounded">
            <input matInput (keyup)="applyFilter($event)" placeholder="Search">
          </mat-form-field>
        </div>
      
        <table mat-table [dataSource]="productData" matSort class="mat-elevation-z8">
          <!-- Columns Definition -->
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
            <td mat-cell *matCellDef="let element"> {{element.id}} </td>
          </ng-container>
      
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
            <td mat-cell *matCellDef="let element"> {{element.productName}} </td>
          </ng-container>
      
          <ng-container matColumnDef="sku">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> SKU </th>
            <td mat-cell *matCellDef="let element"> {{element.primary_sku}} </td>
          </ng-container>
      
          <ng-container matColumnDef="secondary_sku">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Secondary SKU </th>
            <td mat-cell *matCellDef="let element"> {{element.secondary_sku}} </td>
          </ng-container>
      
          <ng-container matColumnDef="color">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Color </th>
            <td mat-cell *matCellDef="let element"> {{element.color}} </td>
          </ng-container>
      
          <ng-container matColumnDef="size">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Size </th>
            <td mat-cell *matCellDef="let element"> {{element.size}} </td>
          </ng-container>
      
          <!-- Header and Row Definition -->
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      
        <!-- Paginator -->
        <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons></mat-paginator>
      </div>
      
    </div>


  </div>
</div>