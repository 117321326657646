import { Route } from '@angular/router';


import { ListinventroySheetComponent } from './listinventroy-sheet/listinventroy-sheet.component';
import { CreateinventroySheetComponent } from './createinventroy-sheet/createinventroy-sheet.component';
import { DetailinventroySheetComponent } from './detailinventroy-sheet/detailinventroy-sheet.component';
import { InventoryResolver } from './inventory-sheet.resolver';
import { data } from 'cypress/types/jquery';



export const InventroySheetRoutes: Route[] = [

    {
        path: '',
        component: ListinventroySheetComponent,
    },
    {
        path: 'NEW',
        component: CreateinventroySheetComponent,

    },
    {
        path: ':id',
        component: DetailinventroySheetComponent,
        resolve      : {
            task     : InventoryResolver
           
        },


    },
]
