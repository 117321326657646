import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InventoryEntryComponent } from './inventory-entry/inventory-entry.component';
import { ListinventoryEntryComponent } from './listinventory-entry/listinventory-entry.component';
import { CreateinventoryEntryComponent } from './createinventory-entry/createinventory-entry.component';
import { DetailinventoryEntryComponent } from './detailinventory-entry/detailinventory-entry.component';
import { MaterialModule } from 'app/modules/material/material.module';
import { RouterModule } from '@angular/router';
import { InventoryEntryRoutes } from './inventory-entry.routing';



@NgModule({
  declarations: [
    InventoryEntryComponent,
    ListinventoryEntryComponent,
    CreateinventoryEntryComponent,
    DetailinventoryEntryComponent
  ],
  imports: [
    RouterModule.forChild(InventoryEntryRoutes),
    MaterialModule,
    CommonModule
  ]
})
export class InventoryEntryModule { }
