<div
    class="sm:absolute sm:inset-0 flex flex-col flex-auto min-w-0 sm:overflow-hidden bg-card dark:bg-transparent"
>
    <!-- Header -->
    <div
        class="relative flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between py-8 px-6 md:px-8 border-b"
    >
        <!-- Loader -->
        <div class="absolute inset-x-0 bottom-0" *ngIf="isLoading">
            <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
        </div>
        <!-- Title -->
        <div class="text-4xl font-bold tracking-tight">
            Credit Notes
            <mat-icon svgIcon="heroicons_outline:academic-cap"></mat-icon>
        </div>
        <!-- Actions -->
        <div class="flex flex-shrink-0 items-center mt-6 sm:mt-0 sm:ml-4">
            <!-- Search -->
            <mat-form-field
                class="fuse-mat-dense fuse-mat-no-subscript min-w-50"
            >
                <mat-icon
                    matPrefix
                    [svgIcon]="'heroicons_outline:search'"
                ></mat-icon>
                <input
                    matInput
                    [autocomplete]="'off'"
                    (keyup)="applyFilter($event)"
                    [placeholder]="'Search Credit Notes'"
                />
            </mat-form-field>
            <!-- Add product button -->
            <button
                class="ml-4"
                mat-flat-button
                [color]="'primary'"
                (click)="createVendorsCredit()"
            >
                <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
                <span class="ml-2 mr-1">Add</span>
            </button>
        </div>
    </div>

    <!-- Main -->
    <div class="flex flex-auto overflow-hidden">
        <!-- Products list -->
        <div
            class="flex flex-col flex-auto sm:mb-18 overflow-hidden sm:overflow-y-auto"
        >
            <div class="mat-elevation-z8">
                <table
                    mat-table
                    [dataSource]="dataSource"
                    matSort
                    class="mat-table"
                >
                    <!-- VendorsName Column -->
                    <ng-container matColumnDef="VendorsName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Vendor's Name
                        </th>
                        <td mat-cell *matCellDef="let row">
                            {{ row.VendorsName }}
                        </td>
                    </ng-container>

                    <!-- CreditNote Column -->
                    <ng-container matColumnDef="CreditNote">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Credit Note
                        </th>
                        <td mat-cell *matCellDef="let row">
                            {{ row.CreditNote }}
                        </td>
                    </ng-container>

                    <!-- OrderNumber Column -->
                    <ng-container matColumnDef="OrderNumber">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Order Number
                        </th>
                        <td mat-cell *matCellDef="let row">
                            {{ row.OrderNumber }}
                        </td>
                    </ng-container>

                    <!-- VendorCreditDate Column -->
                    <ng-container matColumnDef="VendorCreditDate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Vendor Credit Date
                        </th>
                        <td mat-cell *matCellDef="let row">
                            {{ row.VendorCreditDate }}
                        </td>
                    </ng-container>

                    <!-- Actions Column -->
                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef>Actions</th>
                        <td mat-cell *matCellDef="let row">
                            <button
                                mat-icon-button
                                matTooltip="View"
                                (click)="showDetails()"
                            >
                                <mat-icon
                                    svgIcon="heroicons_outline:eye"
                                ></mat-icon>
                            </button>
                            <button
                                mat-icon-button
                                matTooltip="Update"
                                (click)="updateVendorsCredit()"
                            >
                                <mat-icon
                                    svgIcon="heroicons_outline:plus-circle"
                                ></mat-icon>
                            </button>
                            <button
                                mat-icon-button
                                matTooltip="Delete"
                                (click)="deleteVendorsCredit()"
                            >
                                <mat-icon
                                    svgIcon="heroicons_outline:trash"
                                ></mat-icon>
                            </button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr
                        mat-row
                        *matRowDef="let row; columns: displayedColumns"
                    ></tr>
                </table>

                <mat-paginator
                    [pageSizeOptions]="[10, 25, 50, 100]"
                    showFirstLastButtons
                ></mat-paginator>
            </div>
        </div>
    </div>
</div>
