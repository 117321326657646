import { Injectable } from '@angular/core';
import { server_env } from 'config';
import { Subject, Observable } from 'rxjs';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { retryWhen, delay, take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WebSocketService {
  private socket$: WebSocketSubject<any>;
  private subject: Subject<any> = new Subject<any>();

  constructor() {
    this.connect();
  }

  private connect(): void {
    this.socket$ = webSocket({
      url: `${server_env.WS}inventory/`,
      deserializer: msg => {
        try {
          return JSON.parse(msg.data);
        } catch (e) {
          console.error('Invalid JSON:', msg.data);
          return null;
        }
      }
    });

    this.socket$.pipe(
      retryWhen(errors => errors.pipe(
        tap(val => console.error('WebSocket Error, retrying...', val)),
        delay(1000),
        take(10) // Retry up to 10 times
      ))
    ).subscribe(
      (message) => {
        if (message) {
          this.subject.next(message);
        }
      },
      (error) => console.error('WebSocket Error:', error),
      () => console.log('WebSocket connection closed')
    );
  }

  public send(data: any): void {
    this.socket$.next(data);
  }

  public getMessages(): Observable<any> {
    return this.subject.asObservable();
  }
}
