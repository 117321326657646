import { DatePipe } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import {
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FuseCardComponent } from '@fuse/components/card';
import { ConfirmPopupComponent } from 'app/confirm-popup/confirm-popup.component';
import { SalesOrderService } from 'app/sales-order/sales-order.service';
import Swal from 'sweetalert2';
import { PackagesOderService } from '../packages.service';
import {  Router } from '@angular/router';

@Component({
    selector: 'app-package-popup',
    templateUrl: './package-popup.component.html',
    styleUrls: ['./package-popup.component.scss'],
})
export class PackagePopupComponent implements OnInit {
    showMessage: boolean = false;
    formCancel: boolean = true;
    packageGroup: FormGroup;
    packageOrderCode: string;
    counter: number = 0;
    vendor: any;
    saleOderId: string;

    @ViewChild('fuseCard') fuseCard: FuseCardComponent;
    displayedColumns: string[] = ['date', 'salesOrderNumber', 'shipmentArea', 'address', 'deliveryType', 'customerName', 'paymentType', 'packageOrder', 'remarks', 'products'];

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _matDialog: MatDialogRef<ConfirmPopupComponent>,
        private _fb: FormBuilder,
        private productService: SalesOrderService,
        private datePipe: DatePipe,
        private _packageService: PackagesOderService,
        private _router: Router,
    ) {
        this.saleOderId = this.data.id;
        this.fetchProductDetails(this.saleOderId);
        this.fetchSalesOrderInformation(this.saleOderId)
    }

    ngOnInit(): void {
        //recently added this
        this.generatePackageCode();

        this.packageGroup = this._fb.group({
            salesOrderNumber: new FormControl(),
            shipmentArea: new FormControl(),
            address: new FormControl(),
            DeliveryType: new FormControl(),
            customerName: new FormControl(),
            customerId: new FormControl(),
            customerRemarks: new FormControl(),
            payment_type: new FormControl(),

            packageOrder: new FormControl(this.generatePackageCode()),
            packageDate: new FormControl(new Date(), [Validators.required]),
            products: this._fb.array([this.productArray()]),
        });
    }


    productArray() {
        return this._fb.group({
            productName: new FormControl(''),
            quantity: new FormControl(''),
            amount: new FormControl(''),
        });
    }

    generatePackageCode(): string {
        this.counter++;
        const randomPart = Array.from(
            { length: 6 },
            () => Math.random().toString(36).toUpperCase()[2]
        ).join('');
        const packageCode = `PKG-${String(this.counter).padStart(
            4,
            '0'
        )}-${randomPart}`;
        return packageCode;
    }

    fetchProductDetails(id: string): void {
        // Use your product service to fetch the product details based on the provided ID
        this.productService.getSalesOrderById(id).subscribe(
            (data) => {
                this.vendor = data;
                this.addProductValue(this.vendor);

                // After the date fatched, product date is patched in the product form array
                this.packageGroup.setControl(
                    'products',
                    this.setProduct(this.vendor.products)
                );
                // Iterate through the received data and patch the values into the form controls
                // this.product.thumbnail = this.getThumbnailUrl(data.thumbnail); // Update the thumbnail URL
            },
            (error) => {
                console.error('Error fetching product details:', error);
            }
        );
    }

    fetchSalesOrderInformation(id: string): void {
        this.productService.getSalesOrderById(id).subscribe(
            (data) => {
                this.vendor = data;
                const salesOrderNumber = data.sales_order_num
                const shipping_area = data.shipping_area;
                const address = data.address;
                const delivery_type = data.delivery_type;
                const customerName = data.Customers.firstName + ' ' + data.Customers.lastName;
                const customerId = data.Customers.Id;
                const customerRemarks = data.notes;
                const payment_type = data.payment_type;

                // Patch the form controls with fetched data
                this.packageGroup.patchValue({
                    shipmentArea: shipping_area,
                    address: address,
                    DeliveryType: delivery_type,
                    customerName: customerName,
                    customerId: customerId,
                    salesOrderNumber: salesOrderNumber,
                    customerRemarks: customerRemarks,
                    payment_type: payment_type,
                });
            },
            (error) => {
                console.error('Error fetching product details:', error);
            }
        );
    }
    // this function is called and to push the value in the formArray
    setProduct(product: any[]): FormArray {
        const productFormArray: any = new FormArray([]);
        product.forEach((element) => {
            productFormArray.push(
                this._fb.group({
                    productName: element.product.productName,
                    quantity: element.quantity,
                    amount: element.amount,
                })
            );
        });
        return productFormArray;
    }



    exportToExcel(): void {
        const formValues = this.packageGroup.value;
        const productsData = formValues.products.map((product: any) => {
            return {
                productName: product.productName,
                quantity: product.quantity,
                amount: product.amount
            };
        });

        const productRows = productsData.map(product => {
            return `
                    Product Name: ${product.productName} <br>
                    Quantity: ${product.quantity} <br>
                    Amount: ${product.amount}
                `;
        }).join('<br><br>'); // Add additional line break between products


        // Custom Excel template with placeholders for form fields and products data
        const template = `
            <!DOCTYPE html>
            <html lang="en">
            <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <style>
                    body {
                        font-family: sans-serif;
                    }
            
                    .header {
                        background-color: #cfe2ff;
                        color: #ffffff;
                        padding: 10px;
                        text-align: center;
                        border-radius: 5px;
                    }
            
                    .section-header {
                        background-color: #f2f2f2;
                        font-weight: bold;
                        border-bottom: 2px solid #cfe2ff;
                    }
            
                    .table-container {
                        margin-top: 20px;
                    }
            
                    table {
                        width: 100%;
                        border-collapse: collapse;
                        border-radius: 5px;
                    }
            
                    th, td {
                        border: 1px dashed #cfe2ff;
                        padding: 8px;
                        text-align: left;
                    }
            
                    h1 {
                        text-align: center;
                    }
            
                    h2 {
                        text-align: center;
                    }
            
                    .highlight {
                        background-color: lightgreen;
                    }
            
                    .image {
                        width: 50px;
                        height: 50px;
                    }
            
                    .footnote {
                        font-size: 10px;
                    }
                </style>
            </head>
            <body>
                <div class="header">
                    <h1>${formValues.salesOrderNumber}</h1>
                </div>
                
                <table style="table-layout: fixed; width: 100%;">
                        <tbody>
                            <tr class="section-header">
                            <td> Date</td>

                                <td>Sales Order Number</td>
                                <td>Shipment Area</td>
                                <td>Address</td>

                                <td>Delivery Type</td>
                                <td>Customer Name</td>
                                <td>Payment Type</td>

                                <td>Package Order</td>
                                <td>Remarks</td>

                                <td>Products</td>

                            </tr>
                            <tr class="highlight">
                            <td>${formValues.packageDate}</td>

                                <td>${formValues.salesOrderNumber}</td>
                                <td>${formValues.shipmentArea}</td>
                                <td>${formValues.address}</td>

                                <td>${formValues.DeliveryType}</td>

                                <td>${formValues.customerName}</td>
                                <td>${formValues.payment_type}</td>

                                <td>${formValues.packageOrder}</td>
                                <td>${formValues.customerRemarks}</td>
                                <td>
                                ${productRows}

                                </td>
                            </tr>
                        </tbody>
                    </table>
                    
                
            
                    <sup class="footnote">by HUBA</sup>
                </div>
            </body>
            </html>
            
            `;

        const blob = new Blob([template], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = 'DeliverySheet.xlsx';
        a.click();
    }


    addProductValue(vendor: any) {
        if (!vendor) {
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer);
                    toast.addEventListener('mouseleave', Swal.resumeTimer);
                },
            });

            Toast.fire({
                icon: 'error',
                title: 'Error creating sale oders package',
                text: JSON.stringify(
                    'Sorry, Unable to Create SALE Oders Package'
                ),
            });
            // uncomment this line when the data of the sales oder  is available

            this._matDialog.close(true);
        }
        // here code for the sale oder product value patch on the product formArray

        // code need to be modified its not working looking on it

        // const productsArray = this.packageGroup.get('products') as FormArray;
        // productsArray.clear(); // Clear existing controls

        // for (const product of this.vendor.products) {
        //     productsArray.patchValue(this.productArray(product))
        // }
    }
    async save() {


        if (this.packageGroup.valid) {

            this.exportToExcel();

            // Show the loading indicator
            const packageData = { ...this.packageGroup.value };
            const packageDatetoformat =
                this.packageGroup.get('packageDate')?.value;
                
            packageData.packageDate = this.datePipe.transform(
                packageDatetoformat,
                'yyyy-MM-dd'
            );
            packageData.salesOrder = this.vendor.id;
            packageData.isPackaged = true; //set isPackage to true as it is confirmed
            packageData.isShipped = true; //set isPackage to true as it is confirmed

            packageData.infulencerOrder = ''; // while creating the salesOder package, influncer oder is empty

            // Update the contact on the server
            this._packageService
                .createPackageOrder(packageData)
                .then((response) => {
                    if (response.success) {
                        const Toast = Swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 5000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener(
                                    'mouseenter',
                                    Swal.stopTimer
                                );
                                toast.addEventListener(
                                    'mouseleave',
                                    Swal.resumeTimer
                                );
                            },
                        });

                        Toast.fire({
                            icon: 'success',
                            title: `${this.vendor.sales_order_num} Package Order created.`,
                        });
                    } else {
                        const errorMessages = response.errors;
                        // Handle API errors here
                        // Display error messages or perform necessary actions
                        // For example, you can show a toast message with the error details
                        const Toast = Swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener(
                                    'mouseenter',
                                    Swal.stopTimer
                                );
                                toast.addEventListener(
                                    'mouseleave',
                                    Swal.resumeTimer
                                );
                            },
                        });

                        Toast.fire({
                            icon: 'error',
                            title: 'Error creating Package Order',
                            text: JSON.stringify(errorMessages),
                        });
                    }
                })
                .catch((error) => {
                    // Hide the loading indicator
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener(
                                'mouseenter',
                                Swal.stopTimer
                            );
                            toast.addEventListener(
                                'mouseleave',
                                Swal.resumeTimer
                            );
                        },
                    });

                    Toast.fire({
                        icon: 'error',
                        title: `Error creating Package Order: ${error.message}`,
                    });
                });
            this._router.navigate(['apps/sales-logs']);
        } else {
            // Form is not valid, handle accordingly
            // For example, you can show a toast message or perform other actions
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer);
                    toast.addEventListener('mouseleave', Swal.resumeTimer);
                },
            });

            Toast.fire({
                icon: 'error',
                title: 'Invalid form data',
                text: 'Please fill in all the required fields correctly.',
            });
            this._router.navigate(['apps/sales-order']);


        }
        this._matDialog.close({ saved: true });

    }
    printSalesOrderDetailPage() {

    }

    async showConfirmationDialog(): Promise<boolean> {
        const result = await Swal.fire({
            title: 'Are you sure you want to package this salesOrder?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, continue!'
        });

        return result.isConfirmed;
    }

    async showOptionsDialog(): Promise<string> {
        const { value: choice } = await Swal.fire({
            title: 'Select an option',
            input: 'select',
            inputOptions: {
                option1: 'Print Invoice',
                option2: 'Download Delivery Sheet',
                option3: 'Both'
            },
            inputPlaceholder: 'Select an option',
            showCancelButton: true
        });
        return choice;
    }



    trackByFn(index: number, item: any): any {
        return item.id || index;
    }
}
