import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductGroupComponent } from './product-group/product-group.component';
import { ListproductGroupComponent } from './listproduct-group/listproduct-group.component';
import { CreateproductGroupComponent } from './createproduct-group/createproduct-group.component';
import { DetailproductGroupComponent } from './detailproduct-group/detailproduct-group.component';
import { MaterialModule } from 'app/modules/material/material.module';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { RouterModule } from '@angular/router';
import { ProductGroupRoutes } from './product.group.routing';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { NgxColorsModule } from 'ngx-colors';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
    declarations: [
        ProductGroupComponent,
        ListproductGroupComponent,
        CreateproductGroupComponent,
        DetailproductGroupComponent,
    ],
    imports: [
        RouterModule.forChild(ProductGroupRoutes),
        CommonModule,
        MaterialModule,
        MatAutocompleteModule,
        MatCardModule,
        MatButtonModule,
        NgxColorsModule,
        MatDialogModule,
    ],
})
export class ProductGroupModule {}
