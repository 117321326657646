import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FuseCardComponent } from '@fuse/components/card';
import { ConfirmPopupComponent } from 'app/confirm-popup/confirm-popup.component';
import { PackagesOderService } from '../packages.service';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';
import { PartialPayComponent } from 'app/sales-logs/partial-pay/partial-pay.component';

@Component({
    selector: 'app-delivarypopup',
    templateUrl: './delivarypopup.component.html',
    styleUrls: ['./delivarypopup.component.scss'],
})
export class DelivarypopupComponent implements OnInit {
    showMessage: boolean = false;
    formCancel: boolean = true;
    packageGroup: FormGroup;
    packageOrderCode: string;
    counter: number = 0;
    vendor: any;
    packageDetails: any;
    saleOderId: string;
    orderType: string;
    showDenyButton = true
    isCredited: boolean = false


    @ViewChild('fuseCard') fuseCard: FuseCardComponent;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _matDialog: MatDialogRef<ConfirmPopupComponent>,
        private dialog: MatDialog,

        private _fb: FormBuilder,
        private datePipe: DatePipe,
        private _packageService: PackagesOderService,
    ) {
        this.saleOderId = this.data.id;
        this.orderType = this.data.type
    }

    ngOnInit(): void {
        //recently added this

        this.fetchProductDetails(this.saleOderId, this.orderType);

        this.packageGroup = this._fb.group({
            shipOrder: new FormControl(''),
            packageOrder: new FormControl(''),
            packageDate: new FormControl(new Date(), [Validators.required]),
            products: this._fb.array([this.productArray()]),
        });
    }
    productArray() {
        return this._fb.group({
            productName: new FormControl(''),
            quantity: new FormControl(''),
            amount: new FormControl(''),
        });
    }

    fetchProductDetails(id: string, order_number): void {
        try {
            // Fetch the package details directly using the package ID
            this._packageService.getPackageOderByID(id).subscribe(
                (data) => {


                    this.packageGroup.get('packageOrder').patchValue(order_number);
                    this.packageGroup.updateValueAndValidity();


                    this.packageDetails = data;
                    this.vendor = data;

                    if (this.packageDetails.value < 0) {
                        this.isCredited = this.packageDetails.isCreditAndDelivered;
                    } else {
                        console.warn("The package value is not negative. No action taken.");
                    }
                },
                (error) => {
                    console.error('Error fetching package details:', error.message);
                }
            );
        } catch (error) {
            console.error(error.message);
        }
    }



    save = () => {
        if (this.packageGroup && this.packageGroup.valid) {
            if (this.vendor.payment_type && this.vendor.payment_type !== 'CASH ON DELIVERY') {
                this.paymentReceived()
            } else {
                if (this.packageDetails.isCreditAndDelivared) {
                    this.showDenyButton = false
                }
                Swal.fire({
                    title: 'Confirm Delivary Package ',
                    text: 'Is payment recieved ?',
                    icon: 'info',
                    showCancelButton: true,
                    showDenyButton: this.showDenyButton,
                    showConfirmButton: true,
                    confirmButtonText: 'Paid',
                    cancelButtonText: 'Cancel',
                    denyButtonText: 'Partial',
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.paymentReceived()
                    } else if (result.isDenied) {
                        this.paymentDueReasons()
                        // this.paymentDue()
                    } else {
                        this._matDialog.close({ save: true })
                    }
                })
            }
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: `Please fill all the details`,
                showConfirmButton: false, // No OK button
                backdrop: true, // Blurred background
                allowOutsideClick: false, // Prevents clicking outside to close the modal
                position: 'top-end', // Center the modal on the screen
                toast: true, // Display as a toast (centered)
                timer: 5000,
            })
        }
    }


    paymentReceived = () => {
        const packageData = { ...this.packageGroup.value };
        const packageDatetoformat = this.packageGroup.get('packageDate')?.value;
        packageData.packageDate = this.datePipe.transform(packageDatetoformat, 'yyyy-MM-dd');
        packageData.salesOrder = this.packageDetails.salesOrder;
        packageData.packageOrder = this.packageDetails.packageOrder;
        packageData.isPackaged = this.packageDetails.isPackaged;
        packageData.isShipped = this.packageDetails.isShipped;
        packageData.isCreditAndDelivared ? this.packageDetails?.isCreditAndDelivared : false
        packageData.isDelivared = true;
        packageData.paid = true;

        packageData.id = this.packageDetails.id;

        const response = this._packageService
            .updatePackageOrder(packageData.id, packageData)

        if (response) {
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: `${this.vendor.sales_order_num} order Delivered successfully `,
                showConfirmButton: false, // No OK button
                backdrop: true, // Blurred background
                allowOutsideClick: false, // Prevents clicking outside to close the modal
                position: 'top-end', // Center the modal on the screen
                toast: true, // Display as a toast (centered)
                timer: 5000, // Auto-close after 5 seconds (adjust as needed)
            });
            this._matDialog.close({ saved: true });

        }
    }



    paymentDueReasons = () => {
        const packageData = {
            ...this.packageGroup.value,
            packageDate: this.datePipe.transform(this.packageGroup.get('packageDate')?.value, 'yyyy-MM-dd'),
            salesOrder: this.packageDetails.salesOrder,
            packageOrder: this.packageDetails.packageOrder,
            isPackaged: this.packageDetails.isPackaged,
            isShipped: this.packageDetails.isShipped,
            isCreditAndDelivared: this.packageDetails?.isCreditAndDelivared || false,
            isDelivared: true,  // Corrected spelling to match other parts of the code
            partial_pay: true,
            id: this.packageDetails.id
        };

        const dialogRef = this.dialog.open(PartialPayComponent, {
            width: '900px',
            data: {
                packageData: packageData,
                order_number: this.orderType,
                total :this.vendor.total_remaining_total,
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                Swal.fire({
                    icon: 'success',
                    title: 'Partial Payment',
                    text: 'Partial payment has been successfully processed.',
                    showConfirmButton: true,
                }).then(() => {
                    this._matDialog.close({ saved: true });
                });
            }
        });
    }




    trackByFn(index: number, item: any): any {
        return item.id || index;
    }
}
