import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import Swal from 'sweetalert2';
import { ProductPopupComponent } from 'app/products/products-popup/product-popup.component';

import { map, startWith, take, tap } from 'rxjs/operators';
import { Observable, Subject, of } from 'rxjs';

import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';

import { productsGroupService } from '../product-group.service';
import { productVaraitonService } from 'app/productVariations/productVarations.type';
import { productVaraitons } from 'app/productVariations/productVariations.service';
import { HasUnsavedChanges } from 'app/page-detect/wm-can-leave/detect-changes.guard';

@Component({
    selector: 'app-createproduct-group',
    templateUrl: './createproduct-group.component.html',
    styleUrls: ['./createproduct-group.component.scss'],
})
export class CreateproductGroupComponent implements OnInit,HasUnsavedChanges {
    @ViewChild('fruitInput') fruitInput: ElementRef<HTMLInputElement>;
    selectedItems: string[] = [];
    availableItems: string[] = ['Option 1', 'Option 2', 'Option 3', 'Option 4'];
    drawerMode: 'over' | 'side' = 'side';
    drawerOpened: boolean = true;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    vendorsCredit: FormGroup;
    successMessage: string;
    errorMessage: string;
    exampleForm: FormGroup;
    // thumbnail Variables
    thumbnailFile: File | null;
    selectedProductThumbnail: string[] = [];
    selectedProductThumbnailClass: string;
    filteredProductOptions$: Observable<productVaraitons[]>;

    categoriesCTR = new FormControl('');
    filteredCat: Observable<string[]>;
    allproducts: string[] = [
        'FEMALE',
        'STREETWEAR',
        'BAD AND BOUJEE',
        '90zz',
        'RETRO',
    ];
    prodcutsCtr: string[] = ['MALE'];
    genderOptions: { label: string; value: string }[] = [];
    vendorControl: FormControl = new FormControl();

    cardValue: any;
    thumbnailURL: string;
    imageUrls: string[] = [];

    constructor(
        private _formBuilder: FormBuilder,

        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _productService: productVaraitonService,
        private dialog: MatDialog,
        private productService: productsGroupService
    ) {
        this.filteredCat = this.categoriesCTR.valueChanges.pipe(
            startWith(null),
            map((fruit: string | null) =>
                fruit ? this._filter(fruit) : this.allproducts.slice()
            )
        );

        const products$: Observable<productVaraitons[]> = this._productService
            .getproductVaraiton()
            .pipe(
                take(1),
                map((response) => response)
            );
        products$.subscribe((products) => {
            console.log(products['data'], 'Products');
        });
    }

    ngOnInit() {
        // Get the vendors

        this.vendorsCredit = this._formBuilder.group({
            groups_name: [''],
            groups_sku: [''],
            groups_stock: [''],
            groups_gender: [''],
            groups_description: [''],
            products: this._formBuilder.array([]),
            sub_total: [0], // Initialize with a default value
            total: [0], // Initialize with a default value
            adjustment: [0],
            discount: [0],
            discountSuffix: ['%'],
            adjustmentSuffix: ['%'],

        });

        (this.vendorsCredit.get('products') as FormArray).clear();

        // Define the initial number of products
        // Define the initial number of products
        const initialProductCount = 1;

        const subscribeToValueChanges = (productGroup: FormGroup) => {
            const rateControl = productGroup.get('rate');
            const quantityControl = productGroup.get('quantity');


            rateControl.valueChanges.subscribe(() => {
                this.calculateTotal();
            });

            quantityControl.valueChanges.subscribe(() => {
                this.calculateTotal();
            });


        };

        // Loop to create the product form controls
        // Loop to create the product form controls
        for (let i = 0; i < initialProductCount; i++) {
            const productGroup = this._formBuilder.group({
                product: ['', [Validators.required]],
                quantity: [''],
                rate: [''],
                size: [''],
                color: [''],
                amount: [''],
            });

            subscribeToValueChanges(productGroup);

            (this.vendorsCredit.get('products') as FormArray).push(
                productGroup
            );
        }
        this.vendorsCredit.get('discount').valueChanges.subscribe(() => {
            this.calculateTotal();
        });
        this.vendorsCredit.get('discountSuffix').valueChanges.subscribe(() => {
            this.calculateTotal();
        });



        this.vendorsCredit.get('adjustment').valueChanges.subscribe(() => {
            this.calculateTotal();
        });
        this.vendorsCredit
            .get('adjustmentSuffix')
            .valueChanges.subscribe(() => {
                this.calculateTotal();
            });
    }


    addProductField(): void {
        const productFormGroup = this._formBuilder.group({
            product: ['',],
            size: ['',],
            rate: [''],
            quantity: [''],
            color: ['rgb(79, 195, 255)',],
            amount: [''],
        });

        const productArray = this.vendorsCredit.get('products') as FormArray;
        productArray.push(productFormGroup);

        const index = productArray.length - 1;
        this.setupProductFieldSubscriptions(productFormGroup, index);

        this.calculateTotal();
    }


    filterOptionsProduct(value: string): void {
        if (typeof value !== 'string') {
            this.filteredProductOptions$ = of([]);
            return;
        }
        const baseUrl = 'http://103.147.227.9:2/8081';
        this.filteredProductOptions$ = this._productService.getproductVaraiton().pipe(
            take(1),
            map((response) => response),
            tap((products) => console.log(products)), // Log the products array before filtering
            map((products) => {
                if (Array.isArray(products['data'])) {
                    const filteredProducts = products['data']
                        .filter(
                            (product) =>
                                product.productName
                                    ?.toLowerCase()
                                    .includes(value.toLowerCase()) ||
                                (product.sku &&
                                    product.size
                                        .toString()
                                        .toLowerCase()
                                        .includes(value.toLowerCase()))
                        )
                        .map((product) => {
                            return {
                                product: product.id,
                                productName: product.productName,
                                sku: product.sku,
                                size: product.size,
                                color: product.color,
                                basePrice: product.basePrice,
                                thumbnail: baseUrl + product.base_product.thumbnail,

                            };
                        });
                    // Log the filtered products array
                    return filteredProducts;

                } else {

                    return [];
                }
            })
        );
    }

    selectOptionProduct(option: productVaraitons, index: number): void {
        const productFormGroup = this._formBuilder.group({
            product: [option],
            size: [option.size,],

            color: [option.color],
            rate: [option.basePrice],
            quantity: [''],
            amount: [''],
        });

        const productArray = this.vendorsCredit.get('products') as FormArray;
        const existingFormGroup = productArray.at(index) as FormGroup;

        if (existingFormGroup) {
            existingFormGroup.patchValue(productFormGroup.value);
            this.selectedProductThumbnail[index] = option.thumbnail;
            this.selectedProductThumbnailClass = 'example-option-img';
        }
    }
    displayProductOption(product: productVaraitons): string {
        if (product) {
            const productName = product.productName || '';
            const productPrice = product.sku
                ? ' - ' + product.sku.toString()
                : '' + product.productName.toString();

            return productName + productPrice;
        }
        return '';
    }

    removeProductField(index: number): void {
        const productArray = this.vendorsCredit.get('products') as FormArray;
        productArray.removeAt(index);

        this.calculateTotal();
    }

    add(event: MatChipInputEvent): void {
        const value = (event.value || '').trim();

        // Add our fruit
        if (value) {
            this.prodcutsCtr.push(value);
        }

        // Clear the input value
        event.chipInput!.clear();

        this.categoriesCTR.setValue(null);
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        this.prodcutsCtr.push(event.option.viewValue);
        this.fruitInput.nativeElement.value = '';
        this.categoriesCTR.setValue(null);
    }

    private _filter(value: string): string[] {
        const filterValue = value.toLowerCase();

        return this.allproducts.filter((fruit) =>
            fruit.toLowerCase().includes(filterValue)
        );
    }

    selectChange = (event: any) => {
        const key: string = event.key;
        this.cardValue[key] = [...event.data];
    };

    openProductDetails(): void {
        const dialogRef = this.dialog.open(ProductPopupComponent, {
            width: '500px', // Adjust the width as per your requirements
            // Pass the selected vendor as data to the modal
        });

        dialogRef.afterClosed().subscribe((result) => {
            // Handle any actions after the modal is closed
        });
    }



    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    setupProductFieldSubscriptions(
        productGroup: FormGroup,
        index: number
    ): void {
        const quantityControl = productGroup.get('quantity');
        const rateControl = productGroup.get('rate');


        quantityControl.valueChanges.subscribe(() => {
            this.calculateTotal();
        });

        rateControl.valueChanges.subscribe(() => {
            this.calculateTotal();
        });


    }

    calculateTotal(): void {
        const productArray = this.vendorsCredit.get('products') as FormArray;

        let subTotal = 0;
        productArray.controls.forEach((control: FormGroup) => {
            const quantity = 1
            const rate = control.get('rate').value;


            const amount = (quantity || 0) * (rate || 0);
            control.get('amount').setValue(amount);

            subTotal += amount || 0;
        });


        this.vendorsCredit.get('sub_total').setValue(subTotal);
        const discountSuffix = this.vendorsCredit.get('discountSuffix').value;
        const adjustmentSuffix =
            this.vendorsCredit.get('adjustmentSuffix').value;
        const discount = this.vendorsCredit.get('discount').value || 0;

        const adjustment = this.vendorsCredit.get('adjustment').value || 0;
        let calculatedDiscount = discount;
        let calculatedAdjustment = adjustment;

        if (discountSuffix === '%') {
            calculatedDiscount = (subTotal * discount) / 100;
        }

        if (adjustmentSuffix === '%') {
            calculatedAdjustment = (subTotal * adjustment) / 100;
        }

        if (adjustmentSuffix == '%' && discountSuffix == '%') {
            calculatedDiscount = (subTotal * discount) / 100;
            const newTotal = subTotal - calculatedDiscount;

            calculatedAdjustment = (newTotal * adjustment) / 100;
        }

        const total = subTotal - calculatedDiscount + calculatedAdjustment;
        this.vendorsCredit.get('total').setValue(total);


    }


    sumbitsizeInfo() {

        if (this.vendorsCredit.valid) {
            const formData = new FormData();

            if (this.thumbnailFile instanceof File) {
                formData.append(
                    'thumbnail',
                    this.thumbnailFile,
                    this.thumbnailFile.name
                );
            }

            for (const key of Object.keys(this.vendorsCredit.value)) {
                const formValue = this.vendorsCredit.value[key];

                if (Array.isArray(formValue)) {
                    for (const file of formValue) {
                        if (file instanceof File) {
                            formData.append(key, file, file.name);
                        }
                    }
                } else if (formValue instanceof File) {
                    formData.append(key, formValue, formValue.name);
                } else {
                    formData.append(key, formValue);
                }
            }
            const customerData = { ...this.vendorsCredit.value };
            const products = this.vendorsCredit.get('products') as FormArray;
            const updatedProducts = products.value.map((item: any) => ({
                product: item.product.product,
                quantity: item.quantity,
                size: item.size,
                color: item.color,
                rate: item.rate,
                amount: item.amount

            }));

            customerData.products = updatedProducts;
            this.productService
                .createproductss(customerData)
                .then((response) => {
                    if (response.success) {
                        const Toast = Swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener(
                                    'mouseenter',
                                    Swal.stopTimer
                                );
                                toast.addEventListener(
                                    'mouseleave',
                                    Swal.resumeTimer
                                );
                            },
                        });

                        Toast.fire({
                            icon: 'success',
                            title: `Product Bundle  created!!`,
                        });

                        this.vendorsCredit.reset();
                        this._router.navigate(['../'], {
                            relativeTo: this._activatedRoute,
                        });
                        console.log(response, 'response');
                    } else {
                        const errorMessages = response.errors;
                        // Handle API errors here
                        // Display error messages or perform necessary actions
                        // For example, you can show a toast message with the error details
                        const Toast = Swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener(
                                    'mouseenter',
                                    Swal.stopTimer
                                );
                                toast.addEventListener(
                                    'mouseleave',
                                    Swal.resumeTimer
                                );
                            },
                        });

                        Toast.fire({
                            icon: 'error',
                            title: 'Error',
                            text: JSON.stringify(errorMessages),
                        });
                    }
                })
                .catch((error) => {
                    // Hide the loading indicator
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener(
                                'mouseenter',
                                Swal.stopTimer
                            );
                            toast.addEventListener(
                                'mouseleave',
                                Swal.resumeTimer
                            );
                        },
                    });

                    Toast.fire({
                        icon: 'error',
                        title: `Error creating Product: ${error.message}`,
                    });
                });
        } else {
            // Form is not valid, handle accordingly
            // For example, you can show a toast message or perform other actions
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer);
                    toast.addEventListener('mouseleave', Swal.resumeTimer);
                },
            });

            Toast.fire({
                icon: 'error',
                title: 'Invalid form data',
                text: 'Please fill in all the required fields correctly.',
            });
        }
    }

    hasUnsavedChanges(): boolean {
        return this.vendorsCredit.dirty;
      }
    
      canDeactivate(): boolean {
        return !this.vendorsCredit.dirty || confirm('You have unsaved changes. Are you sure you want to leave?');
      }
    
}
