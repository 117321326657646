import {
    Component,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import {
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';

import { Address } from 'ngx-google-places-autocomplete/objects/address';

import { ActivatedRoute, Router } from '@angular/router';

import { Observable, Subject, of } from 'rxjs';

import { ContactsService } from 'app/modules/admin/apps/contacts/contacts.service';

import { DatePipe } from '@angular/common';
import {
    debounceTime,
    map,
    startWith,
    switchMap,
    take,
    
} from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';

import Swal from 'sweetalert2';
import { Contact } from 'app/modules/admin/apps/contacts/contacts.types';
import { PrPackagesCreditNotesPopupComponent } from '../pr-packages-credit-notes-popup/pr-packages-credit-notes-popup.component';
import { PrPackagesProductCreditNotesPopupComponent } from '../pr-packages-product-credit-notes-popup/pr-packages-product-credit-notes-popup.component';



import { PrPackagesCreditNotesService } from '../pr-packages-credit-notes.service';

export interface State {
    flag: string;
    name: string;
    population: string;
}
export interface CustomerOption {
    id: string;
    firstname?: string;
    avatar?: string;
}

export interface ProductOption {
    name?: string;
    stock?: number;
    reserved?: number;
    cost?: number;
    basePrice?: number;
    taxPercent?: number;
    price?: number;

    images?: string[];
}

@Component({
 
selector: 'app-createpr-packages-credit-notes',
templateUrl: './createpr-packages-credit-notes.component.html',
styleUrls: ['./createpr-packages-credit-notes.component.scss']
})
export class CreateprPackagesCreditNotesComponent implements OnInit, OnDestroy {
    districtControl = new FormControl();
    filteredDistricts: Observable<string[]>;
    districts: string[] = [];

    ngControl: any;

    @Input() set disableControl(condition: boolean) {
        const action = condition ? 'disable' : 'enable';
        this.ngControl.control[action]();
    }
    customerId: number | undefined; // Property to store the selected customer ID

    productIds: number[] = []; // Property to store the selected product IDs
    stateCtrl = new FormControl('');
    formFieldHelpers: string[] = [''];
    drawerMode: 'over' | 'side' = 'side';
    drawerOpened: boolean = true;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    vendorsCredit: FormGroup;
    vendorsCount: number;
    selectedVendor: Contact;
    customers$: Observable<Contact[]>;
    CustomerControl: FormControl = new FormControl();
    // Declare an Observable to hold
    filteredCustomers$: Observable<Contact[]>;

    successMessage: string;
    errorMessage: string;


    //Thumbnalil Variables
    selectedProductThumbnail: string[] = [];
    selectedProductThumbnailClass: string;


    showAddressFields: boolean = false;
    showDeliveryType: boolean = false;
    showSocailLink: boolean = false;
    showEmail: boolean = false;
    showPaymentMethod: boolean = false;
    showShippingArea: boolean = false;
    showAddress: boolean = false;
    showShippinginvalley: boolean = false;
    showShippingOutvalley: boolean = false;
    showCountry: boolean = false;
    shwoShippingInternational: boolean = false;
    showDistrict: boolean = false;
    formattedaddress: string = '';

    options: any = {
        componentRestrictions: { country: 'IN' },
    };

    constructor(
        private _formBuilder: FormBuilder,
        private _activatedRoute: ActivatedRoute,
        private _router: Router,

        private dialog: MatDialog,
        private _customerService: ContactsService,
        private _prPackagesCreditNotesService: PrPackagesCreditNotesService,
        private datePipe: DatePipe
    ) {
        this.customers$ = this._customerService.getContacts().pipe(take(1));

        this.customers$.subscribe((customers) => {
            const data = customers['data'];
        });
      
    }

    ngOnInit() {
        this.fetchData();

        this.filteredDistricts = this.districtControl.valueChanges.pipe(
            startWith(''),
            map((value) => this._filterDistricts(value))
        );

        this.filteredCustomers$ = this.CustomerControl.valueChanges.pipe(
            startWith(''),
            debounceTime(300),
            switchMap((value) => this.filterOptions(value))
        );

        this.vendorsCredit = this._formBuilder.group({
            avatar: [null],

            Customers: this.CustomerControl,
            sales_order_num: ['SO-001-A', Validators.required],
            order_number: [''],
            slaes_order_date: ['', Validators.required],
            sales_order_shippmentDate: ['', Validators.required],
            pos: [''],
            email: [''],
            soical_link: [''],
            delivery_type: [''],
            shipping_area: [''],
            payment_type: [''],
            payment_terms: [''],

            district: this.districtControl,
            country: [''],
            shipping_carrier_invalley: [''],
            shipping_carrier_outvalley: [''],
            shipping_carrier_int: [''],

            items_rate: [''],
            gender: [''],
            company: [''],
            products: this._formBuilder.array([]),

            address: [''],

            notes: [null],
            socialMedia: [null],
            sub_total: [0], // Initialize with a default value
            total: [0], // Initialize with a default value
            adjustment: [0],
            discount: [0],
            shipping_charges: [0],
            refrences: [''],
            sales_person: [''],

            tags: [''],
        });

        const salesOrderDateControl = new FormControl(new Date());

        // Get the current date
        const currentDate = new Date();

        // Set the default value of the sales order date to today's date
        salesOrderDateControl.setValue(currentDate);

        // Convert the default date value to a formatted string
        const datePipe = new DatePipe('en-US');
        const defaultDateValue = datePipe.transform(currentDate, 'yyyy-MM-dd');

        // Update the form control name and value in the form group
        this.vendorsCredit.addControl(
            'slaes_order_date',
            salesOrderDateControl
        );
        this.vendorsCredit.get('slaes_order_date').setValue(defaultDateValue);

        // Add one day to the current date
        const tomorrow = new Date(currentDate.getTime() + 24 * 60 * 60 * 1000);

        // Convert the tomorrow date value to a formatted string
        const tomorrowFormatted = datePipe.transform(tomorrow, 'yyyy-MM-dd');

        // Update the form control name and value in the form group
        this.vendorsCredit.addControl(
            'sales_order_shippmentDate',
            new FormControl(tomorrowFormatted, Validators.required)
        );
        this.vendorsCredit
            .get('sales_order_shippmentDate')
            .setValue(tomorrowFormatted);
        // Clear the products FormArray
        (this.vendorsCredit.get('products') as FormArray).clear();

        // Define the initial number of products
        const initialProductCount = 1;

        // Create a function to handle value changes for a specific product field
        // Create a function to handle value changes for a specific product field
        const subscribeToValueChanges = (productGroup: FormGroup) => {
            const rateControl = productGroup.get('rate');
            const quantityControl = productGroup.get('quantity');
            const taxControl = productGroup.get('tax');

            rateControl.valueChanges.subscribe(() => {
                this.calculateTotal();
            });

            quantityControl.valueChanges.subscribe(() => {
                this.calculateTotal();
            });

            taxControl.valueChanges.subscribe(() => {
                this.calculateTotal();
            });
        };

        // Loop to create the product form controls
        // Loop to create the product form controls
        for (let i = 0; i < initialProductCount; i++) {
            const productGroup = this._formBuilder.group({
                product: ['', [Validators.required]],
                quantity: [''],
                rate: [''],
                tax: [''],
                amount: [''],
            });

            subscribeToValueChanges(productGroup);

            (this.vendorsCredit.get('products') as FormArray).push(
                productGroup
            );
        }
        this.vendorsCredit.get('discount').valueChanges.subscribe(() => {
            this.calculateTotal();
        });

        this.vendorsCredit
            .get('shipping_charges')
            .valueChanges.subscribe(() => {
                this.calculateTotal();
            });
        this.vendorsCredit.get('adjustment').valueChanges.subscribe(() => {
            this.calculateTotal();
        });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    handleAddressChange(address: Address) {
        console.log(address.formatted_address);
        console.log(address.geometry.location.lat());
        console.log(address.geometry.location.lng());
    }
    createBillingAddressGroup(): FormGroup {
        return this._formBuilder.group({
            type: [''],
            address: [''],
            nearest_landmark: [''],
            city: [''],
            country: [''],
            phone: [''],
            fax: [''],
            state: [''],
            district: [''],
            zip_code: [''],
            shipping_number: [''],
        });
    }

    openVendorDetails(): void {
        const dialogRef = this.dialog.open(PrPackagesCreditNotesPopupComponent, {
            width: '500px', // Adjust the width as per your requirements
            // Pass the selected vendor as data to the modal
        });

        dialogRef.componentInstance.formSubmitted.subscribe((formData) => {
            // Call your service method here
            this._customerService
                .createCustomer(formData)
                .then((response) => {
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener(
                                'mouseenter',
                                Swal.stopTimer
                            );
                            toast.addEventListener(
                                'mouseleave',
                                Swal.resumeTimer
                            );
                        },
                    });

                    Toast.fire({
                        icon: 'success',
                        title: ` ${formData.first_name} Customer  created!! `,
                    });
                    // Handle the response from the service if needed
                })
                .catch((error) => {
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener(
                                'mouseenter',
                                Swal.stopTimer
                            );
                            toast.addEventListener(
                                'mouseleave',
                                Swal.resumeTimer
                            );
                        },
                    });

                    Toast.fire({
                        icon: 'error',
                        title: `'Error creating Customer: ' ${error.message};`,
                    });
                });
        });
    }

    openProductDetails(): void {
        const dialogRef = this.dialog.open(PrPackagesProductCreditNotesPopupComponent, {
            width: '500px', // Adjust the width as per your requirements
            // Pass the selected vendor as data to the modal
        });

        dialogRef.afterClosed().subscribe((result) => {
            // Handle any actions after the modal is closed
        });
    }
    submitVendorsInfo() {
        if (this.vendorsCredit.valid) {
            // Show the loading indicator
            const customerData = { ...this.vendorsCredit.value };
            const customer = this.vendorsCredit.get('Customers')?.value;
            customerData.Customers = customer ? customer.id : null;

            const products = this.vendorsCredit.get('products') as FormArray;
            const updatedProducts = products.value.map((item: any) => ({
                product: item.product.id,
                // Copy other fields from the original item
                quantity: item.quantity,
                rate: item.rate,
                tax: item.tax,
                amount: item.amount,
            }));

            customerData.products = updatedProducts;

            // Update the contact on the server
            this._prPackagesCreditNotesService
                .createPrPackages(customerData)
                .then((response) => {
                    if (response.success) {
                        const Toast = Swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener(
                                    'mouseenter',
                                    Swal.stopTimer
                                );
                                toast.addEventListener(
                                    'mouseleave',
                                    Swal.resumeTimer
                                );
                            },
                        });

                        Toast.fire({
                            icon: 'success',
                            title: `${customerData.sales_order_date} Sales Order created!!`,
                        });

                        this.vendorsCredit.reset();
                        this._router.navigate(['../'], {
                            relativeTo: this._activatedRoute,
                        });
                    } else {
                        const errorMessages = response.errors;
                        // Handle API errors here
                        // Display error messages or perform necessary actions
                        // For example, you can show a toast message with the error details
                        const Toast = Swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener(
                                    'mouseenter',
                                    Swal.stopTimer
                                );
                                toast.addEventListener(
                                    'mouseleave',
                                    Swal.resumeTimer
                                );
                            },
                        });

                        Toast.fire({
                            icon: 'error',
                            title: 'Error creating Vendors Credit',
                            text: JSON.stringify(errorMessages),
                        });
                    }
                })
                .catch((error) => {
                    // Hide the loading indicator
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener(
                                'mouseenter',
                                Swal.stopTimer
                            );
                            toast.addEventListener(
                                'mouseleave',
                                Swal.resumeTimer
                            );
                        },
                    });

                    Toast.fire({
                        icon: 'error',
                        title: `Error creating Vendors Credit: ${error.message}`,
                    });
                });
        } else {
            // Form is not valid, handle accordingly
            // For example, you can show a toast message or perform other actions
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer);
                    toast.addEventListener('mouseleave', Swal.resumeTimer);
                },
            });

            Toast.fire({
                icon: 'error',
                title: 'Invalid form data',
                text: 'Please fill in all the required fields correctly.',
            });
        }
    }

    toggleDropdown() {
        const dropdownMenu = document.getElementById('dropdown-menu');
        if (dropdownMenu) {
            dropdownMenu.classList.toggle('show');
        }
    }

    saveAsDraft() {
        // Handle save as draft functionality
    }

    saveAndPrint() {
        // Handle save and print functionality
    }

    getVendorPlaceholder(): string {
        return this.vendorsCount > 0
            ? 'Select a Customer'
            : 'No Customer available';
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    filterOptions(value: string): Observable<Contact[]> {
        if (typeof value !== 'string') {
            return of([]); // Return an empty array if value is not a string
        }

        const filterValue = value.toLowerCase();
        return this.customers$.pipe(
            map((customers) => {
                if (Array.isArray(customers['data'])) {
                    return customers['data'].filter(
                        (customer) =>
                            customer.firstName &&
                            customer.firstName
                                .toLowerCase()
                                .includes(filterValue)
                    );
                } else {
                    console.error(
                        'Customers data is not available:',
                        customers
                    );
                    return [];
                }
            })
        );
    }

   

    displayOption(customer: Contact): string {
        const last_name = customer?.lastName || '';
        const first_name = customer?.firstName || '';

        return first_name && last_name ? first_name + ' ' + last_name : '';
    }

    selectOption(option: Contact) {
        if (option) {
            this.CustomerControl.setValue(option);
            this.CustomerControl.updateValueAndValidity();
        }
    }

    // Map the customer object to CustomerOption
    genderOptions(customer: Contact): CustomerOption[] {
        if (customer) {
            const mappedCustomer: CustomerOption = {
                id: customer.id,
                firstname: customer.firstName || '',
            };
            console.log('Mapped Customer:', mappedCustomer);
            return [mappedCustomer];
        }
        return [];
    }

    addProductField(): void {
        const productFormGroup = this._formBuilder.group({
            product: ['', [Validators.required]],
            quantity: ['', Validators.required],
            rate: ['', Validators.required],
            tax: [''],
            amount: [{ value: '', disabled: true }],
        });

        const productArray = this.vendorsCredit.get('products') as FormArray;
        productArray.push(productFormGroup);

        const index = productArray.length - 1;
        this.setupProductFieldSubscriptions(productFormGroup, index);

        this.calculateTotal();
    }

    removeProductField(index: number): void {
        const productArray = this.vendorsCredit.get('products') as FormArray;
        productArray.removeAt(index);

        this.calculateTotal();
    }

    setupProductFieldSubscriptions(
        productGroup: FormGroup,
        index: number
    ): void {
        const quantityControl = productGroup.get('quantity');
        const rateControl = productGroup.get('rate');
        const taxControl = productGroup.get('tax');

        quantityControl.valueChanges.subscribe(() => {
            this.calculateTotal();
        });

        rateControl.valueChanges.subscribe(() => {
            this.calculateTotal();
        });

        taxControl.valueChanges.subscribe(() => {
            this.calculateTotal();
        });
    }

    calculateTotal(): void {
        const productArray = this.vendorsCredit.get('products') as FormArray;

        let subTotal = 0;
        productArray.controls.forEach((control: FormGroup) => {
            const quantity = control.get('quantity').value;
            const rate = control.get('rate').value;
            const tax = control.get('tax').value;

            const amount = (quantity || 0) * (rate || 0) + (tax || 0);
            control.get('amount').setValue(amount);

            subTotal += amount || 0;
        });

        this.vendorsCredit.get('sub_total').setValue(subTotal);

        const discount = this.vendorsCredit.get('discount').value || 0;
        const shippingCharges =
            this.vendorsCredit.get('shipping_charges').value || 0;
        const adjustment = this.vendorsCredit.get('adjustment').value || 0;

        const total =
            subTotal -
            (subTotal * discount) / 100 +
            (subTotal * shippingCharges) / 100 +
            (subTotal * adjustment) / 100;
        this.vendorsCredit.get('total').setValue(total);
    }

 

    getFormFieldHelpersAsString(): string {
        return this.formFieldHelpers.join(' ');
    }

    pointOfsales(): void {
        const type = this.vendorsCredit.controls['pos'].value;
        console.log(type, 'check if data is coming');

        if (type == 'Instagram' || type == 'Website' || type == 'Facebook') {
            this.showSocailLink = false;
            this.showEmail = true;
            this.showPaymentMethod = true;
            this.showShippingArea = true;
        } else if (type == 'Store Vist') {
            this.showEmail = false;
            this.showSocailLink = true;
            this.showPaymentMethod = true;
            this.showShippingArea = false;
        } else {
            this.showEmail = true;
            this.showSocailLink = true;
            this.showPaymentMethod = false;
            this.showShippingArea = false;
        }
    }

    addressField(): void {
        const type = this.vendorsCredit.controls['shipping_area'].value;
        console.log(type, 'addressField');
        if (type == 'Inside Valley') {
            this.showAddress = true;
            this.showShippinginvalley = true;
            this.showDistrict = true;
            this.showShippingOutvalley = false;
            this.showCountry = false;
            this.shwoShippingInternational = false;
        } else if (type == 'Outside Valley') {
            this.showAddress = true;
            this.showShippinginvalley = false;
            this.showDistrict = true;
            this.showShippingOutvalley = true;
            this.showCountry = false;
            this.shwoShippingInternational = false;
        } else if (type == 'International') {
            this.showAddress = true;
            this.showShippinginvalley = false;
            this.showDistrict = false;
            this.showShippingOutvalley = false;
            this.showCountry = true;
            this.shwoShippingInternational = true;
        }
    }

    formatDate(date: Date, format: string, controlName: string) {
        const formattedDate = this.datePipe.transform(date, format);
        this.vendorsCredit.get(controlName)?.setValue(formattedDate);
    }

    async fetchData() {
        try {
            const districtNames = await this._prPackagesCreditNotesService.fetchData();
            this.districts = districtNames.sort();
        } catch (error) {
            console.error(error);
        }
    }

    private _filterDistricts(value: string): string[] {
        const filterValue = value.toLowerCase();
        return this.districts.filter((district) =>
            district.toLowerCase().includes(filterValue)
        );
    }
}


