import { ChangeDetectorRef, Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FuseCardComponent } from '@fuse/components/card';
import { ConfirmPopupComponent } from 'app/confirm-popup/confirm-popup.component';



@Component({
  selector: 'app-sales-order-popup',
  templateUrl: './sales-order-popup.component.html',
  styleUrls: ['./sales-order-popup.component.scss']
})
export class SalesOrderPopupComponent implements OnInit {
  @Output() formSubmitted: EventEmitter<any> = new EventEmitter<any>();

  customer_popup: FormGroup;
  formFieldHelpers: string[] = [''];

  @ViewChild('fuseCard') fuseCard: FuseCardComponent;

  constructor(
    private _matDialog: MatDialogRef<ConfirmPopupComponent>,
    private _formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _changeDetectorRef : ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.customer_popup = this._formBuilder.group({
      firstName: ['',[Validators.pattern('^[A-Z][^0-9]*[a-z]$'),Validators.minLength(3),Validators.required]],
      lastName: ['',[Validators.minLength(3),Validators.pattern('^[A-Z][^0-9]*[a-z]$'),Validators.required]],
      gender: ['',Validators.required],
      emails: [''],
      phoneNumbers:  this._formBuilder.array([]),
    });


    const initialProductCount = 1;


    // Loop to create the product form controls
    for (let i = 0; i < initialProductCount; i++) {
      const phoneFormGroup = this._formBuilder.group({
        number: ['', [Validators.required]],
        label: ['']
      });


      (this.customer_popup.get('phoneNumbers') as FormArray).push(
        phoneFormGroup
      );





      // Mark for check
      this._changeDetectorRef.markForCheck();
    }

  } 

  getFormFieldHelpersAsString(): string {
    return this.formFieldHelpers.join(' ');
}

  submitForm(formData: any): void {
    if (this.customer_popup.valid) {
      // Form is valid, perform required form processing
      formData.emails = [{
        email: formData.emails,
        label: "",
      }];
      
    

      // Emit the form data
      this.formSubmitted.emit(formData);
      
      // Close the modal
      this._matDialog.close();
    } else {
      console.error("Form is not valid. Please check the input fields.");
    }
  }

   /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
    trackByFn(index: number, item: any): any {
      return item.id || index;
    }
  
    // Helper methods to add and remove controls dynamically
  
    addPhoneNumberField(): void {
      // Create an empty email form group
      const phoneFormArray = this._formBuilder.group({
        number: ['', [Validators.required]],
        label: ['']
      });
  
      // Add the email form group to the emails form array
      (this.customer_popup.get('phoneNumbers') as FormArray).push(phoneFormArray);
  
      // Mark for check
      this._changeDetectorRef.markForCheck();
    }
  
    removePhoneField(index: number): void {
      // Get form array for emails
      const phoneFormArray = this.customer_popup.get('phoneNumbers') as FormArray;
  
      // Remove the email field
      phoneFormArray.removeAt(index);
  
      // Mark for check
      this._changeDetectorRef.markForCheck();
    }
  
    // Emit the form data
    

  cancel(): void {
    this._matDialog.close();
  }
}
