import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { productMaster } from '../prize.type';
import { ColorService } from 'app/colors/color.service';
import { SizeService } from 'app/size/size.service';
import { productsGroupService } from 'app/product-group/product-group.service';
import { productCategoryService } from 'app/product-categories/product-categories.service';
import { FabricService } from 'app/product-fabric/fabric-service.service';




@Component({
    selector: 'app-prize-list',
    templateUrl: './prize-list.component.html',
    styleUrls: ['./prize-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,

})
export class PrizeListComponent implements OnInit {
    displayedColumns: string[] = ['id', 'name', 'date', 'sku', 'created_by', 'actions'];

    @ViewChild('matSelectTrigger') matSelectTrigger: ElementRef;

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    productMaster: productMaster[];
    dataSource_category: MatTableDataSource<any>;
    dataSource_collections: MatTableDataSource<any>;
    dataSource_size: MatTableDataSource<any>;
    dataSource_color: MatTableDataSource<any>;
    dataSource_fabrics: MatTableDataSource<any>;

    selectedTable: string = 'Collections';
    category$: Observable<any[]>;
    categorys: any;
    color$: Observable<any[]>;
    colors: any;
    size$: Observable<any[]>;
    size: any;
    collections$: Observable<any[]>;
    collections: any;
    fabrics$: Observable<any[]>;
    fabrics: any;




    flashMessage: 'success' | 'error' | null = null;
    isLoading: boolean = true;
    currentDataSource: MatTableDataSource<any>;



    constructor(

        private _router: Router,
        private cdr: ChangeDetectorRef,
        private _colorService: ColorService,
        private _sizeService: SizeService,
        private _collectionsService: productsGroupService,
        private _categoryService: productCategoryService,
        private _fabricService: FabricService
    ) {


    }

    ngOnInit(): void {

        this.loadTabels();



    }

    ngAfterViewInit() {


    }


    getCellStyle(column: string): { [key: string]: string } {
        if (column === 'id') {
            return { 'font-weight': 'bold' };
        } else if (column === 'date') {
            return { 'font-style': 'italic' };
        } else {
            return {}; // No additional styles for other columns
        }
    }



    refreshProductList() {

    }

    toggleEditMode(arg0: boolean) {
        throw new Error('Method not implemented.');
    }

    showDetails(id: any) {
        this._router.navigate(['apps/product-bundle', id]);
    }


    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.currentDataSource.filter = filterValue.trim().toLowerCase();

        if (this.currentDataSource.paginator) {
            this.currentDataSource.paginator.firstPage();
        }
    }
    createProduct() {
        this._router.navigate(['apps/prizeList', 'NEW']);



    }
    initializeTable() {
        this.currentDataSource = new MatTableDataSource<any>();
        this.currentDataSource.paginator = this.paginator;
        this.currentDataSource.sort = this.sort;
        this.displayedColumns = ['id', 'name', 'date', 'sku', 'created_by', 'actions'];
    }


    loadTabels() {
        this.isLoading = true;

        switch (this.selectedTable) {
            case 'Collections':
                this.loadCollections();
                this.currentDataSource = this.dataSource_collections;
                break;
            case 'Size':
                this.fetchSizes();
                this.currentDataSource = this.dataSource_size;
                break;
            case 'Color':
                this.loadColors();
                this.currentDataSource = this.dataSource_color;
                break;
            case 'Category':
                this.loadCategory();
                this.currentDataSource = this.dataSource_category;
                break;
            case 'Fabrics':
                this.loadFabric();
                break;
            default:
                console.error('Invalid table selected');
                break;
        }
    }

    loadFabric() {

        this.fabrics$ = this._fabricService.getfabrics().pipe(take(1));
        this.fabrics$.subscribe((fabric) => {
            this.fabrics = fabric['data'];
            const transformedData = this.fabrics.map(fabric => {
                const pipedDate = fabric.created.split('T')[0]; // Extracting only the date part
                return {
                    name: fabric.name,
                    id: fabric.id,
                    sku: fabric.sku,
                    created_by: fabric.created_by.username,
                    date: pipedDate,
                    actions: true, // Include this line to ensure 'actions' is part of the data


                };
            });

            this.initializeTable(); // Initialize the table structure

            this.currentDataSource.data = transformedData; // Set the data to the table

            this.cdr.detectChanges();

            this.isLoading = false;

        });
    }


    loadCollections() {

        this.collections$ = this._collectionsService
            .getproducts()
            .pipe(take(1));

        this.collections$.subscribe((collections) => {
            this.collections = collections['data'];

            const transformedData = this.collections.map((collection) => {
                const pipedDate = collection.created.split('T')[0]; // Extracting only the date part

                return {
                    id: collection.id,
                    name: collection.collection_name,
                    sku: collection.sku,
                    date: pipedDate,
                    created_by: collection.created_by.username,

                    actions: true, // Include this line to ensure 'actions' is part of the data


                };
            });

            this.dataSource_collections = new MatTableDataSource<any>(transformedData);
            this.dataSource_collections.paginator = this.paginator;
            this.dataSource_collections.sort = this.sort;

            // Set the dataSource property of the table after the data has been loaded
            this.currentDataSource = this.dataSource_collections;

            this.cdr.detectChanges();
            this.isLoading = false;

        });
    }

    loadColors() {

        this.color$ = this._colorService
            .getColors()
            .pipe(take(1));

        this.color$.subscribe((colors) => {
            this.colors = colors['data'];

            const transformedData = this.colors.map((color) => {
                const pipedDate = color.created.split('T')[0]; // Extracting only the date part

                return {
                    id: color.id,
                    name: color.name,
                    sku: color.sku,
                    date: pipedDate,
                    created_by: color.created_by.username,

                    actions: true, // Include this line to ensure 'actions' is part of the data

                };
            });

            this.dataSource_color = new MatTableDataSource<any>(transformedData);
            this.dataSource_color.paginator = this.paginator;
            this.dataSource_color.sort = this.sort;

            // Set the dataSource property of the table after the data has been loaded
            this.currentDataSource = this.dataSource_color;

            this.cdr.detectChanges();
            this.isLoading = false;

        });
    }

    fetchSizes() {

        this.size$ = this._sizeService.getSizes().pipe(take(1));

        this.size$.subscribe((sizes) => {
            this.size = sizes['data'];

            const transformedData = this.size.map((size) => {
                const pipedDate = size.created.split('T')[0]; // Extracting only the date part

                return {
                    id: size.id,
                    name: size.name,
                    sku: size.sku,
                    date: pipedDate,
                    created_by: size.created_by.username,

                    actions: true, // Include this line to ensure 'actions' is part of the data

                };
            });

            this.dataSource_size = new MatTableDataSource<any>(transformedData);
            this.dataSource_size.paginator = this.paginator;
            this.dataSource_size.sort = this.sort;

            // Set the dataSource property of the table after the data has been loaded
            this.currentDataSource = this.dataSource_size;

            this.cdr.detectChanges();
            this.isLoading = false;

        });
    }

    loadCategory() {

        this.category$ = this._categoryService.getproducts().pipe(take(1));

        this.category$.subscribe((category) => {
            this.categorys = category['data'];

            const transformedData = this.categorys.map((category) => {
                const pipedDate = category.created.split('T')[0]; // Extracting only the date part

                return {
                    name: category.category_name,
                    category_description: category.category_description,
                    id: category.id,
                    created_by: category.created_by.username,
                    date: pipedDate,

                };
            });

            this.dataSource_category = new MatTableDataSource<any>(transformedData);
            this.dataSource_category.paginator = this.paginator;
            this.dataSource_category.sort = this.sort;

            // Set the dataSource property of the table after the data has been loaded
            this.currentDataSource = this.dataSource_category;

            this.cdr.detectChanges();
            this.isLoading = false;

        });
    }

    deleteEntry(id: any) {
        const request_id = id.id;

        switch (this.selectedTable) {
            case 'Collections':
                this.deleteCollection(request_id);
                break;
            case 'Size':
                this.deleteSize(request_id);
                break;
            case 'Color':
                this.deleteColor(request_id);
                break;
            case 'Category':
                this.deleteCategory(request_id);
                break;
            case 'Fabrics':
                this.deleteFabric(request_id);
                break;
            default:
                console.error('Invalid table selected');
                break;
        }
    }






    ViewEntry(id: any) {
        const request_id = id.id;

        switch (this.selectedTable) {
            case 'Collections':
                this.getCollectionDetails(request_id, id);
                break;
            case 'Size':
                this.getSizeDetails(request_id,id);
                break;
            case 'Color':
                this.getColorDetails(request_id,id);
                break;
            case 'Category':
                this.getCategoryDetails(request_id,id);
                break;
            case 'Fabrics':
                this.getFabricDetails(request_id,id);
                break;
            default:
                console.error('Invalid table selected');
                break;
        }
    }

    getCollectionDetails(id: any, data: any) {
        this._collectionsService.getproductsById(id).subscribe(
            (collection) => {
                this._router.navigate(['apps/prizeList/details', 'collection', id], { state: { data: collection, dataC: data } });
            },
            (error) => {
                console.error('Error fetching collection details', error);
            }
        );
    }


    getSizeDetails(id: any,data:any) {
        this._sizeService.getSizeById(id).subscribe(
            (size) => {
                this._router.navigate(['apps/prizeList/details', 'size', id], { state: { data: size , dataC: data} });
            },
            (error) => {
                console.error('Error fetching size details', error);
            }
        );
    }

    getColorDetails(id: any,data:any) {
        this._colorService.getColorById(id).subscribe(
            (color) => {
                // Navigate to the detail page with data
                this._router.navigate(['apps/prizeList/details', 'color', id], { state: { data: color, dataC: data } });
            },
            (error) => {
                console.error('Error fetching color details', error);
            }
        );
    }

    getCategoryDetails(id: any,data:any) {
        this._categoryService.getproductsById(id).subscribe(
            (category) => {
                this._router.navigate(['apps/prizeList/details', 'category', id], { state: { data: category , dataC: data} });
            },
            (error) => {
                console.error('Error fetching category details', error);
            }
        );
    }

    getFabricDetails(id: any,data:any) {
        this._fabricService.getfabricById(id).subscribe(
            (fabric) => {
                // Navigate to the detail page with data
                this._router.navigate(['apps/prizeList/details', 'fabric', id], { state: { data: fabric , dataC: data} });
            },
            (error) => {
                console.error('Error fetching fabric details', error);
            }
        );
    }


    deleteCollection(id: any) {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {

            if (result.isConfirmed) {
                this._collectionsService.deleteproducts(id)

                    .subscribe((isDeleted) => {

                        this.loadCollections();
                        if (!isDeleted) {
                            Swal.fire("Error!", "Cannot Delete");
                            return;
                        }

                        // Display a success message
                        Swal.fire(
                            'Deleted!',
                            'Your file has been deleted.',
                            'success'
                        )
                    });
            }
        });
    }

    deleteSize(id: any) {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {

            if (result.isConfirmed) {
                this._sizeService.deleteSize(id)

                    .subscribe((isDeleted) => {
                        this.fetchSizes();

                        if (!isDeleted) {
                            Swal.fire("Error!", "Cannot Delete");
                            return;
                        }

                        // Display a success message
                        Swal.fire(
                            'Deleted!',
                            'Your file has been deleted.',
                            'success'
                        )
                    });
            }
        });
    }

    deleteColor(id: any) {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                this._colorService.deleteColor(id)
                    .subscribe((isDeleted) => {
                        this.loadColors();

                        if (!isDeleted) {
                            Swal.fire("Error!", "Cannot Delete");
                            return;
                        }

                        // Display a success message
                        Swal.fire(
                            'Deleted!',
                            'Your file has been deleted.',
                            'success'
                        ).then(() => {
                        });
                    });
            }
        });

    }

    deleteCategory(id: any) {
        // Prompt the user for confirmation before deleting the employee
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                this._categoryService.deleteproducts(id)
                    .subscribe((isDeleted) => {
                        this.loadCategory();

                        if (!isDeleted) {
                            Swal.fire("Error!", "Cannot Delete");
                            return;
                        }

                        // Display a success message
                        Swal.fire(
                            'Deleted!',
                            'Your file has been deleted.',
                            'success'
                        ).then(() => {
                        });
                    });
            }
        });
    }

    deleteFabric(id: any) {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                this._fabricService.deletefabric(id)
                    .subscribe((isDeleted) => {
                        this.loadFabric();

                        if (!isDeleted) {
                            Swal.fire("Error!", "Cannot Delete");
                            return;
                        }

                        // Display a success message
                        Swal.fire(
                            'Deleted!',
                            'Your file has been deleted.',
                            'success'
                        ).then(() => {
                        });
                    });
            }
        });
    }


}
