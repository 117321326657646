<div *ngIf="vendor" class="mt-10 ml-15 md:ml-50 lg:ml-90">



  <div class="absolute inset-0 min-w-0 text-center overflow-auto print:overflow-visible" cdkScrollable>

    <div class="container mx-auto p-6 sm:py-8 sm:px-10 border-b bg-card dark:bg-transparent hide-on-print">
      <!-- Header and Action Buttons -->
      <div class="flex flex-col sm:flex-row items-center justify-between">
        <div class="flex-1 min-w-0 flex items-center">
          <button mat-button type="button" matTooltip="Close" class="hide-on-print" (click)="goBackOnPreviousWin()">
            <mat-icon svgIcon="feather:x"></mat-icon>
          </button>
          <button mat-button type="button" class="hide-on-print" (click)="printPage()">
            <mat-icon svgIcon="feather:file"></mat-icon>
          </button>
          <button mat-button type="button" class="hide-on-print" (click)="screenshot()">
            <mat-icon svgIcon="camera"></mat-icon>
          </button>
        </div>
        <div class="text-lg text-primary-500 font-medium whitespace-nowrap">{{ vendor?.status }}</div>
      </div>

      <!-- Vendor Information -->
      <div class="mt-4">
        <h2 class="text-3xl md:text-4xl font-extrabold tracking-tight leading-7 sm:leading-10 truncate">
          SO-00{{ vendor?.id }}-A
        </h2>
        <h2 class="text-3xl md:text-4xl font-extrabold tracking-tight leading-7 sm:leading-10 truncate">
          NPR {{ vendor?.total }}
        </h2>
        <div class="text-secondary leading-tight mt-1" *ngIf="vendor?.created_by?.username">
          Created By: {{ vendor.sales_person }}
        </div>
      </div>

      <!-- Collapsible Additional Information -->
      <div class="mt-6" *ngIf="showAdditionalInfo">
        <!-- Tables Container -->
        <div class="flex flex-wrap">
          <!-- Vendor Information Table -->
          <div class="w-full md:w-1/2 p-2">
            <div class="overflow-hidden border border-gray-300 rounded-lg">
              <table class="min-w-full">
                <tbody>
                  <tr class="border-b border-gray-300">
                    <td class="px-4 py-2 text-left"><strong>Point of Sales</strong></td>
                    <td class="px-4 py-2 text-left">
                      <ng-container *ngIf="vendor.pos; else notAvailable">{{ vendor.pos }}</ng-container>
                      <ng-template #notAvailable>--</ng-template>
                    </td>
                  </tr>
                  <tr class="border-b border-gray-300">
                    <td class="px-4 py-2 text-left"><strong>Social Media Link</strong></td>
                    <td class="px-4 py-2 text-left">
                      <ng-container *ngIf="vendor.social_link; else notAvailable">
                        <a class="text-link" target="_blank"
                          href="https://www.instagram.com/{{ vendor.social_link }}">{{ vendor.social_link }}</a>
                      </ng-container>
                      <ng-template #notAvailable>--</ng-template>
                    </td>
                  </tr>

                  <tr class="border-b border-gray-300">
                    <td class="px-4 py-2 text-left"><strong>PROMO CODE</strong></td>
                    <td class="px-4 py-2 text-left">
                      <ng-container *ngIf="vendor.social_link; else notAvailable">
                        <a class="text-link" target="_blank"
                          href="https://www.instagram.com/{{ vendor.social_link }}">{{ vendor.social_link }}</a>
                      </ng-container>
                      <ng-template #notAvailable>--</ng-template>
                    </td>
                  </tr>

                  <tr class="border-b border-gray-300">
                    <td class="px-4 py-2 text-left"><strong>Notes</strong></td>
                    <td class="px-4 py-2 text-left">
                      {{vendor.notes}}
                    </td>
                  </tr>


                  <tr class="border-b border-gray-300">
                    <td class="px-4 py-2 text-left"><strong>Shipping Area</strong></td>
                    <td class="px-4 py-2 text-left">
                      <ng-container *ngIf="vendor.shipping_area">{{ vendor.shipping_area }}, {{ vendor.district
                        }},<br>{{ vendor.address }}</ng-container>
                      <ng-container *ngIf="!vendor.shipping_area">--</ng-container>
                    </td>
                  </tr>


                  <tr class="border-b border-gray-300">
                    <td class="px-4 py-2 text-left"><strong>Delivery Number</strong></td>
                    <td class="px-4 py-2 text-left">
                      <ng-container *ngIf="vendor.delivery_number">{{ vendor.delivery_number }},
                      </ng-container>
                      <ng-container *ngIf="!vendor.delivery_number">--</ng-container>
                    </td>
                  </tr>
                  <tr class="border-b border-gray-300">
                    <td class="px-4 py-2 text-left"><strong>Payment Type</strong></td>
                    <td class="px-4 py-2 text-left">
                      <ng-container *ngIf="vendor.payment_type">{{ vendor.payment_type }}</ng-container>
                      <ng-container *ngIf="!vendor.payment_type">--</ng-container>
                    </td>
                  </tr>
                  <tr class="border-b border-gray-300">
                    <td class="px-4 py-2 text-left"><strong>Delivery Type</strong></td>
                    <td class="px-4 py-2 text-left">
                      <ng-container *ngIf="vendor.delivery_type">{{ vendor.delivery_type }}</ng-container>
                      <ng-container *ngIf="!vendor.delivery_type">--</ng-container>
                    </td>
                  </tr>
                  <tr class="border-b border-gray-300">
                    <td class="px-4 py-2 text-left"><strong>Shipping Carrier</strong></td>
                    <td class="px-4 py-2 text-left">
                      <ng-container *ngIf="vendor.shipping_carrier_invalley">{{ vendor.shipping_carrier_invalley
                        }}</ng-container>
                      <ng-container *ngIf="vendor.shipping_carrier_outvalley">{{ vendor.shipping_carrier_outvalley
                        }}</ng-container>
                      <ng-container *ngIf="vendor.shipping_carrier_int">{{ vendor.shipping_carrier_int }}</ng-container>
                      <ng-container *ngIf="vendor.shipping_carrier_invalley_others">{{
                        vendor.shipping_carrier_invalley_others }}</ng-container>
                      <ng-container
                        *ngIf="!vendor.shipping_carrier_invalley && !vendor.shipping_carrier_outvalley && !vendor.shipping_carrier_int && !vendor.shipping_carrier_invalley_others">--</ng-container>
                    </td>
                  </tr>
                  <tr class="border-b border-gray-300">
                    <td class="px-4 py-2 text-left"><strong>Sales Person</strong></td>
                    <td class="px-4 py-2 text-left">
                      <ng-container *ngIf="vendor.sales_person">{{ vendor.sales_person }}</ng-container>
                      <ng-container *ngIf="!vendor.sales_person">--</ng-container>
                    </td>
                  </tr>

                  <tr class="border-b border-gray-300">
                    <td class="px-4 py-2 text-left"><strong>Customer</strong></td>
                    <td class="px-4 py-2 text-left">
                      <div class="pl-4text-md">
                        <a [routerLink]="['/apps/contacts', vendor.Customers.id]" class="font-medium">
                          HUB{{vendor.Customers.id}}
                        </a>


                        <div class="font-medium"> {{ vendor.Customers.firstName }} {{ vendor.Customers.lastName
                          }} - {{vendor.Customers.gender}}
                        </div>


                        <div>{{vendor.Customers.socialMedia }}</div>
                        <div *ngFor="let email of vendor.Customers.emails">
                          <div>{{ email.email}}</div>
                        </div>
                        <div *ngFor="let email of vendor.Customers.billingAddress">
                          <div>{{email.type}} {{email.city}} {{ email.address}}</div>
                        </div>

                        <div *ngFor="let email of vendor.Customers.shippingArea">
                          <div>{{email.type}} {{email.city}} {{ email.address}}</div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <!-- Exchanges and Returns Table -->
          <div class="w-full md:w-1/2 p-2">
            <div *ngIf="exchangesAndReturns.length > 0" class="overflow-hidden border border-gray-300 rounded-lg">
              <table class="min-w-full">
                <thead>
                  <tr class="border-b border-gray-300 bg-gray-100">
                    <th class="px-4 py-2 text-left"><strong>Type</strong></th>
                    <th class="px-4 py-2 text-left"><strong>Number</strong></th>
                    <th class="px-4 py-2 text-left"><strong>Total</strong></th>
                    <th class="px-4 py-2 text-left"><strong>Date</strong></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of exchangesAndReturns" class="border-b border-gray-300">
                    <td class="px-4 py-2 text-left">Exchange/Return</td>
                    <td class="px-4 py-2 text-left">{{ item.number }}</td>
                    <td class="px-4 py-2 text-left">{{ item.total }}</td>
                    <td class="px-4 py-2 text-left">{{ item.date }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <!-- Changes Log -->
        <div class="changes-tab mt-6">
          <div class="pl-4 mb-3">
            <div class="changes-header mb-3 flex items-center">
              <i><strong>Changes</strong></i>
              <mat-icon class="icon-size-4 ml-2" svgIcon="change_circle"></mat-icon>
            </div>
            <ul class="changes-list space-y-2">
              <li *ngFor="let change of extractedChanges" class="p-2 border border-gray-200 rounded-md">
                <div class="flex justify-between items-center mb-2">
                  <strong class="mr-2">{{ change.key | uppercase }}</strong>
                  <div class="value-chip new-value" *ngIf="change.newValue">
                    New Value: {{ change.newValue }}
                    <span class="value-icon ml-2">
                      <mat-icon svgIcon="access_time_filled"></mat-icon>
                    </span>
                  </div>
                </div>
                <ng-container *ngIf="change.modifiedBy">
                  <div><i>Modified By: {{ change.modifiedBy.username }}</i></div>
                </ng-container>
                <div class="date-time text-right">{{ change.updatedAt | date: 'medium' }}</div>
              </li>
            </ul>
          </div>
        </div>


        <!-- Payment Information Table -->
        <div class="mt-6" *ngIf="payment">
          <div class="overflow-hidden border border-gray-300 rounded-lg">
            <table class="min-w-full custom-payment-table">
              <thead>
                <tr class="bg-gray-100">
                  <th class="px-4 py-2"><strong>ID</strong></th>
                  <th class="px-4 py-2"><strong>Total Received</strong></th>
                  <th class="px-4 py-2"><strong>Payment Method</strong></th>
                  <th class="px-4 py-2"><strong>Date</strong></th>
                  <th class="px-4 py-2"><strong>Time</strong></th>

                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let payment of payment" class="border-b border-gray-300">
                  <td class="px-4 py-2">{{ payment.id }}</td>

                  <td class="px-4 py-2" [class.amount-value]="payment.total_received < 0"
                    [ngClass]="{ 'text-green-600': payment.total_received >= 0, 'text-red-600': payment.total_received < 0 }">
                    {{ payment.total_received | currency:'USD':'symbol':'1.2-2' }}
                  </td>
                  <td class="px-4 py-2">{{ payment.payment_method }}</td>
                  <td class="px-4 py-2">{{ payment.date }}</td>
                  <td class="px-4 py-2">{{ payment.time }}</td>


                </tr>
              </tbody>
            </table>
          </div>
        </div>



      </div>

      <!-- Toggle Button for Additional Information -->
      <button mat-icon-button class="mt-6" (click)="toggleAdditionalInfo()">
        <mat-icon>{{ showAdditionalInfo ? 'expand_less' : 'expand_more' }}</mat-icon>
      </button>
    </div>

















    <div #screenshotElement class="w-full inline-block p-6 sm:p-10 text-left print:p-0">

      <div
        class="w-full  p-16 rounded-2xl  bg-card print:w-auto print:p-0 print:rounded-none print: print:bg-transparent">




        <div class="flex items-start">

          <div class="grid grid-rows-2 place-items-start ">
            <div class="grid auto-cols-max grid-flow-col gap-x-5">

              <div class="pl-4text-md">
                <img class="flex-0 w-20 mt-2" src="assets/images/logo/logo.png">



                <div class="font-medium">
                  customercare@hubanepal.com
                </div>





                <div class="tracking-tight mr-3">
                  <div> Saraswatinagar
                    Kathmandu,<br>
                    Nepal</div>

                </div>


              </div>
            </div>

          </div>




          <div class="grid grid-cols-2 gap-x-4 gap-y-1 ml-auto">

            <div class="justify-self-end text-4xl tracking-tight text-secondary">Sales Order</div>
            <div class="text-4xl">{{vendor.sales_order_num}}</div>
            <div class="justify-self-end font-medium tracking-tight text-secondary">Created Date</div>
            <div class="font-medium">{{vendor.slaes_order_date}}</div>
            <div class="justify-self-end font-medium tracking-tight text-secondary">Expected Shipment Date
            </div>
            <div class="font-medium">{{vendor.sales_order_shippmentDate}}</div>
            <div class="justify-self-end font-medium tracking-tight text-secondary">Total </div>
            <div class="font-medium">रू {{vendor.total}}</div>
            <div class="justify-self-end font-medium tracking-tight text-secondary">Payment terms </div>
            <div class="font-medium">{{vendor.payment_type}}</div>
          </div>

        </div>
        To - {{ vendor.Customers.firstName }} {{ vendor.Customers.lastName
        }}



        <div class="flex mt-5">
          <table class="w-full border-collapse border border-silver">
            <!-- Table Header -->
            <thead>
              <tr>
                <th class="border border-silver p-2 text-left font-medium text-lg ">PRODUCT</th>
                <th class="border border-silver p-2 text-center font-medium text-lg">SIZE</th>
                <th class="border border-silver p-2 text-center font-medium text-lg">COLOR</th>
                <th class="border border-silver p-2 text-center font-medium text-lg">RATE</th>
                <th class="border border-silver p-2 text-center font-medium text-lg">QTY</th>
                <th class="border border-silver p-2 text-center font-medium text-lg">AMOUNT</th>
              </tr>
            </thead>

            <!-- Table Body for Products -->
            <tbody>
              <!-- Table Body for Products -->
              <tr *ngFor="let product of vendor.products" class="border-b border-silver">
                <td class="border-r border-silver p-2">
                  <div class="text-md font-medium">
                    <ng-container *ngIf="product.isBundle; else productInfo">
                      {{ product.product_bundle.bundle_name }}
                    </ng-container>
                    <ng-template #productInfo>
                      {{ product.displayName }}
                    </ng-template>
                  </div>
                  <div class="mt-2 text-md text-secondary">
                    <ng-container *ngIf="product.isBundle; else productDescription">
                      {{ product.product_bundle.bundle_description }}
                    </ng-container>
                    <ng-template #productDescription>
                      {{ product.displayDescription }}
                    </ng-template>
                  </div>
                </td>
                <td class="border-r border-silver p-2 text-center text-md">{{ product.product.size }}</td>
                <td class="border-r border-silver p-2 text-center text-md">{{ product.product.color }}</td>
                <td class="p-2 text-center border-r border-silver text-md">रू {{ product?.rate }}</td>
                <td class="border-r border-silver p-2 text-center text-md"><strong>{{ product.quantity }} pcs</strong>
                </td>
                <td class="p-2 text-center text-md"><strong>रू {{ product.amount }}</strong></td>
              </tr>

              <!-- Table Body for Modified Products -->
              <tr *ngFor="let product of vendor.mod_products" class="border-b border-silver">
                <td class="border-r border-silver p-2 dim">
                  <div class="text-md font-medium">
                    <ng-container *ngIf="product?.isBundle; else productInfo">
                      {{ product?.product_bundle?.bundle_name }}
                    </ng-container>
                    <ng-template #productInfo>
                      {{ product?.displayName }}
                    </ng-template>
                  </div>
                  <div class="mt-2 text-md text-secondary">
                    <ng-container *ngIf="product?.isBundle; else productDescription">
                      {{ product?.product_bundle?.bundle_description }}
                    </ng-container>
                    <ng-template #productDescription>
                      {{ product?.displayDescription }}
                    </ng-template>
                  </div>
                </td>
                <td class="border-r border-silver p-2 text-center dim text-md">{{ product?.product?.size }}</td>
                <td class="border-r border-silver p-2 text-center dim text-md">{{ product?.product?.color }}</td>
                <td class="p-2 text-center border-r border-silver dim text-md">रू {{ product?.rate }}</td>
                <td class="border-r border-silver p-2 text-center dim text-md">{{ product?.quantity }} pcs</td>
                <td class="p-2 text-center dim text-md">रू {{ product?.amount }}</td>
              </tr>
            </tbody>


            <!-- Subtotal -->
            <tfoot class="">
              <tr>
                <td colspan="5" class=" border-silver p-2 text-right font-medium text-md">

                  SUBTOTAL
                </td>
                <td class="border border-silver p-2 text-right text-md"><strong>
                    रू {{ vendor.sub_total }}
                  </strong></td>
              </tr>

              <!-- Adjustment -->
              <tr>
                <td colspan="5" class=" border-silver p-2 text-right font-medium text-md">
                  ADJUSTMENT
                </td>
                <td class="border border-silver p-2 text-right text-md"><strong>
                    {{ vendor.adjustmentSuffix | uppercase }} {{ vendor.adjustment }}
                  </strong></td>
              </tr>

              <!-- Discount -->
              <tr>
                <td colspan="5" class=" border-silver p-2 text-right font-medium text-md">
                  DISCOUNT
                </td>
                <td class="border border-silver p-2 text-right text-md"><strong>
                    {{ vendor.discountSuffix }} {{ vendor.discount }}
                  </strong></td>
              </tr>

              <tr>
                <td colspan="5" class=" border-silver p-2 text-right font-medium text-md">
                  SHIPPING CHARGES
                </td>
                <td class="border border-silver p-2 text-right text-md"><strong>
                    {{ vendor.shipping_charges }}
                  </strong></td>
              </tr>




              <!-- Total -->
              <tr>
                <td colspan="5" class=" border-silver p-2 text-right font-medium text-md">
                  TOTAL
                </td>
                <td class="border border-silver p-2 font-medium text-right text-md"><strong>
                    रू {{ vendor.total }}
                  </strong></td>
              </tr>
            </tfoot>
          </table>
        </div>








      </div>



    </div>

    <!-- detail..html -->


  </div>

</div>