import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ContactsService } from 'app/modules/admin/apps/contacts/contacts.service';


@Component({
  selector: 'app-customer-detial-mat-drawer',
  templateUrl: './customer-detial-mat-drawer.component.html',
  styleUrls: ['./customer-detial-mat-drawer.component.scss']
})
export class CustomerDetialMatDrawerComponent implements OnInit {
  @Input() id: string;  // Input decorator to receive id from parent component
  @Output() closeDrawerEvent = new EventEmitter<void>();


  customerInfo: any;
  sales_order_count: string;
  pre_order_count: string;
  credit_notes_count: string;
  exchange_count: string;
  total: string;
  total_products: string;
  sales_return_count: string;
  activityLogs: string[] = [];
  highest_sold_product: any;
  highest_salesperson: any;
  pre_sales_orders_count: any;
  


  constructor(
    private _contactsService: ContactsService,
    private _cd: ChangeDetectorRef


  ) { }

  ngOnInit(): void {

    // Initial check if id is already available
    if (this.id) {
      this.getReport(this.id);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // React to changes of the @Input() id
    if (changes.id && changes.id.currentValue) {
      this.getReport(changes.id.currentValue);
    }
  }

  async getReport(id) {
    try {
      this.customerInfo = await this._contactsService.getReportById(id).toPromise();
        console.log(this.customerInfo,"info")

      this.sales_order_count = this.customerInfo.sales_orders_count;
      this.pre_order_count = this.customerInfo.pre_sales_orders_count;
      this.credit_notes_count = this.customerInfo.credit_notes_count;
      this.pre_sales_orders_count = this.customerInfo.pre_sales_orders_count;

      this.exchange_count = this.customerInfo.exchanges_count;
      this.sales_return_count = this.customerInfo.sales_return_count;
      this.total = this.customerInfo.total_amount_sales_orders;
      this.total_products = this.customerInfo.total_products_sales_orders;
      this.highest_sold_product = this.customerInfo.highest_sold_product;
      this.highest_salesperson = this.customerInfo.highest_salesperson
      this.activityLogs = this.customerInfo.activity_logs;
      
      this._cd.detectChanges();




    } catch (error) {
      console.error('Error fetching report:', error);
    }
  }

  downloadExcel(id: any) {

    this._contactsService.downloadExcelReport(id).subscribe((customerInfo) => {
      this.downloadFile(customerInfo, 'customer_report.xlsx');
    });
  }

  downloadPdf(id: any) {
    this._contactsService.downloadPdfReport(id).subscribe((customerInfo) => {
      this.downloadFile(customerInfo, 'customer_report.pdf');
    });
  }

  private downloadFile(customerInfo: any, fileName: string) {
    const blob = new Blob([customerInfo], { type: 'application/octet-stream' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  closeDrawer(): void {
    this.closeDrawerEvent.emit();
  }

}
