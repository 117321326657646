import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, merge, of, throwError } from 'rxjs';
import { filter, map, scan, switchMap, take, tap } from 'rxjs/operators';
import { InventorySheet } from './inventory-sheet.type';
import axios, { AxiosResponse } from 'axios';
import { server_env } from 'config';
import { WebSocketService } from './websocket.service';
import * as Rx from 'rxjs/Rx';


interface WebSocketResponse {
    data: any;
    action: string;
}


@Injectable({
    providedIn: 'root',
})


export class InventorySheetService {
    private _InventorySheet: BehaviorSubject<InventorySheet | null> =
        new BehaviorSubject(null);
    private _InventorySheetss: BehaviorSubject<InventorySheet[] | null> =
        new BehaviorSubject(null);

    public messages: Subject<InventorySheet>;

    public url = `${server_env.URL}api/v1/InventorySheet/`;

    public websocket_url = `${server_env.WS}inventory/`;

    private _webSocketUpdates = new Subject<WebSocketResponse>();
    public webSocketUpdates$: Observable<WebSocketResponse> = this._webSocketUpdates.asObservable();
    private subject: Rx.Subject<MessageEvent>;


    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient, private _websockerService: WebSocketService) {

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors

    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for contact
     */
    get InventorySheet$(): Observable<InventorySheet> {
        return this._InventorySheet.asObservable();
    }

    /**
     * Getter for contacts
     */
    get InventorySheets$(): Observable<InventorySheet[]> {
        return this._InventorySheetss.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get contacts
     */


    getInventorySheets(limit: number, offset: number): Observable<any> {
        return this._httpClient.get<any>(`${this.url}?limit=${limit}&offset=${offset}`).pipe(
            map((httpResponse: any) => {
                const response = {
                    success: true,
                    message: 'Data retrieved successfully',
                    data: httpResponse.data,
                    total: httpResponse.total
                };
                return response;
            })
        );
    }
    
    
    getMergedData(limit: number = 100): Observable<any> {
        let offset = 0;
        let total = 0;
        const httpCall$ = this.getInventorySheets(limit, offset).pipe(
            map(response => {
                total = response.total;
                return response.data;
            })
        );
    
        const wsCall$ = this._websockerService.getMessages().pipe(
            filter((message: any) => message && message.data !== ''),
            filter((value: any) => value && value.action === 'update')
        );
    
        return merge(httpCall$, wsCall$).pipe(
            scan((acc: any, value: any) => {
                if (Array.isArray(value)) {
                    // HTTP response data
                    acc.data = acc.data.concat(value);
                } else if (value && value.action === 'update') {
                    // WebSocket update
                    const updatedItemId = value.data.id;
                    const updatedItemIndex = acc.data.findIndex((item: any) => item.id === updatedItemId);
    
                    if (updatedItemIndex !== -1) {
                        acc.data[updatedItemIndex] = value.data;
                    } else {
                        acc.data.push(value.data);
                    }
                }
    
                // Load more data if needed
                if (acc.data.length < total) {
                    offset += limit;
                    this.getInventorySheets(limit, offset).subscribe(response => {
                        total = response.total;
                        acc.data = acc.data.concat(response.data);
                    });
                }
    
                return acc;
            }, { data: [] })
        );
    }
    
    
    /**
     * Get InventorySheet by id
     */
    getInventorySheetById(id: string): Observable<InventorySheet> {
        const requestUrl = this.url + id;

        return this._httpClient.get<InventorySheet[]>(`${requestUrl}`).pipe(
            take(1),
            map((InventorySheet: any) => {
                this._InventorySheet.next(InventorySheet.data);

                // Return the InventorySheet
                return InventorySheet.data;
            }),
            switchMap((InventorySheet) => {
                if (!InventorySheet) {
                    return throwError(
                        'Could not found InventorySheet with id of ' + id + '!'
                    );
                }

                return of(InventorySheet);
            })
        );
    }


    /**
     * create customer
     * @param data
     * @returns
     */

    createInventorySheets(data): Promise<any> {
        return new Promise((resolve, reject) => {
            const requestUrl = this.url;

            this._httpClient.post<any>(`${requestUrl}`, data).subscribe(
                (response) => {
                    return resolve(response);
                },
                (error) => {
                    return reject(error);
                }
            );
        });
    }
    /**
     * Update contact
     *
     * @param id
     * @param contact
     */
    updateInventorySheet(id: string, contact: InventorySheet): Observable<InventorySheet> {
        return this.InventorySheets$.pipe(
            take(1),
            switchMap((salesordder) =>
                this._httpClient
                    .put<InventorySheet>(`${this.url}${id}/`, {
                        id,
                        contact,
                    })
                    .pipe(
                        map((updatedInventorySheet) => {
                            // Find the index of the updated contact
                            const index = salesordder.findIndex(
                                (item) => item.id === id
                            );

                            // Update the contact
                            salesordder[index] = updatedInventorySheet;

                            // Update the contacts
                            this._InventorySheetss.next(salesordder);

                            // Return the updated contact
                            return updatedInventorySheet;
                        }),
                        switchMap((updatedInventorySheets) =>
                            this.InventorySheet$.pipe(
                                take(1),
                                filter((item) => item && item.id === id),
                                tap(() => {
                                    // Update the contact if it's selected
                                    this._InventorySheet.next(updatedInventorySheets);

                                    // Return the updated contact
                                    return updatedInventorySheets;
                                })
                            )
                        )
                    )
            )
        );
    }

    /**
     * Delete the contact
     *
     * @param id
     */
    deleteInventorySheet(id: string): Observable<boolean> {
        return this.InventorySheets$.pipe(
            take(1),
            switchMap((InventorySheet) =>
                this._httpClient.delete(`${this.url}${id}/`).pipe(
                    map((isDeleted: boolean) => {
                        // Find the index of the deleted contact
                        const index = InventorySheet.findIndex(
                            (item) => item.id === id
                        );

                        // Delete the contact
                        InventorySheet.splice(index, 1);

                        // Update the contacts
                        this._InventorySheetss.next(InventorySheet);

                        // Return the deleted status
                        return isDeleted;
                    })
                )
            )
        );
    }


}
