import { Route } from '@angular/router';
import { PrizeListComponent } from './prize-list/prize-list.component';
import { CreatePrizeListComponent } from './create-prize-list/create-prize-list.component';
import { DetailPrizeListComponent } from './detail-prize-list/detail-prize-list.component';




export const PrizeListRoute: Route[] = [

    {
        path: '',
        component: PrizeListComponent,
    },
    {
        path: 'NEW',
        component: CreatePrizeListComponent,

    },
    { path: 'details/:type/:id', component: DetailPrizeListComponent },

]
