import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { productGroup } from 'app/product-group/product-group.type';
import { productVaraitons } from 'app/productVariations/productVariations.service';
import { map,  take, tap } from 'rxjs/operators';
import { Observable,  of } from 'rxjs';
import { productVaraitonService } from 'app/productVariations/productVarations.type';
import Swal from 'sweetalert2';
import { productCategories } from '../product-categories.type';
import { productCategoryService } from '../product-categories.service';

@Component({
  selector: 'app-detailproduct-categories',
  templateUrl: './detailproduct-categories.component.html',
  styleUrls: ['./detailproduct-categories.component.scss']
})
export class DetailproductCategoriesComponent implements OnInit {
  productId: string;
  editMode: boolean = false;
  vendorsCredit: FormGroup;
  vendor: productGroup;
  vendors: productGroup[];
  thumbnailFile: File | null;
  selectedProductThumbnail: string[] = [];
  selectedProductThumbnailClass: string;
  filteredProductOptions$: Observable<productVaraitons[]>;
  constructor(private route: ActivatedRoute,
    private productService: productCategoryService, private _productService: productVaraitonService, private _formBuilder: FormBuilder, private _changeDetectorRef: ChangeDetectorRef,) {

    const products$: Observable<productVaraitons[]> = this._productService
      .getproductVaraiton()
      .pipe(
        take(1),
        map((response) => response)
      );
  }

  ngOnInit(): void {

    this.vendorsCredit = this._formBuilder.group({
      category_name: ['', Validators.required],
      category_description: ['', Validators.required],
      category_sku: [''],
      products: this._formBuilder.array([]),




    });

    this.route.params.subscribe(params => {
      const productId = params['id'];
      this.fetchAndHandleProductDetails(productId);
    });

    this.productService.productss$.subscribe((product: productCategories[]) => {
      this.vendors = product;
      this._changeDetectorRef.markForCheck();
    });




  }
  fetchAndHandleProductDetails(productId: string): void {
    // Use your product service to fetch the product details based on the provided ID
    this.productService.getproductsById(productId).subscribe(
      (data) => {
        // Handle the fetched product data
        this.handleFetchedProductData(data);
      },
      (error) => {
        console.error('Error fetching product details:', error);
      }
    );
  }

  handleFetchedProductData(vendor: productCategories): void {
    // Get the vendor
    this.vendor = vendor;
    this.vendorsCredit.patchValue(vendor);

    const productFormArray = this.vendorsCredit.get('products') as FormArray;
    productFormArray.clear();

    if (vendor.products.length > 0) {
      // Iterate over the products array and update the form
      vendor.products.forEach(product => {
        const productFormGroup = this._formBuilder.group({
          product: product.product,
          size: product.size,
          color: product.color,
        });

        productFormArray.push(productFormGroup);
      });
    }

    this.toggleEditMode(false);

    // Mark for check
    this._changeDetectorRef.markForCheck();
  }






  toggleEditMode(editMode: boolean | null = null): void {
    if (editMode === null) {
      this.editMode = !this.editMode;
    }
    else {
      this.editMode = editMode;
    }

    // Mark for check
    this._changeDetectorRef.markForCheck();
  }
  printPage() {
    // Select the buttons you want to hide
    const buttonsToHide = document.querySelectorAll('button');

    // Hide the buttons before printing
    buttonsToHide.forEach((button) => {
      button.style.display = 'none';
    });

    // Perform the printing
    window.print();

    // Show the buttons again after printing (delayed to ensure they show after the print dialog)
    setTimeout(() => {
      buttonsToHide.forEach((button) => {
        button.style.display = 'block'; // or 'inline' depending on the button's original display property
      });
    }, 1000); // Adjust the delay as needed
  }






  async updateProductCategories() {
    if (!this.vendorsCredit.valid) {
      this.showFormValidationError();
      return;
    }

    const customerData = { ...this.vendorsCredit.value };
    const id = this.vendor.id;
    const products = this.vendorsCredit.get('products') as FormArray;

    const updatedProducts = products.value.map((item: any) => ({
      product: item.product.id,

      size: item.size,
      color: item.color,


    }));

    customerData.products = updatedProducts;
    // Convert shipping area to a list

    try {
      const updatedvendor = await this.updatevendorOnServer(id, customerData);
      this.handleUpdateSuccess(updatedvendor, id);
    } 
      
     finally {
      this.toggleEditMode(false);
    }

    this._changeDetectorRef.markForCheck();
  }

  private async updatevendorOnServer(id: string, data: any): Promise<productCategories> {
    return new Promise<productCategories>((resolve, reject) => {

      this.productService.updateproducts(id, data).subscribe(
        (customerData: productCategories) => {
          resolve(customerData);
        },


        (error) => {
          reject(error);
        }
      );

    });
  }

  private handleUpdateSuccess(updatedvendor: productCategories, id: string) {

    this.productService.getproductsById(id).subscribe(
      (data) => {
        // Handle the fetched product data
        this.handleFetchedProductData(data);
      },
      (error) => {
        console.error('Error fetching product details:', error);
      }
    );

    this.vendor = updatedvendor;
    this.vendorsCredit.patchValue(updatedvendor);
    this.showSuccessToast('Product Category Updated!!');

    const productFormGroup = this.vendorsCredit.get('products') as FormGroup;
    productFormGroup.patchValue({
      
    }); 
  
    if (updatedvendor.products) {
      updatedvendor.products.forEach((shippingData) => {
        productFormGroup.patchValue({
          product: shippingData.product,
          size: shippingData.size,
          color: shippingData.color,
        });
      });
    }
  

  }
  
  private handleUpdateError(error: any) {
    this.showErrorToast('Error Updating Category');
    console.error('Error updating Bundle:', error);
  }
  

  private showFormValidationError() {
    this.showErrorToast('Form Invalid');
  }


  private showSuccessToast(message: string) {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: 'success',
      title: message,
    });
  }

  private showErrorToast(message: string) {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: 'error',
      title: message,
    });
  }



  addProductField(): void {
    const productFormGroup = this._formBuilder.group({
      product: ['',],
      size: ['',],
      rate: [''],
      quantity: [''],
      color: ['rgb(79, 195, 255)',],
      amount: [''],
    });

    const productArray = this.vendorsCredit.get('products') as FormArray;
    productArray.push(productFormGroup);

    const index = productArray.length - 1;

  }


  filterOptionsProduct(value: string): void {
    if (typeof value !== 'string') {
      this.filteredProductOptions$ = of([]);
      return;
    }
    const baseUrl = 'http://103.147.227.9:2/8081';
    this.filteredProductOptions$ = this._productService.getproductVaraiton().pipe(
      take(1),
      map((response) => response),
      tap((products) => console.log(products)), // Log the products array before filtering
      map((products) => {
        if (Array.isArray(products['data'])) {
          const filteredProducts = products['data']
            .filter(
              (product) =>
                product.productName
                  ?.toLowerCase()
                  .includes(value.toLowerCase()) ||
                (product.sku &&
                  product.size
                    .toString()
                    .toLowerCase()
                    .includes(value.toLowerCase()))
            )
            .map((product) => {
              return {
                product: product.id,
                id:product.id,
                productName: product.base_product.productName,
                sku: product.sku,
                size: product.size,
                color: product.color,
                basePrice: product.basePrice,
                thumbnail: baseUrl + product.base_product.thumbnail,

              };
            });
          // Log the filtered products array
          return filteredProducts;

        } else {

          return [];
        }
      })
    );
  }

  selectOptionProduct(option: productVaraitons, index: number): void {
    const productFormGroup = this._formBuilder.group({
      product: [option],
      size: [option.size,],

      color: [option.color],
      rate: [option.basePrice],
      quantity: [''],
      amount: [''],
    });

    const productArray = this.vendorsCredit.get('products') as FormArray;
    const existingFormGroup = productArray.at(index) as FormGroup;

    if (existingFormGroup) {
      existingFormGroup.patchValue(productFormGroup.value);
      this.selectedProductThumbnail[index] = option.thumbnail;
      this.selectedProductThumbnailClass = 'example-option-img';
    }
  }
  displayProductOption(product: productVaraitons): string {
    if (product) {
      const productName = product.productName.toString() || '';
     
      return productName ;
    }
    return '';
  }

  removeProductField(index: number): void {
    const productArray = this.vendorsCredit.get('products') as FormArray;
    productArray.removeAt(index);


  }

}
