<div *ngIf="vendor" class="mt-10 ml-15 md:ml-50 lg:ml-90">



    <div class="absolute inset-0 min-w-0 text-center overflow-auto print:overflow-visible" cdkScrollable>


        <!-- Main -->
        <div class="w-full inline-block p-6 sm:p-10 text-left print:p-0">

            <!-- Invoice -->
            <div
                class="w-full  p-16 rounded-2xl shadow bg-card print:w-auto print:p-0 print:rounded-none print:shadow-none print:bg-transparent">

                <div class="flex items-center">
                    <div class="grid grid-rows-2 place-items-center ">

                        <div class="grid-cols-2  place-items-center">


                            <button mat-button type="button" matTooltip="Close" class="hide-on-print"
                                (click)="goBack()">
                                <span class="inline-flex items-center">
                                    <mat-icon svgIcon="feather:x"></mat-icon>
                                </span>
                            </button>

                            <button mat-button type="button" class="hide-on-print" (click)="printPage()">
                                <span class="inline-flex items-center">
                                    <mat-icon svgIcon="feather:file"></mat-icon>
                                </span>
                            </button>



                        </div>
                    </div>
                </div>

                <div class="flex items-start">


                    <div class="grid grid-rows-1 place-items-start">
                        <div class="grid auto-cols-max grid-flow-col gap-x-5">
                            <div class="pl-4 border-l text-md">
                                <a [routerLink]="['/apps/contacts', vendor.customer.id]">
                                    <i><strong>HUB{{vendor.customer.id}}</strong></i>
                                </a>
                                <div class="font-medium"> {{ vendor?.customer.firstName }} {{ vendor?.customer.lastName
                                    }}</div>
                                <div>{{vendor?.salesOrder?.Customers.gender}}</div>

                                <div *ngFor="let phone of vendor?.customer.phoneNumbers">
                                    <div> +977-{{ phone.number}}</div>
                                </div>

                                <div *ngFor="let email of vendor?.salesOrder?.Customers.billingAddress">
                                    <div>{{email.type}} {{email.city}} {{ email.address}}</div>
                                </div>

                                <div *ngFor="let email of vendor?.salesOrder?.Customers.shippingArea">
                                    <div>{{email.type}} {{email.city}} {{ email.address}}</div>
                                </div>
                                <div class="mt-2 m-2">
                                    <div>Created</div>
                                    <div class="text-secondary text-md">{{vendor?.created}}</div>

                                    <div>Initial Sales Order</div>

                                    <a class="text-secondary text-md"
                                        [routerLink]="['/apps/sales-order', vendor.salesOrder.id]">
                                        {{vendor?.salesOrder.sales_order_num}}
                                    </a>
                                    <div>Credit Note</div>
                                    <a [routerLink]="['/apps/credit-notes', salesCredit.id]"
                                        class="text-secondary text-md">{{salesCredit?.voucher_order_num}}</a>

                                    <div>Remaining Credit</div>
                                    <div class="text-secondary text-md">{{salesCredit?.total}}</div>

                                    <div>
                                        <span class="chip-rt mt-2 mr-2"><span>Returned</span></span>
                                        <span class="chip-credit mt-2"><span>Credited</span></span>
                                        <span *ngIf="isReceived" class="chip-r mt-2 ml-2"><span>Recived</span></span>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>




                    <div class="grid grid-cols-2 gap-x-4 gap-y-1 ml-auto">

                        <div class="justify-self-end text-4xl tracking-tight text-secondary">Sales Return</div>
                        <div class="text-4xl">{{vendor?.salesReturnNumber}}

                        </div>
                        <div class="justify-self-end font-medium tracking-tight text-secondary">Created Date</div>
                        <div class="font-medium">{{vendor?.salesOrder?.slaes_order_date}}</div>
                        <div class="justify-self-end font-medium tracking-tight text-secondary">Expected Shipment Date
                        </div>
                        <div class="font-medium">{{vendor?.salesOrder?.sales_order_shippmentDate}}</div>
                        <div class="justify-self-end font-medium tracking-tight text-secondary">Order Return Date
                        </div>
                        <div class="font-medium">{{vendor?.date}}</div>
                        <div class="justify-self-end font-medium tracking-tight text-secondary">Reason For Return
                        </div>
                        <div class="font-medium">{{vendor?.reasonForReturn}}</div>
                        <div class="justify-self-end font-medium tracking-tight text-secondary">TOTAL </div>
                        <div class="font-medium">रू {{vendor?.salesOrder?.total}}</div>
                        <div class="justify-self-end font-medium tracking-tight text-secondary">Status </div>
                        <div class="font-medium">{{vendor?.salesOrder?.status}}</div>

                    </div>


                </div>

                <mat-divider class="m-2"></mat-divider>




                <div class="grid grid-cols-12 gap-x-1  mt-10">
                    <!-- Columns -->
                    <div class="col-span-6 font-medium text-md text-secondary">PRODUCT</div>
                    <div class="col-span-2 font-medium text-md text-right text-secondary">SIZE</div>
                    <div class="col-span-2 font-medium text-md text-right text-secondary">COLOR</div>
                    <div class="col-span-1 font-medium text-md text-right text-secondary">QTY</div>
                    <div class="col-span-1 font-medium text-md text-right text-secondary">TOTAL</div>

                    <!-- Divider -->
                    <div class="col-span-12 my-4 border-b"></div>


                    <!-- Divider -->

                    <!-- Products Loop -->
                    <ng-container *ngFor="let product of vendor?.return_products" class="col-span-12 flex items-center">
                        <!-- Product Avatar (Image) -->
                        <div class="col-span-6">
                            <div class="text-lg font-medium">
                                <div class="text-lg font-medium">{{ product.sale_product }}</div>
                            </div>
                        </div>

                        <div class="col-span-2 self-center text-right">{{ product.sale_size }}</div>
                        <div class="col-span-2 self-center text-right">{{ product.sale_color }}</div>
                        <div class="col-span-1 self-center text-right">{{ product.sale_quantity }}</div>
                        <div class="col-span-1 self-center text-right">रू {{ product.sale_amount }}</div>

                        <!-- Adjusted col-span to 2 for TOTAL -->

                        <!-- Divider -->
                        <div class="col-span-12 my-4 border-b"></div>
                    </ng-container>

                    <!-- Spacer -->
                    <div class="col-span-12 mt-16"></div>

                    <!-- Subtotal -->
                    <div class="col-span-10 self-center font-medium tracking-tight text-secondary">SUBTOTAL</div>
                    <div class="col-span-2 text-right text-lg">रू {{ vendor?.sub_total }}</div>

                    <!-- Divider -->
                    <div class="col-span-12 my-3 border-b"></div>

                    <!-- Shipping Charges -->
                    <div class="col-span-10 self-center font-medium tracking-tight text-secondary">ADJUSTMENT
                    </div>
                    <div class="col-span-2 text-right text-lg">{{vendor?.adjustmentSuffix}} {{ vendor?.adjustment }}
                    </div>

                    <!-- Divider -->
                    <div class="col-span-12 my-3 border-b"></div>

                    <!-- Discount -->
                    <div class="col-span-10 self-center font-medium tracking-tight text-secondary">DISCOUNT</div>
                    <div class="col-span-2 text-right text-lg">{{vendor?.discountSuffix}} {{ vendor?.discount }}</div>

                    <!-- Divider -->
                    <div class="col-span-12 my-3 border-b"></div>

                    <!-- Total -->
                    <div class="col-span-10 self-center text-2xl font-medium tracking-tight text-secondary">TOTAL</div>
                    <div class="col-span-2 text-right text-2xl font-medium">
                        रू {{ vendor?.total }}</div>




                </div>






                <div class="mt-16">
                    <div class="font-medium">Notes To the Customer.</div>
                    <div class="flex items-start mt-4">
                        <img class="flex-0 w-20 mt-2" src="assets/images/logo/logo.png">
                        <div class="ml-6 text-sm text-secondary">
                            {{vendor?.salesOrder?.notes}}
                        </div>
                    </div>
                </div>




            </div>

        </div>

    </div>



    <!-- Card #19 -->



</div>