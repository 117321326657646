import { Route } from '@angular/router';
import { ListRestockComponent } from './list-restock/list-restock.component';
import { CreateRestockComponent } from './create-restock/create-restock.component';
import { DetailRestockComponent } from './detail-restock/detail-restock.component';
import { UnsavedChangesGuard } from 'app/page-detect/wm-can-leave/detect-changes.guard';




export const RestockEntryRoutes: Route[] = [
   
        {
            path     : '',
            component: ListRestockComponent,
        },
        {
            path         : 'NEW',
            component    : CreateRestockComponent,
            canDeactivate: [UnsavedChangesGuard], 

           
        },
        {
            path         : ':id',
            component    :DetailRestockComponent,
          
          
        },
]
