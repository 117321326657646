/* eslint-disable */
import * as moment from 'moment';

export const messages = [
    // {
    //     id         : '832276cc-c5e9-4fcc-8e23-d38e2e267bc9',
    //     image      : 'assets/images/avatars/male-04.jpg',
    //     title      : 'Ashim Udas',
    //     description: 'We should talk about the quality of our products',
    //     time       : moment().subtract(25, 'minutes').toISOString(), // 25 minutes ago
    //     read       : false
    // }
    
        ];
