import { Route } from '@angular/router';



import { SalesOrderDetailComponent } from './detail/detail.component';
import { SalesOrderCreateComponent } from './sales-order-create/sales-order-create.component';

import { SalesOrderListComponent } from './list/list.component';
import { UnsavedChangesGuard } from 'app/page-detect/wm-can-leave/detect-changes.guard';
import { SalesOrderResolver } from './sales-order.reslover';

export const SalesRoutes: Route[] = [
   
        {
            path     : '',
            component: SalesOrderListComponent,
            resolve: {
                salesOrders: SalesOrderResolver
              }
        },
        {
            path         : 'NEW',
            component    : SalesOrderCreateComponent,
            canDeactivate: [UnsavedChangesGuard], 

           
        },
        {
            path         : ':id',
            component    :SalesOrderDetailComponent,
          
          
        },

]
