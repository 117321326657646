// sku-creation.service.ts

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SkuCreationService {
  private skuAlreadyCreated: boolean = false;

  // Getter for skuAlreadyCreated
  get isSkuAlreadyCreated(): boolean {
    return this.skuAlreadyCreated;
  }

  // Setter for skuAlreadyCreated
  set isSkuAlreadyCreated(value: boolean) {
    this.skuAlreadyCreated = value;
  }

  // Reset the SKU creation state
  resetSkuCreationState() {
    this.skuAlreadyCreated = false;
  }
}
