<div class="form-container">
  <form [formGroup]="partialUpdateForm" (ngSubmit)="submitForm()">
    <div class="flex flex-col mt-1 p-4 pb-3 bg-card rounded overflow-hidden">
      <p class="text-lg font-medium">Partial Payment Update</p>
      <p class="text-secondary">
        You can update your partial payment details from here. All the previous details will stay the same.
      </p>
      <mat-divider class="mt-4 mb-4"></mat-divider>
      <div class="mb-4 flex">
        <mat-form-field class="fuse-mat-rounded flex-auto m-2">
          <mat-label>Sales Order Number</mat-label>
          <input matInput formControlName="salesOrderNumber" readonly class="mt-1 block w-full" />
        </mat-form-field>
        <mat-form-field class="fuse-mat-rounded flex-auto m-2">
          <mat-label>Total Sales Order Amount</mat-label>
          <input matInput formControlName="salesOrderTotal" readonly class="mt-1 block w-full" />
        </mat-form-field>
      </div>

      <div formArrayName="payment" *ngFor="let paymentControl of payment.controls; let i = index;">
        <div [formGroupName]="i" class="flex items-center mb-2">
          <mat-form-field class="flex-1 mr-2 fuse-mat-rounded">
            <mat-label>Payment Type</mat-label>
            <mat-select formControlName="payment_type" class="elevated-input">
              <mat-option *ngFor="let option of paymentOptions" [value]="option">{{ option }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="flex-1 mr-2 fuse-mat-rounded">
            <mat-label>Total</mat-label>
            <input matInput formControlName="total" type="number" class="mt-1 block w-full" />
          </mat-form-field>
          <button type="button" (click)="removePayment(i)" class="mt-6">
            <mat-icon color="warn">delete</mat-icon>
          </button>
        </div>
      </div>
      <div class="mb-4 flex">

        <button type="button" (click)="addPayment()" class="mt-4">
          <mat-icon color="gteen">add</mat-icon><span>
            Add Payment Method
          </span>
        </button>
      </div>
      <div *ngIf="partialUpdateForm.hasError('totalExceedsSalesOrder')" class="text-red-500 mt-2">
        The total payment amount exceeds the sales order total.
      </div>
    </div>
    <div class="flex items-center justify-end mt-4">
      <button class="px-4 ml-2" mat-flat-button type="submit" color="primary">Save</button>
      <button (click)="cancel()" class="px-4 ml-2 flex" mat-flat-button type="button" color="error">Cancel</button>
    </div>
  </form>
</div>