import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ListpackagesComponent } from './listpackages/listpackages.component';
import { CreatepackagesComponent } from './createpackages/createpackages.component';
import { DetailpackagesComponent } from './detailpackages/detailpackages.component';
import { MaterialModule } from 'app/modules/material/material.module';
import { RouterModule } from '@angular/router';
import { PackagesRoutes } from './packages.routing';
import { PackagePopupComponent } from './package-popup/package-popup.component';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { ShippedPopupComponent } from './shipped-popup/shipped-popup.component';
import { DelivarypopupComponent } from './delivarypopup/delivarypopup.component';
import {fusePackNotShippedComponent } from './packages/pack-not-shipped/pack-not-shipped.component';
import { fuseShippedPackageComponent } from './packages/shipped-package/shipped-package.component';
import { fuseDeliveredPackageComponent } from './packages/delivered-package/delivered-package.component';

@NgModule({
    declarations: [
        ListpackagesComponent,
        CreatepackagesComponent,
        DetailpackagesComponent,
        PackagePopupComponent,
        ShippedPopupComponent,
        DelivarypopupComponent,
        fuseDeliveredPackageComponent,
        fuseShippedPackageComponent,
        fusePackNotShippedComponent,
    ],
    imports: [
        RouterModule.forChild(PackagesRoutes),
        MaterialModule,
        CommonModule,
    ],
    providers: [
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: 'YYYY-MM-DD',
                },
                display: {
                    dateInput: 'YYYY-MM-DD',
                    monthYearLabel: 'MMM YYYY',
                    dateA11yLabel: 'LL',
                    monthYearA11yLabel: 'MMMM YYYY',
                },
            },
        },
        DatePipe,
    ],
})
export class PackagesModule {}
