<div class="w-full h-full">
    <form [formGroup]="vendorsCredit" class="bg-gray-100 rounded shadow-lg h-full">
        <!-- Avatar -->
        <!-- Avatar code goes here -->

        <!-- Personal Information Form -->
        <div class="p-8">
            <div class="prose prose-sm max-w-3xl">
                <!-- HTML template -->
            </div>

            <div class="flex flex-col mt-8">
                <p class="text-lg font-medium">Expense Creation Form</p>
                <p class="text-secondary mt-2">
                    <i>"Provide all relevant details for the expense you are submitting. <br>This includes information
                        such as
                        the expense number, date, the person responsible, and a description of the expense. <br>Make
                        sure to
                        fill out all required fields accurately to ensure proper documentation and processing."</i>
                </p>
                <mat-divider class="mt-8 mb-10"></mat-divider>

                <!-- Expense Number -->
                <div class="flex mb-4 max-w-2xl">
                    <mat-form-field style="max-width: 200px"
                        class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix">
                        <mat-label>Expense Number</mat-label>
                        <input matInput placeholder="Expense Number" formControlName="expense_number"
                            class="elevated-input" />
                    </mat-form-field>
                </div>
                <mat-divider class="mt-3 mb-1"></mat-divider>

                <!-- Expense Date -->
                <div class="flex mb-4 max-w-2xl">
                    <mat-form-field style="max-width: 500px"
                        class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix">
                        <mat-label>Expense Date</mat-label>
                        <input matInput [matDatepicker]="picker" placeholder="Expense Date"
                            formControlName="expense_date" class="elevated-input" />
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <mat-hint>Specify the date when the expense was incurred.</mat-hint>
                    </mat-form-field>
                </div>

                <!-- Contact Person -->
                <div class="flex mb-4 max-w-2xl">
                    <mat-form-field style="max-width: 500px"
                        class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix mr-3">
                        <mat-label>Contact Person</mat-label>
                        <input matInput placeholder="Contact Person" formControlName="contact_person"
                            class="elevated-input" />
                        <mat-hint>The person responsible for or related to this expense.</mat-hint>
                    </mat-form-field>
                    <mat-form-field style="max-width: 500px"
                        class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix">
                        <mat-label>Expense For</mat-label>
                        <input matInput placeholder="Expense For" formControlName="expense_for"
                            class="elevated-input" />
                        <mat-hint>The name of the entity or project this expense is for.</mat-hint>
                    </mat-form-field>
                </div>

                <!-- Expense Head -->
                <div class="flex mb-4 max-w-2xl">
                    <mat-form-field style="max-width: 500px"
                        class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix mr-3">
                        <mat-label>Expense Head</mat-label>
                        <mat-select formControlName="expense_head" required>
                            <mat-option *ngFor="let head of expenseHeads" [value]="head.name">
                                {{ head.name }}
                            </mat-option>
                            <mat-option *ngIf="!expenseHeads?.length" disabled>No Expense Heads Found</mat-option>
                        </mat-select>
                        <mat-hint>Select the main category for this expense.</mat-hint>
                    </mat-form-field>
                    <mat-form-field style="max-width: 500px"
                        class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix">
                        <mat-label>Expense Category</mat-label>
                        <mat-select formControlName="expense_category" required>
                            <mat-option *ngFor="let category of expenseCategories" [value]="category.name">
                                {{ category.name }}
                            </mat-option>
                            <mat-option *ngIf="!expenseCategories?.length" disabled>No Expense Categories
                                Found</mat-option>
                        </mat-select>
                        <mat-hint>Choose a specific category under the selected expense head.</mat-hint>
                    </mat-form-field>
                </div>
                <mat-divider class="mt-3 mb-1"></mat-divider>

                <div class="mb-4 mt-4">
                    <label class="block font-medium text-gray-700 mb-2">Bill Image</label>
                    <p class="text-secondary mt-3">Upload your bill image for your expe by clicking the button below.
                    </p>

                    <input type="file" #fileInput (change)="onFileSelected($event)" accept="image/*" hidden>

                    <button type="button" mat-raised-button color="primary" class="mt-2"
                        (click)="triggerFileInput()">Upload Image</button>
                </div>

                <!-- Image preview card -->
                <div *ngIf="imagePreview" class="mt-4 bg-white p-4 rounded shadow-md w-150 mb-4">
                    <p class="text-sm font-medium text-gray-700">Image Preview:</p>
                    <button mat-icon-button class="absolute top-0 right-0" (click)="clearImage()">
                        <mat-icon>close</mat-icon>
                    </button>
                    <img [src]="imagePreview" alt="Image Preview" class="mt-2 max-h-64 object-contain cursor-pointer"
                        (click)="toggleFullScreen($event)">
                </div>
                <!-- Expense Description -->
                <mat-divider class="mt-3 mb-1"></mat-divider>

                <div class="flex mb-4 max-w-2xl">
                    <mat-form-field style="max-width: 500px"
                        class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix">
                        <mat-label>Expense Description</mat-label>
                        <textarea matInput placeholder="Expense Description" formControlName="expense_description"
                            rows="3" class="elevated-input"></textarea>
                        <mat-hint>Provide a brief description of the expense.</mat-hint>
                    </mat-form-field>
                </div>

                <!-- Expense Type -->
                <div class="flex mb-4 max-w-2xl">
                    <mat-form-field style="max-width: 500px"
                        class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix mr-3">
                        <mat-label>Expense Type</mat-label>
                        <mat-select formControlName="expense_type" required>
                            <mat-option value="materials">Materials</mat-option>
                            <mat-option value="manufacturing">Manufacturing</mat-option>
                            <mat-option value="packaging">Packaging</mat-option>
                            <mat-option value="rent">Rent</mat-option>
                            <mat-option value="utilities">Utilities</mat-option>
                            <mat-option value="salaries_and_wages">Salaries and Wages</mat-option>
                            <mat-option value="marketing_and_advertising">Marketing and Advertising</mat-option>
                            <mat-option value="transportation">Transportation</mat-option>
                            <mat-option value="insurance">Insurance</mat-option>
                            <mat-option value="taxes">Taxes</mat-option>
                            <mat-option value="professional_fees">Professional Fees</mat-option>
                            <mat-option value="depreciation">Depreciation</mat-option>
                            <mat-option value="interest_expense">Interest Expense</mat-option>
                            <mat-option value="product_development">Product Development</mat-option>
                            <mat-option value="quality_control">Quality Control</mat-option>
                            <mat-option value="customer_service">Customer Service</mat-option>
                            <mat-option value="technology">Technology</mat-option>
                            <mat-option value="office_supplies">Office Supplies</mat-option>
                            <mat-option value="other">Other</mat-option>
                        </mat-select>
                        <mat-hint>Select the type of expense. If not listed, choose "Other".</mat-hint>
                    </mat-form-field>
                    <mat-form-field style="max-width: 500px"
                        class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix">
                        <mat-label>Payment Method</mat-label>
                        <mat-select formControlName="payment_method" required>
                            <mat-option value="cash">CASH</mat-option>
                            <mat-option value="cheque">CHEQUE</mat-option>
                            <mat-option value="banking">BANKING</mat-option>
                        </mat-select>
                        <mat-hint>Specify how the payment was made.</mat-hint>
                    </mat-form-field>
                </div>

                <!-- Other Expense Type -->
                <div class="flex mb-4 max-w-2xl" *ngIf="vendorsCredit.get('expense_type').value === 'other'">
                    <mat-form-field style="max-width: 500px"
                        class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix">
                        <mat-label>Other Expense Type</mat-label>
                        <input matInput placeholder="Enter other expense type" formControlName="otherExpenseType"
                            class="elevated-input" />
                        <mat-hint>Provide a description for the other expense type.</mat-hint>
                    </mat-form-field>
                </div>

                <!-- Total -->
                <mat-divider class="mt-3 mb-1"></mat-divider>
                <!-- Is Credit Checkbox -->
                <div class="flex mb-4 max-w-2xl">
                    <mat-checkbox formControlName="is_credit">Is Credit</mat-checkbox>
                </div>
                <div class="flex mb-4 max-w-2xl">
                    <mat-form-field style="max-width: 500px"
                        class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix">
                        <mat-label>Total</mat-label>
                        <input matInput placeholder="Total" required formControlName="total" type="number"
                            class="elevated-input" />
                        <mat-hint>Enter the total amount for the expense.</mat-hint>
                    </mat-form-field>
                </div>


                <!-- Credit Valid Date Field (conditionally shown) -->
                <div *ngIf="vendorsCredit.get('is_credit').value" class="flex mb-4 max-w-2xl">
                    <mat-form-field style="max-width: 500px"
                        class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix">
                        <mat-label>Credit Valid Date</mat-label>
                        <input matInput [matDatepicker]="creditPicker" placeholder="Credit Valid Date"
                            formControlName="credit_valid_date" class="elevated-input" />
                        <mat-datepicker-toggle matSuffix [for]="creditPicker"></mat-datepicker-toggle>
                        <mat-datepicker #creditPicker></mat-datepicker>
                        <mat-hint>Specify the date till when is the credit  valid.</mat-hint>
                    </mat-form-field>
                </div>
                <!-- Save Button -->
                <div class="flex items-center mt-10">
                    <button (click)="sumbitVendorsInfo()"
                    class="px-6 py-2 rounded text-white bg-red-500 hover:bg-red-600" mat-flat-button type="submit">
                        Save
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>