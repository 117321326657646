
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';

import { ColorService } from 'app/colors/color.service';
import { productCategoryService } from 'app/product-categories/product-categories.service';
import { FabricService } from 'app/product-fabric/fabric-service.service';
import { productsGroupService } from 'app/product-group/product-group.service';
import { SizeService } from 'app/size/size.service';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import Swal from 'sweetalert2';


@Component({


  selector: 'app-create-prize-list',
  templateUrl: './create-prize-list.component.html',
  styleUrls: ['./create-prize-list.component.scss']
})
export class



  CreatePrizeListComponent implements OnInit {
  isLoading = false;




  horizontalStepperForm: UntypedFormGroup;
  colorForm: FormGroup;
  sizeForm: FormGroup;
  categoryForm: FormGroup;
  collectionForm: FormGroup;
  fabricForm: FormGroup;
  color$: Observable<any[]>;
  size$: Observable<any[]>;
  catrogry$: Observable<any[]>;
  fabric$: Observable<any[]>;
  collection$: Observable<any[]>;

  size: any;
  colors: any;
  category: any;
  collection: any;
  fabric: any;

  step1Form: FormGroup;
  step2Form: FormGroup;
  step3Form: FormGroup;
  step4Form: FormGroup;
  step5Form: FormGroup;
  formFieldHelpers: any;




  constructor(

    private _formBuilder: FormBuilder,
    private _colorService: ColorService,
    private _sizeServic: SizeService,
    private _categoryService: productCategoryService,
    private _collectionService: productsGroupService,
    private _fabricService: FabricService

  ) {

  }

  ngOnInit() {


    this.colorForm = this._formBuilder.group({
      name: ['', Validators.required],
      sku: ['', Validators.required]

    });

    this.sizeForm = this._formBuilder.group({
      name: ['', Validators.required],
      sku: ['', Validators.required]

    });

    this.categoryForm = this._formBuilder.group({
      category_name: ['', Validators.required],

    });

    this.collectionForm = this._formBuilder.group({
      collection_name: ['', Validators.required],
      sku: ['', Validators.required]

    });

    this.fabricForm = this._formBuilder.group({
      name: ['', Validators.required],
      sku: ['', Validators.required]

    });






  }

  saveStep1() {
    this.isLoading = true;

    // Get the form data
    const formData = this.colorForm.value;

    // Check if color name and SKU already exist
    this._colorService.getColors().pipe(take(1)).subscribe(
      (colors) => {
        this.colors = colors['data'];

        const colorNameExists = this.colors.some((color) => color.name === formData.name);
        const skuExists = this.colors.some((color) => color.sku === formData.sku);

        if (colorNameExists) {
          this.showErrorMessage('Color name already exists!');
          this.isLoading = false;
        } else if (skuExists) {
          this.showErrorMessage('Color SKU already exists!');
          this.isLoading = false;
        } else {
          this.createColor(formData);
        }
      },
      (error) => {
        console.error('Error checking color existence', error);
        this.showErrorMessage('Error checking color existence');
        this.isLoading = false;
      }
    );
  }

  saveStep2() {
    this.isLoading = true;
    this.size$ = this._sizeServic
      .getSizes()
      .pipe(take(1));
  
    this.size$.subscribe(
      (size) => {
        this.size = size['data'];
        const formData = this.sizeForm.value;
  
        const nameExists = this.size.some((size) => size.name === formData.name);
        const skuExists = this.size.some((size) => size.sku === formData.sku);
  
        if (nameExists) {
          this.showErrorMessage('Size name already exists!');
          this.isLoading = false;
        } else if (skuExists) {
          this.showErrorMessage('Size SKU already exists!');
          this.isLoading = false;
        } else {
          this.createSize(formData);
        }
      },
      (error) => {
        console.error('Error checking size existence', error);
        this.showErrorMessage('Error checking size existence');
        this.isLoading = false;
      }
    );
  }
  
  saveStep3() {
    this.isLoading = true;
    this.catrogry$ = this._categoryService
      .getproducts()
      .pipe(take(1));
  
    this.catrogry$.subscribe(
      (category) => {
        this.category = category['data'];
        const formData = this.categoryForm.value;
  
        const nameExists = this.category.some((category) => category.category_name === formData.category_name);
        const skuExists = this.category.some((category) => category.sku === formData.sku);
  
        if (nameExists) {
          this.showErrorMessage('Category name already exists!');
          this.isLoading = false;
        } else if (skuExists) {
          this.showErrorMessage('Category SKU already exists!');
          this.isLoading = false;
        } else {
          this.createCategory(formData);
        }
      },
      (error) => {
        console.error('Error checking Category existence', error);
        this.showErrorMessage('Error checking Category existence');
        this.isLoading = false;
      }
    );
  }
  
  saveStep4() {
    this.isLoading = true;
    this.collection$ = this._collectionService
      .getproducts()
      .pipe(take(1));
  
    this.collection$.subscribe(
      (collection) => {
        this.collection = collection['data'];
        const formData = this.collectionForm.value;
  
        const nameExists = this.collection.some((collection) => collection.collection_name === formData.collection_name);
        const skuExists = this.collection.some((collection) => collection.sku === formData.sku);
  
        if (nameExists) {
          this.showErrorMessage('Collection name already exists!');
          this.isLoading = false;
        } else if (skuExists) {
          this.showErrorMessage('Collection SKU already exists!');
          this.isLoading = false;
        } else {
          this.createCollection(formData);
        }
      },
      (error) => {
        console.error('Error checking Collection existence', error);
        this.showErrorMessage('Error checking Collection existence');
        this.isLoading = false;
      }
    );
  }
  
  saveStep5() {
    this.isLoading = true;
    this.fabric$ = this._fabricService
      .getfabrics()
      .pipe(take(1));
  
    this.fabric$.subscribe(
      (fabric) => {
        this.fabric = fabric['data'];
        const formData = this.fabricForm.value;
  
        const nameExists = this.fabric.some((fabric) => fabric.name === formData.name);
        const skuExists = this.fabric.some((fabric) => fabric.sku === formData.sku);
  
        if (nameExists) {
          this.showErrorMessage('Fabric name already exists!');
          this.isLoading = false;
        } else if (skuExists) {
          this.showErrorMessage('Fabric SKU already exists!');
          this.isLoading = false;
        } else {
          this.createFabric(formData);
        }
      },
      (error) => {
        console.error('Error checking Fabric existence', error);
        this.showErrorMessage('Error checking Fabric existence');
        this.isLoading = false;
      }
    );
  }
  








  private createColor(formData: any) {
    // Create the color
    this._colorService.createColor(formData).then(
      () => {
        this.showSuccessMessage('Color has been created!!');
        this.isLoading = false;
        this.colorForm.reset();
      },
      (error) => {
        // Error while creating color, show error message
        this.showErrorMessage(`Error creating Color: ${error.message}`);
        this.isLoading = false;
      }
    );
  }

  private createSize(formData: any) {
    this._sizeServic.createSize(formData).then(
      () => {
        // Color created successfully, show success message
        this.showSuccessMessage('Size has been created!!');
        this.isLoading = false;
        this.sizeForm.reset();
      },
      (error) => {
        // Error while creating color, show error message
        this.showErrorMessage(`Error creating Siz: ${error.message}`);
        this.isLoading = false;
      }
    );
  }

  private createFabric(formData: any) {
    this._fabricService.createfabric(formData).then(
      () => {
        // Color created successfully, show success message
        this.showSuccessMessage('Fabric has been created!!');
        this.isLoading = false;
        this.fabricForm.reset();
      },
      (error) => {
        // Error while creating color, show error message
        this.showErrorMessage(`Error creating Fabric: ${error.message}`);
        this.isLoading = false;
      }
    );
  }
  private createCollection(formData: any) {
    this._collectionService.createproductss(formData).then(
      () => {
        // Color created successfully, show success message
        this.showSuccessMessage('Collection has been created!!');
        this.isLoading = false;
        this.collectionForm.reset();
      },
      (error) => {
        // Error while creating color, show error message
        this.showErrorMessage(`Error creating Collection: ${error.message}`);
        this.isLoading = false;
      }
    );
  }

  private createCategory(formData: any) {
    this._categoryService.createproductss(formData).then(
      () => {
        // Color created successfully, show success message
        this.showSuccessMessage('Category has been created!!');
        this.isLoading = false;
        this.categoryForm.reset();
      },
      (error) => {
        // Error while creating color, show error message
        this.showErrorMessage(`Error creating Siz: ${error.message}`);
        this.isLoading = false;
      }
    );
  }

  private showSuccessMessage(message: string) {
    // Using SweetAlert to show success message
    Swal.fire({
        title: message,
        width: 600,
        padding: "3em",
        color: "#fff",
        background: "#fff url(/assets/free2.png)", // Adjusting image to fit properly
        backdrop: `
            rgba(0,123,0,0.4) 
        `
    });
}

private showErrorMessage(message: string) {
    // Using SweetAlert to show error message
    Swal.fire({
        title: message,
        width: 600,
        padding: "3em",
        color: "#fff",
        background: "#fff url(/assets/free.png)", // Adjusting image to fit properly
        backdrop: `
            rgba(123,0,0,0.4) 
        `
    });
}



  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }



  getFormFieldHelpersAsString(): string {
    return this.formFieldHelpers.join(' ');
  }

}


