import { Route } from '@angular/router';

import { ListproductCategoriesComponent } from './listproduct-categories/listproduct-categories.component';
import { CreateproductCategoriesComponent } from './createproduct-categories/createproduct-categories.component';
import { DetailproductCategoriesComponent } from './detailproduct-categories/detailproduct-categories.component';



export const ProductCategoriesRoutes: Route[] = [
   
        {
            path     : '',
            component: ListproductCategoriesComponent,
        },
        {
            path         : 'NEW',
            component    : CreateproductCategoriesComponent,
           
        },
        {
            path         : ':id',
            component    : DetailproductCategoriesComponent,
          
          
        },
]
