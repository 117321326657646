<div id="list">


    <div class="sm:absolute sm:inset-0 flex flex-col flex-auto min-w-0 sm:overflow-hidden bg-card dark:bg-transparent">
        <!-- Header -->
        <div
            class="relative flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between py-8 px-6 md:px-8 border-b">


            <!-- Loader -->
            <div class="absolute inset-x-0 bottom-0" *ngIf="isLoading">
                <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
            </div>

            <!-- Title -->
            <div class="text-4xl font-bold tracking-tight " style='font-family:"Montserrat";'>
                Pr Packages
            </div>

            <!-- Actions -->
            <div class="flex flex-shrink-0 items-center mt-6 sm:mt-0 sm:ml-4">
                <!-- Search -->

                <!-- Add product button -->
                <button class="ml-4 mr-4" mat-flat-button [color]="'primary'" (click)="createSalesExchange()">
                    <mat-icon matTooltip="Add new Sales Order" [svgIcon]="'heroicons_outline:plus'"></mat-icon>
                    <span class="ml-2 mr-1">Add</span>
                </button>

                <button [color]="'primary'" mat-flat-button (click)="multiplePackage()">
                    <mat-icon matTooltip="Ship Your Order" [svgIcon]="'heroicons_outline:clipboard'"></mat-icon>
                    <span class="ml-2 mr-1">Shipment </span>
                </button>

            </div>

        </div>
        <div class="flex flex-shrink-0 items-center mt-6 sm:mt-0 sm:ml-4">


            <div>


                <button [matMenuTriggerFor]="menu" mat-flat-button [color]="'primary'">
                    Action
                    <mat-icon svgIcon="feather:chevron-down"></mat-icon>
                </button>

                <mat-menu #menu>
                    <button (click)="DeliverySheet()"
                        class="text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" mat-menu-item>
                        Delivery Sheet
                    </button>
                


                </mat-menu>
            </div>


            <div class="flex flex-shrink-0 items-center ml-3 mt-3">
                <mat-form-field style="min-width:200px;" class="flex-auto fuse-mat-emphasized-affix fuse-mat-rounded ">
                    <input matInput [matDatepicker]="picker" placeholder="Select a date" [(ngModel)]="selectedDate"
                        (ngModelChange)="applyFilters()">
                    <mat-datepicker-toggle matSuffix [for]="picker" class="smaller-datepicker-toggle">
                    </mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>


                </mat-form-field>



                <mat-form-field style="min-width: 200px;"
                    class="flex-auto justify-between ml-3 fuse-mat-rounded fuse-mat-emphasized-affix">
                    <mat-select style="font-family:Montserrat;font-size: smaller;" [(ngModel)]="selectedShippingArea"
                        (ngModelChange)="applyFilters()" placeholder="Shipping Area">
                        <mat-option  value="">----</mat-option>
                        <mat-option  value="Inside Valley">INSIDE
                            VALLEY</mat-option>
                        <mat-option  value="Outside Valley">OUTSIDE
                            VALLEY</mat-option>
                        <mat-option  value="International">
                            INTERNATIONAL</mat-option>
                    </mat-select>
                    <mat-icon class="icon-size-4" matPrefix [svgIcon]="'feather:globe'"></mat-icon>
                </mat-form-field>

                <mat-form-field style="min-width: 200px;"
                    class="flex-auto ml-3 fuse-mat-emphasized-affix fuse-mat-rounded">
                    <mat-select style="font-family:Montserrat;font-size: smaller;" [(ngModel)]="selectedDeliveryType"
                        (ngModelChange)="applyFilters()" placeholder="Delivery Type">
                        <mat-option  value="">----</mat-option>
                        <mat-option value="Normal">Normal
                        </mat-option>
                        <mat-option  value="Express">Express
                        </mat-option>
                    </mat-select>

                    <mat-icon class="icon-size-4" matPrefix [svgIcon]="'feather:truck'"></mat-icon>
                </mat-form-field>

                <mat-form-field style="min-width: 320px;"
                    class="flex-auto ml-3 fuse-mat-emphasized-affix fuse-mat-rounded">
                    <mat-icon matPrefix [svgIcon]="'heroicons_outline:search'"></mat-icon>
                    <input style="font-family:Montserrat" matInput [autocomplete]="'off'" (keyup)="applyFilter($event)"
                        [placeholder]="'Search Pr Packages'" />
                </mat-form-field>
            </div>

        </div>

        <!-- Main -->
        <div class="flex flex-auto overflow-hidden">

            <!-- Products list -->
            <div class="flex flex-col flex-auto sm:mb-18 overflow-hidden sm:overflow-y-auto">
                <div class="mat-elevation-z8">
                    <table id="excel-table" #salesOrderTabel mat-table [dataSource]="dataSource" matSort matPaginator
                        class="mat-table">
                        <!-- InfluencerName Column -->
                        <ng-container matColumnDef="select">
                            <th mat-header-cell *matHeaderCellDef>
                                <mat-checkbox (change)="$event ? toggleAllRows() : null"
                                    [checked]="selection.hasValue() && isAllSelected()"
                                    [indeterminate]="selection.hasValue() && !isAllSelected()"
                                    [aria-label]="checkboxLabel()">
                                </mat-checkbox>
                            </th>
                            <td mat-cell *matCellDef="let row">
                                <mat-checkbox (click)="$event.stopPropagation()"
                                    (change)="$event ? updateSelectedRowIds(row) : null"
                                    [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
                                </mat-checkbox>
                            </td>
                        </ng-container>


                        <ng-container matColumnDef="InfluencerId">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                ID
                            </th>
                            <td mat-cell *matCellDef="let row" class="cursor-pointer">
                                <div class="avatar" >

                                    <i>
                                        <strong>
                                            INF{{ row.customerId ? row.customerId : '--' }}
                                        </strong>
                                    </i>
                                </div>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="InfluencerName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                Name
                            </th>
                            <td mat-cell *matCellDef="let row" class="cursor-pointer">
                                <div class="avatar" >

                                    {{ row.customerName }}
                                </div>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="SaleNumber">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                S.No
                            </th>
                            <mat-icon svgIcon="heroicons_outline:user"></mat-icon>
                            <div></div>
                            <td mat-cell *matCellDef="let row">
                                <i>
                                    <u>
                                        {{ row.saleNumber }}
                                    </u>
                                </i>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Shipping_Date">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                Date
                            </th>
                            <td mat-cell *matCellDef="let row">
                                <div class="status" [ngClass]="getStatusColor(row)">
                                    <i>
                                        <u>
                                            {{ row.shippingDate }}
                                        </u>
                                    </i>

                                </div>
                            </td>
                        </ng-container>

                        <!-- Status Column -->
                        <ng-container matColumnDef="Status">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                Status
                            </th>
                            <td mat-cell *matCellDef="let row">
                                <div class="avatar">

                                    {{ row.status }}
                                </div>
                            </td>
                        </ng-container>

                        <!-- OrderShipped Date Column -->

                        <ng-container matColumnDef="Shipping Area">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                Shipping Area
                            </th>
                            <mat-icon svgIcon="heroicons_outline:user"></mat-icon>
                            <div></div>
                            <td mat-cell *matCellDef="let row">

                                {{ row.shipping_area ? row.shipping_area:"--"}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Delivery Type">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                Delivery Type
                            </th>
                            <td mat-cell *matCellDef="let row">
                                {{ row.delivery_type ? row.delivery_type:"--"}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="total">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                Total Price
                            </th>
                            <td mat-cell *matCellDef="let row">
                                <div class="status" [ngClass]="getStatusColor(row)">
                                    NPR <strong>
                                        {{ row.totalCost }}
                                    </strong>
                                </div>
                            </td>
                        </ng-container>


                        <ng-container matColumnDef="Shipping Carrier">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                Shipping Carrier </th>
                            <td mat-cell *matCellDef="let row">
                                <div>
                                    <strong>
                                        {{ row.shipping_carrier }}
                                    </strong>
                                </div>
                            </td>
                        </ng-container>


                        <!-- Actions Column -->
                        <ng-container matColumnDef="actions">
                            <th mat-header-cell *matHeaderCellDef>Actions</th>
                            <td mat-cell *matCellDef="let row">
                                <button mat-icon-button matTooltip="View" (click)="showDetails(row.id)">
                                    <mat-icon class="icon-size-4" svgIcon="heroicons_outline:eye"></mat-icon>
                                </button>

                                <button mat-icon-button matTooltip="Void" *ngIf="row.status === 'Started Sales Order'"
                                    (click)="deleteVendorsCredit(row.id)">
                                    <mat-icon class="icon-size-4" svgIcon="heroicons_outline:trash"></mat-icon>
                                </button>
                            </td>
                        </ng-container>
                        <!-- create    -->
                        <ng-container matColumnDef="create">
                            <th mat-header-cell *matHeaderCellDef>Create</th>
                            <td mat-cell *matCellDef="let row">

                                <button mat-icon-button mat-flat-button *ngIf="row.status != 'Sales Order Delivered'"
                                    [matMenuTriggerFor]="menu">
                                    <mat-icon svgIcon="feather:chevron-down"></mat-icon>
                                </button>

                                <mat-menu class="rounded" #menu>
                                    <button *ngIf="row.status === 'Started Sales Order'" (click)="singlePackage(row.id)"
                                        class="text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                        mat-menu-item>
                                        Pack and Ship
                                    </button>
                                    <button *ngIf="row.status === 'Started Sales Order'"
                                        (click)="changeShippingCarrier(row.id,row.shipping_area)"
                                        class="text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                        mat-menu-item>
                                        Change Carrier
                                    </button>
                                    <button class="text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                        mat-menu-item (click)="printInvoice(row.id)">
                                        Instant Invoice
                                    </button>
                                </mat-menu>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                    </table>
                    <!-- Footer Row for Statistics -->
                    <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
                    <tr class="total-row">
                        <i>
                            <td colspan="3" class="total-cell">Total: NPR {{ calculateTotal() }}</td>
                        </i>
                        <td class="total-cell"> </td>

                    </tr>



                    <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- sales-order-invoice.html -->
<ng-template id="printable-content">
    <div *ngFor="let vendor of vendors; let i = index" class="print-section" [id]="'print-section-' + i">
      <app-invoice [vendor]="vendor"></app-invoice>
    </div>
</ng-template>
  