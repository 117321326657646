import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OptionSubmissionService {
  private submittedOptions: string[] = [];
  private formDataSubject = new BehaviorSubject<any>(null);

  isOptionSubmitted(option: string): boolean {
    return this.submittedOptions.includes(option);
  }

  addOptionSumbitted(option: string): void {
    
    this.submittedOptions.push(option)
    this.emitFormData();
  }

  submitOption(option: string): void {
    this.submittedOptions.push(option);
    this.emitFormData();
  }

  deleteOption(option: string): void {
    const index = this.submittedOptions.indexOf(option);
    if (index !== -1) {
      this.submittedOptions.splice(index, 1);
      this.emitFormData();
    }
  }

  private emitFormData(): void {
    // Logic to update the form data if needed
    const formData = {}; // Update this with your form data logic
    this.formDataSubject.next(formData);
  }

  clearSubmittedOptions(): void {
    this.submittedOptions = [];
    this.emitFormData();
  }

  getFormDataObservable(): Observable<any> {
    return this.formDataSubject.asObservable();
  }
}