import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, OnInit, Output, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FuseCardComponent } from '@fuse/components/card';
import { ConfirmPopupComponent } from 'app/confirm-popup/confirm-popup.component';
import { COMMA, ENTER } from '@angular/cdk/keycodes';


import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { map, startWith, take } from 'rxjs/operators';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatChipInputEvent } from '@angular/material/chips';
import { OptionSubmissionService } from '../product-options-popup/product.options.service';
import Swal from 'sweetalert2';

export interface User {
  name: string;
}
@Component({
  selector: 'app-products-popup',
  templateUrl: './product-popup.component.html',

})
export class ProductPopupComponent implements OnInit {
  showMessage: boolean = false;
  @Output() formSubmitted: EventEmitter<any> = new EventEmitter<any>();


  productOption_popup: FormGroup;
  addOnBlur = true;
  @ViewChild('fuseCard') fuseCard: FuseCardComponent;
  myControl = new FormControl<string | User>('');


  separatorKeysCodes: number[] = [ENTER, COMMA];
  colorsCtrl = new FormControl('');
  sizesCtrl = new FormControl('');

  filteredcolors: Observable<string[]>;


  selectedOption: string = 'color'; // Set a default option
  showColorPicker: boolean = false;
  showColor: boolean = false;
  showSizes: boolean = false;
  selectedColor: string = "";
  allcolors: string[] = [''];
  allsizes: string[] = [''];
  sizes: string[] = [];
  colors: string[] = [];
  submittedOptions: string[] = [];

  // Initialize with an empty string
  filteredsizes: Observable<string[]>; // Add filtered sizes
  @ViewChild('colorsInput') colorsInput: ElementRef<HTMLInputElement>;
  @ViewChild('sizesInput') sizesInput: ElementRef<HTMLInputElement>;

  // Add this property to your component class
  disableColorOption: boolean = false;
  disableSizeOption: boolean = false;

  announcer = inject(LiveAnnouncer);
  constructor(
    public optionSubmissionService: OptionSubmissionService,
    private _matDialog: MatDialogRef<ConfirmPopupComponent>,
    private _formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cdRef: ChangeDetectorRef

  ) {

    this.filteredcolors = this.colorsCtrl.valueChanges.pipe(
      startWith(null),
      map((colors: string | null) => (colors ? this._filter_chips(colors) : this.allcolors.slice())),
    );
    this.filteredsizes = this.sizesCtrl.valueChanges.pipe(
      startWith(null),
      map((size: string | null) => (size ? this._filter_chips_sizes(size) : this.allsizes.slice())),
    );
  }


  ngOnInit(): void {

    this.productOption_popup = this._formBuilder.group({
      secondary: [''],
      inner: this.colorsCtrl,
      sizes: this.sizesCtrl,

    });
    this.selectedOption = 'secondary'; // You can set a default here



  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value.trim();

    if (!value) {
      return;
    }

    // Check if the value matches any of the autocomplete options exactly
    this.filteredcolors.pipe(take(1)).subscribe(colors => {
      const exactMatch = colors.find(option => option === value);

      // Add the input value as a chip only if it's not already in the colors array and is not an exact match
      if (!this.colors.includes(value) && !exactMatch) {
        this.colors.push(value);
      }

      // Reset the input value
      if (input) {
        input.value = '';
      }

      this.colorsCtrl.setValue(null); // Clear the autocomplete input
    });
  }

  addSizes(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value.trim();

    if (value && !this.sizes.includes(value)) {
      this.sizes.push(value);
    }

      // Reset the input value
      if (input) {
        input.value = '';
      }

      this.sizesCtrl.setValue(null); // Clear the autocomplete input
    
  }




  removeSizes(sizes: string): void {
    const index = this.sizes.indexOf(sizes);

    if (index >= 0) {
      this.sizes.splice(index, 1);

      this.announcer.announce(`Removed ${sizes}`);
    }
  }

  remove(colors: string): void {
    const index = this.colors.indexOf(colors);

    if (index >= 0) {
      this.colors.splice(index, 1);

      this.announcer.announce(`Removed ${colors}`);
    }
  }




  selectedSizes(event: MatAutocompleteSelectedEvent): void {
    const selectedSize = event.option.viewValue;
  
    // Check if the selected size exists in the available options
    const isSizeExists = this.allsizes.includes(selectedSize);
  
    // Add the selected size as a chip only if it exists in the available options and is not already in the sizes array
    if (isSizeExists && !this.sizes.includes(selectedSize)) {
      this.sizes.push(selectedSize);
      this.sizesInput.nativeElement.value = ''; // Clear the input after selection
    }
  }
  


  selected(event: MatAutocompleteSelectedEvent): void {
    const selectedColor = event.option.viewValue;

    if (!this.colors.includes(selectedColor)) {
      this.colors.push(selectedColor);
      this.colorsInput.nativeElement.value = ''; // Clear the input after selection
    }
  }




  private _filter_chips(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allcolors.filter(colors => colors.toLowerCase().includes(filterValue));
  }

  private _filter_chips_sizes(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allsizes.filter(sizes => sizes.toLowerCase().includes(filterValue));
  }


  submitForm(formData: any): void {
    const selectedOption = formData.option_name;
    if (!this.optionSubmissionService.isOptionSubmitted(selectedOption)) {
      // Add the submitted option using the service
      this.optionSubmissionService.submitOption(selectedOption);

      // Disable the "Color" option after submitting it
      if (selectedOption === 'Color') {
        this.disableColorOption = true; // Add this property to your component
      }
      if (selectedOption === 'Size') {
        this.disableSizeOption = true; // Add this property to your component
      }

      // Check the selected option and update the form data accordingly
      if (selectedOption === 'Color') {
        formData.sizes = null; // Clear the sizes data if not needed
        formData.color = this.colors;
      } else if (selectedOption === 'Size') {
        formData.sizes = this.sizes;
        formData.color = null; // Clear the color data if not needed
      }

      // Emit the form data
      this.formSubmitted.emit(formData);

      // Close the modal
      this._matDialog.close();
    } else {
      // Option has already

      const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener(
            'mouseenter',
            Swal.stopTimer
          );
          toast.addEventListener(
            'mouseleave',
            Swal.resumeTimer
          );
        },
      });

      Toast.fire({
        icon: 'error',
        title: `This option is already selcted`,
      });
    }
  }


  // Emit the form data


  cancel(): void {
    this._matDialog.close();
  }


  pointOfsales(): void {
    const type = this.productOption_popup.controls['option_name'].value;
    console.log(type, 'check if data is coming');

    if (type === 'Color' && !this.submittedOptions.includes('Color')) {
      this.showColor = true;
      this.showSizes = false;
    } else if (type === 'Size' && !this.submittedOptions.includes('Size')) {
      this.showSizes = true;
      this.showColor = false;
    }
  }
}
