import { Route } from '@angular/router';


import { ListvendorsExpenseComponent } from './listvendors-expense/listvendors-expense.component';
import { CreatevendorsExpenseComponent } from './createvendors-expense/createvendors-expense.component';
import { DetailvendorsExpenseComponent } from './detailvendors-expense/detailvendors-expense.component';



export const VendorsExpenseRouting: Route[] = [
   
        {
            path     : '',
            component: ListvendorsExpenseComponent,
        },
        {
            path         : 'NEW',
            component    : CreatevendorsExpenseComponent,
           
        },
        {
            path         : ':id',
            component    :DetailvendorsExpenseComponent,
          
          
        },
]
