<mat-dialog-content class="dialog-content">
  <form [formGroup]="customer_popup" (ngSubmit)="submitForm(customer_popup.value)">

    <!-- Avatar -->
    <!-- Avatar code goes here -->

    <!-- Personal Information Form -->
    <div>
      <div class="prose prose-sm max-w-3xl">
      </div>
      
      <div class="flex flex-col mt-8 p-8 pb-5 bg-card rounded shadow overflow-hidden">
        <p class="text-lg font-medium">Create Customer</p>
        <p class="text-secondary">
          You can add basic customer detail for detailed customer creation. Visit the customer page.
        </p>
        <mat-divider class="mt-8 mb-10"></mat-divider>
        
        <div class="flex">
          <mat-form-field class="flex-auto">
            <mat-label>First name</mat-label>
            <input matInput formControlName="firstName" placeholder="First Name" name="firstName">
            <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:mail'"></mat-icon>
          </mat-form-field>
        </div>

        <div class="flex">
          <mat-form-field class="flex-auto">
            <mat-label>Last name</mat-label>
            <input formControlName="lastName" matInput placeholder="Last Name" name="lastName">
            <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:mail'"></mat-icon>
          </mat-form-field>
        </div>

        <div class="flex flex-col gt-xs:flex-row">
          <mat-form-field class="flex-auto gt-xs:pl-3">
            <mat-label>Gender</mat-label>
            <mat-select formControlName="gender" placeholder="Gender" name="gender">
              <mat-option value="Male">Male</mat-option>
              <mat-option value="Female">Female</mat-option>
              <mat-option value="Others">Others</mat-option>
            </mat-select>
            <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:translate'"></mat-icon>
          </mat-form-field>
        </div>
        <div class="flex">
          <mat-form-field class="flex-auto">
            <mat-label>Email</mat-label>
            <input type="email" formControlName="emails" matInput placeholder="emails" name="emails">
            <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:mail'"></mat-icon>
          </mat-form-field>
        </div>

        <div class="flex items-center justify-end mt-10">
          <button class="px-6 ml-3" mat-flat-button type="submit" color="primary">
            Save
          </button>
          <button (click)="cancel()" class="px-6 ml-3" mat-flat-button type="button" color="error">
            Cancel
          </button>
        </div>
      </div>
    </div>

  </form>
</mat-dialog-content>
