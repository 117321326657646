import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { Influencer, Country, Tag } from 'app/modules/Influencer/infulencer.type';
import { server_env } from 'config';

@Injectable({
    providedIn: 'root'
})
export class InfluencerService {

    // Private
    private _Influencer: BehaviorSubject<Influencer | null> = new BehaviorSubject(null);
    private _Influencers: BehaviorSubject<Influencer[] | null> = new BehaviorSubject(null);
    private _countries: BehaviorSubject<Country[] | null> = new BehaviorSubject(null);
    private _tags: BehaviorSubject<Tag[] | null> = new BehaviorSubject(null);
    public url = `${server_env.URL}api/v1/influencer/`;
    public url2 = `${server_env.URL}api/v1/influencer-report/`;

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for Influencer
     */
    get Influencer$(): Observable<Influencer> {
        return this._Influencer.asObservable();
    }

    /**
     * Getter for Influencers
     */
    get Influencers$(): Observable<Influencer[]> {
        return this._Influencers.asObservable();
    }

    /**
     * Getter for countries
     */
    get countries$(): Observable<Country[]> {
        return this._countries.asObservable();
    }

    /**
     * Getter for tags
     */
    get tags$(): Observable<Tag[]> {
        return this._tags.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get Influencers
     */

    getInfluencers(): Observable<Influencer[]> {
        return this._httpClient.get<Influencer[]>(`${this.url}`).pipe(
            tap((Influencers: any) => {
           
                this._Influencers.next(Influencers.data);
            })
        );
    }
    getInfluencerReport(id: number): Observable<any> {
        return this._httpClient.get<any>(`${this.url2}${id}/`);
    }

    downloadInfluencerReportExcel(id: number): Observable<Blob> {
        return this._httpClient.get<Blob>(`${this.url2}excel/${id}/`, {
            responseType: 'blob' as 'json'
        });
    }

    downloadInfluencerReportPDF(id: number): Observable<Blob> {
        return this._httpClient.get<Blob>(`${this.url2}pdf/${id}/`, {
            responseType: 'blob' as 'json'
        });
    }
    /**
     * Search Influencers with given query
     *
     * @param query
     */
    searchInfluencers(query: string): Observable<Influencer[]> {
        return this._httpClient.get<Influencer[]>('api/apps/Influencers/search', {
            params: { query }
        }).pipe(
            tap((Influencers) => {
                this._Influencers.next(Influencers);
            })
        );
    }

    /**
     * Get Influencer by id
     */
    getInfluencerById(id: string): Observable<Influencer> {

        const requestUrl = this.url + id;

        return this._httpClient.get<Influencer[]>(`${requestUrl}`).pipe(
            take(1),
            map((Influencer: any) => {
                this._Influencer.next(Influencer.data);

                // Return the Influencer
                return Influencer.data;
            }),
            switchMap((Influencer) => {

                if (!Influencer) {
                    return throwError('Could not found Influencer with id of ' + id + '!');
                }

                return of(Influencer);
            })
        );
    }

    /**
     * Create Influencer
     */
    createInfluencer(data: any): Observable<Influencer> {
        return this.Influencers$.pipe(
            take(1),
            switchMap(Influencers => this._httpClient.post<Influencer>(`${this.url}`, {}).pipe(
                map((newInfluencer) => {

                    // Update the Influencers with the new Influencer
                    this._Influencers.next([newInfluencer, ...Influencers]);

                    // Return the new Influencer
                    return newInfluencer;
                })
            ))
        );
    }


    createInf(data): Promise<any> {

        return new Promise((resolve, reject) => {

            const requestUrl = this.url;

            this._httpClient.post<any>(`${requestUrl}`, data).subscribe(response => {
                return resolve(response);
            }, error => {
                return reject(error)
            });

        });

    }

    /**
     * create customer
     * @param data 
     * @returns 
     */

    createInfluencers(data): Promise<any> {

        return new Promise((resolve, reject) => {

            const requestUrl = this.url;

            this._httpClient.post<any>(`${requestUrl}`, data).subscribe(response => {
                return resolve(response);
            }, error => {
                return reject(error)
            });

        });

    }
    /**
* Update Contact
*
* @param id
* @param influencerData
*/
    updateInfluencer(id: string, influencerData: any): Observable<Influencer> {

        return this.Influencers$.pipe(
            take(1),
            switchMap(Contacts =>
                this._httpClient.put(`${this.url}${id}/`, influencerData).pipe(
                    map((updatedContact) => {

                        // Find the index of the updated Contact
                        const index = Contacts.findIndex(item => item.id === id);
                        // Update the Contact
                        Contacts[index] = updatedContact;

                        // Update the Contacts
                        this._Influencers.next(Contacts);

                        // Return the updated Contact
                        return updatedContact;
                    }),
                    switchMap(updatedContact =>
                        this.Influencer$.pipe(
                            take(1),
                            filter(item => item && item.id === id),
                            tap(() => {
                                // Update the Contact if it's selected
                                this._Influencer.next(updatedContact);
                            })
                        )
                    )
                )
            )
        );
    }
    /**
     * Delete the contact
     *
     * @param id
     */
    deleteInfluencer(id: string): Observable<boolean> {
        return this.Influencers$.pipe(
            take(1),
            switchMap(influencer => this._httpClient.delete(`${this.url}${id}/`,).pipe(
                map((isDeleted: boolean) => {

                    // Find the index of the deleted contact
                    const index = influencer.findIndex(item => item.id === id);

                    // Delete the contact
                    influencer.splice(index, 1);

                    // Update the contacts
                    this._Influencers.next(influencer);

                    // Return the deleted status
                    return isDeleted;
                })
            ))
        );
    }
    /**
     * Get countries
     */
    getCountries(): Observable<Country[]> {
        return this._httpClient.get<Country[]>('api/apps/contacts/countries').pipe(
            tap((countries) => {
                this._countries.next(countries);
            })
        );
    }

    /**
     * Get tags
     */
    getTags(): Observable<Tag[]> {
        return this._httpClient.get<Tag[]>('api/apps/contacts/tags').pipe(
            tap((tags) => {
                this._tags.next(tags);
            })
        );
    }

    /**
     * Create tag
     *
     * @param tag
     */
    createTag(tag: Tag): Observable<Tag> {
        return this.tags$.pipe(
            take(1),
            switchMap(tags => this._httpClient.post<Tag>('api/apps/contacts/tag', { tag }).pipe(
                map((newTag) => {

                    // Update the tags with the new tag
                    this._tags.next([...tags, newTag]);

                    // Return new tag from observable
                    return newTag;
                })
            ))
        );
    }

    /**
     * Update the tag
     *
     * @param id
     * @param tag
     */
    updateTag(id: string, tag: Tag): Observable<Tag> {
        return this.tags$.pipe(
            take(1),
            switchMap(tags => this._httpClient.patch<Tag>('api/apps/contacts/tag', {
                id,
                tag
            }).pipe(
                map((updatedTag) => {

                    // Find the index of the updated tag
                    const index = tags.findIndex(item => item.id === id);

                    // Update the tag
                    tags[index] = updatedTag;

                    // Update the tags
                    this._tags.next(tags);

                    // Return the updated tag
                    return updatedTag;
                })
            ))
        );
    }

    /**
     * Delete the tag
     *
     * @param id
     */
    deleteTag(id: string): Observable<boolean> {
        return this.tags$.pipe(
            take(1),
            switchMap(tags => this._httpClient.delete('api/apps/contacts/tag', { params: { id } }).pipe(
                map((isDeleted: boolean) => {

                    // Find the index of the deleted tag
                    const index = tags.findIndex(item => item.id === id);

                    // Delete the tag
                    tags.splice(index, 1);

                    // Update the tags
                    this._tags.next(tags);

                    // Return the deleted status
                    return isDeleted;
                }),
                filter(isDeleted => isDeleted),
                switchMap(isDeleted => this.Influencers$.pipe(
                    take(1),
                    map((Influencers) => {

                        // Iterate through the Influencers
                        Influencers.forEach((Influencer) => {

                            const tagIndex = 1;

                            // If the Influencer has the tag, remove it
                            if (tagIndex > -1) {

                            }
                        });

                        // Return the deleted status
                        return isDeleted;
                    })
                ))
            ))
        );
    }

    /**
     * Update the avatar of the given Influencer
     *
     * @param id
     * @param avatar
     */
    uploadAvatar(id: string, avatar: File): Observable<Influencer> {
        return this.Influencers$.pipe(
            take(1),
            switchMap(Influencers => this._httpClient.post<Influencer>('api/apps/contacts/avatar', {
                id,
                avatar
            }, {
                headers: {
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    'Content-Type': avatar.type
                }
            }).pipe(
                map((updatedInfluencer) => {

                    // Find the index of the updated Influencer
                    const index = Influencers.findIndex(item => item.id === id);

                    // Update the Influencer
                    Influencers[index] = updatedInfluencer;

                    // Update the Influencers
                    this._Influencers.next(Influencers);

                    // Return the updated Influencer
                    return updatedInfluencer;
                }),
                switchMap(updatedInfluencer => this.Influencer$.pipe(
                    take(1),
                    filter(item => item && item.id === id),
                    tap(() => {

                        // Update the Influencer if it's selected
                        this._Influencer.next(updatedInfluencer);

                        // Return the updated Influencer
                        return updatedInfluencer;
                    })
                ))
            ))
        );
    }
}
