import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrizeListComponent } from './prize-list/prize-list.component';
import { CreatePrizeListComponent } from './create-prize-list/create-prize-list.component';
import { DetailPrizeListComponent } from './detail-prize-list/detail-prize-list.component';
import { RouterModule } from '@angular/router';
import { PrizeListRoute } from './prize-list.routing';
import { MaterialModule } from 'app/modules/material/material.module';



@NgModule({
  declarations: [
    PrizeListComponent,
    CreatePrizeListComponent,
    DetailPrizeListComponent,
    
  ],
  imports: [
    RouterModule.forChild(PrizeListRoute),
    MaterialModule,
    CommonModule
  ]
})
export class PrizeListModule { }
