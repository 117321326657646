import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { CreditNotes, Refund } from './credit-notes.type';
import axios, { AxiosResponse } from 'axios';
import { server_env } from 'config';

@Injectable({
    providedIn: 'root',
})
export class CreditNotesService {
    // Private
    private _creditNote: BehaviorSubject<CreditNotes | null> =
        new BehaviorSubject(null);
    private _creditNotess: BehaviorSubject<CreditNotes[] | null> =
        new BehaviorSubject(null);
    private _refund: BehaviorSubject<any | null> =
        new BehaviorSubject(null);
    private _refunds: BehaviorSubject<any[] | null> =
        new BehaviorSubject(null);

    private _refund_history: BehaviorSubject<any | null> =
        new BehaviorSubject(null);
    private _refund_histories: BehaviorSubject<any[] | null> =
        new BehaviorSubject(null);

    public url = `${server_env.URL}api/v1/creditNotes/`;
    public refund_url = `${server_env.URL}api/v1/refund/`;
    public refundHistory_url = `${server_env.URL}api/v1/creditNotes/<credit_notes_id>/refund_history`;




    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) { }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors

    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for contact
     */
    get creditNote$(): Observable<CreditNotes> {
        return this._creditNote.asObservable();
    }

    get refund_history$(): Observable<any> {
        return this._refund_history.asObservable();
    }

    get refund_histories$(): Observable<any> {
        return this._refund_histories.asObservable();
    }



    /**
     * Getter for contacts
     */
    get creditNotes$(): Observable<CreditNotes[]> {
        return this._creditNotess.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get contacts
     */

    getCreditNotes(): Observable<CreditNotes[]> {
        return this._httpClient.get<CreditNotes[]>(`${this.url}`).pipe(
            tap((creditnote: any) => {
                this._creditNotess.next(creditnote.data);

                for (creditnote in creditnote.data) {
                }
            })
        );
    }

    getRefundHistory(): Observable<any[]> {
        return this._httpClient.get<any[]>(`${this.refundHistory_url}`).pipe(
            tap((creditnote: any) => {
                this._creditNotess.next(creditnote.data);

                for (creditnote in creditnote.data) {
                }
            })
        );
    }

    getRefundHistories(credit_notes_id: string): Observable<any> {
        const requestUrl = `${server_env.URL}api/v1/creditNotes/${credit_notes_id}/refund_history`;


        return this._httpClient.get<any[]>(`${requestUrl}`).pipe(
            tap((creditnote: any) => {
                this._creditNotess.next(creditnote.data);

                for (creditnote in creditnote.data) {
                }
            })
        );
    }
     
    getCustomerVoucher(credit_notes_id: string): Observable<any> {
        const requestUrl = `${server_env.URL}api/v1/customer_creditNotes/${credit_notes_id}/`;


        return this._httpClient.get<any[]>(`${requestUrl}`).pipe(
            tap((creditnote: any) => {
                this._creditNotess.next(creditnote.data);

                for (creditnote in creditnote.data) {
                }
            })
        );
    }



    getRefund(): Observable<any[]> {
        return this._httpClient.get<any[]>(`${this.refund_url}`).pipe(
            tap((creditnote: any) => {
                this._creditNotess.next(creditnote.data);

                for (creditnote in creditnote.data) {
                }
            })
        );
    }
    getRefundsById(id: string): Observable<Refund> {
        const requestUrl = this.refund_url + id;

        return this._httpClient.get<any[]>(`${requestUrl}`).pipe(
            take(1),
            map((salesorder: any) => {
                this._creditNote.next(salesorder.data);

                // Return the contact
                return salesorder.data;
            }),
            switchMap((salesorder) => {
                if (!salesorder) {
                    return throwError(
                        'Could not found contact with id of ' + id + '!'
                    );
                }

                return of(salesorder);
            })
        );
    }


    /**
     * Get contact by id
     */
    getSalesOrderById(id: string): Observable<CreditNotes> {
        const requestUrl = this.url + id;

        return this._httpClient.get<CreditNotes[]>(`${requestUrl}`).pipe(
            take(1),
            map((salesorder: any) => {
                this._creditNote.next(salesorder.data);

                // Return the contact
                return salesorder.data;
            }),
            switchMap((salesorder) => {
                if (!salesorder) {
                    return throwError(
                        'Could not found contact with id of ' + id + '!'
                    );
                }

                return of(salesorder);
            })
        );
    }

    /**
     * Create contact
     */
    // createSalesOrder(): Observable<CreditNotes>
    // {
    //     return this.salesOrder$.pipe(
    //         take(1),
    //         switchMap(salesOrder => this._httpClient.post<CreditNotes>(`${this.url}`, {}).pipe(
    //             map((newSalesOrder) => {

    //                 // Update the contacts with the new contact
    //                 this._creditNotess.next([newSalesOrder, ...salesOrder]);

    //                 // Return the new contact
    //                 return newSalesOrder;
    //             })
    //         ))
    //     );
    // }

    /**
     * create customer
     * @param data
     * @returns
     */

    createSalesOrders(data): Promise<any> {
        return new Promise((resolve, reject) => {
            const requestUrl = this.url;

            this._httpClient.post<any>(`${requestUrl}`, data).subscribe(
                (response) => {
                    return resolve(response);
                },
                (error) => {
                    return reject(error);
                }
            );
        });
    }

    /**
      * create customer
      * @param data
      * @returns
      */

    createRefund(data): Promise<any> {
        return new Promise((resolve, reject) => {
            const requestUrl = this.refund_url;

            this._httpClient.post<any>(`${requestUrl}`, data).subscribe(
                (response) => {
                    return resolve(response);
                },
                (error) => {
                    return reject(error);
                }
            );
        });
    }



    /**
     * Update contact
     *
     * @param id
     * @param contact
     */
    updateCredtiNotes(
        id: string,
        contact: CreditNotes
    ): Observable<CreditNotes> {
        return this.creditNotes$.pipe(
            take(1),
            switchMap((creditNotes) =>
                this._httpClient
                    .put<CreditNotes>(`${this.url}${id}/`, {
                        id,
                        contact,
                    })
                    .pipe(
                        map((updatedCreditNotes) => {
                            // Find the index of the updated contact
                            const index = creditNotes.findIndex(
                                (item) => item.id === id
                            );

                            // Update the contact
                            creditNotes[index] = updatedCreditNotes;

                            // Update the contacts
                            this._creditNotess.next(creditNotes);

                            // Return the updated contact
                            return updatedCreditNotes;
                        }),
                        switchMap((updatedCreditNotes) =>
                            this.creditNote$.pipe(
                                take(1),
                                filter((item) => item && item.id === id),
                                tap(() => {
                                    // Update the contact if it's selected
                                    this._creditNote.next(updatedCreditNotes);

                                    // Return the updated contact
                                    return updatedCreditNotes;
                                })
                            )
                        )
                    )
            )
        );
    }

    /**
     * Delete the contact
     *
     * @param id
     */
    deleteCreditNotes(id: string): Observable<boolean> {
        return this.creditNotes$.pipe(
            take(1),
            switchMap((creditNotes) =>
                this._httpClient.delete(`${this.url}${id}/`).pipe(
                    map((isDeleted: boolean) => {
                        // Find the index of the deleted contact
                        const index = creditNotes.findIndex(
                            (item) => item.id === id
                        );

                        // Delete the contact
                        creditNotes.splice(index, 1);

                        // Update the contacts
                        this._creditNotess.next(creditNotes);

                        // Return the deleted status
                        return isDeleted;
                    })
                )
            )
        );
    }


    async fetchData(): Promise<string[]> {
        try {
            const response: AxiosResponse = await axios.get(
                'https://nepallocation.com.np/api/v1/district/list',
                {
                    headers: {
                        Authorization: `Bearer AFKw8mI-vtgRq-M2i1qDvIGS`,
                        'Content-Type': 'application/json',
                    },
                }
            );

            const districtNames = response.data.data.data.map(
                (district) => district.name
            );



            // console.log(districtNames, 'districtNames');

            return districtNames;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
}
