


import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PackagePopupComponent } from 'app/packages/package-popup/package-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { ExchangesService } from '../exchanges.service';
import html2canvas from 'html2canvas';
import { copyImageToClipboard } from 'copy-image-clipboard';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-detail-exchanges',
  templateUrl: './detail-exchanges.component.html',
  styleUrls: ['./detail-exchanges.component.scss']
})
export class DetailExchangesComponent implements OnInit {
    @ViewChild('screenshotElement') screenshotElement!: ElementRef;

    productId: string;
    vendor: any; // Replace 'any' with the actual type of your product
    dropdownHidden: boolean = true; //drop down for create the pack
    salesCredit: any;
    showAdditionalInfo: boolean = false;

    constructor(
        private route: ActivatedRoute,
        private productService: ExchangesService, // Replace with your actual product service
        private dialog: MatDialog
    ) { }

    ngOnInit(): void {
        this.route.paramMap.subscribe((params) => {
            this.productId = params.get('id');
            this.fetchProductDetails(this.productId);
            this.fetchExchangeDetials(this.productId);
        });
    }
    
    fetchProductDetails(productId: string): void {
        // Use your product service to fetch the product details based on the provided ID
        this.productService.getSalesOrderById(productId).subscribe(
            (data) => {
                this.vendor = data;
        
                // this.product.thumbnail = this.getThumbnailUrl(data.thumbnail); // Update the thumbnail URL
            },
            (error) => {
                console.error('Error fetching product details:', error);
            }
        );
    }

    fetchExchangeDetials(productId: string): void {
    this.productService.getSalesReturnCreditNote(productId).subscribe(
        (data) => {
            data = data.data;


            data.map((data)=> {

                this.salesCredit = data;
            });


        }
    )
}


toggleAdditionalInfo() {
    this.showAdditionalInfo = !this.showAdditionalInfo;
}

    printPage() {
        // Select the buttons you want to hide
        const buttonsToHide = document.querySelectorAll('button');

        // Hide the buttons before printing
        buttonsToHide.forEach((button) => {
            button.style.display = 'none';
        });

        // Perform the printing
        window.print();

        // Show the buttons again after printing (delayed to ensure they show after the print dialog)
        setTimeout(() => {
            buttonsToHide.forEach((button) => {
                button.style.display = 'block'; // or 'inline' depending on the button's original display property
            });
        }, 1000); // Adjust the delay as needed
    }

    screenshot() {
        const element = this.screenshotElement.nativeElement;
    
        if (!element) {
          console.error('Element not found');
          return;
        }
    
    
        // Apply temporary styling to ensure visibility and correct rendering
    
        // Use a longer timeout to ensure everything is fully rendered
        
        setTimeout(() => {
    
          html2canvas(element, {
    
          })
            .then(canvas => {
              const dataURL = canvas.toDataURL('image/png');
    
              const anchor = document.createElement('a');
              anchor.style.display = 'none';
              anchor.href = dataURL;
    
              copyImageToClipboard(anchor.href)
                .then(() => {
    
                  const Toast = Swal.mixin({
                    toast: true,
                    position: "top",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                      toast.onmouseenter = Swal.stopTimer;
                      toast.onmouseleave = Swal.resumeTimer;
                    }
                  });
                  Toast.fire({
                    icon: "success",
                    title: "Copied to the clipboard"
                  });
                })
                .catch((e) => {
                  })
    
    
    
              document.body.removeChild(anchor);
    
    
    
            })
            .catch(error => {
              console.error('Error capturing screenshot:', error);
    
              // Rests
            });
        });
      }

 
    toggleDropdown() {
        this.dropdownHidden = !this.dropdownHidden;
    }

    // on package click this open the package dialog box
    openPackagepopup(saleOderId: any) {
        const dialogRef = this.dialog.open(PackagePopupComponent, {
            width: '800px',
            data: { saleOderId }, // Adjust the width as per your requirements
            // Pass the selected vendor as data to the modal
        });

        dialogRef.afterClosed().subscribe((result) => {
            // Handle any actions after the modal is closed
        });
    }

    ngOnDestroy(): void { }
    
    goBack=()=>{
        window.history.back();
    }
}

