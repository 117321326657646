import {

  Component,
  ChangeDetectorRef,
  OnInit,
  Inject,
  EventEmitter,
  Output,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,

  Validators,
} from '@angular/forms';
import { Observable, of, forkJoin } from 'rxjs';
import {
  map,
  switchMap,
  take,

} from 'rxjs/operators';
import { productVaraitonService } from 'app/productVariations/productVarations.type';
import Swal from 'sweetalert2';
import { products } from 'app/products/products.type';
import { productsService } from 'app/products/products.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmPopupComponent } from 'app/confirm-popup/confirm-popup.component';

@Component({
  selector: 'app-product-bundle-popup',
  templateUrl: './product-bundle-popup.component.html',
  styleUrls: ['./product-bundle-popup.component.scss']
})
export class ProductBundlePopupComponent implements OnInit {

  sizes: any[] = []; // Initialize with empty array
  colors: any[] = []; // Initialize with empty array
  productBundlePopUpForm: FormGroup;
  active: boolean[] = [];
  filteredProductOptions$: Observable<products[]>;

  current_stock: any[] = [];
  product_details: any[] = [];
  animationFlags: boolean[] = [];
  match_stock: boolean[] = [];

  productsFormArray: FormArray;
  productVariationsMap: { [index: number]: any[] } = {};
  selectedSizeColorMap: { [productId: string]: { size: string, color: string } } = {};

  matchedVariationIds: Set<string> = new Set();
  formFieldHelpers: any;
  @Output() formSubmitted: EventEmitter<any> = new EventEmitter<any>();

  constructor(


    private _formBuilder: FormBuilder,
    private _productService: productsService,
    private cd: ChangeDetectorRef,
    private _productVaraitons: productVaraitonService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _matDialog: MatDialogRef<ConfirmPopupComponent>,
    public dialogRef: MatDialogRef<ProductBundlePopupComponent>,





  ) { }

  ngOnInit(): void {
    this.productBundlePopUpForm = this._formBuilder.group({
      products: this._formBuilder.array([]),
      sub_total: [0], // Initialize with a default value,
      discount: [''],
      discountSuffix: ['%'],
      adjustmentSuffix: ['%'],
      adjustment: [''],

      total: [0], // Initialize with a default value
    });

    const selectedProducts = this.data.selectedProducts;

    const productArray = this.productBundlePopUpForm.get('products') as FormArray;
    const numberOfProducts = selectedProducts.length;

    // Clear existing controls before adding new ones
    productArray.clear();

    this.animationFlags = Array(numberOfProducts).fill(false);
    this.match_stock = Array(numberOfProducts).fill(false);
    this.active = Array(numberOfProducts).fill(false);

    const subscribeToValueChanges = (productGroup: FormGroup) => {
      const rateControl = productGroup.get('rate');
      const quantityControl = productGroup.get('quantity');

      rateControl.valueChanges.subscribe(() => {
        this.calculateTotal();
      });

      quantityControl.valueChanges.subscribe(() => {
        this.calculateTotal();
      });
    };
    this.productBundlePopUpForm.get('discount').valueChanges.subscribe(() => {
      this.calculateTotal();
    });
    this.productBundlePopUpForm.get('discountSuffix').valueChanges.subscribe(() => {
      this.calculateTotal();
    });



    this.productBundlePopUpForm.get('adjustment').valueChanges.subscribe(() => {
      this.calculateTotal();
    });
    this.productBundlePopUpForm
      .get('adjustmentSuffix')
      .valueChanges.subscribe(() => {
        this.calculateTotal();
      });

    selectedProducts.forEach((selectedProduct: any, index: number) => {
      const productGroup = this._formBuilder.group({
        id: [selectedProduct.product.product.id],
        product: [selectedProduct.product.product, [Validators.required]],
        quantity: [0, [Validators.required]],
        rate: [selectedProduct.product.product.basePrice],
        amount: [0],
        color: [''],
        size: ['']
      });


      // Subscribe to value changes for the initialized form controls
      subscribeToValueChanges(productGroup);

      productGroup.patchValue({
        id: selectedProduct.product.product.id,
        product: selectedProduct.product.product,
        quantity: 0,
        rate: selectedProduct.product.product.basePrice,
        amount: 0,
        color: '',
        size: ''
      });

      // Add the product group to the form array
      productArray.push(productGroup);

      // Add this log to check if selectOptionProduct is being called correctly
      
      this.selectOptionProduct(productGroup.get('product').value, index);
    });

  }



  submitForm(formData: any): void {
    if (this.productBundlePopUpForm.valid) {




      this.formSubmitted.emit(formData);

      this.dialogRef.close({ total: formData.total, otherData: formData });

    } else {
      console.error("Form is not valid. Please check the input fields.");
    }
  }





  // Emit the form data


  cancel(): void {
    this._matDialog.close();
  }


  addProductField(): void {
    const productFormGroup = this._formBuilder.group({
      id: [''],
      product: ['', [Validators.required]],
      quantity: ['', Validators.required],
      rate: ['', Validators.required],
      amount: [''],
      color: [''],
      size: ['']
    });

    const productArray = this.productBundlePopUpForm.get('products') as FormArray;
    productArray.push(productFormGroup);

    const index = productArray.length - 1;
    this.setupProductFieldSubscriptions(productFormGroup, index);

    this.calculateTotal();
  }



  removeProductField(index: number): void {
    const productArray = this.productBundlePopUpForm.get('products') as FormArray;
    const existingFormGroup = productArray.at(index) as FormGroup;

    const removedId = existingFormGroup.get('id').value;
    this.matchedVariationIds.delete(removedId);

    this.active.splice(index, 1);
    this.animationFlags.splice(index, 1);
    this.match_stock.splice(index, 1);
    this.current_stock.splice(index, 1);
    this.product_details.splice(index, 1);

    this.sizes.splice(index, 1);
    this.colors.splice(index, 1);

    productArray.removeAt(index);

    this.calculateTotal();
    this.cd.markForCheck();
  }






  setupProductFieldSubscriptions(
    productGroup: FormGroup,
    index: number
  ): void {
    const quantityControl = productGroup.get('quantity');
    const rateControl = productGroup.get('rate');

    quantityControl.valueChanges.subscribe(() => {
      this.calculateTotal();
    });

    rateControl.valueChanges.subscribe(() => {
      this.calculateTotal();
    });

    this.productBundlePopUpForm.get('discount').valueChanges.subscribe(() => {
      this.calculateTotal();
    });
    this.productBundlePopUpForm.get('discountSuffix').valueChanges.subscribe(() => {
      this.calculateTotal();
    });



    this.productBundlePopUpForm.get('adjustment').valueChanges.subscribe(() => {
      this.calculateTotal();
    });
    this.productBundlePopUpForm
      .get('adjustmentSuffix')
      .valueChanges.subscribe(() => {
        this.calculateTotal();
      });

  }


  calculateTotal(): void {
    const productArray = this.productBundlePopUpForm.get('products') as FormArray;

    let subTotal = 0;
    productArray.controls.forEach((control: FormGroup) => {
      const quantity = control.get('quantity').value;
      const rate = control.get('rate').value;

      const amount = (quantity || 0) * (rate || 0);
      control.get('amount').setValue(amount);

      subTotal += amount || 0;
    });

    this.productBundlePopUpForm.get('sub_total').setValue(subTotal);
    const discountSuffix = this.productBundlePopUpForm.get('discountSuffix').value;
    const adjustmentSuffix =
      this.productBundlePopUpForm.get('adjustmentSuffix').value;
    const discount = +this.productBundlePopUpForm.get('discount').value || 0;

    const adjustment = +this.productBundlePopUpForm.get('adjustment').value || 0;




    let calculatedDiscount = discount;
    let calculatedAdjustment = adjustment;

    if (discountSuffix === '%') {
      calculatedDiscount = (subTotal * discount) / 100;
    } else if (discountSuffix === 'rs') {
      calculatedDiscount = discount;

    }

    if (adjustmentSuffix === '%') {
      calculatedAdjustment = (subTotal * adjustment) / 100;

    } else if (adjustmentSuffix === 'rs') {
      calculatedAdjustment = adjustment;

    }

    if (adjustmentSuffix == '%' && discountSuffix == '%') {
      calculatedDiscount = (subTotal * discount) / 100;
      const newTotal = subTotal - calculatedDiscount;

      calculatedAdjustment = (newTotal * adjustment) / 100;
    }

    const totalBeforeShipping = subTotal - calculatedDiscount + calculatedAdjustment;
    const total = totalBeforeShipping;


    this.productBundlePopUpForm.get('total').setValue(total);


  }



  toggleInputBack(): void {
    this.productBundlePopUpForm.get('shipping_carrier_int').setValue('ARAMEX');
  }


  filterOptionsProduct(value: string): void {
    if (typeof value !== 'string') {
      this.filteredProductOptions$ = of([]);
      return;
    }


    this._productService.getproducts().pipe(
      take(1),
      switchMap((products) => {
        if (!Array.isArray(products['data'])) {
          return of([]);
        }

        const productObservables = products['data'].map((product) => {
          return this._productService.getProductVariations(product.id).pipe(
            map((variations) => ({
              product,
              variations: variations.data,
            }))
          );
        });

        return forkJoin(productObservables);
      }),
      map((productsWithVariations) => {
        return productsWithVariations.map(({ product, variations }) => {
          const productId = product.id;
          const hasStock = variations.some((variation) => variation.stock > 0);
          const isSelected = this.productBundlePopUpForm.value.products.some(
            (selectedProduct) => selectedProduct.product.id === productId
          );

          const isOutOfStock = !hasStock || isSelected;
          const productStyle = isOutOfStock ? { color: 'red' } : {};
          const disabled = isOutOfStock;

          return {
            product: productId,
            id: productId,
            productName: product.productName,
            sku: product.sku,
            basePrice: product.basePrice,
            size: product.size,
            color: product.color,
            isOutOfStock: isOutOfStock,
            style: productStyle,
            description: product.description,
            disabled: disabled,
          };
        });
      })
    ).subscribe((filteredProducts) => {
      this.filteredProductOptions$ = of(filteredProducts);
    });

  }

  mappedVariations: any[] = [];


  filtersOptionsProducts(productId: string, index: number): void {
    

    this._productService.getProductVariations(productId).subscribe((variations) => {
      

      // Filter and map variations
      this.productVariationsMap[index] = variations.data
        .filter((variation) => variation.active && variation.stock !== 0)
        .map((variation) => ({
          id: variation.id,
          size: variation.size,
          color: variation.color,
          price: variation.basePrice,
          base_product: productId,
          stock: variation.stock,
          description: variation.base_product.description,
          category: variation.base_product.category,
          gender: variation.base_product.gender,
        }));

      

      // Create size-to-color mapping
      const sizeToColorMap = {};
      this.productVariationsMap[index].forEach((variation) => {
        const { size, color, id } = variation;
        if (!sizeToColorMap[size]) {
          sizeToColorMap[size] = [];
        }
        sizeToColorMap[size].push({ id, color });
      });

      // Create sizes array
      const sizes = Object.keys(sizeToColorMap).map((size) => ({
        id: size,
        name: size,
      }));

      // Get the first size
      const firstSize = sizes[0]?.id;

      // Create colors array based on the first size
      const colors = sizeToColorMap[firstSize]?.map((color) => ({
        id: color.color,
        name: color.color,
      })) || [];

      // Update sizes and colors
      this.updateSizesAndColors(index, sizes, colors);
    });
  }



  // Add this method to handle size and color updates
  updateSizesAndColors(index: number, sizes: any[], colors: any[]): void {
    this.sizes[index] = sizes;
    this.colors[index] = colors;

    
  }




  quantityExceedsStock(index: number): boolean {
    const productArray = this.productBundlePopUpForm.get('products') as FormArray;
    const existingFormGroup = productArray.at(index) as FormGroup;
    const quantityControl = existingFormGroup.get('quantity');

    if (quantityControl.invalid) {
      return false;
    }

    const quantityValue = Number(quantityControl.value);
    const currentStockValue = Number(this.current_stock[index]);

    const exceedsStock = quantityValue > currentStockValue;

    if (exceedsStock) {
      // Mark the quantity control as invalid
      quantityControl.setErrors({ 'exceedsStock': true });
    } else {
      // Clear any existing errors
      quantityControl.setErrors(null);
    }

    return exceedsStock;
  }

  onSelectionChange(index: number, resetAnimation: boolean): void {
    const productArray = this.productBundlePopUpForm.get('products') as FormArray;
    const existingFormGroup = productArray.at(index) as FormGroup;

    if (existingFormGroup) {
      const selectedProductId = existingFormGroup.get('product').value.id;
      const selectedSizeId = existingFormGroup.get('size').value;
      const selectedColorId = existingFormGroup.get('color').value;

      const selectedSizeColorCombo = `${selectedSizeId}${selectedColorId}`;
      
      // Use productVariationsMap specific to the index
      if (!this.productVariationsMap[index]) {
        console.error(`Variations for index ${index} not available.`);
        return;
      }

      const matchingOriginalVariation = this.productVariationsMap[index].find((variation) => {
        const variationSizeColorCombo = `${variation.size}${variation.color}`;
       
        return variationSizeColorCombo === selectedSizeColorCombo;
      });

      if (matchingOriginalVariation) {
        

        if (matchingOriginalVariation.stock > 0) {
          if (!this.matchedVariationIds.has(matchingOriginalVariation.id)) {
            

            existingFormGroup.get('rate').patchValue(matchingOriginalVariation.price);
            existingFormGroup.get('size').patchValue(matchingOriginalVariation.size);
            existingFormGroup.get('color').patchValue(matchingOriginalVariation.color);
            existingFormGroup.get('id').patchValue(matchingOriginalVariation.id);

            this.active[index] = true;
            this.current_stock[index] = matchingOriginalVariation.stock;
            this.product_details[index] = matchingOriginalVariation.description;

            if (resetAnimation) {
              this.animationFlags[index] = true;

              setTimeout(() => {
                this.animationFlags[index] = false;
              }, 3000);
            }

            this.matchedVariationIds.add(matchingOriginalVariation.id);
          } else {
            // Code for handling already selected product
          }
        } else {
          // Code for handling out of stock product
        }
      } else {
      }
    }
  }



  getFormFieldHelpersAsString(): string {
    return this.formFieldHelpers.join(' ');
  }

  /**
      * Track by function for ngFor loops
      *
      * @param index
      * @param item
      */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  selectOptionProduct(option: any, index: number): void {
    if (option && !option.isOutOfStock) {
      const productFormGroup = this._formBuilder.group({
        id: [option.id],
        product: [option, [Validators.required]],
        quantity: [''],
        rate: [option.basePrice, ''],
        amount: [''],
        size: [],
        color: [],
      });
      this.filtersOptionsProducts(option.id, index)


      const productArray = this.productBundlePopUpForm.get('products') as FormArray;

    }
  }
  displayProductOption(product: any): string {
    if (product) {
      const productName = product.productName || '';

      const result = productName;
      return result;
    }

    return '';
  }

}
