import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';

import Swal from 'sweetalert2';
import { PackagesOderService } from 'app/packages/packages.service';
import { SalesOrderService } from 'app/sales-order/sales-order.service';

@Component({
  selector: 'app-partial-pay',
  templateUrl: './partial-pay.component.html',
  styleUrls: ['./partial-pay.component.scss']
})
export class PartialPayComponent {
  partialUpdateForm: FormGroup;
  salesOrderTotal: number = 0;
  salesOrderNumber: string = '';
  paymentOptions = [
    'CASH ON DELIVERY',
    "SIDDHARTHA BANK FONEPAY - HEADQUARTER",
    "SIDDHARTHA BANK FONEPAY - JHAMSIKHEL",
    "NIC ASIA BANK FONEPAY - HEADQUARTER",
    "NIC ASIA BANK FONEPAY",
];


  constructor(
    private _formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<PartialPayComponent>,
    private datePipe: DatePipe,
    private _packageService: PackagesOderService,
    private salesOrderService: SalesOrderService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.partialUpdateForm = this._formBuilder.group({
      salesOrderNumber: [],
      salesOrderTotal: [],
      payment: this._formBuilder.array([], this.paymentTotalValidator.bind(this))
    });
    this.addPayment();
    this.fetchSalesHistory(data.total,data.order_number);
  }

  get payment(): FormArray {
    return this.partialUpdateForm.get('payment') as FormArray;
  }

    addPayment(): void {
    this.payment.push(
      this._formBuilder.group({
        payment_type: ['', Validators.required],
        total: ['', [Validators.required, Validators.min(0)]]
      })
    );
  }

  removePayment(index: number): void {
    this.payment.removeAt(index);
  }

  fetchSalesHistory(total: any,orderNumber:string): void {
    
        this.salesOrderTotal = total;
        this.salesOrderNumber = orderNumber;
        this.partialUpdateForm.patchValue({
          salesOrderNumber: this.salesOrderNumber,
          salesOrderTotal: this.salesOrderTotal
        });
      
  }

  paymentTotalValidator(control: AbstractControl): ValidationErrors | null {
    const paymentArray = control as FormArray;
    const totalPayments = paymentArray.controls.reduce((sum, group) => {
      const total = group.get('total').value || 0;
      return sum + total;
    }, 0);

    if (totalPayments !== this.salesOrderTotal) {
      return { totalMismatch: true };
    }
    return null;
  }

  async submitForm() {
    if (this.partialUpdateForm.valid) {
      const totalPayments = this.payment.controls.reduce((sum, group) => {
        return sum + group.get('total').value;
      }, 0);

      if (totalPayments !== this.salesOrderTotal) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Total payment should match the sales order total.',
          showConfirmButton: true,
        });
        return;
      }else if (totalPayments> this.salesOrderTotal){
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Total payment is less then the sales order total.',
          showConfirmButton: true,
        });
      }

      const partialPayments = this.partialUpdateForm.value.payment.map(payment => ({
        amount: payment.total,
        payment_type: payment.payment_type
      }));

      const packageData = {
        ...this.partialUpdateForm.getRawValue(),
        packageDate: this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
        isDelivared: true,
        partial_pay: true,
        partial_payments: partialPayments
      };

      try {
        const response = await this._packageService.updatePackageOrder(this.data.packageData.id, packageData);
        if (response.success) {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: `${this.data.orderType} order delivered successfully`,
            showConfirmButton: false,
            backdrop: true,
            allowOutsideClick: false,
            position: 'top-end',
            toast: true,
          });
          this.dialogRef.close(true); // Close the dialog with success result
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Failed to update package order. Please try again.',
            showConfirmButton: true,
          });
        }
      } catch (error) {
        console.error('Failed to update package order:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to update package order. Please try again.',
          showConfirmButton: true,
        });
      }
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Please fill all required fields correctly.',
        showConfirmButton: false,
        backdrop: true,
        allowOutsideClick: false,
        position: 'top-end',
        toast: true,
        timer: 5000,
      });
    }
  }

  cancel(): void {
    this.dialogRef.close(false); // Close the dialog with false result
  }
}
