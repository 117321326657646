import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { forkJoin, Observable } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { SalesOrderService } from 'app/sales-order/sales-order.service';
import { map, } from 'rxjs/operators';
import { DelivarypopupComponent } from 'app/packages/delivarypopup/delivarypopup.component';
import { SalesLogsService } from './sales-logs.service';
import { SalesOrder } from 'app/sales-order/sales-order.type';
import { Exchanges } from 'app/exchanges/exchanges.type';
import { SalesReturn } from 'app/sales-return/sales-return.type';
import { PreOrders } from 'app/pre-orders/pre-orders.type';
import { CreditNotes } from 'app/credit-notes/credit-notes.type';
import { ExchangesService } from 'app/exchanges/exchanges.service';
import { CreditNotesService } from 'app/credit-notes/credit-notes.service';
import { PreOrdersService } from 'app/pre-orders/pre-orders.service';
import Swal from 'sweetalert2';
import { PackagesOderService } from 'app/packages/packages.service';
import * as ExcelJS from 'exceljs';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { FormBuilder, FormGroup } from '@angular/forms';
import { RoleService } from 'app/core/auth/guards/role.service';

export interface SalesOrderData {
    type?: string;
    rowId?: string;
    id?: string;
    customerId?: string;
    customerName?: string;
    shipping_area?: string;
    delivery_type?: string;
    saleNumber?: string;
    main_type?: string;
    status?: string;
    shippingDate?: any;
    products?: any;
    totalCost?: number;
    isExpanded?: boolean;
    payment_type?: any;
    shipping_carrier?: string;
    updated?: any;
}

@Component({
    selector: 'app-sales-logs',
    templateUrl: './sales-logs.component.html',
    styleUrls: ['./sales-logs.component.scss']

})



export class SalesLogsComponent implements OnInit, AfterViewInit {
    displayedColumns: string[] = [
        "select",
        'SaleNumber',
        'CustomerId',
        'CustomerName',
        'Status',
        'paid_status',
        'Shipping_Date',
        "Delivery Type",
        "Shipping Area",
        "Shipping Carrier",
        'total',
        'actions',
        'create',
    ];

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    salesorder$: Observable<SalesOrder[]>;
    salesOrder: SalesOrder[];
    salesOrderPackage$: Observable<any[]>;
    salesOrderPackage: any[];

    exchanges: Exchanges[];
    exchanges$: Observable<Exchanges[]>;

    salesReturn: SalesReturn[];
    salesReturn$: Observable<SalesReturn[]>;

    preOrder: PreOrders[];
    preOrder$: Observable<PreOrders[]>;

    creditNotes: CreditNotes[];
    creditNotes$: Observable<CreditNotes[]>;
    isLoading: boolean = true;

    configForm: FormGroup;
    deliveryConfig: FormGroup;
    shippingCancleConfig: FormGroup;
    dataSource: MatTableDataSource<SalesOrderData>;
    @ViewChild('salesOrderTabel') salesOrderTabel: ElementRef;
    requiredRoles: string[] = ["Manager", "Operation Manager", "HR"];  // Define the roles allowed to execute the function
    requiredRolesCreation: string[] = ["Manager", "Communication Manager", "HR"];  // Define the roles allowed to execute the function
    checked = false;
    selectedDate: any;
    selection = new SelectionModel<SalesOrderData>(true, []);
    totalRows: number = 0;
    selectedShippingArea: string;
    selectedDeliveryType: string;
    selectedStatusType: string;
    selectedType: string;
    selectedRowIds: any[] = [];
    counter: number = 0;
    selectAll: boolean = false;
    salesId: any;
    variations: any;
    searchTerm: string = '';

    constructor(

        private _router: Router,
        private dialog: MatDialog,
        private datePipe: DatePipe,
        private _salesOrderService: SalesOrderService,
        private _exchangeService: ExchangesService,
        private _creditNotesService: CreditNotesService,
        private _preOrderService: PreOrdersService,
        private _packageService: PackagesOderService,
        private _fuseConfirmationService: FuseConfirmationService,
        private _formBuilder: FormBuilder,
        private _packageLogsService: SalesLogsService,
        private route: ActivatedRoute,
        private roleService: RoleService,




    ) {

    }

    // Global Functions 

    isObjectEmpty(obj: Record<string, any>): boolean {
        return Object.keys(obj).length === 0;
    }



    async getSalesLogs() {

        this.route.data.subscribe(data => {
            this.isLoading = false;
            this.dataSource = new MatTableDataSource<SalesOrderData>(data.salesLogs);

        });
    }



    ngOnInit(): void {
        this.getSalesLogs();

        this.configForm = this._formBuilder.group({
            title: 'Info',
            message: 'There are multiple instances of Sales with diffrent status sometimes it will take time to be printed<br> <span class="font-medium">Are you sure you want to proceed!</span>',
            icon: this._formBuilder.group({
                show: true,
                name: 'heroicons_outline:exclamation',
                color: 'info'
            }),
            actions: this._formBuilder.group({
                confirm: this._formBuilder.group({
                    show: true,
                    label: 'Proceed',
                    color: 'accent'
                }),

            }),
            dismissible: true
        });

        this.deliveryConfig = this._formBuilder.group({
            title: 'Info',
            message: `
            There are multiple instances of Sales with different statuses. Only status with 'Shipped' tag will be delivered.
            <br>
            <span class="font-medium">
                By selecting 'Group Delivery,' all marked deliveries will be set as paid,
                and the delivery date will be updated to today. If you have individual deliveries pending,
                please proceed from the singular delivery.
            </span>
            <br>
            Are you sure you want to proceed?
        `,
            icon: this._formBuilder.group({
                show: true,
                name: 'heroicons_outline:exclamation',
                color: 'info'
            }),
            actions: this._formBuilder.group({
                confirm: this._formBuilder.group({
                    show: true,
                    label: 'Proceed',
                    color: 'accent'
                }),
                cancel: this._formBuilder.group({
                    show: true,
                    label: 'Cancel',
                    color: 'warn'

                })
            }),
            dismissible: true
        });


        this.shippingCancleConfig = this._formBuilder.group({
            title: 'Info',
            message: `
            You are about to cancle the shipping. Are you sure you want to continue.
            <br>
            <span class="font-medium">
                By selecting 'Group Shipping return,' all unpaid shipped will be cancled. If you have individual shipping which are paid,
                please proceed from the singular shipping return.
            </span>
            <br>
            Are you sure you want to proceed?
        `,
            icon: this._formBuilder.group({
                show: true,
                name: 'heroicons_outline:exclamation',
                color: 'info'
            }),
            actions: this._formBuilder.group({
                confirm: this._formBuilder.group({
                    show: true,
                    label: 'Proceed',
                    color: 'accent'
                }),
                cancel: this._formBuilder.group({
                    show: true,
                    label: 'Cancel',
                    color: 'warn'

                })
            }),
            dismissible: true
        });
    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

    }

    refreshSalesLogs(): void {
        this.isLoading = true;
        this._packageLogsService.getSalesLogs().subscribe(
            (salesLogs: any) => {
                this.isLoading = false;
                this.dataSource = new MatTableDataSource<any>(salesLogs['data']);
            },
            error => {
                this.isLoading = false;
                console.error('Error refreshing sales logs:', error);
            }
        );
    }


    applyFilters() {
        const shippingAreaFilter = this.selectedShippingArea ? this.selectedShippingArea.trim().toLowerCase() : '';
        const deliveryTypeFilter = this.selectedDeliveryType ? this.selectedDeliveryType.trim().toLowerCase() : '';
        const selectedDateFilter = this.selectedDate ? this.datePipe.transform(this.selectedDate, 'yyyy-MM-dd') : '';
        const selectedStatusFilter = this.selectedStatusType ? this.selectedStatusType.trim().toLowerCase() : '';
        const selectedTypeFilter = this.selectedType ? this.selectedType.trim().toLowerCase() : '';
        const searchTermFilter = this.searchTerm ? this.searchTerm.trim().toLowerCase() : '';

        this.isLoading = true;

        if (shippingAreaFilter || deliveryTypeFilter || selectedDateFilter || selectedStatusFilter || selectedTypeFilter || searchTermFilter) {
            this.dataSource.filterPredicate = (data: SalesOrderData) => {
                const shippingAreaMatch = data.shipping_area ? data.shipping_area.toLowerCase().includes(shippingAreaFilter) : true;
                const deliveryTypeMatch = data.delivery_type ? data.delivery_type.toLowerCase().includes(deliveryTypeFilter) : true;
                const dateMatch = data.shippingDate ? data.shippingDate.includes(selectedDateFilter) : true;
                const statusMatch = data.status ? data.status.toLowerCase().includes(selectedStatusFilter) : true;
                const typeMatch = data.type ? data.type.toLowerCase().includes(selectedTypeFilter) : true;

                // Handle null/undefined values before calling toString()
                const searchTermMatch = this.searchTerm ?
                    Object.values(data).some(value => value && value.toString().toLowerCase().includes(searchTermFilter)) : true;

                return shippingAreaMatch && deliveryTypeMatch && dateMatch && statusMatch && typeMatch && searchTermMatch;
            };

            const combinedFilterString = `${shippingAreaFilter} ${deliveryTypeFilter} ${selectedDateFilter} ${selectedStatusFilter} ${selectedTypeFilter} ${searchTermFilter}`;
            this.dataSource.filter = combinedFilterString.trim();
        } else {
            this.dataSource.filter = '';
        }

        this.isLoading = false;
    }


    showDetails(id: any, type: string) {



        if (type) {
            this._router.navigate([`apps/${type}`, id]);
        } else {
            console.error(`Unsupported order type: ${type}`);
        }
    }




    openDeliverypopup(id: string, type?: string) {
        this.roleService.withRoleCheck(this.requiredRoles, async () => {

            const dialogRef = this.dialog.open(DelivarypopupComponent, {
                width: '800px',
                data: { id, type },
            });

            dialogRef.afterClosed().subscribe((result) => {
                if (result && result.saved === true) {
                    this.refreshSalesLogs()

                } else {
                }
            });
        });
    }


    formatSelectedDate(event: Date) {
        if (event) {
            this.selectedDate = this.datePipe.transform(event, 'yyyy-MM-dd');
        } else {
            this.selectedDate = null;
        }
    }


    updateSelectedRowIds(row: any): void {
        const isSelected = this.selection.isSelected(row);

        if (isSelected) {
            this.selection.deselect(row);
        } else {
            this.selection.select(row);
        }
    }


    isAllSelected() {
        if (!this.dataSource || !this.dataSource.data) {
            return false;
        }

        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected > 0 && numSelected === numRows;
    }


    /** Selects all rows if they are not all selected; otherwise clear selection. */
    toggleAllRows() {
        if (this.isAllSelected()) {
            this.selection.clear();
        } else {
            this.selection.clear(); // Clear any existing selection
            this.selection.select(...this.dataSource.data); // Select all rows at once
        }
    }

    getSelectedRowIds(): any[] {
        return this.selection.selected.map(row => row.saleNumber);
    }

    checkboxLabel(row?: SalesOrderData): string {
        if (!row) {
            const result = `${this.isAllSelected() ? 'deselect' : 'select'} all`;
            return result;
        }
        const result = `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.customerId + 1}`;
        return result;
    }


    trackByProduct(index: number, product): string {
        return product.id;
    }



    async multipleDelivery() {
        this.roleService.withRoleCheck(this.requiredRoles, async () => {

            const selectedRowIds = this.getSelectedRowIds();
            const ids = selectedRowIds;

            // Open delivery information dialog
            const confirmed = await this.openDeliveryInformationDialog().toPromise();

            if (!confirmed) {
                return;
            }

            // Show loading dialog
            Swal.fire({
                title: 'Processing...',
                html: 'Please wait while the deliveries are being processed.',
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading();
                }
            });

            // Filter selected rows based on selected row IDs
            const selectedRows = this.dataSource.data.filter(row => ids.includes(row.saleNumber));

            // Initialize arrays to handle success and failure results
            const successResults: any[] = [];
            const errorResults: any[] = [];

            // Collect all delivery processing promises
            const deliveryPromises = selectedRows.map(async (row) => {
                try {
                    const { main_type, status, id, rowId } = row;

                    let result;
                    if (main_type === 'sales-order') {
                        result = await this.processSalesOrderDelivery(id);
                    } else if (main_type === 'exchanges') {
                        result = await this.processExchangeDelivery([id]);
                    } else if (main_type === 'pre-order') {
                        result = await this.processPreOrderDelivery([id]);

                    } else if (main_type === 'pr-order') {
                        result = await this.processPrDelivery([rowId]);
                    } else {
                        throw new Error(`Unhandled type or status: ${main_type} - ${status}`);
                    }

                    console.log(`Delivery processed for ID: ${id}`, result); // Debug log
                    successResults.push(result);

                } catch (error) {
                    console.error(`Error processing delivery for ID: ${row.id}`, error); // Error log
                    errorResults.push({ id: row.id, error: error.message || 'Unknown error' });
                }
            });

            try {
                // Execute all delivery promises concurrently
                await Promise.all(deliveryPromises);

                // Close the loading dialog
                Swal.close();

                // After all promises complete
                if (errorResults.length > 0) {
                    // Show error notification if there are any failed deliveries
                    Swal.fire({
                        icon: 'error',
                        title: 'Error Processing Deliveries',
                        text: errorResults.map(result => `ID: ${result.id} - Error: ${result.error}`).join('\n'),
                    });
                } else {
                    // Show success notification if all deliveries succeeded
                    Swal.fire({
                        icon: 'success',
                        title: 'Deliveries Processed!',
                        text: 'All selected deliveries have been processed successfully!',
                    });
                }

                // Refresh sales logs
                this.refreshSalesLogs();

            } catch (error) {
                console.error('Error processing deliveries:', error); // Error log
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'An unexpected error occurred while processing deliveries.',
                });
            }
        });
    }






    async cancleShippingMultiple() {
        this.roleService.withRoleCheck(this.requiredRolesCreation, async () => {

            const selectedRowIds = this.getSelectedRowIds();
            const ids = selectedRowIds;
            const confirmed = await this.openShippingReturnInformationDailogC().toPromise();

            if (!confirmed) {
                return;

            } else {

                const selectedRows = this.dataSource.data.filter(row => {
                    const included = ids.includes(row.saleNumber);
                    return included;
                });

                for (const row of selectedRows) {


                    const type = row.main_type;
                    const status = row.status;
                    const payment_type = row.payment_type;
                    if (type === 'sales-order' && status === 'Sales Order Shipped' && payment_type === 'CASH ON DELIVERY') {


                        await this.processSalesOrderShippingReturn(row.id);



                    } if (type === 'exchanges' && status === 'Shipped Exchange Order' && payment_type === 'CASH ON DELIVERY') {

                        await this.processExchangeShippingReturn(row.id)



                    }

                    this.refreshSalesLogs();

                }

            }
        });

    }

    async processSalesOrderShippingReturnPrePaid(id: any) {
        const request = this._packageLogsService.getSalesOrderPackages(id);
        const currentDate = new Date().toISOString().split('T')[0];

        try {
            const result = await request.toPromise();
            const data = result['data'];
            const salesOrderId = data[0].salesOrder;
            const requestSales = this._salesOrderService.getSalesOrderById(salesOrderId);
            const resultSales = await requestSales.toPromise();


            try {
                await this.paymentDueSales(resultSales, id)
                Swal.close();
                Swal.fire({
                    icon: 'info',
                    title: 'Success!',
                    text: 'Credit Note processing completed successfully.',
                    showConfirmButton: false,
                    backdrop: true,
                    allowOutsideClick: false,
                    position: 'top-end',
                    toast: true,
                    timer: 3000
                });
            } catch (error) {
                console.error("Error in paymentDue:", error);
                Swal.close();
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: 'An error occurred while processing the payment.',
                    confirmButtonText: 'OK'
                });
            }



            if (Array.isArray(data)) {
                for (const item of data) {
                    const packageOrder = item.packageOrder;
                    const packageDate = currentDate;
                    const salesOrder = item.salesOrder;
                    const isCancelled = true;
                    const packageId = item.id; // Rename to packageId to avoid conflict

                    const row = {
                        packageDate,
                        salesOrder,
                        packageOrder,
                        isCancelled
                    };


                    try {
                        const response = await this.updatePackage(packageId, row);

                        if (response) {
                            Swal.fire({
                                icon: 'success',
                                title: 'Success',
                                text: `Sales order Cancled successfully `,
                                showConfirmButton: false,
                                backdrop: true,
                                allowOutsideClick: false,
                                position: 'top-end',
                                toast: true,
                                timer: 5000,
                            });
                            this.refreshSalesLogs();
                        }

                    } catch (error) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: `${error} `,
                            showConfirmButton: false,
                            backdrop: true,
                            allowOutsideClick: false,
                            position: 'top-end',
                            toast: true,
                            timer: 5000,
                        });
                    }
                }
            } else {
                // Handle case when data is not an array
            }
        } catch (error) {
            console.error('Error occurred while processing data:', error);
            return []; // Return an empty array if an error occurs
        }

    }


    async processExchangeOrderShippingReturnPrePaid(id: any) {
        const request = this._packageLogsService.getExchangesPackages(id);
        const currentDate = new Date().toISOString().split('T')[0];

        try {
            const result = await request.toPromise();
            const data = result['data'];

            try {
                await this.paymentDueExchange(data, id)
                Swal.close(); // Close the loading modal after paymentDue completes
                Swal.fire({
                    icon: 'info',
                    title: 'Success!',
                    text: 'Credit Note processing completed successfully.',
                    showConfirmButton: false,
                    backdrop: true,
                    allowOutsideClick: false,
                    position: 'top-end',
                    toast: true,
                    timer: 3000
                });
            } catch (error) {
                console.error("Error in paymentDue:", error);
                Swal.close();
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: 'An error occurred while processing the payment.',
                    confirmButtonText: 'OK'
                });
            }




            if (Array.isArray(data)) {
                for (const item of data) {

                    const packageOrder = item.packageOrder;
                    const packageDate = currentDate;
                    const exchanges = item.exchanges;
                    const isCancelled = true;


                    const packageId = item.id; // Rename to packageId to avoid conflict

                    const row = {
                        packageDate,
                        exchanges,
                        isCancelled,
                        packageOrder,

                    };


                    try {
                        const response = await this.updatePackage(packageId, row);

                        if (response.success) {
                            Swal.fire({
                                icon: 'success',
                                title: 'Success',
                                text: ` Cancled successfully `,
                                showConfirmButton: false,
                                backdrop: true,
                                allowOutsideClick: false,
                                position: 'top-end',
                                toast: true,
                                timer: 5000,
                            });
                            this.refreshSalesLogs();
                        }

                    } catch (error) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: `${error} `,
                            showConfirmButton: false,
                            backdrop: true,
                            allowOutsideClick: false,
                            position: 'top-end',
                            toast: true,
                            timer: 5000,
                        });
                    }
                }
            } else {
            }
        } catch (error) {
            console.error('Error occurred while processing data:', error);
            return []; // Return an empty array if an error occurs
        }

    }

    paymentDueExchange = async (creditNote, id) => {
        try {
            if (!creditNote) {
                throw new Error('Invalid credit note');
            }

            const today = new Date();
            const formattedDate = today.toISOString().split('T')[0]; // Format: YYYY-MM-DD

            const customerData = {
                Customers: creditNote.Customers.id,
                salesExchange: id,
                voucher_description: `This is a credit note for ${creditNote.exchange_number}`,
                voucher_type: "Exchange Voucher",
                total: creditNote.remainingAmount,
                isVoucher: true,
                isCredited: true,
                voucher_order_date: formattedDate,

            };

            const resp = await this._creditNotesService.createSalesOrders(customerData);

            if (resp && resp.success) {
                return { success: true };
            } else {
                throw new Error('Creedit Note processing failed');
            }
        } catch (error) {
            console.error('Error in paymentDue:', error);
            throw error; // Re-throw the error to be caught by the calling code
        }
    };


    paymentDueSales = async (sales_order, id) => {
        try {
            if (!sales_order) {
                throw new Error('Invalid credit note');
            }
            console.log(sales_order, "here")

            const today = new Date();
            const formattedDate = today.toISOString().split('T')[0]; // Format: YYYY-MM-DD

            const customerData = {
                Customers: sales_order.Customers.id,
                voucher_description: `This is a Sale shipping return voucher for ${sales_order.sales_order_num} at date ${today} 
                \n which was created by ${sales_order.sales_person}`,
                voucher_type: "Shipping Canceled Credit Note",
                total: sales_order.total,
                isVoucher: true,
                isCredited: true,
                voucher_order_date: formattedDate,

            };

            const resp = await this._creditNotesService.createSalesOrders(customerData);

            if (resp && resp.success) {
                return { success: true };
            } else {
                throw new Error('Creedit Note processing failed');
            }
        } catch (error) {
            console.error('Error in paymentDue:', error);
            throw error; // Re-throw the error to be caught by the calling code
        }
    };


    async processSalesOrderShippingReturn(id: any) {


        const request = this._packageLogsService.getSalesOrderPackages(id);
        const currentDate = new Date().toISOString().split('T')[0];

        try {
            const result = await request.toPromise();
            const data = result['data'];

            if (Array.isArray(data)) {
                for (const item of data) {
                    const packageOrder = item.packageOrder;
                    const packageDate = currentDate;
                    const salesOrder = item.salesOrder;
                    const isCancelled = true;
                    const packageId = item.id; // Rename to packageId to avoid conflict

                    const row = {
                        packageDate,
                        salesOrder,
                        packageOrder,
                        isCancelled
                    };


                    try {
                        const response = await this.updatePackage(packageId, row);

                        if (response) {
                            Swal.fire({
                                icon: 'success',
                                title: 'Success',
                                text: `Sales order Cancled successfully `,
                                showConfirmButton: false,
                                backdrop: true,
                                allowOutsideClick: false,
                                position: 'top-end',
                                toast: true,
                                timer: 5000,
                            });
                            this.refreshSalesLogs();
                        }

                    } catch (error) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: `${error} `,
                            showConfirmButton: false,
                            backdrop: true,
                            allowOutsideClick: false,
                            position: 'top-end',
                            toast: true,
                            timer: 5000,
                        });
                    }
                }
            } else {
                // Handle case when data is not an array
            }
        } catch (error) {
            console.error('Error occurred while processing data:', error);
            return []; // Return an empty array if an error occurs
        }
    }


    async processExchangeShippingReturn(id: string) {

        const request = this._packageLogsService.getExchangesPackages(id);
        const currentDate = new Date().toISOString().split('T')[0];

        try {
            const result = await request.toPromise();
            const data = result['data'];
            console.log(data, "data")

            if (Array.isArray(data)) {
                for (const item of data) {

                    const packageOrder = item.packageOrder;
                    const packageDate = currentDate;
                    const exchanges = item.exchanges;
                    const isCancelled = true;


                    const packageId = item.id; // Rename to packageId to avoid conflict

                    const row = {
                        packageDate,
                        exchanges,
                        isCancelled,
                        packageOrder,

                    };


                    try {
                        const response = await this.updatePackage(packageId, row);

                        if (response.success) {
                            Swal.fire({
                                icon: 'success',
                                title: 'Success',
                                text: ` Cancled successfully `,
                                showConfirmButton: false,
                                backdrop: true,
                                allowOutsideClick: false,
                                position: 'top-end',
                                toast: true,
                                timer: 5000,
                            });
                            this.refreshSalesLogs();
                        }

                    } catch (error) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: `${error} `,
                            showConfirmButton: false,
                            backdrop: true,
                            allowOutsideClick: false,
                            position: 'top-end',
                            toast: true,
                            timer: 5000,
                        });
                    }
                }
            } else {
            }
        } catch (error) {
            console.error('Error occurred while processing data:', error);
            return []; // Return an empty array if an error occurs
        }

    }

    async singleCancle(id: any, type: any, payment_type: any) {
        this.roleService.withRoleCheck(this.requiredRolesCreation, async () => {

            if (type === 'sales-order') {
                if (payment_type === 'CASH ON DELIVERY') {

                    await this.processSalesOrderShippingReturn(id);
                } else {
                    await this.processSalesOrderShippingReturnPrePaid(id);
                }
            } else if (type === 'exchanges') {
                if (payment_type === 'CASH ON DELIVERY') {
                    await this.processExchangeShippingReturn(id);
                } else {
                    await this.processExchangeOrderShippingReturnPrePaid(id);
                }
            }
            this.refreshSalesLogs();
        });
    }








    /**
     * Open confirmation dialog
     */
    openConfirmationDialog(): Observable<boolean> {
        const dialogRef = this._fuseConfirmationService.open(this.configForm.value);

        return dialogRef.afterClosed().pipe(
            map(result => {
                // Check if result is undefined (close action)
                // or explicitly set to true (confirm action)
                return result !== undefined && !!result;
            })
        );
    }

    openDeliveryInformationDialog(): Observable<boolean> {
        const dialogRef = this._fuseConfirmationService.open(this.deliveryConfig.value);

        return dialogRef.afterClosed().pipe(
            map(result => {
                // Return false if the result is explicitly set to false (cancel action),
                // otherwise, return true for other cases (confirm action or close without a specific result).
                return result !== undefined ? !!result : false;
            })
        );
    }


    openShippingReturnInformationDailogC(): Observable<boolean> {
        const dialogRef = this._fuseConfirmationService.open(this.shippingCancleConfig.value);

        return dialogRef.afterClosed().pipe(
            map(result => {
                // Return false if the result is explicitly set to false (cancel action),
                // otherwise, return true for other cases (confirm action or close without a specific result).
                return result !== undefined ? !!result : false;
            })
        );
    }








    showSuccessToast() {
        Swal.fire({
            icon: 'success',
            title: 'Package Order Created!',
            text: 'Selected Sales Order has been shipped!!',
        });

        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
            },
        });

        Toast.fire({
            icon: 'success',
            title: 'Selected Sales Order has been shipped!!',
        });
    }

    showErrorToast(errors) {
        Swal.fire({
            icon: 'error',
            title: 'Error Creating Package Order',
            text: JSON.stringify(errors),
        });
    }



    async DeliverySheet() {
        this.roleService.withRoleCheck(this.requiredRoles, async () => {

            const selectedRowIds = this.getSelectedRowIds();
            const ids = selectedRowIds;
            const selectedRows = this.dataSource.data.filter(row => {
                const included = ids.includes(row.saleNumber);
                return included;
            });
            const allProcessedRows = [];


            for (const row of selectedRows) {
                const type = row.main_type;
                const status = row.status;

                if (type === 'sales-order') {

                    const processedRows = await this.processSalesOrderSheet(row.id);
                    allProcessedRows.push(...processedRows);



                } if (type === 'exchanges') {

                    const processedRows = await this.processExchangeSheet([row.id])
                    allProcessedRows.push(...processedRows);



                }

                if (type === 'pre-order') {

                    const processedRows = await this.processPreOrderSheet([row.id]);
                    allProcessedRows.push(...processedRows);




                }

            }

            await this.confirmAndPrintExcel(allProcessedRows);
        });


    }


    async confirmAndPrintExcel(allProcessedRows: any[]): Promise<void> {
        const confirmed = await this.openConfirmationDialog().toPromise();

        if (confirmed) {
            this.ExcelPrinting(allProcessedRows);
        }
    }


    ExcelPrinting(rows: any) {
        const headers = ['Date', 'Sales-Order-Number', 'Point of Sales', 'Customer-Name', 'Customer Phone', 'Customer ID', "Type", "Sales Person", 'Shipment-Area', 'Address', 'Delivery-Type', 'Payment-Type', 'Total', 'Remarks', 'Products'];

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet1');

        const headerStyle = {
            font: { size: 13, bold: true, color: { argb: 'FFFFFF' } },
            fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '000000' } },
            alignment: { horizontal: 'center' },
            border: { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } },
            padding: { top: 5, bottom: 5, left: 5, right: 5 },
        };
        const BigheaderStyle = {
            font: { size: 20, bold: true, color: { argb: 'FFFFFF' } },
            fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '000000' } },
            alignment: { horizontal: 'left' },
            border: { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } },
            padding: { top: 5, bottom: 5, left: 5, right: 5 },
        };

        const dataCellStyle = {
            font: { size: 12 },
            alignment: { horizontal: 'left' },
            border: { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } },
            padding: { top: 5, bottom: 5, left: 5, right: 5 },
        };

        // Add big header with spaces below
        const bigHeaderCellStart = worksheet.getCell('A1');
        const bigHeaderCellEnd = worksheet.getCell('Q5'); // Add 2 extra rows for spaces below
        bigHeaderCellStart.value = 'Sales Logs Sheet - HUBA';
        bigHeaderCellStart.font = { size: 25, bold: true };
        bigHeaderCellStart.alignment = { horizontal: 'center' };
        worksheet.mergeCells(bigHeaderCellStart.address, bigHeaderCellEnd.address);
        Object.assign(bigHeaderCellStart, BigheaderStyle);

        // Add headers to the worksheet with padding and borders
        worksheet.addRow([]); // Add a blank row as space after big header
        worksheet.addRow(headers).eachCell((cell) => {
            Object.assign(cell, headerStyle);
        });
        // Add data to the worksheet with padding and borders
        rows.forEach(row => {
            const rowData = [
                row.packageDate,
                row.salesOrderNumber,
                row.pos,
                row.customerName,
                row.customerPhone,
                row.customerId,
                row.type,
                row.sales_person,
                row.shippingArea,
                row.address,
                row.deliveryType,
                row.paymentType,
                row.total,
                row.customerRemarks,
                row.products,
            ];
            worksheet.addRow(rowData).eachCell((cell) => {
                Object.assign(cell, dataCellStyle);
            });
        });

        headers.forEach((header, columnIndex) => {
            const headerWidth = header.length;
            worksheet.getColumn(columnIndex + 1).width = Math.max(headerWidth + 3, 10);
        });

        worksheet.columns.forEach((column) => {
            column.eachCell({ includeEmpty: true }, (cell) => {
                const cellContentWidth = cell.value ? String(cell.value).length : 0;
                column.width = Math.max(cellContentWidth + 5, column.width || 15);
            });
        });

        worksheet.addRow([]);
        worksheet.addRow([]);
        const footerRow = worksheet.addRow(['Powered By HUBA']);
        footerRow.eachCell((cell) => {
            const footerStyle = {
                font: { size: 12, bold: true, color: { argb: 'FFFFFF' } },
                fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '000000' } },
                alignment: { horizontal: 'center' },
                border: { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } },
                padding: { top: 5, bottom: 5, left: 5, right: 5 },
            };
            Object.assign(cell, footerStyle);
        });
        // Create a blob and initiate file download
        workbook.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'GroupSales-Order.xlsx';
            a.click();
        });
    }




    async processSalesOrderSheet(id: any) {

        const request = this._salesOrderService.getSalesOrderById(id);

        const currentDate = new Date().toISOString().split('T')[0];

        try {
            const result = await request.toPromise();
            const productsData = result.products.map((product: any) => {
                return `${product.product?.base_product.productName} (Qty: ${product.quantity}, Size: ${product.product?.size}, Color: ${product.product?.color})`;
            }).join('\n');

            const salesOrderNumber = result.sales_order_num;
            const shippingArea = result.shipping_area;
            const address = result.address;
            const deliveryType = result.delivery_type;
            const customerId = "HUB" + result.Customers.id;
            const customerName = `${result.Customers.firstName} ${result.Customers.lastName}`;
            const customerPhone = result.Customers.phoneNumbers.length > 0 ? result.Customers.phoneNumbers[0].number : '';
            const customerRemarks = result.notes;
            const paymentType = result.payment_type;
            const packageOrder = this.generatePackageCode();
            const packageDate = currentDate;
            const total = result.total;
            const pos = result.pos;
            const sales_person = result.sales_person;
            const status = result.status

            const type = "SALES ORDER"
            const salesOrder = result.id;
            const isPackaged = true;
            const isShipped = true;
            const influencerOrder = '';
            const row = {
                packageDate,
                salesOrder,
                isPackaged,
                influencerOrder,
                salesOrderNumber,
                shippingArea,
                address,
                deliveryType,
                customerName,
                customerPhone,
                paymentType,
                packageOrder,
                customerRemarks,
                sales_person,
                type,
                status,
                total,
                customerId,
                pos,
                isShipped,
                products: productsData,
            };

            return [row]; // Return an array with a single row
        } catch (error) {
            console.error('Error occurred while processing data:', error);
            return []; // Return an empty array if an error occurs
        }
    }

    async processSalesOrderDelivery(id: any) {
        const request = this._packageLogsService.getSalesOrderPackages(id);
        const currentDate = new Date().toISOString().split('T')[0];

        try {
            const result = await request.toPromise();
            const data = result['data'];

            if (Array.isArray(data)) {
                for (const item of data) {
                    const packageOrder = item.packageOrder;
                    const packageDate = currentDate;
                    const salesOrder = item.salesOrder;
                    const isPackaged = true;
                    const isShipped = true;
                    const influencerOrder = '';
                    const isCreditAndDelivered = false;
                    const isDelivared = true;
                    const packageId = item.id;
                    const paid = true;


                    const row = {
                        packageDate,
                        salesOrder,
                        paid,
                        isPackaged,
                        influencerOrder,
                        packageOrder,
                        isShipped,
                        isDelivared,
                        isCreditAndDelivered
                    };


                    try {
                        const response = await this.updatePackage(packageId, row);

                        if (response) {
                            Swal.fire({
                                icon: 'success',
                                title: 'Success',
                                text: `Sales order Delivered successfully `,
                                showConfirmButton: false,
                                backdrop: true,
                                allowOutsideClick: false,
                                position: 'top-end',
                                toast: true,
                                timer: 5000,
                            });
                            this.refreshSalesLogs();
                        }

                    } catch (error) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: `${error} `,
                            showConfirmButton: false,
                            backdrop: true,
                            allowOutsideClick: false,
                            position: 'top-end',
                            toast: true,
                            timer: 5000,
                        });
                    }
                }
            } else {
                // Handle case when data is not an array
            }
        } catch (error) {
            console.error('Error occurred while processing data:', error);
            return []; // Return an empty array if an error occurs
        }
    }



    async processPreOrderDelivery(id: any) {
        const request = this._packageLogsService.getPreOrderPackages(id);
        const currentDate = new Date().toISOString().split('T')[0];

        try {
            const result = await request.toPromise();
            const data = result['data'];
            console.log(data, "data")

            if (Array.isArray(data)) {
                for (const item of data) {
                    const packageOrder = item.packageOrder;
                    const packageDate = currentDate;
                    const preOrder = item.preOrder;
                    const isPackaged = true;
                    const isShipped = true;
                    const influencerOrder = '';
                    const isCreditAndDelivered = false;
                    const isDelivared = true;
                    const packageId = item.id; // Rename to packageId to avoid conflict
                    const paid = true;
                    const row = {
                        packageDate,
                        preOrder,
                        isPackaged,
                        influencerOrder,
                        packageOrder,
                        isShipped,
                        isDelivared,
                        isCreditAndDelivered,
                        paid
                    };


                    try {
                        const response = await this.updatePackage(packageId, row);

                        if (response) {
                            Swal.fire({
                                icon: 'success',
                                title: 'Success',
                                text: ` Deliverey successfully `,
                                showConfirmButton: false,
                                backdrop: true,
                                allowOutsideClick: false,
                                position: 'top-end',
                                toast: true,
                                timer: 5000,
                            });
                            this.refreshSalesLogs();
                        }

                    } catch (error) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: `${error} `,
                            showConfirmButton: false,
                            backdrop: true,
                            allowOutsideClick: false,
                            position: 'top-end',
                            toast: true,
                            timer: 5000,
                        });
                    }
                }
            } else {
                // Handle case when data is not an array
            }
        } catch (error) {
            console.error('Error occurred while processing data:', error);
            return []; // Return an empty array if an error occurs
        }
    }


    async processPrDelivery(id: any) {

        try {



            const isCreditAndDelivered = false;
            const isDelivared = true;
            const paid = true;
            const row = {

                isDelivared,
                isCreditAndDelivered,
                paid
            };


            try {
                const response = await this.updatePackage(id, row);

                if (response) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: ` Deliverey successfully `,
                        showConfirmButton: false,
                        backdrop: true,
                        allowOutsideClick: false,
                        position: 'top-end',
                        toast: true,
                        timer: 5000,
                    });
                    this.refreshSalesLogs();
                }

            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: `${error} `,
                    showConfirmButton: false,
                    backdrop: true,
                    allowOutsideClick: false,
                    position: 'top-end',
                    toast: true,
                    timer: 5000,
                });
            }


        } catch (error) {
            console.error('Error occurred while processing data:', error);
            return []; // Return an empty array if an error occurs
        }
    }

    async processExchangeDelivery(id: any) {
        const request = this._packageLogsService.getExchangesPackages(id);
        const currentDate = new Date().toISOString().split('T')[0];

        try {
            const result = await request.toPromise();
            const data = result['data'];
            console.log(data, "data")

            if (Array.isArray(data)) {
                for (const item of data) {
                    const packageOrder = item.packageOrder;
                    const packageDate = currentDate;
                    const exchanges = item.exchanges;
                    const isPackaged = true;
                    const isShipped = true;
                    const influencerOrder = '';
                    const isCreditAndDelivered = false;
                    const isDelivared = true;
                    const packageId = item.id; // Rename to packageId to avoid conflict
                    const paid = true;
                    const row = {
                        packageDate,
                        exchanges,
                        isPackaged,
                        influencerOrder,
                        packageOrder,
                        isShipped,
                        isDelivared,
                        isCreditAndDelivered,
                        paid,
                    };


                    try {
                        const response = await this.updatePackage(packageId, row);

                        if (response.success) {
                            Swal.fire({
                                icon: 'success',
                                title: 'Success',
                                text: ` Deliverey successfully `,
                                showConfirmButton: false,
                                backdrop: true,
                                allowOutsideClick: false,
                                position: 'top-end',
                                toast: true,
                                timer: 5000,
                            });
                            this.refreshSalesLogs();
                        }

                    } catch (error) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: `${error} `,
                            showConfirmButton: false,
                            backdrop: true,
                            allowOutsideClick: false,
                            position: 'top-end',
                            toast: true,
                            timer: 5000,
                        });
                    }
                }
            } else {
            }
        } catch (error) {
            console.error('Error occurred while processing data:', error);
            return []; // Return an empty array if an error occurs
        }
    }





    async updatePackage(packageId: any, row: any) {
        const response = await this._packageService.updatePackageOrder(packageId, row);
        return response;
    }




    async processCreditNotesSheet(selectedRows: any[]) {
        const requests = selectedRows.map(id => this._creditNotesService.getSalesOrderById(id));
        const currentDate = new Date().toISOString().split('T')[0];


        try {
            const results = await forkJoin(requests).toPromise();

            const rows = results.map(data => {



                const salesOrderNumber = data.voucher_order_num;
                const shippingArea = data.shipping_area;
                const address = data.address;
                const deliveryType = data.delivery_type;
                const customerName = data.Customers.firstName + ' ' + data.Customers.lastName;
                const customerRemarks = data.notes;
                const paymentType = data.payment_type;
                const packageOrder = this.generatePackageCode();
                const packageDate = currentDate;
                const total = data.total;

                const creditNotes = data.id;
                const isPackaged = true; //set isPackage to true as it is confirmed
                const isShipped = true; //set isPackage to true as it is confirmed

                const influencerOrder = ''; // while creating the salesOrder package, influencer order is empty


                return {
                    packageDate,
                    creditNotes,
                    isPackaged,
                    isShipped,
                    influencerOrder,
                    salesOrderNumber,
                    shippingArea,
                    address,
                    deliveryType,
                    customerName,
                    paymentType,
                    packageOrder,
                    customerRemarks,
                    total,
                };
            });
            console.log(rows, "credit Notes rows")
            return rows;



        } catch (error) {
            console.error('Error occurred while processing data:', error);
        }
    }


    async processPreOrderSheet(selectedRows: any[]) {
        const requests = selectedRows.map(id => this._preOrderService.getPreOrdeByID(id));
        const currentDate = new Date().toISOString().split('T')[0];


        try {
            const results = await forkJoin(requests).toPromise();

            const rows = results.map(data => {
                const productsData = data.products.map((product: any) => {
                    return `${product.product?.base_product.productName} (Qty: ${product.quantity}, Size: ${product.product?.size}, Color: ${product.product?.color})`;
                }).join('\n');

                const salesOrderNumber = data.sales_order_num;
                const shippingArea = data.shipping_area;
                const address = data.address;
                const deliveryType = data.delivery_type;
                const customerName = `${data.Customers.firstName} ${data.Customers.lastName}`;
                const customerRemarks = data.notes;
                const paymentType = data.payment_type;
                const packageOrder = this.generatePackageCode();
                const packageDate = currentDate;
                const total = data.total;
                const pos = data.pos;
                const customerId = "HUB" + data.Customers.id
                const influencerOrder = '';
                const preOrder = data.id;
                const isPackaged = true;
                const isShipped = true;
                const customerPhone = data.Customers.phoneNumbers.length > 0 ? data.Customers.phoneNumbers[0].number : '';
                const sales_person = data.sales_person;
                const status = data.status

                const type = "PRE ORDER"
                return {
                    packageDate,
                    preOrder,
                    isPackaged,
                    isShipped,
                    influencerOrder,
                    salesOrderNumber,
                    pos,
                    shippingArea,
                    address,
                    deliveryType,
                    customerName,
                    paymentType,
                    packageOrder,
                    customerId,
                    customerPhone,
                    status,
                    sales_person,
                    customerRemarks,
                    total,
                    products: productsData,
                };
            });

            return rows;



        } catch (error) {
            console.error('Error occurred while processing data:', error);
        }
    }


    async processExchangeSheet(selectedRows: any[]) {
        const requests = selectedRows.map(id => this._exchangeService.getSalesOrderById(id));
        const currentDate = new Date().toISOString().split('T')[0];

        try {
            const results = await forkJoin(requests).toPromise();

            const rows = results.map(data => {
                const productsData = data.exchange_products.map((product: any) => {
                    return `${product.product?.base_product.productName} (Qty: ${product.quantity}, Size: ${product.product?.size}, Color: ${product.product?.color})`;
                }).join('\n');

                console.log(productsData, "data")

                const salesOrderNumber = data.exchange_number;
                const shippingArea = data.sales_order.shipping_area;
                const address = data.sales_order.address;
                const deliveryType = data.sales_order.delivery_type;
                const customerPhone = data.sales_order.Customers.phoneNumbers.length > 0 ? data.sales_order.Customers.phoneNumbers[0].number : '';
                const customerId = "HUB" + data.sales_order.Customers.id;
                const customerName = data.sales_order.Customers.firstName + ' ' + data.sales_order.Customers.lastName;
                const customerRemarks = data.sales_order.notes;
                const paymentType = data.sales_order.payment_type;
                const packageOrder = this.generatePackageCode();
                const packageDate = currentDate;
                const sales_person = data.sales_order.sales_person;
                const status = data.status

                const type = "EXCHANGE"
                const total = data.total;
                const pos = data.sales_order.pos;
                const exchanges = data.id;
                const isPackaged = true; //set isPackage to true as it is confirmed
                const isShipped = true; //set isPackage to true as it is confirmed
                const isExchange = true;
                const influencerOrder = ''; // while creating the salesOrder package, influencer order is empty

                return {
                    packageDate,
                    exchanges,
                    isPackaged,
                    isShipped,
                    influencerOrder,
                    isExchange,
                    salesOrderNumber,
                    shippingArea,
                    customerPhone,
                    customerId,
                    address,
                    deliveryType,
                    customerName,
                    paymentType,
                    packageOrder,
                    customerRemarks,
                    type,
                    sales_person,
                    status,
                    total,
                    pos,
                    products: productsData,

                };
            });

            return rows;



        } catch (error) {
            console.error('Error occurred while processing data:', error);
        }
    }

    generatePackageCode(): string {
        this.counter++;
        const randomPart = Array.from(
            { length: 8 },
            () => Math.random().toString(36).toUpperCase()[2]
        ).join('');
        const packageCode = `PKG-${String(this.counter).padStart(
            4,
            '0'
        )}-${randomPart}`;
        return packageCode;
    }









}
