        <!-- Scheme -->
        <div class="grid grid-cols-3 gap-3 justify-items-start mt-2">
            <!-- Auto -->
            <div
                class="flex items-center py-3 pl-5 pr-6 rounded-full cursor-pointer ring-inset ring-primary bg-hover"
                [class.ring-2]="config.scheme === 'auto'"
               
                (click)="setScheme('auto')">
                <div class="flex items-center rounded-full overflow-hidden">
                    <mat-icon
                        class="icon-size-5"
                        [svgIcon]="'heroicons_solid:lightning-bolt'"></mat-icon>
                </div>
                <div
                    class="flex items-center ml-2 font-medium leading-5"
                    [class.text-secondary]="config.scheme !== 'auto'">
                    Auto
                </div>
            </div>
            <!-- Dark -->
            <div
                class="flex items-center py-3 pl-5 pr-6 rounded-full cursor-pointer ring-inset ring-primary bg-hover"
                [class.ring-2]="config.scheme === 'dark'"
                (click)="setScheme('dark')">
                <div class="flex items-center rounded-full overflow-hidden">
                    <mat-icon
                        class="icon-size-5"
                        [svgIcon]="'heroicons_solid:moon'"></mat-icon>
                </div>
                <div
                    class="flex items-center ml-2 font-medium leading-5"
                    [class.text-secondary]="config.scheme !== 'dark'">
                    Dark
                </div>
            </div>
            <!-- Light -->
            <div
                class="flex items-center py-3 pl-5 pr-6 rounded-full cursor-pointer ring-inset ring-primary bg-hover"
                [class.ring-2]="config.scheme === 'light'"
                (click)="setScheme('light')">
                <div class="flex items-center rounded-full overflow-hidden">
                    <mat-icon
                        class="icon-size-5"
                        [svgIcon]="'heroicons_solid:sun'"></mat-icon>
                </div>
                <div
                    class="flex items-center ml-2 font-medium leading-5"
                    [class.text-secondary]="config.scheme !== 'light'">
                    Light
                </div>
            </div>
        </div>