import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vendors-credit.module',
  templateUrl: './vendors-credit.module.component.html',
  styleUrls: ['./vendors-credit.module.component.scss']
})
export class VendorsCredit implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
