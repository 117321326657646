import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WmCanLeaveComponent } from './wm-can-leave/wm-can-leave.component';
import { MaterialModule } from 'app/modules/material/material.module';



@NgModule({
  declarations: [
    WmCanLeaveComponent
  ],
  imports: [
    MaterialModule,

    CommonModule,
  ]
})
export class PageDetectModule { }
