import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, Validators, FormsModule, ReactiveFormsModule, FormGroup, FormControl} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatStepper, MatStepperModule} from '@angular/material/stepper';
import {MatButtonModule} from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';



/**
 * @title Stepper overview
 */
@Component({
  selector: 'horizontalstepper-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css'],
  standalone: true,
  imports: [
    MatButtonModule,
    MatStepperModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule
  ],
})
export class StepperOverviewExample implements OnInit {

 

  @Input('fb') formGroup : FormGroup;

  @ViewChild('stepper') stepper: MatStepper;
   
  billingAddress : FormControl;
  shippingArea : FormControl;


  ngOnInit(): void {

    console.log('TEST',this.formGroup)
    this.formGroup.get('billingAddress').setValue(this.formGroup.get('billingAddress').value || this.billingAddress)
    this.formGroup.get('shippingArea').setValue(this.formGroup.get('shippingArea').value || this.shippingArea)

  }

  submit(): void {

  }

  isLinear = false;
  showDependentField = false;

  constructor(private _formBuilder: FormBuilder) {}

  onFirstCtrlChange() {
    const selectedValue = this.formGroup.get('shippingArea').value;
    this.showDependentField = selectedValue === 'option1';
  }
}

