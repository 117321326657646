import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { InventroySheetComponent } from './inventroy-sheet/inventroy-sheet.component';
import { ListinventroySheetComponent } from './listinventroy-sheet/listinventroy-sheet.component';
import { CreateinventroySheetComponent } from './createinventroy-sheet/createinventroy-sheet.component';
import { DetailinventroySheetComponent } from './detailinventroy-sheet/detailinventroy-sheet.component';
import { MaterialModule } from 'app/modules/material/material.module';
import { RouterModule } from '@angular/router';
import { InventroySheetRoutes } from './inventory-sheet.routing';
import { StockIndicatorDirective } from './listinventroy-sheet/StockIndicatorDirective';
import { AdvanceFilteringComponent } from './advance-filtering/advance-filtering.component';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { InitalstockPopupComponent } from './initalstock-popup/initalstock-popup.component';
import { NgApexchartsModule } from 'ng-apexcharts';



@NgModule({
  declarations: [
    InventroySheetComponent,
    ListinventroySheetComponent,
    CreateinventroySheetComponent,
    DetailinventroySheetComponent,
    StockIndicatorDirective,
    AdvanceFilteringComponent,
    InitalstockPopupComponent,
  ],
  imports: [
    RouterModule.forChild(InventroySheetRoutes),
    CommonModule,
    MaterialModule,
    NgApexchartsModule


  ],
  providers: [
    {
        provide: MAT_DATE_FORMATS,
        useValue: {
            parse: {
                dateInput: "YYYY-MM-DD"
            },
            display: {
                dateInput: 'YYYY-MM-DD',
                monthYearLabel: 'MMM YYYY',
                dateA11yLabel: 'LL',
                monthYearA11yLabel: 'MMMM YYYY'

            }
        }
    },
    DatePipe,
],  
})
export class InventroySheetModule { }
