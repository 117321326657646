import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PrPackages } from './pr-packages.types';
import { PrPackagesService } from './pr-packages.service';


@Injectable({
  providedIn: 'root'
})
export class SalesOrderResolver implements Resolve<PrPackages[]> {

  constructor(private salesOrderService: PrPackagesService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PrPackages[]> {
    return this.salesOrderService.getSalesOrders().pipe(
      catchError((error) => {
        console.error('Error fetching sales orders', error);
        return of([]);
      })
    );
  }
}
