<!-- vendorsCredit form -->
<mat-dialog-content class="dialog-content">

    <form [formGroup]="vendorsCredit" class="" (keydown.enter)="$event.preventDefault()">
        <!-- Avatar -->
        <!-- Avatar code goes here -->

        <!-- Personal Information Form -->
        <div *ngIf="vendorsCredit" class="p-8">
            <div class="flex justify-between items-center">
                <!-- Total Amount Display -->
                <div class="flex items-center">

                </div>
                <!-- Close Button -->
                <button mat-raised-button (click)="_matDialog.close()" mat-icon-button>
                    <mat-icon svgIcon="feather:x"></mat-icon>
                </button>



            </div>

            <div class="flex flex-col mt-3">
                <p class="text-lg font-medium">Refund Information </p>
                <p class="text-secondary"> This form is to set the refund information for the customer to whom this
                    voucher is assigned. Please ensure all details are filled accurately, including the refund date,
                    payment type, and the total amount to be refunded. Verify that the description clearly outlines the
                    reason for the refund. The remaining balance will be displayed at the top for reference.
                </p>

                <div class="flex justify-end ">

                    <p style="color:rgb(255, 104, 99) ;" class="text-lg mt-2 
                font-medium ">
                        <u>
                            <i>
                                Voucher Balance: {{ credit_noteTotal }}
                            </i>
                        </u>
                    </p>
                </div>
                <mat-divider class="mt-3 mb-3"></mat-divider>

                <!-- Refund Date -->
                <div class="flex mb-2">
                    <mat-form-field style="max-width: 600px"
                        class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix mr-3">
                        <mat-label>Refund Date</mat-label>
                        <input matInput [matDatepicker]="picker2" placeholder="Refund Date" name="refund_date"
                            [formControlName]="'refund_date'" class="elevated-input" mask="00/00/0000" required
                            (dateChange)="formatDate($event.value, 'yyyy-MM-dd', 'refund_date')" />
                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                        <mat-icon class="icon-size-5" matPrefix svgIcon="heroicons_solid:calendar"></mat-icon>
                    </mat-form-field>

                    <!-- Payment Type -->
                    <mat-form-field style="max-width: 600px"
                        class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix">
                        <mat-label class="ml-3">Payment Type</mat-label>
                        <mat-select [ngClass]="formFieldHelpers" placeholder="Payment Method" name="paymentType"
                            [formControlName]="'payment_type'" class="elevated-input">
                            <mat-option *ngFor="let option of paymentOptions" [value]="option">{{ option }}</mat-option>
                        </mat-select>
                        <mat-icon class="icon-size-4" matPrefix svgIcon="payments"></mat-icon>

                        <mat-error class="m-1 ml-5 mt-2"
                            *ngIf="vendorsCredit?.get('payment_type')?.invalid && vendorsCredit?.get('payment_type')?.touched">
                            <ng-container *ngIf="vendorsCredit?.get('payment_type')?.hasError('required')">
                                Payment Type is required.
                            </ng-container>
                        </mat-error>
                    </mat-form-field>
                </div>

                <!-- Description -->
                <div class="flex mb-2">
                    <mat-form-field style="max-width: 600px" class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix mr-3">
                        <mat-label>Description</mat-label>
                        <textarea matInput placeholder="Description" name="description" [formControlName]="'description'" class="elevated-input"></textarea>
                        <mat-icon class="icon-size-5" matPrefix svgIcon="feather:hash"></mat-icon>
                    </mat-form-field>
                </div>
                
                <div class="flex mb-2">

                    <mat-form-field style="max-width: 300px"
                        class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix">
                        <input matInput placeholder="Total" type="number" required name="total" formControlName="amount"
                            class="elevated-input" />
                        <span matPrefix>रु</span>
                        <span matSuffix>.00</span>
                        <mat-hint class="mr-3 mt-5">
                            Amount should not exceed {{ credit_noteTotal }}
                        </mat-hint>
                    </mat-form-field>
                   
                </div>

                <!-- Action Buttons -->
                <div class="flex items-center justify-start mt-5">
                        <button style="margin: 5px" (click)="saveRefund()"
                            class="px-6 py-2 rounded text-white bg-blue-500 hover:bg-green-600 ml-5" mat-flat-button
                            type="submit" color="'accent'">Save</button>

                    <button (click)="_matDialog.close();"
                        class="px-6 py-2 rounded text-white bg-red-500 hover:bg-yellow-600 ml-5" mat-flat-button
                        color="'accent'">Cancel</button>
                </div>
            </div>
        </div>
    </form>
</mat-dialog-content>