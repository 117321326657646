
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { server_env } from 'config';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FabricService {
  private _fabric: BehaviorSubject<any | null> = new BehaviorSubject(null);
  private _fabrics: BehaviorSubject<any[] | null> = new BehaviorSubject(null);

  public url = `${server_env.URL}api/v1/products-fabric/`;
  public Detailurl = `${server_env.URL}api/v1/products-fabrics/`;

  constructor(private _httpClient: HttpClient) { }

  get fabric$(): Observable<any> {
    return this._fabric.asObservable();
}

/**
 * Getter for contacts
 */
get fabrics$(): Observable<any[]> {
    return this._fabrics.asObservable();
}



getfabrics(): Observable<any[]> {
  return this._httpClient.get<any[]>(`${this.url}`).pipe(
      tap((fabrics: any) => {
          this._fabrics.next(fabrics.data);

          for (fabrics in fabrics.data) {
             
          }
      })
  );
}
createfabric(data): Promise<any> {
    return new Promise((resolve, reject) => {
        const requestUrl = this.url;

        this._httpClient.post<any>(`${requestUrl}`, data).subscribe(
            (response) => {
                return resolve(response);
            },
            (error) => {
                return reject(error);
            }
        );
    });
}

/**
* Get fabric by id
*/
getfabricById(id: any): Observable<any> {
  const requestUrl = this.Detailurl + id;
 
  return this._httpClient.get<any[]>(`${requestUrl}/`).pipe(
      take(1),
      map((fabric: any) => {
          this._fabric.next(fabric);

          // Return the fabric
          return fabric;
      }),
      switchMap((fabric) => {
          if (!fabric) {
              return throwError(
                  'Could not found contact with id of ' + id + '!'
              );
          }

          return of(fabric);
      })
  );

}


   /**
     * Delete the fabric
     *
     * @param id
     */
    deletefabric(id: string): Observable<boolean> {
      return this.fabrics$.pipe(
          take(1),
          switchMap((fabric) =>
              this._httpClient.delete(`${this.url}${id}/`).pipe(
                  map((isDeleted: boolean) => {
                      // Find the index of the deleted fabric
                      const index = fabric.findIndex(
                          (item) => item.id === id
                      );

                      // Delete the fabric
                      fabric.splice(index, 1);

                      // Update the fabric
                      this._fabrics.next(fabric);

                      // Return the deleted fabric
                      return isDeleted;
                  })
              )
          )
      );
  }
}
