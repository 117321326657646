import { Route } from '@angular/router';


import { ListSizeComponent } from './list-size/list-size.component';
import { CreateSizeComponent } from './create-size/create-size.component';
import { DetailSizeComponent } from './detail-size/detail-size.component';



export const SizeRouting: Route[] = [
   
        {
            path     : '',
            component: ListSizeComponent,
        },
        {
            path         : 'NEW',
            component    : CreateSizeComponent,
           
        },
        {
            path         : ':id',
            component    :DetailSizeComponent,
          
          
        },
]
