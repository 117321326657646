import {
    AfterViewInit,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import * as ExcelJS from 'exceljs';
import { DatePipe } from '@angular/common';
import { SelectionModel } from '@angular/cdk/collections';
import { PreOrdersService } from '../pre-orders.service';
import { PreOrders } from '../pre-orders.type';
import { PackagesOderService } from 'app/packages/packages.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { MatSnackBar } from '@angular/material/snack-bar';
import { and } from '@angular/fire/firestore';
import { data } from 'cypress/types/jquery';
import { RoleService } from 'app/core/auth/guards/role.service';



export interface SalesOrderData {
    customerId: string;
    customerName: string;
    shipping_area: string;
    delivery_type: string;
    saleNumber: string;
    status: string;
    shippingDate: any;
    products: any;
    totalCost: number;
    isExpanded: boolean;
}

@Component({
    selector: 'app-list-pre-orders',
    templateUrl: './list-pre-orders.component.html',
    styleUrls: ['./list-pre-orders.component.scss'],
})
export class ListPreOrdersComponent implements OnInit, AfterViewInit, OnDestroy {
    displayedColumns: string[] = [
        "select",
        'CustomerId',
        'CustomerName',
        'SaleNumber',
        'Status',
        'Shipping_Date',
        "Delivery Type",
        "Shipping Area",
        'total',
        'actions',
        'create',
    ];

    vendor: any;
    counter: number = 0;
    @ViewChild('printableContent') printableContent: ElementRef;
    tooltipActive: boolean = false;

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    salesOrders: PreOrders[];
    dataSource: MatTableDataSource<SalesOrderData>;
    @ViewChild('salesOrderTabel') salesOrderTabel: ElementRef;
    salesorder$: Observable<PreOrders[]>;
    flashMessage: 'success' | 'error' | null = null;
    isLoading: boolean = false;
    dropdownHidden: boolean = true;
    fileName = 'PreSalesOrderSheet.xlsx';
    numberOfCustomers: number;
    numberOfProducts: number;
    highestSellerCustomerId: string;
    biggestSellerProductId: string;
    completedSalesOrdersCount: number;
    startedSalesOrdersCount: number;
    checked = false;
    selectedDate: any;
    totalRows: number = 0;
    selectedShippingArea: string;
    selectedDeliveryType: string;
    selectedRowIds: any[] = [];
    selectAll: boolean = false;
    selection = new SelectionModel<SalesOrderData>(true, []);
    configForm: FormGroup;
    requiredRoles: string[] = ["Manager", "Operation Manager", "HR"];  // Define the roles allowed to execute the function
    requiredRolesCreation: string[] = ["Manager", "Communication Manager", "HR"];  // Define the roles allowed to execute the function
    shipmentMessage: string = '';
    constructor(
        private _router: Router,
        private _salesorderService: PreOrdersService,
        private datePipe: DatePipe,
        private _packageService: PackagesOderService,
        private _formBuilder: FormBuilder,
        private _fuseConfirmationService: FuseConfirmationService,
        private _snackBar: MatSnackBar,
        private roleService: RoleService,



    ) {

    }




    ngOnInit(): void {
        this.loadData();
        this.configForm = this._formBuilder.group({
            title: 'Info',
            message: 'Released Pre-order products will only be shipped.<br>Do you still want to proceed?',
            icon: this._formBuilder.group({
                show: true,
                name: 'heroicons_outline:exclamation',
                color: 'info'
            }),
            actions: this._formBuilder.group({
                confirm: this._formBuilder.group({
                    show: true,
                    label: 'Proceed',
                    color: 'accent'
                }),
                cancel: this._formBuilder.group({
                    show: true,
                    label: 'Cancel',
                    color: 'warn'
                }),

            }),
            dismissible: true
        });
    }


    async multipleInvoice() {
        const ids = this.selectedRowIds;
        let combinedContent = ''; // Variable to accumulate content for all pages

        // Iterate over each selected ID
        for (const id of ids) {
            try {
                const data = await this._salesorderService.getpreorderById(id).toPromise();
                this.vendor = data; // Set vendor data for the app-invoice component

                await this.delay(100);

                const printableContent = this.printableContent.nativeElement.innerHTML;

                // Wrap each page content in a container with a unique class or identifier
                combinedContent += `<div class="page-container">${printableContent}</div><div class="page-break"></div>`;
            } catch (error) {
                console.error('Error fetching sales order:', error);
            }
        }

        // Create a new div element for the combined content
        const combinedPageContent = document.createElement('div');
        combinedPageContent.innerHTML = combinedContent;

        // Append the new combined content to the document body
        document.body.appendChild(combinedPageContent);

        // Trigger print operation
        window.print();

        // Remove the appended content after printing to avoid duplication
        document.body.removeChild(combinedPageContent);

        // Wait for a longer time to ensure the print dialog opens and user interacts with it
        await this.delay(1000); // Adjust the delay as needed
    }









    delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    multiplePackage() {
        this.roleService.withRoleCheck(this.requiredRoles, async () => {

            let Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.onmouseenter = Swal.stopTimer;
                    toast.onmouseleave = Swal.resumeTimer;
                }
            });

            const ids = this.selectedRowIds;

            const requests = ids.map(id => this._salesorderService.getPreOrdeByID(id));

            forkJoin(requests).subscribe(
                async (results: any[]) => {
                    console.log('Fetched Pre-Orders:', results);

                    try {
                        const assumedInventoryResultsArray: { isValid: boolean, productId: number, quantity: number }[][] =
                            await Promise.all(results.map(data => this.checkAssummedStock(data)));

                        console.log('Assumed Inventory Results Array:', assumedInventoryResultsArray);

                        const allProductsValid = assumedInventoryResultsArray.every(resultArray =>
                            resultArray.every(result => !result.isValid)
                        );

                        console.log('All Products Valid:', allProductsValid);

                        if (allProductsValid) {
                            results.forEach((data, index) => {
                                assumedInventoryResultsArray[index].forEach(result => {
                                    if (!result.isValid) {
                                        console.log(`Deducting stock for Product ID: ${result.productId}, Quantity: ${result.quantity}`);
                                        this._salesorderService.deductStock(result.productId, result.quantity).subscribe(
                                            (response) => {
                                                console.log('Stock deducted for Product ID:', result.productId);
                                            },
                                            (error) => {
                                                console.error('Error deducting stock:', error);
                                            }
                                        );
                                    }
                                });
                            });

                            this.packagePreOrder(results);
                        } else {
                            Toast.fire({
                                icon: 'error',
                                title: 'Some products have insufficient assumed inventory'
                            });
                        }
                    } catch (error) {
                        console.error('Error checking assumed stock:', error);
                    }
                },
                error => {
                    console.error('Error fetching pre-orders:', error);
                }
            );
        });
    }



    async openConfirmationDialog(): Promise<'cancel' | 'proceed' | 'proceedAll'> {
        const dialogRef = this._fuseConfirmationService.open(this.configForm.value);

        const result = await dialogRef.afterClosed().toPromise();
        console.log(result, "main result")

        if (result === 'confirmed') {

            return 'proceed';

        }

        else {
            return 'cancel';
        }
    }

    async packagePreOrder(results: any) {

        const rows: any[] = [];
        const currentDate = new Date().toISOString().split('T')[0];

        results.forEach(data => {
            const productsData = data.products.map((product: any) => {
                return {
                    productName: product.product?.productName,
                    quantity: product.quantity,
                    amount: product.amount,
                    size: product.product?.size,
                    color: product.product?.color,
                    preOrderToggle: product.product?.preOrder,
                    expectedReleaseDate: product.product?.base_product?.expected_release_date,
                };
            });

            const salesOrderNumber = data.sales_order_num;
            const shippingArea = data.shipping_area;
            const address = data.address;
            const deliveryType = data.delivery_type;
            const customerName = data.Customers.firstName + ' ' + data.Customers.lastName;
            const customerRemarks = data.notes;
            const paymentType = data.payment_type;
            const packageOrder = this.generatePackageCode();
            const packageDate = currentDate;
            const total = data.total;
            const total_remaining_total = data.total

            const preOrder = data.id;
            const isPackaged = true;
            const isShipped = true;

            const influencerOrder = '';


            const productRows = productsData.map(product => {
                return `
                Product Name: ${product.productName} <br>
                Quantity: ${product.quantity} <br>
                Size : ${product.size} Color :${product.color}
                `;
            }).join('<br><br>');

            // Add data to the rows array
            rows.push({
                packageDate,
                preOrder,
                isPackaged,
                isShipped,
                influencerOrder,
                salesOrderNumber,
                shippingArea,
                address,
                deliveryType,
                customerName,
                paymentType,
                packageOrder,
                customerRemarks,
                total,
                total_remaining_total,
                products: productRows,
            });
        });
        for (const row of rows) {
            const packageResult = await this.createPackageOrder(row);
            if (packageResult.success) {
                // Show success notification using Swal
                Swal.fire({
                    icon: 'success',
                    title: 'Package Order Created!',
                    text: packageResult.message,
                });
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener(
                            'mouseenter',
                            Swal.stopTimer
                        );
                        toast.addEventListener(
                            'mouseleave',
                            Swal.resumeTimer
                        );
                    },
                });

                Toast.fire({
                    icon: 'success',
                    title: `Selected Sales Order has been shipped!!`,
                });
            } else {
                // Show error notification using Swal
                Swal.fire({
                    icon: 'error',
                    title: 'Error Creating Package Order',
                    text: JSON.stringify(packageResult.errors),
                });
            }
            this._router.navigate(['apps/sales-logs']);
        }
    }



    checkAssummedStock(data: any): Promise<{ isValid: boolean, productId: number, quantity: number }[]> {
        console.log('Checking assumed stock for data:', data);

        if (!data || !data.products) {
            console.error('Data or products are undefined:', data);
            return Promise.resolve([]);
        }

        const observables = data.products.map((product: any) => {
            const productId = product.product?.id;
            const quantity = product.quantity;

            console.log(`Checking assumed stock for Product ID: ${productId}, Quantity: ${quantity}`);

            if (product.product?.preOrder === false) {
                console.log(`Product ID: ${productId} is not a pre-order product.`);
                return of({ isValid: false, productId, quantity });
            }

            return this._salesorderService.getStockReport(productId).pipe(
                map((assumedStock: any) => {
                    console.log(`Assumed stock for Product ID: ${productId}:`, assumedStock);
                    if (assumedStock && assumedStock.stock !== undefined) {
                        // Corrected condition to check if quantity is greater than stock
                        if (quantity > assumedStock.stock) {
                            console.log(`Product ID: ${productId} has insufficient stock.`);
                            return { isValid: true, productId, quantity };
                        }
                    } else {
                        console.log(`Assumed stock for Product ID: ${productId} is undefined.`);
                        return { isValid: true, productId, quantity };
                    }
                    return { isValid: false, productId, quantity };
                }),
                catchError(error => {
                    console.error('Error in getStockReport:', error);
                    return of({ isValid: true, productId, quantity });
                })
            );
        });

        return forkJoin(observables).toPromise() as Promise<{ isValid: boolean, productId: number, quantity: number }[]>;
    }

    async singlePackage(orderId: any) {
        this.roleService.withRoleCheck(this.requiredRoles, async () => {

            try {
                const currentDate = new Date().toISOString().split('T')[0];
                let canShip = true;

                const data = await this._salesorderService.getPreOrdeByID(orderId).toPromise();

                const productsData = data.products.map((product: any) => {
                    return {
                        productId: product.product.id,
                        productName: product.product?.base_product?.productName,
                        quantity: product.quantity,
                        amount: product.amount,
                        size: product.product?.size,
                        color: product.product?.color,
                        preOrder: product.product?.preOrder,
                        expectedReleaseDate: product.product?.base_product?.expected_release_date,
                    };
                });

                // Check assumed stock for each product
                const assumedInventoryResults = await Promise.all(productsData.map(product => this.checkAssummedStock(product)));

                const allProductsValid = assumedInventoryResults.every(result => result === true);

                if (allProductsValid) {
                    // Deduct stock after validation
                    for (const product of productsData) {
                        await this._salesorderService.deductStock(product.productId, product.quantity).toPromise();
                        console.log('Stock deducted for product ID:', product.productId);
                    }

                    const salesOrderNumber = data.sales_order_num;
                    const shippingArea = data.shipping_area;
                    const address = data.address;
                    const deliveryType = data.delivery_type;
                    const customerName = data.Customers.firstName + ' ' + data.Customers.lastName;
                    const customerRemarks = data.notes;
                    const paymentType = data.payment_type;
                    const packageOrder = this.generatePackageCode();
                    const packageDate = currentDate;
                    const total = data.total;

                    const preOrder = data.id;
                    const isPackaged = true;
                    const isShipped = true;
                    const influencerOrder = '';

                    const productRows = productsData.map(product => {
                        return `
                        Product Name: ${product.productName} <br>
                        Quantity: ${product.quantity} <br>
                        Size: ${product.size} Color: ${product.color}
                    `;
                    }).join('<br><br>');

                    const row = {
                        packageDate,
                        preOrder,
                        isPackaged,
                        isShipped,
                        influencerOrder,
                        salesOrderNumber,
                        shippingArea,
                        address,
                        deliveryType,
                        customerName,
                        paymentType,
                        packageOrder,
                        customerRemarks,
                        total,
                        products: productsData,
                    };

                    const packageResult = await this.createPackageOrder(row);

                    if (packageResult.success) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Package Order Created!',
                            text: packageResult.message,
                        });

                        const Toast = Swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener(
                                    'mouseenter',
                                    Swal.stopTimer
                                );
                                toast.addEventListener(
                                    'mouseleave',
                                    Swal.resumeTimer
                                );
                            },
                        });

                        Toast.fire({
                            icon: 'success',
                            title: `Selected Sales Order has been shipped!!`,
                        });

                        this._router.navigate(['apps/sales-logs']);
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error Creating Package Order',
                            text: JSON.stringify(packageResult.errors),
                        });
                    }
                } else {
                    Swal.fire({
                        title: ' ',
                        text: ' ',
                        html: `
                    <div style="display: flex; align-items: center; margin-left:50px;">
                    <img src="http://localhost:4200/assets/images/logo/logo-dark.svg" alt="LOGO" style="width: 40px; height: 40px; margin-right: 10px;">
                    <div style="font-weight: bold;">Huba Admin</div>
                </div>
                <div style="margin-top: 10px;">
                    <div style="display: flex; align-items: center;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="margin-right: 5px;">
                            <circle cx="12" cy="12" r="10"/>
                            <line x1="12" y1="16" x2="12" y2="12"/>
                            <line x1="12" y1="8" x2="12" y2="8"/>
                        </svg>
                        <span style="font-weight: bold; margin-left: 5px; margin-bottom:10px;">Improper Packing</span>
                    </div>
                    <div>There are pre-order products which are not released for packaging</div>
                </div>
                    `,
                        showConfirmButton: false,
                        toast: true,
                        position: 'top',
                        timer: 3000,
                        didClose: () => {
                            this.shipmentMessage = 'You can ship products that are not released yet. Consult before taking this step.';
                            this.enableTooltip();
                            setTimeout(() => {
                                this.disableTooltip();
                                this.shipmentMessage = '';
                            }, 3000);
                        }
                    });
                }
            } catch (error) {
                console.error('Error fetching or processing sales order details:', error);
            }
        });
    }





    disableTooltip() {
        this.tooltipActive = false;
    }
    enableTooltip() {
        this.tooltipActive = true;
    }

    async DeliverySheet() {
        this.roleService.withRoleCheck(this.requiredRoles, async () => {

            const ids = this.selectedRowIds;
            const currentDate = new Date().toISOString().split('T')[0];

            const requests = ids.map(id => this._salesorderService.getPreOrdeByID(id));

            try {
                const results = await forkJoin(requests).toPromise();

                const rows = results.map(data => {
                    const productsData = data.products.map((product: any) => {
                        return `${product.product?.base_product.productName} (Qty: ${product.quantity}, Size: ${product.product?.size}, Color: ${product.product?.color})`;
                    }).join('\n');

                    const salesOrderNumber = data.sales_order_num;
                    const shippingArea = data.shipping_area;
                    const address = data.address;
                    const deliveryType = data.delivery_type;
                    const customerName = `${data.Customers.firstName} ${data.Customers.lastName}`;
                    const customerRemarks = data.notes;
                    const paymentType = data.payment_type;
                    const packageOrder = this.generatePackageCode();
                    const packageDate = currentDate;
                    const total = data.total;

                    const salesOrder = data.id;
                    const isPackaged = true;
                    const influencerOrder = '';

                    return {
                        packageDate,
                        salesOrder,
                        isPackaged,
                        influencerOrder,
                        salesOrderNumber,
                        shippingArea,
                        address,
                        deliveryType,
                        customerName,
                        paymentType,
                        packageOrder,
                        customerRemarks,
                        total,
                        products: productsData,
                    };
                });
                const headers = ['Date', 'Sales-Order-Number', 'Shipment-Area', 'Delivery-Type', 'Customer-Name', 'Payment-Type', 'Remarks', 'Products'];

                // Create a new workbook and worksheet
                const workbook = new ExcelJS.Workbook();
                const worksheet = workbook.addWorksheet('Sheet1');

                // Define styles for header, data cells, and footer
                const headerStyle = {
                    font: { size: 13, bold: true, color: { argb: 'FFFFFF' } },
                    fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '000000' } },
                    alignment: { horizontal: 'center' },
                    border: { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } },
                    padding: { top: 5, bottom: 5, left: 5, right: 5 },
                };
                const BigheaderStyle = {
                    font: { size: 20, bold: true, color: { argb: 'FFFFFF' } },
                    fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '000000' } },
                    alignment: { horizontal: 'left' },
                    border: { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } },
                    padding: { top: 5, bottom: 5, left: 5, right: 5 },
                };

                const dataCellStyle = {
                    font: { size: 12 },
                    alignment: { horizontal: 'left' },
                    border: { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } },
                    padding: { top: 5, bottom: 5, left: 5, right: 5 },
                };

                // Add big header with spaces below
                const bigHeaderCellStart = worksheet.getCell('A1');
                const bigHeaderCellEnd = worksheet.getCell('K5'); // Add 2 extra rows for spaces below
                bigHeaderCellStart.value = 'Delivery Sheet -HUBA';
                bigHeaderCellStart.font = { size: 25, bold: true };
                bigHeaderCellStart.alignment = { horizontal: 'center' };
                worksheet.mergeCells(bigHeaderCellStart.address, bigHeaderCellEnd.address);
                Object.assign(bigHeaderCellStart, BigheaderStyle);

                // Add headers to the worksheet with padding and borders
                worksheet.addRow([]); // Add a blank row as space after big header
                worksheet.addRow(headers).eachCell((cell) => {
                    Object.assign(cell, headerStyle);
                });
                // Add data to the worksheet with padding and borders
                rows.forEach(row => {
                    const rowData = [
                        row.packageDate,
                        row.salesOrderNumber,
                        row.shippingArea,
                        row.deliveryType,
                        row.customerName,
                        row.paymentType,
                        row.customerRemarks,
                        row.products,
                    ];
                    worksheet.addRow(rowData).eachCell((cell) => {
                        Object.assign(cell, dataCellStyle);
                    });
                });

                // Ensure minimum column width based on headers
                headers.forEach((header, columnIndex) => {
                    const headerWidth = header.length;
                    worksheet.getColumn(columnIndex + 1).width = Math.max(headerWidth + 3, 10); // Set minimum width of 10 characters
                });

                // Automatically adjust column widths based on cell content
                worksheet.columns.forEach((column) => {
                    column.eachCell({ includeEmpty: true }, (cell) => {
                        const cellContentWidth = cell.value ? String(cell.value).length : 0;
                        column.width = Math.max(cellContentWidth + 5, column.width || 15); // Set minimum width of 10 characters
                    });
                });

                // Add footer with spaces and padding, borders, and fill color
                worksheet.addRow([]);
                worksheet.addRow([]);
                const footerRow = worksheet.addRow(['Powered By HUBA']);
                footerRow.eachCell((cell) => {
                    const footerStyle = {
                        font: { size: 12, bold: true, color: { argb: 'FFFFFF' } },
                        fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '000000' } },
                        alignment: { horizontal: 'center' },
                        border: { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } },
                        padding: { top: 5, bottom: 5, left: 5, right: 5 },
                    };
                    Object.assign(cell, footerStyle);
                });
                // Create a blob and initiate file download
                workbook.xlsx.writeBuffer().then((buffer) => {
                    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    const url = URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = 'GroupSales-Order.xlsx';
                    a.click();
                });

            } catch (error) {
                console.error('Error occurred while processing data:', error);
            }
        });
    }





    async createPackageOrder(packageData: any): Promise<any> {
        try {
            const response = await this._packageService.createPackageOrder(packageData);
            if (response.success) {
                return { success: true, message: `${packageData.packageOrder} Package Order created successfully.` };
            } else {
                return { success: false, errors: response.errors };
            }
        } catch (error) {
            throw new Error(`Error creating Package Order: ${error.message}`);
        }
    }

    generatePackageCode(): string {
        this.counter++;
        const randomPart = Array.from(
            { length: 8 },
            () => Math.random().toString(36).toUpperCase()[2]
        ).join('');
        const packageCode = `PKG-${String(this.counter).padStart(
            4,
            '0'
        )}-${randomPart}`;
        return packageCode;
    }


    formatSelectedDate(event: Date) {
        if (event) {
            this.selectedDate = this.datePipe.transform(event, 'yyyy-MM-dd');
        } else {
            this.selectedDate = null;
        }
    }

    updateSelectedRowIds(row: any): void {
        const index = this.selectedRowIds.indexOf(row.id);

        if (index >= 0) {
            // If the ID is already in the array, remove it
            this.selectedRowIds.splice(index, 1);
        } else {
            // If the ID is not in the array, add it
            this.selectedRowIds.push(row.id);
        }

        console.log('Updated selectedRowIds:', this.selectedRowIds);
    }

    isAllSelected() {
        if (!this.dataSource || !this.dataSource.data) {
            // If dataSource or dataSource.data is undefined or null, return false
            return false;
        }

        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected > 0 && numSelected === numRows;
    }


    /** Selects all rows if they are not all selected; otherwise clear selection. */
    toggleAllRows() {
        if (this.isAllSelected()) {
            this.selection.clear();
            this.selectedRowIds = []; // Clear selectedRowIds when deselecting all
        } else {
            this.dataSource.data.forEach(row => {
                this.selection.select(row); // Select all rows in the dataSource
                this.updateSelectedRowIds(row); // Update selectedRowIds for each row
            });
        }
    }

    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: SalesOrderData): string {
        if (!row) {
            const result = `${this.isAllSelected() ? 'deselect' : 'select'} all`;
            return result;
        }
        const result = `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.customerId + 1}`;
        return result;
    }


    trackByProduct(index: number, product): string {
        return product.id;
    }
    ngAfterViewInit() {
        setTimeout(() => {
            if (this.dataSource) {
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
            }
        }, 400);
    }
    calculateTotal(): number {
        // Check if the dataSource is defined and has data
        if (this.dataSource && this.dataSource.data) {
            return this.dataSource.data.reduce((total, row) => total + row.totalCost, 0);
        }
        // Return 0 if data is not available or undefined
        return 0;
    }

    calculateStatistics() {

        const data = this.dataSource.data;

        // Initialize statistics object
        const statistics = {
            numberOfCustomers: 0,
            numberOfProducts: 0,
            highestSellerCustomerId: '',
            biggestSellerProductId: '',
            completedSalesOrdersCount: 0,
            startedSalesOrdersCount: 0,
        };

        // Calculate the number of customers
        const uniqueCustomerIds = new Set<string>();
        data.forEach((row) => {
            uniqueCustomerIds.add(row.customerName);
        });
        this.numberOfCustomers = statistics.numberOfCustomers = uniqueCustomerIds.size;

        // Calculate the number of products
        const uniqueProductNames = new Set<string>();
        data.forEach((row) => {
            row.products.forEach((product) => {
                uniqueProductNames.add(product.productName);
            });
        });
        this.numberOfProducts = statistics.numberOfProducts = uniqueProductNames.size;

        // Calculate the customer with the highest sales
        const customerSalesMap = new Map<string, number>();
        data.forEach((row) => {
            const customerId = row.customerName;
            const totalSales = row.totalCost;

            if (!customerSalesMap.has(customerId)) {
                customerSalesMap.set(customerId, totalSales);
            } else {
                const currentTotal = customerSalesMap.get(customerId) || 0;
                customerSalesMap.set(customerId, currentTotal + totalSales);
            }
        });
        const sortedCustomerSales = [...customerSalesMap.entries()].sort(
            (a, b) => b[1] - a[1]
        );
        if (sortedCustomerSales.length > 0) {
            statistics.highestSellerCustomerId = sortedCustomerSales[0][0];
        }
        this.highestSellerCustomerId = statistics.highestSellerCustomerId
        // Calculate the product with the highest sales
        const productSalesMap = new Map<string, number>();
        data.forEach((row) => {
            row.products.forEach((product) => {
                const productName = product.productName;
                const quantity = product.quantity;

                if (!productSalesMap.has(productName)) {
                    productSalesMap.set(productName, quantity);
                } else {
                    const currentQuantity = productSalesMap.get(productName) || 0;
                    productSalesMap.set(productName, currentQuantity + quantity);
                }
            });
        });
        const sortedProductSales = [...productSalesMap.entries()].sort(
            (a, b) => b[1] - a[1]
        );
        if (sortedProductSales.length > 0) {
            statistics.biggestSellerProductId = sortedProductSales[0][0];
        }
        this.biggestSellerProductId = statistics.biggestSellerProductId
        // Calculate the completed and started sales orders
        this.completedSalesOrdersCount = statistics.completedSalesOrdersCount = data.filter(
            (row) => row.status === 'Completed'
        ).length;

        this.startedSalesOrdersCount = statistics.startedSalesOrdersCount = data.filter(
            (row) => row.status === 'Started'
        ).length;

        // Return the calculated statistics
        return statistics;



    }


    updateVendorsCredit() { }


    deleteVendorsCredit(id: any) {
        this.roleService.withRoleCheck(this.requiredRolesCreation, async () => {

            // Prompt the user for confirmation before deleting the employee
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {

                if (result.isConfirmed) {
                    this._salesorderService.deletepreorder(id)

                        .subscribe((isDeleted) => {
                            this.salesorder$ = this._salesorderService
                                .getpreorder()
                                .pipe(take(1));
                            this.loadData();

                            if (!isDeleted) {
                                Swal.fire("Error!", "Cannot Delete");
                                return;
                            }

                            // Display a success message
                            Swal.fire(
                                'Deleted!',
                                'Your file has been deleted.',
                                'success'
                            )
                        });
                }
            });
        });
    }




    refreshProductList() {
        this._salesorderService.getpreorder()
            .subscribe((data) => {
                // Update your product list with the new data
                this.salesOrders = data['data']; // Assuming your data is in a 'data' property
                // Update the MatTableDataSource data

                // Manually trigger change detection

            });
    }


    showDetails(id: any) {
        this._router.navigate(['apps/pre-order', id]);
    }





    openPackagepopup(id: any) {

    }

    getStatusIcon(status: string): { icon: string; color: string } {
        if (status === 'Started Sales Order') {
            return { icon: 'heroicons_outline:shopping-bag', color: 'blue' };
        } else if (status === 'Sales Order Shipped') {
            return { icon: 'heroicons_outline:truck', color: 'orange' };
        } else if (status === 'Sales Order Delivered') {
            return { icon: 'heroicons_outline:check-circle', color: 'green' };
        } else if (status === 'Completed Sales Order') {
            return { icon: 'heroicons_outline:ban', color: 'green' };
        } else if (status === 'Delivered Sales Order') {
            return { icon: 'heroicons_outline:ban', color: 'green' };
        } else if (status === 'Sales Order Returned') {
            return { icon: 'feather:corner-down-left', color: 'red' };
        } else if (status === 'Canceled Sales Order') {
            return { icon: 'heroicons_outline:ban', color: 'red' };
        } else if (status === 'Sales Order Packed') {
            return { icon: 'feather:package', color: 'red' };
        }

        // Return a default icon and color if the status doesn't match any of the conditions
        return { icon: 'heroicons_outline:ban', color: 'black' };
    }



    isCollapsed(row: SalesOrderData): boolean {
        return !row.isExpanded;
    }

    toggleCollapse(row: SalesOrderData): void {
        row.isExpanded = !row.isExpanded;
    }
    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    applyFilters() {
        // Get the filter strings for shipping area and delivery type
        const shippingAreaFilter = this.selectedShippingArea ? this.selectedShippingArea.trim().toLowerCase() : '';
        const deliveryTypeFilter = this.selectedDeliveryType ? this.selectedDeliveryType.trim().toLowerCase() : '';
        const selectedDateFilter = this.selectedDate ? this.datePipe.transform(this.selectedDate, 'yyyy-MM-dd') : '';

        // Apply filter only if either shipping area, delivery type, or date is selected
        if (shippingAreaFilter || deliveryTypeFilter || selectedDateFilter) {
            // Set the custom filter function
            this.dataSource.filterPredicate = (data, filter) => {
                const shippingAreaMatch = data.shipping_area.toLowerCase().includes(shippingAreaFilter);
                const deliveryTypeMatch = data.delivery_type.toLowerCase().includes(deliveryTypeFilter);
                const dateMatch = data.shippingDate.includes(selectedDateFilter); // Assuming shippingDate is in 'YYYY-MM-DD' format

                return shippingAreaMatch && deliveryTypeMatch && dateMatch;
            };

            // Combine filter strings for logging
            const combinedFilterString = `${shippingAreaFilter} ${deliveryTypeFilter} ${selectedDateFilter}`;
            console.log('Filter String:', combinedFilterString); // Log the filter string

            // Apply the combined filter
            this.dataSource.filter = combinedFilterString;
        } else {
            // Clear filter if neither shipping area, delivery type, nor date is selected
            this.dataSource.filter = '';
        }
    }




    createSalesExchange() {
        this.roleService.withRoleCheck(this.requiredRolesCreation, async () => {

            this._router.navigate(['apps/pre-order', 'NEW']);
        });
    }
    getStatusColor(status: string): string {
        if (status === 'pending') {
            return 'yellow';
        } else if (status === 'completed') {
            return 'green';
        } else if (status === 'failed') {
            return 'red';
        }
        return '';
    }
    ngOnDestroy(): void {
        // throw new Error('Method not implemented.');
    }
    loadData() {
        this.salesorder$ = this._salesorderService
            .getpreorder()
            .pipe(take(1));

        this.salesorder$.subscribe((salesorder) => {
            this.salesOrders = salesorder['data'];
            this.totalRows = this.salesOrders.length;

            this.dataSource = new MatTableDataSource<SalesOrderData>(

                this.salesOrders
                    .filter(salesOrder => salesOrder.status === 'Started Pre Order')
                    .map((salesOrder) => {
                        const productsInfo = salesOrder.products.map((product) => ({
                            productName: product.product?.productName,
                            quantity: product.quantity

                        }));

                        return {

                            customerId: salesOrder.Customers.id,
                            customerName: salesOrder.Customers.firstName + " " + salesOrder.Customers.lastName,
                            saleNumber: salesOrder.sales_order_num,
                            shippingDate: salesOrder.slaes_order_date,
                            status: salesOrder.status,
                            delivery_type: salesOrder.delivery_type,
                            shipping_area: salesOrder.shipping_area,
                            products: productsInfo,
                            isExpanded: false,
                            totalCost: salesOrder.total,
                            id: salesOrder.id,
                            productTotal: salesOrder.sub_total,
                            productquantity: productsInfo.quantity,

                        };
                    })
            );
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            this.calculateStatistics();
        });
    }
}

/** Builds and returns a new User. */
