import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appUnsavedChanges]'
})
export class UnsavedChangesDirective  {
  @Input() form: any; // Input property to receive the form reference

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): void {
    if (this.form && this.form.dirty) {
      $event.returnValue = true;
    }
  }


}

