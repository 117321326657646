import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ColorsComponent } from './colors/colors.component';
import { CreateColorsComponent } from './create-colors/create-colors.component';
import { ListColorsComponent } from './list-colors/list-colors.component';
import { DetailColorsComponent } from './detail-colors/detail-colors.component';
import { MaterialModule } from 'app/modules/material/material.module';
import { RouterModule } from '@angular/router';
import { ColorsRoutes } from './colors.routing';



@NgModule({
  declarations: [
    ColorsComponent,
    CreateColorsComponent,
    ListColorsComponent,
    DetailColorsComponent
  ],
  imports: [
    RouterModule.forChild(ColorsRoutes),
    MaterialModule,
    CommonModule
  ]
})
export class ProductColorsModule { }
