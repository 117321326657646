<div class="flex justify-end">
    <button mat-raised-button (click)="closeDrawer()">
        <span class="inline-flex items-center">
            <mat-icon svgIcon="feather:x"></mat-icon>
        </span>
    </button>
</div>
<div class="relative flex flex-col flex-auto items-center p-6 pt-0 sm:p-12 sm:pt-0 h-full w-full">
    

    <div class="flex items-center">

        <div class="text-4xl font-bold truncate">{{ customerInfo?.customer_name }} {{ customerInfo?.customer_last_name
            }} (HUB{{customerInfo?.customer_id}})</div>

        <div class="flex items-center ml-3">
            <img *ngIf="customerInfo?.gender === 'Male'" class="w-10 h-10" src="assets/male.png" alt="Male Icon">
            <img *ngIf="customerInfo?.gender === 'Female'" class="w-10 h-10" src="assets/female.png" alt="Female Icon">
            <img *ngIf="customerInfo?.gender === 'Others'" class="w-10 h-10" src="assets/others.png" alt="Others Icon">
        </div>



    </div>

    <div class="w-full mt-4 pt-6 border-t">
        <fuse-card class="flex items-center p-6 filter-info">
            <mat-icon class="icon-size-13 mr-6" style="color: rgb(130, 221, 130);" [svgIcon]="'heroicons_outline:backspace'"></mat-icon>
            <div class="flex flex-col">
                <div class="text-md font-semibold leading-tight">Best Seller</div>
                <div class="text-md text-secondary">{{highest_sold_product}}</div>
            </div>
        </fuse-card>
    </div>
    <!-- Cards Section -->
    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-4 pt-6 border-t w-full">

        <!-- Card #1 -->
        <fuse-card class="flex items-center p-6 filter-info">
            <mat-icon class="icon-size-13 mr-6" style="color: green;"
                [svgIcon]="'heroicons_outline:check-circle'"></mat-icon>
            <div class="flex flex-col">
                <div class="text-md font-semibold leading-tight">Completed</div>
                <div class="text-md text-secondary">{{sales_order_count}}</div>
            </div>
        </fuse-card>

        <!-- Card #2 -->
        <fuse-card class="flex items-center p-6 filter-info">
            <mat-icon class="icon-size-13 mr-6" style="color:rgb(182, 182, 116);"
                [svgIcon]="'heroicons_outline:truck'"></mat-icon>
            <div class="flex flex-col">
                <div class="text-md font-semibold leading-tight">Exchanges</div>
                <div class="text-md text-secondary">{{exchange_count}}</div>
            </div>
        </fuse-card>

        <!-- Card #3 -->
        <fuse-card class="flex items-center p-6 filter-info">
            <mat-icon class="icon-size-13 mr-6" style="color: red;"
                [svgIcon]="'heroicons_outline:backspace'"></mat-icon>
            <div class="flex flex-col">
                <div class="text-md font-semibold leading-tight">Return</div>
                <div class="text-md text-secondary">{{sales_return_count || 0}}</div>
            </div>
        </fuse-card>

        <!-- Card #4 -->
        <fuse-card class="flex items-center p-6 filter-info">
            <mat-icon class="icon-size-13 mr-6" style="color: rgb(255, 157, 0);"
                [svgIcon]="'heroicons_outline:backspace'"></mat-icon>
            <div class="flex flex-col">
                <div class="text-md font-semibold leading-tight" matTooltip="Pre Order">P O</div>
                <div class="text-md text-secondary">{{pre_sales_orders_count || 0}}</div>
            </div>
        </fuse-card>

        <!-- Card #5 -->
        <fuse-card class="flex items-center p-6 filter-info">
            <mat-icon class="icon-size-13 mr-6" style="color: rgb(255, 166, 0);"
                [svgIcon]="'heroicons_outline:backspace'"></mat-icon>
            <div class="flex flex-col">
                <div class="text-md font-semibold leading-tight " matTooltip='Total Voucher created for customer'>
                    Voucher</div>
                <div class="text-md text-secondary">{{credit_notes_count || 0}}</div>
            </div>
        </fuse-card>

        <!-- Card #6 -->
        <fuse-card class="flex items-center p-6 filter-info">
            <mat-icon class="icon-size-13 mr-6" style="color: rgb(130, 221, 130);"
                [svgIcon]="'heroicons_outline:backspace'"></mat-icon>
            <div class="flex flex-col">
                <div class="text-md font-semibold leading-tight" matTooltip='Diffrent items bought by the customer'>DPB
                </div>
                <div class="text-md text-secondary">{{total_products}}</div>
            </div>
        </fuse-card>

        <!-- Card #7 -->
    

        <!-- Card #8 -->
        <fuse-card class="flex items-center p-6 filter-info">
            <div class="flex flex-col">
                <div class="text-md font-semibold leading-tight">Total</div>
                <div class="text-md text-secondary">रू {{total}}</div>
            </div>
        </fuse-card>

        <!-- Card #9 -->
        <fuse-card class="flex items-center p-6 filter-info">
            <mat-icon class="icon-size-13 mr-6" style="color: rgb(130, 221, 130);"
                [svgIcon]="'heroicons_outline:backspace'"></mat-icon>
            <div class="flex flex-col">
                <div class="text-md font-semibold leading-tight"
                    matTooltip='Most product sold to customer by the employee'>MSB</div>
                <div class="text-md text-secondary">{{highest_salesperson}}</div>
            </div>
        </fuse-card>

    </div>

       <!-- Activity Section -->
    
    <!-- Activity Section -->
    <div class="flex flex-col flex-grow w-full mt-4 pt-6 border-t">
        <div class="flex items-center justify-between w-full">
            <div class="text-2xl font-semibold leading-tight">Activity</div>
            <div class="-mr-3">
                <button mat-icon-button [matMenuTriggerFor]="listCard01Menu">
                    <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:dots-vertical'"></mat-icon>
                </button>
                <mat-menu #listCard01Menu="matMenu">
                    <button mat-menu-item (click)="downloadExcel(id)">Print Report Excel</button>
                    <button mat-menu-item (click)="downloadPdf(id)">Print Report Pdf</button>
                </mat-menu>
            </div>
        </div>

        <div class="flex-grow overflow-y-auto w-full mt-5">
            <div *ngFor="let log of activityLogs; let i = index" class="flex mt-5">
                <mat-icon class="icon-size-5 mt-0.5 mr-2" style="color: green;" [svgIcon]="'heroicons_solid:check-circle'"></mat-icon>
                <div>{{ ' ' + (i + 1) + ': ' + log }}</div>
            </div>
        </div>
    </div>

</div>