import { Directive, Input, ElementRef, Renderer2, OnInit } from '@angular/core';

@Directive({
  selector: '[appSelectable]'
})
export class SelectableDirective implements OnInit {
  @Input() appSelectable: boolean = true;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    if (!this.appSelectable) {
      const selectElement = this.el.nativeElement;
      const wrapperDiv = this.renderer.createElement('div');

      // Replace mat-select with an input field
      const inputElement = this.renderer.createElement('input');
      this.renderer.setProperty(inputElement, 'type', 'text');
      this.renderer.setProperty(inputElement, 'value', selectElement.value);
      this.renderer.setProperty(inputElement, 'readonly', 'true');
      this.renderer.setStyle(inputElement, 'cursor', 'not-allowed');
      this.renderer.appendChild(wrapperDiv, inputElement);

      // Append any additional styles or classes to the wrapperDiv if needed

      // Replace the original mat-select with the wrapperDiv
      this.renderer.parentNode(selectElement).replaceChild(wrapperDiv, selectElement);
    }
  }
}
