import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { CreditNotesService } from 'app/credit-notes/credit-notes.service';
import { server_env } from 'config';
import { VendorsPayment } from '../vendors-payment.type';
import { VendorsPaymentService } from '../vendors-payment.service';
@Component({
  selector: 'app-detailvendors-payment',
  templateUrl: './detailvendors-payment.component.html',
  styleUrls: ['./detailvendors-payment.component.scss']
})
export class DetailvendorsPaymentComponent implements OnInit {
  displayedColumns: string[] = [
    'date',
    'amount',
    'remaning_amount'

  ];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  panelOpenState = false;

  productId: string;
  vendor: any; // Replace 'any' with the actual type of your product
  refund: any;
  refund_total: any;
  dataSource: MatTableDataSource<any>;
  length: any;
  thumbnail: string;
  approved_user: any;
  approved_expense_date: any;
  approved_payment_method: any;

  constructor(
    private route: ActivatedRoute,
    private productService: VendorsPaymentService // Replace with your actual product service
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.productId = params.get('id');
      this.fetchProductDetails(this.productId);
    });


  }


  fetchProductDetails(productId: string): void {
    // Use your product service to fetch the product details based on the provided ID
    this.productService.getVendorsPaymentById(productId).subscribe(
      (data) => {
        this.vendor = data;
        this.approved_user = this.vendor.modified_by.username;
        this.approved_expense_date = this.vendor.approved_expense_date;
        this.approved_payment_method = this.vendor.approved_payment_method;
        this.thumbnail = server_env.URL_MEDIA + data.bill_photo;
      },
      

      (error) => {
        console.error('Error fetching product details:', error);
      }
    );


  }
  toggleFullScreen(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    if (!document.fullscreenElement) {
      target.requestFullscreen().catch(err => console.log(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`));
    } else {
      document.exitFullscreen();
    }
  }
  printPage() {
    // Select the buttons you want to hide
    const buttonsToHide = document.querySelectorAll('button');

    buttonsToHide.forEach((button) => {
      button.style.display = 'none';
    });

    // Perform the printing
    window.print();

    setTimeout(() => {
      buttonsToHide.forEach((button) => {
        button.style.display = 'block'; // or 'inline' depending on the button's original display property
      });
    }, 1000); // Adjust the delay as needed
  }




  goBack = () => {
    window.history.back();
  }

}
