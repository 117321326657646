import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { MarkdownModule } from 'ngx-markdown';
import { FuseModule } from '@fuse';
import { FuseConfigModule } from '@fuse/services/config';
import { FuseMockApiModule } from '@fuse/lib/mock-api';
import { CoreModule } from 'app/core/core.module';
import { appConfig } from 'app/core/config/app.config';
import { mockApiServices } from 'app/mock-api';
import { LayoutModule } from 'app/layout/layout.module';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import {TRANSLOCO_CONFIG } from '@ngneat/transloco';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { server_env } from 'config';
import { PageDetectModule } from './page-detect/page-detect.module';



//set the fallback language for the for transloco problem and se the provider.
const translocoConfig = {
    fallbackLang: 'en', // Specify your fallback language code here
    // Other configuration options...
};

const routerConfig: ExtraOptions = {
    scrollPositionRestoration: 'enabled',
    preloadingStrategy: PreloadAllModules,
};

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
   
        RouterModule.forRoot(appRoutes, routerConfig),

        // Fuse, FuseConfig & FuseMockAPI
        FuseModule,
        FuseConfigModule.forRoot(appConfig),
        FuseMockApiModule.forRoot(mockApiServices),
        // TranslocoRootModule.init({
        //     loadLangs: {
        //       en: () => import('../assets/i18n/en.json'),
        //       tr: () => import('../assets/i18n/tr.json'),
        //     },
        //   }),

        // Core module of your application
        CoreModule,

        // Layout module of your application
        LayoutModule,
        PageDetectModule,
  
        // 3rd party modules that require global configuration via forRoot
        MarkdownModule.forRoot({}),
    ],
    providers: [
        // added the provider for the transloco problem
        {
            provide: TRANSLOCO_CONFIG,
            useValue: translocoConfig,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
// export class AppModule {
//     constructor(private translocoService: TranslocoService) {
//       // Set the default language (e.g., English)
//       this.translocoService.setDefaultLang('en');
//     }
//   }
