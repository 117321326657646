import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inventory-entry',
  templateUrl: './inventory-entry.component.html',
  styleUrls: ['./inventory-entry.component.scss']
})
export class InventoryEntryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
