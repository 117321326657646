import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { InvoiceComponent } from './sales-order-invoice';

@Injectable({
  providedIn: 'root'
})
export class PrintingService {
  private idSubject = new Subject<string>();

  private printTriggerSubject = new Subject<void>();

  constructor(private dialog: MatDialog) { }



 
  getId(): Observable<string> {
    return this.idSubject.asObservable();
  }

  setIdAndPrint(id: string) {
    this.idSubject.next(id);
    this.printTriggerSubject.next(); // Trigger printing immediately after setting the ID
  }

  getPrintTrigger(): Observable<void> {
    return this.printTriggerSubject.asObservable();
  }
  
 

}

