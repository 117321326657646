import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Observable, Subject } from 'rxjs';
import { take, } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import Swal from 'sweetalert2';
import { RestockEntryService } from '../restock-entry.service';


export interface IntialStock {
  id?: string;
  date?: string;
  products?: any;
  restock_entry?: any;
  adjustment_reason?: any;
  previous_stock?:any;
  created_by?:any;

}

@Component({
  selector: 'app-listrestock-entry',
  templateUrl: './listrestock-entry.component.html',
  styleUrls: ['./listrestock-entry.component.scss'],
})
export class ListrestockEntryComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = ['id', 'date', 'product', 'size', 'color','previous_stock', 'stock', 'adjustment_reason','created_by'];

  dataSource: MatTableDataSource<IntialStock>;
  initalSheet: IntialStock[];
  initalSheet$: Observable<IntialStock[]>;

  selectedInital: string;
  initalStockFilter: string;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  _activatedRoute: any;
  isLoading = true;

  /**
   * Constructor
   */
  constructor(
    private _inventoryService: RestockEntryService,
    private _router: Router,
  ) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {

    this.loadData();

  }

  ngAfterViewInit(): void {

  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  /**
   * On destroy
   */



  delteInital(id: any) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this! This will only delete the record won't revert the stock changes",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {

      if (result.isConfirmed) {
        this._inventoryService.deleteRestockEntry(id)

          .subscribe((isDeleted) => {

            this.loadData();

            if (!isDeleted) {
              Swal.fire("Error!", "Cannot Delete");
              return;
            }

            // Display a success message
            Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            )
          });
      }
    });
  }


  createInital() {
    this._router.navigate(['apps/inventory-adjustment', 'NEW']);

  }
  applyFilters() {
    this.isLoading = true;

    this.initalStockFilter = this.selectedInital ? this.selectedInital.trim().toLowerCase() : '';

    this.loadData();


  }

  loadData() {
    this.initalSheet$ = this._inventoryService
      .getRestockEntry()
      .pipe(take(1));
    this.initalSheet$.subscribe((inital) => {
      this.initalSheet = inital['data'];
      console.log(this.initalSheet, "s")

      this.dataSource = new MatTableDataSource<IntialStock>(

        this.initalSheet
          .map((inital) => {
            // Map nested properties of products array
            const product = inital.products;
            const pipedDate = inital.date.split('T')[0]; // Extracting only the date part


            return {
              id: inital.id,
              date: pipedDate,
              productName: product.base_product.productName, // Replace 'productName' with the actual property name
              size: product.size,
              color: product.color,
              restock_entry: inital.restock_entry,
              previous_stock:inital.previous_stock,
              adjustment_reason: inital.adjustment_reason,
              isPreOrder: product.base_product.pre_order_toggel,
              created_by:inital.created_by.username,


            };
          })
          .filter((item) => {

            if (this.initalStockFilter === 'pre order inital') {

              return item.isPreOrder === true;

            } else if (this.initalStockFilter === 'product inital') {
              return item.isPreOrder === false;
            } else {
              return true;
            }

          })
      );
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.isLoading = false;

    });


  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
  createInventoryEntry() {
    this._router.navigate(['apps/restock-entry', 'NEW']);
  }
}
