/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';
import { RoleGuard } from 'app/core/auth/guards/role.auth.guard';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboards',
        title: 'Dashboard',
        subtitle: '',
        type: 'group',
        icon: 'heroicons_outline:home',
        required_roles: ["admin", "Manager", "HR"],

        children: [
            {
                id: 'dashboards.project',
                title: 'Home',
                type: 'basic',
                icon: 'heroicons_outline:clipboard-check',
                link: '/dashboards/project',
                required_roles: ["admin", "Manager", "HR",]
            },
            // {
            //     id   : 'dashboards.analytics',
            //     title: 'Analytics',
            //     type : 'basic',
            //     icon : 'heroicons_outline:chart-pie',
            //     link : '/dashboards/analytics',
            //     required_roles:["admin","Manager","HR"]


            // }


        ]
    },

    {
        id: 'sales',
        title: 'Sales',
        subtitle: '',
        type: 'group',
        icon: 'heroicons_outline:users',
        children: [
            {
                id: 'apps.salesLogs',
                title: 'Sales Logs',
                type: 'basic',
                icon: 'mat_outline:table_chart',
                link: '/apps/sales-logs',
                required_roles: ["Manager", 'Communication Manager', "Operation Manager", "Production Manager", "Accountant", "HR"]


            },
            {
                id: 'apps.salesOrder',
                title: 'Sales Orders',
                type: 'basic',
                icon: 'mat_outline:account_balance',
                link: '/apps/sales-order',
                required_roles: ["Manager", 'Communication Manager', "Operation Manager", "Production Manager", "HR"]

            },
            {
                id: 'apps.sales-exchange',
                title: 'Exchanges',
                type: 'basic',
                icon: 'mat_outline:360',
                link: '/apps/exchanges',
                required_roles: ["Manager", 'Communication Manager', "Operation Manager", "Production Manager", "HR"]

            },
            {
                id: 'apps.pre-order',
                title: 'Pre-Orders',
                type: 'basic',
                icon: 'mat_outline:wifi_calling_3',
                link: 'apps/pre-order',
                required_roles: ["Manager", 'Communication Manager', "Operation Manager", "Production Manager", "HR"]

            },
            {
                id: 'apps.sales-returns',
                title: 'Sales Returns',
                type: 'basic',
                icon: 'mat_outline:keyboard_backspace',
                link: '/apps/sales-return',
                required_roles: ["Manager", 'Communication Manager', "Operation Manager", "Production Manager", "HR"]

            },
            {
                id: 'apps.credit_notes',
                title: 'Credit Notes',
                type: 'basic',
                icon: 'mat_outline:addchart',
                link: '/apps/credit-notes',
                required_roles: ["Manager", 'Communication Manager', "Operation Manager", "Production Manager", "Accountant", "HR"]

            },
            {
                id: 'apps.promo-code',
                title: 'Promo Code',
                type: 'basic',
                icon: 'mat_outline:money',
                link: '/apps/promo-code',
                required_roles: ["Manager", 'Communication Manager', "Operation Manager", "Production Manager", "HR"]

            },
        ]
    },
    {
        id: 'daybook',
        title: 'Billing',
        subtitle: '',
        type: 'group',
        icon: 'heroicons_outline:book-open',
        required_roles: ["Manager", "Production Manager", "Accountant", "HR"],

        children: [
            {
                id: 'daybook',
                title: 'Daybook',
                type: 'basic',
                icon: 'heroicons_outline:book-open',
                link: '/apps/daybook',
                required_roles: ["Manager", "Production Manager", "Accountant", "HR"]

            },
            {
                id: 'daybook',
                title: 'Payments',
                type: 'basic',
                icon: 'mat_outline:account_balance',
                link: '/apps/daybook/payment/',
                required_roles: ["Manager", "Production Manager", "Accountant", "HR"]

            },
            {
                id: 'accounts',
                title: 'Accounts',
                type: 'basic',
                icon: 'heroicons_outline:identification',
                link: '/apps/benifits',
                required_roles: ["Manager", "Production Manager", "Accountant", "HR"]

            },

            {
                id: 'accounts',
                title: 'Internal Transcations',
                type: 'basic',
                icon: 'heroicons_outline:identification',
                link: '/apps/benifits/internal_trans/',
                required_roles: ["Manager", "Production Manager", "Accountant", "HR"]

            },
        ]
    },
    {
        id: 'realtionship',
        title: 'relationship',
        subtitle: '',
        type: 'group',
        icon: 'heroicons_outline:users',
        children: [
            {
                id: 'relationship.customers',
                title: 'Employees',
                type: 'basic',
                icon: 'mat_outline:accessibility_new',
                link: '/apps/employees',
                required_roles: ["Manager", "HR"]

            },
            {
                id: 'apps.contacts',
                title: 'Customer',
                type: 'basic',
                icon: 'heroicons_outline:user-group',
                link: '/apps/contacts',
                required_roles: ["Manager", 'Communication Manager', "Operation Manager", "Production Manager", "HR"]

            },
            {
                id: 'apps.contacts',
                title: 'Vendors',
                type: 'basic',
                icon: 'mat_outline:shopping_bag',
                link: '/apps/vendors',
                required_roles: ["Manager", "Production Manager", "Accountant", "HR"]

            },
            {
                id: 'apps.influencer',
                title: 'Influencers',
                type: 'basic',
                icon: 'mat_outline:camera_front',
                link: '/apps/influencer',
                required_roles: ["Manager", "Creative Manager", "HR"]

            },
        ]
    },
    
    {
        id: 'product_master ',
        title: 'Products',
        subtitle: '',
        type: 'group',
        icon: 'mat_solid:storefront',
        required_roles: ["Manager", "Operation Manager", "Production Manager", "HR"],

        children: [
            {
                id: 'apps.contacts',
                title: 'Product Master',
                type: 'basic',
                icon: 'mat_outline:checkroom',
                link: '/apps/prizeList',
                required_roles: ["Manager", "Operation Manager", "Production Manager", "HR"]

            },
            {
                id: 'relationship.products',
                title: 'Products',
                type: 'basic',
                icon: 'mat_solid:storefront',
                link: '/apps/products',
                required_roles: ["Manager", "Operation Manager", "Production Manager", "HR"]


            },
            {
                id: 'apps.contacts',
                title: 'Product Bundle',
                type: 'basic',
                icon: 'speaker_group',

                link: '/apps/product-bundle',
                required_roles: ["Manager", "Operation Manager", "Production Manager", "HR"]

            },
            

        ]
    },

    {
        id: 'inventory',
        title: 'Inventory',
        subtitle: '',
        type: 'group',
        icon: 'heroicons_outline:users',
        children: [
            {
                id: 'apps.ecommerce.inventory',
                title: 'Inventory Sheet',
                icon: 'mat_solid:local_convenience_store',
                type: 'basic',
                link: '/apps/inventory-sheet',

                required_roles: ["Manager", 'Communication Manager', "Operation Manager", "Creative Manager", "Production Manager", "Accountant", "HR"]


            },
            {
                id: 'apps.contacts',
                title: 'Items',
                type: 'basic',
                icon: 'mat_outline:markunread_mailbox',
                link: '/apps/inventory-entry',
                required_roles: ["Manager", "Operation Manager", "Production Manager", "HR"]

            },

            {
                id: 'apps.contacts',
                title: 'Initial Stock Entry',
                type: 'basic',
                icon: 'mat_outline:add_road',
                link: 'apps/inventory-adjustment',
                required_roles: ["Manager", "Operation Manager", "Production Manager", "HR"]

            },
            {
                id: 'apps.contacts',
                title: 'Inventory Adjustment',
                type: 'basic',
                icon: 'mat_outline:adjust',
                link: '/apps/restock-entry',
                required_roles: ["Manager", "Operation Manager", "Production Manager", "HR"]

            },
            {
                id: 'apps.contacts',
                title: 'Restock Entry',
                type: 'basic',
                icon: 'mat_outline:settings_backup_restore',
                link: '/apps/restock-product',
                required_roles: ["Manager", "Operation Manager", "Production Manager", "HR"]

            },

        ]
    },

    {
        id: 'purchases',
        title: 'Purchases',
        subtitle: '',
        type: 'group',
        icon: 'heroicons_outline:users',
        required_roles: ["Manager", "Accountant", "Production Manager", "HR"],

        children: [
            {
                id: 'apps.contacts',
                title: 'Expense Master',
                type: 'basic',
                icon: 'heroicons_solid:credit-card',
                link: '/apps/vendors-credit',
                required_roles: ["Manager", "Accountant", "Production Manager", "HR"]

            },
            {
                id: 'apps.academy',
                title: 'Vendors payments',
                type: 'basic',
                icon: 'mat_outline:payments',
                link: '/apps/vendors-payment',
                required_roles: ["Manager", "Accountant", "Production Manager", "HR"]

            }, {
                id: 'apps.contacts',
                title: 'Expenses',
                type: 'basic',
                icon: 'mat_solid:account_balance_wallet',
                link: '/apps/vendors-expenses',
                required_roles: ["Manager", "Accountant", "Production Manager", "HR"]

            },


        ]
    },
    {
        id: 'Influncers Package',
        title: 'Influncers Package',
        subtitle: '',
        type: 'group',
        icon: 'heroicons_outline:users',
        required_roles: ["Manager", "Creative Manager", "HR"],

        children: [
            {
                id: 'apps.academy',
                title: 'Pr Packages',
                type: 'basic',
                icon: 'mat_solid:camera_rear',
                link: '/apps/pr-packages',
                required_roles: ["Manager", "Creative Manager", "HR"]

            }, 
            
            // {
            //     id: 'apps.contacts',
            //     title: 'Pr Packages Returns',
            //     type: 'basic',
            //     icon: 'mat_solid:arrow_back_ios_new',
            //     link: '/apps/pr-packages-return',
            //     required_roles: ["Manager", "Creative Manager", "HR"]

            // },
            // {
            //     id: 'apps.contacts',
            //     title: 'Credit Notes',
            //     type: 'basic',
            //     icon: 'heroicons_solid:credit-card',
            //     link: '/apps/pr-packages-creditNotes',
            //     required_roles: ["Manager", "Creative Manager", "HR"]

            // },

        ]
    },

]


export const compactNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboards',
        title: 'Dashboards',
        type: 'aside',
        icon: 'heroicons_outline:home',
        children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id: 'apps',
        title: 'Apps',
        type: 'aside',
        icon: 'heroicons_outline:qrcode',
        children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id: 'pages',
        title: 'Pages',
        type: 'aside',
        icon: 'heroicons_outline:document-duplicate',
        children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id: 'user-interface',
        title: 'UI',
        type: 'aside',
        icon: 'heroicons_outline:collection',
        children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id: 'navigation-features',
        title: 'Navigation',
        type: 'aside',
        icon: 'heroicons_outline:menu',
        children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboards',
        title: 'DASHBOARDS',
        type: 'group',
        children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id: 'apps',
        title: 'APPS',
        type: 'group',
        children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id: 'others',
        title: 'OTHERS',
        type: 'group'
    },
    {
        id: 'pages',
        title: 'Pages',
        type: 'aside',
        icon: 'heroicons_outline:document-duplicate',
        children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id: 'user-interface',
        title: 'User Interface',
        type: 'aside',
        icon: 'heroicons_outline:collection',
        children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id: 'navigation-features',
        title: 'Navigation Features',
        type: 'aside',
        icon: 'heroicons_outline:menu',
        children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [

];