<div id="list">

<div class="sm:absolute sm:inset-0 flex flex-col flex-auto min-w-0 sm:overflow-hidden bg-card dark:bg-transparent">
    <!-- Header -->
    <div
        class="relative flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between py-8 px-6 md:px-8 border-b">
        <!-- Loader -->
        <div class="absolute inset-x-0 bottom-0" *ngIf="isLoading">
            <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
        </div>
        <!-- Title -->
        <div class="text-4xl font-bold tracking-tight">
            Credit Notes
            <mat-icon svgIcon="heroicons_outline:academic-cap"></mat-icon>
        </div>
        <!-- Actions -->
        <div class="flex flex-shrink-0 items-center mt-6 sm:mt-0 sm:ml-4">
            <!-- Search -->
          
            <!-- Add product button -->
            <button class="ml-4" mat-flat-button [color]="'primary'" (click)="createSalesExchange()">
                <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
                <span class="ml-2 mr-1">Add</span>
            </button>
           
        </div>
    </div>
    <div class="flex flex-shrink-0 items-center mt-6 sm:mt-0 sm:ml-4">


        <div>


            <button [matMenuTriggerFor]="menu" mat-flat-button [color]="'primary'">
                Action
                <mat-icon svgIcon="feather:chevron-down"></mat-icon>
            </button>

            <mat-menu #menu>
              
                <button  (click)="multipleInvoice()" class="text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                    mat-menu-item>
                    Instant Invoice
                </button>
            </mat-menu>
        </div>



        <div class="flex flex-shrink-0 items-center ml-3 mt-3">
            <mat-form-field>
                <input matInput [matDatepicker]="picker" placeholder="Select a date" [(ngModel)]="selectedDate"
                    (ngModelChange)="applyFilters()">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>



            <mat-form-field style="min-width: 200px;" class="flex-auto ml-3">
                <mat-select [(ngModel)]="selectedShippingArea" (ngModelChange)="applyFilters()"
                    placeholder="Shipping Area">
                    <mat-option value="">----</mat-option>
                    <mat-option value="Inside Valley">INSIDE VALLEY</mat-option>
                    <mat-option value="Outside Valley">OUTSIDE VALLEY</mat-option>
                    <mat-option value="International">INTERNATIONAL</mat-option>
                </mat-select>
                <mat-icon class="icon-size-5" matPrefix [svgIcon]="'feather:truck'"></mat-icon>
            </mat-form-field>

            <mat-form-field style="min-width: 200px;" class="flex-auto ml-3">
                <mat-select [(ngModel)]="selectedDeliveryType" (ngModelChange)="applyFilters()"
                    placeholder="Delivery Type">
                    <mat-option value="">----</mat-option>
                    <mat-option value="Normal">Normal</mat-option>
                    <mat-option value="Express">Express</mat-option>
                </mat-select>

                <mat-icon class="icon-size-5" matPrefix [svgIcon]="'feather:truck'"></mat-icon>
            </mat-form-field>

            <mat-form-field style="min-width: 200px;" class="flex-auto ml-3">
                <mat-icon matPrefix [svgIcon]="'heroicons_outline:search'"></mat-icon>
                <input matInput [autocomplete]="'off'" (keyup)="applyFilter($event)"
                    [placeholder]="'Search Sales Order'" />
            </mat-form-field>
        </div>

    </div>
    <!-- Main -->
    <div class="flex flex-auto overflow-hidden">

  
        <!-- Products list -->
        <div class="flex flex-col flex-auto sm:mb-18 overflow-hidden sm:overflow-y-auto">
            <div class="mat-elevation-z8">
                <table mat-table [dataSource]="dataSource" matSort matPaginator class="mat-table">
                    <ng-container matColumnDef="select">
                        <th mat-header-cell *matHeaderCellDef>
                            <mat-checkbox (change)="$event ? toggleAllRows() : null"
                                [checked]="selection.hasValue() && isAllSelected()"
                                [indeterminate]="selection.hasValue() && !isAllSelected()"
                                [aria-label]="checkboxLabel()">
                            </mat-checkbox>
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <mat-checkbox (click)="$event.stopPropagation()"
                                (change)="$event ? updateSelectedRowIds(row) : null"
                                [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
                            </mat-checkbox>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="CustomerId">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            ID
                        </th>
                        <td mat-cell *matCellDef="let row" class="cursor-pointer">
                            <div class="avatar" (click)="showDetails(row.id)">

                                {{ row.customerId }}
                            </div>
                        </td>
                    </ng-container>

                    <!-- CustomerName Column -->
                    <ng-container matColumnDef="CustomerName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Name
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <div class="avatar">

                                {{ row.customerName }}
                            </div>
                        </td>
                    </ng-container>

                    <!-- SalesNumber Column -->
                    <ng-container matColumnDef="SaleNumber">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            <strong>S.N</strong>
                        </th>
                        <mat-icon svgIcon="heroicons_outline:user"></mat-icon>
                        <div></div>
                        <td mat-cell *matCellDef="let row">
                            {{ row.voucher_order_num }}
                        </td>
                    </ng-container>

                    <!-- CreditNotes -->
                    <ng-container matColumnDef="history">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            <strong>Refund History</strong>
                        </th>
                        <mat-icon svgIcon="heroicons_outline:user"></mat-icon>
                        <div></div>
                        <td mat-cell *matCellDef="let row">
                            <div *ngIf="row.totalCost === 0 ">
                                <span class="chip chip--success">
                                    <i style="font-size: 11px; margin-top: -2px;" matTooltip="Payment Completed for this voucher"
                                        matTooltipPosition="above" class="chip__label">
                                        <strong>Payment Completed</strong>
                                    </i>
                                </span>
                            </div>
                            <div *ngIf="row.totalCost !== 0 && row.history">
                                <span class="chip chip--success">
                                    <i style="font-size: 11px; margin-top: -2px;" matTooltip="Payment has started but not fully claimed"
                                        matTooltipPosition="above" class="chip__label">
                                        <strong>Payment Started</strong>
                                    </i>
                                </span>
                            </div>
                            <div *ngIf="row.totalCost !== 0 && !row.history">
                                <span class="chip chip--error">
                                    <i style="font-size: 11px; margin-top: -2px;" matTooltip="No payment has been started"
                                        matTooltipPosition="above" class="chip__label">
                                        <strong>No Payment</strong>
                                    </i>
                                </span>
                            </div>
                        </td>
                        
                    </ng-container>

                    <!-- CreditNotes -->
                    <!-- <ng-container matColumnDef="deliverd">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            <strong>Completed</strong>
                        </th>
                        <mat-icon svgIcon="heroicons_outline:user"></mat-icon>
                        <div></div>
                        <td mat-cell *matCellDef="let row">
                            <div *ngIf="row.delivered">
                                <span class="chip chip--success">
                                    <i style="font-size: 11px; margin-top: -2px; "
                                        matTooltip="Sales Order delivered and converted" matTooltipPosition="above"
                                        class="chip__label">
                                        <strong>Completed</strong>
                                    </i>
                                </span>
                            </div>
                            <div *ngIf="!row.delivered">
                                <span class="chip chip--error">
                                    <i style="font-size: 11px; margin-top: -2px; " matTooltip="Not Delivered"
                                        matTooltipPosition="above" class="chip__label">
                                        <strong>Not Completed</strong>
                                    </i>
                                </span>
                            </div>

                        </td>
                    </ng-container> -->

                    <!-- Status Column -->
                    <ng-container matColumnDef="Status">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Status
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <div class="avatar">

                                {{ row.status }}
                            </div>
                        </td>
                    </ng-container>

                    <!-- OrderShipped Date Column -->
                    <ng-container matColumnDef="Shipping_Date">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Date
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <div class="status">
                                <i><u>
                                    {{ row.voucher_order_date }}
                                </u>

                                </i>
                            </div>
                        </td>
                    </ng-container>

                    <!-- <ng-container matColumnDef="Shipping Area">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Voucher Type
                        </th>
                        <mat-icon svgIcon="heroicons_outline:user"></mat-icon>
                        <div></div>
                        <td mat-cell *matCellDef="let row">
                            {{ row.shipping_area }}
                        </td>
                    </ng-container> -->

                    <ng-container matColumnDef="Delivery Type">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Voucher Type
                        </th>
                        <td mat-cell *matCellDef="let row">
                            {{ row.voucher_type }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="total">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Total Price
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <div class="status">
                                NPR <strong>
                                    {{ row.totalCost }}
                                </strong>
                            </div>
                        </td>
                    </ng-container>


                    <ng-container matColumnDef="create">
                        <th mat-header-cell *matHeaderCellDef>Create</th>
                        <td mat-cell *matCellDef="let row">

                            <button mat-icon-button mat-flat-button 
                                [matMenuTriggerFor]="menu">
                                <mat-icon svgIcon="feather:chevron-down"></mat-icon>
                            </button>

                            <mat-menu class="rounded" #menu>

                                <button class="text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" mat-menu-item   (click)="showDetails(row.id)">
                                    View
                                </button>

                                <button *ngIf="row.status != 'Completed Credit Note'" class="text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" mat-menu-item  matTooltip="Refund credit note"
                                    (click)="openRefundPackage(row.id)">
                                    Refund
                                </button>
                                <button class="text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" mat-menu-item   (click)="deleteVendorsCredit(row.id)">
                                    Delete
                                </button>
                                
                            </mat-menu>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>

                <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>
            </div>
        </div>
    </div>
</div>

</div>
