import { Component, ElementRef, ViewChild, Input,  EventEmitter, Output, ChangeDetectionStrategy } from '@angular/core';
import { jsPDF } from 'jspdf';

@Component({
  selector: 'app-invoice',
  templateUrl: './sales-order-invoice.html',
  styleUrls: ['./sales-order-invoice.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class InvoiceComponent {
  @Input() vendor: any;
  @ViewChild('screenshotElement', { static: false }) screenshotElement: ElementRef;
  @Output() pdfGenerated = new EventEmitter<jsPDF>();

  constructor() {}

 getInvoiceElement(): HTMLElement {
  return this.screenshotElement.nativeElement;
}

}