import { Route } from '@angular/router';


import { ListColorsComponent } from './list-colors/list-colors.component';
import { CreateColorsComponent } from './create-colors/create-colors.component';
import { DetailColorsComponent } from './detail-colors/detail-colors.component';



export const ColorsRoutes: Route[] = [
   
        {
            path     : '',
            component: ListColorsComponent,
        },
        {
            path         : 'NEW',
            component    : CreateColorsComponent,
           
        },
        {
            path         : ':id',
            component    : DetailColorsComponent,
          
          
        },
]
