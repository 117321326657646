import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { PreOrdersComponent } from './pre-orders/pre-orders.component';
import { ListPreOrdersComponent } from './list-pre-orders/list-pre-orders.component';
import { CreatePreOrdersComponent } from './create-pre-orders/create-pre-orders.component';
import { DetailPreOrdersComponent } from './detail-pre-orders/detail-pre-orders.component';
import { MaterialModule } from 'app/modules/material/material.module';
import { RouterModule } from '@angular/router';
import { PrizeListRoute } from 'app/prize-list/prize-list.routing';
import { PreOrderRouting } from './pre-orders.routing';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDialogModule } from '@angular/material/dialog';
import { CustomSelectModule } from 'app/custom-select/custom-select.module';
import { MAT_DATE_FORMATS } from '@angular/material/core';



@NgModule({
  declarations: [
    PreOrdersComponent,
    ListPreOrdersComponent,
    CreatePreOrdersComponent,
    DetailPreOrdersComponent
  ],
  imports: [
    RouterModule.forChild(PreOrderRouting),
    MaterialModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    CustomSelectModule,
    CommonModule
  ],
  providers   : [
    {
        provide : MAT_DATE_FORMATS,
        useValue: {
            parse  : {
                dateInput: "YYYY-MM-DD"
            },
            display: {
                dateInput         : 'YYYY-MM-DD',
                monthYearLabel    : 'MMM YYYY',
                dateA11yLabel     : 'LL',
                monthYearA11yLabel: 'MMMM YYYY'
                
            }
        }
    },
    DatePipe
]
})
export class PreOrdersModule { }
