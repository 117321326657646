import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { PromoCode } from '../promo-code.type';
import { PromoCodeService } from '../promo-code.service';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { RoleService } from 'app/core/auth/guards/role.service';

@Component({
  selector: 'app-list-promo-code',
  templateUrl: './list-promo-code.component.html',
  styleUrls: ['./list-promo-code.component.scss']
})
export class ListPromoCodeComponent implements OnInit {
  displayedColumns: string[] = ['id', 'name', 'discount', 'date', 'actions'];
  dataSource: MatTableDataSource<PromoCode>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  promoCode: PromoCode[];
  isLoading: boolean = true;
  promoCode$: Observable<PromoCode[]>;
  requiredRoles: string[] = ["Manager", "Operation Manager","Communication Manager", "HR"];  // Define the roles allowed to execute the function

  constructor(

    private _promoCodeService: PromoCodeService,
    private _router: Router,
    private roleService: RoleService,



  ) { }

  ngOnInit(): void {

    this.fetchPromoCode();
  }

  fetchPromoCode() {
    this.isLoading = true;
    this.promoCode$ = this._promoCodeService.getPromoCodes().pipe(take(1));

    this.promoCode$.subscribe((promoCode) => {
      this.promoCode = promoCode['data'];

      this.dataSource = new MatTableDataSource<PromoCode>(
        this.promoCode.map((promoCode) => {
          return {
            id: promoCode.id,
            name: promoCode.name,
            discount: promoCode.discount,
            date: promoCode.disable_date,
            isActive: promoCode.isActive,
            discount_alias: promoCode.discount_alias
          };
        })
      );

      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

      this.isLoading = false;
    });
  }



  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  createPromoCode() {
    this.roleService.withRoleCheck(this.requiredRoles, async () => {

    this._router.navigate(['apps/promo-code', 'NEW']);

    });
  }

  detailPromo(id: any) {
    this._router.navigate(['apps/promo-code', id]);



  }



}
