<mat-dialog-content class="dialog-content">
    <form [formGroup]="advance_filtering" (ngSubmit)="submitForm(advance_filtering.value)" (keydown.enter)="$event.preventDefault()">
      <div class="flex flex-col mt-8 p-8 pb-5 rounded shadow overflow-hidden">
        <p class="text-lg font-medium">Detail Filtering</p>
        <p class="text-secondary">Set your Filtering criteria for the inventory sheet here.<br></p>
        <mat-divider class="mt-8 mb-3"></mat-divider>
  
        <div class="flex flex-wrap w-100">
          <!-- Row 1: Type -->
       \
  
          <!-- Row 2: Size and Color -->
          <div class="flex flex-row w-100">
            <mat-form-field class="flex-auto gt-xs:pl-3 mr-3">
              <mat-label>Size</mat-label>
              <mat-select formControlName="size" placeholder="Size" name="Size" multiple>
                <mat-option *ngFor="let sizeOption of formFieldHelpers['size']" [value]="sizeOption">
                  {{ sizeOption }}
                </mat-option>
              </mat-select>
              <mat-icon class="icon-size-4" matPrefix [svgIcon]="'heroicons_solid:cube-transparent'"></mat-icon>
            </mat-form-field>
  
            <mat-form-field class="flex-auto gt-xs:pl-3">
              <mat-label>Color</mat-label>
              <mat-select formControlName="color" placeholder="Color" name="Color" multiple>
                <mat-option *ngFor="let colorOption of formFieldHelpers['color']" [value]="colorOption">
                  {{ colorOption }}
                </mat-option>
              </mat-select>
              <mat-icon class="icon-size-4" matPrefix [svgIcon]="'mat_solid:format_paint'"></mat-icon>
            </mat-form-field>
          </div>
  
          <!-- Row 3: Price Range -->
          <div class="flex flex-row w-100">
            <mat-form-field class="flex-auto gt-xs:pl-3 mr-3">
              <mat-label>Price Range</mat-label>
              <mat-select formControlName="price_range" placeholder="Price Range">
                <mat-option *ngFor="let priceRangeOption of formFieldHelpers['priceRanges']" [value]="priceRangeOption">
                  {{ priceRangeOption }}
                </mat-option>
              </mat-select>
              <mat-icon class="icon-size-4" matPrefix [svgIcon]="'heroicons_solid:currency-dollar'"></mat-icon>
            </mat-form-field>
  
            <!-- Row 4: Stock Range -->
            <mat-form-field class="flex-auto gt-xs:pl-3">
              <mat-label>Stock Range</mat-label>
              <mat-select formControlName="stock_range" placeholder="Stock Range">
                <mat-option *ngFor="let stockRangeOption of formFieldHelpers['stockRanges']" [value]="stockRangeOption">
                  {{ stockRangeOption }}
                </mat-option>
              </mat-select>
              <mat-icon class="icon-size-4" matPrefix [svgIcon]="'heroicons_solid:cube'"></mat-icon>
            </mat-form-field>
          </div>
        </div>
      </div>
  
      <div class="flex items-center justify-end mt-10">
        <button class="px-6 ml-3" mat-flat-button type="submit" color="primary">
          Save
        </button>
        <button (click)="cancel()" class="px-6 ml-3" mat-flat-button type="button" color="error">
          Cancel
        </button>
      </div>
    </form>
  </mat-dialog-content>
  