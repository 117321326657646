import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StockSubmissionService {
  private stockSubmittedSource = new Subject<void>();
  private isStockSubmitted: boolean = false;

  stockSubmitted$ = this.stockSubmittedSource.asObservable();

  announceStockSubmission() {
    this.isStockSubmitted = true;
    this.stockSubmittedSource.next();
  }

  resetStockSubmissionStatus() {
    this.isStockSubmitted = false;
  }

  getStockSubmissionStatus(): boolean {
    return this.isStockSubmitted;
  }
}
