import {
    AfterViewInit,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { SalesOrder } from '../../sales-order/sales-order.type';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { saveAs } from 'file-saver';


import * as ExcelJS from 'exceljs';
import { SalesReturnService } from '../sales-return.service';

const EXCEL_EXTENSION = '.xlsx';
export interface SalesOrderData {
    customerName: string;
    saleNumber: string;
    status: string;
    shippingDate: string;
    totalCost: number;
    isExpanded: boolean;
    returningDate: string;
    rfr: string;
}

@Component({
    selector: 'app-list-sales-return',
    templateUrl: './list-sales-return.component.html',
    styleUrls: ['./list-sales-return.component.scss'],
})
export class ListSalesReturnComponent implements OnInit, AfterViewInit, OnDestroy {
    displayedColumns: string[] = [
        'Cid',
        'CustomerName',
        'SaleReturnNumber',
        'SaleNumber',
        'ReturnDate',
        'Status',
        'Shipping_Date',
        'total',
        'rfr',
        'isReceived',
        'actions',

    ];
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    salesOrders: any[];
    dataSource: MatTableDataSource<SalesOrderData>;
    @ViewChild('salesOrderTabel') salesOrderTabel: ElementRef;
    salesorder$: Observable<SalesOrder[]>;
    flashMessage: 'success' | 'error' | null = null;

    isLoading: boolean = true;
    dropdownHidden: boolean = true;
    fileName = 'SalesReturnSheet.xlsx';
    numberOfCustomers: number;
    numberOfProducts: number;
    highestSellerCustomerId: string;
    biggestSellerProductId: string;
    completedSalesOrdersCount: number;
    startedSalesOrdersCount: number;

    constructor(
        private _router: Router,
        private _salesorderService: SalesReturnService,

    ) {

    }


    ngOnInit(): void {

        this.fetchData();

    }

    ngAfterViewInit() {

    }
    calculateTotal(): number {
        // Check if the dataSource is defined and has data
        if (this.dataSource && this.dataSource.data) {
            return this.dataSource.data.reduce((total, row) => total + row.totalCost, 0);
        }
        // Return 0 if data is not available or undefined
        return 0;
    }

   


    updateVendorsCredit() { }


    deleteVendorsCredit(id: any) {
        // Prompt the user for confirmation before deleting the employee
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {

            if (result.isConfirmed) {
                this._salesorderService.deleteSalesOrder(id)

                    .subscribe((isDeleted) => {
                        this.fetchData();


                        if (!isDeleted) {
                            Swal.fire("Error!", "Cannot Delete");
                            return;
                        }

                        // Display a success message
                        Swal.fire(
                            'Deleted!',
                            'Your file has been deleted.',
                            'success'
                        )
                    });
            }
        });
    }



    exportexcel(): void {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You want to download the file!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, download it!'
        }).then((result) => {
            if (result.isConfirmed) {
                const element = document.getElementById('excel-table');
                const data = [];

                // Iterate over table rows and collect data
                const rows = element.querySelectorAll('tr');

                rows.forEach((row) => {
                    const rowData = [];
                    const cells = row.querySelectorAll('td');
                    cells.forEach((cell) => {
                        rowData.push(cell.textContent || ''); // Use textContent to get the cell's text
                    });
                    data.push(rowData);
                });

                // Create a new workbook and add a worksheet
                const workbook = new ExcelJS.Workbook();
                const worksheet = workbook.addWorksheet('Sheet1');

                // Define your custom styles for headers and data
                const headerStyle = { font: { bold: true }, alignment: { horizontal: 'center' as const } };
                const dataStyle = { alignment: { horizontal: 'left' as const } };

                // Add headers with custom style
                const headers = ['CustomerName',
                    'SaleNumber',
                    'Status',
                    'Shipping_Date',
                    'product',
                    'total'

                /* Add more header column names here */];
                worksheet.addRow(headers);
                worksheet.getRow(1).eachCell((cell) => {
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFF00' }, // Yellow background color
                    };
                    cell.border = {
                        top: { style: 'thin' },
                        left: { style: 'thin' },
                        bottom: { style: 'thin' },
                        right: { style: 'thin' },
                    };
                });

                // Add data with custom style
                data.forEach((rowData) => {
                    worksheet.addRow(rowData);
                    worksheet.lastRow.eachCell((cell) => {
                        cell.alignment = dataStyle.alignment;
                    });
                });

                // Calculate the total and add it to the end of the worksheet
                const total = data.reduce((sum, row) => sum + (parseFloat(row[1]) || 0), 0); // Assuming the total is in the second column (index 1)
                worksheet.addRow(['Total', total]);
                worksheet.lastRow.eachCell((cell) => {
                    cell.font = headerStyle.font;
                    cell.alignment = headerStyle.alignment;
                });

                // Create a blob and initiate file download
                workbook.xlsx.writeBuffer().then((buffer) => {
                    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    saveAs(blob, 'SalesOrder.xlsx'); // Replace 'your-filename' with the desired file name
                });
            }
        });

    }

    refreshProductList() {
        this._salesorderService.getSalesOrder()
            .subscribe((data) => {
                // Update your product list with the new data
                this.salesOrders = data['data']; // Assuming your data is in a 'data' property
                // Update the MatTableDataSource data

                // Manually trigger change detection

            });
    }


    showDetails(id: any) {
        this._router.navigate(['apps/sales-return', id]);
    }



    getStatusIcon(status: string): { icon: string; color: string } {
        if (status === 'Started Sales Order') {
            return { icon: 'heroicons_outline:shopping-bag', color: 'green' };
        } else if (status === 'Shipped Sales Order') {
            return { icon: 'heroicons_outline:check-circle', color: 'blue' };
        } else if (status === 'Delivered Sales Order') {
            return { icon: 'heroicons_outline:ban', color: 'yellow' };
        } else if (status === 'Completed Sales Order') {
            return { icon: 'heroicons_outline:ban', color: 'green' };
        } else if (status === 'Delivered Sales Order') {
            return { icon: 'heroicons_outline:ban', color: 'yellow' };
        } else if (status === 'Returned Sales Order') {
            return { icon: 'heroicons_outline:ban', color: 'red' };
        } else if (status === 'Canceled Sales Order') {
            return { icon: 'heroicons_outline:ban', color: 'red' };
        } else if (status === 'Package Created') {
            return { icon: 'heroicons_outline:shopping-bag', color: 'red' };
        }

        // Return a default icon and color if the status doesn't match any of the conditions
        return { icon: 'heroicons_outline:ban', color: 'black' };
    }

    isCollapsed(row: SalesOrderData): boolean {
        return !row.isExpanded;
    }

    toggleCollapse(row: SalesOrderData): void {
        row.isExpanded = !row.isExpanded;
    }
    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }
    createSalesExchange() {
        this._router.navigate(['apps/sales-return', 'NEW']);
    }
    getStatusColor(status: string): string {
        if (status === 'pending') {
            return 'yellow';
        } else if (status === 'completed') {
            return 'green';
        } else if (status === 'failed') {
            return 'red';
        }
        return '';
    }
    ngOnDestroy(): void {
        // throw new Error('Method not implemented.');
    }

    fetchData() {
        this.salesorder$ = this._salesorderService
            .getSalesOrder()
            .pipe(take(1));
        this.salesorder$.subscribe((salesorder) => {
            this.salesOrders = salesorder['data'];

            this.dataSource = new MatTableDataSource<SalesOrderData>(
                this.salesOrders?.map((saleOrder) => {
               

                    return {
                        Cid: saleOrder?.salesOrder?.Customers?.id,
                        customerName: saleOrder?.salesOrder?.Customers?.firstName,
                        saleNumber: saleOrder?.salesOrder?.sales_order_num,
                        saleReturnNumber: saleOrder?.salesReturnNumber,
                        status: saleOrder?.salesOrder?.status,
                        shippingDate: saleOrder?.salesOrder?.sales_order_shippmentDate,
                        returningDate: saleOrder?.date,
                        isExpanded: false,
                        totalCost: saleOrder?.total,
                        id: saleOrder.id,
                        productTotal: saleOrder?.salesOrder?.sub_total,
                        rfr: saleOrder?.reasonForReturn,
                        isReceived: saleOrder?.isReceived,

                    };
                })
            );
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            this.isLoading = false;
        });




    }

    async reciveProduct(id: any) {

        const row = {
            isReceived: true
        }
        const packageResult = await this.UpdateRecive(row, id);

        if (packageResult.success) {
            // Show success notification using Swal
            Swal.fire({
                icon: 'success',
                title: 'Package Recived!',
                text: packageResult.message,
            });
            this.fetchData();

            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener(
                        'mouseenter',
                        Swal.stopTimer
                    );
                    toast.addEventListener(
                        'mouseleave',
                        Swal.resumeTimer
                    );
                },
            });

            Toast.fire({
                icon: 'success',
                title: `Selected Package  has been recived you can now proceed to ship it!!`,
            });
        } else {
            // Show error notification using Swal
            Swal.fire({
                icon: 'error',
                title: 'Error Creating Package Order',
                text: JSON.stringify(packageResult.errors),
            });
        }

        this._router.navigate(['apps/sales-return']);

    }


    async UpdateRecive(packageData: any, id: any): Promise<any> {
        try {
          const response = await this._salesorderService.PartialupdateSalesReturn(id, packageData).toPromise();
          if (response) { // Check if response is truthy
            return { success: true, message: `${packageData.packageOrder} Package Order received successfully.` };
          } else {
            return { success: false, message: `Failed to receive package order for ${packageData.packageOrder}.` };
          }
        } catch (error) {
          throw new Error(`Error receiving Package Order: ${error.message}`);
        }
      }


}


