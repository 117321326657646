import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-detail-prize-list',
  templateUrl: './detail-prize-list.component.html',
  styleUrls: ['./detail-prize-list.component.scss']
})
export class DetailPrizeListComponent implements OnInit {
  item: any;
  itemType: string;
  title: string = '';
  displayedColumns: string[] = ['id','name', 'sku','secondary_sku','size','color'];
  totalQuantity: number = 0;
  totalAmount: number = 0;
  datac:any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  productData: MatTableDataSource<any>;

  constructor(private route: ActivatedRoute) { }
  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.itemType = params.get('type') || '';
      const itemId = params.get('id');
  
      this.item = history.state.data;
      this.datac = history.state.dataC;
      
      this.title = this.itemType.charAt(0).toUpperCase() + this.itemType.slice(1);
  
      // Ensure productData is an instance of MatTableDataSource
      this.productData = new MatTableDataSource(this.item.product_data || []);
      
      this.productData.paginator = this.paginator;
      this.productData.sort = this.sort;
  
      this.totalQuantity = this.item.total_quantity || 0;
      this.totalAmount = this.item.total_amount || 0;
    });
  }
  
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.productData.filter = filterValue.trim().toLowerCase();

    if (this.productData.paginator) {
      this.productData.paginator.firstPage();
    }
  }

  goBackOnPreviousWin = () => {
    window.history.back()
}
}  