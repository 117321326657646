import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PackagesOderService } from '../packages.service';

@Component({
    selector: 'app-detailpackages',
    templateUrl: './detailpackages.component.html',
    styleUrls: ['./detailpackages.component.scss'],
})
export class DetailpackagesComponent implements OnInit {
    productId: string;
    vendor: any; // Replace 'any' with the actual type of your product
    dropdownHidden: boolean = true; //drop down for create the pack
    delayTimeout: any;

    constructor(
        private route: ActivatedRoute,
        private packageService: PackagesOderService // Replace with your actual product service
    ) {}

    ngOnInit(): void {
        this.route.paramMap.subscribe((params) => {
            this.productId = params.get('id');
            this.fetchProductDetails(this.productId);
        });
    }
    fetchProductDetails(productId: string): void {
        // Use your product service to fetch the product details based on the provided ID
        this.packageService.getPackageOderByID(productId).subscribe(
            (data) => {
                this.vendor = data;
                console.log(this.vendor,'&&&&&&&&&&&&&&&&')
            },
            (error) => {
                console.error('Error fetching product details:', error);
            }
        );
    }
    printPage() {
        // Select the buttons you want to hide
        const buttonsToHide = document.querySelectorAll('button');
        
        // Hide the buttons before printing
        buttonsToHide.forEach((button) => {
          button.style.display = 'none';
        });
      
        // Perform the printing
        window.print();
      
        // Show the buttons again after printing (delayed to ensure they show after the print dialog)
        setTimeout(() => {
          buttonsToHide.forEach((button) => {
            button.style.display = 'block'; // or 'inline' depending on the button's original display property
          });
        }, 1000); // Adjust the delay as needed
      }
      
      
    
    getThumbnailUrl(thumbnail: string): string {
        const baseUrl = 'http://103.147.227.9:2/8081'; // Your base URL here
        return baseUrl + thumbnail;
    }
    toggleDropdown() {
        this.dropdownHidden = !this.dropdownHidden;
    }

}
