
import {
    ChangeDetectorRef,
    Component,

    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import {
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject, of, forkJoin } from 'rxjs';
import { debounceTime, map, startWith, switchMap, take, takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { SalesOrder } from 'app/sales-order/sales-order.type';
import { SalesOrderService } from 'app/sales-order/sales-order.service';
import { DatePipe } from '@angular/common';
import { ExchangesService } from '../exchanges.service';
import { productsService } from 'app/products/products.service';
import { products } from 'app/products/products.type';
import { SalesOrderProductPopupComponent } from 'app/sales-order/sales-order-product-popup/sales-order-product-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { productBundle } from 'app/product-bundle/product-bundle.type';
import { ProductBundlePopupComponent } from 'app/product-bundle-popup/product-bundle-popup.component';
import { contrydata, insideValleyData } from 'app/sales-order/sales-order-create/countries';
import { UserService } from 'app/core/user/user.service';
import { User } from 'app/core/user/user.types';
import { MatDrawer } from '@angular/material/sidenav';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { HasUnsavedChanges } from 'app/page-detect/wm-can-leave/detect-changes.guard';
import { CreditNotesService } from 'app/credit-notes/credit-notes.service';
import { Exchanges } from '../exchanges.type';
import { PreOrdersService } from 'app/pre-orders/pre-orders.service';
import { update } from 'lodash';
export interface SalesOrderOption {
    sales_order_num: string;
}
@Component({
    selector: 'app-create-exchanges',
    templateUrl: './create-exchanges.component.html',
    styleUrls: ['./create-exchanges.component.scss'],
})
export class CreateExchangesComponent implements OnInit, OnDestroy, HasUnsavedChanges {

    @ViewChild('matDrawer', { static: true }) matDrawer: MatDrawer;
    saleOrderIDToReturn: string
    maxDate = new Date();
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    vendorsCredit: FormGroup;
    salesOrder$: Observable<SalesOrder[]>;
    exchanges$: Observable<Exchanges[]>;
    selectedSalesOrder: SalesOrder;
    selectedExchangeOrder: Exchanges;

    ExchangeNumberControl: FormControl = new FormControl();
    SaleOrderNumberControl: FormControl = new FormControl();
    filteredSalesOrder$: Observable<SalesOrder[]>;
    filteredExchangeOrders$: Observable<Exchanges[]>;
    filteredProductOptions$: Observable<products[]>;
    productVariationsMap: { [productId: string]: any[] } = {};
    selectedSizeColorMap: { [productId: string]: { size: string, color: string } } = {};
    sizes: any[] = [];
    colors: any[] = [];
    mappedVariations: any;
    active: boolean[] = [];
    productActive: boolean[] = [];
    name: any[] = [];
    quantity: any[] = [];


    current_stock: any[] = [];
    product_details: any[] = [];
    animationFlags: boolean[] = [];
    match_stock: boolean[] = [];
    filterByBundle: boolean[] = [];
    configForm: FormGroup;

    matchedVariationIds: Set<string> = new Set();
    matchedProductBundleIds: Set<string> = new Set();
    private searchTerms = new Subject<string>();
    private allProducts: any[] = [];
    successMessage: string;
    errorMessage: string;

    productIds: number[] = [];
    selectedProductThumbnail: string[] = []
    selectedProductThumbnailClass: string;
    isFieldReadOnly: boolean = false;
    isFieldReadOnlyExchange: boolean = false
    current_stock_sales: { [key: number]: number } = {}; // Store current stock for each product
    quantityErrors: string[] = [];
    showAddressFields: boolean = false;
    showDeliveryType: boolean = true;
    showSocailLink: boolean = false;
    showEmail: boolean = false;
    showPaymentMethod: boolean = false;
    showShippingArea: boolean = false;
    showAddress: boolean = false;
    showShippinginvalley: boolean = false;
    showShippingOutvalley: boolean = false;
    showCountry: boolean = false;
    shwoShippingInternational: boolean = false;
    showDistrict: boolean = false;
    showOthers: boolean = false;
    showDeliveryDetails: boolean = false;
    showExpectedShippingDate: boolean = true;
    shipping_required = false;
    formattedAddress = '';
    countryControl = new FormControl();
    countries: string[] = contrydata;
    districtControl = new FormControl();
    filteredDistricts: Observable<string[]>;
    InsidefilteredDistricts: Observable<string[]>;
    districts: string[] = [];
    user: User;
    isFieldReadOnly_outside_val = false;
    filteredCountries: Observable<string[]>;
    drawerMode: string;
    selectedSalesOrderId: string;
    redelivered: boolean = false;
    preOrder$: any;
    currentSales: any = {};
    absoluteRemainingAmount: number; // Variable to store the absolute value for display

    paymentOptions = [
        'CASH ON DELIVERY',
        "SIDDHARTHA BANK FONEPAY - HEADQUARTER",
        "SIDDHARTHA BANK FONEPAY - JHAMSIKHEL",
        "NIC ASIA BANK FONEPAY - HEADQUARTER",
        "NIC ASIA BANK FONEPAY",
    ];


    created: any;
    salesPerson: any;
    formattedTime: string;
    confirmationAcknowledged: boolean = false;
    useFormValues: boolean;
    enablePartialExchange: boolean = false;
    salesOrderApplied: any;
    saleProduct: any;

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _formBuilder: FormBuilder,
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _salesreturnService: ExchangesService,
        private _salesOrderService: SalesOrderService,
        private _preOrderService: PreOrdersService,
        private _productService: productsService,
        private datePipe: DatePipe,
        private dialog: MatDialog,
        private cd: ChangeDetectorRef,
        private _userService: UserService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseConfirmationService: FuseConfirmationService,
        private _creditNoteService: CreditNotesService




    ) {
        this.salesOrder$ = this._salesOrderService
            .getSalesOrder()

            .pipe(take(1));

        this.salesOrder$.subscribe((salesOrder) => {
        });

        this.exchanges$ = this._salesreturnService.getSalesOrder().pipe(take(1));
        this.exchanges$.subscribe(() => {

        });

        this.exchanges$ = this._salesreturnService.getSalesOrder().pipe(take(1));
        this.exchanges$.subscribe(() => {

        });



        this.preOrder$ = this._preOrderService.getpreorder().pipe(take(1));
        this.preOrder$.subscribe(() => {

        });


        this.filteredCountries = this.countryControl.valueChanges.pipe(
            startWith(''),
            map((value) => this._filterCountries(value))
        );
        this.InsidefilteredDistricts = this.districtControl.valueChanges.pipe(
            startWith(''),
            map((value) => this._filterInsideDistricts(value))
        )
    }

    ngOnInit() {
        this.loadServices();
        this.loadAllProducts();

        this.configForm = this._formBuilder.group({
            title: 'Info',
            message: '<span class="font-medium">Are you sure you want to proceed?</span> Please be aware that once you remove the product, all the data will be reverted. Are you sure you want to finalize it? Proceed carefully.',
            icon: this._formBuilder.group({
                show: true,
                name: 'heroicons_outline:exclamation',
                color: 'info'
            }),
            actions: this._formBuilder.group({
                confirm: this._formBuilder.group({
                    show: true,
                    label: 'Proceed',
                    color: 'accent'
                }),
                cancel: this._formBuilder.group({
                    show: true,
                    label: 'Cancel',
                    color: 'warn'

                })
            }),
            dismissible: true
        });

        this.vendorsCredit = this._formBuilder.group({
            sales_order_num: this.SaleOrderNumberControl,
            exchanges_order_num: this.ExchangeNumberControl,
            customerControl: new FormControl(),
            saleProduct: this._formBuilder.array([this.productArray()]),
            products: this._formBuilder.array([]),
            date: new FormControl(new Date(), [Validators.required]),
            rfr: new FormControl(''),
            totalAmount: new FormControl(),
            sub_total: [],
            exchange_type: [''],
            total: [],
            adjustment: [0],
            discount: [0],
            shipping_charges: [0],
            discountSuffix: ['%'],
            adjustmentSuffix: ['%'],
            remainingAmount: [''],
            pos: [''],
            sales_order_shippmentDate: [''],
            email: [''],
            soical_link: [''],
            delivery_type: [null, this.showDeliveryType ? Validators.required : null],
            shipping_area: [null, this.showShippingArea ? Validators.required : null],
            payment_type: [null, Validators.required],
            payment_terms: [''],
            myCheckbox: new FormControl(true),
            district: this.districtControl,
            country: this.countryControl,
            shipping_carrier_invalley: [''],
            shipping_carrier_invalley_others: [''],
            shipping_carrier_outvalley: [''],
            shipping_carrier_int: [''],
            delivery_detail: [''],
            sales_person: [''],
            others: [''],
            tags: [''],
            others_out: [''],
            refrences: [''],
            address: [''],
            delivery_number:[''],
            isCredited: [''],
            isDebited: [''],
            partial_exchange: [false]

        });

        this.configForm = this._formBuilder.group({
            title: 'Info',
            message: 'You\'re about to inherit the selected sales order data for the exchange. You have the option to modify the information or proceed with the existing data. <br> <span class="font-medium">Note: Unedited data will retain the original sales order details!</span>',

            icon: this._formBuilder.group({
                show: true,
                name: 'heroicons_outline:exclamation',
                color: 'info'
            }),
            actions: this._formBuilder.group({
                confirm: this._formBuilder.group({
                    show: true,
                    label: 'I understand',
                    color: 'accent'
                }),

            }),
        });

        this.filteredProductOptions$ = of([]);

        (this.vendorsCredit.get('products') as FormArray).clear();

        const initialProductCount = 1;


        const subscribeToValueChanges = (productGroup: FormGroup) => {
            const rateControl = productGroup.get('rate');
            const quantityControl = productGroup.get('quantity');

            rateControl.valueChanges.subscribe(() => {
                this.calculateTotal();
            });

            quantityControl.valueChanges.subscribe(() => {
                this.calculateTotal();
            });
            this.vendorsCredit.get('sales_person').patchValue(this.user.username.toLocaleUpperCase());


        };
        this.vendorsCredit.get('pos').valueChanges.subscribe((newValue) => {
            this.pointOfsales();
        });

        // Subscribe to changes in remainingAmount to update absoluteRemainingAmount
        this.vendorsCredit.get('remainingAmount').valueChanges.subscribe(value => {
            this.absoluteRemainingAmount = Math.abs(value);
        });

        this.vendorsCredit.get('shipping_area').valueChanges.subscribe((newValue) => {
            this.addressField();
        })

        for (let i = 0; i < initialProductCount; i++) {
            const productGroup = this._formBuilder.group({
                id: [''],
                product_bundle: [''],
                bundle_id: [''],
                product: ['', [Validators.required]],
                quantity: ['', Validators.required],
                rate: ['', Validators.required],
                amount: [''],
                color: [''],
                size: [''],
            });

            subscribeToValueChanges(productGroup);

            (this.vendorsCredit.get('products') as FormArray).push(
                productGroup
            );
        }
        this.vendorsCredit.get('discount').valueChanges.subscribe(() => {
            this.calculateTotal();
        });
        this.vendorsCredit.get('discountSuffix').valueChanges.subscribe(() => {
            this.calculateTotal();
        });

        this.subscribeToChanges();

        this.vendorsCredit
            .get('shipping_charges')
            .valueChanges.subscribe(() => {
                this.calculateTotal();
            });

        this.vendorsCredit.get('adjustment').valueChanges.subscribe(() => {
            this.calculateTotal();
        });
        this.vendorsCredit
            .get('adjustmentSuffix')
            .valueChanges.subscribe(() => {
                this.calculateTotal();
            });


        this.matDrawer.openedChange.subscribe((opened) => {
            if (!opened) {

                this._changeDetectorRef.markForCheck();
            }
        });

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {

                // Set the drawerMode if the given breakpoint is active
                if (matchingAliases.includes('lg')) {
                    this.drawerMode = 'side';
                }
                else {
                    this.drawerMode = 'over';
                }

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });


    }


    handleAddressChange(address: any) {
        if (!address) { return; }
        const addrName = address.name.split(",");
        const formattedAddr = address.formatted_address.split(",");
        if (formattedAddr.includes(...addrName)) {
            this.formattedAddress = address.formatted_address;
        } else {
            this.formattedAddress = `${address.name}, ${address.formatted_address}`;
        }
        this.vendorsCredit.get('address').patchValue(this.formattedAddress);
    }



    productArray() {
        return this._formBuilder.group({
            sale_product: new FormControl(''),
            sale_quantity: new FormControl(''),
            sale_rate: new FormControl(''),
            sale_color: new FormControl(''),
            sale_size: new FormControl(''),
            sale_amount: new FormControl(''),
            id: new FormControl(),
            is_selected: new FormControl(),
        });
    }


    setProduct(products: any[]): FormArray {
        const productFormArray = new FormArray([]);

        try {
            // Count the number of products
            const productCount = products.length;

            // Check if there are more than one product
            if (productCount > 1) {
                this.enablePartialExchange = true;
            }

            if (!products) {
                throw new Error('Products unavailable');
            }

            products.forEach((element) => {
                productFormArray.push(
                    this._formBuilder.group({
                        id: element.product.id,
                        sale_product: element.product.base_product.productName,
                        sale_color: element.product.color,
                        sale_size: element.product.size,
                        sale_quantity: [element.quantity, Validators.required], // Add Validators if needed
                        sale_amount: element.amount,
                        sale_rate: element.rate,
                        is_selected: element.is_selected,
                    })
                );
                // Set the initial quantity to the current stock
                this.current_stock_sales[element.product.id] = element.quantity;
            });

            return productFormArray;
        } catch (err) {
            console.error(err.message);
        }
    }


    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }


    async submitVendorsInfo() {
        if (this.vendorsCredit.valid) {
            this.showLoading("Creating, please wait...");

            // Update the form controls with the latest values
            this.updateFormControls();

            // Prepare customer data
            let customerData = this.prepareCustomerData();

            // Prepare updated products for customerData
            const updatedProducts = this.getUpdatedProducts();
            customerData.exchange_products = updatedProducts;
            if (customerData.partial_exchange == true) {

                await this.handlePartialExchange(updatedProducts);

                customerData.saleProduct = this.saleProduct;

            } else {

                await this.handleNonPartialExchange(updatedProducts);

            }

            customerData.sales_order = this.saleOrderIDToReturn;

            // Create sales orders and handle the response
            try {
                const response = await this._salesreturnService.createSalesOrders(customerData);
                if (response.success) {
                    await this.handlePostSalesOrder(response, customerData);
                } else {
                    this.showError(response.message);
                }
            } catch (error) {
                console.error(error);
            }

            // Navigate to the list page
            this.resetFormAndNavigate();
        } else {
            console.error('Form is invalid:', this.vendorsCredit.errors);
        }
    }

    showLoading(message: string) {
        Swal.fire({
            title: "Loading",
            html: `
            <div style="display: flex; flex-direction: column; align-items: center;">
                <img src="assets/loading.gif" style="width: auto; height: auto;" alt="loading"/>
                <p style="margin: 10px 0 0 0; font-size: 14px; color: #666;">${message}</p>
            </div>
        `,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading();
            },
        });
    }

    updateFormControls() {
        const saleProduct = this.vendorsCredit.get('saleProduct') as FormArray;
        saleProduct.controls.forEach((control: FormGroup) => {
            const updatedProduct = {
                id: control.get('id').value,
                sale_product: control.get('sale_product').value,
                sale_color: control.get('sale_color').value,
                sale_size: control.get('sale_size').value,
                sale_quantity: control.get('sale_quantity').value,
                sale_amount: control.get('sale_amount').value,
                sale_rate: control.get('sale_rate').value,
                is_selected: control.get('is_selected') ? control.get('is_selected').value : false
            };
            control.patchValue(updatedProduct);
        });
    }

    prepareCustomerData() {
        let customerData = { ...this.vendorsCredit.value };
        customerData.date = this.datePipe.transform(this.vendorsCredit.get('date')?.value, 'yyyy-MM-dd');
        customerData.reasonForReturn = this.vendorsCredit?.get("rfr").value;
        return customerData;
    }

    getUpdatedProducts() {
        const products = this.vendorsCredit.get('products') as FormArray;
        return products.value.map((item: any) => ({
            product: item?.id,
            quantity: item?.quantity,
            rate: item?.rate,
            amount: item?.amount,
            color: item?.color,
            size: item?.size,
        }));
    }

    async handlePartialExchange(updateProd: any[]) {
        const saleProduct = this.vendorsCredit.get('saleProduct') as FormArray;

        this.currentSales = this.currentSales || {};
        const updatedProductsSales: any[] = saleProduct.controls
            .filter((control: FormGroup) => control.get('is_selected')?.value) // Filter by is_selected
            .map((control: FormGroup) => ({
                product: control.get('id').value,
                quantity: control.get('sale_quantity').value,
                rate: control.get('sale_rate').value,
                amount: control.get('sale_amount').value,
                color: control.get('sale_color').value,
                size: control.get('sale_size').value,
            }));

        let hasChanges = false;

        const nonSelectedProducts = saleProduct.controls.filter((control: FormGroup) => {
            const isSelected = control.get('is_selected')?.value;
            return !isSelected;  // Include non-selected products
        });

        const selectedProducts = saleProduct.controls.filter((control: FormGroup) => {
            const isSelected = control.get('is_selected')?.value;
            return isSelected;
        });


        saleProduct.clear();

        selectedProducts.forEach((control: FormGroup) => {
            const updatedProduct = {
                id: control.get('id').value,
                sale_product: control.get('sale_product').value,
                sale_quantity: control.get('sale_quantity').value,
                sale_rate: control.get('sale_rate').value,
                sale_amount: control.get('sale_amount').value,
                sale_color: control.get('sale_color').value,
                sale_size: control.get('sale_size').value,
                is_selected: true  // Mark as selected
            };
            saleProduct.push(this.createProductFormGroup(updatedProduct));
        });


        this.saleProduct = saleProduct.controls.map((control: FormGroup) => {
            return {
                id: control.get('id').value,
                sale_product: control.get('sale_product').value,
                sale_quantity: control.get('sale_quantity').value,
                sale_rate: control.get('sale_rate').value,
                sale_amount: control.get('sale_amount').value,
                sale_color: control.get('sale_color').value,
                sale_size: control.get('sale_size').value,
                is_selected: control.get('is_selected').value
            };
        });

        this.currentSales.products = []; // Initialize or reset currentSales.products


        // Processing updated products to detect changes
        updatedProductsSales.forEach((updatedProduct, index) => {
            const baseQuantity = this.current_stock_sales[updatedProduct.product];
            const activeQuantity = updatedProduct.quantity;
            const originalSalesQuantity = baseQuantity - activeQuantity;



            // Check if there's any change in the product's stock
            if (activeQuantity !== baseQuantity) {
                hasChanges = true;

                this.currentSales.products.push({
                    product: updatedProduct.product,
                    quantity: originalSalesQuantity,
                    rate: updatedProduct.rate,
                    amount: updatedProduct.amount,
                    color: updatedProduct.color,
                    size: updatedProduct.size,
                });

            } else {
            }
        });

        // If there are changes, merge nonSelectedProducts with the updated products
        if (hasChanges) {
            this.currentSales.products = [
                ...this.currentSales.products, // Existing changed products
                ...nonSelectedProducts.map((control: FormGroup) => ({
                    product: control.get('id').value,
                    quantity: control.get('sale_quantity').value,
                    rate: control.get('sale_rate').value,
                    amount: control.get('sale_amount').value,
                    color: control.get('sale_color').value,
                    size: control.get('sale_size').value,
                })),
            ];

        } else {
            // If no changes, use nonSelectedProducts only
            this.currentSales.products = nonSelectedProducts.map((control: FormGroup) => ({
                product: control.get('id').value,
                quantity: control.get('sale_quantity').value,
                rate: control.get('sale_rate').value,
                amount: control.get('sale_amount').value,
                color: control.get('sale_color').value,
                size: control.get('sale_size').value,
            }));
        }

        // Regardless of changes, calculate totals and update the currentSales object
        this.calculateTotals(this.currentSales.products);

        // Ensure currentSales object is updated with other required fields
        this.currentSales.total = this.currentSales.total || 0;
        this.currentSales.sub_total = this.currentSales.sub_total || 0;
        this.currentSales.discount = this.salesOrderApplied.discount || 0;
        this.currentSales.discountSuffix = this.salesOrderApplied.discountSuffix || '';
        this.currentSales.adjustment = this.salesOrderApplied.adjustment || 0;
        this.currentSales.adjustmentSuffix = this.salesOrderApplied.adjustmentSuffix || '';
        this.currentSales.shipping_charges = this.salesOrderApplied.shipping_charges || 0;
        this.currentSales.status = 'Partial Exchange Sales Order';
        this.currentSales.is_partialExchange = true;

        try {
            const response = await this._salesOrderService.PartialupdateSalesOrder(this.saleOrderIDToReturn, this.currentSales);
        } catch (error) {
            console.error('Error in PartialupdateSalesOrder:', error);
        }

    }

    // Create FormGroup from product data
    createProductFormGroup(product: any): FormGroup {
        return new FormGroup({
            id: new FormControl(product.id),
            sale_product: new FormControl(product.sale_product),
            sale_quantity: new FormControl(product.sale_quantity),
            sale_rate: new FormControl(product.sale_rate),
            sale_amount: new FormControl(product.sale_amount),
            sale_color: new FormControl(product.sale_color),
            sale_size: new FormControl(product.sale_size),
            is_selected: new FormControl(product.is_selected),
        });
    }



    async handleNonPartialExchange(updatedProducts: any[]) {
        const hasChanges = await this.updateSalesOrderIfNeeded(updatedProducts);

        if (hasChanges) {
            try {
                const response = await this._salesOrderService.PartialupdateSalesOrder(this.saleOrderIDToReturn, this.currentSales);
            } catch (error) {
                console.error(error);
            }
        }
    }

    async updateSalesOrderIfNeeded(updatedProducts: any[]): Promise<boolean> {
        const saleProduct = this.vendorsCredit.get('saleProduct') as FormArray;
        const updatedProductsSales: any[] = saleProduct.controls.map((control: FormGroup) => ({
            product: control.get('id').value,
            quantity: control.get('sale_quantity').value,
            rate: control.get('sale_rate').value,
            amount: control.get('sale_amount').value,
            color: control.get('sale_color').value,
            size: control.get('sale_size').value,
        }));

        let hasChanges = false;

        updatedProductsSales.forEach((updatedProduct, index) => {
            const baseQuantity = this.current_stock_sales[updatedProduct.product];
            const activeQuantity = updatedProduct.quantity;
            const originalSalesQuantity = baseQuantity - activeQuantity;

            if (activeQuantity !== baseQuantity) {
                hasChanges = true;

                // Ensure the currentSales.products array is initialized properly
                if (!this.currentSales.products) {
                    this.currentSales.products = [];
                }

                // Update the quantity in currentSales object with the original stock values
                this.currentSales.products[index] = {
                    product: updatedProduct.product,
                    quantity: originalSalesQuantity, // Update with the original stock value
                    rate: updatedProduct.rate,
                    amount: updatedProduct.amount,
                    color: updatedProduct.color,
                    size: updatedProduct.size,
                };

                saleProduct.at(index).patchValue({
                    product: updatedProduct.product,
                    quantity: activeQuantity, // Use the correct applied quantity
                    rate: updatedProduct.rate,
                    amount: updatedProduct.amount,
                    color: updatedProduct.color,
                    size: updatedProduct.size,
                });



            }
        });

        if (hasChanges) {
            this.calculateTotals(this.currentSales.products);

            this.currentSales = {
                products: this.currentSales.products, // Use the properly updated currentSales.products
                total: this.currentSales.total,
                sub_total: this.currentSales.sub_total,
                discount: this.salesOrderApplied.discount,
                discountSuffix: this.salesOrderApplied.discountSuffix,
                adjustment: this.salesOrderApplied.adjustment,
                adjustmentSuffix: this.salesOrderApplied.adjustmentSuffix,
                shipping_charges: this.salesOrderApplied.shipping_charges,
                status: 'Partial Exchange Sales Order',
                is_partialExchange: true

            }

        }
        const saleProductFormArray = this.vendorsCredit.get('saleProduct') as FormArray;

        const saleProductData = this.transformFormArrayToJson(saleProductFormArray);



        return hasChanges;
    }

    private transformFormArrayToJson(formArray: FormArray): { [key: string]: any } {
        const result: { [key: string]: any } = {};

        formArray.controls.forEach((control: FormGroup) => {
            const productId = control.get('product')?.value;
            result[productId] = {
                product: productId,
                quantity: control.get('quantity')?.value,
                rate: control.get('rate')?.value,
                amount: control.get('amount')?.value,
                color: control.get('color')?.value,
                size: control.get('size')?.value
            };
        });

        return result;
    }


    calculateTotals(updatedProductsSales: any[]) {
        let totalAmount = 0;

        updatedProductsSales.forEach(selectedProduct => {
            // Calculate the new amount based on quantity and rate
            if (selectedProduct.quantity != null && selectedProduct.rate != null) {
                selectedProduct.amount = selectedProduct.quantity * selectedProduct.rate;
            }

            if (selectedProduct.amount != null) {
                totalAmount += selectedProduct.amount;
            } else {
                console.warn(`Product with id ${selectedProduct.product} has a null or undefined amount.`);
            }
        });

        let calculatedDiscount = this.salesOrderApplied.discount;
        let calculatedAdjustment = this.salesOrderApplied.adjustment;
        let shipping = this.salesOrderApplied.shipping_charges || 0;

        if (this.salesOrderApplied.discountSuffix === '%') {
            calculatedDiscount = (totalAmount * this.salesOrderApplied.discount) / 100;
        }

        if (this.salesOrderApplied.adjustmentSuffix === '%') {
            calculatedAdjustment = (totalAmount * this.salesOrderApplied.adjustment) / 100;
        }

        if (this.salesOrderApplied.adjustmentSuffix === '%' && this.salesOrderApplied.discountSuffix === '%') {
            calculatedDiscount = (totalAmount * this.salesOrderApplied.discount) / 100;
            const newTotal = totalAmount - calculatedDiscount;
            calculatedAdjustment = (newTotal * this.salesOrderApplied.adjustment) / 100;
        }

        this.currentSales.total = totalAmount - calculatedDiscount + calculatedAdjustment + shipping;
        this.currentSales.sub_total = totalAmount;


    }

    async handlePostSalesOrder(response: any, customerData: any) {
        const { id } = response.data;
        const remainingAmountValue = this.vendorsCredit.get('remainingAmount').value;
        if (remainingAmountValue > 0) {
            await this.processCreditNote(response.data, id);
            customerData.isDebited = true;
        } else if (remainingAmountValue < 0) {
            customerData.isDebited = false;

            await this.processDebitNote(response.data, id);
        } else {
            this.showSuccess('Exchnage completed successfully.');
        }
    }

    showSuccess(message: string) {
        Swal.fire({
            icon: 'info',
            title: 'Success!',
            text: message,
            showConfirmButton: false,
            backdrop: true,
            allowOutsideClick: false,
            position: 'top-end',
            toast: true,
            timer: 3000
        });
    }

    showError(message: string) {
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: message,
        });
    }


    async processCreditNote(responseData: any, id: number) {
        Swal.fire({
            title: "Creating credit note for remaining amount",
            html: `
<div style="display: flex; flex-direction: column; align-items: center;">
    <img src="assets/loading.gif" style="width: auto; height: auto;" alt="loading"/>
    <p style="margin: 10px 0 0 0; font-size: 14px; color: #666;">Creating, please wait...</p>
</div>
`,
            timerProgressBar: true,
            didOpen: async () => {
                Swal.showLoading();
                try {
                    await this.paymentDue(responseData, id);
                    Swal.close();
                    this.showSuccess('Credit Note processing completed successfully.');
                } catch (error) {
                    console.error('Error in paymentDue:', error);
                }
            }
        });
    }

    async processDebitNote(responseData: any, id: number) {
        Swal.fire({
            title: "Creating debit note for remaining amount",
            html: `
<div style="display: flex; flex-direction: column; align-items: center;">
    <img src="assets/loading.gif" style="width: auto; height: auto;" alt="loading"/>
    <p style="margin: 10px 0 0 0; font-size: 14px; color: #666;">Creating, please wait...</p>
</div>
`,
            timerProgressBar: true,
            didOpen: async () => {
                Swal.showLoading();
                try {
                    Swal.close();
                    this.showSuccess('Debit Note processing completed successfully.');
                } catch (error) {
                    console.error('Error in paymentDue:', error);
                }
            }
        });
    }

    resetFormAndNavigate() {
        // Reset the form
        this.vendorsCredit.reset();

        // Navigate to the list page
        this._router.navigate(['apps/exchanges']).then(() => {
            // Confirm navigation has completed
        });
    }




    saveAsDraft() {
        // Handle save as draft functionality
    }

    saveAndPrint() {
        // Handle save and print functionality
    }


    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }


    validateQuantity(index: number): void {
        const productFormArray = this.vendorsCredit.get('saleProduct') as FormArray;
        const productGroup = productFormArray.at(index) as FormGroup;

        const control = productGroup.get('sale_quantity');
        const enteredQuantity = parseInt(control.value, 10);
        const productId = productGroup.get('id').value;
        const currentStock = this.current_stock_sales[productId];

        if (isNaN(enteredQuantity)) {
            control.setErrors({ invalidQuantity: true });
        } else if (enteredQuantity > currentStock) {
            control.setErrors({ exceedStock: true });
        } else if (enteredQuantity <= 0) {
            control.setErrors({ negativeQuantity: true });
        } else {
            control.setErrors(null);
            this.calculateTotalApplied();
        }
    }


    toggleFilter() {

        this.redelivered = !this.redelivered;
    }
    openDrawer() {
        if (this.useFormValues) {
            this.matDrawer.open();
            return;
        }


        this._salesOrderService.getSalesOrderById(this.saleOrderIDToReturn).subscribe(
            (response: any) => {

                if (!response) {
                    throw new Error(`Sales Order of id ${this.saleOrderIDToReturn} not found`)
                }

                this.vendorsCredit.patchValue({

                    pos: response.pos,
                    sales_order_shippmentDate: response.sales_order_shippmentDate,
                    email: response.email,
                    soical_link: response.soical_link,
                    delivery_type: response.delivery_type,
                    payment_type: response.payment_type,
                    payment_terms: response.payment_terms,
                    district: response.district,
                    country: response.country,
                    shipping_area: response.shipping_area,
                    shipping_carrier_invalley: response.shipping_carrier_invalley,
                    shipping_carrier_invalley_others: response.shipping_carrier_invalley_others,
                    shipping_carrier_outvalley: response.shipping_carrier_outvalley,
                    shipping_carrier_int: response.shipping_carrier_int,
                    delivery_detail: response.delivery_detail,
                    refrences: response.refrences,
                    address: response.address,
                    delivery_number:response.delivery_number,



                });

                this.created = response.created;
                this.salesPerson = response.sales_person;

                const timestampString = response.created;
                const dateObject = new Date(timestampString);

                this.created = dateObject.toLocaleDateString(); // "1/2/2024" (adjust based on your locale)
                this.formattedTime = dateObject.toLocaleTimeString(); // "11:36:40 AM" (adjust based on your locale)
                this.cd.detectChanges();
                this.matDrawer.open();

            },
            (error) => {
                console.error('Error fetching sales order by ID', error.message);
                // Handle error if needed
            }
        );
    }


    patchValues() {
        this.useFormValues = true;


        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener(
                    'mouseenter',
                    Swal.stopTimer
                );
                toast.addEventListener(
                    'mouseleave',
                    Swal.resumeTimer
                );
            },
        });

        Toast.fire({
            icon: 'success',
            title: `Sales exchange Data has been updated!!`,
        });

        this.matDrawer.close();
    }

    closeDrawer() {
        this.matDrawer.close();
    }


    openConfirmationDialog(): Observable<boolean> {
        const dialogRef = this._fuseConfirmationService.open(this.configForm.value);

        return dialogRef.afterClosed().pipe(
            map(result => {
                return !!result;
            })
        );
    }

    filterSalesOrderOptions(value: string): Observable<SalesOrder[]> {
        if (typeof value !== 'string') {
            return of([]); // Return an empty array if value is not a string
        }

        const filterValue = value.toLowerCase();
        return this.salesOrder$.pipe(
            map((salesOrder) => {
                if (Array.isArray(salesOrder['data'])) {
                    return salesOrder['data'].filter((salesOrder) =>
                        salesOrder.sales_order_num
                            .toLowerCase()
                            .includes(filterValue)
                    );
                } else {
                    console.error(
                        'SalesOrder data is not available:',
                        salesOrder
                    );
                    return [];
                }
            })
        );
    }

    displaySalesOrderOption(salesOrder: SalesOrder): string {
        const sales_order_num = salesOrder?.sales_order_num || '';

        return sales_order_num;
    }



    filterExchangeOrderOptions(value: string): Observable<Exchanges[]> {
        if (typeof value !== 'string') {
            return of([]); // Return an empty array if value is not a string
        }

        const filterValue = value.toLowerCase();
        return this.exchanges$.pipe(
            map((salesOrder) => {
                if (Array.isArray(salesOrder['data'])) {
                    return salesOrder['data'].filter((salesOrder) =>
                        salesOrder.exchange_number
                            .toLowerCase()
                            .includes(filterValue)
                    );
                } else {
                    console.error(
                        'SalesOrder data is not available:',
                        salesOrder
                    );
                    return [];
                }
            })
        );
    }


    selectExchangeOption(option: Exchanges, index?: number) {
        if (option) {
            this.saleOrderIDToReturn = option.sales_order.id;
            this.patchExchangeValues(option.sales_order.id);
            const customer = option.sales_order.Customers.firstName + " " + option.sales_order.Customers.lastName;
            this.vendorsCredit.get('customerControl').patchValue(customer);
            this.vendorsCredit.get('totalAmount').patchValue(option.remainingAmount);
            this.isFieldReadOnlyExchange = true;
            this.vendorsCredit.setControl('saleProduct', this.setProduct(option.exchange_products));
            this.vendorsCredit.updateValueAndValidity();

            // Skip confirmation if the user has acknowledged it before
            if (!this.confirmationAcknowledged) {
                this.openConfirmationDialog().subscribe(result => {
                    if (result) {
                        this.confirmationAcknowledged = true;
                        this._changeDetectorRef.detectChanges();
                    }
                });
            }
        }
    }


    displayexchangesOption(salesOrder: Exchanges): string {
        const sales_order_num = salesOrder?.exchange_number || '';

        return sales_order_num;
    }

    patchExchangeValues(id: any) {

        this._salesOrderService.getSalesOrderById(id).subscribe(
            (response: any) => {

                if (!response) {
                    throw new Error(`Sales Order of id ${id} not found`)
                }

                this.vendorsCredit.patchValue({

                    pos: response.pos,
                    sales_order_shippmentDate: response.sales_order_shippmentDate,
                    email: response.email,
                    soical_link: response.soical_link,
                    delivery_type: response.delivery_type,
                    payment_type: response.payment_type,
                    payment_terms: response.payment_terms,
                    district: response.district,
                    country: response.country,
                    shipping_area: response.shipping_area,
                    shipping_carrier_invalley: response.shipping_carrier_invalley,
                    shipping_carrier_invalley_others: response.shipping_carrier_invalley_others,
                    shipping_carrier_outvalley: response.shipping_carrier_outvalley,
                    shipping_carrier_int: response.shipping_carrier_int,
                    delivery_detail: response.delivery_detail,
                    refrences: response.refrences,
                    address: response.address,
                    delivery_number:response.delivery_number,



                });

                this.created = response.created;
                this.salesPerson = response.sales_person;

                const timestampString = response.created;
                const dateObject = new Date(timestampString);

                this.created = dateObject.toLocaleDateString(); // "1/2/2024" (adjust based on your locale)
                this.formattedTime = dateObject.toLocaleTimeString(); // "11:36:40 AM" (adjust based on your locale)

            },
            (error) => {
                console.error('Error fetching sales order by ID', error.message);
                // Handle error if needed
            }
        );

    }

    selectSalesOrderOption(option: SalesOrder, index?: number) {
        if (option) {
            this.salesOrderApplied = option;
            this.saleOrderIDToReturn = option.id;
            this.patchExchangeValues(option.id);
            const customer = option.Customers.firstName + " " + option.Customers.lastName;
            this.vendorsCredit.get('customerControl').patchValue(customer);
            this.vendorsCredit.get('totalAmount').patchValue(option.total);
            this.isFieldReadOnly = true;
            this.vendorsCredit.setControl('saleProduct', this.setProduct(option.products));
            this.vendorsCredit.updateValueAndValidity();

            // Skip confirmation if the user has acknowledged it before
            if (!this.confirmationAcknowledged) {
                this.openConfirmationDialog().subscribe(result => {
                    if (result) {
                        this.confirmationAcknowledged = true;
                        this._changeDetectorRef.detectChanges();
                    }
                });
            }
        }
    }

    calculateTotalApplied() {
        const { discount, discountSuffix, adjustment, adjustmentSuffix, shipping_charges, total: initialTotal } = this.salesOrderApplied;
        const productArray = this.vendorsCredit.get('saleProduct') as FormArray;

        let subTotalAll = 0;
        let subTotalSelected = 0;
        let isAnyProductSelected = false;

        // Calculate the subtotal for all products and for selected products
        productArray.controls.forEach((control: FormGroup) => {
            const sale_quantity = control.get('sale_quantity')?.value || 0;
            const sale_rate = control.get('sale_rate')?.value || 0;
            const is_selected = control.get('is_selected') ? control.get('is_selected').value : false;

            const amount = sale_quantity * sale_rate;
            control.get('sale_amount')?.setValue(amount, { emitEvent: false });
            control.updateValueAndValidity({ emitEvent: false });

            subTotalAll += amount || 0;

            if (is_selected) {
                subTotalSelected += amount || 0;
                isAnyProductSelected = true;
            }
        });

        let calculatedDiscount = discount;
        let calculatedAdjustment = adjustment;
        const shipping = shipping_charges || 0;

        let updatedTotal;

        // Apply percentage discount and adjustment if applicable
        if (isAnyProductSelected) {
            if (discountSuffix === '%') {
                calculatedDiscount = (subTotalSelected * discount) / 100;
            }

            if (adjustmentSuffix === '%') {
                calculatedAdjustment = (subTotalSelected * adjustment) / 100;
            }

            if (adjustmentSuffix === '%' && discountSuffix === '%') {
                calculatedDiscount = (subTotalSelected * discount) / 100;
                const newTotal = subTotalSelected - calculatedDiscount;
                calculatedAdjustment = (newTotal * adjustment) / 100;
            }

            updatedTotal = subTotalSelected - calculatedDiscount + calculatedAdjustment + shipping;
        } else {
            // Revert to initial total if no products are selected
            updatedTotal = subTotalAll > 0 ? subTotalAll : initialTotal;
        }

        // Set the updated total amount
        this.vendorsCredit.get('totalAmount')?.setValue(updatedTotal, { emitEvent: false });
        this.cd.detectChanges();
    }



    subscribeToChanges() {
        const productArray = this.vendorsCredit.get('saleProduct') as FormArray;

        productArray.controls.forEach((control: FormGroup) => {
            control.get('sale_quantity').valueChanges.subscribe(() => {

                this.calculateTotalApplied();
            });

            control.get('sale_rate').valueChanges.subscribe(() => {
                this.calculateTotalApplied();
            });

            control.get('is_selected').valueChanges.subscribe(() => {
                this.calculateTotalApplied();
            });

            this._changeDetectorRef.detectChanges()
        });
    }


    onCheckboxChange(event: any): void {
        if (event.target.checked) {
        } else {


        }

    }


    makeFieldEditable(salesNumberInput: HTMLInputElement) {
        this.isFieldReadOnly = false;
        this.selectedSalesOrder = null; // Assuming `selectedCustomer` is the variable holding the current customer value
        if (salesNumberInput) {
            salesNumberInput.value = ''; // Clear the input value
        }

        // Clear the value of the form control
        this.SaleOrderNumberControl.setValue('');


    }

    makeFieldEditableExchanges(salesNumberInput: HTMLInputElement) {
        this.isFieldReadOnlyExchange = false;
        this.selectedExchangeOrder = null; // Assuming `selectedCustomer` is the variable holding the current customer value
        if (salesNumberInput) {
            salesNumberInput.value = ''; // Clear the input value
        }

        // Clear the value of the form control
        this.ExchangeNumberControl.setValue('');


    }

  

    private loadAllProducts(): void {
        this._productService.getproducts().pipe(
            take(1),
            switchMap((products) => {
                if (!Array.isArray(products['data'])) {
                    return of([]);
                }

                const productObservables = products['data'].map((product) => {
                    return this._productService.getProductVariations(product.id).pipe(
                        map((variations) => ({
                            product,
                            variations: variations.data,
                        }))
                    );
                });

                return forkJoin(productObservables);
            }),
            map((productsWithVariations) => {
                this.allProducts = productsWithVariations.map(({ product, variations }) => {
                    const productId = product.id;

                    // Determine if all variations are disabled
                    const allDisabled = variations.every((variation) => {
                        // Check if the variation should be considered disabled
                        const isNotActive = !variation.active;
                        const isPreOrder = variation.preOrder;
                        const isStock = variation.stock === 0;
                        const isSelected = this.matchedVariationIds.has(variation.id);
                        return isNotActive || isPreOrder || isStock || isSelected;
                    });

                    // Mark the product as disabled if all variations are disabled
                    const disabled = allDisabled || variations.length === 0;

                    return {
                        product: productId,
                        id: productId,
                        productName: product.productName,
                        sku: product.sku,
                        basePrice: product.basePrice,
                        size: product.size,
                        color: product.color,
                        description: product.description,
                        disabled: disabled,
                    };
                });

                // Initially set the filtered options to all products
                this.filteredProductOptions$ = of(this.allProducts);
            })
        ).subscribe();
    }



    filterOptionsProduct(value: string): void {
        this.searchTerms.next(value);
    }

    private filterLocalProducts(value: string): Observable<any[]> {
        if (typeof value !== 'string' || !value.trim()) {
            return of(this.allProducts); // Return all products if the value is empty
        }

        const filteredProducts = this.allProducts.filter((filteredProduct) =>
            filteredProduct.productName.toLowerCase().includes(value.toLowerCase()) ||
            (filteredProduct.sku && filteredProduct.sku.toString().toLowerCase().includes(value.toLowerCase()))
        );

        return of(filteredProducts);
    }

    filtersOptionsProduct(productId: string, index: number): void {
        this._productService.getProductVariations(productId).subscribe((variations) => {
            this.mappedVariations = variations.data
                .map((variation) => ({
                    id: variation.id,
                    size: variation.size,
                    color: variation.color,
                    price: variation.basePrice,
                    base_product: productId,
                    stock: variation.stock,
                    preOrder: variation.preOrder,
                    active: variation.active,
                    description: variation.base_product.description,
                    category: variation.base_product.category,
                    gender: variation.base_product.gender,
                }));

            const sizeToColorMap = {};
            const allColorsDisabledMap = {};

            this.mappedVariations.forEach((variation) => {
                const { size, color, id, active, preOrder, stock } = variation;

                if (!sizeToColorMap[size]) {
                    sizeToColorMap[size] = [];
                }

                const isNotActive = !active;
                const isPreOrder = preOrder;
                const isStock = stock == 0

                const isSelected = this.matchedVariationIds.has(id);
                const isOutOfStock = isNotActive || isSelected || (isPreOrder === true) || isStock;
                const style = isOutOfStock ? { color: 'red' } : {};
                const disabled = isOutOfStock;

                sizeToColorMap[size].push({
                    id,
                    color,
                    style,
                    disabled,
                });

                allColorsDisabledMap[color] = allColorsDisabledMap[color] || {};
                allColorsDisabledMap[color][size] = disabled;
            });

            const sizes = Object.keys(sizeToColorMap).map((size) => {
                const colors = sizeToColorMap[size] || [];

                // Check if all colors for this size are disabled
                const disabled = colors.every((color) => allColorsDisabledMap[color.color]?.[size]);

                // console.log('Disabled:', disabled);
                return {
                    id: size,
                    name: size,
                    disabled,
                };
            });

            const firstSize = sizes[0]?.id;
            const colors =
                sizeToColorMap[firstSize]?.map((color) => ({
                    id: color.color,
                    name: color.color,
                    style: color.style,
                    disabled: allColorsDisabledMap[color.color]?.[firstSize],
                })) || [];

            this.updateSizesAndColors(index, sizes, colors);
        });
    }


    // Add this method to handle size and color updates
    updateSizesAndColors(index: number, sizes: any[], colors: any[]): void {
        this.sizes[index] = sizes;
        this.colors[index] = colors;
    }

    quantityExceedsStock(index: number): boolean {
        const productArray = this.vendorsCredit.get('products') as FormArray;
        const existingFormGroup = productArray.at(index) as FormGroup;
        const quantityControl = existingFormGroup.get('quantity');

        if (quantityControl.invalid) {
            return false;
        }

        const quantityValue = Number(quantityControl.value);
        const currentStockValue = Number(this.current_stock[index]);

        const exceedsStock = quantityValue > currentStockValue;

        if (exceedsStock) {
            // Mark the quantity control as invalid
            quantityControl.setErrors({ 'exceedsStock': true });
        } else {
            // Clear any existing errors
            quantityControl.setErrors(null);
        }

        return exceedsStock;
    }



    onSelectionChange(index: number): void {
        const productArray = this.vendorsCredit.get('products') as FormArray;
        const existingFormGroup = productArray.at(index) as FormGroup;

        if (existingFormGroup) {
            const selectedProductId = existingFormGroup.get('product').value.id;
            const selectedSizeId = existingFormGroup.get('size').value;
            this.colors[index]?.forEach((color) => {
                color.disabled = false;
            });

            this.mappedVariations.forEach((variation) => {
                const { size, color, id, active, stock, preOrder } = variation;

                if (size === selectedSizeId) {
                    const colorToDisable = this.colors[index]?.find(c => c.name === color);

                    if (colorToDisable) {
                        colorToDisable.disabled = !active || stock == 0 || (preOrder === true) || this.matchedVariationIds.has(id);
                    }
                }
            });

            const selectedColorId = existingFormGroup.get('color').value;

            const selectedSizeColorCombo = `${selectedSizeId}${selectedColorId}`;
            const matchingOriginalVariation = this.mappedVariations?.find(variation => {
                const variationSizeColorCombo = `${variation.size}${variation.color}`;
                return variationSizeColorCombo === selectedSizeColorCombo;
            });

            if (matchingOriginalVariation) {
                if (matchingOriginalVariation.stock != 0) {
                    if (!this.matchedVariationIds.has(matchingOriginalVariation.id)) {
                        existingFormGroup.get('rate').patchValue(matchingOriginalVariation.price);
                        existingFormGroup.get('size').patchValue(matchingOriginalVariation.size);
                        existingFormGroup.get('color').patchValue(matchingOriginalVariation.color);
                        existingFormGroup.get('id').patchValue(matchingOriginalVariation.id);
                        this.active[index] = true;
                        this.current_stock[index] = matchingOriginalVariation.stock;

                        this.product_details[index] = matchingOriginalVariation.description;
                        this.animationFlags[index] = true;

                        this.matchedVariationIds.add(matchingOriginalVariation.id);

                        this.disableColorsForSelectedSize(index, selectedSizeId);
                        this.addProductField();


                        setTimeout(() => {
                            this.animationFlags[index] = false;
                        }, 1000);

                        // console.log("Matching variation found for the selected size and color:", matchingOriginalVariation);
                    } else {
                    }
                } else {
                }
            } else {
                // console.log("No matching original variation found for the selected size and color.");
            }
        }
    }

    disableColorsForSelectedSize(index: number, selectedSizeId: string): void {
        this.colors[index].forEach((color) => {
            color.disabled = color.id !== selectedSizeId;
        });

        // Update the UI
        this.updateSizesAndColors(index, this.sizes[index], this.colors[index]);
    }



    

    selectOptionProduct(option: products, index: number): void {




        if (option && !option.isOutOfStock) {
            const productFormGroup = this._formBuilder.group({
                id: [''],
                product: [option, [Validators.required]],
                quantity: [''],
                product_bundle: [''],
                bundle_id: [''],
                rate: [option.basePrice, ''],
                amount: [''],
                size: [],
                color: [],
            });
            this.productActive[index] = true;

            this.filtersOptionsProduct(option.id, index)


            const productArray = this.vendorsCredit.get('products') as FormArray;

        }
    }

    paymentDue = async (creditNote, id) => {
        try {
            if (!creditNote) {
                throw new Error('Invalid credit note');
            }

            const today = new Date();
            const formattedDate = today.toISOString().split('T')[0]; // Format: YYYY-MM-DD

            const customerData = {
                Customers: creditNote.sales_order.Customers.id,
                salesExchange: id,
                voucher_description: `This is a credit note for ${creditNote.exchange_number}`,
                voucher_type: "Exchange Voucher",
                total: creditNote.remainingAmount,
                isVoucher: true,
                isCredited: true,
                voucher_order_date: formattedDate,

            };

            const resp = await this._creditNoteService.createSalesOrders(customerData);

            if (resp && resp.success) {
                return { success: true };
            } else {
                throw new Error('Creedit Note processing failed');
            }
        } catch (error) {
            console.error('Error in paymentDue:', error);
            throw error; // Re-throw the error to be caught by the calling code
        }
    };




    selectOptionProductBundle(option: productBundle, index: number): void {


        const selectedProducts = option.products.map(product => {
            return {
                product: product,

            };
        });

        const dialogRef = this.dialog.open(ProductBundlePopupComponent, {
            width: '3000px',
            height: '750px',
            data: {
                selectedProducts: selectedProducts
            }
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result && result.total && result.otherData && result.otherData.products && Array.isArray(result.otherData.products)) {


                // Extract sizes, colors, product names, and quantities from the array of products
                const sizes = result.otherData.products.map((product) => product.size);
                const colors = result.otherData.products.map((product) => product.color);
                const name = result.otherData.products.map((product) => product.product);
                const product_name = name.map((product) => product.productName)
                this.name[index] = product_name;
                const quantities = result.otherData.products.map((product) => product.quantity);

                this.quantity[index] = quantities;


                // Create a reference to the form group
                const productFormGroup = this._formBuilder.group({
                    id: [''],
                    bundle_id: [option.id],
                    product: [option, [Validators.required]],
                    product_bundle: [option],
                    quantity: [''], // Directly bind to quantities
                    rate: [result.total, ''],
                    amount: [''],
                    size: [sizes.join(', ')], // Update with the combined size values
                    color: [colors.join(', ')], // Update with the combined color values
                });
                const productArray = this.vendorsCredit.get(
                    'products'
                ) as FormArray;
                const existingFormGroup = productArray.at(index) as FormGroup;

                if (existingFormGroup) {
                    existingFormGroup.patchValue(productFormGroup.value);

                }
                this.active[index] = true;
                this.animationFlags[index] = true;

                this.matchedProductBundleIds.add(option.id);

                this._changeDetectorRef.detectChanges();
            } else {
                console.error("Invalid data structure or missing required properties");
            }
        });





    }


    displayProductOption(product: products): string {
        if (product) {

            const productName = product.productName || '';
            const productPrice = product.sku
                ? ' - ' + product.sku.toString()
                : '';

            return productName + productPrice;

        }

        return '';
    }


    displayProductBundleOption(product: any): string {
        if (product) {

            const productName = product.name || '';
            const productPrice = product.description
                ? ' - ' + product.description.toString()
                : '';

            return productName + productPrice;

        }

        return '';
    }




    openProductDetails(): void {
        const dialogRef = this.dialog.open(SalesOrderProductPopupComponent, {
            width: '500px',
        });

        dialogRef.afterClosed().subscribe((result) => {
        });
    }



    addProductField(): void {
        const productFormGroup = this._formBuilder.group({
            id: [''],
            bundle_product: [''],
            bundle_id: [''],

            product: ['', [Validators.required]],
            quantity: ['', Validators.required],
            rate: ['', Validators.required],
            amount: [''],
            color: [''],
            size: [''],
        });

        const productArray = this.vendorsCredit.get('products') as FormArray;
        productArray.push(productFormGroup);

        const index = productArray.length - 1;
        this.setupProductFieldSubscriptions(productFormGroup, index);

        this.calculateTotal();
    }



    removeProductField(index: number): void {
        const productArray = this.vendorsCredit.get('products') as FormArray;
        const existingFormGroup = productArray.at(index) as FormGroup;

        const removedId = existingFormGroup.get('id').value;
        this.matchedVariationIds.delete(removedId);

        this.active.splice(index, 1);
        this.animationFlags.splice(index, 1);
        this.match_stock.splice(index, 1);
        this.current_stock.splice(index, 1);
        this.product_details.splice(index, 1);
        this.productActive.splice(index, 1)

        this.sizes.splice(index, 1);
        this.colors.splice(index, 1);

        productArray.removeAt(index);

        this.calculateTotal();
        this.cd.markForCheck();
    }



    setupProductFieldSubscriptions(
        productGroup: FormGroup,
        index: number
    ): void {
        const quantityControl = productGroup.get('quantity');
        const rateControl = productGroup.get('rate');

        quantityControl.valueChanges.subscribe(() => {
            this.calculateTotal();
        });

        rateControl.valueChanges.subscribe(() => {
            this.calculateTotal();
        });


    }

    calculateTotal(): void {
        const productArray = this.vendorsCredit.get('products') as FormArray;

        let subTotal = 0;
        productArray.controls.forEach((control: FormGroup) => {
            const quantity = control.get('quantity').value || 0;
            const rate = control.get('rate').value || 0;

            const amount = quantity * rate;
            control.get('amount').setValue(amount);

            subTotal += amount;
        });

        this.vendorsCredit.get('sub_total').setValue(subTotal);

        const discountSuffix = this.vendorsCredit.get('discountSuffix').value;
        const adjustmentSuffix = this.vendorsCredit.get('adjustmentSuffix').value;
        const discount = parseFloat(this.vendorsCredit.get('discount').value) || 0;
        const adjustment = parseFloat(this.vendorsCredit.get('adjustment').value) || 0;

        let calculatedDiscount = discount;
        let calculatedAdjustment = adjustment;

        // Calculate discount
        if (discountSuffix === '%') {
            calculatedDiscount = (subTotal * discount) / 100;
        }

        // Calculate adjustment
        if (adjustmentSuffix === '%') {
            calculatedAdjustment = (subTotal * adjustment) / 100;
        }

        // Calculate total before shipping
        const totalBeforeShipping = subTotal - calculatedDiscount + calculatedAdjustment;
        this.vendorsCredit.get('total').setValue(totalBeforeShipping);

        const totalAmount = parseFloat(this.vendorsCredit.get('totalAmount').value) || 0;
        const remainingTotalAmount = totalAmount - totalBeforeShipping;
        this.vendorsCredit.get('remainingAmount').setValue(remainingTotalAmount);
    }

    customer_detail(id: any) {

    }
    private _filterCountries(value: string) {
        const filterValue = value.toLowerCase();
        return contrydata.filter((country) =>
            country.toLowerCase().includes(filterValue)
        );
    }

    private _filterInsideDistricts(value: string) {
        const filterValue = value.toLowerCase();
        return insideValleyData.filter((district) =>
            district.toLowerCase().includes(filterValue)
        );
    }

    pointOfsales(): void {
        const type = this.vendorsCredit.controls['pos'].value;

        if (type == 'Instagram' || type == 'Website' || type == 'Facebook') {
            this.showSocailLink = false;
            this.showEmail = true;
            this.showPaymentMethod = true;
            this.showShippingArea = true;
        } else if (type == 'Store Vist') {
            this.showSocailLink = true;
            this.showPaymentMethod = true;
            this.showEmail = false;
            this.showExpectedShippingDate = false;
            this.showDeliveryType = false;
            this.showShippingArea = false;

            this.paymentOptions = [
                'CASH',
                'ASHIM ESEWA',
                'DANISH ESEWA',
                'HUBA NIC ASIA',
                'ASHIM SIDDHARTA BANK'
            ];

            // Set validators and update validity for 'shipping_area' and 'delivery_type'
            this.vendorsCredit.get('shipping_area').setValidators(this.showShippingArea ? Validators.required : null);
            this.vendorsCredit.get('shipping_area').updateValueAndValidity();

            this.vendorsCredit.get('delivery_type').setValidators(this.showDeliveryType ? Validators.required : null);
            this.vendorsCredit.get('delivery_type').updateValueAndValidity();



        } else {
            this.showEmail = true;
            this.showSocailLink = true;
            this.showPaymentMethod = false;
            this.showShippingArea = false;
        }
    }

    addressField(): void {
        const type = this.vendorsCredit.controls['shipping_area'].value;
        const shipping = this.vendorsCredit.get('shipping_charges').value || 0;



        if (type == 'Inside Valley') {
            this.showAddress = true;
            this.showShippinginvalley = true;
            this.showDistrict = true;
            this.showShippingOutvalley = false;
            this.showCountry = false;
            this.shwoShippingInternational = false;
            this.showDeliveryDetails = false;
            const currentShippingCharges = this.vendorsCredit.get('shipping_charges').value || 0;
            this.vendorsCredit.get('shipping_charges').patchValue(currentShippingCharges + 100);

        } else if (type == 'Outside Valley') {
            const currentShippingCharges = this.vendorsCredit.get('shipping_charges').value || 0;
            this.vendorsCredit.get('shipping_charges').patchValue(currentShippingCharges + 100);
            this.vendorsCredit.get('shipping_charges').setValidators([Validators.required]);
            this.shipping_required = true;

            this.showAddress = true;
            this.showShippinginvalley = false;
            this.showDistrict = true;
            this.showShippingOutvalley = true;
            this.showCountry = false;
            this.shwoShippingInternational = false;
            this.showDeliveryDetails = false;
            this.cd.detectChanges();

        } else if (type == 'International') {

            this.vendorsCredit.get('shipping_charges').patchValue(0);
            this.vendorsCredit.get('shipping_charges').patchValue(0);
            this.shipping_required = true;

            this.showAddress = true;
            this.showShippinginvalley = false;
            this.showDistrict = false;
            this.showShippingOutvalley = false;
            this.showCountry = true;
            this.shwoShippingInternational = true;
            this.showDeliveryDetails = true;
            this.cd.detectChanges();

        } else {
            this.vendorsCredit.get('shipping_charges').clearValidators();

        }
        this.vendorsCredit.get('shipping_charges').updateValueAndValidity();

    }

    showOtherss(): void {
        const type = this.vendorsCredit.controls['shipping_carrier_int'].value;
        if (type == 'others') {
            this.showOthers = true;
        } else {
            this.showOthers = false;
        }
    }



    formatDate(date: Date, format: string, controlName: string) {
        const formattedDate = this.datePipe.transform(date, format);
        this.vendorsCredit.get(controlName)?.setValue(formattedDate);
    }
    loadServices() {
        this.filteredSalesOrder$ = this.SaleOrderNumberControl.valueChanges.pipe(
            startWith(''),
            debounceTime(300),
            switchMap((searchTerm) => this.salesOrder$.pipe(
                map(salesOrder => {
                    if (Array.isArray(salesOrder['data'])) {
                        const searchTermString: string = String(searchTerm);
                        return salesOrder['data'].filter(order =>
                            order.sales_order_num.toLowerCase().includes(searchTermString.toLowerCase())
                            && (order.status === 'Sales Order Delivered')
                        );
                    } else {
                        console.error('SalesOrder data is not available:', salesOrder);
                        return [];
                    }
                })
            ))
        );

        this.filteredExchangeOrders$ = this.ExchangeNumberControl.valueChanges.pipe(
            startWith(''),
            debounceTime(300),
            switchMap((searchTerm) => this.exchanges$.pipe(
                map(salesOrder => {
                    if (Array.isArray(salesOrder['data'])) {
                        const searchTermString: string = String(searchTerm);
                        return salesOrder['data'].filter(order =>
                            order.exchange_number.toLowerCase().includes(searchTermString.toLowerCase())
                            && (order.status === 'Delivered Exchange Order')
                        );
                    } else {
                        console.error('Exchanges Deilvered are not shown data is not available:', salesOrder);
                        return [];
                    }
                })
            ))
        );

        const products$ = this._productService.getproducts().pipe(
            take(1),
            map((response) => response['data'])
        );

        products$.subscribe((data) => {
        });


        this._userService.user$
            .pipe((takeUntil(this._unsubscribeAll)))
            .subscribe((user: User) => {
                this.user = user;
            });

    }





    hasUnsavedChanges(): boolean {
        return this.vendorsCredit.dirty;
    }

    canDeactivate(): boolean {
        return !this.vendorsCredit.dirty || confirm('You have unsaved changes. Are you sure you want to leave?');
    }

    async makeFieldEditableProduct(index: number) {

        const confirmationResult = await this.openConfirmationDialog().toPromise();
        if (confirmationResult) {

            this.productActive[index] = false;
            const productInput = document.getElementById(`product-input-${index}`) as HTMLInputElement;
            if (productInput) {
                productInput.removeAttribute('readonly'); // Make the input field editable
                productInput.value = ''; // Clear the value of the input field
            }

            const productArray = this.vendorsCredit.get('products') as FormArray;
            const existingFormGroup = productArray.at(index) as FormGroup;

            // Remove the ID from the matchedVariationIds set
            const removedId = existingFormGroup.get('id').value;
            this.matchedVariationIds.delete(removedId);

            // Clear values associated with the deleted product
            this.active.splice(index, 1);
            this.animationFlags.splice(index, 1);
            this.match_stock.splice(index, 1);
            this.current_stock.splice(index, 1);
            this.product_details.splice(index, 1);

            // Clear sizes and colors associated with the deleted product
            this.sizes[index] = [];
            this.colors[index] = [];

            // Remove the productActive flag associated with the deleted product
            this.productActive.splice(index, 1);

            // Remove the form group from the form array
            productArray.removeAt(index);

            this.calculateTotal();
            this.cd.markForCheck();
            this.addProductField();
        } else {
            ""
        }
    }
    toggleInputs(): void {
        this.vendorsCredit.get('shipping_carrier_outvalley').setValue('Dash logistics');
    }
    toggleInputsExchange(): void {
        this.vendorsCredit.get('exchange_type').setValue('SIZE EXCHANGE');
    }



}