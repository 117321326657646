import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MaterialModule } from 'app/modules/material/material.module';
import { VendorsCredit } from './vendors-credit.module/vendors-credit.module.component';
import { ListvendorsCreditComponent } from './listvendors-credit/listvendors-credit.component';
import { CreatevendorsCreditComponent } from './createvendors-credit/createvendors-credit.component';
import { DetailvendorsCreditComponent } from './detailvendors-credit/detailvendors-credit.component';
import { RouterModule } from '@angular/router';
import { VendorsCreditRouting } from './vendors-credit.notes.routing';
import { NgxMaskModule } from 'ngx-mask';

import { VendorpopupComponent } from './vendorpopup/vendorpopup.component';
import { MAT_DATE_FORMATS } from '@angular/material/core';

@NgModule({
    declarations: [
        VendorsCredit,
        ListvendorsCreditComponent,
        CreatevendorsCreditComponent,
        DetailvendorsCreditComponent,
        VendorpopupComponent,
    ],
    imports: [
        NgxMaskModule.forRoot(),
        RouterModule.forChild(VendorsCreditRouting),
        CommonModule,
        MaterialModule,
  
    ], providers   : [
        {
            provide : MAT_DATE_FORMATS,
            useValue: {
                parse  : {
                    dateInput: "YYYY-MM-DD"
                },
                display: {
                    dateInput         : 'YYYY-MM-DD',
                    monthYearLabel    : 'MMM YYYY',
                    dateA11yLabel     : 'LL',
                    monthYearA11yLabel: 'MMMM YYYY'
                    
                }
            }
        },
        DatePipe
    ]
})
export class VendorsCreditModule {}
