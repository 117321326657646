import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { productCategories } from '../product-categories.type';
import { Observable, Subject } from 'rxjs';
import { saveAs } from 'file-saver';
import { take } from 'rxjs/operators';

import { productCategoryService } from '../product-categories.service';
import Swal from 'sweetalert2';
import * as ExcelJS from 'exceljs';
export interface ProductData {

  id?: any;
  category_name?: string,
  category_description?: string;
  products?: any;
  category_sku?: any;
  total?: any;
  category_stock?: any;
  isExpanded?: boolean;
  dropdownHidden?: boolean;


}

@Component({
  selector: 'app-listproduct-categories',
  templateUrl: './listproduct-categories.component.html',
  styleUrls: ['./listproduct-categories.component.scss']

})
export class ListproductCategoriesComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = ['id', 'Category Name', 'Category Description', 'actions'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  salesOrders: productCategories[];
  dataSource: MatTableDataSource<ProductData>;

  salesorder$: Observable<productCategories[]>;
  flashMessage: 'success' | 'error' | null = null;
  isLoading: boolean = true;
  private delayTimeout: any;

  constructor(

    private _router: Router,
    private _productCategoryService: productCategoryService
  ) {
  }

  ngOnInit() {

    this.loadCategory();
  }

  ngAfterViewInit() {
    setTimeout(() => {

    }, 400);
  }

  isCollapsed(row: ProductData): boolean {
    return !row.isExpanded;
  }

  toggleCollapse(row: ProductData): void {
    row.isExpanded = !row.isExpanded;
  }
  onMouseEnter(row: ProductData) {
    clearTimeout(this.delayTimeout);
    row.dropdownHidden = false;
  }

  onMouseLeave(row: ProductData) {
    this.delayTimeout = setTimeout(() => {
      row.dropdownHidden = true;
    }, 10);
  }
  deleteVendorsCredit(id: any) {
    // Prompt the user for confirmation before deleting the employee
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this._productCategoryService.deleteproducts(id)
          .subscribe((isDeleted) => {
            // Return if the contact wasn't deleted...
            if (!isDeleted) {
              Swal.fire("Error!", "Cannot Delete");
              return;
            }

            // Display a success message
            Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            ).then(() => {
              this.loadCategory();
            });
          });
      }
    });
  }

  updateVendorsCredit() {

  }

  showDetails(id: any) {
    this._router.navigate(['apps/product-categories', id]);
  }


  exportexcel(): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to download the file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, download it!'
    }).then((result) => {
      if (result.isConfirmed) {
        const element = document.getElementById('excel-table');
        const data = [];

        // Iterate over table rows and collect data
        const rows = element.querySelectorAll('tr');

        rows.forEach((row) => {
          const rowData = [];
          const cells = row.querySelectorAll('td');
          cells.forEach((cell) => {
            rowData.push(cell.textContent || ''); // Use textContent to get the cell's text
          });
          data.push(rowData);
        });

        // Create a new workbook and add a worksheet
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet1');

        // Define your custom styles for headers and data
        const headerStyle = { font: { bold: true }, alignment: { horizontal: 'center' as const } };
        const dataStyle = { alignment: { horizontal: 'left' as const } };

        // Add headers with custom style
        const headers = this.displayedColumns;
        worksheet.addRow(headers);
        worksheet.getRow(1).eachCell((cell) => {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFFF00' }, // Yellow background color
          };
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
        });

        // Add data with custom style
        data.forEach((rowData) => {
          worksheet.addRow(rowData);
          worksheet.lastRow.eachCell((cell) => {
            cell.alignment = dataStyle.alignment;
          });
        });

        // Calculate the total and add it to the end of the worksheet
        const total = data.reduce((sum, row) => sum + (parseFloat(row[1]) || 0), 0); // Assuming the total is in the second column (index 1)
        worksheet.addRow(['Total', total]);
        worksheet.lastRow.eachCell((cell) => {
          cell.font = headerStyle.font;
          cell.alignment = headerStyle.alignment;
        });

        // Create a blob and initiate file download
        workbook.xlsx.writeBuffer().then((buffer) => {
          const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          saveAs(blob, 'ProductCat.xlsx'); // Replace 'your-filename' with the desired file name
        });
      }
    });

  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  createProductCategory() {
    this._router.navigate(['apps/product-categories', 'NEW']);

  }
  getStatusColor(status: string): string {
    if (status === 'pending') {
      return 'yellow';
    } else if (status === 'completed') {
      return 'green';
    } else if (status === 'failed') {
      return 'red';
    }
    return '';
  }


  loadCategory() {
    this.salesorder$ = this._productCategoryService.getproducts().pipe(take(1));
    this.salesorder$.subscribe((salesorder) => {
      this.salesOrders = salesorder['data'];
      this.dataSource = new MatTableDataSource<ProductData>(this.salesOrders.map(salesOrder => {
     

        return {
          category_name: salesOrder.category_name,
          category_description: salesOrder.category_description,
          isExpanded: false,
          id: salesOrder.id,
          dropdownHidden: true,

        };
      }));
    });

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.isLoading = false;
  }

}


/** Builds and returns a new User. */






