export const server_env = {
    URL: 'https://hubasystem.jcho.com/',
    URL_MEDIA: 'https://hubasystem.jcho.com',

    WS: 'wss://hubasystem.jcho.com/ws/', 

    // URL: 'http://localhost:8000/',
    // URL_MEDIA: 'http://localhost:8000',

    // WS: 'ws://localhost:8000/ws/',  

  
};

export const server_env_server = {
    URL: 'https://hubasystem.jcho.com/',
    URL_MEDIA: 'https://hubasystem.jcho.com',

    WS: 'wss://hubasystem.jcho.com/ws/',  // Use wss for secure WebSocket

  
};

const urls = {
    localhost: 'localhost:80',
    server_url: "https://hubasystem.jcho.com/",
}
    



export const server_envLocal = {
    URL: 'http://localhost:8000/',
    URL_MEDIA: 'http://localhost:8000',

    WS: 'ws://localhost:8000/ws/',  // Use wss for secure WebSocket

   
};

