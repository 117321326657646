import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { PrPackages } from './pr-packages.types';
import axios, { AxiosResponse } from 'axios';
import { server_env } from 'config';

@Injectable({
    providedIn: 'root',
})
export class PrPackagesService {
    // Private
    private _salesorder: BehaviorSubject<PrPackages | null> =
        new BehaviorSubject(null);
    private _salesorderss: BehaviorSubject<PrPackages[] | null> =
        new BehaviorSubject(null);
        private _salesorder_numbers: BehaviorSubject<any[] | null> =
        new BehaviorSubject(null);
        private _salesorderFiltered: BehaviorSubject<PrPackages | null> =
        new BehaviorSubject(null);

    public url = `${server_env.URL}api/v1/prPackagesOrder/`;
    public url_2 = `${server_env.URL}api/v1/prPackages_n/`;
    public filteredUrl = `${server_env.URL}api/v1/prPackagesOrders/`;


    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors

    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for contact
     */
    get salesOrder$(): Observable<PrPackages> {
        return this._salesorder.asObservable();
    }

    /**
     * Getter for contacts
     */
    get salesOrders$(): Observable<PrPackages[]> {
        return this._salesorderss.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get contacts
     */

    getSalesOrder(): Observable<PrPackages[]> {
        return this._httpClient.get<PrPackages[]>(`${this.url}`).pipe(
            tap((salesorder: any) => {
                console.log();
                this._salesorderss.next(salesorder.data);

                for (salesorder in salesorder.data) {
                    console.log(salesorder, 'sdas');
                }
            })
        );
    }
    getSalesOrder_number(): Observable<any[]> {
        return this._httpClient.get<any>(`${this.url_2}`).pipe(
            tap((number: any) => {
                this._salesorder_numbers.next(number.data);
    
            })
        );
    }
    getSalesOrders(): Observable<PrPackages[]> {
        return this._httpClient.get<PrPackages[]>(`${this.filteredUrl}`).pipe(
            tap((salesorder: any) => {
                this._salesorderFiltered.next(salesorder);

                for (salesorder in salesorder) {
                   
                }
            })
        );
    }
    /**
     * Get contact by id
     */
    getSalesOrderById(id: string): Observable<PrPackages> {
        const requestUrl = this.url + id;

        return this._httpClient.get<PrPackages[]>(`${requestUrl}`).pipe(
            take(1),
            map((salesorder: any) => {
                this._salesorder.next(salesorder.data);

                // Return the contact
                return salesorder.data;
            }),
            switchMap((salesorder) => {
                if (!salesorder) {
                    return throwError(
                        'Could not found contact with id of ' + id + '!'
                    );
                }

                return of(salesorder);
            })
        );
    }

    /**
     * Create contact
     */
    // createSalesOrder(): Observable<SalesOrder>
    // {
    //     return this.salesOrder$.pipe(
    //         take(1),
    //         switchMap(salesOrder => this._httpClient.post<SalesOrder>(`${this.url}`, {}).pipe(
    //             map((newSalesOrder) => {

    //                 // Update the contacts with the new contact
    //                 this._salesorderss.next([newSalesOrder, ...salesOrder]);

    //                 // Return the new contact
    //                 return newSalesOrder;
    //             })
    //         ))
    //     );
    // }

    /**
     * create customer
     * @param data
     * @returns
     */

    createPrPackages(data): Promise<any> {
        return new Promise((resolve, reject) => {
            const requestUrl = this.url;

            this._httpClient.post<any>(`${requestUrl}`, data).subscribe(
                (response) => {
                    return resolve(response);
                },
                (error) => {
                    return reject(error);
                }
            );
        });
    }

    PartialupdateSalesOrder(id, contact): Promise<any> {
        return new Promise((resolve, reject) => {
            const requestUrl = this.url;

            this._httpClient.patch<any>(`${requestUrl}/${id}/`, contact).subscribe(
                (response) => {
                    return resolve(response);
                },
                (error) => {
                    return reject(error);
                }
            );
        });
    }
    
    /**
     * Update contact
     *
     * @param id
     * @param contact
     */
    updateSalesOrder(id: string, contact: PrPackages): Observable<PrPackages> {
        return this.salesOrders$.pipe(
            take(1),
            switchMap((salesordder) =>
                this._httpClient
                    .put<PrPackages>(`${this.url}${id}/`, {
                        id,
                        contact,
                    })
                    .pipe(
                        map((updatedSalesOrder) => {
                            // Find the index of the updated contact
                            const index = salesordder.findIndex(
                                (item) => item.id === id
                            );

                            // Update the contact
                            salesordder[index] = updatedSalesOrder;

                            // Update the contacts
                            this._salesorderss.next(salesordder);

                            // Return the updated contact
                            return updatedSalesOrder;
                        }),
                        switchMap((updatedSalesOrders) =>
                            this.salesOrder$.pipe(
                                take(1),
                                filter((item) => item && item.id === id),
                                tap(() => {
                                    // Update the contact if it's selected
                                    this._salesorder.next(updatedSalesOrders);

                                    // Return the updated contact
                                    return updatedSalesOrders;
                                })
                            )
                        )
                    )
            )
        );
    }

    /**
     * Delete the contact
     *
     * @param id
     */
    deleteSalesOrder(id: string): Observable<boolean> {
        return this.salesOrders$.pipe(
            take(1),
            switchMap((salesOrder) =>
                this._httpClient.delete(`${this.url}${id}/`).pipe(
                    map((isDeleted: boolean) => {
                        // Find the index of the deleted contact
                        const index = salesOrder.findIndex(
                            (item) => item.id === id
                        );

                        // Delete the contact
                        salesOrder.splice(index, 1);

                        // Update the contacts
                        this._salesorderss.next(salesOrder);

                        // Return the deleted status
                        return isDeleted;
                    })
                )
            )
        );
    }

    async fetchData(): Promise<string[]> {
        try {
            const response: AxiosResponse = await axios.get(
                'https://nepallocation.com.np/api/v1/district/list',
                {
                    headers: {
                        Authorization: `Bearer AFKw8mI-vtgRq-M2i1qDvIGS`,
                        'Content-Type': 'application/json',
                    },
                }
            );

            const districtNames = response.data.data.data.map(
                (district) => district.name
            );
            console.log(districtNames, 'districtNames');
            return districtNames;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
}
