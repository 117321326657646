import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { InventoryEntry } from './inventory-entry-type';
import { server_env } from 'config';

@Injectable({
    providedIn: 'root',
})
export class InventoryEntryService {
    // Private
    private _InventoryEntry: BehaviorSubject<InventoryEntry | null> =
        new BehaviorSubject(null);
    private _InventoryEntryss: BehaviorSubject<InventoryEntry[] | null> =
        new BehaviorSubject(null);

    public url = `${server_env.URL}api/v1/initial_stock/`;

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors

    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for contact
     */
    get InventoryEntry$(): Observable<InventoryEntry> {
        return this._InventoryEntry.asObservable();
    }

    /**
     * Getter for contacts
     */
    get InventoryEntrys$(): Observable<InventoryEntry[]> {
        return this._InventoryEntryss.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get contacts
     */

    getInventoryEntry(): Observable<InventoryEntry[]> {
        return this._httpClient.get<InventoryEntry[]>(`${this.url}`).pipe(
            tap((InventoryEntry: any) => {
                console.log();
                this._InventoryEntryss.next(InventoryEntry.data);

                for (InventoryEntry in InventoryEntry.data) {
                    console.log(InventoryEntry, 'sdas');
                }
            })
        );
    }

    /**
     * Get contact by id
     */
    getInventoryEntryById(id: string): Observable<InventoryEntry> {
        const requestUrl = this.url + id;

        return this._httpClient.get<InventoryEntry[]>(`${requestUrl}`).pipe(
            take(1),
            map((InventoryEntry: any) => {
                this._InventoryEntry.next(InventoryEntry.data);

                // Return the contact
                return InventoryEntry.data;
            }),
            switchMap((InventoryEntry) => {
                if (!InventoryEntry) {
                    return throwError(
                        'Could not found contact with id of ' + id + '!'
                    );
                }

                return of(InventoryEntry);
            })
        );
    }

   
    /**
     * create customer
     * @param data
     * @returns
     */

    createInventoryEntrys(data): Promise<any> {
        return new Promise((resolve, reject) => {
            const requestUrl = this.url;

            this._httpClient.post<any>(`${requestUrl}`, data).subscribe(
                (response) => {
                    return resolve(response);
                },
                (error) => {
                    return reject(error);
                }
            );
        });
    }
    /**
     * Update contact
     *
     * @param id
     * @param contact
     */
    updateInventoryEntry(id: string, contact: InventoryEntry): Observable<InventoryEntry> {
        return this.InventoryEntrys$.pipe(
            take(1),
            switchMap((salesordder) =>
                this._httpClient
                    .put<InventoryEntry>(`${this.url}${id}/`, {
                        id,
                        contact,
                    })
                    .pipe(
                        map((updatedInventoryEntry) => {
                            // Find the index of the updated contact
                            const index = salesordder.findIndex(
                                (item) => item.id === id
                            );

                            // Update the contact
                            salesordder[index] = updatedInventoryEntry;

                            // Update the contacts
                            this._InventoryEntryss.next(salesordder);

                            // Return the updated contact
                            return updatedInventoryEntry;
                        }),
                        switchMap((updatedInventoryEntrys) =>
                            this.InventoryEntry$.pipe(
                                take(1),
                                filter((item) => item && item.id === id),
                                tap(() => {
                                    // Update the contact if it's selected
                                    this._InventoryEntry.next(updatedInventoryEntrys);

                                    // Return the updated contact
                                    return updatedInventoryEntrys;
                                })
                            )
                        )
                    )
            )
        );
    }

    /**
     * Delete the contact
     *
     * @param id
     */
    deleteInventoryEntry(id: string): Observable<boolean> {
        return this.InventoryEntrys$.pipe(
            take(1),
            switchMap((InventoryEntry) =>
                this._httpClient.delete(`${this.url}${id}/`).pipe(
                    map((isDeleted: boolean) => {
                        // Find the index of the deleted contact
                        const index = InventoryEntry.findIndex(
                            (item) => item.id === id
                        );

                        // Delete the contact
                        InventoryEntry.splice(index, 1);

                        // Update the contacts
                        this._InventoryEntryss.next(InventoryEntry);

                        // Return the deleted status
                        return isDeleted;
                    })
                )
            )
        );
    }

}
