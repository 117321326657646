import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SalesOrderService } from '../../sales-order/sales-order.service';
import { PackagePopupComponent } from 'app/packages/package-popup/package-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { SalesReturnService } from '../sales-return.service';

@Component({
    selector: 'app-detail-sales-return',
    templateUrl: './detail-sales-return.component.html',
    styleUrls: ['./detail-sales-return.component.scss'],
})
export class DetailSalesReturnComponent implements OnInit {
    productId: string;
    vendor: any; // Replace 'any' with the actual type of your product
    dropdownHidden: boolean = true; //drop down for create the pack
    salesCredit: any;
    isReceived: any;

    constructor(
        private route: ActivatedRoute,
        private productService: SalesReturnService, // Replace with your actual product service
        private dialog: MatDialog
    ) { }

    ngOnInit(): void {
        this.route.paramMap.subscribe((params) => {
            this.productId = params.get('id');
            this.fetchProductDetails(this.productId);
        });
    }
    async fetchProductDetails(productId: string) {
        // Use your product service to fetch the product details based on the provided ID
        this.productService.getSalesOrderById(productId).subscribe(
            (data) => {
                
                this.vendor = data;
                this.isReceived = data.isReceived



            },
            (error) => {
                console.error('Error fetching product details:', error);
            }
        );

    
       this.productService.getSalesReturnCreditNote(productId).subscribe(
            (data) => {
                data = data.data;

                data.map((data)=> {

                    this.salesCredit = data;
                });


            }
        )



        // console.log(this.vendor);
    }
    printPage() {
        // Select the buttons you want to hide
        const buttonsToHide = document.querySelectorAll('button');

        // Hide the buttons before printing
        buttonsToHide.forEach((button) => {
            button.style.display = 'none';
        });

        // Perform the printing
        window.print();

        // Show the buttons again after printing (delayed to ensure they show after the print dialog)
        setTimeout(() => {
            buttonsToHide.forEach((button) => {
                button.style.display = 'block'; // or 'inline' depending on the button's original display property
            });
        }, 1000); // Adjust the delay as needed
    }



    getThumbnailUrl(thumbnail: string): string {
        const baseUrl = 'http://103.147.227.9:2/8081'; // Your base URL here
        return baseUrl + thumbnail;
    }
    toggleDropdown() {
        this.dropdownHidden = !this.dropdownHidden;
    }

    // on package click this open the package dialog box
    openPackagepopup(saleOderId: any) {
        const dialogRef = this.dialog.open(PackagePopupComponent, {
            width: '800px',
            data: { saleOderId }, // Adjust the width as per your requirements
            // Pass the selected vendor as data to the modal
        });

        dialogRef.afterClosed().subscribe((result) => {
            // Handle any actions after the modal is closed
        });
    }

    ngOnDestroy(): void { }

    goBack=()=>{
        window.history.back();
    }
}

