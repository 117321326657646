
<mat-stepper [linear]="isLinear" #stepper>
  <mat-step >
    <ng-template matStepLabel>Shipping Address</ng-template>
    <form [formGroup]="formGroup">
      <mat-form-field>
        <mat-label>Shipping Area</mat-label>
        <mat-select formControlName="shippingArea">
          <mat-option value="In-Valley">In-Valley</mat-option>
          <mat-option value="Out-Valley">Out-Valley</mat-option>
          <mat-option value="International">International</mat-option>
  
        </mat-select>
      </mat-form-field>

      <div>
        <button mat-button matStepperPrevious>Back</button>
        <button mat-button matStepperNext (click)="submit()">Next</button>
      </div>

    </form>

    <!-- <div *ngIf="showDependentField">
      <form [formGroup]="secondFormGroup">
        <mat-form-field>
          <mat-label>Dependent Field</mat-label>
          <input matInput formControlName="secondCtrl">
        </mat-form-field>
      </form>
    </div> -->

  </mat-step>
  <mat-step label="Billing Address">
    <form [formGroup]="formGroup" >
      <mat-form-field>
        <mat-label> Billing Address</mat-label>
        <input matInput formControlName="billingAddress"
               required>
      </mat-form-field>
      <div>
        <button mat-button matStepperPrevious>Back</button>
        <!-- <button mat-button matStepperNext (click)="submit()">Done</button> -->
      </div>
    </form>
  </mat-step>
  <!-- <mat-step>
    <ng-template matStepLabel>Done</ng-template>
    <p>You are now done.</p>
    <div>
      <button mat-button matStepperPrevious>Back</button>
      <button mat-button (click)="submit()">Submit</button>

    </div>
  </mat-step> -->
</mat-stepper>
