<ng-container *ngIf="vendor" class="mt-10 ml-15 md:ml-50 lg:ml-90">

    <ng-container *ngIf="!editMode">

        <div class="absolute inset-0 min-w-0 text-center overflow-auto print:overflow-visible" cdkScrollable>




            <div class="w-full inline-block p-6 sm:p-10 text-left print:p-0">
                <div
                    class="w-full  p-16 rounded-2xl shadow bg-card print:w-auto print:p-0 print:rounded-none print:shadow-none print:bg-transparent">

                    <div class="flex items-center">
                        <div class="grid grid-rows-2 place-items-center ">

                            <div class="grid-cols-2  place-items-center">


                                <button mat-button type="button" matTooltip="Close" class="hide-on-print"
                                    [routerLink]="['..']">
                                    <span class="inline-flex items-center">
                                        <mat-icon svgIcon="feather:x"></mat-icon>
                                    </span>
                                </button>

                                <button mat-button type="button" class="hide-on-print" (click)="printPage()">
                                    <span class="inline-flex items-center">
                                        <mat-icon svgIcon="feather:file"></mat-icon>
                                    </span>
                                </button>

                                <button mat-button type="button" class="hide-on-print" (click)="toggleEditMode(true)">
                                    <span class="inline-flex items-center">
                                        <mat-icon svgIcon="feather:edit"></mat-icon>
                                    </span>
                                </button>


                            </div>
                        </div>
                    </div>


                    <div class="flex items-start">

                        <div class="grid grid-rows-2 place-items-start ">
                            <div class="grid auto-cols-max grid-flow-col gap-x-5">
                             
                                <div class="pl-4 border-l text-md">
                                    <div class="font-medium">Name: {{ vendor.category_name }}
                                    </div>
                                    <div>Description :{{vendor.category_description}}</div>
                                   
                               
                                    <div>Created at : {{vendor.created}}</div>
                                    <div>Updated at : {{vendor.updated}}</div>
                                </div>
                            </div>

                        </div>






                    </div>




                    <div class="grid grid-cols-12 gap-x-1 mt-16">
                        <!-- Columns -->
                        <!-- Columns -->
                        <div class="col-span-6 font-medium text-md text-secondary">PRODUCT</div>
                        <div class="col-span-2 font-medium text-md text-right text-secondary">SKU</div>
                        <div class="col-span-2 font-medium text-md text-right text-secondary">SIZE</div>
                        <div class="col-span-2 font-medium text-md text-right text-secondary">COLOR</div>

                        <!-- Divider -->
                        <div class="col-span-12 my-4 border-b"></div>

                        <!-- Products Loop -->
                        <ng-container *ngFor="let product of vendor.products" class="col-span-12 flex items-center">
                            <!-- Product Avatar (Image) -->
                            <div class="col-span-6">
                                <div class="col-span-2">
                                    <!-- <img [src]="getThumbnailUrl(product.product.thumbnail)"
                                    alt="{{ product.product.productName }}" class="w-12 h-12 rounded-full"> -->
                                </div>
                                <div class="col-span-10">
                                    <div class="text-lg font-medium">{{product.product.base_product.productName}}
                                       </div>
                                  
                                </div>
                            </div>

                            <div class="col-span-2 self-center text-right">{{ product.product.sku }}</div>
                            <div class="col-span-2 self-center text-right">{{ product.product.size }}</div>
                            <div class="col-span-2 self-center text-right">{{ product.product.color }}</div>
                            <!-- Adjusted col-span to 2 for TOTAL -->

                            <!-- Divider -->
                            <div class="col-span-12 my-4 border-b"></div>
                        </ng-container>

                        <!-- Spacer -->
                
                        <!-- Subtotal -->
                       
                        <!-- Divider -->
                                              <!-- Total -->
                     
                    </div>



                    <div class="mt-16">

                        <div class="flex items-start mt-4">
                            <img class="flex-0 w-20 mt-2" src="assets/images/logo/logo.png">
                            <div class="ml-6 text-sm text-secondary">

                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </div>
    </ng-container>



</ng-container>
<ng-container *ngIf="editMode" class="w-full h-full">


<form [formGroup]="vendorsCredit" class="bg-gray-100 w-full rounded shadow-lg h-full">
    <!-- Avatar -->
    <!-- Avatar code goes here -->

    <!-- Personal Information Form -->
    <div *ngIf="vendorsCredit" class="p-8">
        <div class="prose prose-sm max-w-3xl">
            <!-- HTML template -->
        </div>
        <div class="flex justify-end">
            <button mat-raised-button [routerLink]="['..']">
                <span class="inline-flex items-center">
                    <mat-icon svgIcon="feather:x"></mat-icon>
                </span>
            </button>
        </div>

        <div class="flex flex-col mt-8">
            <p class="text-lg font-medium">Basic information</p>
            <p class="text-secondary">Set your product category</p>
            <mat-divider class="mt-8 mb-10"></mat-divider>
            <div class="flex mb-4">
                <mat-form-field style="max-width: 600px" class="flex-auto">
                    <mat-label>Product Category Name</mat-label>
                    <input matInput type="text" placeholder="Add Product Category Name"
                        formControlName="category_name" aria-label="Customers" />

                    <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:user'"></mat-icon>
                </mat-form-field>
            </div>



            <div class="flex mb-2">
                <mat-form-field style="max-width: 600px" class="flex-auto">
                    <mat-label>Description</mat-label>
                    <textarea matInput placeholder="Description" formControlName="category_description"
                        class="elevated-input"></textarea>
                    <mat-icon class="icon-size-5" matPrefix svgIcon="feather:file-text"></mat-icon>
                </mat-form-field>
            </div>


            <mat-divider class="mt-6 mb-4"></mat-divider>
            <ng-container s formArrayName="products">
                <ng-container *ngFor="
                    let product of vendorsCredit.get('products')
                        .controls;
                    let i = index;
                    let first = first;
                    let last = last;
                    trackBy: trackByFn
                ">
                    <div class="flex" style="padding: auto" [formGroupName]="i">
                        <mat-form-field class="fuse-mat-no-subscript" style="min-width: 600px">
                        
                            <mat-label *ngIf="first">Product Category</mat-label>
                            <input style="height: 50px" matInput formControlName="product"
                                placeholder="Type SKU or Product Name" required [matAutocomplete]="auto"
                                (input)="
                                filterOptionsProduct(
                                    $event.target.value
                                )
                            " (focus)="
                                filterOptionsProduct(
                                    $event.target.value
                                )
                            " />

                            <mat-autocomplete #auto="matAutocomplete" [displayWith]="
                                displayProductOption.bind(this)
                            " (optionSelected)="
                                selectOptionProduct(
                                    $event.option.value,
                                    i
                                )
                            ">
                                <mat-option style="
                                    height: auto;
                                    min-height: 32px;
                                    display: flex;
                                    align-items: center;
                                    padding: 4px 16px;
                                " *ngFor="
                                    let option of filteredProductOptions$
                                        | async;
                                    let i = index
                                " [value]="option">
                                    <div style="
                                        display: flex;
                                        align-items: center;
                                    ">
                                        
                                        <span class="example-option-name">{{
                                            option.productName
                                            }}</span>
                                        <span class="example-option-sku">
                                          - {{option.color}} - {{ option.size }}</span>
                                    </div>
                                </mat-option>

                                <mat-option style="
                                    cursor: pointer;
                                    height: fit-content;
                                " (click)="openProductDetails()" *ngIf="
                                    (filteredProductOptions$ | async)
                                        ?.length === 0
                                " [disabled]="true">
                                    No options found
                                    <br />
                                    <mat-icon svgIcon="feather:plus">
                                    </mat-icon>
                                    Add Product
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>

                        <mat-form-field class="fuse-mat-no-subscript" style="min-width: 200px">
                            <mat-label *ngIf="first">size</mat-label>

                            <input style="height: 50px" matInput type="text" formControlName="size"
                                placeholder="Size" />
                        </mat-form-field>
                        <mat-form-field style="max-width: 300px" class="flex-auto">
                            <mat-label *ngIf="first">Color</mat-label>
                            <input style="height: 50px" placeholder="Color" matInput formControlName="color"
                             [value]="product.controls['color'].value" />
                         
                        </mat-form-field>

                        <!-- Remove product -->
                        <ng-container *ngIf="!(first && last)">
                            <div class="flex items-center w-10 pl-2" [ngClass]="{ 'mt-6': first }">
                                <button mat-icon-button (click)="removeProductField(i)" matTooltip="Remove">
                                    <mat-icon class="icon-size-5"
                                        [svgIcon]="'heroicons_solid:trash'"></mat-icon>
                                </button>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
            </ng-container>

            <div class="inline-flex items-center mt-2 -ml-4 py-2 px-4">
                <mat-icon matPrefix class="hidden sm:flex icon-size-5"
                    [svgIcon]="'heroicons_solid:tag'"></mat-icon>
                <span (click)="addProductField()"
                    class="ml-2 font-medium text-secondary cursor-pointer hover:underline">Add a product</span>
            </div>

            <div class="flex items-center justify-end mt-10">
                <button (click)="updateProductCategories()"
                    class="px-6 py-2 rounded text-white bg-blue-500 hover:bg-blue-600" mat-flat-button
                    [color]="'primary'">
                    Save
                </button>
            </div>
        </div>
    </div>
</form>

    
</ng-container>