<div class="w-full h-full w-full">
    <form [formGroup]="vendorsCredit"  class="bg-gray-100 rounded shadow-lg h-full">

        
        <div *ngIf="vendorsCredit" class="p-8">
            <div class="prose prose-sm max-w-3xl">
                <!-- HTML template -->
            </div>
            <div class="flex justify-end">
                <button mat-raised-button [routerLink]="['..']">
                    <span class="inline-flex items-center">

                        <mat-icon svgIcon="feather:x"></mat-icon>


                    </span>
                </button>
            </div>


            <div class="flex flex-col mt-8">
                <p class="text-lg font-medium">Basic information</p>
                <p class="text-secondary">Set your Color Details</p>

                <mat-divider class="mt-8 mb-10"></mat-divider>

                <div class="flex mb-4">
                    <mat-form-field class="flex-auto">
                        <mat-label>Name</mat-label>
                        <input matInput placeholder="Name" required name="lastName" [formControlName]="'name'"
                            class="elevated-input" />
                        <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:mail'"></mat-icon>
                    </mat-form-field>
                </div>


                <div class="flex mb-4">
                    <mat-form-field class="flex-auto">
                        <mat-label>Family</mat-label>
                        <input matInput placeholder="Family" required name="lastName" [formControlName]="'family'"
                            class="elevated-input" />
                        <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:mail'"></mat-icon>
                    </mat-form-field>
                </div>

                <div class="flex mb-4">
                    <mat-form-field class="flex-auto">
                        <mat-label>Hex Color Code</mat-label>
                        <input matInput placeholder="Color Code" required name="email" [formControlName]="'color_code'"
                            class="elevated-input" />
                        <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:mail'"></mat-icon>
                    </mat-form-field>
                </div>


                <!-- Additional Fields -->
                <div class="flex mb-4">
                    <mat-form-field class="flex-auto">
                        <mat-label>Color Scheme</mat-label>
                        <input matInput placeholder="Color Scheme" name="dob" [formControlName]="'color_scheme'"
                            class="elevated-input" type="text" />
                        <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:calendar'"></mat-icon>
                    </mat-form-field>
                </div>

                <!-- <div class="flex mb-4">
            <mat-form-field class="flex-auto">
              <mat-label>Influencer phone</mat-label>
              <input matInput placeholder="(000) 000-000-0000" name="phone" [formControlName]="'phoneNumbers'"
                class="elevated-input" mask="(000) 000-000-0000" required maskDirective />
              <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:phone'"></mat-icon>
            </mat-form-field>
          </div> -->


                <div class="flex mb-4">
                    <mat-form-field class="flex-auto">
                        <mat-label>Dimensions</mat-label>
                        <mat-select placeholder="Dimenison" name="gender" [formControlName]="'dimensions'"
                            class="elevated-input" required>
                            <mat-option value="2D">2D</mat-option>
                            <mat-option value="3D">3D</mat-option>


                        </mat-select>
                        <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:translate'"></mat-icon>
                    </mat-form-field>

                </div>






                <div class="flex mb-4">
                    <mat-form-field class="flex-auto">
                        <mat-label>Releted</mat-label>
                        <input matInput placeholder="Releted" required name="sub_total" [formControlName]="'releted'"
                            class="elevated-input" />
                        <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:mail'"></mat-icon>
                    </mat-form-field>
                </div>




                <div class="flex items-center justify-end mt-10">
                    <button (click)='sumbitcolorInfo()'class="px-6 py-2 rounded text-white bg-blue-500 hover:bg-blue-600" mat-flat-button
                        [color]="'primary'">
                        Save
                    </button>
                </div>

            </div>
        </div>
        
    </form>
</div>