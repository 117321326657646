import { Route } from '@angular/router';
import { ListPromoCodeComponent } from './list-promo-code/list-promo-code.component';
import { CreatePromoCodeComponent } from './create-promo-code/create-promo-code.component';
import { PromoCodeDetailComponent } from './promo-code-detail/promo-code-detail.component';



export const PromoCodeRouting: Route[] = [
   
        {
            path     : '',
            component: ListPromoCodeComponent,
        },
        {
            path         : 'NEW',
            component    : CreatePromoCodeComponent,
           
        },
        {
            path         : ':id',
            component    :PromoCodeDetailComponent,
          
          
        },
]
