import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-detailvendors-credit',
  templateUrl: './detailvendors-credit.component.html',
  styleUrls: ['./detailvendors-credit.component.scss']
})
export class DetailvendorsCreditComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
