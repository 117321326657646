import { ChangeDetectorRef, Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-partial-update',
  templateUrl: './partial-update.component.html',
  styleUrls: ['./partial-update.component.scss']
})
export class PartialUpdateComponent implements OnInit {
  @Output() formSubmitted: EventEmitter<any> = new EventEmitter<any>();
  paritalUpdateForm: FormGroup;
  formCancel: boolean = true;
  formFieldHelpers: string[] = [''];
  showAddressFields: boolean = false;
  showShippinginvalley: boolean = false;
  showShippingOutvalley: boolean = false;
  shwoShippingInternational: boolean = false;
  showOthers: boolean = false;



  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _matDialog: MatDialogRef<PartialUpdateComponent>,
    private _formBuilder: FormBuilder,
    private cd: ChangeDetectorRef

  ) { }

  ngOnInit(): void {

    this.paritalUpdateForm = this._formBuilder.group({
      shipping_area: [''],
      shipping_carrier_invalley: [''],
      shipping_carrier_invalley_others: [''],
      shipping_carrier_outvalley: [''],
      shipping_carrier_int: [''],
      others: [''],
      others_out: ['']



    });
    const shipping_area_type = this.data.shippingAreaTodisplay;

    this.paritalUpdateForm.get('shipping_area').setValue(shipping_area_type);

    // Call the addressField function after setting the value
    this.addressField();






  }


  cancel(): void {
    this.formCancel = false;
    this._matDialog.close(false); // Close the dialog with false result
  }

  getFormFieldHelpersAsString(): string {
    return this.formFieldHelpers.join(' ');
  }
  submitForm(formData: any): void {
    const shipping_area = this.paritalUpdateForm.get('shipping_area').value;

    // Validate the form based on shipping area and carrier selection
    if (shipping_area === 'Inside Valley' && this.paritalUpdateForm.get('shipping_carrier_invalley').invalid) {
        // Inform the user about the required field
        Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Please select a shipping carrier.',
        });
    } else if (shipping_area === 'Outside Valley' && this.paritalUpdateForm.get('shipping_carrier_outvalley').invalid) {
        // Inform the user about the required field
        Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Please select a shipping carrier.',
        });
    } else if (shipping_area === 'International' && this.paritalUpdateForm.get('shipping_carrier_int').invalid) {
        // Inform the user about the required field
        Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Please select a shipping carrier.',
        });
    } else {
        // Handle the 'Others' case
        if (shipping_area === 'Inside Valley' && this.paritalUpdateForm.get('shipping_carrier_invalley').value === 'others') {
            formData['shipping_carrier_invalley'] = this.paritalUpdateForm.get('shipping_carrier_invalley_others').value;
        } else if (shipping_area === 'Outside Valley' && this.paritalUpdateForm.get('shipping_carrier_outvalley').value === 'others') {
            formData['shipping_carrier_outvalley'] = this.paritalUpdateForm.get('shipping_carrier_outvalley_others').value;
        } else if (shipping_area === 'International' && this.paritalUpdateForm.get('shipping_carrier_int').value === 'others') {
            formData['shipping_carrier_int'] = this.paritalUpdateForm.get('shipping_carrier_int_others').value;
        }

        // Emit the form data
        this.formSubmitted.emit(formData);

        // Close the modal
        this._matDialog.close();
    }
}


  addressField(): void {
    const type = this.paritalUpdateForm.controls['shipping_area'].value;

    if (type === 'Inside Valley') {
        this.showShippinginvalley = true;
        this.showShippingOutvalley = false;
        this.shwoShippingInternational = false;

        // Set 'shipping_carrier_invalley' as required
        this.paritalUpdateForm.get('shipping_carrier_invalley').setValidators(Validators.required);
        this.paritalUpdateForm.get('shipping_carrier_invalley').updateValueAndValidity();

    } else if (type === 'Outside Valley') {
        this.showShippinginvalley = false;
        this.showShippingOutvalley = true;
        this.shwoShippingInternational = false;

        // Set 'shipping_carrier_outvalley' as required
        this.paritalUpdateForm.get('shipping_carrier_outvalley').setValidators(Validators.required);
        this.paritalUpdateForm.get('shipping_carrier_outvalley').updateValueAndValidity();

    } else if (type === 'International') {
        this.showShippinginvalley = false;
        this.showShippingOutvalley = false;
        this.shwoShippingInternational = true;

        // Set 'shipping_carrier_int' as required
        this.paritalUpdateForm.get('shipping_carrier_int').setValidators(Validators.required);
        this.paritalUpdateForm.get('shipping_carrier_int').updateValueAndValidity();

    } else {
        // Clear validation for other cases
        this.paritalUpdateForm.get('shipping_carrier_invalley').clearValidators();
        this.paritalUpdateForm.get('shipping_carrier_outvalley').clearValidators();
        this.paritalUpdateForm.get('shipping_carrier_int').clearValidators();
    }

    // Update validity after changing validation rules
    this.paritalUpdateForm.get('shipping_carrier_invalley').updateValueAndValidity();
    this.paritalUpdateForm.get('shipping_carrier_outvalley').updateValueAndValidity();
    this.paritalUpdateForm.get('shipping_carrier_int').updateValueAndValidity();
}

  /**
    * Track by function for ngFor loops
    *
    * @param index
    * @param item
    */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }


  toggleOtherInput(): void {
    this.paritalUpdateForm.get('shippingCarrier').setValue('OTHERS');
  }

  toggleInternationalInput(): void {
    this.paritalUpdateForm.get('shipping_carrier_int').setValue('ARAMEX');
  }

  toggleInputs(): void {
    this.paritalUpdateForm.get('shipping_carrier_outvalley').setValue('Dash logistics');
  }


  toggleInputss(): void {
    this.paritalUpdateForm.get('shipping_carrier_invalley').setValue('Dash logistics');
  }


  showOtherss(): void {
    const type = this.paritalUpdateForm.controls['shipping_carrier_int'].value;
    if (type == 'others') {
      this.showOthers = true;
    } else {
      this.showOthers = false;
    }
  }
}
