<!-- intial_stock_entry_form form -->
<mat-dialog-content class="dialog-content">

<div class="w-full h-full frame"  (scroll)="scrolled($event)" #scrollframe>
    <form [formGroup]="intial_stock_entry_form" class="rounded ">

        <div *ngIf="intial_stock_entry_form" class="p-8">


            <div class="flex flex-col ">
                <p class="text-lg font-medium">Set your Initial Stock Details</p>
                <p class="text-secondary mt-3">Please note that this is only used for the initial stock. After the
                    initial stock is depleted, you should use the restock entry option.</p>
                <p class="text-secondary">Products will no longer be visible in the Initial Stock once the stock has
                    been inputted.</p>
                <mat-divider class="mt-5 mb-3"></mat-divider>








                <mat-form-field style="max-width: 600px" class="flex-auto  fuse-mat-emphasized-affix fuse-mat-rounded">
                    <mat-label> Date</mat-label>
                    <input [min]="todayDate" matInput [matDatepicker]="picker2" placeholder="Sales order Date" name="Date"
                        [formControlName]="'date'" readonly class="elevated-input small-input-text" required />

                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>
                <mat-divider class="mt-3 mb-3"></mat-divider>


                <ng-container s class="mb-3" formArrayName="products">
                    <ng-container *ngFor="
                            let product of intial_stock_entry_form.get('products')
                                .controls;
                            let i = index;
                            let first = first;
                            let last = last;
                            trackBy: trackByFn
                        "#item>
                        <div class="flex mb-3" [ngClass]="{'fadeInOut': animationFlags[i]}" [formGroupName]="i">

                            <mat-form-field
                                class="fuse-mat-no-subscript w-200  fuse-mat-rounded mr-4 fuse-mat-emphasized-affix">

                                <mat-label *ngIf="first">Product</mat-label>
                                <input [readonly]="active[i]" class="small-input-text" style="height: 50px" matInput
                                    formControlName="product" placeholder="Type SKU or Product Name" required
                                    [matAutocomplete]="auto" [ngStyle]="product.style"
                                    [attr.disabled]="product.disabled ? true : null" (input)="
                                    filterOptionsProduct($event.target.value)
                                " (focus)="filterOptionsProduct($event.target.value)" />

                                <mat-icon *ngIf="active[i]" class="icon-size-5" matSuffix [svgIcon]="'verified'"
                                    style="font-size: 24px; color: green;"></mat-icon>


                                <mat-autocomplete #auto="matAutocomplete" [displayWith]="
                                        displayProductOption.bind(this)
                                    " (optionSelected)="
                                        selectOptionProduct(
                                            $event.option.value,
                                            i
                                        )
                                    ">
                                    
                                    <mat-option
                                        style="height: auto; display: flex; align-items: center; padding: 4px 16px;font-size: small;"
                                        *ngFor="let option of filteredProductOptions$ | async; let i = index"
                                        [value]="option" [ngStyle]="option.style" [disabled]="option.disabled">


                                        <div style="
                                                display: flex;
                                                align-items: center;
                                            ">

                                            <span class="example-option-name">{{
                                                option.productName
                                                }}</span>
                                            <span class="example-option-sku">
                                                - {{ option.sku }}</span>
                                        </div>
                                    </mat-option>

                                    <mat-option style="
                                            cursor: pointer;
                                            height: fit-content;
                                        " (click)="openProductDetails()" *ngIf="
                                            (filteredProductOptions$ | async)
                                                ?.length === 0
                                        " [disabled]="true">
                                        No options found
                                        <br />
                                        <mat-icon svgIcon="feather:plus">
                                        </mat-icon>
                                        Add Product
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>


                            <ng-container *ngIf="!active[i]">
                                <!-- Render the mat-select when active is false -->
                                <mat-form-field
                                    class="fuse-mat-no-subscript w-75  fuse-mat-rounded fuse-mat-emphasized-affix mr-4">
                                    <mat-label *ngIf="first">Size</mat-label>
                                    <mat-icon class="icon-size-4" matPrefix svgIcon="format_size"></mat-icon>

                                    <mat-select matInput formControlName="size" placeholder="Size" class="h-20"
                                        (selectionChange)="onSelectionChange(i, true)">
                                        <mat-option [ngStyle]="size.style" [disabled]="size.disabled"
                                            style="height: auto; display: flex; align-items: center; padding: 4px 16px;font-size: small;"
                                            *ngFor="let size of sizes[i]" [value]="size.id">{{ size.name }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field
                                    class="fuse-mat-no-subscript w-75  fuse-mat-rounded fuse-mat-emphasized-affix mr-4">
                                    <mat-label *ngIf="first">Color</mat-label>
                                    <mat-icon class="icon-size-4" matPrefix svgIcon="color_lens"></mat-icon>

                                    <mat-select matInput formControlName="color" placeholder="Color" class="h-20"
                                        (selectionChange)="onSelectionChange(i, true)">
                                        <mat-option *ngFor="let color of colors[i]" [disabled]="color.disabled"
                                            style="height: auto; display: flex; align-items: center; padding: 4px 16px;font-size: small;"
                                            [value]="color.id">{{ color.name }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                            </ng-container>

                            <ng-container *ngIf="active[i]">
                                <!-- Render a readonly input or display the selected value when active is true -->
                                <mat-form-field
                                    class="fuse-mat-no-subscript w-75  fuse-mat-rounded fuse-mat-emphasized-affix mr-4">
                                    <mat-icon class="icon-size-4" matPrefix svgIcon="format_size"></mat-icon>

                                    <mat-label *ngIf="first">Size</mat-label>
                                    <input class="small-input-text" matInput formControlName="size" placeholder="Size"
                                        style="height: 50px" class="small-input-text" readonly />
                                </mat-form-field>

                                <mat-form-field
                                    class="fuse-mat-no-subscript w-75  fuse-mat-rounded fuse-mat-emphasized-affix mr-4">
                                    <mat-label *ngIf="first">Color</mat-label>
                                    <mat-icon class="icon-size-4" matPrefix svgIcon="color_lens"></mat-icon>

                                    <input class="small-input-text" matInput formControlName="color" placeholder="Color"
                                        style="height: 50px" readonly />
                                </mat-form-field>


                            </ng-container>

                            <div class="form-field-container">

                            <mat-form-field
                                class="fuse-mat-no-subscript w-75  fuse-mat-rounded fuse-mat-emphasized-affix mr-4">
                                <mat-label *ngIf="first">Stock</mat-label>
                                <mat-icon class="icon-size-4" matPrefix svgIcon="mat_solid:production_quantity_limits">
                                </mat-icon>

                                <input required class="small-input-text" type="text"
                                    onkeypress="return event.charCode>=48 && event.charCode<=57" matInput
                                    formControlName="stock" placeholder="Inital Stock" style="height: 50px" />
                            </mat-form-field>
                             <!-- Display current_stock below the form field -->
                             <div *ngIf="active[i]" class="current-stock-container m-2">
                                <span [ngClass]="{
                                        'chip chip--success': current_stock[i] > 10,
                                        'chip chip--warning': current_stock[i] <= 10 && current_stock[i] > 5,
                                        'chip chip--error': current_stock[i] <= 5
                                    }" style="line-height: 16px;">

                                    <i style="font-size: 11px; margin-top: -2px;" class="chip__label">
                                        <strong>Available stock: {{ current_stock[i] }} </strong>
                                    </i>
                                </span>
                            </div>
                            
                        </div>





                            <!-- Remove product -->
                            <ng-container *ngIf="!(first && last)">
                                <div class="flex items-center w-10 pl-2" [ngClass]="{ 'mt-6': first }">
                                    <button mat-icon-button (click)="removeProductField(i)" matTooltip="Remove">
                                        <mat-icon class="icon-size-4" [svgIcon]="'heroicons_solid:trash'"></mat-icon>
                                    </button>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                </ng-container>


            </div>




            <mat-divider class="mt-3 mb-3"></mat-divider>




            <div class="flex items-center mt-10">
                <button (click)="sumbitInitalStock()" class="px-6 py-2 rounded text-white bg-blue-500 hover:bg-blue-600"
                    mat-flat-button [color]="'primary'">
                    Save
                </button>
            </div>





        </div>


    </form>
</div>
</mat-dialog-content>