<!-- Confirm delete template -->
<mat-dialog-content class="dialog-content">
    <form [formGroup]="packageGroup" class="bg-gray-100 rounded shadow-lg h-full"
        (keydown.enter)="$event.preventDefault()">
        <!-- Avatar -->
        <!-- Avatar code goes here -->

        <!-- Personal Information Form -->
        <div>
            <div class="prose prose-sm max-w-3xl"></div>
            <!-- HTML template -->

            <div class="flex flex-col mt-8 p-8 pb-5 bg-card rounded shadow overflow-hidden">
                <p class="text-lg font-medium">Product Shipped</p>
                <p class="text-secondary">New Product Shipped</p>
                <mat-divider class="mt-8 mb-10"></mat-divider>

                <div class="flex flex-col gt-xs:flex-row">
                    <mat-form-field class="flex-auto gt-xs:pl-3">
                        <mat-label>Package Slip#</mat-label>
                        <input matInput [placeholder]="'packageSlip'"  name="packageOrder"
                            [formControlName]="'packageOrder'" class="elevated-input" [value]="" readonly />
                        <mat-icon class="icon-size-5" matPrefix [svgIcon]="'feather:hash'"></mat-icon>
                    </mat-form-field>
                </div>
                <div class="flex flex-col gt-xs:flex-row">
                    <mat-form-field class="flex-auto gt-xs:pl-3">
                        <mat-label>Shipped Order#</mat-label>
                        <input matInput [placeholder]="'Shipped Order'"  name="shipOrder"
                            [formControlName]="'shipOrder'" class="elevated-input" [value]=""  readonly/>
                        <mat-icon class="icon-size-5" matPrefix [svgIcon]="'feather:hash'"></mat-icon>
                    </mat-form-field>
                </div>

                <div class="flex flex-col gt-xs:flex-row">
                    <mat-form-field class="flex-auto gt-xs:pl-3">
                        <mat-label>Choose a date</mat-label>
                        <input matInput [matDatepicker]="picker" formControlName="packageDate" name="packageDate" required/>
                        <mat-hint>MM/DD/YYYY</mat-hint>
                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div>

                <div class="flex flex-col gt-xs:flex-row">
                    <ng-container class="flex-auto gt-xs:pl-3" [formArrayName]="'products'">
                        <ng-container *ngFor="
                                let product of packageGroup.get('products')['controls'];
                                let i = index;
                                let first = first;
                                let last = last;
                                trackBy: trackByFn
                            ">
                            <div class="flex flex-wrap" [formGroupName]="i">
                                <mat-form-field class="w-90">
                                    <mat-label *ngIf="first">Product</mat-label>
                                    <input matInput formControlName="productName"
                                        placeholder="Type SKU or Product Name"  readonly/>
                                </mat-form-field>

                                <mat-form-field style="width: 149px">
                                    <mat-label *ngIf="first">Quantity</mat-label>
                                    <mat-icon matPrefix svgIcon="mat_solid:production_quantity_limits"></mat-icon>
                                    <input matInput type="text" onkeypress="return event.charCode>=48 && event.charCode<=57" formControlName="quantity" placeholder="Quantity" readonly/>
                                </mat-form-field>

                                <mat-form-field style="width: 149px">
                                    <mat-label *ngIf="first">Amount</mat-label>
                                    <input matInput type="text" onkeypress="return event.charCode>=48 && event.charCode<=57" formControlName="amount" placeholder="Amount" readonly/>
                                    <span matPrefix>रु</span>
                                    <span matSuffix>.00</span>
                                </mat-form-field>

                                <!-- Remove product -->
                                <!-- <span
                                    *ngIf="!(first && last)"
                                    class="w-4 pl-2 -mt-2"
                                    [ngClass]="{ 'mt-6': first }"
                                >
                                    <button
                                        mat-icon-button
                                        (click)="removeProductField(i)"
                                        matTooltip="Remove"
                                    >
                                        <mat-icon
                                            class="icon-size-5"
                                            [svgIcon]="'heroicons_solid:trash'"
                                        ></mat-icon>
                                    </button>
                                </span> -->
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
                <!-- <button mat-flat-button [color]="'primary'" (click)="add()">
                    add
                </button> -->

                <div class="flex items-center justify-end mt-10">
                    <button class="px-6 ml-3" mat-flat-button [color]="'primary'" (click)="save()">
                        Save
                    </button>
                </div>
            </div>
        </div>
    </form>
</mat-dialog-content>