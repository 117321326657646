<!-- vendorsCredit form -->

<div class="w-full h-full">
    <mat-drawer-container class="flex-auto h-full  dark:bg-transparent">



        <mat-drawer class="w-full md:w-160 dark:bg-gray-900 p-5" [mode]="drawerMode" [opened]="false" [position]="'end'"
            [disableClose]="true" #matDrawer>


            <div class="flex justify-end">
                <button mat-raised-button (click)="closeDrawer()">
                    <span class="inline-flex items-center">
                        <mat-icon svgIcon="feather:x"></mat-icon>
                    </span>
                </button>
            </div>

            <div class="flex flex-col mt-8">
                <p class="text-lg font-medium" style="font-family: Montserrat">
                    Sales Order Information </p>

                <div class="text-container mt-2"
                    style="display: flex; justify-content: space-between; white-space: nowrap;">
                    <p class="text-secondary mt-3">
                        <span class="icon">
                        </span>
                        Created by: {{salesPerson}}
                    </p>
                    <p class="text-secondary mt-3">
                        <span>
                        </span>
                        Date: {{created}} Time: {{formattedTime}}
                    </p>
                </div>
                <mat-divider class="mt-5 mb-5"></mat-divider>
                <p class="text-secondary mt-2" style="font-family: Montserrat">Customer
                </p>
                <div class="flex items-start">

                    <div class="grid grid-rows-2 place-items-start  ">
                        <div class="grid auto-cols-max grid-flow-col gap-x-5">

                            <div class="pl-4text-md">
                                <div class="font-medium"> {{ vendor?.Customers.firstName }} {{
                                    vendor?.Customers.lastName
                                    }}
                                </div>
                                <div>{{vendor?.Customers.gender}}</div>
                                <div>{{vendor?.Customers.socialMedia }}</div>
                                <ng-container *ngFor="let number of vendor?.Customers.phoneNumbers"
                                    class="col-span-12 flex items-center">

                                    <div> {{number.label}} +977-{{number.number}}</div>
                                </ng-container>
                                <div *ngFor="let email of vendor?.Customers.emails">
                                    <div>{{ email.email}}</div>
                                </div>
                                <div *ngFor="let email of vendor?.Customers.billingAddress">
                                    <div>{{email.type}} {{email.city}} {{ email.address}}</div>
                                </div>

                                <div *ngFor="let email of vendor?.Customers.shippingArea">
                                    <div>{{email.type}} {{email.city}} {{ email.address}}</div>
                                </div>
                            </div>
                        </div>

                    </div>


                    <div class="grid grid-cols-2 gap-x-4 gap-y-1 ml-auto">

                        <div class="justify-self-end text-4xl tracking-tight text-secondary">Sales Order</div>
                        <div class="text-4xl">{{vendor?.sales_order_num}}</div>
                        <div class="justify-self-end font-medium tracking-tight text-secondary">Created Date</div>
                        <div class="font-medium">{{vendor?.slaes_order_date}}</div>
                        <div class="justify-self-end font-medium tracking-tight text-secondary">Expected Shipment Date
                        </div>
                        <div class="font-medium">{{vendor?.sales_order_shippmentDate}}</div>
                        <div class="justify-self-end font-medium tracking-tight text-secondary">TOTAL </div>
                        <div class="font-medium">रू {{vendor?.total}}</div>
                        <div class="justify-self-end font-medium tracking-tight text-secondary">Status </div>
                        <div class="font-medium">{{vendor?.status}}</div>
                    </div>

                </div>


                <div class="flex items-start">

                    <div class="grid grid-rows-1 place-items-start">
                        <div class="grid auto-cols-max grid-flow-col gap-x-5">

                            <div class="pl-4text-md">
                                <div class=" tracking-tight">
                                    <strong class="mr-3">Point of sales </strong>
                                    <ng-container class="text-secondary" *ngIf="vendor?.pos">{{ vendor?.pos }}
                                    </ng-container>
                                    <ng-container *ngIf="!vendor?.refrences">--</ng-container>

                                </div>


                                <div class="font-medium tracking-tight">
                                    <strong class="mr-3">Social Media Link </strong>
                                    <a class="text-link" *ngIf="vendor?.soical_link" target="_blank"
                                        href="https://www.instagram.com/{{vendor?.soical_link}}">@{{ vendor?.soical_link
                                        }}</a>
                                    <div *ngIf="!vendor?.refrences">--</div>

                                </div>


                                <div class="tracking-tight mr-3">
                                    <strong class="mr-3">Shipping Area </strong>
                                    <div *ngIf="vendor?.shipping_area">{{ vendor?.shipping_area }},
                                        {{vendor?.district}},<br>
                                        {{vendor?.address}}</div>
                                    <div *ngIf="!vendor?.shipping_area">--</div>

                                </div>


                                <div class="tracking-tight">
                                    <strong class="mr-3">Payment Type </strong>

                                    <ng-container class="text-secondary " *ngIf="vendor?.payment_type">{{
                                        vendor?.payment_type }}</ng-container>
                                    <ng-container class="text-secondary" *ngIf="!vendor?.payment_type">--</ng-container>
                                </div>


                                <div class="tracking-tight ">
                                    <strong class="mr-3">Delivery Type </strong>
                                    <ng-container class="text-secondary" *ngIf="vendor?.delivery_type">{{
                                        vendor?.delivery_type }}</ng-container>
                                    <ng-container class="text-secondary" *ngIf="!vendor?.refrences">--</ng-container>
                                </div>


                                <div class="tracking-light">
                                    <strong class="mr-3">Shipping Carrier </strong>

                                    <ng-container class="text-secondary" *ngIf="vendor?.shipping_carrier_invalley">{{
                                        vendor?.shipping_carrier_invalley }}</ng-container>
                                    <ng-container class="text-secondary" *ngIf="vendor?.shipping_carrier_outvalley">{{
                                        vendor?.shipping_carrier_outvalley}}</ng-container>
                                    <ng-container class="text-secondary" *ngIf="vendor?.shipping_carrier_int">{{
                                        vendor?.shipping_carrier_int }}</ng-container>
                                    <ng-container class="text-secondary"
                                        *ngIf="vendor?.shipping_carrier_invalley_others">
                                        {{vendor?.shipping_carrier_invalley_others }}</ng-container>
                                    <ng-container class="text-secondary" *ngIf="vendor?.shipping_carrier_int">{{
                                        vendor?.shipping_carrier_int }}</ng-container>
                                    <ng-container class="text-secondary" *ngIf="!vendor?.refrences">--</ng-container>

                                </div>


                            </div>
                        </div>
                    </div>
                </div>


                <div class="grid grid-cols-12 gap-x-1  mt-10">
                    <!-- Columns -->
                    <div class="col-span-4 font-medium text-md text-secondary">PRODUCT</div>
                    <div class="col-span-2 font-medium text-md text-right text-secondary">SIZE</div>
                    <div class="col-span-2 font-medium text-md text-right text-secondary">COLOR</div>
                    <div class="col-span-1 font-medium text-md text-right text-secondary">QTY</div>
                    <div class="col-span-1 font-medium text-md text-right text-secondary">TOTAL</div>

                    <!-- Divider -->

                    <!-- Products Loop -->
                    <ng-container *ngFor="let product of vendor?.products" class="col-span-12 flex items-center">
                        <!-- Product Avatar (Image) -->
                        <div class="col-span-4">
                            <div class="text-lg font-medium">
                                <!-- Check if it is a bundle or product -->
                                <ng-container *ngIf="product.isBundle; else productInfo">
                                    {{ product.product_bundle.bundle_name }}
                                </ng-container>
                                <ng-template #productInfo>
                                    {{ product.product.base_product.productName }}
                                </ng-template>
                            </div>
                            <div class="mt-2 text-md text-secondary">
                                <!-- Check if it is a bundle or product -->
                                <ng-container *ngIf="product.isBundle; else productDescription">
                                    {{ product.product_bundle.bundle_description }}
                                </ng-container>
                                <ng-template #productDescription>
                                    {{ product.displayDescription }}
                                </ng-template>
                            </div>
                        </div>

                        <div class="col-span-2 self-center text-right">{{ product.product.size }}</div>
                        <div class="col-span-2 self-center text-right">{{ product.product.color }}</div>
                        <div class="col-span-1 self-center text-right">{{ product.quantity }}</div>
                        <div class="col-span-1 self-center text-right"> {{ product.amount }}</div>

                        <!-- Divider -->
                        <div class="col-span-12 my-4 border-b"></div>
                    </ng-container>




                    <!-- Divider -->

                    <!-- Total -->
                    <div class="col-span-14 self-center text-2xl font-medium tracking-tight text-secondary">TOTAL</div>
                    <div class="col-span-2 text-right text-2xl font-medium">रू {{ vendor?.total }}</div>
                </div>


            </div>
        </mat-drawer>

        <mat-drawer-content>
            <form [formGroup]="vendorsCredit" class=" rounded shadow-lg h-full">
                <div *ngIf="vendorsCredit" class="p-8">
                    <div class="prose prose-sm max-w-3xl">
                    </div>
                    <div class="flex justify-end">
                        <button mat-raised-button [routerLink]="['..']">
                            <span class="inline-flex items-center">
                                <mat-icon svgIcon="feather:x"></mat-icon>
                            </span>
                        </button>
                    </div>
                    <mat-divider class="mt-8 mb-10"></mat-divider>
                    <div class="flex flex-col mt-8">
                        <p class="text-lg font-medium" style="font-family: Montserrat">
                            Basic information
                        </p>
                        <p class="text-secondary" style="font-family: Montserrat">
                            Set your Sales Return Detail Here
                        </p>
                        <mat-divider class="mt-8 mb-10"></mat-divider>

                        <div class="flex mb-4">
                            <mat-form-field style="max-width: 600px"
                                class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix">
                                <mat-label class="ml-3">Sales Order Number</mat-label>
                                <input matInput type="text" placeholder="Select Sales Order Num"
                                    [formControl]="SaleOrderNumberControl" aria-label="SalesOrderNumber"
                                    [matAutocomplete]="auto1" required [readonly]="isFieldReadOnly" />
                                <button type="button" *ngIf="isFieldReadOnly" mat-icon-button
                                    (click)="makeFieldEditable()">
                                    <mat-icon class="icon-size-4" svgIcon="feather:edit"></mat-icon>
                                </button>
                                <button type="button" *ngIf="isFieldReadOnly" class="ml-3" matTooltip="View Sales Order"
                                    (click)="openDrawer()" style="display: flex; align-items: center;">
                                    <mat-icon class="icon-size-4" svgIcon="heroicons_outline:eye"></mat-icon>
                                </button>

                                <mat-autocomplete #auto1="matAutocomplete" [displayWith]="displaySalesOrderOption"
                                    (optionSelected)="
                    selectSalesOrderOption($event.option.value)
                ">
                                    <mat-option style="height: fit-content" *ngFor="
                        let salesorder of filteredSalesOrder$
                            | async
                    " [value]="salesorder">
                                        <div style="display: flex; align-items: center">
                                            <span class="example-option-name">{{
                                                salesorder.sales_order_num
                                                }}</span>
                                        </div>
                                    </mat-option>
                                </mat-autocomplete>
                                <mat-icon class="icon-size-4" matPrefix [svgIcon]="'feather:hash'"></mat-icon>
                            </mat-form-field>

                        </div>




                        <div class="flex mb-4">
                            <mat-form-field style="max-width: 600px"
                                class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix">
                                <mat-label class="ml-3">Customer</mat-label>
                                <input matInput type="text" placeholder="Search Customers"
                                    [formControlName]="'customerControl'" aria-label="Customers" readonly />
                                <mat-icon class="icon-size-4" matPrefix [svgIcon]="'heroicons_solid:user'"></mat-icon>
                            </mat-form-field>
                        </div>


                        <div class="flex flex-col gt-xs:flex-row">
                            <mat-form-field class="flex-auto gt-xs:pl-3 fuse-mat-rounded fuse-mat-emphasized-affix"
                                style="max-width: 600px">
                                <mat-label class="ml-3">Choose a date</mat-label>
                                <input matInput [matDatepicker]="picker" [max]="maxDate" formControlName="date"
                                    name="date" />
                                <mat-hint class="ml-3">MM/DD/YYYY</mat-hint>
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                                <mat-icon class="icon-size-4" matPrefix
                                    [svgIcon]="'heroicons_solid:calendar'"></mat-icon>
                            </mat-form-field>
                        </div>

                        <ng-container *ngIf="enablePartialReturns" class="flex mb-4">
                            <mat-checkbox formControlName="enablePartialReturn">Enable Partial Return</mat-checkbox>
                        </ng-container>

                        <mat-divider class="mt-8 mb-3"></mat-divider>



                        <ng-container class="flex mb-4" formArrayName="products">

                            <ng-container *ngFor="
                            let product of vendorsCredit.get('products')['controls'];
                            let i = index;
                            let first = first;
                            let last = last;
                            trackBy: trackByFn
                        ">

                                <div class="flex mt-1 mb-2" style="padding: auto" [formGroupName]="i">

                                    <ng-container *ngIf="vendorsCredit.get('enablePartialReturn').value"
                                        class="flex mt-4">
                                        <mat-checkbox formControlName="returnSelection"
                                            (change)="calculateTotalApplied()"></mat-checkbox>
                                    </ng-container>

                                    <mat-form-field class="fuse-mat-no-subscript fuse-mat-rounded w-200 mr-2">
                                        <mat-label class="ml-3" *ngIf="first">Product</mat-label>
                                        <input style="height: 50px" matInput formControlName="sale_product"
                                            placeholder="Type SKU or Product Name" readonly />
                                    </mat-form-field>
                                    <mat-form-field
                                        class="fuse-mat-no-subscript w-75 fuse-mat-rounded fuse-mat-emphasized-affix mr-2">
                                        <mat-label class="ml-3" *ngIf="first">Color</mat-label>
                                        <input style="height: 50px" matInput formControlName="sale_color"
                                            placeholder="Color" readonly />

                                        <mat-icon class="icon-size-4" matPrefix svgIcon="color_lens"></mat-icon>

                                    </mat-form-field>
                                    <mat-form-field
                                        class="fuse-mat-no-subscript w-75 fuse-mat-rounded fuse-mat-emphasized-affix mr-2">
                                        <mat-label class="ml-3" *ngIf="first">Size</mat-label>
                                        <input style="height: 50px" matInput formControlName="sale_size"
                                            placeholder="Size" readonly />
                                        <mat-icon class="icon-size-4" matPrefix svgIcon="format_size"></mat-icon>

                                    </mat-form-field>


                                
                                    <mat-form-field class="w-50 fuse-mat-rounded fuse-mat-emphasized-affix mr-2">
                                        <mat-label class="ml-3" *ngIf="first">Quantity</mat-label>
                                        <mat-icon matPrefix svgIcon="mat_solid:production_quantity_limits"></mat-icon>
                                        <input style="height: 50px" matInput type="text"
                                            onkeypress="return event.charCode>=48 && event.charCode<=57"
                                            formControlName="sale_quantity" (change)="validateQuantity(i)"
                                            placeholder="Quantity" />
                                        <mat-error
                                            *ngIf="product.get('sale_quantity').hasError('invalidQuantity')">Invalid
                                            quantity.</mat-error>
                                        <mat-error *ngIf="product.get('sale_quantity').hasError('exceedStock')">Quantity
                                            cannot exceed current stock.</mat-error>
                                        <mat-error
                                            *ngIf="product.get('sale_quantity').hasError('negativeQuantity')">Quantity
                                            cannot be less than zero.</mat-error> </mat-form-field>
                                    <mat-form-field [ngClass]="formFieldHelpers"
                                        class="fuse-mat-no-subscript w-75 fuse-mat-rounded  fuse-mat-emphasized-affix mr-2">
                                        <mat-label class="ml-3" *ngIf="first">Rate</mat-label>

                                        <input style="height: 50px" matInput type="text"
                                            onkeypress="return event.charCode>=48 && event.charCode<=57"
                                            formControlName="sale_rate" placeholder="Rate" readonly />
                                        <span matPrefix>रु</span>
                                    </mat-form-field>

                                    <mat-form-field [ngClass]="formFieldHelpers"
                                        class="fuse-mat-no-subscript w-75 fuse-mat-rounded fuse-mat-emphasized-affix mr-2">
                                        <mat-label class="ml-3" *ngIf="first">Amount</mat-label>
                                        <input style="height: 50px" matInput type="text"
                                            onkeypress="return event.charCode>=48 && event.charCode<=57"
                                            formControlName="sale_amount" placeholder="Amount" readonly />
                                        <span matPrefix>रु</span>
                                    </mat-form-field>

                                </div>
                            </ng-container>
                        </ng-container>

                        <div class="flex mb-2 mt-2">
                            <mat-form-field style="max-width: 300px"
                                class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix">
                                <mat-label>Total</mat-label>
                                <input matInput placeholder="Total" name="total" [formControlName]="'total'"
                                    class="elevated-input" />
                                <mat-icon class="icon-size-4" matPrefix [svgIcon]="''">रु</mat-icon>
                            </mat-form-field>
                        </div>
                        <mat-divider class="mt-8 mb-3"></mat-divider>

                        <div class="flex mb-2">
                            <mat-form-field style="max-width: 600px"
                                class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix">
                                <mat-label>Reason For return</mat-label>
                                <input matInput placeholder="Reason For Return" name="ResonFor Retunr"
                                    [formControlName]="'rfr'" class="elevated-input" />
                                <mat-icon class="icon-size-5" matPrefix [svgIcon]="'location_city'"></mat-icon>
                            </mat-form-field>
                        </div>

                        <!-- sdsa product -->

                        <div class="flex items-center justify-start mt-10">
                            <button (click)="submitVendorsInfo()"
                                class="px-6 py-2 mr-2 rounded text-white bg-red-500 hover:bg-red-600" mat-flat-button
                                color="'accent'">
                                Save
                            </button>

                            <button [routerLink]="['..']"
                                class="px-6 py-2 rounded text-white bg-blue-500 hover:bg-blue-600" mat-flat-button
                                color="'accent'">
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </mat-drawer-content>
    </mat-drawer-container>
</div>