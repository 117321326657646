import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { server_env } from 'config';
import { RestockProduct } from './restock-product.type'; 
@Injectable({
    providedIn: 'root',
})
export class DetailRestockService {
  private _RestockEntry: BehaviorSubject<RestockProduct | null> =
  new BehaviorSubject(null);
private _RestockEntryss: BehaviorSubject<RestockProduct[] | null> =
  new BehaviorSubject(null);

public url = `${server_env.URL}api/v1/RestockEntry/`;


/**
* Constructor
*/
constructor(private _httpClient: HttpClient) {}

// -----------------------------------------------------------------------------------------------------
// @ Accessors

// -----------------------------------------------------------------------------------------------------

/**
* Getter for contact
*/
get RestockProduct$(): Observable<RestockProduct> {
  return this._RestockEntry.asObservable();
}

/**
* Getter for contacts
*/
get RestockEntrys$(): Observable<RestockProduct[]> {
  return this._RestockEntryss.asObservable();
}

// -----------------------------------------------------------------------------------------------------
// @ Public methods
// -----------------------------------------------------------------------------------------------------

/**
* Get contacts
*/

getRestockEntry(): Observable<RestockProduct[]> {
  return this._httpClient.get<RestockProduct[]>(`${this.url}`).pipe(
      tap((RestockProduct: any) => {
          // console.log();
          this._RestockEntryss.next(RestockProduct.data);

          for (RestockProduct in RestockProduct.data) {
              // console.log(RestockProduct, 'sdas');
          }
      })
  );
}

/**
* Get contact by id
*/
getRestockEntryById(id: string): Observable<RestockProduct> {
  const requestUrl = this.url + id;

  return this._httpClient.get<RestockProduct[]>(`${requestUrl}`).pipe(
      take(1),
      map((RestockProduct: any) => {
          this._RestockEntry.next(RestockProduct.data);

          // Return the contact
          return RestockProduct.data;
      }),
      switchMap((RestockProduct) => {
          if (!RestockProduct) {
              return throwError(
                  'Could not found contact with id of ' + id + '!'
              );
          }

          return of(RestockProduct);
      })
  );
}



/**
* create customer
* @param data
* @returns
*/

createRestockEntrys(data): Promise<any> {
  return new Promise((resolve, reject) => {
      const requestUrl = this.url;

      this._httpClient.post<any>(`${requestUrl}`, data).subscribe(
          (response) => {
              return resolve(response);
          },
          (error) => {
              return reject(error);
          }
      );
  });
}
/**
* Update contact
*
* @param id
* @param contact
*/
updateRestockEntry(
  id: string,
  contact: RestockProduct
): Observable<RestockProduct> {
  return this.RestockEntrys$.pipe(
      take(1),
      switchMap((salesordder) =>
          this._httpClient
              .put<RestockProduct>(`${this.url}${id}/`, {
                  id,
                  contact,
              })
              .pipe(
                  map((updatedRestockEntry) => {
                      // Find the index of the updated contact
                      const index = salesordder.findIndex(
                          (item) => item.id === id
                      );

                      // Update the contact
                      salesordder[index] = updatedRestockEntry;

                      // Update the contacts
                      this._RestockEntryss.next(salesordder);

                      // Return the updated contact
                      return updatedRestockEntry;
                  }),
                  switchMap((updatedRestockEntrys) =>
                      this.RestockProduct$.pipe(
                          take(1),
                          filter((item) => item && item.id === id),
                          tap(() => {
                              // Update the contact if it's selected
                              this._RestockEntry.next(
                                  updatedRestockEntrys
                              );

                              // Return the updated contact
                              return updatedRestockEntrys;
                          })
                      )
                  )
              )
      )
  );
}

/**
* Delete the contact
*
* @param id
*/
deleteRestockEntry(id: string): Observable<boolean> {
  return this.RestockEntrys$.pipe(
      take(1),
      switchMap((RestockProduct) =>
          this._httpClient.delete(`${this.url}${id}/`).pipe(
              map((isDeleted: boolean) => {
                  // Find the index of the deleted contact
                  const index = RestockProduct.findIndex(
                      (item) => item.id === id
                  );

                  // Delete the contact
                  RestockProduct.splice(index, 1);

                  // Update the contacts
                  this._RestockEntryss.next(RestockProduct);

                  // Return the deleted status
                  return isDeleted;
              })
          )
      )
  );
}
}
