<div class="relative flex flex-col w-full h-full">

    <!-- Dismiss button -->
    <ng-container>
        <div class="absolute top-0 right-0 pt-4 pr-4">
            <button mat-icon-button (click)="onCancelClick()">
                <mat-icon class="text-secondary" svgIcon="heroicons_outline:x"></mat-icon>

            </button>
        </div>
    </ng-container>

    <!-- Content -->
    <div class="flex flex-col sm:flex-row flex-auto items-center sm:items-start p-8 pb-6 sm:pb-8">

        <!-- Icon -->
        <ng-container>
            <div
                class="flex flex-0 items-center justify-center w-10 h-10 sm:mr-4 rounded-full text-primary-600 bg-primary-100 dark:text-primary-50 dark:bg-primary-600">
                <mat-icon class="text-current" svgIcon="feather:alert-triangle"></mat-icon>

            </div>
        </ng-container>

        <ng-container>
            <div
                class="flex flex-col items-center sm:items-start mt-4 sm:mt-0 sm:pr-8 space-y-1 text-center sm:text-left">

                <!-- Title -->
                <ng-container>
                    <div class="text-xl leading-6 font-medium"> Unsaved Changes ?</div>
                </ng-container>

                <!-- Message -->
                <ng-container>
                    <div class="text-secondary">
                        You have unsaved changes. If you leave this page, your changes will be discarded.
                        <br>
                        Do you want to continue and discard the changes?
                    </div>
                    
                </ng-container>
            </div>
        </ng-container>

    </div>

    <!-- Actions -->
    <!-- Actions -->
    <ng-container>
        <div
            class="flex items-center justify-center sm:justify-end px-6 py-4 space-x-3 bg-gray-50 dark:bg-black dark:bg-opacity-10">

            <!-- Cancel -->
            <ng-container>
                <button mat-stroked-button class="bg-red-300" (click)="onCancelClick()">
                    Close
                </button>
            </ng-container>

            <!-- Confirm -->
            <ng-container>
                <button mat-flat-button class="bg-green-200" (click)="onStayClick()">
                    Stay
                </button>
            </ng-container>

            <ng-container>
                <button  mat-flat-button class="bg-yellow-300" (click)="onLeaveAnywayClick()">
                    Leave Anyway
                </button>
            </ng-container>

        </div>
    </ng-container>


</div>