import { Route } from '@angular/router';




import { ListCreditNotesComponent } from './list-credit-notes/list-credit-notes.component';
import { CreateCreditNotesComponent } from './create-credit-notes/create-credit-notes.component';
import { DetailCreditNotesComponent } from './detail-credit-notes/detail-credit-notes.component';
import { UnsavedChangesGuard } from 'app/page-detect/wm-can-leave/detect-changes.guard';

export const CreditNotesRoutes: Route[] = [
    {
        path: '',
        component: ListCreditNotesComponent,
    },
    {
        path: 'NEW',
        component: CreateCreditNotesComponent,
        canDeactivate: [UnsavedChangesGuard]

    },
    {
        path: ':id',
        component: DetailCreditNotesComponent,


    },
]