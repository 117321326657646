import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { productBundle } from '../product-bundle.type';
import { Observable } from 'rxjs';
import { productBundleService } from '../product-bundle.service';
import { take } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import Swal from 'sweetalert2';
import * as ExcelJS from 'exceljs';

export interface ProductData {


    bundle_name?: string,
    bundle_description?: string;
    products?: any;
    bundle_sku?: any;
    total?: any;
    stock?: any;
    disabled?: boolean,
    isExpanded?: boolean;
    dropdownHidden?: boolean;


}


@Component({
    selector: 'app-list-product-bundle',
    templateUrl: './list-product-bundle.component.html',
    styleUrls: ['./list-product-bundle.component.scss'],
})
export class ListProductBundleComponent implements OnInit, AfterViewInit {
    displayedColumns: string[] = ['Bundle Name', 'Bundle Description','disabled', 'actions'];
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    salesOrders: productBundle[];
    dataSource: MatTableDataSource<ProductData>;

    salesorder$: Observable<productBundle[]>;
    flashMessage: 'success' | 'error' | null = null;
    isLoading: boolean = false;
    private delayTimeout: any;


    constructor(

        private _router: Router,
        private _salesorderService: productBundleService,
        private cdr: ChangeDetectorRef
    ) {


    }

    ngOnInit(): void {
        this.listProductBundle();




    }
    listProductBundle() {
        this.salesorder$ = this._salesorderService.getproducts().pipe(take(1));
        this.salesorder$.subscribe((salesorder) => {
            this.salesOrders = salesorder['data'];

            this.dataSource = new MatTableDataSource<ProductData>(this.salesOrders.map(salesOrder => {


                return {
                    bundle_name: salesOrder.bundle_name,
                    bundle_description: salesOrder.bundle_description,
                    isExpanded: false,
                    disabled: salesOrder.disabled,
                    id: salesOrder.id,
                    dropdownHidden: true,

                };
            }));
        });
    }


    toggleDisabledStatus(id: any) {
        const product = this.salesOrders.find(p => p.id === id);
        if (product) {
          // Toggle the disabled status
          const updatedStatus = !product.disabled;
          this._salesorderService.updateProductStatus(id, updatedStatus).subscribe(() => {
            // Update successful, re-fetch data or notify the user
            this.listProductBundle();
          }, error => {
            // Handle error
            console.error('Error updating product status:', error);
          });
        }
      }
      
    
    
    ngAfterViewInit() {

        setTimeout(() => {
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
        }, 400);
    }


    deleteVendorsCredit(id: any) {
        // Prompt the user for confirmation before deleting the employee
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                this._salesorderService.deleteproducts(id)
                    .subscribe((isDeleted) => {
                        // Return if the contact wasn't deleted...
                        if (!isDeleted) {
                            Swal.fire("Error!", "Cannot Delete");
                            return;
                        }

                        // Display a success message
                        Swal.fire(
                            'Deleted!',
                            'Your file has been deleted.',
                            'success'
                        ).then(() => {
                            this.listProductBundle()
                        });
                    });
            }
        });
    }




    showDetails(id: any) {
        this._router.navigate(['apps/product-bundle', id]);
    }


    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }
    createProduct() {
        this._router.navigate(['apps/product-bundle', 'NEW']);

    }
  

    


    exportexcel(): void {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You want to download the file!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, download it!'
        }).then((result) => {
            if (result.isConfirmed) {
                const element = document.getElementById('excel-table');
                const data = [];

                // Iterate over table rows and collect data
                const rows = element.querySelectorAll('tr');

                rows.forEach((row) => {
                    const rowData = [];
                    const cells = row.querySelectorAll('td');
                    cells.forEach((cell) => {
                        rowData.push(cell.textContent || ''); // Use textContent to get the cell's text
                    });
                    data.push(rowData);
                });

                // Create a new workbook and add a worksheet
                const workbook = new ExcelJS.Workbook();
                const worksheet = workbook.addWorksheet('Sheet1');

                // Define your custom styles for headers and data
                const headerStyle = { font: { bold: true }, alignment: { horizontal: 'center' as const } };
                const dataStyle = { alignment: { horizontal: 'left' as const } };

                // Add headers with custom style
                const headers = ['Name',
                    'SKU',
                    'Description',
                    'Stock',
                    'Total Price',
                    'Products',


                /* Add more header column names here */];
                worksheet.addRow(headers);
                worksheet.getRow(1).eachCell((cell) => {
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFF00' }, // Yellow background color
                    };
                    cell.border = {
                        top: { style: 'thin' },
                        left: { style: 'thin' },
                        bottom: { style: 'thin' },
                        right: { style: 'thin' },
                    };
                });

                // Add data with custom style
                data.forEach((rowData) => {
                    worksheet.addRow(rowData);
                    worksheet.lastRow.eachCell((cell) => {
                        cell.alignment = dataStyle.alignment;
                    });
                });

                // Calculate the total and add it to the end of the worksheet
                const total = data.reduce((sum, row) => sum + (parseFloat(row[1]) || 0), 0); // Assuming the total is in the second column (index 1)
                worksheet.addRow(['Total', total]);
                worksheet.lastRow.eachCell((cell) => {
                    cell.font = headerStyle.font;
                    cell.alignment = headerStyle.alignment;
                });

                // Create a blob and initiate file download
                workbook.xlsx.writeBuffer().then((buffer) => {
                    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    saveAs(blob, 'ProductBundle.xlsx'); // Replace 'your-filename' with the desired file name
                });
            }
        });

    }
}



/** Builds and returns a new User. */






