<!-- vendorsCredit form -->



<div class="w-full h-full w-full">
    <form [formGroup]="vendorsCredit"  class="bg-gray-100 rounded shadow-lg h-full">
     
        <!-- Avatar -->
        <!-- Avatar code goes here -->


        <!-- Personal Information Form -->
        <div *ngIf="vendorsCredit" class="p-8">
            <div class="prose prose-sm max-w-3xl">
                <!-- HTML template -->
            </div>
            <div class="flex justify-end">
                <button mat-raised-button [routerLink]="['..']">
                    <span class="inline-flex items-center">

                        <mat-icon svgIcon="feather:x"></mat-icon>


                    </span>
                </button>
            </div>


            <div class="flex flex-col mt-8">
                <p class="text-lg font-medium">Basic information</p>
                <p class="text-secondary">Set your Size Details</p>
                <mat-divider class="mt-8 mb-10"></mat-divider>

                <div class="flex mb-4">
                    <mat-form-field class="flex-auto">
                        <mat-label>Waist</mat-label>
                        <input matInput placeholder="Waist" required name="lastName" [formControlName]="'waist'"
                            class="elevated-input" />
                        <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:mail'"></mat-icon>
                    </mat-form-field>
                </div>
                <div class="flex mb-4">
                    <mat-form-field class="flex-auto">
                        <mat-label>Size</mat-label>
                        <input matInput placeholder="Size" required name="lastName" [formControlName]="'size'"
                            class="elevated-input" />
                        <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:mail'"></mat-icon>
                    </mat-form-field>
                </div>

                <div class="flex mb-4">
                    <mat-form-field class="flex-auto">
                        <mat-label>Hips</mat-label>
                        <input matInput placeholder="Hips" required name="email" [formControlName]="'hips'"
                            class="elevated-input" />
                        <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:mail'"></mat-icon>
                    </mat-form-field>
                </div>
                <!-- Additional Fields -->
                <div class="flex mb-4">
                    <mat-form-field class="flex-auto">
                        <mat-label>Bust</mat-label>
                        <input matInput placeholder="Bust" name="dob" [formControlName]="'bust'" class="elevated-input"
                            type="text" />
                        <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:calendar'"></mat-icon>
                    </mat-form-field>
                </div>

                <!-- <div class="flex mb-4">
            <mat-form-field class="flex-auto">
              <mat-label>Influencer phone</mat-label>
              <input matInput placeholder="(000) 000-000-0000" name="phone" [formControlName]="'phoneNumbers'"
                class="elevated-input" mask="(000) 000-000-0000" required maskDirective />
              <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:phone'"></mat-icon>
            </mat-form-field>
          </div> -->
                <div class="flex mb-4">
                    <mat-form-field class="flex-auto">
                        <mat-label>Cup Size</mat-label>
                        <mat-select placeholder="Cup Size" name="gender" [formControlName]="'cup_size'"
                            class="elevated-input" required>
                            <mat-option value="A">A</mat-option>
                            <mat-option value="B">B</mat-option>
                            <mat-option value="C">C</mat-option>
                            <mat-option value="D">D</mat-option>

                        </mat-select>
                        <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:translate'"></mat-icon>
                    </mat-form-field>
                </div>






                <div class="flex mb-4">
                    <mat-form-field class="flex-auto">
                        <mat-label>Height</mat-label>
                        <input matInput placeholder="Height" required name="sub_total" [formControlName]="'height'"
                            class="elevated-input" />
                        <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:mail'"></mat-icon>
                    </mat-form-field>
                </div>
                <div class="flex mb-4">
                    <mat-form-field class="flex-auto">
                        <mat-label>Length</mat-label>
                        <input matInput placeholder="Length" required name="sub_total" [formControlName]="'length'"
                            class="elevated-input" />
                        <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:mail'"></mat-icon>
                    </mat-form-field>
                </div>
                <div class="flex mb-4">
                    <mat-form-field class="flex-auto">
                        <mat-label>Fit</mat-label>
                        <input matInput placeholder="Fit" required name="type" [formControlName]="'fit'"
                            class="elevated-input" />
                        <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:mail'"></mat-icon>
                    </mat-form-field>
                </div>








                <div class="flex items-center justify-end mt-10">
                    <button (click)="sumbitsizeInfo()"  class="px-6 py-2 rounded text-white bg-blue-500 hover:bg-blue-600" mat-flat-button
                        [color]="'primary'">
                        Save
                    </button>
                </div>

            </div>
        </div>
        
    </form>
</div>