import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { server_env } from 'config';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { Size } from './size.type';

@Injectable({
  providedIn: 'root'
})
export class SizeService {
  private _size: BehaviorSubject<Size | null> = new BehaviorSubject(null);
  private _sizes: BehaviorSubject<Size[] | null> = new BehaviorSubject(null);



  public url = `${server_env.URL}api/v1/size/`;
  public urlReport = `${server_env.URL}api/v1/sizes/`;



  constructor(private _httpClient: HttpClient) { }

  get size$(): Observable<Size> {
    return this._size.asObservable();
}

/**
 * Getter for contacts
 */
get sizes$(): Observable<Size[]> {
    return this._sizes.asObservable();
}



getSizes(): Observable<Size[]> {
  return this._httpClient.get<Size[]>(`${this.url}`).pipe(
      tap((sizes: any) => {
          this._sizes.next(sizes.data);

          for (sizes in sizes.data) {
             
          }
      })
  );
}
createSize(data): Promise<any> {
    return new Promise((resolve, reject) => {
        const requestUrl = this.url;

        this._httpClient.post<any>(`${requestUrl}`, data).subscribe(
            (response) => {
                return resolve(response);
            },
            (error) => {
                return reject(error);
            }
        );
    });
}

/**
* Get Size by id
*/
getSizeById(id: any): Observable<Size> {
  const requestUrl = this.urlReport + id;
 
  return this._httpClient.get<Size[]>(`${requestUrl}/`).pipe(
      take(1),
      map((size: any) => {
          this._size.next(size);

          // Return the Size
          return size;
      }),
      switchMap((size) => {
          if (!size) {
              return throwError(
                  'Could not found contact with id of ' + id + '!'
              );
          }

          return of(size);
      })
  );

}


   /**
     * Delete the Size
     *
     * @param id
     */
    deleteSize(id: string): Observable<boolean> {
      return this.sizes$.pipe(
          take(1),
          switchMap((size) =>
              this._httpClient.delete(`${this.url}${id}/`).pipe(
                  map((isDeleted: boolean) => {
                      // Find the index of the deleted Size
                      const index = size.findIndex(
                          (item) => item.id === id
                      );

                      // Delete the Size
                      size.splice(index, 1);

                      // Update the Size
                      this._sizes.next(size);

                      // Return the deleted Size
                      return isDeleted;
                  })
              )
          )
      );
  }

}
