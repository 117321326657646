import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { server_env } from 'config';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { PromoCode } from './promo-code.type';

@Injectable({
  providedIn: 'root'
})
export class PromoCodeService {
  private _promoCode: BehaviorSubject<PromoCode | null> = new BehaviorSubject(null);
  private _promoCodes: BehaviorSubject<PromoCode[] | null> = new BehaviorSubject(null);



  public url = `${server_env.URL}api/v1/promo-code/`;



  constructor(private _httpClient: HttpClient) { }

  get promoCode$(): Observable<PromoCode> {
    return this._promoCode.asObservable();
}

/**
 * Getter for contacts
 */
get promoCodes$(): Observable<PromoCode[]> {
    return this._promoCodes.asObservable();
}



getPromoCodes(): Observable<PromoCode[]> {
  return this._httpClient.get<PromoCode[]>(`${this.url}`).pipe(
      tap((promoCodes: any) => {
          this._promoCodes.next(promoCodes.data);

          for (promoCodes in promoCodes.data) {
             
          }
      })
  );
}
createPromoCode(data): Promise<any> {
    return new Promise((resolve, reject) => {
        const requestUrl = this.url;

        this._httpClient.post<any>(`${requestUrl}`, data).subscribe(
            (response) => {
                return resolve(response);
            },
            (error) => {
                return reject(error);
            }
        );
    });
}

/**
* Get PromoCode by id
*/
getPromoCodeById(id: any): Observable<PromoCode> {
  const requestUrl = this.url + id;
 
  return this._httpClient.get<PromoCode[]>(`${requestUrl}/`).pipe(
      take(1),
      map((promoCode: any) => {
          this._promoCode.next(promoCode.data);

          // Return the PromoCode
          return promoCode.data;
      }),
      switchMap((promoCode) => {
          if (!promoCode) {
              return throwError(
                  'Could not found contact with id of ' + id + '!'
              );
          }

          return of(promoCode);
      })
  );

}


   /**
     * Delete the PromoCode
     *
     * @param id
     */
    deletePromoCode(id: string): Observable<boolean> {
      return this.promoCodes$.pipe(
          take(1),
          switchMap((promoCode) =>
              this._httpClient.delete(`${this.url}${id}/`).pipe(
                  map((isDeleted: boolean) => {
                      // Find the index of the deleted PromoCode
                      const index = promoCode.findIndex(
                          (item) => item.id === id
                      );

                      // Delete the PromoCode
                      promoCode.splice(index, 1);

                      // Update the PromoCode
                      this._promoCodes.next(promoCode);

                      // Return the deleted PromoCode
                      return isDeleted;
                  })
              )
          )
      );
  }

}
