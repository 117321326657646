import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pr-packages-credit-notes',
  templateUrl: './pr-packages-credit-notes.component.html',
  styleUrls: ['./pr-packages-credit-notes.component.scss']
})
export class PrPackagesCreditNotesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
