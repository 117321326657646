import {

    Component,
    ElementRef,
    ChangeDetectorRef,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,

} from '@angular/core';
import {
    AbstractControl,
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,

    ValidationErrors,

    ValidatorFn,

    Validators,
} from '@angular/forms';


import { ActivatedRoute, Router } from '@angular/router';

import { Observable, Subject, of, forkJoin, Subscription, EMPTY, BehaviorSubject } from 'rxjs';

import { ContactsService } from 'app/modules/admin/apps/contacts/contacts.service';
import { DatePipe } from '@angular/common';
import {
    catchError,
    debounceTime,
    distinctUntilChanged,
    map,
    startWith,
    switchMap,
    take,
    takeUntil,
} from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { productVaraitonService } from 'app/productVariations/productVarations.type';
import Swal from 'sweetalert2';
import { Contact } from 'app/modules/admin/apps/contacts/contacts.types';
import { UserService } from 'app/core/user/user.service';
import { SalesOrderPopupComponent } from '../sales-order-popup/sales-order-popup.component';
import { User } from 'app/core/user/user.types';

import { SalesOrderService } from '../sales-order.service';
import { products } from 'app/products/products.type';
import { productsService } from 'app/products/products.service';
import { contrydata, insideValleyData } from './countries';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { productBundleService } from 'app/product-bundle/product-bundle.service';
import { ProductBundlePopupComponent } from 'app/product-bundle-popup/product-bundle-popup.component';
import { productBundle } from 'app/product-bundle/product-bundle.type';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { HasUnsavedChanges } from 'app/page-detect/wm-can-leave/detect-changes.guard';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { PromoCodeService } from 'app/promo-code/promo-code.service';
import { PromoCode } from 'app/promo-code/promo-code.type';
import { CreditNotes } from 'app/credit-notes/credit-notes.type';
import { CreditNotesService } from 'app/credit-notes/credit-notes.service';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { MatDrawer } from '@angular/material/sidenav';


export const discountValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const discount = control.value;
    const total = control.parent ? control.parent.get('total').value : null;

    if (total !== null && discount > total) {
        return { discountExceedsTotal: true };
    }

    return null;
};

export interface CustomerOption {
    id: string;
    firstname?: string;
    avatar?: string;
}

export interface ProductOption {
    name?: string;
    stock?: number;
    reserved?: number;
    cost?: number;
    basePrice?: number;
    price?: number;

    images?: string[];
}

export interface Country {
    name: string;
    code: string;

}




@Component({
    selector: 'app-sales-order-create',
    templateUrl: './sales-order-create.component.html',
    styleUrls: ['./sales-order-create.component.scss'],

    animations: [
        trigger('fadeInOut', [
            state('in', style({ opacity: 1, filter: 'blur(0)' })),
            state('out', style({ opacity: 0, filter: 'blur(5px)' })),
            transition(':enter', [
                style({ opacity: 0, filter: 'blur(5px)' }),
                animate('1s ease-out')
            ]),
            transition(':leave', [
                animate('1s ease-in', style({ opacity: 0, filter: 'blur(5px)' }))
            ]),
        ]),
    ],
})
export class SalesOrderCreateComponent implements OnInit, OnDestroy, HasUnsavedChanges {
    @ViewChild('customerInput') customerInput: ElementRef;
    @ViewChild('promoCodeInput') promoCodeInput: ElementRef;
    @ViewChild('voucherInput') voucherInput: ElementRef;
    @ViewChild('matDrawer', { static: true }) matDrawer: MatDrawer;


    discountPercentageSubscription: Subscription;

    private _subscription: Subscription;
    initialRates: { [key: string]: number } = {};

    private searchTerms = new Subject<string>();
    private allProducts: any[] = [];

    mappedVariations: any;
    districtControl = new FormControl();

    filteredDistricts: Observable<string[]>;
    InsidefilteredDistricts: Observable<string[]>;
    districts: string[] = [];

    promoCode: string[] = [];
    voucher: string[] = [];
    currentSalesOrderNum: number;
    ngControl: any;
    sizes: any[] = [];
    colors: any[] = [];
    name: any[] = [];
    quantity: any[] = [];
    current_stock: any[] = [];
    productActive: any[] = [];
    product_details: any[] = [];
    animationFlags: boolean[] = [];
    match_stock: boolean[] = [];
    filterByBundle: boolean[] = [];
    isFieldReadOnlyPromo: boolean;
    productSelected: boolean;
    disableReadOnlyPromo: boolean = false;
    selectedVoucher: any;
    isFieldReadOnlyVoucher: boolean;
    discountPercentage: number = 20;
    appliedAmount: number = 0; // Initialize applied amount to 0

    initialTotal: number | null = null;


    @Input() set disableControl(condition: boolean) {
        const action = condition ? 'disable' : 'enable';
        this.ngControl.control[action]();
    }
   
    productIds: number[] = [];
    stateCtrl = new FormControl('');
    formFieldHelpers: string[] = [''];
    drawerMode: 'over' | 'side' = 'side';
    drawerOpened: boolean = true;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    active: boolean[] = [];
    salesOrderForm: FormGroup;
    vendorsCount: number;
    selectedVendor: Contact;
    customerId: number | undefined;
    customers$: Observable<Contact[]>;
    CustomerControl: FormControl = new FormControl();
    filteredCustomers$: Observable<Contact[]>;
    selectedCustomer: Contact | null = null;
    filteredVoucher$: Observable<any[]>;

    promoCode$: Observable<PromoCode[]>;
    voucher$: Observable<CreditNotes[]>;

    PromoCodeControl: FormControl = new FormControl();
    VoucherControl: FormControl = new FormControl();

    filteredPromoCode$: Observable<any[]>;
    selectedPromoCode: any | null = null;

    filteredProductOptions$: Observable<products[]>;
    filteredProductBundleOptions$: Observable<any[]>;
    usename: string = '';
    discountPercentageControl = new FormControl(this.discountPercentage, { validators: discountValidator });

    showAddressFields: boolean = false;
    showDeliveryType: boolean = true;
    showSocailLink: boolean = false;
    showEmail: boolean = false;
    showPaymentMethod: boolean = false;
    showShippingArea: boolean = false;
    showAddress: boolean = false;
    showShippinginvalley: boolean = false;
    showShippingOutvalley: boolean = false;
    showCountry: boolean = false;
    shwoShippingInternational: boolean = false;
    showDistrict: boolean = false;
    showOthers: boolean = false;
    showDeliveryDetails: boolean = false;
    showExpectedShippingDate: boolean = true;
    shipping_required = false;
    formattedAddress = '';
    countryControl = new FormControl();
    countries: string[] = contrydata;
    isProductArrayReadOnly = false;
    isFieldReadOnly = false;
    isFieldReadOnly_outside_val = false;
    filteredCountries: Observable<string[]>;
    searchQuery: string;
    searchResults: any[];
    formSubmitted = true;
    productsFormArray: FormArray;
    productVariationsMap: { [productId: string]: any[] } = {};
    selectedSizeColorMap: { [productId: string]: { size: string, color: string } } = {};
    user: User;
    matchedVariationIds: Set<string> = new Set();
    matchedProductBundleIds: Set<string> = new Set();
    configForm: FormGroup;
    matchedProductsFound: boolean;
    errorMessage:string;

    paymentOptions = [
        'CASH ON DELIVERY',
        "SIDDHARTHA BANK FONEPAY - HEADQUARTER",
        "SIDDHARTHA BANK FONEPAY - JHAMSIKHEL",
        "NIC ASIA BANK FONEPAY - HEADQUARTER",
        "NIC ASIA BANK FONEPAY",
    ];




    options: any = {
        componentRestrictions: { country: 'NP' },
        types: { city: 'Kathmandu' }

    };


    constructor(

        private _formBuilder: FormBuilder,
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _productBundleService: productBundleService,
        private dialog: MatDialog,
        private _customerService: ContactsService,
        private _userService: UserService,
        private _productService: productsService,
        private _salesOrderService: SalesOrderService,
        private datePipe: DatePipe,
        private _productVaraitons: productVaraitonService,
        private cd: ChangeDetectorRef,
        private _fuseConfirmationService: FuseConfirmationService,
        private _promoCodeService: PromoCodeService,
        private _creditNoteService: CreditNotesService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,



    ) {

        this._subscription = this._salesOrderService.getSalesOrder_number().subscribe(
            (response: any) => {
                if (response.success && response.data !== undefined) {
                    this.currentSalesOrderNum = response.data;

                    // Now, you can update your form control
                    this.salesOrderForm.controls['sales_order_num'].setValue(`SO-00${this.currentSalesOrderNum}-A`);
                } else {
                    console.error('Invalid response format:', response);
                }
            },
            (error) => {
                console.error('Error fetching sales order numbers:', error);
            }
        );




        this.filteredCountries = this.countryControl.valueChanges.pipe(
            startWith(''),
            map((value) => this._filterCountries(value))
        );



        this.InsidefilteredDistricts = this.districtControl.valueChanges.pipe(
            startWith(''),
            map((value) => this._filterInsideDistricts(value))
        )


        this.customers$ = this._customerService.getContacts().pipe(take(1));

        this.customers$.subscribe((customers) => {
            const data = customers['data'];
        });

        this.promoCode$ = this._promoCodeService.getPromoCodes().pipe(take(1));

        this.promoCode$.subscribe((promoCode) => {
            const data = promoCode['data'];
        });

        this.voucher$ = this._creditNoteService.getCreditNotes().pipe(take(1));

        this.voucher$.subscribe((voucher) => {
            const data = voucher['data'];
        });


        const products$ = this._productVaraitons.getproductVaraiton().pipe(
            take(1),
            map((response) => response['data'])
        );

        products$.subscribe((product) => {
            const data = product['data'];

        });

    }

    ngOnInit() {
        this.fetchData();
        this.loadAllProducts();
        this.configForm = this._formBuilder.group({
            title: 'Info',
            message: '<span class="font-medium">Are you sure you want to proceed?</span> Please be aware that once you remove the product, all the data will be reverted. Are you sure you want to finalize it? Proceed carefully.',
            icon: this._formBuilder.group({
                show: true,
                name: 'heroicons_outline:exclamation',
                color: 'info'
            }),
            actions: this._formBuilder.group({
                confirm: this._formBuilder.group({
                    show: true,
                    label: 'Proceed',
                    color: 'accent'
                }),
                cancel: this._formBuilder.group({
                    show: true,
                    label: 'Cancel',
                    color: 'warn'

                })
            }),
            dismissible: true
        });

        this._userService.user$
            .pipe((takeUntil(this._unsubscribeAll)))
            .subscribe((user: User) => {
                this.user = user;
            });

        this.salesOrderForm = this._formBuilder.group({

            Customers: [this.CustomerControl, Validators.required],
            sales_order_num: [
                `SO-00-A`,
                Validators.required,
            ],
            order_number: [''],
            slaes_order_date: ['', Validators.required],
            sales_order_shippmentDate: [''],
            pos: [''],
            email: [''],
            soical_link: [''],
            delivery_type: [null, this.showDeliveryType ? Validators.required : null],
            shipping_area: [null, this.showShippingArea ? Validators.required : null],
            payment_type: [null, Validators.required],
            payment_terms: [''],
            discountSuffix: ['%'],
            adjustmentSuffix: ['%'],
            district: this.districtControl,
            country: this.countryControl,
            shipping_carrier_invalley: [''],
            shipping_carrier_invalley_others: [''],
            shipping_carrier_outvalley: [''],
            shipping_carrier_int: [''],
            delivery_detail: [''],
            items_rate: [''],
            gender: [''],
            company: [''],
            products: this._formBuilder.array([]),
            address: [''],
            notes: [null],
            socialMedia: [null],
            sub_total: [0],
            total: [0],
            adjustment: [0],
            discount: [0],
            shipping_charges: [0],
            refrences: [''],
            sales_person: [''],
            others: [''],
            tags: [''],
            discountSuffixVoucherApplied: ['%'],// Set default value to percentage and mark as required
            promoCode: [this.PromoCodeControl],
            voucher: [this.VoucherControl],
            others_out: [''],
            delivery_number: [''],
        });



        this.filteredProductOptions$ = of([]);

        const salesOrderDateControl = new FormControl(new Date());

        const currentDate = new Date();

        salesOrderDateControl.setValue(currentDate);

        const datePipe = new DatePipe('en-US');
        const defaultDateValue = datePipe.transform(currentDate, 'yyyy-MM-dd');

        this.salesOrderForm.addControl(
            'slaes_order_date',
            salesOrderDateControl
        );

        this.salesOrderForm.get('slaes_order_date').setValue(defaultDateValue);
        const tomorrow = new Date(currentDate.getTime() + 24 * 60 * 60 * 1000);
        this.salesOrderForm.get('sales_person').patchValue(this.user.username.toLocaleUpperCase());


        (this.salesOrderForm.get('products') as FormArray).clear();

        const initialProductCount = 1;
        const productsArray = this.salesOrderForm.get('products') as FormArray;
        const numberOfProducts = productsArray.length;

        const productArray = this.salesOrderForm.get('products') as FormArray;
        for (let i = 0; i < productArray.length; i++) {
            this.animationFlags[i] = false;
            this.match_stock[i] = false;
            this.filterByBundle[i] = false;
            this.productActive[i] = false;


        };

        this.active = Array(numberOfProducts).fill(false);


        const subscribeToValueChanges = (productGroup: FormGroup) => {
            const rateControl = productGroup.get('rate');
            const quantityControl = productGroup.get('quantity');

            rateControl.valueChanges.subscribe(() => {
                this.calculateTotal();
            });

            quantityControl.valueChanges.subscribe(() => {
                this.calculateTotal();
            });


        };

        this.discountPercentageSubscription = this.discountPercentageControl.valueChanges.subscribe(value => {
            // Update the discountPercentage variable
            this.discountPercentage = value;
            // Call the method to update the applied amount or any other logic
            this.onDiscountChange();
        });

        for (let i = 0; i < initialProductCount; i++) {
            const productGroup = this._formBuilder.group({
                id: [''],
                product_bundle: [''],
                bundle_id: [''],
                product: ['', [Validators.required]],
                quantity: ['', Validators.required],
                rate: ['', Validators.required],
                amount: [''],
                color: [''],
                size: [''],

            });

            subscribeToValueChanges(productGroup);

            (this.salesOrderForm.get('products') as FormArray).push(
                productGroup
            );
        }

        this.salesOrderForm.get('discount').valueChanges.subscribe(() => {
            this.calculateTotal();
        });
        this.salesOrderForm.get('discountSuffix').valueChanges.subscribe(() => {
            this.calculateTotal();
        });



        this.salesOrderForm.get('adjustment').valueChanges.subscribe(() => {
            this.calculateTotal();
        });
        this.salesOrderForm
            .get('adjustmentSuffix')
            .valueChanges.subscribe(() => {
                this.calculateTotal();
            });

        this.salesOrderForm
            .get('shipping_charges')
            .valueChanges.subscribe(() => {
                this.calculateTotal();
            });


        this.matDrawer.openedChange.subscribe((opened) => {
            if (!opened) {
                // Remove the selected contact when drawer closed

                // Mark for check
                this.cd.markForCheck();
            }
        }); closed

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {

                // Set the drawerMode if the given breakpoint is active
                if (matchingAliases.includes('lg')) {
                    this.drawerMode = 'side';
                }
                else {
                    this.drawerMode = 'over';
                }

                // Mark for check
                this.cd.markForCheck();
            });

    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
        this.discountPercentageSubscription.unsubscribe();

    }

    handleAddressChange(address: any) {
        if (!address) { return; }
        const addrName = address.name.split(",");
        const formattedAddr = address.formatted_address.split(",");
        if (formattedAddr.includes(...addrName)) {
            this.formattedAddress = address.formatted_address;
        } else {
            this.formattedAddress = `${address.name}, ${address.formatted_address}`;
        }
        this.salesOrderForm.get('address').patchValue(this.formattedAddress);
    }



    toggleFilterByBundle(index: number): void {
        this.filterByBundle[index] = !this.filterByBundle[index];
    }

    makeFieldEditable(customerInput: HTMLInputElement) {
        this.isFieldReadOnly = false;
        this.selectedCustomer = null;
        if (customerInput) {
            customerInput.value = '';
        }

        // Clear the value of the form control
        this.CustomerControl.setValue('');
    }





    openConfirmationDialog(): Observable<boolean> {
        const dialogRef = this._fuseConfirmationService.open(this.configForm.value);

        return dialogRef.afterClosed().pipe(
            map(result => {
                return result === 'confirmed';
            })
        );
    }

    onInput(event: Event): void {
        const inputElement = event.target as HTMLInputElement;
        inputElement.value = inputElement.value.replace(/[^0-9-]/g, '');
    }

    async makeFieldEditableProduct(index: number) {

        const confirmationResult = await this.openConfirmationDialog().toPromise();
        if (confirmationResult) {

            this.productActive[index] = false;
            const productInput = document.getElementById(`product-input-${index}`) as HTMLInputElement;
            if (productInput) {
                productInput.removeAttribute('readonly'); // Make the input field editable
                productInput.value = ''; // Clear the value of the input field
            }

            const productArray = this.salesOrderForm.get('products') as FormArray;
            const existingFormGroup = productArray.at(index) as FormGroup;

            // Remove the ID from the matchedVariationIds set
            const removedId = existingFormGroup.get('id').value;
            this.matchedVariationIds.delete(removedId);

            // Clear values associated with the deleted product
            this.active.splice(index, 1);
            this.animationFlags.splice(index, 1);
            this.match_stock.splice(index, 1);
            this.current_stock.splice(index, 1);
            this.product_details.splice(index, 1);

            // Clear sizes and colors associated with the deleted product
            this.sizes[index] = [];
            this.colors[index] = [];

            // Remove the productActive flag associated with the deleted product
            this.productActive.splice(index, 1);

            // Remove the form group from the form array
            productArray.removeAt(index);
            this.addProductField();

            this.calculateTotal();
            this.cd.markForCheck();
        } else {
            ""
        }
    }



    makeFieldEditable_outside_val(disctrict: HTMLInputElement) {
        this.isFieldReadOnly_outside_val = false;
        if (disctrict) {
            disctrict.value = ''; // Clear the input value
        }

        // Clear the value of the form control
        this.districtControl.setValue('');
    }
    openCustomerPopup(): void {
        const dialogRef = this.dialog.open(SalesOrderPopupComponent, {
            width: '560px',
        });

        dialogRef.componentInstance.formSubmitted.subscribe((formData) => {
            this._customerService.createCustomer(formData)
                .then(async (response) => {
                    this.selectOption(response.data);
                    this.CustomerControl.markAsUntouched();

                    this.formSubmitted = false;

                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer);
                            toast.addEventListener('mouseleave', Swal.resumeTimer);
                        },
                    });

                    Toast.fire({
                        icon: 'success',
                        title: `Customer created!! `,
                    });

                    await this.fetchData();


                    dialogRef.close();
                    Toast.fire({
                        icon: 'error',
                        title: `Error: Customer not patched correctly`,
                    });

                })
                .catch((error) => {
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer);
                            toast.addEventListener('mouseleave', Swal.resumeTimer);
                        },
                    });

                    Toast.fire({
                        icon: 'error',
                        title: `'Error creating Customer: ' ${error.message};`,
                    });
                });
        });
    }



    // openProductDetails(): void {

    // }

    async createRefund(id: any) {
        const salesOrderNumber = this.salesOrderForm.get('sales_order_num').value;

        // Get today's date
        const todayDate = new Date().toISOString().split('T')[0];

        const refundData = {
            refund_date: todayDate, // Add today's date
            payment_type: `Sales Order Cashed Voucher for Id ${salesOrderNumber}`,
            credit_note: id,
            reference: "SALES ORDER",
            amount: this.appliedAmount,
            total: this.selectedVoucher.total,
        };

        try {
            const response = await this._creditNoteService.createRefund(refundData);
            if (response.success) {
                return response;
            } else {
                throw new Error(response.error); // Throw an error if response indicates failure
            }
        } catch (error) {
            console.error(error); // Log the error
            throw new Error("Error occurred while creating refund"); // Throw an error to handle it in the caller function
        }
    }
    cleanUpProductValidators() {
        const productArray = this.salesOrderForm.get('products') as FormArray;

        // Loop backwards to safely remove items while iterating
        for (let i = productArray.length - 1; i >= 0; i--) {
            const productGroup = productArray.at(i) as FormGroup;

            if (!productGroup.get('product').value) {
                if (productArray.length > 1) {
                    productArray.removeAt(i);
                }
            } else {
                productGroup.get('product').clearValidators();
                productGroup.get('rate').clearValidators();

                productGroup.get('product').updateValueAndValidity();
                productGroup.get('rate').updateValueAndValidity();
            }
        }

        // Ensure that at least one product group is present
        if (productArray.length === 0) {
            this.addProductField();
        }
    }




    async sumbitSalesOrder() {
        this.cleanUpProductValidators();

        let Toast = Swal.mixin({
            toast: true,
            position: 'top', // Set position to 'top'
            customClass: {
                popup: 'swal2-toast-top-center', // Center the toast
            },
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener(
                    'mouseenter',
                    Swal.stopTimer
                );
                toast.addEventListener(
                    'mouseleave',
                    Swal.resumeTimer
                );
            },
        });
        if (this.salesOrderForm.valid) {
            // Show the loading indicator
            const customerData = { ...this.salesOrderForm.value };
            const customer = this.salesOrderForm.get('Customers')?.value;
            customerData.Customers = customer ? customer.value.id : null;
            const promoCode = this.salesOrderForm.get('promoCode')?.value;
            customerData.promoCode = promoCode && promoCode.value && promoCode.value.id ? promoCode.value.id : null;
            const voucher = this.salesOrderForm.get('voucher')?.value;
            customerData.voucher = voucher && voucher.value && voucher.value.id ? voucher.value.id : null;
            // Create refund and wait for it to complete
            if (customerData.voucher !== null) {
                // Create refund and wait for it to complete
                const refundResponse = await this.createRefund(customerData.voucher);

                // Check if refund was successful
                if (refundResponse.success) {
                    Toast.fire({
                        icon: 'success',
                        title: `Voucher has been updated. Thank you`,
                    });

                    customerData.voucher = "";
                } else {
                    return;
                }
            }
            if (customerData.Customers === undefined) {


                Toast.fire({
                    icon: 'warning',
                    title: `Please select valid customer`,
                });
                return
            }
            const products = this.salesOrderForm.get('products') as FormArray;
            const updatedProducts = products.value.map((item: any) => {
                const hasBundleId = !!item.bundle_id; // Check if bundle_id has a value

                return {
                    product: hasBundleId ? '' : item.id, // Set product to an empty string if bundle_id has a value
                    product_bundle: hasBundleId ? item.bundle_id : item.product_bundle, // Set product_bundle to bundle_id if it has a value
                    quantity: item.quantity,
                    rate: item.rate,
                    amount: item.amount,
                };
            });

            customerData.products = updatedProducts;
            Swal.fire({
                title: "Loading",
                html: `
                    <div style="display: flex; flex-direction: column; align-items: center;">
                        <img src="assets/loading.gif" style="width: auto; height: auto;" alt="loading"/>
                        <p style="margin: 10px 0 0 0; font-size: 14px; color: #666;">Creating, please wait...</p>
                    </div>
                `,
                timerProgressBar: true,
                didOpen: () => {
                    Swal.showLoading();
                },
            });

            // Update the contact on the server
            this._salesOrderService
                .createSalesOrders(customerData)
                .then((response) => {
                    if (response.success) {
                        const data = response.data
                        const Toast = Swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener(
                                    'mouseenter',
                                    Swal.stopTimer
                                );
                                toast.addEventListener(
                                    'mouseleave',
                                    Swal.resumeTimer
                                );
                            },
                        });

                        Toast.fire({
                            icon: 'success',
                            title: `${customerData.sales_order_num} Sales Order created!!`,
                        });

                        this.salesOrderForm.reset();
                        if (data.pos === 'Store Vist') {
                            this._router.navigate(['apps/sales-logs'])
                        } else {
                            this._router.navigate(['../'], {
                                relativeTo: this._activatedRoute,
                            });
                        }


                    } else {
                        Swal.close();

                        console.log(response, response.erros)
                        const errorMessages = response.errors;

                        const Toast = Swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener(
                                    'mouseenter',
                                    Swal.stopTimer
                                );
                                toast.addEventListener(
                                    'mouseleave',
                                    Swal.resumeTimer
                                );
                            },
                        });

                        Toast.fire({
                            icon: 'error',
                            title: 'Error creating Sales Order ',
                            text: JSON.stringify(errorMessages),
                        });
                    }
                })
                .catch((error) => {
                    // Hide the loading indicator
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener(
                                'mouseenter',
                                Swal.stopTimer
                            );
                            toast.addEventListener(
                                'mouseleave',
                                Swal.resumeTimer
                            );
                        },
                    });

                    Toast.fire({
                        icon: 'error',
                        title: `Error creating Sales Order: ${error.message}`,
                    });
                });
        } else {
            Swal.close();

            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer);
                    toast.addEventListener('mouseleave', Swal.resumeTimer);
                },
            });

            Toast.fire({
                icon: 'error',
                title: 'Invalid form data',
                text: 'Please fill in all the required fields correctly.',
            });
        }
    }

    toggleDropdown() {
        const dropdownMenu = document.getElementById('dropdown-menu');
        if (dropdownMenu) {
            dropdownMenu.classList.toggle('show');
        }
    }



    saveAsDraft() {
    }

    saveAndPrint() {
    }

    hasUnsavedChanges(): boolean {
        return this.salesOrderForm.dirty;
    }

    canDeactivate(): boolean {
        return !this.salesOrderForm.dirty || confirm('You have unsaved changes. Are you sure you want to leave?');
    }



    // Your common utility function to check if data is an array
    isArrayData(data: any): data is any[] {
        return Array.isArray(data);
    }


    // Assuming you have a class with SalesOrderForm and ProductService and ProductBundleService instances


    filterProductBundle(value: any): void {
        if (typeof value !== 'string') {
            this.filteredProductBundleOptions$ = of([]);
            return;
        }

        this._productBundleService.getproductsFilter().pipe(
            switchMap((response) => {
                const bundles = response['data'];

                if (!Array.isArray(bundles) || bundles.length === 0) {
                    return of([]);
                }

                const filteredBundles = bundles.map((bundle) => {

                    return {
                        id: bundle.id,
                        name: bundle.bundle_name,
                        products: bundle.products,
                        description: bundle.bundle_description,

                    };
                });

                return of(filteredBundles);
            })
        ).subscribe((filteredBundles) => {
            this.filteredProductBundleOptions$ = of(filteredBundles);
        });
    }



    getVendorPlaceholder(): string {
        return this.vendorsCount > 0
            ? 'Select a Customer'
            : 'No Customer available';
    }
    onSalesOrderDateChange(event: any) {
        const selectedDate: Date = event.value;

        const formattedDate = this.datePipe.transform(selectedDate, 'yyyy-MM-dd');

        this.salesOrderForm.get('slaes_order_date').setValue(formattedDate);

    }
    /**
      * Track by function for ngFor loops
      *
      * @param index
      * @param item
      */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    // Helper method to check if a name includes at least one part of the filter values
    private includesAnyPart(name: string, filterValues: Set<string>): boolean {
        for (let filterValue of filterValues) {
            if (name.includes(filterValue)) {
                return true;
            }
        }
        return false;
    }

    private matchesFilter(customer: Contact, filterValues: Set<string>): boolean {
        const trimmedFirstName = customer.firstName?.trim().toLowerCase();
        const trimmedLastName = customer.lastName?.trim().toLowerCase();
        const idWithPrefix = `hub${customer.id}`.toLowerCase(); // Ensure 'hub' is lowercase

        return (
            (trimmedFirstName && this.includesAnyPart(trimmedFirstName, filterValues)) ||
            (trimmedLastName && this.includesAnyPart(trimmedLastName, filterValues)) ||
            filterValues.has(idWithPrefix)
        );
    }





  
    filterPromoCode(value: string): Observable<any[]> {
        if (typeof value !== 'string') {
            return of([]); // Return an empty array if value is not a string
        }

        const filterValues = new Set(value.trim().toLowerCase().split(' '));

        return this.promoCode$.pipe(
            map((promoCodes) => {
                if (Array.isArray(promoCodes['data'])) {
                    const currentDate = new Date();
                    return promoCodes['data'].filter((promoCode) =>
                        this.matchesFilterPromo(promoCode, filterValues)
                    );
                } else {
                    console.error('Promo codes data is not available:', promoCodes);
                    return [];
                }
            })
        );
    }

    filterAndFetchVoucher(value: string): Observable<any[]> {
        if (typeof value !== 'string') {
            return of([]); // Return an empty array if value is not a string
        }

        const filterValues = new Set(value.trim().toLowerCase().split(' '));

        return this.voucher$.pipe(
            map((voucher) => {
                if (Array.isArray(voucher['data'])) {
                    return voucher['data'].map((voucherItem) => {
                        const isDisabled = voucherItem.total <= 0;
                        const customerMatch = voucherItem.Customers.id !== this.selectedCustomer.id;
                        const matchesFilter = this.matchesFilterVoucher(voucherItem, filterValues);
                        const shouldDisable = isDisabled || !matchesFilter || customerMatch;

                        return {
                            ...voucherItem,
                            isDisabled: shouldDisable
                        };
                    });
                } else {
                    console.error('Voucher codes data is not available:', voucher);
                    return [];
                }
            })
        );
    }
   

    validCustomerValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const selectedCustomer: Contact = control.value;

            if (!selectedCustomer) {
                return { 'required': true };
            }

            if (!selectedCustomer.id) {
                return { 'customerNotSelected': true };
            }

            return null;
        };
    }
    private matchesFilterPromo(promo: any, filterValues: Set<string>): boolean {
        const trimmedName = promo.name?.trim().toLowerCase();

        return (
            trimmedName && this.includesAnyPart(trimmedName, filterValues)
        );
    }

    private matchesFilterVoucher(voucher: any, filterValues: Set<string>): boolean {
        const trimmedVoucherOrderNum = voucher.voucher_order_num?.trim().toLowerCase();

        return (
            trimmedVoucherOrderNum && this.includesAnyPart(trimmedVoucherOrderNum, filterValues)
        );
    }

    filterOptions(value: string): Observable<Contact[]> {
        if (typeof value !== 'string') {
            return of([]); // Return an empty array if value is not a string
        }
        this.formSubmitted = true;

        const filterValues = new Set(value.trim().toLowerCase().split(' '));

        return this.customers$.pipe(
            map((customers) => {
                if (Array.isArray(customers['data'])) {
                    return customers['data'].filter((customer) =>
                        this.matchesFilter(customer, filterValues)
                    );
                } else {
                    console.error('Customers data is not available:', customers);
                    return [];
                }
            })
        );
    }
    displayOption(customer: Contact): string {
        const last_name = customer?.lastName || '';
        const first_name = customer?.firstName || '';
        const id = customer?.id || '';

        // Construct the display string based on the available values
        if (first_name && last_name) {
            return id ? `${first_name} ${last_name} (HUB${id})` : `${first_name} ${last_name}`;
        } else if (first_name) {
            return id ? `${first_name} (HUB${id})` : first_name;
        } else if (last_name) {
            return id ? `${last_name} (HUB${id})` : last_name;
        } else {
            return id ? `(HUB${id})` : '';
        }
    }


    selectOption(option: Contact) {
        if (!option) {
            return;
        }
        this.CustomerControl.setValue(option);
        this.CustomerControl.updateValueAndValidity();
        this.selectedCustomer = option;
        this.isFieldReadOnly = true;

    }




    // Method to check if a customer is selected
    isCustomerSelected(selectedCustomer: Contact): boolean {
        return this.CustomerControl.value && selectedCustomer && this.CustomerControl.value.id === selectedCustomer.id;
    }




    // Map the customer object to CustomerOption
    genderOptions(promcode: any): any[] {
        if (promcode) {
            const mappedPromo: any = {
                id: promcode.id,
                name: promcode.name || '',
            };
            return [mappedPromo];
        }
        return [];
    }

    // Map the customer object to CustomerOption
    genderOption(voucher: any): any[] {
        if (voucher) {
            const mappedVoucher: any = {
                id: voucher.id,
                name: voucher.voucher_order_num || '',
            };
            return [mappedVoucher];
        }
        return [];
    }
    selectOptionVoucher(option: any) {
        const total = this.salesOrderForm.get('total').value;

        if (!this.selectedCustomer) {
            this.showErrorMessage('Please select a customer first.');
            return;
        }

        if (total <= 0) {
            this.showErrorMessage('There are no products selected.');
            return;
        }

        if (option.Customers.id !== this.selectedCustomer.id) {
            this.showErrorMessage('The selected voucher will not work for this customer.');
            return;
        }

        this.VoucherControl.setValue(option);
        this.VoucherControl.updateValueAndValidity();
        this.selectedVoucher = option;
        this.isFieldReadOnlyVoucher = true;
        this.initialTotal = total;

        this.calculateTotal();
    }
    onDiscountChange() {
        const discountSuffix = this.salesOrderForm.get('discountSuffixVoucherApplied').value;
        const billTotal = this.salesOrderForm.get('total').value;
        const realTotal = this.initialTotal;
    
        let discountAmount = 0;
    
        if (discountSuffix === '%') {
            discountAmount = (this.discountPercentageControl.value / 100) * realTotal;
        } else if (discountSuffix === 'rs') {
            discountAmount = this.discountPercentageControl.value;
        }
    
        if (discountAmount > 0 && discountAmount <= realTotal && discountAmount <= billTotal) {
            const newTotal = realTotal - discountAmount;
            this.salesOrderForm.get('total').setValue(newTotal);
            this.appliedAmount = discountAmount;
            this.salesOrderForm.get('voucher').setErrors(null); // Clear any previous error
        } else {
            // Reset the discount and total to the original if the discount is invalid
            this.salesOrderForm.get('total').setValue(realTotal);
            this.appliedAmount = 0;
            this.salesOrderForm.get('voucher').setErrors({ invalidDiscount: true }); // Set validation error
        }
    }
    


    private showErrorMessage(message: string) {
        let Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener(
                    'mouseenter',
                    Swal.stopTimer
                );
                toast.addEventListener(
                    'mouseleave',
                    Swal.resumeTimer
                );
            },
        });

        Toast.fire({
            icon: 'error',
            title: message,
        });
    }

    displayOptionP(promcode: any): string {
        const name = promcode?.name || '';
        return name;

    }


    displayOptionV(voucher: any): string {
        const name = voucher?.voucher_order_num || '';
        return name;

    }

    selectOptionP(option: any) {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
            },
        });

        const productArray = this.salesOrderForm.get('products') as FormArray;
        this.PromoCodeControl.setValue(option);
        this.PromoCodeControl.updateValueAndValidity();
        this.selectedPromoCode = option;
        this.isFieldReadOnlyPromo = true;
        this.saveInitialRates();
        this.matchedProductsFound = false;

        if (this.selectedPromoCode && this.selectedPromoCode.products && this.selectedPromoCode.products.length > 0) {
            productArray.controls.forEach((control: FormGroup) => {
                const productId = control.get('id').value;
                if (this.selectedPromoCode.products.some((product: any) => product.product.id === productId)) {
                    this.matchedProductsFound = true;
                    this.applyPromoCodeDiscount(control, this.selectedPromoCode.discount, this.selectedPromoCode.discount_alias);
                }
            });

            if (!this.matchedProductsFound) {
                this.PromoCodeControl.reset();
                this.PromoCodeControl.updateValueAndValidity();
                this.selectedPromoCode = null;
                this.isFieldReadOnlyPromo = false;
                this.restoreInitialRates();
                Toast.fire({
                    icon: 'error',
                    title: 'The selected promo code doesn\'t work with any product in the form.',
                });
            } else {
                this.calculateTotal(); // Trigger total calculation
            }
        }
    }


    makePromoCodeEditable(promoCodeInput: HTMLInputElement) {
        this.isFieldReadOnlyPromo = false;
        this.selectedPromoCode = null;
        if (promoCodeInput) {
            promoCodeInput.value = '';
        }

        // Clear the value of the form control
        this.PromoCodeControl.setValue('');
        this.isFieldReadOnlyPromo = false;

        // Restore initial rates when Promo Code becomes editable
        this.restoreInitialRates();
    }



    makeVoucherEditable(VoucherCodeInput: HTMLInputElement) {
        this.isFieldReadOnlyVoucher = false;
        this.selectedVoucher = null;
        if (VoucherCodeInput) {
            VoucherCodeInput.value = '';
        }

        // Clear the value of the form control
        this.VoucherControl.setValue('');
        this.isFieldReadOnlyVoucher = false;

        // Restore initial rates when Promo Code becomes editable
        if (this.initialTotal !== null) {
            this.salesOrderForm.get('total').setValue(this.initialTotal);
        }
    }


    isPromoSelected(selectedPromo: any): boolean {
        return this.PromoCodeControl.value && selectedPromo && this.PromoCodeControl.value.id === selectedPromo.id;
    }

    isVoucherSelected(selectedVoucher: any): boolean {
        return this.VoucherControl.value && selectedVoucher && this.VoucherControl.value.id === selectedVoucher.id;
    }
    saveInitialRates() {
        const productArray = this.salesOrderForm.get('products') as FormArray;
        this.initialRates = {};
        productArray.controls.forEach((control: FormGroup) => {
            const productId = control.get('id').value;
            this.initialRates[productId] = control.get('rate').value;
        });
    }

    restoreInitialRates() {
        const productArray = this.salesOrderForm.get('products') as FormArray;
        productArray.controls.forEach((control: FormGroup) => {
            const productId = control.get('id').value;
            control.get('rate').setValue(this.initialRates[productId]);
        });
    }




    // Map the customer object to CustomerOption
    genderOptionss(customer: Contact): CustomerOption[] {
        if (customer) {
            const mappedCustomer: CustomerOption = {
                id: customer.id,
                firstname: customer.firstName || '',
            };
            return [mappedCustomer];
        }
        return [];
    }

    addProductField(): void {
        const productFormGroup = this._formBuilder.group({
            id: [''],
            bundle_product: [''],
            bundle_id: [''],
            product: ['', [Validators.required]],
            quantity: ['', Validators.required],
            rate: ['', Validators.required],
            amount: [''],
            color: [''],
            size: [''],
        });

        const productArray = this.salesOrderForm.get('products') as FormArray;
        productArray.push(productFormGroup);

        const index = productArray.length - 1;
        this.setupProductFieldSubscriptions(productFormGroup, index);

        this.calculateTotal();
    }



    removeProductField(index: number): void {
        const productArray = this.salesOrderForm.get('products') as FormArray;
        const existingFormGroup = productArray.at(index) as FormGroup;

        // Remove the ID from the matchedVariationIds set
        const removedId = existingFormGroup.get('id').value;
        this.matchedVariationIds.delete(removedId);

        // Remove the values associated with the deleted product
        this.active.splice(index, 1);
        this.animationFlags.splice(index, 1);
        this.match_stock.splice(index, 1);
        this.current_stock.splice(index, 1);
        this.product_details.splice(index, 1);

        // Remove sizes and colors associated with the deleted product
        this.sizes.splice(index, 1);
        this.colors.splice(index, 1);

        // Remove the productActive flag associated with the deleted product
        this.productActive.splice(index, 1);

        // Remove the form group from the form array
        productArray.removeAt(index);

        this.calculateTotal();
        this.cd.markForCheck();
    }



    setupProductFieldSubscriptions(
        productGroup: FormGroup,
        index: number
    ): void {
        const quantityControl = productGroup.get('quantity');
        const rateControl = productGroup.get('rate');

        quantityControl.valueChanges.subscribe(() => {
            this.calculateTotal();
        });

        rateControl.valueChanges.subscribe(() => {
            this.calculateTotal();
        });


    }

    calculateTotal(): void {
        const productArray = this.salesOrderForm.get('products') as FormArray;

        let subTotal = 0;
        productArray.controls.forEach((control: FormGroup) => {
            const quantity = control.get('quantity').value;
            const rate = control.get('rate').value;

            const amount = (quantity || 0) * (rate || 0);
            control.get('amount').setValue(amount);

            subTotal += amount || 0;
        });

        this.salesOrderForm.get('sub_total').setValue(subTotal);
        const discountSuffix = this.salesOrderForm.get('discountSuffix').value;
        const adjustmentSuffix =
            this.salesOrderForm.get('adjustmentSuffix').value;
        const discount = +this.salesOrderForm.get('discount').value || 0;

        const adjustment = +this.salesOrderForm.get('adjustment').value || 0;

        const shipping = +this.salesOrderForm.get('shipping_charges').value || 0;



        let calculatd_shipping = shipping
        let calculatedDiscount = discount;
        let calculatedAdjustment = adjustment;

        if (discountSuffix === '%') {
            calculatedDiscount = (subTotal * discount) / 100;
        } else if (discountSuffix === 'rs') {
            calculatedDiscount = discount;

        }

        if (adjustmentSuffix === '%') {
            calculatedAdjustment = (subTotal * adjustment) / 100;

        } else if (adjustmentSuffix === 'rs') {
            calculatedAdjustment = adjustment;

        }

        if (adjustmentSuffix == '%' && discountSuffix == '%') {
            calculatedDiscount = (subTotal * discount) / 100;
            const newTotal = subTotal - calculatedDiscount;

            calculatedAdjustment = (newTotal * adjustment) / 100;
        }

        const totalBeforeShipping = subTotal - calculatedDiscount + calculatedAdjustment;
        const total = totalBeforeShipping + calculatd_shipping;


        this.salesOrderForm.get('total').setValue(total);


    }


    applyPromoCodeDiscount(control: FormGroup, discount: number, discountAlias: string) {
        let rate = control.get('rate').value;
        if (discountAlias === '%') {
            rate -= (rate * discount) / 100;
        } else if (discountAlias === 'rs') {
            rate -= discount;
        }
        control.get('rate').setValue(rate);
    }


    toggleInputBack(): void {
        this.salesOrderForm.get('shipping_carrier_int').setValue('ARAMEX');
    }



    private loadAllProducts(): void {
        this._productService.getproducts().pipe(
            take(1),
            switchMap((products) => {
                if (!Array.isArray(products['data'])) {
                    return of([]);
                }

                const productObservables = products['data'].map((product) => {
                    return this._productService.getProductVariations(product.id).pipe(
                        map((variations) => ({
                            product,
                            variations: variations.data,
                        }))
                    );
                });

                return forkJoin(productObservables);
            }),
            map((productsWithVariations) => {
                this.allProducts = productsWithVariations.map(({ product, variations }) => {
                    const productId = product.id;

                    // Determine if all variations are disabled
                    const allDisabled = variations.every((variation) => {
                        // Check if the variation should be considered disabled
                        const isNotActive = !variation.active;
                        const isPreOrder = variation.preOrder;
                        const isStock = variation.stock === 0;
                        const isSelected = this.matchedVariationIds.has(variation.id);
                        return isNotActive || isPreOrder || isStock || isSelected;
                    });

                    // Mark the product as disabled if all variations are disabled
                    const disabled = allDisabled || variations.length === 0;

                    return {
                        product: productId,
                        id: productId,
                        productName: product.productName,
                        sku: product.sku,
                        basePrice: product.basePrice,
                        size: product.size,
                        color: product.color,
                        description: product.description,
                        disabled: disabled,
                    };
                });

                // Initially set the filtered options to all products
                this.filteredProductOptions$ = of(this.allProducts);
            })
        ).subscribe();
    }



    filterOptionsProduct(value: string): void {
        this.searchTerms.next(value);
    }

    private filterLocalProducts(value: string): Observable<any[]> {
        if (typeof value !== 'string' || !value.trim()) {
            return of(this.allProducts); // Return all products if the value is empty
        }

        const filteredProducts = this.allProducts.filter((filteredProduct) =>
            filteredProduct.productName.toLowerCase().includes(value.toLowerCase()) ||
            (filteredProduct.sku && filteredProduct.sku.toString().toLowerCase().includes(value.toLowerCase()))
        );

        return of(filteredProducts);
    }


    filtersOptionsProduct(productId: string, index: number): void {
        this._productService.getProductVariations(productId).subscribe((variations) => {
            this.mappedVariations = variations.data
                .map((variation) => ({
                    id: variation.id,
                    size: variation.size,
                    color: variation.color,
                    price: variation.basePrice,
                    base_product: productId,
                    stock: variation.stock,
                    preOrder: variation.preOrder,
                    active: variation.active,
                    description: variation.base_product.description,
                    category: variation.base_product.category,
                    gender: variation.base_product.gender,
                }));

            const sizeToColorMap = {};
            const allColorsDisabledMap = {};

            this.mappedVariations.forEach((variation) => {
                const { size, color, id, active, preOrder, stock } = variation;

                if (!sizeToColorMap[size]) {
                    sizeToColorMap[size] = [];
                }

                const isNotActive = !active;
                const isPreOrder = preOrder;
                const isStock = stock == 0

                const isSelected = this.matchedVariationIds.has(id);
                const isOutOfStock = isNotActive || isSelected || (isPreOrder === true) || isStock;
                const style = isOutOfStock ? { color: 'red' } : {};
                const disabled = isOutOfStock;

                sizeToColorMap[size].push({
                    id,
                    color,
                    style,
                    disabled,
                });

                allColorsDisabledMap[color] = allColorsDisabledMap[color] || {};
                allColorsDisabledMap[color][size] = disabled;
            });

            const sizes = Object.keys(sizeToColorMap).map((size) => {
                const colors = sizeToColorMap[size] || [];

                // Check if all colors for this size are disabled
                const disabled = colors.every((color) => allColorsDisabledMap[color.color]?.[size]);

                // console.log('Disabled:', disabled);
                return {
                    id: size,
                    name: size,
                    disabled,
                };
            });

            const firstSize = sizes[0]?.id;
            const colors =
                sizeToColorMap[firstSize]?.map((color) => ({
                    id: color.color,
                    name: color.color,
                    style: color.style,
                    disabled: allColorsDisabledMap[color.color]?.[firstSize],
                })) || [];

            this.updateSizesAndColors(index, sizes, colors);
        });
    }

    updateSizesAndColors(index: number, sizes: any[], colors: any[]): void {
        this.sizes[index] = sizes;
        this.colors[index] = colors;
    }


    quantityExceedsStock(index: number): boolean {
        const productArray = this.salesOrderForm.get('products') as FormArray;
        const existingFormGroup = productArray.at(index) as FormGroup;
        const quantityControl = existingFormGroup.get('quantity');

        if (quantityControl.invalid) {
            return false;
        }

        const quantityValue = Number(quantityControl.value);
        const currentStockValue = Number(this.current_stock[index]);

        const exceedsStock = quantityValue > currentStockValue;

        if (exceedsStock) {
            quantityControl.setErrors({ 'exceedsStock': true });
        } else {
            quantityControl.setErrors(null);
        }

        return exceedsStock;
    }


    hasProductsInMainForm(): boolean {
        const productArray = this.salesOrderForm.get('products') as FormArray;

        // Check if there are any products in the form array
        if (productArray.length === 0) {

            return false;
        }

        // Check if any product has the required fields filled
        for (let i = 0; i < productArray.length; i++) {
            const productFormGroup = productArray.at(i) as FormGroup;

            // Check if the product has the required fields filled (e.g., rate and size)
            const rateControl = productFormGroup.get('rate');

            // If any product doesn't have the required fields filled or the values are zero, disable the promo code
            if (!rateControl || !rateControl.value || rateControl.value === 0) {
                this.disableReadOnlyPromo = true;


                return false;
            }
        }

        this.disableReadOnlyPromo = false;
        return this.disableReadOnlyPromo;
    }






    onSelectionChange(index: number): void {
        const productArray = this.salesOrderForm.get('products') as FormArray;
        const existingFormGroup = productArray.at(index) as FormGroup;

        if (existingFormGroup) {
            const selectedProductId = existingFormGroup.get('product').value.id;
            const selectedSizeId = existingFormGroup.get('size').value;
            this.colors[index]?.forEach((color) => {
                color.disabled = false;
            });

            this.mappedVariations.forEach((variation) => {
                const { size, color, id, active, stock, preOrder } = variation;

                if (size === selectedSizeId) {
                    const colorToDisable = this.colors[index]?.find(c => c.name === color);

                    if (colorToDisable) {
                        colorToDisable.disabled = !active || stock == 0 || (preOrder === true) || this.matchedVariationIds.has(id);
                    }
                }
            });

            const selectedColorId = existingFormGroup.get('color').value;

            const selectedSizeColorCombo = `${selectedSizeId}${selectedColorId}`;
            const matchingOriginalVariation = this.mappedVariations?.find(variation => {
                const variationSizeColorCombo = `${variation.size}${variation.color}`;
                return variationSizeColorCombo === selectedSizeColorCombo;
            });

            if (matchingOriginalVariation) {
                if (matchingOriginalVariation.stock != 0) {
                    if (!this.matchedVariationIds.has(matchingOriginalVariation.id)) {
                        existingFormGroup.get('rate').patchValue(matchingOriginalVariation.price);
                        existingFormGroup.get('size').patchValue(matchingOriginalVariation.size);
                        existingFormGroup.get('color').patchValue(matchingOriginalVariation.color);
                        existingFormGroup.get('id').patchValue(matchingOriginalVariation.id);
                        this.active[index] = true;
                        this.current_stock[index] = matchingOriginalVariation.stock;

                        this.product_details[index] = matchingOriginalVariation.description;
                        this.animationFlags[index] = true;

                        this.matchedVariationIds.add(matchingOriginalVariation.id);

                        this.disableColorsForSelectedSize(index, selectedSizeId);
                        this.addProductField();


                        setTimeout(() => {
                            this.animationFlags[index] = false;
                        }, 1000);

                        // console.log("Matching variation found for the selected size and color:", matchingOriginalVariation);
                    } else {
                    }
                } else {
                }
            } else {
                // console.log("No matching original variation found for the selected size and color.");
            }
        }
    }

    disableColorsForSelectedSize(index: number, selectedSizeId: string): void {
        this.colors[index].forEach((color) => {
            color.disabled = color.id !== selectedSizeId;
        });

        this.updateSizesAndColors(index, this.sizes[index], this.colors[index]);
    }


    selectOptionProduct(option: products, index: number): void {




        if (option && !option.isOutOfStock) {
            const productFormGroup = this._formBuilder.group({
                id: [''],
                product: [option, [Validators.required]],
                quantity: [''],
                product_bundle: [''],
                bundle_id: [''],
                rate: [option.basePrice, ''],
                amount: [''],
                size: [],
                color: [],
            });
            this.productActive[index] = true;

            this.filtersOptionsProduct(option.id, index)


            const productArray = this.salesOrderForm.get('products') as FormArray;

        }
    }




    selectOptionProductBundle(option: productBundle, index: number): void {


        const selectedProducts = option.products.map(product => {
            return {
                product: product,

            };
        });
        // console.log(selectedProducts)
        const dialogRef = this.dialog.open(ProductBundlePopupComponent, {
            width: '3000px',
            height: '750px',
            data: {
                selectedProducts: selectedProducts
            }
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result && result.total && result.otherData && result.otherData.products && Array.isArray(result.otherData.products)) {
                // console.log("total", result.total);



                const sizes = result.otherData.products.map((product) => product.size);
                const colors = result.otherData.products.map((product) => product.color);
                const name = result.otherData.products.map((product) => product.product);
                const products = result.otherData.products;
                const productDetails = products.map(product => ({
                    name: product.product.productName,
                    size: product.size,
                    color: product.color,
                    rate: product.rate,
                    quantity: product.quantity,
                }));
                this.name[index] = productDetails;


                const quantities = result.otherData.products.map((product) => product.quantity);

                this.quantity[index] = quantities;

                // console.log(sizes, colors, quantities);

                // console.log(option)
                const productFormGroup = this._formBuilder.group({
                    id: [''],
                    bundle_id: [option.id],
                    product: [option, [Validators.required]],
                    product_bundle: [option],
                    quantity: [''],
                    rate: [result.total, ''],
                    amount: [''],
                    size: [sizes.join(', ')],
                    color: [colors.join(', ')],
                });
                const productArray = this.salesOrderForm.get(
                    'products'
                ) as FormArray;
                const existingFormGroup = productArray.at(index) as FormGroup;

                if (existingFormGroup) {
                    existingFormGroup.patchValue(productFormGroup.value);

                }
                this.active[index] = true;
                this.animationFlags[index] = true;

                this.matchedProductBundleIds.add(option.id);

                this.cd.detectChanges();
            } else {
                console.error("Invalid data structure or missing required properties");
            }
        });





    }


    displayProductOption(product: products): string {
        if (product) {

            const productName = product.productName || '';
            const productPrice = product.sku
                ? ' - ' + product.sku.toString()
                : '';

            return productName + productPrice;

        }

        return '';
    }


    displayProductBundleOption(product: any): string {
        if (product) {
            // console.log(product)
            const productName = product.name || '';
            const productPrice = product.description
                ? ' - ' + product.description.toString()
                : '';

            return productName + productPrice;

        }

        return '';
    }



    getFormFieldHelpersAsString(): string {
        return this.formFieldHelpers.join(' ');
    }
    pointOfsales(): void {
        const type = this.salesOrderForm.controls['pos'].value;

        // Reset all flags initially
        this.showEmail = false;
        this.showSocailLink = false;
        this.showPaymentMethod = false;
        this.showShippingArea = false;


        // Remove all validators initially
        this.salesOrderForm.get('shipping_area').clearValidators();
        this.salesOrderForm.get('delivery_type').clearValidators();

        // Update the flags and validators based on the selected type
        if (type === 'Instagram' || type === 'Website' || type === 'Facebook') {
            this.showEmail = true;
            this.showPaymentMethod = true;
            this.showShippingArea = true;

            this.salesOrderForm.get('shipping_area').setValidators(Validators.required);
        } else if (type === 'Store Vist') {
            this.showSocailLink = true;
            this.showPaymentMethod = true;

            this.paymentOptions = [
                'CASH',
                'ASHIM ESEWA',
                'DANISH ESEWA',
                'HUBA NIC ASIA',
                'ASHIM SIDDHARTA BANK'
            ];

            // You may add validators for other fields as needed, e.g., shipping_area, delivery_type
        } else {
            this.showEmail = true;
            this.showSocailLink = true;
        }

        // Update the validity of the controls
        this.salesOrderForm.get('shipping_area').updateValueAndValidity();
        this.salesOrderForm.get('delivery_type').updateValueAndValidity();
    }

    addressField(): void {
        const type = this.salesOrderForm.controls['shipping_area'].value;

        // Reset all flags initially
        this.showAddress = false;
        this.showShippinginvalley = false;
        this.showDistrict = false;
        this.showShippingOutvalley = false;
        this.showCountry = false;
        this.shwoShippingInternational = false;
        this.showDeliveryDetails = false;
        this.shipping_required = false;

        // Remove all validators initially
        this.salesOrderForm.get('shipping_charges').clearValidators();
        this.salesOrderForm.get('district').clearValidators();
        this.salesOrderForm.get('country').clearValidators();
        this.salesOrderForm.get('shipping_carrier_invalley').clearValidators();
        this.salesOrderForm.get('shipping_carrier_outvalley').clearValidators();
        this.salesOrderForm.get('shipping_carrier_int').clearValidators();
        this.salesOrderForm.get('delivery_detail').clearValidators();

        // Update the flags and validators based on the selected type
        if (type === 'Inside Valley') {
            this.showAddress = true;
            this.showShippinginvalley = true;
            this.showDistrict = true;

            this.salesOrderForm.get('district').setValidators(Validators.required);
            this.salesOrderForm.get('shipping_carrier_invalley').setValidators(Validators.required);
        } else if (type === 'Outside Valley') {
            const currentShippingCharges = this.salesOrderForm.get('shipping_charges').value || 0;
            this.salesOrderForm.get('shipping_charges').patchValue(currentShippingCharges + 100);
            this.salesOrderForm.get('shipping_charges').setValidators(Validators.required);
            this.shipping_required = true;

            this.showAddress = true;
            this.showDistrict = true;
            this.showShippingOutvalley = true;

            this.salesOrderForm.get('district').setValidators(Validators.required);
            this.salesOrderForm.get('shipping_carrier_outvalley').setValidators(Validators.required);
        } else if (type === 'International') {
            this.salesOrderForm.get('shipping_charges').patchValue(0);
            this.shipping_required = true;

            this.showAddress = true;
            this.showCountry = true;
            this.shwoShippingInternational = true;
            this.showDeliveryDetails = true;

            this.salesOrderForm.get('country').setValidators(Validators.required);
            this.salesOrderForm.get('shipping_carrier_int').setValidators(Validators.required);
            this.salesOrderForm.get('delivery_detail').setValidators(Validators.required);
        }

        // Update the validity of the controls
        this.salesOrderForm.get('shipping_charges').updateValueAndValidity();
        this.salesOrderForm.get('district').updateValueAndValidity();
        this.salesOrderForm.get('country').updateValueAndValidity();
        this.salesOrderForm.get('shipping_carrier_invalley').updateValueAndValidity();
        this.salesOrderForm.get('shipping_carrier_outvalley').updateValueAndValidity();
        this.salesOrderForm.get('shipping_carrier_int').updateValueAndValidity();
        this.salesOrderForm.get('delivery_detail').updateValueAndValidity();

        // Trigger change detection if necessary
        this.cd.detectChanges();
    }

    showOtherss(): void {
        const type = this.salesOrderForm.controls['shipping_carrier_int'].value;
        if (type == 'others') {
            this.showOthers = true;
        } else {
            this.showOthers = false;
        }
    }

    private _filterCountries(value: string) {
        const filterValue = value.toLowerCase();
        return contrydata.filter((country) =>
            country.toLowerCase().includes(filterValue)
        );
    }

    private _filterInsideDistricts(value: string) {
        const filterValue = value.toLowerCase();
        return insideValleyData.filter((district) =>
            district.toLowerCase().includes(filterValue)
        );
    }


    formatDate(date: Date, format: string, controlName: string) {
        const formattedDate = this.datePipe.transform(date, format);
        this.salesOrderForm.get(controlName)?.setValue(formattedDate);
    }


    async fetchData() {
        try {

            this.searchTerms.pipe(
                debounceTime(300),
                distinctUntilChanged(),
                switchMap((value) => this.filterLocalProducts(value))
            ).subscribe((filteredProducts) => {
                this.filteredProductOptions$ = of(filteredProducts);
            });

            this.filteredVoucher$ = this.VoucherControl.valueChanges.pipe(
                startWith(''),
                debounceTime(300),
                switchMap((value) => this.filterAndFetchVoucher(value))
            );


            this.filteredDistricts = this.districtControl.valueChanges.pipe(
                startWith(''),
                map((value) => this._filterDistricts(value))
            );

            this.filteredCustomers$ = this.CustomerControl.valueChanges.pipe(
                startWith(''),
                debounceTime(300),
                switchMap((value) => this.filterOptions(value))
            );

            this.filteredPromoCode$ = this.PromoCodeControl.valueChanges.pipe(
                startWith(''),
                debounceTime(300),
                switchMap((value) => this.filterPromoCode(value))
            );


            const districtNames = await this._salesOrderService.fetchData();
            this.districts = districtNames.sort();

        } catch (error) {
        }
    }

    private _filterDistricts(value: string): string[] {
        const filterValue = value.toLowerCase();
        return this.districts.filter((district) =>
            district.toLowerCase().includes(filterValue)
        );
    }
    onDistrictSelected(event: MatAutocompleteSelectedEvent): void {
        this.isFieldReadOnly_outside_val = true;
    }


    toggleOtherInput(): void {
        this.salesOrderForm.get('shippingCarrier').setValue('OTHERS');
    }

    toggleInternationalInput(): void {
        this.salesOrderForm.get('shipping_carrier_int').setValue('ARAMEX');
    }

    toggleInputs(): void {
        this.salesOrderForm.get('shipping_carrier_outvalley').setValue('Dash logistics');
    }


    toggleInputss(): void {
        this.salesOrderForm.get('shipping_carrier_invalley').setValue('Dash logistics');
    }

    openDrawer() {




        this.matDrawer.open();


    }



    onDrawerClose(): void {
        this.matDrawer.close();
    }
}