import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { Color } from './color.type';
import { server_env } from 'config';
@Injectable({
  providedIn: 'root'
})
export class ColorService {
  private _color: BehaviorSubject<Color | null> = new BehaviorSubject(null);
  private _colors: BehaviorSubject<Color[] | null> = new BehaviorSubject(null);



  public url = `${server_env.URL}api/v1/color/`;
  public Serverurl = `${server_env.URL}api/v1/colors/`;



  constructor(private _httpClient: HttpClient) { }

  get color$(): Observable<Color> {
    return this._color.asObservable();
}

/**
 * Getter for contacts
 */
get colors$(): Observable<Color[]> {
    return this._colors.asObservable();
}



getColors(): Observable<Color[]> {
  return this._httpClient.get<Color[]>(`${this.url}`).pipe(
      tap((colors: any) => {
          this._colors.next(colors.data);

          for (colors in colors.data) {
             
          }
      })
  );
}


createColor(data): Promise<any> {
    return new Promise((resolve, reject) => {
        const requestUrl = this.url;

        this._httpClient.post<any>(`${requestUrl}`, data).subscribe(
            (response) => {
                return resolve(response);
            },
            (error) => {
                return reject(error);
            }
        );
    });
}

/**
* Get color by id
*/
getColorById(id: any): Observable<Color> {
  const requestUrl = this.Serverurl + id;
 
  return this._httpClient.get<Color[]>(`${requestUrl}/`).pipe(
      take(1),
      map((color: any) => {
          this._color.next(color);

          // Return the color
          return color;
      }),
      switchMap((color) => {
          if (!color) {
              return throwError(
                  'Could not found contact with id of ' + id + '!'
              );
          }

          return of(color);
      })
  );

}


   /**
     * Delete the color
     *
     * @param id
     */
    deleteColor(id: string): Observable<boolean> {
      return this.colors$.pipe(
          take(1),
          switchMap((color) =>
              this._httpClient.delete(`${this.url}${id}/`).pipe(
                  map((isDeleted: boolean) => {
                      // Find the index of the deleted color
                      const index = color.findIndex(
                          (item) => item.id === id
                      );

                      // Delete the color
                      color.splice(index, 1);

                      // Update the color
                      this._colors.next(color);

                      // Return the deleted color
                      return isDeleted;
                  })
              )
          )
      );
  }

}
