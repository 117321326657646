import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { PackageType } from 'app/packages/package.type';
import { Observable, Subject } from 'rxjs';
import { PackagesOderService } from 'app/packages/packages.service';
import { takeUntil } from 'rxjs/operators';

interface Card {
  title: string;
}

interface packageData {
  id: number;
  salesOrder: string;
  infulencerOrder: string;
  date: Date;
  packageSlip: string;
  shipSlip: string;
  isPackaged: boolean;
  isShipped: boolean;
  isDelivared: boolean;
}

@Component({
  selector: 'app-listpackages',
  templateUrl: './listpackages.component.html',
  styleUrls: ['./listpackages.component.scss'],
})
export class ListpackagesComponent implements OnInit, OnDestroy {
  packageOders: PackageType[];
  dataSource: MatTableDataSource<packageData>;
  pkgsOrder$: Observable<PackageType[]>;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private _packageOderService: PackagesOderService,
    ) {}

  ngOnInit(): void {
    this.getPackagess();
  
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  getPackagess = () => {

    this.pkgsOrder$ = this._packageOderService.getPackagesOder();
    this.pkgsOrder$.subscribe(
      data => {
        // console.log('Data received:', data);
      },
      error => {
        // console.error('Error occurred:', error);
      },
      () => {
        // console.log('Observable complete');
      }
    );
  
    if(this.pkgsOrder$){
        this.pkgsOrder$.pipe(takeUntil(this.destroy$)).subscribe(
        (package_order) => {
          if (package_order && package_order['data']) {
            this.packageOders = package_order['data'];
            this.dataSource = new MatTableDataSource<packageData>(
              this.packageOders.map((pkgOder) => {
                return {
                  id: pkgOder.id,
                  salesOrder: pkgOder.salesOrder,
                  date: pkgOder.packageDate,
                  packageSlip: pkgOder.packageOrder,
                  shipSlip: pkgOder.shipOrder,
                  isPackaged: pkgOder.isPackaged,
                  isShipped: pkgOder.isShipped,
                  isDelivared: pkgOder.isDelivared,
                  infulencerOrder: pkgOder.infulencerOrder,
                };
              })
            );
          }
        },
        (error) => { }
      );}
  };

}
