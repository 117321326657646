import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { productCategories } from './product-categories.type';
import { HttpHeaders } from '@angular/common/http';
import { server_env } from 'config';

@Injectable({
    providedIn: 'root',
})
export class productCategoryService {
    // Private
    private _products: BehaviorSubject<productCategories | null> =
        new BehaviorSubject(null);
    private _productsss: BehaviorSubject<productCategories[] | null> =
        new BehaviorSubject(null);

    public url = `${server_env.URL}api/v1/productsCategory/`;
    public urlReport = `${server_env.URL}api/v1/productsCategories/`;


    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors

    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for contact
     */
    get products$(): Observable<productCategories> {
        return this._products.asObservable();
    }

    /**
     * Getter for contacts
     */
    get productss$(): Observable<productCategories[]> {
        return this._productsss.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get contacts
     */

    getproducts(): Observable<productCategories[]> {
        return this._httpClient.get<productCategories[]>(`${this.url}`).pipe(
            tap((products: any) => {
                // console.log();
                this._productsss.next(products.data);

                for (products in products.data) {
                    // console.log(products, 'sdas');
                }
            })
        );
    }

    /**
     * Get contact by id
     */
    getproductsById(id: string): Observable<productCategories> {
        const requestUrl = this.urlReport + id;

        return this._httpClient.get<productCategories[]>(`${requestUrl}`).pipe(
            take(1),
            map((products: any) => {
                this._products.next(products);

                // Return the contact
                return products;
            }),
            switchMap((products) => {
                if (!products) {
                    return throwError(
                        'Could not found contact with id of ' + id + '!'
                    );
                }

                return of(products);
            })
        );
    }

    /**
     * Create contact
     */
    // createproducts(): Observable<products>
    // {
    //     return this.products$.pipe(
    //         take(1),
    //         switchMap(products => this._httpClient.post<products>(`${this.url}`, {}).pipe(
    //             map((newproducts) => {

    //                 // Update the contacts with the new contact
    //                 this._productsss.next([newproducts, ...products]);

    //                 // Return the new contact
    //                 return newproducts;
    //             })
    //         ))
    //     );
    // }

    /**
     * create customer
     * @param data
     * @returns
     */

    createproductss(data: any): Promise<any> {
        const requestUrl = this.url;
        const headers = new HttpHeaders();

        // Remove the default 'Content-Type' header to allow Angular to automatically set the appropriate headers for multipart form data
        headers.delete('Content-Type');

        return this._httpClient
            .post<any>(requestUrl, data, { headers })
            .toPromise();
    }
       /**
 * Update Contact
 *
 * @param id
 * @param customerData
 */
       updateproducts(id: string, customerData: any): Observable<productCategories> {
  
        return this.productss$.pipe(
            take(1),
            switchMap(Contacts =>
                this._httpClient.patch(`${this.url}${id}/`, customerData).pipe(
                    map((updatedContact) => {
                
                        const index = Contacts.findIndex(item => item.id === id);
                        Contacts[index] = updatedContact;
                       
                        // Update the Contacts
                        this._productsss.next(Contacts);
    
                        return updatedContact;
                    }),
                    switchMap(updatedContact =>
                        this.products$.pipe(
                            take(1),
                            filter(item => item && item.id === id),
                            tap(() => {
                                // Update the Contact if it's selected
                                this._products.next(updatedContact);
                            })
                        )
                    )
                )
            )
        );
    }
    
    /**
     * Delete the contact
     *
     * @param id
     */
    deleteproducts(id: string): Observable<boolean> {
        return this.productss$.pipe(
            take(1),
            switchMap((products) =>
                this._httpClient.delete(`${this.url}${id}/`).pipe(
                    map((isDeleted: boolean) => {
                        // Find the index of the deleted contact
                        const index = products.findIndex(
                            (item) => item.id === id
                        );

                        // Delete the contact
                        products.splice(index, 1);

                        // Update the contacts
                        this._productsss.next(products);

                        // Return the deleted status
                        return isDeleted;
                    })
                )
            )
        );
    }
}
