import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { VendorsPaymentComponent } from './vendors-payment/vendors-payment.component';
import { ListvendorsPaymentComponent } from './listvendors-payment/listvendors-payment.component';
import { CreatevendorsPaymentComponent } from './createvendors-payment/createvendors-payment.component';
import { DetailvendorsPaymentComponent } from './detailvendors-payment/detailvendors-payment.component';
import { MaterialModule } from 'app/modules/material/material.module';
import { RouterModule } from '@angular/router';
import { VendorsPaymentRoutes } from './vendors-payment.routing';
import { MAT_DATE_FORMATS } from '@angular/material/core';

@NgModule({
    declarations: [
        VendorsPaymentComponent,
        ListvendorsPaymentComponent,
        CreatevendorsPaymentComponent,
        DetailvendorsPaymentComponent,
    ],
    imports: [
        MaterialModule,

        RouterModule.forChild(VendorsPaymentRoutes),
        CommonModule,
      
    ],providers   : [
        {
            provide : MAT_DATE_FORMATS,
            useValue: {
                parse  : {
                    dateInput: "YYYY-MM-DD"
                },
                display: {
                    dateInput         : 'YYYY-MM-DD',
                    monthYearLabel    : 'MMM YYYY',
                    dateA11yLabel     : 'LL',
                    monthYearA11yLabel: 'MMMM YYYY'
                    
                }
            }
        },
        DatePipe
    ]
})
export class VendorsPaymentModule {}
