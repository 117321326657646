import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-restock-entry',
  templateUrl: './restock-entry.component.html',
  styleUrls: ['./restock-entry.component.scss']
})
export class RestockEntryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
