import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ProductGroupComponent } from '../product-group/product-group.component'; 
import { productsGroupService } from '../product-group.service'; 
import { ActivatedRoute } from '@angular/router';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { productGroup } from '../product-group.type';
import { productVaraitons } from 'app/productVariations/productVariations.service';
import { map, startWith, take, tap } from 'rxjs/operators';
import { Observable, Subject, of } from 'rxjs';
import { productVaraitonService } from 'app/productVariations/productVarations.type';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-detailproduct-group',
  templateUrl: './detailproduct-group.component.html',
  styleUrls: ['./detailproduct-group.component.scss']
})
export class DetailproductGroupComponent implements OnInit {
  productId: string;
  editMode: boolean = false;
  vendorsCredit: FormGroup;
  vendor: productGroup;
  vendors: productGroup[];
  thumbnailFile: File | null;
  selectedProductThumbnail: string[] = [];
  selectedProductThumbnailClass: string;
  filteredProductOptions$: Observable<productVaraitons[]>;
  thumbnailURL: string;
  imageUrls: string[] = [];
  constructor(private route: ActivatedRoute,
    private productService: productsGroupService, private _productService: productVaraitonService, private _formBuilder: FormBuilder, private _changeDetectorRef: ChangeDetectorRef,) {

    const products$: Observable<productVaraitons[]> = this._productService
      .getproductVaraiton()
      .pipe(
        take(1),
        map((response) => response)
      );

    products$.subscribe((products) => {
      console.log(products['data'], 'Products');
    });
    this.route.params.subscribe(params => {
    const id = params['id'];
    // Use the updated value of id here
    this.fetchProductDetails(id);
  });

}

  ngOnInit(): void {
    this.vendorsCredit = this._formBuilder.group({
      groups_name: [''],
      groups_sku: [''],
      groups_stock:[''],
      groups_gender:[''],
      groups_description: [''],
      products: this._formBuilder.array([]),
      sub_total: [0], // Initialize with a default value
      total: [0], // Initialize with a default value
      adjustment: [0],
      discount: [0],
      discountSuffix: ['%'],
      adjustmentSuffix: ['%'],

  });

  
    this.productService.products$

    .subscribe((vendor: productGroup) => {
      // Open the drawer in case it is closed


      // Get the vendor
      this.vendor = vendor;

      // Patch values to the form
      this.vendorsCredit.patchValue(vendor);




      // Clear and set shippingArea

      // Define the initial number of products


      const subscribeToValueChanges = (productGroup: FormGroup) => {
        const rateControl = productGroup.get('rate');
        const quantityControl = productGroup.get('quantity');


        rateControl.valueChanges.subscribe(() => {
          this.calculateTotal();
        });

        quantityControl.valueChanges.subscribe(() => {
          this.calculateTotal();
        });


      };
      // Clear existing form array values
      const productFormArray = this.vendorsCredit.get('products') as FormArray;
      productFormArray.clear();

      if (vendor.products.length > 0) {
        // Iterate over the products array
        vendor.products.forEach(product => {
          // Create a new form group for each product
          const productFormGroup = this._formBuilder.group({
            product: product.product,
            quantity: product.quantity,
            rate: product.rate,
            size: product.size,
            color: product.color,
            amount: product.amount,
          });

          // Subscribe to value changes in the productFormGroup
          subscribeToValueChanges(productFormGroup);

          // Push the form group to the productFormArray
          productFormArray.push(productFormGroup);
        });
      }
      // Other code...



      this.vendorsCredit.get('discount').valueChanges.subscribe(() => {
        this.calculateTotal();
      });
      this.vendorsCredit.get('discountSuffix').valueChanges.subscribe(() => {
        this.calculateTotal();
      });



      this.vendorsCredit.get('adjustment').valueChanges.subscribe(() => {
        this.calculateTotal();
      });
      this.vendorsCredit
        .get('adjustmentSuffix')
        .valueChanges.subscribe(() => {
          this.calculateTotal();
        });

      // Clear the billingAddress form array

      this.toggleEditMode(false);

      // Mark for check
      this._changeDetectorRef.markForCheck();
    });
}
toggleEditMode(editMode: boolean | null = null): void {
  if (editMode === null) {
    this.editMode = !this.editMode;
  }
  else {
    this.editMode = editMode;
  }

  // Mark for check
  this._changeDetectorRef.markForCheck();
}

  addProductField(): void {
    const productFormGroup = this._formBuilder.group({
      product: ['',],
      size: ['',],
      rate: [''],
      quantity: [''],
      color: ['rgb(79, 195, 255)',],
      amount: [''],
    });

    const productArray = this.vendorsCredit.get('products') as FormArray;
    productArray.push(productFormGroup);

    const index = productArray.length - 1;
    this.setupProductFieldSubscriptions(productFormGroup, index);

    this.calculateTotal();
  }

  printPage() {
    // Select the buttons you want to hide
    const buttonsToHide = document.querySelectorAll('button');
    
    // Hide the buttons before printing
    buttonsToHide.forEach((button) => {
      button.style.display = 'none';
    });
  
    // Perform the printing
    window.print();
  
    // Show the buttons again after printing (delayed to ensure they show after the print dialog)
    setTimeout(() => {
      buttonsToHide.forEach((button) => {
        button.style.display = 'block'; // or 'inline' depending on the button's original display property
      });
    }, 1000); // Adjust the delay as needed
  }
  
  filterOptionsProduct(value: string): void {
    if (typeof value !== 'string') {
      this.filteredProductOptions$ = of([]);
      return;
    }
    const baseUrl = 'http://103.147.227.9:2/8081';
    this.filteredProductOptions$ = this._productService.getproductVaraiton().pipe(
      take(1),
      map((response) => response),
      tap((products) => console.log(products)), // Log the products array before filtering
      map((products) => {
        if (Array.isArray(products['data'])) {
          const filteredProducts = products['data']
            .filter(
              (product) =>
                product.productName
                  ?.toLowerCase()
                  .includes(value.toLowerCase()) ||
                (product.sku &&
                  product.size
                    .toString()
                    .toLowerCase()
                    .includes(value.toLowerCase()))
            )
            .map((product) => {
              return {
                product: product.id,
                productName: product.productName,
                sku: product.sku,
                size: product.size,
                color: product.color,
                basePrice: product.basePrice,
                thumbnail: baseUrl + product.base_product.thumbnail,

              };
            });
          // Log the filtered products array
          return filteredProducts;

        } else {

          return [];
        }
      })
    );
  }

  selectOptionProduct(option: productVaraitons, index: number): void {
    const productFormGroup = this._formBuilder.group({
      product: [option],
      size: [option.size,],

      color: [option.color],
      rate: [option.basePrice],
      quantity: [''],
      amount: [''],
    });

    const productArray = this.vendorsCredit.get('products') as FormArray;
    const existingFormGroup = productArray.at(index) as FormGroup;

    if (existingFormGroup) {
      existingFormGroup.patchValue(productFormGroup.value);
      this.selectedProductThumbnail[index] = option.thumbnail;
      this.selectedProductThumbnailClass = 'example-option-img';
    }
  }
  displayProductOption(product: productVaraitons): string {
    if (product) {
      const productName = product.productName || '';
      const productPrice = product.sku
        ? ' - ' + product.sku.toString()
        : '' + product.productName.toString();

      return productName + productPrice;
    }
    return '';
  }

  removeProductField(index: number): void {
    const productArray = this.vendorsCredit.get('products') as FormArray;
    productArray.removeAt(index);

    this.calculateTotal();
  }



  fetchProductDetails(productId: string): void {
    // Use your product service to fetch the product details based on the provided ID
    this.productService.getproductsById(productId).subscribe(
      (data) => {
        this.vendor = data;

        // this.product.thumbnail = this.getThumbnailUrl(data.thumbnail); // Update the thumbnail URL
      },
      (error) => {
        console.error('Error fetching product details:', error);
      }
    );
    // console.log(this.vendor);
  }
  setupProductFieldSubscriptions(
    productGroup: FormGroup,
    index: number
  ): void {
    const quantityControl = productGroup.get('quantity');
    const rateControl = productGroup.get('rate');


    quantityControl.valueChanges.subscribe(() => {
      this.calculateTotal();
    });

    rateControl.valueChanges.subscribe(() => {
      this.calculateTotal();
    });


  }
  calculateTotal(): void {
    const productArray = this.vendorsCredit.get('products') as FormArray;

    let subTotal = 0;
    productArray.controls.forEach((control: FormGroup) => {
        const quantity = 1
        const rate = control.get('rate').value;


        const amount = (quantity || 0) * (rate || 0);
        control.get('amount').setValue(amount);

        subTotal += amount || 0;
    });


    this.vendorsCredit.get('sub_total').setValue(subTotal);
    const discountSuffix = this.vendorsCredit.get('discountSuffix').value;
    const adjustmentSuffix =
        this.vendorsCredit.get('adjustmentSuffix').value;
    const discount = this.vendorsCredit.get('discount').value || 0;

    const adjustment = this.vendorsCredit.get('adjustment').value || 0;
    let calculatedDiscount = discount;
    let calculatedAdjustment = adjustment;

    if (discountSuffix === '%') {
        calculatedDiscount = (subTotal * discount) / 100;
    }

    if (adjustmentSuffix === '%') {
        calculatedAdjustment = (subTotal * adjustment) / 100;
    }

    if (adjustmentSuffix == '%' && discountSuffix == '%') {
        calculatedDiscount = (subTotal * discount) / 100;
        const newTotal = subTotal - calculatedDiscount;

        calculatedAdjustment = (newTotal * adjustment) / 100;
    }

    const total = subTotal - calculatedDiscount + calculatedAdjustment;
    this.vendorsCredit.get('total').setValue(total);


}

async updateProductGroup() {
  if (!this.vendorsCredit.valid) {
    this.showFormValidationError();
    return;
  }

  const customerData = { ...this.vendorsCredit.value };
  const id = this.vendor.id;
  const products = this.vendorsCredit.get('products') as FormArray;

  const updatedProducts = products.value.map((item: any) => ({
    product: item.product.id,
    quantity: item.quantity,
    size: item.size,
    color: item.color,
    rate: item.rate,
    amount: item.amount

  }));

  customerData.products = updatedProducts;
  // Convert shipping area to a list

  try {
    const updatedvendor = await this.updatevendorOnServer(id, customerData);
    this.handleUpdateSuccess(updatedvendor, id);
  } catch (error) {
    this.handleUpdateError(error);
  } finally {
    this.toggleEditMode(false);
  }

  this._changeDetectorRef.markForCheck();
}

private async updatevendorOnServer(id: string, data: any): Promise<productGroup> {
  return new Promise<productGroup>((resolve, reject) => {

    this.productService.updateproducts(id, data).subscribe(
      (customerData: productGroup) => {
        resolve(customerData);
      },


      (error) => {
        reject(error);
      }
    );

  });
}

private handleUpdateSuccess(updatedvendor: productGroup, id: string) {
  this.vendor = updatedvendor;

  this.vendorsCredit.patchValue(updatedvendor);




  const billingAddressFormGroup = this.vendorsCredit.get('products') as FormGroup;
  billingAddressFormGroup.patchValue({}); // Clear existing values

  if (updatedvendor.products) {
    updatedvendor.products.forEach((shippingData) => {
      billingAddressFormGroup.patchValue({
        product: shippingData.product,
        quantity: shippingData.quantity,
        rate: shippingData.rate,
        size: shippingData.size,
        color: shippingData.color,
        amount: shippingData.amount,
      });
    });
  }




  this.productService.getproductsById(id).subscribe((vendor: productGroup) => {
    // Open the drawer in case it is closed

    // Get the employee
    this.vendor = vendor;
  })
  this.reloadvendorsList();

  this.showSuccessToast('Product Group Updated!!');
}

private handleUpdateError(error: any) {

  this.showErrorToast('Error Updating Product Bundle');

  console.error('Error updating Group:', error);
}

private showFormValidationError() {
  this.showErrorToast('Form Invalid');
}

private reloadvendorsList() {
  this.productService.getproducts().subscribe(() => {
    // Reset the form and navigate if needed
  });


}

private showSuccessToast(message: string) {
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
  });

  Toast.fire({
    icon: 'success',
    title: message,
  });
}

private showErrorToast(message: string) {
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
  });

  Toast.fire({
    icon: 'error',
    title: message,
  });
}



}
