import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { server_env } from 'config';
@Injectable({
  providedIn: 'root'
})
export class SalesLogsService {

    private _packageSalesOrder: BehaviorSubject<any[] | null> =
        new BehaviorSubject(null);

  private _packagesPreOrder:BehaviorSubject<any[] | null> =
  new BehaviorSubject(null);

  private _packagesExchanges:BehaviorSubject<any[]|null> = 
  new BehaviorSubject(null);

  private _packagesCreditNotes: BehaviorSubject<any[] |null> = 
  new BehaviorSubject(null);

  constructor(private _httpClient: HttpClient) {}
  
  public url = `${server_env.URL}api/v1/salesOrder_p/`;
  public apiUrl = `${server_env.URL}api/v1/aggregated_data/`;
  public apiUrls = `${server_env.URL}api/v1/salesOrderPartialPay/`;

  getSalesLogs() {
    return this._httpClient.get(this.apiUrl);
  }


getSalesOrderPackages(productId: string): Observable<any> {
  const requestUrl = `${server_env.URL}api/v1/salesOrder_p/${productId}/`

  return this._httpClient.get<any[]>(`${requestUrl}`).pipe(
      tap((variants: any) => {
          this._packageSalesOrder.next(variants.data);

          for (variants in variants.data) {
          }
      })
  );
}

getPreOrderPackages(preOrderId:string):Observable<any>{
  const requestUrl = `${server_env.URL}api/v1/packagePreOrder/${preOrderId}/`

  return this._httpClient.get<any[]>(`${requestUrl}`).pipe(
      tap((variants: any) => {
          this._packagesPreOrder.next(variants.data);

          for (variants in variants.data) {
          }
      })
  );
  
}

getExchangesPackages(exchangesId:string):Observable<any>{
  const requestUrl = `${server_env.URL}api/v1/packagesExchange/${exchangesId}/`

  return this._httpClient.get<any[]>(`${requestUrl}`).pipe(
      tap((variants: any) => {
          this._packagesExchanges.next(variants.data);

          for (variants in variants.data) {
          }
      })
  );
  
}

getCreditNotePackages(creditNotesId:string):Observable<any>{
  const requestUrl = `${server_env.URL}api/v1/packagesCreditNotes/${creditNotesId}/`

  return this._httpClient.get<any[]>(`${requestUrl}`).pipe(
      tap((variants: any) => {
          this._packagesCreditNotes.next(variants.data);

          for (variants in variants.data) {
          }
      })
  );
  
}



submitPartialPayment(data: any): Observable<any> {

  return this._httpClient.post(this.apiUrls, data);
}


}
