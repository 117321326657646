import {
    Component,
    ElementRef,
    Input,
    OnInit,
    ViewChild,
} from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormGroup,

    Validators,
} from '@angular/forms';


import { ActivatedRoute, Router } from '@angular/router';

import { Observable, Subject, of } from 'rxjs';

import { ContactsService } from 'app/modules/admin/apps/contacts/contacts.service';

import { DatePipe } from '@angular/common';
import {
    debounceTime,
    map,
    startWith,
    switchMap,
    take,
    takeUntil,
} from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';

import Swal from 'sweetalert2';
import { Contact } from 'app/modules/admin/apps/contacts/contacts.types';
import { UserService } from 'app/core/user/user.service';
import { SalesOrderPopupComponent } from 'app/sales-order/sales-order-popup/sales-order-popup.component';
import { User } from 'app/core/user/user.types';

import { products } from 'app/products/products.type';
import { CreditNotesService } from '../credit-notes.service';
import { SalesOrderNumberService } from 'app/sales-order/sales-order-number.service';
import { contrydata } from 'app/sales-order/sales-order-create/countries';
import { HasUnsavedChanges } from 'app/page-detect/wm-can-leave/detect-changes.guard';
import { FuseConfirmationService } from '@fuse/services/confirmation';

export interface CustomerOption {
    id: string;
    firstname?: string;
    avatar?: string;
}

export interface ProductOption {
    name?: string;
    stock?: number;
    reserved?: number;
    cost?: number;
    basePrice?: number;
    price?: number;

    images?: string[];
}

export interface Country {
    name: string;
    code: string;

}





@Component({
    selector: 'app-create-credit-notes',
    templateUrl: './create-credit-notes.component.html',
    styleUrls: ['./create-credit-notes.component.scss'],
})
export class CreateCreditNotesComponent implements OnInit, HasUnsavedChanges {
    @ViewChild('customerInput') customerInput: ElementRef;

    currentSalesOrderNum: number = 1;
    ngControl: any;


  


   

    @Input() set disableControl(condition: boolean) {
        const action = condition ? 'disable' : 'enable';
        this.ngControl.control[action]();
    }
    customerId: number | undefined; // Property to store the selected customer ID

    stateCtrl = new FormControl('');
    formFieldHelpers: string[] = [''];
    drawerMode: 'over' | 'side' = 'side';
    drawerOpened: boolean = true;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    active: boolean[] = [];

    salesOrderForm: FormGroup;
    vendorsCount: number;
    selectedVendor: Contact;
    customers$: Observable<Contact[]>;
    CustomerControl: FormControl = new FormControl();
    // Declare an Observable to hold
    filteredCustomers$: Observable<Contact[]>;
    selectedCustomer: Contact | null = null;




    filteredProductOptions$: Observable<products[]>;
    filteredProductBundleOptions$: Observable<any[]>;

    usename: string = '';
   
    // Default value
   
    isFieldReadOnly = false;

    dynamicNumber: any;
    searchQuery: string;
    searchResults: any[];
    formSubmitted = true;
 
    user: User;
    configForm: FormGroup;


    

    get sales_order_num() {
        return this._formBuilder.control(`SO-00${this.currentSalesOrderNum}-A`, Validators.required);
    }

  


    constructor(

        private _formBuilder: FormBuilder,
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private dialog: MatDialog,
        private _customerService: ContactsService,
        private _userService: UserService,
        private _salesOrderService: CreditNotesService,
        private datePipe: DatePipe,
        private _salesorderNumber: SalesOrderNumberService,
        private _fuseConfirmationService: FuseConfirmationService,


    ) {


     
        this.customers$ = this._customerService.getContacts().pipe(take(1));

        this.customers$.subscribe((customers) => {
        });

    }

    ngOnInit() {
        this.fetchData();


        this.configForm = this._formBuilder.group({
            title: 'Info',
            message: '<span class="font-medium">Are you sure you want to proceed?</span> Please be aware that once you remove the product, all the data will be reverted. Are you sure you want to finalize it? Proceed carefully.',
            icon: this._formBuilder.group({
                show: true,
                name: 'heroicons_outline:exclamation',
                color: 'info'
            }),
            actions: this._formBuilder.group({
                confirm: this._formBuilder.group({
                    show: true,
                    label: 'Proceed',
                    color: 'accent'
                }),
                cancel: this._formBuilder.group({
                    show: true,
                    label: 'Cancel',
                    color: 'warn'

                })
            }),
            dismissible: true
        });
        this._userService.user$
            .pipe((takeUntil(this._unsubscribeAll)))
            .subscribe((user: User) => {
                this.user = user;
            });

        this.salesOrderForm = this._formBuilder.group({

            Customers: [this.CustomerControl, Validators.required],
    
            
            voucher_order_num :[
                `VR-00${this.currentSalesOrderNum}-A`
            ],
            order_number: [''],
            voucher_order_date:[''],
           
            voucher_type:[''],
            notes:[''],
        
            total: [0,[Validators.required,Validators.min(1)]], // Initialize with a default value
           
            sales_person: [''],
         
        });


        const salesOrderDateControl = new FormControl(new Date());

        // Get the current date
        const currentDate = new Date();

        // Set the default value of the sales order date to today's date
        salesOrderDateControl.setValue(currentDate);

        // Convert the default date value to a formatted string
        const datePipe = new DatePipe('en-US');
        const defaultDateValue = datePipe.transform(currentDate, 'yyyy-MM-dd');

     

        this.salesOrderForm.get('voucher_order_date').setValue(defaultDateValue);
        const tomorrow = new Date(currentDate.getTime() + 24 * 60 * 60 * 1000);
        this.salesOrderForm.get('sales_person').patchValue(this.user.username.toLocaleUpperCase())

     
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

   

    makeFieldEditable(customerInput: HTMLInputElement) {
        this.isFieldReadOnly = false;
        this.selectedCustomer = null; // Assuming `selectedCustomer` is the variable holding the current customer value
        if (customerInput) {
            customerInput.value = ''; // Clear the input value
        }

        // Clear the value of the form control
        this.CustomerControl.setValue('');
    }




    openCustomerPopup(): void {
        const dialogRef = this.dialog.open(SalesOrderPopupComponent, {
            width: '500px',
        });

        dialogRef.componentInstance.formSubmitted.subscribe((formData) => {

            // Call your service method here
            this._customerService
                .createCustomer(formData)

                .then(async (response) => {

                    this.formSubmitted = false;

                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener(
                                'mouseenter',
                                Swal.stopTimer
                            );
                            toast.addEventListener(
                                'mouseleave',
                                Swal.resumeTimer
                            );
                        },
                    });

                    Toast.fire({
                        icon: 'success',
                        title: `Customer  created!! `,
                    });
                    await this.fetchData();
                    this.selectOption(response.data)
                    this.CustomerControl.markAsUntouched();
                    dialogRef.close();

                    // Handle the response from the service if needed
                })
                .catch((error) => {
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener(
                                'mouseenter',
                                Swal.stopTimer
                            );
                            toast.addEventListener(
                                'mouseleave',
                                Swal.resumeTimer
                            );
                        },
                    });

                    Toast.fire({
                        icon: 'error',
                        title: `'Error creating Customer: ' ${error.message};`,
                    });
                });
        });

    }

    openProductDetails(): void {

    }

    sumbitSalesOrder() {
        if (this.salesOrderForm.valid) {
            // Show the loading indicator
            const customerData = { ...this.salesOrderForm.value };
            const customer = this.salesOrderForm.get('Customers')?.value;
            customerData.Customers = customer ? customer.value.id : null;
            if (customerData.Customers === undefined) {
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top', // Set position to 'top'
                    customClass: {
                        popup: 'swal2-toast-top-center', // Center the toast
                    },
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener(
                            'mouseenter',
                            Swal.stopTimer
                        );
                        toast.addEventListener(
                            'mouseleave',
                            Swal.resumeTimer
                        );
                    },
                });

                Toast.fire({
                    icon: 'warning',
                    title: `Please select valid customer`,
                });
                return
            }
           

            Swal.fire({
                title: "Loading",
                html: `
                    <div style="display: flex; flex-direction: column; align-items: center;">
                        <img src="assets/dragon.gif" style="width: 60px; height: 80px;" alt="loading"/>
                        <p style="margin: 10px 0 0 0; font-size: 14px; color: #666;">Creating, please wait...</p>
                    </div>
                `,
                timerProgressBar: true,
                didOpen: () => {
                    Swal.showLoading();
                },
            });

            // Update the contact on the server
            this._salesOrderService
                .createSalesOrders(customerData)
                .then((response) => {
                    if (response.success) {
                        this._salesorderNumber.incrementSalesOrderNum();

                        const Toast = Swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener(
                                    'mouseenter',
                                    Swal.stopTimer
                                );
                                toast.addEventListener(
                                    'mouseleave',
                                    Swal.resumeTimer
                                );
                            },
                        });

                        Toast.fire({
                            icon: 'success',
                            title: `${customerData.sales_order_num} Sales Order created!!`,
                        });

                        this.salesOrderForm.reset();
                        this._router.navigate(['../'], {
                            relativeTo: this._activatedRoute,
                        });
                    } else {
                        Swal.close();

                        const errorMessages = response.errors;

                        const Toast = Swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener(
                                    'mouseenter',
                                    Swal.stopTimer
                                );
                                toast.addEventListener(
                                    'mouseleave',
                                    Swal.resumeTimer
                                );
                            },
                        });

                        Toast.fire({
                            icon: 'error',
                            title: 'Error creating Sales Order ',
                            text: JSON.stringify(errorMessages),
                        });
                    }
                })
                .catch((error) => {
                    // Hide the loading indicator
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener(
                                'mouseenter',
                                Swal.stopTimer
                            );
                            toast.addEventListener(
                                'mouseleave',
                                Swal.resumeTimer
                            );
                        },
                    });

                    Toast.fire({
                        icon: 'error',
                        title: `Error creating Sales Order: ${error.message}`,
                    });
                });
        } else {
            Swal.close();

            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer);
                    toast.addEventListener('mouseleave', Swal.resumeTimer);
                },
            });

            Toast.fire({
                icon: 'error',
                title: 'Invalid form data',
                text: 'Please fill in all the required fields correctly.',
            });
        }
    }

    toggleDropdown() {
        const dropdownMenu = document.getElementById('dropdown-menu');
        if (dropdownMenu) {
            dropdownMenu.classList.toggle('show');
        }
    }



    saveAsDraft() {
    }

    saveAndPrint() {
    }




    // Your common utility function to check if data is an array
    isArrayData(data: any): data is any[] {
        return Array.isArray(data);
    }


    // Assuming you have a class with SalesOrderForm and ProductService and ProductBundleService instances

    // ... Your existing class members ...



    getVendorPlaceholder(): string {
        return this.vendorsCount > 0
            ? 'Select a Customer'
            : 'No Customer available';
    }
    onSalesOrderDateChange(event: any) {
        const selectedDate: Date = event.value;

        const formattedDate = this.datePipe.transform(selectedDate, 'yyyy-MM-dd');

        this.salesOrderForm.get('slaes_order_date').setValue(formattedDate);

    }
    /**
      * Track by function for ngFor loops
      *
      * @param index
      * @param item
      */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    filterOptions(value: string): Observable<Contact[]> {
        if (typeof value !== 'string') {
            return of([]); // Return an empty array if value is not a string
        }
        this.formSubmitted = true;

        const filterValues = value.trim().toLowerCase().split(' ');

        return this.customers$.pipe(
            map((customers) => {
                if (Array.isArray(customers['data'])) {
                    return customers['data'].filter((customer) =>
                        this.matchesFilter(customer, filterValues)
                    );
                } else {
                    console.error('Customers data is not available:', customers);
                    return [];
                }
            })
        );
    }

    // Helper method to check if a name includes at least one part of the filter values
    private includesAnyPart(name: string, filterValues: string[]): boolean {
        return filterValues.some((filterValue) => name.includes(filterValue));
    }

    // Helper method to check if a customer matches the filter values
    private matchesFilter(customer: Contact, filterValues: string[]): boolean {
        // Trim and convert each part of the name to lowercase for a case-insensitive comparison
        const trimmedFirstName = customer.firstName?.trim().toLowerCase();
        const trimmedLastName = customer.lastName?.trim().toLowerCase();

        // Check if either first or last names contain at least one part of the filter values
        return (
            (trimmedFirstName && this.includesAnyPart(trimmedFirstName, filterValues)) ||
            (trimmedLastName && this.includesAnyPart(trimmedLastName, filterValues))
        );
    }




    displayOption(customer: Contact): string {
        const last_name = customer?.lastName || '';
        const first_name = customer?.firstName || '';
        return first_name && last_name ? first_name + ' ' + last_name : '';

    }

    selectOption(option: Contact) {
        if (!option) {
            return;
        }
        this.CustomerControl.setValue(option);
        this.CustomerControl.updateValueAndValidity();
        this.selectedCustomer = option;
        this.isFieldReadOnly = true;

    }


    isCustomerSelected(selectedCustomer: Contact): boolean {
        return this.CustomerControl.value && selectedCustomer && this.CustomerControl.value.id === selectedCustomer.id;
    }


    // Map the customer object to CustomerOption
    genderOptions(customer: Contact): CustomerOption[] {
        if (customer) {
            const mappedCustomer: CustomerOption = {
                id: customer.id,
                firstname: customer.firstName || '',
            };
            return [mappedCustomer];
        }
        return [];
    }



    formatDate(date: Date, format: string, controlName: string) {
        const formattedDate = this.datePipe.transform(date, format);
        this.salesOrderForm.get(controlName)?.setValue(formattedDate);
    }


    async fetchData() {
        try {


            this.filteredCustomers$ = this.CustomerControl.valueChanges.pipe(
                startWith(''),
                debounceTime(300),
                switchMap((value) => this.filterOptions(value))
            );
            this.currentSalesOrderNum = this._salesorderNumber.getCurrentSalesOrderNum()


        } catch (error) {
            // console.error(error);
        }
    }

  
    hasUnsavedChanges(): boolean {
        return this.salesOrderForm.dirty;
    }

    canDeactivate(): boolean {
        return !this.salesOrderForm.dirty || confirm('You have unsaved changes. Are you sure you want to leave?');
    }

    openConfirmationDialog(): Observable<boolean> {
        const dialogRef = this._fuseConfirmationService.open(this.configForm.value);

        return dialogRef.afterClosed().pipe(
            map(result => {
                return result === 'confirmed';
            })
        );
    }


  

}