import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FuseCardComponent } from '@fuse/components/card';
import { ConfirmPopupComponent } from 'app/confirm-popup/confirm-popup.component';
import { flip } from 'lodash';

@Component({
  selector: 'app-pr-packages-product-credit-notes-popup',
  templateUrl: './pr-packages-product-credit-notes-popup.component.html',
  styleUrls: ['./pr-packages-product-credit-notes-popup.component.scss']
})
export class PrPackagesProductCreditNotesPopupComponent implements OnInit {
  showMessage: boolean = false;
  formCancel: boolean = true;

  @ViewChild('fuseCard') fuseCard: FuseCardComponent;

  constructor(private _matDialog: MatDialogRef<ConfirmPopupComponent>) {}

  ngOnInit(): void {
  }
  cancelFunction(): void {
    this.formCancel = false;
    this._matDialog.close(false); // Close the dialog with false result
  }

  confirmDelete(): void {
    this.showMessage = true;
    this.fuseCard.flippable = true

    this._matDialog.close(true); // Close the dialog with true result

    // Flip the fuse-card to the back
    
  }
}