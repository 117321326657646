<!-- vendorsCredit form -->



<div class="w-full h-full w-full">
    <!-- Header -->
    <div
        class="flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between p-6 sm:py-8 sm:px-10 border-b bg-card dark:bg-transparent">
        <div class="flex-1 min-w-0">
            <!-- Breadcrumbs -->

            <!-- Title -->


            <div class="mt-2">
                <h2 class="text-3xl md:text-4xl font-extrabold tracking-tight leading-7 sm:leading-10 truncate">
                    Product Master

                </h2>
            </div>
        </div>
    </div>

    <app-loader *ngIf="isLoading"></app-loader>


    <mat-horizontal-stepper #horizontalStepper>
        <!-- Step 1 - Color Information -->
        <mat-step>
            <ng-template matStepLabel> Create Color</ng-template>
            <form [formGroup]="colorForm">
                <p class="mt-3 mb-2 font-medium">Provide details about color.</p>

                <p class="text-secondary mb-5">To ensure clarity and consistency, please adhere to one of the following
                    color text formats:<br> CapitalThenSmall (e.g., LightBlue) or ALL CAPS (e.g., LIGHTBLUE).

                </p>


                <div class="flex">
                    <mat-form-field [ngClass]="formFieldHelpers"
                        class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix" style="max-width:500px;">
                        <input matInput oninput="this.value = this.value.toUpperCase()" formControlName="name"
                            placeholder="Color Name" required>
                        <mat-icon class="icon-size-4" matPrefix svgIcon="color_lens"></mat-icon>


                    </mat-form-field>
                    <mat-form-field [ngClass]="formFieldHelpers"
                        class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix ml-3" style="max-width:500px;">
                        <input matInput oninput="this.value = this.value.toUpperCase()" formControlName="sku"
                            placeholder="Color SKU" required>
                        <mat-icon class="icon-size-4" matPrefix svgIcon="feather:hash"></mat-icon>

                    </mat-form-field>
                </div>


                <div class="flex justify-start mt-5">
                    <button class="px-8" mat-flat-button [color]="'primary'"
                        [disabled]="colorForm.pristine || colorForm.invalid" type="button"
                        (click)="saveStep1()">Save</button>
                </div>
            </form>
        </mat-step>

        <!-- Step 2 - Size Information -->
        <mat-step>
            <ng-template matStepLabel>Create Size </ng-template>
            <form [formGroup]="sizeForm">
                <p class="mt-3 mb-2 font-medium">Provide details about size.</p>

                <p class="text-secondary mb-5">To ensure clarity and consistency, please adhere to one of the following
                    size text formats:<br> CapitalThenSmall (e.g., Small,Xl) or ALL CAPS (e.g., SMALL,XL)

                </p>
                <div class="flex">
                    <mat-form-field [ngClass]="formFieldHelpers"
                        class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix" style="max-width:500px;">
                        <input matInput oninput="this.value = this.value.toUpperCase()" formControlName="name"
                            placeholder="Size Name" required>
                        <mat-icon class="icon-size-4" matPrefix svgIcon="format_size"></mat-icon>

                    </mat-form-field>

                    <mat-form-field [ngClass]="formFieldHelpers"
                        class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix ml-3" style="max-width:500px;">
                        <input matInput oninput="this.value = this.value.toUpperCase()" formControlName="sku"
                            placeholder="Size SKU" required>
                        <mat-icon class="icon-size-4" matPrefix svgIcon="feather:hash"></mat-icon>

                    </mat-form-field>
                </div>


                <div class="flex justify-start mt-5">
                    <button class="px-8 mr-2" mat-flat-button [color]="'accent'" type="button"
                        [disabled]="sizeForm.pristine || sizeForm.invalid" (click)="saveStep2()">Save</button>
                </div>
            </form>
        </mat-step>

        <!-- Step 3 - Category Information -->
        <mat-step>
            <ng-template matStepLabel>Create Category</ng-template>
            <form [formGroup]="categoryForm">
                <p class="font-medium">Provide details about category.</p>
                <p class="text-secondary mb-5">To ensure clarity and consistency, please adhere to one of the following
                    category text formats:<br> CapitalThenSmall (e.g., Hip) or ALL CAPS (e.g., HIP)

                </p>
                <div class="flex">
                    <mat-form-field [ngClass]="formFieldHelpers"
                        class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix" style="max-width:500px;">
                        <input oninput="this.value = this.value.toUpperCase()" matInput formControlName="category_name"
                            placeholder="Category Name" required>

                        <mat-icon class="icon-size-4" matPrefix svgIcon="feather:grid"></mat-icon>

                    </mat-form-field>


                </div>


                <div class="flex justify-start mt-5">
                    <button class="px-8 mr-2" mat-flat-button [color]="'accent'" type="button"
                        [disabled]="categoryForm.pristine || categoryForm.invalid" (click)="saveStep3()">Save</button>
                </div>
            </form>
        </mat-step>

        <!-- Step 4 - Collection Information -->
        <mat-step>
            <ng-template matStepLabel>Create Collection</ng-template>
            <form [formGroup]="collectionForm">
                <p class=" font-medium">Provide details about collection.</p>
                <p class="text-secondary mb-5">To ensure clarity and consistency, please adhere to one of the following
                    collection text formats:<br> CapitalThenSmall (e.g., Summer) or ALL CAPS (e.g., SUMMER)

                </p>
                <div class="flex">
                    <mat-form-field [ngClass]="formFieldHelpers"
                        class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix" style="max-width:500px;">
                        <input matInput oninput="this.value = this.value.toUpperCase()"
                            formControlName="collection_name" placeholder="Collection Name" required>
                        <mat-icon class="icon-size-4" matPrefix svgIcon="feather:list"></mat-icon>

                    </mat-form-field>

                    <mat-form-field [ngClass]="formFieldHelpers"
                        class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix ml-3" style="max-width:500px;">
                        <input matInput oninput="this.value = this.value.toUpperCase()" formControlName="sku"
                            placeholder="Collection SKU" required>
                        <mat-icon class="icon-size-4" matPrefix svgIcon="feather:hash"></mat-icon>

                    </mat-form-field>
                </div>
                <div class="flex justify-start mt-5">
                    <button class="px-8 mr-2" mat-flat-button [color]="'accent'" type="button"
                        [disabled]="collectionForm.pristine || collectionForm.invalid"
                        (click)="saveStep4()">Save</button>
                </div>
            </form>
        </mat-step>

        <!-- Step 5 - Fabric Information -->
        <mat-step>
            <ng-template matStepLabel>Create Fabric</ng-template>
            <form [formGroup]="fabricForm">
                <p class=" font-medium">Provide details about fabric.</p>
                <p class="text-secondary mb-5">To ensure clarity and consistency, please adhere to one of the following
                    collection text formats:<br> CapitalThenSmall (e.g., Wool) or ALL CAPS (e.g., WOOL)

                </p>

                <div class="flex">
                    <mat-form-field [ngClass]="formFieldHelpers"
                        class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix" style="max-width:500px;">
                        <input matInput oninput="this.value = this.value.toUpperCase()" formControlName="name"
                            placeholder="Primary Fabric" required>
                        <mat-icon class="icon-size-4" matPrefix svgIcon="feather:wind"></mat-icon>

                    </mat-form-field>

                    <mat-form-field [ngClass]="formFieldHelpers"
                        class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix ml-3" style="max-width:500px;">
                        <input matInput oninput="this.value = this.value.toUpperCase()" formControlName="sku"
                            placeholder="Fabric SKU" required>
                        <mat-icon class="icon-size-4" matPrefix svgIcon="feather:hash"></mat-icon>

                    </mat-form-field>
                </div>
                <div class="flex justify-start mt-5">
                    <button class="px-8 mr-2" mat-flat-button [color]="'accent'" type="button"
                        [disabled]="fabricForm.pristine || fabricForm.invalid" (click)="saveStep5()">Save</button>
                </div>
            </form>
        </mat-step>
    </mat-horizontal-stepper>



</div>