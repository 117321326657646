import { Route } from '@angular/router';


import { ListinventoryEntryComponent } from './listinventory-entry/listinventory-entry.component';
import { CreateinventoryEntryComponent } from './createinventory-entry/createinventory-entry.component';
import { DetailinventoryEntryComponent } from './detailinventory-entry/detailinventory-entry.component';



export const InventoryEntryRoutes: Route[] = [
   
        {
            path     : '',
            component: CreateinventoryEntryComponent,
        },
        {
            path         : 'NEW',
            component    : ListinventoryEntryComponent,
           
        },
        {
            path         : ':id',
            component    :DetailinventoryEntryComponent,
          
          
        },
]
