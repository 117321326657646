import { NgModule } from '@angular/core';
import { InventroyAdjustmentComponent } from './inventroy-adjustment/inventroy-adjustment.component';
import { ListinventroyAdjustmentComponent } from './listinventroy-adjustment/listinventroy-adjustment.component';
import { DetailinventroyAdjustmentComponent } from './detailinventroy-adjustment/detailinventroy-adjustment.component';
import { CreateinventroyAdjustmentComponent } from './createinventroy-adjustment/createinventroy-adjustment.component';
import { MaterialModule } from 'app/modules/material/material.module';
import { RouterModule } from '@angular/router';
import { InventoryAdjustmentRoutes } from './inventory-adjustment.routing';
import { MAT_DATE_FORMATS } from '@angular/material/core';

import { CommonModule, DatePipe } from '@angular/common';


@NgModule({
  declarations: [
    InventroyAdjustmentComponent,
    ListinventroyAdjustmentComponent,
    DetailinventroyAdjustmentComponent,
    CreateinventroyAdjustmentComponent
  ],
  imports: [
    RouterModule.forChild(InventoryAdjustmentRoutes),
    MaterialModule,
    CommonModule
  ], providers: [
    {
        provide: MAT_DATE_FORMATS,
        useValue: {
            parse: {
                dateInput: "YYYY-MM-DD"
            },
            display: {
                dateInput: 'YYYY-MM-DD',
                monthYearLabel: 'MMM YYYY',
                dateA11yLabel: 'LL',
                monthYearA11yLabel: 'MMMM YYYY'

            }
        }
    },
    DatePipe,
],
  
})
export class InventroyAdjustmentModule { }
