import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pre-orders',
  templateUrl: './pre-orders.component.html',
  styleUrls: ['./pre-orders.component.scss']
})
export class PreOrdersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
