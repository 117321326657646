import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { products } from './products.type';
import { HttpHeaders } from '@angular/common/http';
import { productVaraitons } from 'app/productVariations/productVariations.service';
import { server_env } from 'config';

@Injectable({
    providedIn: 'root',
})
export class productsService {
    // Private
    private _products: BehaviorSubject<products | null> = new BehaviorSubject(
        null
    );
    private _variation: BehaviorSubject<products | null> = new BehaviorSubject(
        null
    );
    private _prevariation: BehaviorSubject<products | null> = new BehaviorSubject(
        null
    );
    private _nonprevariation: BehaviorSubject<products | null> = new BehaviorSubject(
        null
    );
    private _productsss: BehaviorSubject<products[] | null> =
        new BehaviorSubject(null);


        private _varaitons: BehaviorSubject<productVaraitons[] | null> =
        new BehaviorSubject(null);



        private _prevaraitons: BehaviorSubject<productVaraitons[] | null> =
        new BehaviorSubject(null);



        private _nonvaraitons: BehaviorSubject<productVaraitons[] | null> =
        new BehaviorSubject(null);
    public url = `${server_env.URL}api/v1/products/`;
    public url2 = `${server_env.URL}api/v1/productVariants/`;
    
    public urlS = `${server_env.URL}api/v1/scheduled_update/`;
    public url3 = `${server_env.URL}api/v1/n_products/`;
    public url4  = `${server_env.URL}api/v1/p_products/`;

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors

    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for contact
     */
    get products$(): Observable<products> {
        return this._products.asObservable();
    }
    get productsV$(): Observable<productVaraitons> {
        return this._variation.asObservable();
    }

    get productsVP$(): Observable<productVaraitons> {
        return this._prevariation.asObservable();
    }
    get productsVNP$(): Observable<productVaraitons> {
        return this._nonprevariation.asObservable();
    }
    /**
     * Getter for contacts
     */
    get productss$(): Observable<products[]> {
        return this._productsss.asObservable();
    }


    get productssV$(): Observable<products[]> {
        return this._productsss.asObservable();
    }

    
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get contacts
     */

    getproducts(): Observable<products[]> {
        return this._httpClient.get<products[]>(`${this.url}`).pipe(
            tap((products: any) => {
                // console.log();
                this._productsss.next(products.data);

                for (products in products.data) {
                    // console.log(products, 'sdas');
                }
            })
        );
    }

    getNonPreOrderproducts(): Observable<products[]> {
        return this._httpClient.get<products[]>(`${this.url3}`).pipe(
            tap((products: any) => {
                // console.log();
                this._productsss.next(products.data);

                for (products in products.data) {
                    // console.log(products, 'sdas');
                }
            })
        );
    }


    getPreOrderproducts(): Observable<products[]> {
        return this._httpClient.get<products[]>(`${this.url4}`).pipe(
            tap((products: any) => {
                // console.log();
                this._productsss.next(products.data);

                for (products in products.data) {
                    // console.log(products, 'sdas');
                }
            })
        );
    }




    getProductVariations(productId: string): Observable<any> {
        const requestUrl = `${server_env.URL}api/v1/product-variations/${productId}/`
        return this._httpClient.get<any[]>(`${requestUrl}`).pipe(
            tap((variants: any) => {
                this._varaitons.next(variants.data);

                for (variants in variants.data) {
                }
            })
        );
    }


    getProductPreOrderVariations(productId: string): Observable<any> {
        const requestUrl = `${server_env.URL}api/v1/product-variations-preorder/${productId}/`
        return this._httpClient.get<any[]>(`${requestUrl}`).pipe(
            tap((variants: any) => {
                this._prevaraitons.next(variants.data);

                for (variants in variants.data) {
                }
            })
        );
    }


    getNoNPreVariations(productId: string): Observable<any> {
        const requestUrl = `${server_env.URL}api/v1/product-variations-no-preorder/${productId}/`
        return this._httpClient.get<any[]>(`${requestUrl}`).pipe(
            tap((variants: any) => {
                this._nonprevariation.next(variants.data);

                for (variants in variants.data) {
                }
            })
        );
    }
    /**
     * Get contact by id
     */
    getproductsById(id: string): Observable<products> {
        const requestUrl = this.url + id;

        return this._httpClient.get<products[]>(`${requestUrl}`).pipe(
            take(1),
            map((products: any) => {
                this._products.next(products.data);

                // Return the contact
                return products.data;
            }),
            switchMap((products) => {
                if (!products) {
                    return throwError(
                        'Could not found contact with id of ' + id + '!'
                    );
                }

                return of(products);
            })
        );
    }

    /**
     * Create contact
     */
    // createproducts(): Observable<products>
    // {
    //     return this.products$.pipe(
    //         take(1),
    //         switchMap(products => this._httpClient.post<products>(`${this.url}`, {}).pipe(
    //             map((newproducts) => {

    //                 // Update the contacts with the new contact
    //                 this._productsss.next([newproducts, ...products]);

    //                 // Return the new contact
    //                 return newproducts;
    //             })
    //         ))
    //     );
    // }

    /**
     * create customer
     * @param data
     * @returns
     */

    createproductss(data: FormData): Promise<any> {
        const requestUrl = this.url;
        const headers = new HttpHeaders();

        // Remove the default 'Content-Type' header to allow Angular to automatically set the appropriate headers for multipart form data
        headers.delete('Content-Type');

        return this._httpClient
            .post<any>(requestUrl, data, { headers })
            .toPromise();
    }

    /**
     * create Product Varients
     * @param data
     * @returns
     */

    createProductVarients(data): Promise<any> {
        return new Promise((resolve, reject) => {
            const requestUrl = this.url2;

            this._httpClient.post<any>(`${requestUrl}`, data).subscribe(
                (response) => {
                    return resolve(response);
                },
                (error) => {
                    return reject(error);
                }
            );
        });
    }

    /**
     * Update contact
     *
     * @param id
     * @param contact
     */

    
     updateproductsVarients(id: string, formData: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.put(`${this.url2}${id}/`, formData, { observe: 'response' })
                .subscribe(
                    (response) => {
                        const updatedContact = response.body;
    
                        // Update the Contact if it's selected
                        this.productsV$.pipe(
                            take(1),
                            filter(item => item && item.id === id),
                            tap(() => {
                                this._variation.next(updatedContact);
                            })
                        ).subscribe();
    
                        resolve(response);
                    },
                    (error) => {
                        reject(error);
                    }
                );
        });
    }
    
  /**
     * Update contact
     *
     * @param id
     * @param contact
     */

    
  updateproductsVarientsScheduled(id: string, formData: any): Promise<any> {
    return new Promise((resolve, reject) => {
        this._httpClient.put(`${this.urlS}${id}/`, formData, { observe: 'response' })
            .subscribe(
                (response) => {
                    const updatedContact = response.body;

                    // Update the Contact if it's selected
                    this.productsV$.pipe(
                        take(1),
                        filter(item => item && item.id === id),
                        tap(() => {
                            this._variation.next(updatedContact);
                        })
                    ).subscribe();

                    resolve(response);
                },
                (error) => {
                    reject(error);
                }
            );
    });
}

/**
 * Update contact
 *
 * @param id
 * @param contact
 */
 updateproducts(id: string, contact: any): Observable<any> {
    return this._httpClient.put(`${this.url}${id}/`, contact, { observe: 'response' })
      .pipe(
        switchMap(response => {
          const updatedContact = response.body;
  
          // Update the Contact if it's selected
          this.products$.pipe(
            take(1),
            filter(item => item && item.id === id),
            tap(() => {
              this._products.next(updatedContact);
            })
          ).subscribe();
  
          return of(response);
        }),
        catchError(error => throwError(error))
      );
  }
  

    /**
     * Delete the contact
     *
     * @param id
     */
    deleteproducts(id: string): Observable<boolean> {
        return this.productss$.pipe(
            take(1),
            switchMap((products) =>
                this._httpClient.delete(`${this.url}${id}/`).pipe(
                    map((isDeleted: boolean) => {
                        // Find the index of the deleted contact
                        const index = products.findIndex(
                            (item) => item.id === id
                        );

                        // Delete the contact
                        products.splice(index, 1);

                        // Update the contacts
                        this._productsss.next(products);

                        // Return the deleted status
                        return isDeleted;
                    })
                )
            )
        );
    }
}
