import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { RoleService } from 'app/core/auth/guards/role.service';
import { VendorsPaymentService } from '../vendors-payment.service';
import { VendorsExpenseComponent } from 'app/vendors-expense/vendors-expense/vendors-expense.component';
import { VendorsPaymentComponent } from '../vendors-payment/vendors-payment.component';
import Swal from 'sweetalert2';


@Component({

  selector: 'app-listvendors-payment',
  templateUrl: './listvendors-payment.component.html',
  styleUrls: ['./listvendors-payment.component.scss'],


})
export class ListvendorsPaymentComponent implements OnInit, AfterViewInit {
  date

  PAYMENT_METHOD_CHOICES = [
    ['CASH ON DELIVERY', 'CASH ON DELIVERY'],
    ['SIDDHARTHA BANK FONEPAY - HEADQUARTER', 'SIDDHARTHA BANK FONEPAY - HEADQUARTER'],
    ['SIDDHARTHA BANK FONEPAY - JHAMSIKHEL', 'SIDDHARTHA BANK FONEPAY - JHAMSIKHEL'],
    ['NIC ASIA BANK FONEPAY - HEADQUARTER', 'NIC ASIA BANK FONEPAY - HEADQUARTER'],
    ['NIC ASIA BANK FONEPAY', 'NIC ASIA BANK FONEPAY'],
  ];

  displayedColumns: string[] = [
    'expense_number',
    'vendor_id',
    'vendor_name',
    'vendor_contact_person', // Ensure this is included in displayedColumns
    'expense_date',
    'expense_head',
    'expense_category',
    'total',
    'is_approved',
    "is_credit",
    'expense_type',
    'actions'
  ];

  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  flashMessage: 'success' | 'error' | null = null;
  isLoading: boolean = false;
  expense: any;
  private _activatedRoute: any;
  expense$: Observable<any[]>;

  requiredRoles: string[] = ["Manager"];
  requiredRolesA: string[] = ["Accountant", "Manager"];

  constructor(
    private _router: Router,
    private _expenseService: VendorsPaymentService,
    private dialog: MatDialog,
    private roleService: RoleService,


  ) {


    this.dataSource = new MatTableDataSource();

  }


  ngOnInit(): void {
    this.loadData();
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  createVendorsCredit() {
    this.roleService.withRoleCheck(this.requiredRoles, async () => {

      this._router.navigate(['apps/vendors-payment', 'NEW'], { relativeTo: this._activatedRoute });
    });
  }
  updateVendorsCredit() {



  }
  deleteVendorsCredit() {

  }
  showDetails(id: any) {
    this._router.navigate(['apps/vendors-payment', `${id}`],);

  }


  loadData() {
    this._expenseService.getVendorsPayment().pipe(take(1)).subscribe((expense) => {
      this.dataSource.data = expense['data'].map(item => ({
        ...item,
        vendor_id: item?.vendor?.id,
        vendor_name: item.vendor.firstName + item.vendor.lastName,
        vendor_contact_person: item.vendor.contact_person,
        expenseType: item.otherExpenseType ? item.otherExpenseType : item.expense_type,
        paymentMethod: item.payment_method,
        is_credit: item.is_credit
      }));

      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


  approvePayment(id: any) {
    this.roleService.withRoleCheck(this.requiredRoles, async () => {
      const refundData = {
        is_approved: true
      }
  
      try {
        const response = await this._expenseService.PartialupdateExpense(id, refundData);
  
        // Check if the response is successful
        if (response) {
          // Show Swal success message
          Swal.fire({
            title: 'Success!',
            text: 'The payment has been approved successfully.',
            icon: 'success',
            confirmButtonText: 'OK'
          });
          this.loadData();
        } else {
          // Handle case where response is not successful
          Swal.fire({
            title: 'Error',
            text: 'The payment could not be approved. Please try again.',
            icon: 'error',
            confirmButtonText: 'OK'
          });
        }
      } catch (error) {
        // Handle any error that occurs during the request
        Swal.fire({
          title: 'Error',
          text: 'An error occurred while approving the payment.',
          icon: 'error',
          confirmButtonText: 'OK'
        });
      }
    });
  }
  

  openRefundPackage(id: any, total: number, vendor) {

    this.roleService.withRoleCheck(this.requiredRoles, async () => {
      const dialogRef = this.dialog.open(VendorsPaymentComponent, {
        width: '900px',
        data: { id, total, vendor },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result && result.saved === true) {
          this.loadData();
        } else {
        }
      });
    });
  }

}




