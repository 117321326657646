import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-bundle',
  templateUrl: './product-bundle.component.html',
  styleUrls: ['./product-bundle.component.scss']
})
export class ProductBundleComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
