import { Route } from '@angular/router';
import { ListSalesReturnComponent } from './list-sales-return/list-sales-return.component';
import { CreateSalesReturnComponent } from './create-sales-return/create-sales-return.component';
import { DetailSalesReturnComponent } from './detail-sales-return/detail-sales-return.component';
import { UnsavedChangesGuard } from 'app/page-detect/wm-can-leave/detect-changes.guard';



export const SalesReturnRoutes: Route[] = [
   
        {
            path     : '',
            component: ListSalesReturnComponent,
        },
        {
            path         : 'NEW',
            component    : CreateSalesReturnComponent,
            canDeactivate: [UnsavedChangesGuard], 

           
        },
        {
            path         : ':id',
            component    :DetailSalesReturnComponent,
          
          
        },
]
