import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { productBundleService } from '../product-bundle.service';
import { ActivatedRoute } from '@angular/router';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { productBundle } from '../product-bundle.type';
import { productVaraitons } from 'app/productVariations/productVariations.service';
import { map,take  } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { productVaraitonService } from 'app/productVariations/productVarations.type';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-detail-product-bundle',
  templateUrl: './detail-product-bundle.component.html',
  styleUrls: ['./detail-product-bundle.component.scss']
})
export class DetailProductBundleComponent implements OnInit {

  productId: string;
  editMode: boolean = false;
  productBundleForm: FormGroup;
  product_bundle: productBundle;
  product_bundles: productBundle[];
 
  filteredProductOptions$: Observable<productVaraitons[]>;

  constructor(private route: ActivatedRoute,
    private productService: productBundleService, private _productService: productVaraitonService, private _formBuilder: FormBuilder, private _changeDetectorRef: ChangeDetectorRef,) {

    const products$: Observable<productVaraitons[]> = this._productService
      .getproductVaraiton()
      .pipe(
        take(1),
        map((response) => response)
      );

    products$.subscribe((products) => {
      console.log(products['data'], 'Products');
    });
    this.route.params.subscribe(params => {
      const id = params['id'];
      this.fetchProductDetails(id);
    });
  }

  ngOnInit(): void {


    this.productBundleForm = this._formBuilder.group({
      bundle_name: [''],
      bundle_description: [''],
      products: this._formBuilder.array([]),
   

    });

    this.productService.products$
      .subscribe((product_bundle: productBundle) => {
        this.product_bundle = product_bundle;
        this.productBundleForm.patchValue(product_bundle);
        const productFormArray = this.productBundleForm.get('products') as FormArray;
        productFormArray.clear();
        if (product_bundle.products.length > 0) {
          product_bundle.products.forEach(product => {
            const productFormGroup = this._formBuilder.group({
              product: product.product,
             
            });
            productFormArray.push(productFormGroup);
          });
        }
    
        this.toggleEditMode(false);
        this._changeDetectorRef.markForCheck();
      });
  }


  toggleEditMode(editMode: boolean | null = null): void {
    if (editMode === null) {
      this.editMode = !this.editMode;
    }
    else {
      this.editMode = editMode;
    }

    this._changeDetectorRef.markForCheck();
  }


  addProductField(): void {
    const productFormGroup = this._formBuilder.group({
      id:[''],
      product: ['',],
    
    });

    const productArray = this.productBundleForm.get('products') as FormArray;
    productArray.push(productFormGroup);

    const index = productArray.length - 1;

  }

  printPage() {
    const buttonsToHide = document.querySelectorAll('button');

    buttonsToHide.forEach((button) => {
      button.style.display = 'none';
    });

    window.print();

    setTimeout(() => {
      buttonsToHide.forEach((button) => {
        button.style.display = 'block'; 
      });
    }, 1000); 
  }



   /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }





  fetchProductDetails(productId: string): void {
    this.productService.getproductsById(productId).subscribe(
      (data) => {
        this.product_bundle = data;

      },
      (error) => {
        console.error('Error fetching product details:', error);
      }
    );
  }




  async updateProductBundle() {
    if (!this.productBundleForm.valid) {
      this.showFormValidationError();
      return;
    }

    const customerData = { ...this.productBundleForm.value };
    const id = this.product_bundle.id;
    const products = this.productBundleForm.get('products') as FormArray;

    const updatedProducts = products.value.map((item: any) => ({
      product: item.id,
   

    }));

    customerData.products = updatedProducts;

    try {
      const updatedproduct_bundle = await this.updateproduct_bundleOnServer(id, customerData);
      this.handleUpdateSuccess(updatedproduct_bundle, id);
    } catch (error) {
      this.handleUpdateError(error);
    } finally {
      this.toggleEditMode(false);
    }

    this._changeDetectorRef.markForCheck();
  }

  private async updateproduct_bundleOnServer(id: string, data: any): Promise<productBundle> {
    return new Promise<productBundle>((resolve, reject) => {

      this.productService.updateproducts(id, data).subscribe(
        (customerData: productBundle) => {
          resolve(customerData);
        },


        (error) => {
          reject(error);
        }
      );

    });
  }

  private handleUpdateSuccess(updated_product_bundle: productBundle, id: string) {
    this.product_bundle = updated_product_bundle;
    this.productBundleForm.patchValue(updated_product_bundle);
    const productFormGroup = this.productBundleForm.get('products') as FormGroup;
    productFormGroup.patchValue({}); 

    if (updated_product_bundle.products) {
      updated_product_bundle.products.forEach((produtBundleData) => {
        productFormGroup.patchValue({
          product: produtBundleData.product,
        
        });
      });
    }
    this.productService.getproductsById(id).subscribe((product_bundle: productBundle) => {
      this.product_bundle = product_bundle;
    })

    this.reloadproduct_bundlesList();

    this.showSuccessToast('Product Bundle Updated!!');
  }


  private handleUpdateError(error: any) {

    this.showErrorToast('Error Updating Product Bundle');

    console.error('Error updating Bundle:', error);
  }


  private showFormValidationError() {
    this.showErrorToast('Form Invalid');
  }

  private reloadproduct_bundlesList() {
    this.productService.getproducts().subscribe(() => {
    });


  }


  private showSuccessToast(message: string) {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: 'success',
      title: message,
    });
  }


  private showErrorToast(message: string) {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: 'error',
      title: message,
    });
  }



}
