import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { SalesReturnComponent } from './sales-return/sales-return.component';
import { CreateSalesReturnComponent } from './create-sales-return/create-sales-return.component';
import { ListSalesReturnComponent } from './list-sales-return/list-sales-return.component';
import { DetailSalesReturnComponent } from './detail-sales-return/detail-sales-return.component';
import { MaterialModule } from 'app/modules/material/material.module';
import { RouterModule } from '@angular/router';
import { SalesReturnRoutes } from './sales-return.routing';
import { MatDialogModule } from '@angular/material/dialog';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { CustomSelectModule } from 'app/custom-select/custom-select.module';
import { MAT_DATE_FORMATS } from '@angular/material/core';



@NgModule({
  declarations: [
    SalesReturnComponent,
    CreateSalesReturnComponent,
    ListSalesReturnComponent,
    DetailSalesReturnComponent, 
  ],
  imports: [
    RouterModule.forChild(SalesReturnRoutes),
    CommonModule,
    MaterialModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    CustomSelectModule,
    CommonModule

  ],
  providers   : [
    
    DatePipe
]
})
export class SalesReturnModule { }
