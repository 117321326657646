<div class="sm:absolute sm:inset-0 flex flex-col flex-auto min-w-0 sm:overflow-hidden bg-card dark:bg-transparent">
    <!-- Header -->
    <div
        class="relative flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between py-8 px-6 md:px-8 border-b">
        <!-- Loader -->
        <div class="absolute inset-x-0 bottom-0" *ngIf="isLoading">
            <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
        </div>
        <!-- Title -->
        <div class="text-4xl font-bold tracking-tight">
            Sales Exchange
        </div>
        <!-- Actions -->
        <div class="flex flex-shrink-0 items-center mt-6 sm:mt-0 sm:ml-4">
            <!-- Search -->

            <!-- Add product button -->
            <button class="ml-4 mr-4" mat-flat-button [color]="'primary'" (click)="createSalesExchange()">
                <mat-icon matTooltip="Add new Sales Order" [svgIcon]="'heroicons_outline:plus'"></mat-icon>
                <span class="ml-2 mr-1">Add</span>
            </button>

            <button [color]="'primary'" mat-flat-button (click)="multiplePackage()">
                <mat-icon matTooltip="Export your tabel to excel" [svgIcon]="'heroicons_outline:clipboard'"></mat-icon>
                <span class="ml-2 mr-1">Shipment </span>
            </button>

        </div>

    </div>
    <div class="flex flex-shrink-0 items-center mt-6 sm:mt-0 sm:ml-4">


        <div>


            <button [matMenuTriggerFor]="menu" mat-flat-button [color]="'primary'">
                Action
                <mat-icon svgIcon="feather:chevron-down"></mat-icon>
            </button>

            <mat-menu #menu>

                <button (click)="multipleInvoice()" class="text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                    mat-menu-item>
                    Instant Invoice
                </button>
            </mat-menu>
        </div>


        <div class="flex flex-shrink-0 items-center ml-3 mt-3">
            <mat-form-field style="min-width:200px;" class="flex-auto fuse-mat-emphasized-affix fuse-mat-rounded ">
                <input matInput [matDatepicker]="picker" placeholder="Select a date" [(ngModel)]="selectedDate"
                    (ngModelChange)="applyFilters()">
                <mat-datepicker-toggle matSuffix [for]="picker" class="smaller-datepicker-toggle">
                </mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>


            </mat-form-field>



            <mat-form-field style="min-width: 200px;"
                class="flex-auto justify-between ml-3 fuse-mat-rounded fuse-mat-emphasized-affix">
                <mat-select style="font-family:Montserrat" [(ngModel)]="selectedShippingArea"
                    (ngModelChange)="applyFilters()" placeholder="Shipping Area">
                    <mat-option style="font-size: smaller; font-family:Montserrat" value="">----</mat-option>
                    <mat-option style="font-size: smaller; font-family:Montserrat" value="Inside Valley">INSIDE
                        VALLEY</mat-option>
                    <mat-option style="font-size: smaller; font-family:Montserrat" value="Outside Valley">OUTSIDE
                        VALLEY</mat-option>
                    <mat-option style="font-size: smaller; font-family:Montserrat" value="International">
                        INTERNATIONAL</mat-option>
                </mat-select>
                <mat-icon class="icon-size-4" matPrefix [svgIcon]="'feather:globe'"></mat-icon>
            </mat-form-field>

            <mat-form-field style="min-width: 200px;" class="flex-auto ml-3 fuse-mat-emphasized-affix fuse-mat-rounded">
                <mat-select style="font-family:Montserrat" [(ngModel)]="selectedDeliveryType"
                    (ngModelChange)="applyFilters()" placeholder="Delivery Type">
                    <mat-option style="font-size: smaller; font-family:Montserrat" value="">----</mat-option>
                    <mat-option style="font-size: smaller; font-family:Montserrat" value="Normal">Normal
                    </mat-option>
                    <mat-option style="font-size: smaller; font-family:Montserrat" value="Express">Express
                    </mat-option>
                </mat-select>

                <mat-icon class="icon-size-4" matPrefix [svgIcon]="'feather:truck'"></mat-icon>
            </mat-form-field>

            <mat-form-field style="min-width: 320px;" class="flex-auto ml-3 fuse-mat-emphasized-affix fuse-mat-rounded">
                <mat-icon matPrefix [svgIcon]="'heroicons_outline:search'"></mat-icon>
                <input style="font-family:Montserrat" matInput [autocomplete]="'off'" (keyup)="applyFilter($event)"
                    [placeholder]="'Search Exchanges'" />
            </mat-form-field>
        </div>

    </div>

    <!-- Main -->
    <div class="flex flex-auto overflow-hidden">
        <!-- Products list -->
        <div class="flex flex-col flex-auto sm:mb-18 overflow-hidden sm:overflow-y-auto">
            <div class="mat-elevation-z8">
                <table id="excel-table" #salesOrderTabel mat-table [dataSource]="dataSource" matSort matPaginator
                    class="mat-table">
                    <!-- CustomerName Column -->
                    <ng-container matColumnDef="select">
                        <th mat-header-cell *matHeaderCellDef>
                            <mat-checkbox (change)="$event ? toggleAllRows() : null"
                                [checked]="selection.hasValue() && isAllSelected()"
                                [indeterminate]="selection.hasValue() && !isAllSelected()"
                                [aria-label]="checkboxLabel()">
                            </mat-checkbox>
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <mat-checkbox (click)="$event.stopPropagation()"
                                (change)="$event ? updateSelectedRowIds(row) : null"
                                [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
                            </mat-checkbox>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="CustomerName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Name
                        </th>
                        <td mat-cell *matCellDef="let row" class="cursor-pointer">
                            <div class="avatar" (click)="showDetails(row.id)">

                                {{ row.customerName }}
                            </div>

                        </td>
                    </ng-container>

                    <!-- SalesNumber Column -->
                    <ng-container matColumnDef="SaleNumber">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            <strong>Ex No</strong>
                        </th>
                        <mat-icon svgIcon="heroicons_outline:user"></mat-icon>
                        <div></div>
                        <td mat-cell *matCellDef="let row">
                            <i><u>
                                    {{ row.saleNumber }}
                                </u></i>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="ReturnDate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Date
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <div class="status" [ngClass]="getStatusColor(row)">
                                {{ row.returningDate }}
                            </div>
                        </td>
                    </ng-container>
                    <!-- OrderShipped Date Column -->


                    <!-- reasons for return -->
                    <ng-container matColumnDef="rfr">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Reason </th>
                        <td mat-cell *matCellDef="let row">
                            <div class="status" [ngClass]="getStatusColor(row)">
                                {{ row.rfr }}
                            </div>
                        </td>
                    </ng-container>

                    <!-- Status Column -->
                    <ng-container matColumnDef="Status">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Status
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <div class="avatar">

                                {{ row.status }}
                            </div>

                            <div class="chip chip--success" *ngIf="row.received" style="line-height: 16px;">
                                <i style="font-size: 11px; margin-top: -2px;" class="chip__label">
                                    <strong>Recived</strong>
                                </i>
                            </div>

                            <div class="chip chip--error" *ngIf="row.received === false" style="line-height: 16px;">
                                <i style="font-size: 11px; margin-top: -2px;" class="chip__label">
                                    <strong>Not Recived</strong>
                                </i>
                            </div>
                        </td>


                    </ng-container>

                    <!-- OrderReturn Date Column -->

                    <ng-container matColumnDef="Shipping Area">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Shipping Area
                        </th>
                        <mat-icon svgIcon="heroicons_outline:user"></mat-icon>
                        <div></div>
                        <td mat-cell *matCellDef="let row">
                            {{ row.shipping_area }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="Delivery Type">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Delivery Type
                        </th>
                        <td mat-cell *matCellDef="let row">
                            {{ row.delivery_type }}
                        </td>
                    </ng-container>




                    <ng-container matColumnDef="total">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Price
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <div class="status" [ngClass]="getStatusColor(row)">
                                रू <strong>
                                    {{ row.totalCost }}
                                </strong>
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="total_ex">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Price
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <div class="status" [ngClass]="getStatusColor(row)">
                                रू <strong>
                                    {{ row.totalCoste }}
                                </strong>
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="remiangNewTotal">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            ExchangeTotal
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <div class="status" [ngClass]="getStatusColor(row)">
                                रू
                                <strong>{{ row.remainingNewTotal }}</strong>


                            </div>
                        </td>
                    </ng-container>

                    <!-- Actions Column -->
                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef>Actions</th>
                        <td mat-cell *matCellDef="let row">
                            <button mat-icon-button matTooltip="View" (click)="showDetails(row.id)">
                                <mat-icon class="icon-size-4" svgIcon="heroicons_outline:eye"></mat-icon>
                            </button>

                            <button mat-icon-button matTooltip="Void"
                                *ngIf="row.status === 'Started Exchange Order' || row.status === 'Waiting to Receive' "
                                (click)="deleteVendorsCredit(row.id)">
                                <mat-icon class="icon-size-4" svgIcon="heroicons_outline:trash"></mat-icon>
                            </button>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="create">
                        <th mat-header-cell *matHeaderCellDef>Create</th>
                        <td mat-cell *matCellDef="let row">

                            <button mat-icon-button mat-flat-button *ngIf="row.status != 'Delivered Exchange Order'"
                                [matMenuTriggerFor]="menu">
                                <mat-icon svgIcon="feather:chevron-down"></mat-icon>
                            </button>

                            <mat-menu class="rounded" #menu>
                                <button *ngIf="row.status === 'Started Exchange Order'" (click)="singlePackage(row.id)"
                                    class="text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" mat-menu-item>
                                    Package
                                </button>
                                <button *ngIf="row.status === 'Waiting to Receive'" (click)="confirmReceive(row.id)"
                                    class="text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" mat-menu-item>
                                    Confirm recived
                                </button>
                                <button class="text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                    mat-menu-item (click)="printInvoice(row.id)">
                                    Instant Invoice
                                </button>
                            </mat-menu>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>
                <!-- Footer Row for Statistics -->
                <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
                <tr class="total-row">
                    <td colspan="6" class="total-cell">Total:</td>
                    <td class="total-cell"> {{ calculateTotal() }}</td>

                </tr>



                <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>
            </div>
        </div>
    </div>
</div>