import {
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { Vendor } from 'app/modules/Vendor/vendors.type';
import Swal from 'sweetalert2';
import { VendorCreditService } from 'app/vendors-credit/vendors-credit.service';
import { formatDate } from '@angular/common';
import { VendorsPaymentService } from '../vendors-payment.service';
import { VendorService } from 'app/modules/Vendor/vendorsservice';
import { debounceTime, distinctUntilChanged, map, startWith, switchMap, take } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { VendorpopupComponent } from 'app/vendors-credit/vendorpopup/vendorpopup.component';
export interface VendorOption {
    id: string;
    firstname?: string;
    avatar?: string;
}
@Component({
    selector: 'app-createvendors-payment',
    templateUrl: './createvendors-payment.component.html',
    styleUrls: ['./createvendors-payment.component.scss'],
})
export class CreatevendorsPaymentComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    @ViewChild('vendorInput') vendorInput: ElementRef;
    filteredVendors$: Observable<Vendor[]>;

    vendorsCredit: FormGroup;
    successMessage: string;
    errorMessage: string;
    expenseHeads: any[] = [];
    expenseCategories: any[] = [];
    vendors: Vendor[];
    imagePreview: string | ArrayBuffer | null;
    vendorsCount: number;
    vendorId: number | undefined;
    venders$: Observable<Vendor[]>;
    VendorControl: FormControl = new FormControl();
    selectedVendor: any | null = null;

    vendors$: Observable<Vendor[]>;
    private _subscription: Subscription;
    Tost = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener(
                'mouseenter',
                Swal.stopTimer
            );
            toast.addEventListener(
                'mouseleave',
                Swal.resumeTimer
            );
        },
    });

    @ViewChild('fileInput') fileInput: ElementRef;
    expenseOrderNum: number;
    formSubmitted = true;
    isFieldReadOnly: boolean;


    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _vendorExpenseService: VendorsPaymentService,
        private _formBuilder: FormBuilder,
        private _expenseService: VendorCreditService,
        private _vendorsService: VendorService,
        private dialog: MatDialog,

    ) {

        this.vendors$ = this._vendorsService.getVendors().pipe(take(1));

        this.vendors$.subscribe((vendor) => {
            vendor['data'];
        });
        this._subscription = this._vendorExpenseService.getPayment_number().subscribe(
            (response: any) => {
                if (response.success && response.data !== undefined) {
                    this.expenseOrderNum = response.data;

                    // Now, you can update your form control
                    this.vendorsCredit.controls['expense_number'].setValue(`VP-0-${this.expenseOrderNum}-A`);
                } else {
                    console.error('Invalid response format:', response);
                }
            },
            (error) => {
                console.error('Error fetching sales order numbers:', error);
            }
        );
    }

    ngOnInit() {

        this.filteredVendors$ = this.VendorControl.valueChanges.pipe(
            startWith(''),
            debounceTime(300),
            switchMap((value) => this.filterOptions(value))
        );


        this.vendorsCredit = this._formBuilder.group({
            vendor: [this.VendorControl, Validators.required],
            expense_number: ['EXP-0-'],
            expense_date: ['', Validators.required],
            expense_head: [''],
            expense_category: [''],
            expense_for: [''],
            expense_description: [''],
            total: ['', Validators.required],
            is_credit: [false],
            payment_method: [''],
            credit_valid_date: [''],
            expense_type: [''],
            bill_photo: [null], // Initialize as null
            otherExpenseType: '',

        });
        this.imagePreview = null;

        this.vendorsCredit.get('expense_type').valueChanges.subscribe((value) => {
            if (value === 'other') {
                this.vendorsCredit.get('otherExpenseType').enable();
            } else {
                this.vendorsCredit.get('otherExpenseType').disable();
                this.vendorsCredit.get('otherExpenseType').reset();
            }
        });

        this.vendorsCredit.get('expense_date').valueChanges.subscribe((value) => {
            if (value) {
                const formattedDate = formatDate(value, 'yyyy-MM-dd', 'en-US');
                this.vendorsCredit.get('expense_date').setValue(formattedDate, { emitEvent: false });
            }
        });
        this.loadExpenseHeads();
        this.loadExpenseCategories();
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    loadExpenseHeads(): void {
        this._expenseService.getExpenseHead().subscribe(data => {
            const main_data = data['data']

            this.expenseHeads = main_data.map((item: any) => ({ id: item.id, name: item.name }));
        });
    }
    handleOtherExpenseTypeChange() {
        const otherExpenseTypeValue = this.vendorsCredit.get('otherExpenseType').value;
        if (otherExpenseTypeValue) {
            this.vendorsCredit.get('expense_type').setValue('other');
        }
    }
    triggerFileInput(): void {
        this.fileInput.nativeElement.click();
    }

    onFileSelected(event: Event): void {
        const file = (event.target as HTMLInputElement).files?.[0];
        if (file) {
            // Patch the form control with the selected file
            this.vendorsCredit.patchValue({ bill_photo: file });
            this.vendorsCredit.get('bill_photo')?.updateValueAndValidity();


            // Create a FileReader to preview the image
            const reader = new FileReader();
            reader.onload = () => {
                this.imagePreview = reader.result as string;
            };
            reader.readAsDataURL(file);
        } else {
        }
    }
    openVendorPopup(): void {
        const dialogRef = this.dialog.open(VendorpopupComponent, {
            width: '600px',
            height: '95vh',
            panelClass: 'custom-dialog-container', // Add a custom class for styling
            data: this.selectedVendor, // Pass the selected vendor as data to the dialog
        });

        dialogRef.componentInstance.formSubmitted.subscribe((formData) => {

            this._vendorsService
                .createVendors(formData)

                .then((response) => {
                    this.selectOption(response.data);
                    this.VendorControl.markAsUntouched();

                    this.formSubmitted = false;
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener(
                                'mouseenter',
                                Swal.stopTimer
                            );
                            toast.addEventListener(
                                'mouseleave',
                                Swal.resumeTimer
                            );
                        },
                    });

                    Toast.fire({
                        icon: 'success',
                        title: ` ${formData.first_name} Vendor  created!! `,
                    });
                })
                .catch((error) => {
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener(
                                'mouseenter',
                                Swal.stopTimer
                            );
                            toast.addEventListener(
                                'mouseleave',
                                Swal.resumeTimer
                            );
                        },
                    });

                    Toast.fire({
                        icon: 'error',
                        title: `'Error creating Vendor: ' ${error.message};`,
                    });
                });
        });
    }

    clearImage(): void {
        this.imagePreview = null;
        this.vendorsCredit.patchValue({ bill_photo: null });
        this.vendorsCredit.get('bill_photo')?.updateValueAndValidity();
    }

    toggleFullScreen(event: MouseEvent): void {
        const target = event.target as HTMLElement;
        if (!document.fullscreenElement) {
            target.requestFullscreen().catch(err => console.log(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`));
        } else {
            document.exitFullscreen();
        }
    }

    loadExpenseCategories(): void {
        this._expenseService.getExpenseCategory().subscribe(data => {
            const main_data = data['data']
            this.expenseCategories = main_data.map((item: any) => ({ id: item.id, name: item.name }));
        });
    }
    submitVendorsInfo() {
        if (this.vendorsCredit.valid) {
            const formData = new FormData();
            const vendor = this.VendorControl.value;
    
            let extracted_vendor: number | null = null;
    
            // Check if vendor is an object with an ID and extract the ID
            if (typeof vendor === 'object' && vendor !== null && 'id' in vendor) {
                const vendorId = vendor.id;
                extracted_vendor = parseInt(vendorId, 10);
            }
    
            // Check if extracted_vendor is valid
            if (!extracted_vendor || isNaN(extracted_vendor)) {
                console.error('Vendor ID not found or invalid');
                return;
            }
    
            // Append vendor ID as string
            formData.append('vendor', extracted_vendor.toString());
    
            // Add other form fields to formData
            const formValue = this.vendorsCredit.value;
            for (const key in formValue) {
                if (formValue.hasOwnProperty(key)) {
                    const value = formValue[key];
                    if (key === 'vendor' && (typeof value === 'object' || value === null)) {
                        // Skip adding 'vendor' field if it's an object or null
                        continue;
                    }
                    if (value !== null && value !== undefined && value !== '') {
                        if (!Array.isArray(value) || value.length > 0) {
                            formData.append(key, value);
                        }
                    }
                }
            }
    
            // Handle bill photo file
            const billPhotoFile = this.vendorsCredit.get('bill_photo')?.value;
            if (billPhotoFile instanceof File) {
                formData.append('bill_photo', billPhotoFile);
            }
    
            // Submit to the service
            this._vendorExpenseService.createVendorsPaymentss(formData)
                .then((response) => {
                    if (response.success) {
                        this.Tost.fire({
                            icon: 'success',
                            title: 'Vendor Payment Order created!',
                        });
                        this.vendorsCredit.reset();
                        this._router.navigate(['apps/vendors-payment']);
                    } else {
                        const errorMessages = response.errors;
                        this.Tost.fire({
                            icon: 'error',
                            title: 'Error creating Vendor Payment Order',
                            text: JSON.stringify(errorMessages),
                        });
                    }
                })
                .catch((error) => {
                    this.Tost.fire({
                        icon: 'error',
                        title: `Error creating Vendors Order: ${error.message}`,
                    });
                });
        } else {
            this.Tost.fire({
                icon: 'error',
                title: 'Invalid form data',
                text: 'Please fill in all the required fields correctly.',
            });
        }
    }
    
    

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------
    filterOptions(value: string): Observable<Vendor[]> {
        if (typeof value !== 'string') {
            return of([]); // Return an empty array if value is not a string
        }
        this.formSubmitted = true;

        const filterValues = new Set(value.trim().toLowerCase().split(' '));

        return this.vendors$.pipe(
            map((vendors) => {
                if (Array.isArray(vendors['data'])) {
                    return vendors['data'].filter((vendor) =>
                        this.matchesFilter(vendor, filterValues)
                    );
                } else {
                    console.error('Vendors data is not available:', vendors);
                    return [];
                }
            })
        );
    }
    displayOption(vendor: Vendor): string {
        const last_name = vendor?.lastName || '';
        const first_name = vendor?.firstName || '';
        const contact_person = vendor?.contact_person || '';
        const id = vendor?.id || '';

        // Construct the display string based on the available values
        let displayName = '';

        // Add contact person if available
        if (contact_person) {
            displayName += `${contact_person} - `;
        }

        // Add first and last names if available
        if (first_name && last_name) {
            displayName += id ? `${first_name} ${last_name} (VHUB${id})` : `${first_name} ${last_name}`;
        } else if (first_name) {
            displayName += id ? `${first_name} (VHUB${id})` : first_name;
        } else if (last_name) {
            displayName += id ? `${last_name} (VHUB${id})` : last_name;
        } else {
            displayName += id ? `(VHUB${id})` : '';
        }

        return displayName;
    }

    genderOptionss(vendor: Vendor): VendorOption[] {
        if (vendor) {
            const mappedVendor: VendorOption = {
                id: vendor.id,
                firstname: vendor.firstName || '',
            };
            return [mappedVendor];
        }
        return [];
    }

    selectOption(option: Vendor) {
        if (!option) {
            return;
        }

        this.VendorControl.setValue(option);
        this.VendorControl.updateValueAndValidity();
        this.selectedVendor = option;
        this.isFieldReadOnly = true;

    }



    makeFieldEditable(customerInput: HTMLInputElement) {
        this.isFieldReadOnly = false;
        this.selectedVendor = null;
        if (customerInput) {
            customerInput.value = '';
        }

        this.selectedVendor.setValue('');
    }




    // Method to check if a vendor is selected
    isVendorSelected(selectedVendor: Vendor): boolean {
        return this.VendorControl.value && selectedVendor && this.VendorControl.value.id === selectedVendor.id;
    }



    // Helper method to check if a name includes at least one part of the filter values
    private includesAnyPart(name: string, filterValues: Set<string>): boolean {
        for (let filterValue of filterValues) {
            if (name.includes(filterValue)) {
                return true;
            }
        }
        return false;
    }

    private matchesFilter(vendor: Vendor, filterValues: Set<string>): boolean {
        const trimmedFirstName = vendor.firstName?.trim().toLowerCase();
        const trimmedLastName = vendor.lastName?.trim().toLowerCase();
        const idWithPrefix = `hub${vendor.id}`.toLowerCase(); // Ensure 'hub' is lowercase

        return (
            (trimmedFirstName && this.includesAnyPart(trimmedFirstName, filterValues)) ||
            (trimmedLastName && this.includesAnyPart(trimmedLastName, filterValues)) ||
            filterValues.has(idWithPrefix)
        );
    }





}
