import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { FuseNavigationItem, FuseNavigationService } from '@fuse/components/navigation';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import { compactNavigation, defaultNavigation, futuristicNavigation, horizontalNavigation } from 'app/mock-api/common/navigation/data';
import { UserMockApi } from '../user/api';

@Injectable({
    providedIn: 'root'
})
export class NavigationMockApi {
    private readonly _compactNavigation: FuseNavigationItem[] = compactNavigation;
    private readonly _defaultNavigation: FuseNavigationItem[] = defaultNavigation;
    private readonly _futuristicNavigation: FuseNavigationItem[] = futuristicNavigation;
    private readonly _horizontalNavigation: FuseNavigationItem[] = horizontalNavigation;

    constructor(
        private _fuseMockApiService: FuseMockApiService,
        private _userMockApi: UserMockApi
    ) {
        this.registerHandlers();
    }

    private _filterNavigation(navigation: FuseNavigationItem[], userRoles: string[]): FuseNavigationItem[] {
        return navigation
            .filter(item => {
                if (item.required_roles && item.required_roles.length) {
                    const hasRequiredRole = item.required_roles.some(role => userRoles.includes(role));
                    return hasRequiredRole;
                }
                return true;
            })
            .map(item => {
                if (item.children) {
                    item.children = this._filterNavigation(item.children, userRoles);
                }
                return item;
            });
    }

    registerHandlers(): void {
        this._fuseMockApiService.onGet('api/common/navigation').reply(() => {
            const user = this._userMockApi.getUser();
            const userRoles = user.roles || [];

            // Fill compact navigation children using the default navigation
            this._compactNavigation.forEach((compactNavItem) => {
                this._defaultNavigation.forEach((defaultNavItem) => {
                    if (defaultNavItem.id === compactNavItem.id) {
                        compactNavItem.children = cloneDeep(defaultNavItem.children);
                    }
                });
            });

            // Fill futuristic navigation children using the default navigation
            this._futuristicNavigation.forEach((futuristicNavItem) => {
                this._defaultNavigation.forEach((defaultNavItem) => {
                    if (defaultNavItem.id === futuristicNavItem.id) {
                        futuristicNavItem.children = cloneDeep(defaultNavItem.children);
                    }
                });
            });

            // Fill horizontal navigation children using the default navigation
            this._horizontalNavigation.forEach((horizontalNavItem) => {
                this._defaultNavigation.forEach((defaultNavItem) => {
                    if (defaultNavItem.id === horizontalNavItem.id) {
                        horizontalNavItem.children = cloneDeep(defaultNavItem.children);
                    }
                });
            });

            const filteredDefaultNavigation = this._filterNavigation(this._defaultNavigation, userRoles);
            const filteredCompactNavigation = this._filterNavigation(this._compactNavigation, userRoles);
            const filteredFuturisticNavigation = this._filterNavigation(this._futuristicNavigation, userRoles);
            const filteredHorizontalNavigation = this._filterNavigation(this._horizontalNavigation, userRoles);

            return [
                200,
                {
                    compact: cloneDeep(filteredCompactNavigation),
                    default: cloneDeep(filteredDefaultNavigation),
                    futuristic: cloneDeep(filteredFuturisticNavigation),
                    horizontal: cloneDeep(filteredHorizontalNavigation)
                }
            ];
        });
    }
}
