<!-- produtBundleForm form -->

<div class="w-full h-full">
    <form [formGroup]="produtBundleForm" class="bg-gray-100 rounded shadow-lg h-full">

        <div *ngIf="produtBundleForm" class="p-8">
            <div class="prose prose-sm max-w-3xl">
            </div>
            <div class="flex justify-end">
                <button mat-raised-button [routerLink]="['..']">
                    <span class="inline-flex items-center">
                        <mat-icon svgIcon="feather:x"></mat-icon>
                    </span>
                </button>
            </div>

            <div class="flex flex-col mt-8">
                <p class="text-lg font-medium">Basic information</p>
                <p class="text-secondary">Set your product bundle information you can just select the base product here
                    when ordering from sales section you can choose it's variations </p>
                <mat-divider class="mt-8 mb-10"></mat-divider>
                <div class="flex mb-4">
                    <mat-form-field style="max-width: 600px"
                        class="flex-auto fuse-mat-emphasized-affix fuse-mat-rounded">
                        <mat-label>Product Bundle Name</mat-label>
                        <input required matInput type="text" oninput="this.value = this.value.toUpperCase()"  placeholder="Add Product Bundle Name"
                            formControlName="bundle_name" aria-label="Customers" />

                        <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:user'"></mat-icon>
                    </mat-form-field>
                </div>

                <div class="flex mb-2">
                    <mat-form-field style="max-width: 600px"
                        class="flex-auto  fuse-mat-emphasized-affix fuse-mat-rounded">
                        <mat-label>Description</mat-label>
                        <textarea required matInput placeholder="Description" formControlName="bundle_description"
                            class="elevated-input"></textarea>
                        <mat-icon class="icon-size-5" matPrefix svgIcon="feather:file-text"></mat-icon>
                    </mat-form-field>
                </div>


                <!-- Add Product Bundle Details here -->
                <mat-divider class="mt-6 mb-4"></mat-divider>

                <ng-container s formArrayName="products">
                    <ng-container *ngFor="
                            let product of produtBundleForm.get('products')
                                .controls;
                            let i = index;
                            let first = first;
                            let last = last;
                            trackBy: trackByFn
                        ">
                        <div class="flex mb-3" [ngClass]="{'fadeInOut': animationFlags[i]}" [formGroupName]="i">

                            <mat-form-field
                                class="fuse-mat-no-subscript w-200 fuse-mat-rounded fuse-mat-emphasized-affix ">

                                <mat-label *ngIf="first">Product</mat-label>
                                <input [readonly]="active[i]" class="small-input-text" style="height: 50px" matInput
                                    formControlName="product" placeholder="Type SKU or Product Name" required
                                    [matAutocomplete]="auto" [ngStyle]="product.style"
                                    [attr.disabled]="product.disabled ? true : null" (input)="
                                    filterOptionsProduct($event.target.value)
                                " (focus)="filterOptionsProduct($event.target.value)" />

                                <mat-icon *ngIf="active[i]" class="icon-size-5" matSuffix [svgIcon]="'verified'"
                                    style="font-size: 24px; color: green;"></mat-icon>


                                <mat-autocomplete #auto="matAutocomplete" [displayWith]="
                                        displayProductOption.bind(this)
                                    " (optionSelected)="
                                        selectOptionProduct(
                                            $event.option.value,
                                            i
                                        )
                                    ">
                                    <mat-option
                                        style="height: auto; display: flex; align-items: center; padding: 4px 16px;"
                                        *ngFor="let option of filteredProductOptions$ | async; let i = index"
                                        [value]="option" [ngStyle]="option.style" [disabled]="option.disabled">

                                        <div style="
                                                display: flex;
                                                align-items: center;
                                            ">

                                            <span class="example-option-name">{{
                                                option.productName
                                                }}</span>
                                            <span class="example-option-sku">
                                                - {{ option.sku }}</span>
                                        </div>
                                    </mat-option>

                                    <mat-option style="
                                            cursor: pointer;
                                            height: fit-content;
                                        " (click)="openProductDetails()" *ngIf="
                                            (filteredProductOptions$ | async)
                                                ?.length === 0
                                        " [disabled]="true">
                                        No options found
                                        <br />
                                        <mat-icon svgIcon="feather:plus">
                                        </mat-icon>
                                        Add Product
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>






                            <!-- Remove product -->
                            <ng-container *ngIf="!(first && last)">
                                <div class="flex items-center w-10 pl-2" [ngClass]="{ 'mt-6': first }">
                                    <button mat-icon-button (click)="removeProductField(i)" matTooltip="Remove">
                                        <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:trash'"></mat-icon>
                                    </button>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                </ng-container>


                <div class="inline-flex items-center mt-2 -ml-4 py-2 px-4">
                    <mat-icon matPrefix class="hidden sm:flex icon-size-5" [svgIcon]="'heroicons_solid:tag'"></mat-icon>
                    <span (click)="addProductField()"
                        class="ml-2 font-medium text-secondary cursor-pointer hover:underline">Add a product</span>
                </div>


                <div class="flex items-center justify-end mt-10">
                    <button (click)="sumbitProductBundleForm()"
                        class="px-6 py-2 rounded text-white bg-blue-500 hover:bg-blue-600" mat-flat-button
                        [color]="'primary'">
                        Save
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>