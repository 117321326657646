<div class="flex flex-col flex-auto min-w-0">

    <div *ngIf="vendor">

        <div
            class="flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between p-6 sm:py-8 sm:px-10 border-b bg-card dark:bg-transparent">
            <div class="flex-1 min-w-0">
                <!-- Breadcrumbs -->
                <div class="flex flex-wrap items-center font-medium">
                    <div>
                        <a class="whitespace-nowrap text-primary-500">Promo Code</a>
                    </div>
                </div>
                <!-- Title -->
                <div class="mt-2">
                    <h2 class="text-3xl md:text-4xl font-extrabold tracking-tight leading-7 sm:leading-10 truncate">
                        Detail Promo Code
                    </h2>
                </div>
            </div>
        </div>


        <div class="flex-auto p-6 sm:p-10">

            <fuse-card class="flex flex-col lg:flex-row max-w-sm lg:max-w-240 filter-pricing">
                <div class="p-6 sm:p-8 lg:p-10">
                    <div class="text-3xl font-bold">{{vendor.name}}</div>
                    <div class="mt-2 leading-relaxed text-secondary">
                        {{vendor.description}}
                    </div>
                    <div class="flex items-center mt-10">
                        <div class="font-medium text-secondary">APPLIED TO</div>
                        <div class="flex-auto ml-2 border-b-2"></div>
                    </div>
                    <!-- Features -->
                    <div class="grid grid-cols-1 lg:grid-cols-2 gap-y-4 mt-6">
                        <div *ngFor="let product of products" class="flex items-center">
                            <mat-icon class="icon-size-5 text-primary"
                                [svgIcon]="'heroicons_solid:check-circle'"></mat-icon>
                            <div class="ml-2">{{ product.product.base_product.productName }} <br>  {{product.size}}
                                {{product.color}} </div>
                        </div>
                    </div>




                </div>
                <!-- Price -->
                <div
                    class="flex flex-col items-center p-8 lg:px-10 lg:py-12 lg:min-w-80 bg-gray-100 dark:bg-black dark:bg-opacity-10">
                    <div class="flex items-center whitespace-nowrap">
                        <div class="text-8xl font-extrabold tracking-tight">NPR {{vendor.discount}} </div>
                        <div class="ml-2 text-2xl font-semibold text-secondary">{{vendor.discount_alias}}</div>
                    </div>
                    <div class="font-medium text-center text-secondary">
                        <div>Valid till</div>
                        <div>{{vendor.disable_date}}</div>
                    </div>

                </div>
            </fuse-card>
        </div>

    </div>
</div>