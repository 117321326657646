import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-detail-restock',
  templateUrl: './detail-restock.component.html',
  styleUrls: ['./detail-restock.component.scss']
})
export class DetailRestockComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
