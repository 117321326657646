<div class="sm:absolute sm:inset-0 flex flex-col flex-auto min-w-0 sm:overflow-hidden bg-card dark:bg-transparent">
    <!-- Header -->
    <div
        class="relative flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between py-8 px-6 md:px-8 border-b">


        <div class="absolute inset-x-0 bottom-0" *ngIf="isLoading">
            <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
        </div>

        <div style='font-family: "Montserrat";' class="text-4xl font-bold tracking-tight">
            Products
        </div>
        <!-- Actions -->
        <div class="flex flex-shrink-0 items-center mt-6 sm:mt-0 sm:ml-4">
            <!-- Search -->
            <mat-form-field class="fuse-mat-dense fuse-mat-rounded  fuse-mat-no-subscript min-w-50">
                <mat-icon matPrefix [svgIcon]="'heroicons_outline:search'"></mat-icon>
                <input matInput [autocomplete]="'off'" (keyup)="applyFilter($event)"
                    [placeholder]="'Search Products'" />
            </mat-form-field>

            <!-- Add product button -->
            <button class="ml-4" mat-flat-button [color]="'primary'" (click)="createProduct()">
                <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
                <span class="ml-2 mr-1">Add</span>
            </button>
        </div>
    </div>


    <!-- Main -->
    <div class="flex flex-auto overflow-hidden">
        <!-- Products list -->
        <div class="flex flex-col flex-auto sm:mb-18 overflow-hidden sm:overflow-y-auto">
            <div class="mat-elevation-z8">
                <table mat-table [dataSource]="dataSource" matSort matPaginator class="mat-table">


                    <!-- CustomerName Column -->
                    <ng-container matColumnDef="ProductName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Name
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <strong>
                            </strong> {{ row.productName }}

                            <mat-icon *ngIf="row.pre_order_toggel === true" matTooltipPosition="above"
                                matTooltipClass="comic-tooltip"
                                matTooltip="Upon the scheduled release date of the pre-order,  All pre-orders for this product , then can be processed and prepared for shipment."
                                class="icon-size-4" svgIcon="heroicons_outline:information-circle"></mat-icon>
                        </td>
                    </ng-container>










                    <ng-container matColumnDef="sku">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            SKU

                        </th>
                        <td mat-cell *matCellDef="let row">
                            <div class="avatar">

                                {{ row.sku }}
                            </div>
                        </td>
                    </ng-container>  


                    <ng-container matColumnDef="size">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                          Size
                        </th>
                        <td mat-cell *matCellDef="let row">
                          <mat-accordion class="main-card">
                            <mat-expansion-panel>
                              <mat-expansion-panel-header>
                                <div class="list-header size-card-header">
                                  <span style="font-size: 11px; font-family: Montserrat;">Sizes : </span>
                                  <span  class="badge-size">{{ row.size.split(',').length }}</span>
                                </div>
                              </mat-expansion-panel-header>
                              <div class="list size-list">
                                <div *ngFor="let size of row.size.split(','); let i = index" class="list-item">
                                  {{ size.trim() }}
                                </div>
                              </div>
                            </mat-expansion-panel>
                          </mat-accordion>
                        </td>
                      </ng-container>
                      
                      <!-- Color Column -->
                      <ng-container matColumnDef="color">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                          Color
                        </th>
                        <td mat-cell *matCellDef="let row">
                          <mat-accordion class="main-card">
                            <mat-expansion-panel>
                              <mat-expansion-panel-header>
                                <div class="list-header color-card-header">
                                  <span style="font-size: 11px; font-family: Montserrat;">Colors  </span>
                                  <span class="badge">{{ row.color.split(',').length }}</span>
                                </div>
                              </mat-expansion-panel-header>
                              <div class="list color-list">
                                <div *ngFor="let color of row.color.split(','); let i = index" class="list-item">
                                  {{ color.trim() }}
                                </div>
                              </div>
                            </mat-expansion-panel>
                          </mat-accordion>
                        </td>
                      </ng-container>
                      


                    <!-- OrderShipped Date Column -->
                    <ng-container matColumnDef="Prize">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Price

                        </th>
                        <td mat-cell *matCellDef="let row">
                            <div class="status">
                                NPR {{ row.basePrice }}
                            </div>
                        </td>
                    </ng-container>


                    <ng-container matColumnDef="created">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Date
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <div class="status">
                                <i>
                                    <u>
                                        {{ row.created }}
                                    </u>
                                </i>
                            </div>
                        </td>
                    </ng-container>

                    <!-- <ng-container matColumnDef="pre_order">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Pre-Order
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <div class="status">
                                <mat-slide-toggle [(ngModel)]="row.pre_order_toggel" (click)="onToggleChange(row)"
                                    [disabled]="!isToggleClickable">
                                    <div class="chip chip--success" *ngIf="row.pre_order_toggel === true"
                                        style="line-height: 16px;">
                                        <i style="font-size: 11px; margin-top: -2px;" class="chip__label">
                                            <strong>Pre-Order</strong>
                                        </i>
                                    </div>
                                </mat-slide-toggle>
                            </div>
                        </td>
                    </ng-container>
 -->


                    <!-- Actions Column -->
                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef>Actions</th>
                        <td mat-cell *matCellDef="let row">
                            <button mat-icon-button matTooltip="View" (click)="showDetails(row.productId)">
                                <mat-icon class="icon-size-4" svgIcon="heroicons_outline:eye"></mat-icon>
                            </button>


                            <button mat-icon-button matTooltip="Delete" (click)="deleteVendorsCredit(row.productId)">
                                <mat-icon class="icon-size-4" svgIcon="heroicons_outline:trash"></mat-icon>
                            </button>
                         
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

                </table>

                <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>
            </div>
        </div>
    </div>



</div>