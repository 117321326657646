import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { CreatePromoCodeComponent } from './create-promo-code/create-promo-code.component';
import { ListPromoCodeComponent } from './list-promo-code/list-promo-code.component';
import { PromoCodeComponent } from './promo-code.component';
import { PromoCodeRouting } from './promo-code.routing';
import { RouterModule } from '@angular/router';
import { MaterialModule } from 'app/modules/material/material.module';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { PromoCodeDetailComponent } from './promo-code-detail/promo-code-detail.component';



@NgModule({
  declarations: [
    CreatePromoCodeComponent,
    ListPromoCodeComponent,
    PromoCodeComponent,
    PromoCodeDetailComponent,

  ],
  imports: [
    RouterModule.forChild(PromoCodeRouting),
    
    CommonModule,
    MaterialModule
  ],
  providers: [
    {
        provide: MAT_DATE_FORMATS,
        useValue: {
            parse: {
                dateInput: "YYYY-MM-DD"
            },
            display: {
                dateInput: 'YYYY-MM-DD',
                monthYearLabel: 'MMM YYYY',
                dateA11yLabel: 'LL',
                monthYearA11yLabel: 'MMMM YYYY'

            }
        }
    },
    DatePipe,
],
})
export class PromoCodeModule { }
