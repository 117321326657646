import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inventroy-adjustment',
  templateUrl: './inventroy-adjustment.component.html',
  styleUrls: ['./inventroy-adjustment.component.scss']
})
export class InventroyAdjustmentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
