import {

    Component,
    ElementRef,
    ChangeDetectorRef,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,

} from '@angular/core';
import {
    AbstractControl,
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,

    ValidationErrors,

    ValidatorFn,

    Validators,
} from '@angular/forms';


import { ActivatedRoute, Router } from '@angular/router';

import { Observable, Subject, of, forkJoin, Subscription } from 'rxjs';

import { DatePipe } from '@angular/common';
import {
    debounceTime,
    distinctUntilChanged,
    map,
    startWith,
    switchMap,
    take,
    takeUntil,
} from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { productVaraitonService } from 'app/productVariations/productVarations.type';
import Swal from 'sweetalert2';
import { UserService } from 'app/core/user/user.service';
import { User } from 'app/core/user/user.types';

import { products } from 'app/products/products.type';
import { productsService } from 'app/products/products.service';
import { productBundleService } from 'app/product-bundle/product-bundle.service';
import { ProductBundlePopupComponent } from 'app/product-bundle-popup/product-bundle-popup.component';
import { productBundle } from 'app/product-bundle/product-bundle.type';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { MatDrawer } from '@angular/material/sidenav';
import { Influencer } from 'app/modules/Influencer/infulencer.type';
import { contrydata, insideValleyData } from 'app/sales-order/sales-order-create/countries';
import { PrPackagesService } from '../pr-packages.service';
import { InfluencerService } from 'app/modules/Influencer/Infulencer.service';
import { PrPackagesPopupComponent } from '../pr-packages-popup/pr-packages-popup.component';


export const discountValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const discount = control.value;
    const total = control.parent ? control.parent.get('total').value : null;

    if (total !== null && discount > total) {
        return { discountExceedsTotal: true };
    }

    return null;
};

export interface InfluencerOption {
    id: string;
    firstname?: string;
    avatar?: string;
}

export interface ProductOption {
    name?: string;
    stock?: number;
    reserved?: number;
    cost?: number;
    basePrice?: number;
    price?: number;

    images?: string[];
}

export interface Country {
    name: string;
    code: string;

}


@Component({
  selector: 'app-createpr-packages',
  templateUrl: './createpr-packages.component.html',
  styleUrls: ['./createpr-packages.component.scss']
})
export class CreateprPackagesComponent implements OnInit, OnDestroy {
    @ViewChild('InfluencerInput') InfluencerInput: ElementRef;
    @ViewChild('promoCodeInput') promoCodeInput: ElementRef;
    @ViewChild('voucherInput') voucherInput: ElementRef;
    @ViewChild('matDrawer', { static: true }) matDrawer: MatDrawer;


    discountPercentageSubscription: Subscription;

    private _subscription: Subscription;
    initialRates: { [key: string]: number } = {};

    private searchTerms = new Subject<string>();
    private allProducts: any[] = [];

    mappedVariations: any;
    districtControl = new FormControl();

    filteredDistricts: Observable<string[]>;
    InsidefilteredDistricts: Observable<string[]>;
    districts: string[] = [];

    promoCode: string[] = [];
    voucher: string[] = [];
    currentSalesOrderNum: number;
    ngControl: any;
    sizes: any[] = [];
    colors: any[] = [];
    name: any[] = [];
    quantity: any[] = [];
    current_stock: any[] = [];
    productActive: any[] = [];
    product_details: any[] = [];
    animationFlags: boolean[] = [];
    match_stock: boolean[] = [];
    filterByBundle: boolean[] = [];
    productSelected: boolean;


    @Input() set disableControl(condition: boolean) {
        const action = condition ? 'disable' : 'enable';
        this.ngControl.control[action]();
    }
    InfluencerId: number | undefined;
    productIds: number[] = [];
    stateCtrl = new FormControl('');
    formFieldHelpers: string[] = [''];
    drawerMode: 'over' | 'side' = 'side';
    drawerOpened: boolean = true;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    active: boolean[] = [];
    salesOrderForm: FormGroup;
    vendorsCount: number;
    selectedVendor: Influencer;

    Influencers$: Observable<Influencer[]>;
    InfluencerControl: FormControl = new FormControl();
    filteredInfluencers$: Observable<Influencer[]>;
    selectedInfluencer: Influencer | null = null;


    filteredProductOptions$: Observable<products[]>;
    filteredProductBundleOptions$: Observable<any[]>;
    usename: string = '';

    showAddressFields: boolean = false;
    showDeliveryType: boolean = true;
    showSocailLink: boolean = false;
    showEmail: boolean = false;
    showPaymentMethod: boolean = false;
    showShippingArea: boolean = false;
    showAddress: boolean = false;
    showShippinginvalley: boolean = false;
    showShippingOutvalley: boolean = false;
    showCountry: boolean = false;
    shwoShippingInternational: boolean = false;
    showDistrict: boolean = false;
    showOthers: boolean = false;
    showDeliveryDetails: boolean = false;
    showExpectedShippingDate: boolean = true;
    shipping_required = false;
    formattedAddress = '';
    countryControl = new FormControl();
    countries: string[] = contrydata;
    isProductArrayReadOnly = false;
    isFieldReadOnly = false;
    isFieldReadOnly_outside_val = false;
    filteredCountries: Observable<string[]>;
    searchQuery: string;
    searchResults: any[];
    formSubmitted = true;
    productsFormArray: FormArray;
    productVariationsMap: { [productId: string]: any[] } = {};
    selectedSizeColorMap: { [productId: string]: { size: string, color: string } } = {};
    user: User;
    matchedVariationIds: Set<string> = new Set();
    matchedProductBundleIds: Set<string> = new Set();
    configForm: FormGroup;
    matchedProductsFound: boolean;
    errorMessage:string;

    paymentOptions = [
        'CASH ON DELIVERY',
        "SIDDHARTHA BANK FONEPAY - HEADQUARTER",
        "SIDDHARTHA BANK FONEPAY - JHAMSIKHEL",
        "NIC ASIA BANK FONEPAY - HEADQUARTER",
        "NIC ASIA BANK FONEPAY",
    ];




    options: any = {
        componentRestrictions: { country: 'NP' },
        types: { city: 'Kathmandu' }

    };


    constructor(

        private _formBuilder: FormBuilder,
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _productBundleService: productBundleService,
        private dialog: MatDialog,
        private _InfluencerService: InfluencerService,
        private _userService: UserService,
        private _productService: productsService,
        private _salesOrderService: PrPackagesService,
        private datePipe: DatePipe,
        private _productVaraitons: productVaraitonService,
        private cd: ChangeDetectorRef,
        private _fuseConfirmationService: FuseConfirmationService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,



    ) {

        this._subscription = this._salesOrderService.getSalesOrder_number().subscribe(
            (response: any) => {
                if (response.success && response.data !== undefined) {
                    this.currentSalesOrderNum = response.data;

                    // Now, you can update your form control
                    this.salesOrderForm.controls['sales_order_num'].setValue(`SO-00${this.currentSalesOrderNum}-A`);
                } else {
                    console.error('Invalid response format:', response);
                }
            },
            (error) => {
                console.error('Error fetching sales order numbers:', error);
            }
        );




        this.filteredCountries = this.countryControl.valueChanges.pipe(
            startWith(''),
            map((value) => this._filterCountries(value))
        );



        this.InsidefilteredDistricts = this.districtControl.valueChanges.pipe(
            startWith(''),
            map((value) => this._filterInsideDistricts(value))
        )


        this.Influencers$ = this._InfluencerService.getInfluencers().pipe(take(1));

        this.Influencers$.subscribe((Influencers) => {
            const data = Influencers['data'];
        });

     


        const products$ = this._productVaraitons.getproductVaraiton().pipe(
            take(1),
            map((response) => response['data'])
        );

        products$.subscribe((product) => {
            const data = product['data'];

        });

    }

    ngOnInit() {
        this.fetchData();
        this.loadAllProducts();
        this.configForm = this._formBuilder.group({
            title: 'Info',
            message: '<span class="font-medium">Are you sure you want to proceed?</span> Please be aware that once you remove the product, all the data will be reverted. Are you sure you want to finalize it? Proceed carefully.',
            icon: this._formBuilder.group({
                show: true,
                name: 'heroicons_outline:exclamation',
                color: 'info'
            }),
            actions: this._formBuilder.group({
                confirm: this._formBuilder.group({
                    show: true,
                    label: 'Proceed',
                    color: 'accent'
                }),
                cancel: this._formBuilder.group({
                    show: true,
                    label: 'Cancel',
                    color: 'warn'

                })
            }),
            dismissible: true
        });

        this._userService.user$
            .pipe((takeUntil(this._unsubscribeAll)))
            .subscribe((user: User) => {
                this.user = user;
            });

        this.salesOrderForm = this._formBuilder.group({

            Influencer: [this.InfluencerControl, Validators.required],
            sales_order_num: [
                `PR-00-A`,
                Validators.required,
            ],
            order_number: [''],
            slaes_order_date: ['', Validators.required],
            sales_order_shippmentDate: [''],
            pos: [''],
            email: [''],
            soical_link: [''],
            delivery_type: [null, this.showDeliveryType ? Validators.required : null],
            shipping_area: [null, this.showShippingArea ? Validators.required : null],
            payment_type: [null, Validators.required],
            payment_terms: [''],
            discountSuffix: ['%'],
            adjustmentSuffix: ['%'],
            district: this.districtControl,
            country: this.countryControl,
            shipping_carrier_invalley: [''],
            shipping_carrier_invalley_others: [''],
            shipping_carrier_outvalley: [''],
            shipping_carrier_int: [''],
            delivery_detail: [''],
            items_rate: [''],
            gender: [''],
            company: [''],
            products: this._formBuilder.array([]),
            address: [''],
            notes: [null],
            socialMedia: [null],
            sub_total: [0],
            total: [0],
            adjustment: [0],
            discount: [0],
            shipping_charges: [0],
            refrences: [''],
            sales_person: [''],
            others: [''],
            tags: [''],
            discountSuffixVoucherApplied: ['%'],// Set default value to percentage and mark as required
            others_out: [''],
            delivery_number: [''],
        });



        this.filteredProductOptions$ = of([]);

        const salesOrderDateControl = new FormControl(new Date());

        const currentDate = new Date();

        salesOrderDateControl.setValue(currentDate);

        const datePipe = new DatePipe('en-US');
        const defaultDateValue = datePipe.transform(currentDate, 'yyyy-MM-dd');

        this.salesOrderForm.addControl(
            'slaes_order_date',
            salesOrderDateControl
        );

        this.salesOrderForm.get('slaes_order_date').setValue(defaultDateValue);
        const tomorrow = new Date(currentDate.getTime() + 24 * 60 * 60 * 1000);
        this.salesOrderForm.get('sales_person').patchValue(this.user.username.toLocaleUpperCase());


        (this.salesOrderForm.get('products') as FormArray).clear();

        const initialProductCount = 1;
        const productsArray = this.salesOrderForm.get('products') as FormArray;
        const numberOfProducts = productsArray.length;

        const productArray = this.salesOrderForm.get('products') as FormArray;
        for (let i = 0; i < productArray.length; i++) {
            this.animationFlags[i] = false;
            this.match_stock[i] = false;
            this.filterByBundle[i] = false;
            this.productActive[i] = false;


        };

        this.active = Array(numberOfProducts).fill(false);


        const subscribeToValueChanges = (productGroup: FormGroup) => {
            const rateControl = productGroup.get('rate');
            const quantityControl = productGroup.get('quantity');

            rateControl.valueChanges.subscribe(() => {
                this.calculateTotal();
            });

            quantityControl.valueChanges.subscribe(() => {
                this.calculateTotal();
            });


        };


        for (let i = 0; i < initialProductCount; i++) {
            const productGroup = this._formBuilder.group({
                id: [''],
                product_bundle: [''],
                bundle_id: [''],
                product: ['', [Validators.required]],
                quantity: ['', Validators.required],
                rate: ['', Validators.required],
                amount: [''],
                color: [''],
                size: [''],

            });

            subscribeToValueChanges(productGroup);

            (this.salesOrderForm.get('products') as FormArray).push(
                productGroup
            );
        }

        this.salesOrderForm.get('discount').valueChanges.subscribe(() => {
            this.calculateTotal();
        });
        this.salesOrderForm.get('discountSuffix').valueChanges.subscribe(() => {
            this.calculateTotal();
        });



        this.salesOrderForm.get('adjustment').valueChanges.subscribe(() => {
            this.calculateTotal();
        });
        this.salesOrderForm
            .get('adjustmentSuffix')
            .valueChanges.subscribe(() => {
                this.calculateTotal();
            });

        this.salesOrderForm
            .get('shipping_charges')
            .valueChanges.subscribe(() => {
                this.calculateTotal();
            });


        this.matDrawer.openedChange.subscribe((opened) => {
            if (!opened) {
                // Remove the selected Influencer when drawer closed

                // Mark for check
                this.cd.markForCheck();
            }
        }); closed

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {

                // Set the drawerMode if the given breakpoint is active
                if (matchingAliases.includes('lg')) {
                    this.drawerMode = 'side';
                }
                else {
                    this.drawerMode = 'over';
                }

                // Mark for check
                this.cd.markForCheck();
            });

    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
        this.discountPercentageSubscription.unsubscribe();

    }

    handleAddressChange(address: any) {
        if (!address) { return; }
        const addrName = address.name.split(",");
        const formattedAddr = address.formatted_address.split(",");
        if (formattedAddr.includes(...addrName)) {
            this.formattedAddress = address.formatted_address;
        } else {
            this.formattedAddress = `${address.name}, ${address.formatted_address}`;
        }
        this.salesOrderForm.get('address').patchValue(this.formattedAddress);
    }



    toggleFilterByBundle(index: number): void {
        this.filterByBundle[index] = !this.filterByBundle[index];
    }

    makeFieldEditable(InfluencerInput: HTMLInputElement) {
        this.isFieldReadOnly = false;
        this.selectedInfluencer = null;
        if (InfluencerInput) {
            InfluencerInput.value = '';
        }

        // Clear the value of the form control
        this.InfluencerControl.setValue('');
    }





    openConfirmationDialog(): Observable<boolean> {
        const dialogRef = this._fuseConfirmationService.open(this.configForm.value);

        return dialogRef.afterClosed().pipe(
            map(result => {
                return result === 'confirmed';
            })
        );
    }

    onInput(event: Event): void {
        const inputElement = event.target as HTMLInputElement;
        inputElement.value = inputElement.value.replace(/[^0-9-]/g, '');
    }

    async makeFieldEditableProduct(index: number) {

        const confirmationResult = await this.openConfirmationDialog().toPromise();
        if (confirmationResult) {

            this.productActive[index] = false;
            const productInput = document.getElementById(`product-input-${index}`) as HTMLInputElement;
            if (productInput) {
                productInput.removeAttribute('readonly'); // Make the input field editable
                productInput.value = ''; // Clear the value of the input field
            }

            const productArray = this.salesOrderForm.get('products') as FormArray;
            const existingFormGroup = productArray.at(index) as FormGroup;

            // Remove the ID from the matchedVariationIds set
            const removedId = existingFormGroup.get('id').value;
            this.matchedVariationIds.delete(removedId);

            // Clear values associated with the deleted product
            this.active.splice(index, 1);
            this.animationFlags.splice(index, 1);
            this.match_stock.splice(index, 1);
            this.current_stock.splice(index, 1);
            this.product_details.splice(index, 1);

            // Clear sizes and colors associated with the deleted product
            this.sizes[index] = [];
            this.colors[index] = [];

            // Remove the productActive flag associated with the deleted product
            this.productActive.splice(index, 1);

            // Remove the form group from the form array
            productArray.removeAt(index);
            this.addProductField();

            this.calculateTotal();
            this.cd.markForCheck();
        } else {
            ""
        }
    }



    makeFieldEditable_outside_val(disctrict: HTMLInputElement) {
        this.isFieldReadOnly_outside_val = false;
        if (disctrict) {
            disctrict.value = ''; // Clear the input value
        }

        // Clear the value of the form control
        this.districtControl.setValue('');
    }
    openInfluencerPopup(): void {
        const dialogRef = this.dialog.open(PrPackagesPopupComponent, {
            width: '560px',
        });

        dialogRef.componentInstance.formSubmitted.subscribe((formData) => {
            this._InfluencerService.createInf(formData)
                .then(async (response) => {
                    this.selectOption(response.data);
                    this.InfluencerControl.markAsUntouched();

                    this.formSubmitted = false;

                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer);
                            toast.addEventListener('mouseleave', Swal.resumeTimer);
                        },
                    });

                    Toast.fire({
                        icon: 'success',
                        title: `Influencer created!! `,
                    });

                    await this.fetchData();


                    dialogRef.close();
                    Toast.fire({
                        icon: 'error',
                        title: `Error: Influencer not patched correctly`,
                    });

                })
                .catch((error) => {
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer);
                            toast.addEventListener('mouseleave', Swal.resumeTimer);
                        },
                    });

                    Toast.fire({
                        icon: 'error',
                        title: `'Error creating Influencer: ' ${error.message};`,
                    });
                });
        });
    }



    // openProductDetails(): void {

    // }

    cleanUpProductValidators() {
        const productArray = this.salesOrderForm.get('products') as FormArray;

        // Loop backwards to safely remove items while iterating
        for (let i = productArray.length - 1; i >= 0; i--) {
            const productGroup = productArray.at(i) as FormGroup;

            if (!productGroup.get('product').value) {
                if (productArray.length > 1) {
                    productArray.removeAt(i);
                }
            } else {
                productGroup.get('product').clearValidators();
                productGroup.get('rate').clearValidators();

                productGroup.get('product').updateValueAndValidity();
                productGroup.get('rate').updateValueAndValidity();
            }
        }

        // Ensure that at least one product group is present
        if (productArray.length === 0) {
            this.addProductField();
        }
    }




    async sumbitSalesOrder() {
        this.cleanUpProductValidators();

        let Toast = Swal.mixin({
            toast: true,
            position: 'top', // Set position to 'top'
            customClass: {
                popup: 'swal2-toast-top-center', // Center the toast
            },
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener(
                    'mouseenter',
                    Swal.stopTimer
                );
                toast.addEventListener(
                    'mouseleave',
                    Swal.resumeTimer
                );
            },
        });
        if (this.salesOrderForm.valid) {
            // Show the loading indicator
            const InfluencerData = { ...this.salesOrderForm.value };
            const Influencer = this.salesOrderForm.get('Influencer')?.value;

            console.log(Influencer.value.id,Influencer,Influencer.value)
            InfluencerData.Influencer = Influencer ? Influencer.value.id : null;
         
            const products = this.salesOrderForm.get('products') as FormArray;
            const updatedProducts = products.value.map((item: any) => {
                const hasBundleId = !!item.bundle_id; // Check if bundle_id has a value

                return {
                    product: hasBundleId ? '' : item.id, // Set product to an empty string if bundle_id has a value
                    product_bundle: hasBundleId ? item.bundle_id : item.product_bundle, // Set product_bundle to bundle_id if it has a value
                    quantity: item.quantity,
                    rate: item.rate,
                    amount: item.amount,
                };
            });

            InfluencerData.products = updatedProducts;
            Swal.fire({
                title: "Loading",
                html: `
                    <div style="display: flex; flex-direction: column; align-items: center;">
                        <img src="assets/loading.gif" style="width: auto; height: auto;" alt="loading"/>
                        <p style="margin: 10px 0 0 0; font-size: 14px; color: #666;">Creating, please wait...</p>
                    </div>
                `,
                timerProgressBar: true,
                didOpen: () => {
                    Swal.showLoading();
                },
            });

            // Update the Influencer on the server
            this._salesOrderService
                .createPrPackages(InfluencerData)
                .then((response) => {
                    if (response.success) {
                        const data = response.data
                        const Toast = Swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener(
                                    'mouseenter',
                                    Swal.stopTimer
                                );
                                toast.addEventListener(
                                    'mouseleave',
                                    Swal.resumeTimer
                                );
                            },
                        });

                        Toast.fire({
                            icon: 'success',
                            title: `${InfluencerData.sales_order_num} Pr Order created!!`,
                        });

                        this.salesOrderForm.reset();
                        if (data.pos === 'Store Vist') {
                            this._router.navigate(['apps/sales-logs'])
                        } else {
                            this._router.navigate(['apps/pr-packages'], {
                            });
                        }


                    } else {
                        Swal.close();

                        console.log(response, response.erros)
                        const errorMessages = response.errors;

                        const Toast = Swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener(
                                    'mouseenter',
                                    Swal.stopTimer
                                );
                                toast.addEventListener(
                                    'mouseleave',
                                    Swal.resumeTimer
                                );
                            },
                        });

                        Toast.fire({
                            icon: 'error',
                            title: 'Error creating Sales Order ',
                            text: JSON.stringify(errorMessages),
                        });
                    }
                })
                .catch((error) => {
                    // Hide the loading indicator
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener(
                                'mouseenter',
                                Swal.stopTimer
                            );
                            toast.addEventListener(
                                'mouseleave',
                                Swal.resumeTimer
                            );
                        },
                    });

                    Toast.fire({
                        icon: 'error',
                        title: `Error creating Sales Order: ${error.message}`,
                    });
                });
        } else {
            Swal.close();

            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer);
                    toast.addEventListener('mouseleave', Swal.resumeTimer);
                },
            });

            Toast.fire({
                icon: 'error',
                title: 'Invalid form data',
                text: 'Please fill in all the required fields correctly.',
            });
        }
    }

    toggleDropdown() {
        const dropdownMenu = document.getElementById('dropdown-menu');
        if (dropdownMenu) {
            dropdownMenu.classList.toggle('show');
        }
    }



    saveAsDraft() {
    }

    saveAndPrint() {
    }

    hasUnsavedChanges(): boolean {
        return this.salesOrderForm.dirty;
    }

    canDeactivate(): boolean {
        return !this.salesOrderForm.dirty || confirm('You have unsaved changes. Are you sure you want to leave?');
    }



    isArrayData(data: any): data is any[] {
        return Array.isArray(data);
    }




    filterProductBundle(value: any): void {
        if (typeof value !== 'string') {
            this.filteredProductBundleOptions$ = of([]);
            return;
        }

        this._productBundleService.getproductsFilter().pipe(
            switchMap((response) => {
                const bundles = response['data'];

                if (!Array.isArray(bundles) || bundles.length === 0) {
                    return of([]);
                }

                const filteredBundles = bundles.map((bundle) => {

                    return {
                        id: bundle.id,
                        name: bundle.bundle_name,
                        products: bundle.products,
                        description: bundle.bundle_description,

                    };
                });

                return of(filteredBundles);
            })
        ).subscribe((filteredBundles) => {
            this.filteredProductBundleOptions$ = of(filteredBundles);
        });
    }



    getVendorPlaceholder(): string {
        return this.vendorsCount > 0
            ? 'Select a Influencer'
            : 'No Influencer available';
    }
    onSalesOrderDateChange(event: any) {
        const selectedDate: Date = event.value;

        const formattedDate = this.datePipe.transform(selectedDate, 'yyyy-MM-dd');

        this.salesOrderForm.get('slaes_order_date').setValue(formattedDate);

    }
    /**
      *
      * @param index
      * @param item
      */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    // Helper method to check if a name includes at least one part of the filter values
    private includesAnyPart(name: string, filterValues: Set<string>): boolean {
        for (let filterValue of filterValues) {
            if (name.includes(filterValue)) {
                return true;
            }
        }
        return false;
    }

    private matchesFilter(Influencer: Influencer, filterValues: Set<string>): boolean {
        const trimmedFirstName = Influencer.firstName?.trim().toLowerCase();
        const trimmedLastName = Influencer.lastName?.trim().toLowerCase();
        const idWithPrefix = `inf${Influencer.id}`.toLowerCase(); // Ensure 'hub' is lowercase

        return (
            (trimmedFirstName && this.includesAnyPart(trimmedFirstName, filterValues)) ||
            (trimmedLastName && this.includesAnyPart(trimmedLastName, filterValues)) ||
            filterValues.has(idWithPrefix)
        );
    }





    filterOptions(value: string): Observable<Influencer[]> {
        if (typeof value !== 'string') {
            return of([]); // Return an empty array if value is not a string
        }
        this.formSubmitted = true;

        const filterValues = new Set(value.trim().toLowerCase().split(' '));

        return this.Influencers$.pipe(
            map((Influencers) => {
                if (Array.isArray(Influencers['data'])) {
                    return Influencers['data'].filter((Influencer) =>
                        this.matchesFilter(Influencer, filterValues)
                    );
                } else {
                    console.error('Influencers data is not available:', Influencers);
                    return [];
                }
            })
        );
    }
 
    validInfluencerValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const selectedInfluencer: Influencer = control.value;

            if (!selectedInfluencer) {
                return { 'required': true };
            }

            if (!selectedInfluencer.id) {
                return { 'InfluencerNotSelected': true };
            }

            return null;
        };
    }
    private matchesFilterPromo(promo: any, filterValues: Set<string>): boolean {
        const trimmedName = promo.name?.trim().toLowerCase();

        return (
            trimmedName && this.includesAnyPart(trimmedName, filterValues)
        );
    }

  

    displayOption(Influencer: Influencer): string {
        const last_name = Influencer?.lastName || '';
        const first_name = Influencer?.firstName || '';
        const id = Influencer?.id || '';

        // Construct the display string based on the available values
        if (first_name && last_name) {
            return id ? `${first_name} ${last_name} (HUB${id})` : `${first_name} ${last_name}`;
        } else if (first_name) {
            return id ? `${first_name} (HUB${id})` : first_name;
        } else if (last_name) {
            return id ? `${last_name} (HUB${id})` : last_name;
        } else {
            return id ? `(HUB${id})` : '';
        }
    }


    selectOption(option: Influencer) {
        if (!option) {
            return;
        }
        this.InfluencerControl.setValue(option);
        this.InfluencerControl.updateValueAndValidity();
        this.selectedInfluencer = option;
        this.isFieldReadOnly = true;

    }




    // Method to check if a Influencer is selected
    isInfluencerSelected(selectedInfluencer: Influencer): boolean {
        return this.InfluencerControl.value && selectedInfluencer && this.InfluencerControl.value.id === selectedInfluencer.id;
    }





    private showErrorMessage(message: string) {
        let Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener(
                    'mouseenter',
                    Swal.stopTimer
                );
                toast.addEventListener(
                    'mouseleave',
                    Swal.resumeTimer
                );
            },
        });

        Toast.fire({
            icon: 'error',
            title: message,
        });
    }

 

    // Map the Influencer object to InfluencerOption
    genderOptionss(Influencer: Influencer): InfluencerOption[] {
        if (Influencer) {
            const mappedInfluencer: InfluencerOption = {
                id: Influencer.id,
                firstname: Influencer.firstName || '',
            };
            return [mappedInfluencer];
        }
        return [];
    }

    addProductField(): void {
        const productFormGroup = this._formBuilder.group({
            id: [''],
            bundle_product: [''],
            bundle_id: [''],
            product: ['', [Validators.required]],
            quantity: ['', Validators.required],
            rate: ['', Validators.required],
            amount: [''],
            color: [''],
            size: [''],
        });

        const productArray = this.salesOrderForm.get('products') as FormArray;
        productArray.push(productFormGroup);

        const index = productArray.length - 1;
        this.setupProductFieldSubscriptions(productFormGroup, index);

        this.calculateTotal();
    }



    removeProductField(index: number): void {
        const productArray = this.salesOrderForm.get('products') as FormArray;
        const existingFormGroup = productArray.at(index) as FormGroup;

        // Remove the ID from the matchedVariationIds set
        const removedId = existingFormGroup.get('id').value;
        this.matchedVariationIds.delete(removedId);

        // Remove the values associated with the deleted product
        this.active.splice(index, 1);
        this.animationFlags.splice(index, 1);
        this.match_stock.splice(index, 1);
        this.current_stock.splice(index, 1);
        this.product_details.splice(index, 1);

        // Remove sizes and colors associated with the deleted product
        this.sizes.splice(index, 1);
        this.colors.splice(index, 1);

        // Remove the productActive flag associated with the deleted product
        this.productActive.splice(index, 1);

        // Remove the form group from the form array
        productArray.removeAt(index);

        this.calculateTotal();
        this.cd.markForCheck();
    }



    setupProductFieldSubscriptions(
        productGroup: FormGroup,
        index: number
    ): void {
        const quantityControl = productGroup.get('quantity');
        const rateControl = productGroup.get('rate');

        quantityControl.valueChanges.subscribe(() => {
            this.calculateTotal();
        });

        rateControl.valueChanges.subscribe(() => {
            this.calculateTotal();
        });


    }

    calculateTotal(): void {
        const productArray = this.salesOrderForm.get('products') as FormArray;

        let subTotal = 0;
        productArray.controls.forEach((control: FormGroup) => {
            const quantity = control.get('quantity').value;
            const rate = control.get('rate').value;

            const amount = (quantity || 0) * (rate || 0);
            control.get('amount').setValue(amount);

            subTotal += amount || 0;
        });

        this.salesOrderForm.get('sub_total').setValue(subTotal);
        const discountSuffix = this.salesOrderForm.get('discountSuffix').value;
        const adjustmentSuffix =
            this.salesOrderForm.get('adjustmentSuffix').value;
        const discount = +this.salesOrderForm.get('discount').value || 0;

        const adjustment = +this.salesOrderForm.get('adjustment').value || 0;

        const shipping = +this.salesOrderForm.get('shipping_charges').value || 0;



        let calculatd_shipping = shipping
        let calculatedDiscount = discount;
        let calculatedAdjustment = adjustment;

        if (discountSuffix === '%') {
            calculatedDiscount = (subTotal * discount) / 100;
        } else if (discountSuffix === 'rs') {
            calculatedDiscount = discount;

        }

        if (adjustmentSuffix === '%') {
            calculatedAdjustment = (subTotal * adjustment) / 100;

        } else if (adjustmentSuffix === 'rs') {
            calculatedAdjustment = adjustment;

        }

        if (adjustmentSuffix == '%' && discountSuffix == '%') {
            calculatedDiscount = (subTotal * discount) / 100;
            const newTotal = subTotal - calculatedDiscount;

            calculatedAdjustment = (newTotal * adjustment) / 100;
        }

        const totalBeforeShipping = subTotal - calculatedDiscount + calculatedAdjustment;
        const total = totalBeforeShipping + calculatd_shipping;


        this.salesOrderForm.get('total').setValue(total);


    }


    applyPromoCodeDiscount(control: FormGroup, discount: number, discountAlias: string) {
        let rate = control.get('rate').value;
        if (discountAlias === '%') {
            rate -= (rate * discount) / 100;
        } else if (discountAlias === 'rs') {
            rate -= discount;
        }
        control.get('rate').setValue(rate);
    }


    toggleInputBack(): void {
        this.salesOrderForm.get('shipping_carrier_int').setValue('ARAMEX');
    }



    private loadAllProducts(): void {
        this._productService.getproducts().pipe(
            take(1),
            switchMap((products) => {
                if (!Array.isArray(products['data'])) {
                    return of([]);
                }

                const productObservables = products['data'].map((product) => {
                    return this._productService.getProductVariations(product.id).pipe(
                        map((variations) => ({
                            product,
                            variations: variations.data,
                        }))
                    );
                });

                return forkJoin(productObservables);
            }),
            map((productsWithVariations) => {
                this.allProducts = productsWithVariations.map(({ product, variations }) => {
                    const productId = product.id;

                    // Determine if all variations are disabled
                    const allDisabled = variations.every((variation) => {
                        // Check if the variation should be considered disabled
                        const isNotActive = !variation.active;
                        const isPreOrder = variation.preOrder;
                        const isStock = variation.stock === 0;
                        const isSelected = this.matchedVariationIds.has(variation.id);
                        return isNotActive || isPreOrder || isStock || isSelected;
                    });

                    // Mark the product as disabled if all variations are disabled
                    const disabled = allDisabled || variations.length === 0;

                    return {
                        product: productId,
                        id: productId,
                        productName: product.productName,
                        sku: product.sku,
                        basePrice: product.basePrice,
                        size: product.size,
                        color: product.color,
                        description: product.description,
                        disabled: disabled,
                    };
                });

                // Initially set the filtered options to all products
                this.filteredProductOptions$ = of(this.allProducts);
            })
        ).subscribe();
    }



    filterOptionsProduct(value: string): void {
        this.searchTerms.next(value);
    }

    private filterLocalProducts(value: string): Observable<any[]> {
        if (typeof value !== 'string' || !value.trim()) {
            return of(this.allProducts); // Return all products if the value is empty
        }

        const filteredProducts = this.allProducts.filter((filteredProduct) =>
            filteredProduct.productName.toLowerCase().includes(value.toLowerCase()) ||
            (filteredProduct.sku && filteredProduct.sku.toString().toLowerCase().includes(value.toLowerCase()))
        );

        return of(filteredProducts);
    }


    filtersOptionsProduct(productId: string, index: number): void {
        this._productService.getProductVariations(productId).subscribe((variations) => {
            this.mappedVariations = variations.data
                .map((variation) => ({
                    id: variation.id,
                    size: variation.size,
                    color: variation.color,
                    price: variation.basePrice,
                    base_product: productId,
                    stock: variation.stock,
                    preOrder: variation.preOrder,
                    active: variation.active,
                    description: variation.base_product.description,
                    category: variation.base_product.category,
                    gender: variation.base_product.gender,
                }));

            const sizeToColorMap = {};
            const allColorsDisabledMap = {};

            this.mappedVariations.forEach((variation) => {
                const { size, color, id, active, preOrder, stock } = variation;

                if (!sizeToColorMap[size]) {
                    sizeToColorMap[size] = [];
                }

                const isNotActive = !active;
                const isPreOrder = preOrder;
                const isStock = stock == 0

                const isSelected = this.matchedVariationIds.has(id);
                const isOutOfStock = isNotActive || isSelected || (isPreOrder === true) || isStock;
                const style = isOutOfStock ? { color: 'red' } : {};
                const disabled = isOutOfStock;

                sizeToColorMap[size].push({
                    id,
                    color,
                    style,
                    disabled,
                });

                allColorsDisabledMap[color] = allColorsDisabledMap[color] || {};
                allColorsDisabledMap[color][size] = disabled;
            });

            const sizes = Object.keys(sizeToColorMap).map((size) => {
                const colors = sizeToColorMap[size] || [];

                // Check if all colors for this size are disabled
                const disabled = colors.every((color) => allColorsDisabledMap[color.color]?.[size]);

                // console.log('Disabled:', disabled);
                return {
                    id: size,
                    name: size,
                    disabled,
                };
            });

            const firstSize = sizes[0]?.id;
            const colors =
                sizeToColorMap[firstSize]?.map((color) => ({
                    id: color.color,
                    name: color.color,
                    style: color.style,
                    disabled: allColorsDisabledMap[color.color]?.[firstSize],
                })) || [];

            this.updateSizesAndColors(index, sizes, colors);
        });
    }

    updateSizesAndColors(index: number, sizes: any[], colors: any[]): void {
        this.sizes[index] = sizes;
        this.colors[index] = colors;
    }


    quantityExceedsStock(index: number): boolean {
        const productArray = this.salesOrderForm.get('products') as FormArray;
        const existingFormGroup = productArray.at(index) as FormGroup;
        const quantityControl = existingFormGroup.get('quantity');

        if (quantityControl.invalid) {
            return false;
        }

        const quantityValue = Number(quantityControl.value);
        const currentStockValue = Number(this.current_stock[index]);

        const exceedsStock = quantityValue > currentStockValue;

        if (exceedsStock) {
            quantityControl.setErrors({ 'exceedsStock': true });
        } else {
            quantityControl.setErrors(null);
        }

        return exceedsStock;
    }


    





    onSelectionChange(index: number): void {
        const productArray = this.salesOrderForm.get('products') as FormArray;
        const existingFormGroup = productArray.at(index) as FormGroup;

        if (existingFormGroup) {
            const selectedProductId = existingFormGroup.get('product').value.id;
            const selectedSizeId = existingFormGroup.get('size').value;
            this.colors[index]?.forEach((color) => {
                color.disabled = false;
            });

            this.mappedVariations.forEach((variation) => {
                const { size, color, id, active, stock, preOrder } = variation;

                if (size === selectedSizeId) {
                    const colorToDisable = this.colors[index]?.find(c => c.name === color);

                    if (colorToDisable) {
                        colorToDisable.disabled = !active || stock == 0 || (preOrder === true) || this.matchedVariationIds.has(id);
                    }
                }
            });

            const selectedColorId = existingFormGroup.get('color').value;

            const selectedSizeColorCombo = `${selectedSizeId}${selectedColorId}`;
            const matchingOriginalVariation = this.mappedVariations?.find(variation => {
                const variationSizeColorCombo = `${variation.size}${variation.color}`;
                return variationSizeColorCombo === selectedSizeColorCombo;
            });

            if (matchingOriginalVariation) {
                if (matchingOriginalVariation.stock != 0) {
                    if (!this.matchedVariationIds.has(matchingOriginalVariation.id)) {
                        existingFormGroup.get('rate').patchValue(matchingOriginalVariation.price);
                        existingFormGroup.get('size').patchValue(matchingOriginalVariation.size);
                        existingFormGroup.get('color').patchValue(matchingOriginalVariation.color);
                        existingFormGroup.get('id').patchValue(matchingOriginalVariation.id);
                        this.active[index] = true;
                        this.current_stock[index] = matchingOriginalVariation.stock;

                        this.product_details[index] = matchingOriginalVariation.description;
                        this.animationFlags[index] = true;

                        this.matchedVariationIds.add(matchingOriginalVariation.id);

                        this.disableColorsForSelectedSize(index, selectedSizeId);
                        this.addProductField();


                        setTimeout(() => {
                            this.animationFlags[index] = false;
                        }, 1000);

                        // console.log("Matching variation found for the selected size and color:", matchingOriginalVariation);
                    } else {
                    }
                } else {
                }
            } else {
                // console.log("No matching original variation found for the selected size and color.");
            }
        }
    }

    disableColorsForSelectedSize(index: number, selectedSizeId: string): void {
        this.colors[index].forEach((color) => {
            color.disabled = color.id !== selectedSizeId;
        });

        this.updateSizesAndColors(index, this.sizes[index], this.colors[index]);
    }


    selectOptionProduct(option: products, index: number): void {




        if (option && !option.isOutOfStock) {
            const productFormGroup = this._formBuilder.group({
                id: [''],
                product: [option, [Validators.required]],
                quantity: [''],
                product_bundle: [''],
                bundle_id: [''],
                rate: [option.basePrice, ''],
                amount: [''],
                size: [],
                color: [],
            });
            this.productActive[index] = true;

            this.filtersOptionsProduct(option.id, index)


            const productArray = this.salesOrderForm.get('products') as FormArray;

        }
    }




    selectOptionProductBundle(option: productBundle, index: number): void {


        const selectedProducts = option.products.map(product => {
            return {
                product: product,

            };
        });
        // console.log(selectedProducts)
        const dialogRef = this.dialog.open(ProductBundlePopupComponent, {
            width: '3000px',
            height: '750px',
            data: {
                selectedProducts: selectedProducts
            }
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result && result.total && result.otherData && result.otherData.products && Array.isArray(result.otherData.products)) {
                // console.log("total", result.total);



                const sizes = result.otherData.products.map((product) => product.size);
                const colors = result.otherData.products.map((product) => product.color);
                const name = result.otherData.products.map((product) => product.product);
                const products = result.otherData.products;
                const productDetails = products.map(product => ({
                    name: product.product.productName,
                    size: product.size,
                    color: product.color,
                    rate: product.rate,
                    quantity: product.quantity,
                }));
                this.name[index] = productDetails;


                const quantities = result.otherData.products.map((product) => product.quantity);

                this.quantity[index] = quantities;

                // console.log(sizes, colors, quantities);

                // console.log(option)
                const productFormGroup = this._formBuilder.group({
                    id: [''],
                    bundle_id: [option.id],
                    product: [option, [Validators.required]],
                    product_bundle: [option],
                    quantity: [''],
                    rate: [result.total, ''],
                    amount: [''],
                    size: [sizes.join(', ')],
                    color: [colors.join(', ')],
                });
                const productArray = this.salesOrderForm.get(
                    'products'
                ) as FormArray;
                const existingFormGroup = productArray.at(index) as FormGroup;

                if (existingFormGroup) {
                    existingFormGroup.patchValue(productFormGroup.value);

                }
                this.active[index] = true;
                this.animationFlags[index] = true;

                this.matchedProductBundleIds.add(option.id);

                this.cd.detectChanges();
            } else {
                console.error("Invalid data structure or missing required properties");
            }
        });





    }


    displayProductOption(product: products): string {
        if (product) {

            const productName = product.productName || '';
            const productPrice = product.sku
                ? ' - ' + product.sku.toString()
                : '';

            return productName + productPrice;

        }

        return '';
    }


    displayProductBundleOption(product: any): string {
        if (product) {
            // console.log(product)
            const productName = product.name || '';
            const productPrice = product.description
                ? ' - ' + product.description.toString()
                : '';

            return productName + productPrice;

        }

        return '';
    }



    getFormFieldHelpersAsString(): string {
        return this.formFieldHelpers.join(' ');
    }
    pointOfsales(): void {
        const type = this.salesOrderForm.controls['pos'].value;

        // Reset all flags initially
        this.showEmail = false;
        this.showSocailLink = false;
        this.showPaymentMethod = false;
        this.showShippingArea = false;


        // Remove all validators initially
        this.salesOrderForm.get('shipping_area').clearValidators();
        this.salesOrderForm.get('delivery_type').clearValidators();

        // Update the flags and validators based on the selected type
        if (type === 'Instagram' || type === 'Website' || type === 'Facebook') {
            this.showEmail = true;
            this.showPaymentMethod = true;
            this.showShippingArea = true;

            this.salesOrderForm.get('shipping_area').setValidators(Validators.required);
        } else if (type === 'Store Vist') {
            this.showSocailLink = true;
            this.showPaymentMethod = true;

            this.paymentOptions = [
                'CASH',
                'ASHIM ESEWA',
                'DANISH ESEWA',
                'HUBA NIC ASIA',
                'ASHIM SIDDHARTA BANK'
            ];

            // You may add validators for other fields as needed, e.g., shipping_area, delivery_type
        } else {
            this.showEmail = true;
            this.showSocailLink = true;
        }

        // Update the validity of the controls
        this.salesOrderForm.get('shipping_area').updateValueAndValidity();
        this.salesOrderForm.get('delivery_type').updateValueAndValidity();
    }

    addressField(): void {
        const type = this.salesOrderForm.controls['shipping_area'].value;

        // Reset all flags initially
        this.showAddress = false;
        this.showShippinginvalley = false;
        this.showDistrict = false;
        this.showShippingOutvalley = false;
        this.showCountry = false;
        this.shwoShippingInternational = false;
        this.showDeliveryDetails = false;
        this.shipping_required = false;

        // Remove all validators initially
        this.salesOrderForm.get('shipping_charges').clearValidators();
        this.salesOrderForm.get('district').clearValidators();
        this.salesOrderForm.get('country').clearValidators();
        this.salesOrderForm.get('shipping_carrier_invalley').clearValidators();
        this.salesOrderForm.get('shipping_carrier_outvalley').clearValidators();
        this.salesOrderForm.get('shipping_carrier_int').clearValidators();
        this.salesOrderForm.get('delivery_detail').clearValidators();

        // Update the flags and validators based on the selected type
        if (type === 'Inside Valley') {
            this.showAddress = true;
            this.showShippinginvalley = true;
            this.showDistrict = true;

            this.salesOrderForm.get('district').setValidators(Validators.required);
            this.salesOrderForm.get('shipping_carrier_invalley').setValidators(Validators.required);
        } else if (type === 'Outside Valley') {
            const currentShippingCharges = this.salesOrderForm.get('shipping_charges').value || 0;
            this.salesOrderForm.get('shipping_charges').patchValue(currentShippingCharges + 100);
            this.salesOrderForm.get('shipping_charges').setValidators(Validators.required);
            this.shipping_required = true;

            this.showAddress = true;
            this.showDistrict = true;
            this.showShippingOutvalley = true;

            this.salesOrderForm.get('district').setValidators(Validators.required);
            this.salesOrderForm.get('shipping_carrier_outvalley').setValidators(Validators.required);
        } else if (type === 'International') {
            this.salesOrderForm.get('shipping_charges').patchValue(0);
            this.shipping_required = true;

            this.showAddress = true;
            this.showCountry = true;
            this.shwoShippingInternational = true;
            this.showDeliveryDetails = true;

            this.salesOrderForm.get('country').setValidators(Validators.required);
            this.salesOrderForm.get('shipping_carrier_int').setValidators(Validators.required);
            this.salesOrderForm.get('delivery_detail').setValidators(Validators.required);
        }

        // Update the validity of the controls
        this.salesOrderForm.get('shipping_charges').updateValueAndValidity();
        this.salesOrderForm.get('district').updateValueAndValidity();
        this.salesOrderForm.get('country').updateValueAndValidity();
        this.salesOrderForm.get('shipping_carrier_invalley').updateValueAndValidity();
        this.salesOrderForm.get('shipping_carrier_outvalley').updateValueAndValidity();
        this.salesOrderForm.get('shipping_carrier_int').updateValueAndValidity();
        this.salesOrderForm.get('delivery_detail').updateValueAndValidity();

        // Trigger change detection if necessary
        this.cd.detectChanges();
    }

    showOtherss(): void {
        const type = this.salesOrderForm.controls['shipping_carrier_int'].value;
        if (type == 'others') {
            this.showOthers = true;
        } else {
            this.showOthers = false;
        }
    }

    private _filterCountries(value: string) {
        const filterValue = value.toLowerCase();
        return contrydata.filter((country) =>
            country.toLowerCase().includes(filterValue)
        );
    }

    private _filterInsideDistricts(value: string) {
        const filterValue = value.toLowerCase();
        return insideValleyData.filter((district) =>
            district.toLowerCase().includes(filterValue)
        );
    }


    formatDate(date: Date, format: string, controlName: string) {
        const formattedDate = this.datePipe.transform(date, format);
        this.salesOrderForm.get(controlName)?.setValue(formattedDate);
    }


    async fetchData() {
        try {

            this.searchTerms.pipe(
                debounceTime(300),
                distinctUntilChanged(),
                switchMap((value) => this.filterLocalProducts(value))
            ).subscribe((filteredProducts) => {
                this.filteredProductOptions$ = of(filteredProducts);
            });

          


            this.filteredDistricts = this.districtControl.valueChanges.pipe(
                startWith(''),
                map((value) => this._filterDistricts(value))
            );

            this.filteredInfluencers$ = this.InfluencerControl.valueChanges.pipe(
                startWith(''),
                debounceTime(300),
                switchMap((value) => this.filterOptions(value))
            );

      

            const districtNames = await this._salesOrderService.fetchData();
            this.districts = districtNames.sort();

        } catch (error) {
        }
    }

    private _filterDistricts(value: string): string[] {
        const filterValue = value.toLowerCase();
        return this.districts.filter((district) =>
            district.toLowerCase().includes(filterValue)
        );
    }
    onDistrictSelected(event: MatAutocompleteSelectedEvent): void {
        this.isFieldReadOnly_outside_val = true;
    }


    toggleOtherInput(): void {
        this.salesOrderForm.get('shippingCarrier').setValue('OTHERS');
    }

    toggleInternationalInput(): void {
        this.salesOrderForm.get('shipping_carrier_int').setValue('ARAMEX');
    }

    toggleInputs(): void {
        this.salesOrderForm.get('shipping_carrier_outvalley').setValue('Dash logistics');
    }


    toggleInputss(): void {
        this.salesOrderForm.get('shipping_carrier_invalley').setValue('Dash logistics');
    }

    openDrawer() {




        this.matDrawer.open();


    }



    onDrawerClose(): void {
        this.matDrawer.close();
    }
}