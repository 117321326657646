import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { productVaraitons } from './productVariations.service';

import { HttpHeaders } from '@angular/common/http';
import { server_env } from 'config';

@Injectable({
    providedIn: 'root',
})
export class productVaraitonService {
    // Private
    private _productVaraiton: BehaviorSubject<productVaraitons | null> =
        new BehaviorSubject(null);
    private _productVaraitonss: BehaviorSubject<productVaraitons[] | null> =
        new BehaviorSubject(null);

        private _itemReport: BehaviorSubject<any | null> =
        new BehaviorSubject(null);
        private _stockReport: BehaviorSubject<any | null> =
        new BehaviorSubject(null);

    public url = `${server_env.URL}api/v1/productVariants/`;
    public url2 = `${server_env.URL}api/v1/assumedPreOrderStock/`

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors

    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for contact
     */
    get productVaraiton$(): Observable<productVaraitons> {
        return this._productVaraiton.asObservable();
    }

    /**
     * Getter for contacts
     */
    get productVaraitons$(): Observable<productVaraitons[]> {
        return this._productVaraitonss.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get contacts
     */

    getproductVaraiton(): Observable<productVaraitons[]> {
        return this._httpClient.get<productVaraitons[]>(`${this.url}`).pipe(
            tap((productVaraiton: any) => {
                this._productVaraitonss.next(productVaraiton.data);

                for (productVaraiton in productVaraiton.data) {
                    // console.log(productVaraiton, 'sdas');
                }
            })
        );
    }

    /**
     * Get contact by id
     */
    getproductVaraitonById(id: string): Observable<productVaraitons> {
        const requestUrl = this.url + id;

        return this._httpClient.get<productVaraitons[]>(`${requestUrl}`).pipe(
            take(1),
            map((productVaraiton: any) => {
                this._productVaraiton.next(productVaraiton.data);

                // Return the contact
                return productVaraiton.data;
            }),
            switchMap((productVaraiton) => {
                if (!productVaraiton) {
                    return throwError(
                        'Could not found contact with id of ' + id + '!'
                    );
                }

                return of(productVaraiton);
            })
        );
    }

    /**
     * Create contact
     */
    // createproductVaraiton(): Observable<productVaraiton>
    // {
    //     return this.productVaraiton$.pipe(
    //         take(1),
    //         switchMap(productVaraiton => this._httpClient.post<productVaraiton>(`${this.url}`, {}).pipe(
    //             map((newproductVaraiton) => {

    //                 // Update the contacts with the new contact
    //                 this._productVaraitonss.next([newproductVaraiton, ...productVaraiton]);

    //                 // Return the new contact
    //                 return newproductVaraiton;
    //             })
    //         ))
    //     );
    // }

    /**
     * create customer
     * @param data
     * @returns
     */

    createproductVaraitons(data: FormData): Promise<any> {
        const requestUrl = this.url;
        const headers = new HttpHeaders();

        // Remove the default 'Content-Type' header to allow Angular to automatically set the appropriate headers for multipart form data
        headers.delete('Content-Type');

        return this._httpClient
            .post<any>(requestUrl, data, { headers })
            .toPromise();
    }
    /**
     * Update contact
     *
     * @param id
     * @param contact
     */
    updateproductVaraiton(
        id: string,
        contact: productVaraitons
    ): Observable<productVaraitons> {
        return this.productVaraitons$.pipe(
            take(1),
            switchMap((productVaraiton) => {
                const requestUrl = `${this.url}${id}/`;

                return this._httpClient
                    .put<productVaraitons>(requestUrl, { id, contact })
                    .pipe(
                        map((updatedproductVaraiton) => {
                            // Find the index of the updated contact
                            const index = productVaraiton.findIndex(
                                (item) => item.id === id
                            );

                            // Update the contact
                            productVaraiton[index] = updatedproductVaraiton;

                            // Update the contacts
                            this._productVaraitonss.next(productVaraiton);

                            // Return the updated contact
                            return updatedproductVaraiton;
                        }),
                        switchMap((updatedproductVaraitons) =>
                            this.productVaraiton$.pipe(
                                take(1),
                                filter((item) => item && item.id === id),
                                tap(() => {
                                    // Update the contact if it's selected
                                    this._productVaraiton.next(updatedproductVaraitons);
                                })
                            )
                        )
                    );
            })
        );
    }

    /**
     * Delete the contact
     *
     * @param id
     */
    deleteproductVaraiton(id: string): Observable<boolean> {
        return this.productVaraitons$.pipe(
            take(1),
            switchMap((productVaraiton) =>
                this._httpClient.delete(`${this.url}${id}/`).pipe(
                    map((isDeleted: boolean) => {
                        // Find the index of the deleted contact
                        const index = productVaraiton.findIndex(
                            (item) => item.id === id
                        );

                        // Delete the contact
                        productVaraiton.splice(index, 1);

                        // Update the contacts
                        this._productVaraitonss.next(productVaraiton);

                        // Return the deleted status
                        return isDeleted;
                    })
                )
            )
        );
    }


    getItemsReport(id: any): Observable<any> {
        return this._httpClient.get<any>(`${server_env.URL}api/v1/product_variation/${id}/statistics`).pipe(
            tap((accountReport: any) => {
                this._itemReport.next(accountReport);
            })
        );
    }
    getStockReport(id: any): Observable<any> {
        return this._httpClient.get<any>(`${server_env.URL}api/v1/stockHistory/${id}/`).pipe(
            tap((accountReport: any) => {
                this._stockReport.next(accountReport);
            })
        );
    }

    /**
     * create customer
     * @param data
     * @returns
     */

    createAssumedStock(data): Promise<any> {
        return new Promise((resolve, reject) => {
            const requestUrl = this.url2;

            this._httpClient.post<any>(`${requestUrl}`, data).subscribe(
                (response) => {
                    return resolve(response);
                },
                (error) => {
                    return reject(error);
                }
            );
        });
    }

    
}
