import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, switchMap, take, tap } from 'rxjs/operators';
import { PackageType } from './package.type';
import { server_env } from 'config';

@Injectable({
    providedIn: 'root',
})
export class PackagesOderService {
    // Private
    private _packagesOder: BehaviorSubject<PackageType>;

    private _packagesOderss: BehaviorSubject<PackageType[]>;

    public url = `${server_env.URL}api/v1/packages/`;

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) { }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors

    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for contact
     */
    get packageOrder$(): Observable<PackageType> {
        return this._packagesOder.asObservable();
    }

    /**
     * Getter for contacts
     */
    get salesOrders$(): Observable<PackageType[]> {
        return this._packagesOderss.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get contacts
     */

    getPackagesOder(): Observable<PackageType[]> {
        return this._httpClient.get<PackageType[]>(`${this.url}`).pipe(
            tap((packageOrder: any) => {
                if (packageOrder in packageOrder.data) {
                    this._packagesOderss.next(packageOrder.data);
                }
            })
        );
    }

    /**
     * Get contact by id
     */
    getPackageOderByID(id: any): Observable<any> {
        const requestUrl = this.url + id;
        // console.log(requestUrl);
        // if you uncommend the below code then just below line should be commented and the line return data should be uncommmented
        // const data = this._httpClient.get<SalesOrder[]>(`${requestUrl}`).pipe(
        return this._httpClient.get<PackageType>(`${requestUrl}/`).pipe(
            take(1),

            switchMap((packageorder: any) => {
                return of(packageorder.data);
            }),
            catchError((error) => {
                return throwError(
                    'Could not find package with id of ' + id + '!'
                );
            })
        );
        // console.log(data);
        // return data;
    }

    /**
     * Create contact
     */
    // createSalesOrder(): Observable<SalesOrder>
    // {
    //     return this.salesOrder$.pipe(
    //         take(1),
    //         switchMap(salesOrder => this._httpClient.post<SalesOrder>(`${this.url}`, {}).pipe(
    //             map((newSalesOrder) => {

    //                 // Update the contacts with the new contact
    //                 this._salesorderss.next([newSalesOrder, ...salesOrder]);

    //                 // Return the new contact
    //                 return newSalesOrder;
    //             })
    //         ))
    //     );
    // }

    /**
     * create customer
     * @param data
     * @returns
     */

    createPackageOrder(data): Promise<any> {
        return new Promise((resolve, reject) => {
            const requestUrl = this.url;

            this._httpClient.post<any>(`${requestUrl}`, data).subscribe(
                (response) => {
                    return resolve(response);
                },
                (error) => {
                    return reject(error);
                }
            );
        });
    }

    /**
     * Update contact
     *
     * @param id
     * @param contact
     * @returns
     */
    
    updatePackageOrder(id, contact): Promise<any> {
        return new Promise((resolve, reject) => {
            const requestUrl = this.url;

            this._httpClient.patch<any>(`${requestUrl}${id}/`, contact).subscribe(
                (response) => {
                    return resolve(response);
                },
                (error) => {
                    return reject(error);
                }
            );
        });
    }
    
    

    /**
     * Delete the contact
     *
     * @param id
     */
    // deleteSalesOrder(id: string): Observable<boolean> {
    //     return this.salesOrders$.pipe(
    //         take(1),
    //         switchMap((salesOrder) =>
    //             this._httpClient.delete(`${this.url}${id}/`).pipe(
    //                 map((isDeleted: boolean) => {
    //                     // Find the index of the deleted contact
    //                     const index = salesOrder.findIndex(
    //                         (item) => item.id === id
    //                     );

    //                     // Delete the contact
    //                     salesOrder.splice(index, 1);

    //                     // Update the contacts
    //                     this._salesorderss.next(salesOrder);

    //                     // Return the deleted status
    //                     return isDeleted;
    //                 })
    //             )
    //         )
    //     );
    // }
}
