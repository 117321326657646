// unsaved-changes.guard.ts

import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { WmCanLeaveComponent } from './wm-can-leave.component';
import { map } from 'rxjs/operators';

export interface HasUnsavedChanges {
  hasUnsavedChanges(): boolean;
}

@Injectable({
  providedIn: 'root',
})
export class UnsavedChangesGuard implements CanDeactivate<HasUnsavedChanges> {

  constructor(private dialog: MatDialog) {}

  canDeactivate(component: HasUnsavedChanges): Observable<boolean> | boolean {
    if (component.hasUnsavedChanges()) {
      // Open the confirmation dialog
      const dialogRef = this.dialog.open(WmCanLeaveComponent, {
        disableClose: true, // Prevent closing the dialog by clicking outside
      });

      // Handle dialog close result
      return dialogRef.afterClosed().pipe(
        map(result => {
          if (result === 'confirmed') {
            // User clicked "Stay" in the dialog, handle accordingly
            return false; // Prevent leaving
          } else if (result === 'Leave') {
            // User clicked "Leave Anyway" in the dialog, allow leaving
            return true;
          } else {
            // User clicked "Close" or closed the dialog, prevent leaving
            return false;
          }
        })
      );
    }

    // If there are no unsaved changes, allow leaving
    return true;
  }
}
