<div class="sm:absolute sm:inset-0 flex flex-col flex-auto min-w-0 sm:overflow-hidden bg-card dark:bg-transparent">
  <!-- Header -->
  <div class="relative flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between py-8 px-6 md:px-8 border-b">
    <!-- Loader -->
    <div class="absolute inset-x-0 bottom-0" *ngIf="isLoading">
      <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
    </div>
    <!-- Title -->
    <div class="text-4xl font-bold tracking-tight">
      Product Bundle
      <mat-icon svgIcon="feather:shopping-bag"></mat-icon>
    </div>
    <!-- Actions -->
    <div class="flex flex-shrink-0 items-center mt-6 sm:mt-0 sm:ml-4">
      <!-- Search -->
      <mat-form-field class="fuse-mat-dense fuse-mat-no-subscript min-w-50">
        <mat-icon matPrefix [svgIcon]="'heroicons_outline:search'"></mat-icon>
        <input matInput [autocomplete]="'off'" (keyup)="applyFilter($event)" [placeholder]="'Search Products'" />
      </mat-form-field>
      <!-- Add product button -->
      <button class="ml-4 mr-4" mat-flat-button [color]="'primary'" (click)="createProduct()">
        <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
        <span class="ml-2 mr-1">Add</span>
      </button>

      <button [color]="'primary'" mat-flat-button (click)="exportexcel()">
        <mat-icon matTooltip="Export your tabel to excel" [svgIcon]="'heroicons_outline:clipboard'"></mat-icon>
        <span class="ml-2 mr-1">Export </span>
      </button>
    </div>
  </div>

  <!-- Main -->
  <div class="flex flex-auto overflow-hidden">
    <!-- Products list -->
    <div class="flex flex-col flex-auto sm:mb-18 overflow-hidden sm:overflow-y-auto">
      <div class="mat-elevation-z8">
        <table id="excel-table" mat-table [dataSource]="dataSource" matSort matPaginator class="mat-table">


          <!-- CustomerName Column -->
          <ng-container matColumnDef="Bundle Name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Bundle Name
            </th>
            <td mat-cell *matCellDef="let row">
              <div class="avatar">
                {{ row.bundle_name }}
              </div>
            </td>
          </ng-container>


          <!-- Status Column -->
          <ng-container matColumnDef="Bundle Description">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Description
            </th>
            <td mat-cell *matCellDef="let row">
              <div class="avatar">

                {{ row.bundle_description }}
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="disabled">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Status
            </th>
            <td mat-cell *matCellDef="let row">
              <div class="avatar">

                {{ row.disabled ? 'Disabled' : 'Enabled' }}

              <mat-slide-toggle class="icon-size-3" [checked]="!row.disabled" (change)="toggleDisabledStatus(row.id)">

              </mat-slide-toggle>
              </div>
            </td>
          </ng-container>




          <!-- Actions Column -->
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let row">
              <button mat-icon-button matTooltip="View" (click)="showDetails(row.id)">
                <mat-icon class="icon-size-4" svgIcon="heroicons_outline:eye"></mat-icon>
              </button>


              <button mat-icon-button matTooltip="Delete" (click)="deleteVendorsCredit(row.id)">
                <mat-icon class="icon-size-4" svgIcon="heroicons_outline:trash"></mat-icon>
              </button>

            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        </table>

        <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>
      </div>
    </div>
  </div>
</div>