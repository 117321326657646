<div class="sm:absolute sm:inset-0 flex flex-col flex-auto min-w-0 sm:overflow-hidden bg-card dark:bg-transparent">
  <!-- Header -->
  <div class="relative flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between py-8 px-6 md:px-8 border-b">
    <!-- Loader -->
    <div class="absolute inset-x-0 bottom-0" *ngIf="isLoading">
      <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
    </div>
    <!-- Title -->
    <div class="text-4xl font-bold tracking-tight">Initial Stock Entry <mat-icon
        svgIcon="heroicons_outline:academic-cap"></mat-icon></div>
    <!-- Actions -->
    <div class="flex flex-shrink-0 items-center mt-6 sm:mt-0 sm:ml-4">
      <!-- Filter -->

      <mat-form-field class="fuse-mat-rounded fuse-mat-no-subscript min-w-50 mr-3">
        <mat-select style="font-family:Montserrat;font-size: smaller;" [(ngModel)]="selectedInital"
          (ngModelChange)="applyFilters()" placeholder="Inital Stock Type">
          <mat-option style="font-size: smaller; font-family:Montserrat" value="">----</mat-option>
          <mat-option style="font-size: smaller; font-family:Montserrat" value="Pre Order Inital">Pre-Order Inital
          </mat-option>
          <mat-option style="font-size: smaller; font-family:Montserrat" value="Product Inital">Product Inital
          </mat-option>

        </mat-select>
        <mat-icon class="icon-size-4" matPrefix [svgIcon]="'feather:globe'"></mat-icon>
      </mat-form-field>

      <!-- Search -->

      <mat-form-field class="fuse-mat-rounded fuse-mat-no-subscript min-w-50">
        <mat-icon matPrefix [svgIcon]="'heroicons_outline:search'"></mat-icon>
        <input matInput [autocomplete]="'off'" (keyup)="applyFilter($event)" [placeholder]="'Search Inital Stock'">
      </mat-form-field>
      <!-- Add product button -->
      <button class="ml-4" mat-flat-button [color]="'primary'" (click)="createInital()">
        <mat-icon [svgIcon]="'heroicons_outline:plus'"></mat-icon>
        <span class="ml-2 mr-1">Add</span>
      </button>
    </div>



  </div>

  <!-- Main -->
  <div class="flex flex-auto overflow-hidden">
    <!-- Products list -->
    <div class="flex flex-col flex-auto sm:mb-18 overflow-hidden sm:overflow-y-auto">
      <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource" matSort matPaginator class="mat-table">
          <!-- Waist Column -->

          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
            <td mat-cell *matCellDef="let row"> <strong>
                {{ row.id }}
              </strong></td>
          </ng-container>

          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
            <td mat-cell *matCellDef="let row"> <i><u>
                  {{ row.date }}
                </u></i> </td>
          </ng-container>

          <ng-container matColumnDef="product">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Product Name </th>
            <td mat-cell *matCellDef="let row"> {{ row.productName }} </td>
          </ng-container>
          <ng-container matColumnDef="size">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Size </th>
            <td mat-cell *matCellDef="let row"> {{ row.size }} </td>
          </ng-container>
          <ng-container matColumnDef="color">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Color </th>
            <td mat-cell *matCellDef="let row"> {{ row.color }} </td>
          </ng-container>
          <ng-container matColumnDef="stock">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Inital Entry </th>
            <td mat-cell *matCellDef="let row"> <strong>{{ row.initial_entry }}

              </strong> </td>
          </ng-container>

          <ng-container matColumnDef="created_by">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Created By.</th>
            <td mat-cell *matCellDef="let row"> <strong>{{ row.created_by }}

            </strong> </td>
          </ng-container>
          <!-- Actions Column -->
         

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>


        <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>
      </div>
    </div>
  </div>



</div>