
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SalesOrderService } from '../../sales-order/sales-order.service';
import { PackagePopupComponent } from 'app/packages/package-popup/package-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { PreOrdersService } from '../pre-orders.service';

@Component({
    selector: 'app-detail-pre-orders',
    templateUrl: './detail-pre-orders.component.html',
    styleUrls: ['./detail-pre-orders.component.scss'],
})
export class DetailPreOrdersComponent implements OnInit, OnDestroy {
    productId: string;
    vendor: any; // Replace 'any' with the actual type of your product
    dropdownHidden: boolean = true; //drop down for create the pack

    constructor(
        private route: ActivatedRoute,
        private productService: PreOrdersService, // Replace with your actual product service
        private dialog: MatDialog
    ) { }

    ngOnInit(): void {
        this.route.paramMap.subscribe((params) => {
            this.productId = params.get('id');
            this.fetchProductDetails(this.productId);
        });
    }
    fetchProductDetails(productId: string): void {
        // Use your product service to fetch the product details based on the provided ID
        this.productService.getPreOrdeByID(productId).subscribe(
            (data) => {
                this.vendor = data;

                this.vendor.products.forEach(product => {
                    const hasBundleId = !!product.product_bundle?.id; // Check if product_bundle has an id property
                    product.isBundle = hasBundleId;

                    product.displayName = hasBundleId ? product.product_bundle.bundle_name : product.product.base_product.productName;
                    product.displayDescription = hasBundleId ? product.product_bundle.bundle_description : product.product.base_product.description;
                });
            },
            (error) => {
                console.error('Error fetching product details:', error);
            }
        );
        // console.log(this.vendor);
    }
    printPage() {
        // Select the buttons you want to hide
        const buttonsToHide = document.querySelectorAll('button');

        // Hide the buttons before printing
        buttonsToHide.forEach((button) => {
            button.style.display = 'none';
        });

        // Perform the printing
        window.print();

        // Show the buttons again after printing (delayed to ensure they show after the print dialog)
        setTimeout(() => {
            buttonsToHide.forEach((button) => {
                button.style.display = 'block'; // or 'inline' depending on the button's original display property
            });
        }, 1000); // Adjust the delay as needed
    }

    goBackOnPreviousWin=()=>{
        window.history.back()
    }


    getThumbnailUrl(thumbnail: string): string {
        const baseUrl = 'http://103.147.227.9:2/8081'; // Your base URL here
        return baseUrl + thumbnail;
    }
    toggleDropdown() {
        this.dropdownHidden = !this.dropdownHidden;
    }

    // on package click this open the package dialog box
    openPackagepopup(saleOderId: any) {
        const dialogRef = this.dialog.open(PackagePopupComponent, {
            width: '800px',
            data: { saleOderId }, // Adjust the width as per your requirements
            // Pass the selected vendor as data to the modal
        });

        dialogRef.afterClosed().subscribe((result) => {
            // Handle any actions after the modal is closed
        });
    }

    ngOnDestroy(): void { }
}