import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { CreditNotesComponent } from './credt-notes/credt-notes.component';
import { CreateCreditNotesComponent } from './create-credit-notes/create-credit-notes.component';
import { ListCreditNotesComponent } from './list-credit-notes/list-credit-notes.component';
import { DetailCreditNotesComponent } from './detail-credit-notes/detail-credit-notes.component';
import { MaterialModule } from 'app/modules/material/material.module';
import { RouterModule } from '@angular/router';
import { CreditNotesRoutes } from './credit-notes.routing';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { CustomSelectModule } from 'app/custom-select/custom-select.module';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { RefundPopupComponent } from './refund-popup/refund-popup.component';

@NgModule({
    declarations: [
        CreditNotesComponent,
        CreateCreditNotesComponent,
        ListCreditNotesComponent,
        DetailCreditNotesComponent,
        RefundPopupComponent,
    ],
    imports: [
        RouterModule.forChild(CreditNotesRoutes),
        CommonModule,
        MaterialModule,
        MatDialogModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        CustomSelectModule,
    ],
    providers: [
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: 'YYYY-MM-DD',
                },
                display: {
                    dateInput: 'YYYY-MM-DD',
                    monthYearLabel: 'MMM YYYY',
                    dateA11yLabel: 'LL',
                    monthYearA11yLabel: 'MMMM YYYY',
                },
            },
        },
        DatePipe,
    ],
})
export class CreditNotesModule {}
