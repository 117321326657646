import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from 'app/core/auth/auth.service';
import { switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
    constructor(
        private _authService: AuthService,
        private _router: Router
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const redirectUrl = state.url === '/sign-out' ? '/' : state.url;

        // Check authentication status
        return this._check(redirectUrl).pipe(
            switchMap((authenticated) => {
                if (!authenticated) {
                    return of(false);
                }

                // Check if the route has data with required roles
                const requiredRoles = route.data?.roles as string[];

                if (requiredRoles && requiredRoles.length > 0) {
                    // Check if the user has the required roles
                    const hasRoles = this._authService.hasRoles(requiredRoles);

                    if (!hasRoles) {
                        // Redirect to unauthorized page or handle as needed
                        this._router.navigate(['unauthorized']);
                        return of(false);
                    }
                }

                return of(true);
            })
        );
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
        return this._check(redirectUrl);
    }

    canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
        return this._check('/');
    }

    private _check(redirectURL: string): Observable<boolean> {
        return this._authService.check().pipe(
            switchMap((authenticated) => {
                if (!authenticated) {
                    this._router.navigate(['sign-in'], { queryParams: { redirectURL } });
                    return of(false);
                }

                return of(true);
            })
        );
    }
}
