import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';
import { SalesOrderModule } from 'app/sales-order/sales-order.module';
import { CreditNotesModule } from 'app/credit-notes/credit-notes.module';
import { ExchangesModule } from 'app/exchanges/exchanges.module';
import { PreOrdersModule } from 'app/pre-orders/pre-orders.module';
import { SalesReturnModule } from 'app/sales-return/sales-return.module';
import { ProductsModule } from './products/products.module';
import { ProductGroupModule } from './product-group/product-group.module';
import { ProductBundleModule } from './product-bundle/product-bundle.module';
import { ProductCategoriesModule } from './product-categories/product-categories.module';
import { SizeModule } from 'app/size/size.module';
import { PrizeListModule } from 'app/prize-list/prize-list.module';
import { InventoryEntryModule } from './inventory-entry/inventory-entry.module';
import { InventroySheetModule } from './inventroy-sheet/inventroy-sheet.module';
import { InventroyAdjustmentModule } from './inventroy-adjustment/inventroy-adjustment.module';
import { VendorsExpenseModule } from './vendors-expense/vendors-expense.module';
import { VendorsCreditModule } from './vendors-credit/vendors-credit.module';
import { PrPackagesModule } from './pr-packages/pr-packages.module';
import { PrPackagesReturnModule } from './pr-packages-return/pr-packages-return.module';
import { PackagesHistoryModule } from './packages-history/packages-history.module';
import { PackagesModule } from './packages/packages.module';
import { RestockEntryModule } from 'app/restock-entry/restock-entry.module';
import { PrPackagesCreditNotesModule } from 'app/pr-packages-credit-notes/pr-packages-credit-notes.module';
import { ProductColorsModule } from 'app/colors/colors.module';
import { VendorsPaymentModule } from 'app/vendors-payment/vendors-payment.module';
import { SalesLogsModule } from 'app/sales-logs/sales-logs.module';
import { RestockProductModule } from 'app/restock-product/restock-product.module';
import { PromoCodeModule } from 'app/promo-code/promo-code.module';
import { RoleGuard } from './core/auth/guards/role.auth.guard';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
    // Redirect empty path to '/dashboards/project'
    { path: '', pathMatch: 'full', redirectTo: 'dashboards/project' },

    // Redirect signed in user to the '/dashboards/project'
    //
    // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    {
        path: 'signed-in-redirect',
        pathMatch: 'full',
        redirectTo: 'dashboards/project',
    },

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'confirmation-required',
                loadChildren: () =>
                    import(
                        'app/modules/auth/confirmation-required/confirmation-required.module'
                    ).then((m) => m.AuthConfirmationRequiredModule),
            },
            {
                path: 'forgot-password',
                loadChildren: () =>
                    import(
                        'app/modules/auth/forgot-password/forgot-password.module'
                    ).then((m) => m.AuthForgotPasswordModule),
            },
            {
                path: 'reset-password',
                loadChildren: () =>
                    import(
                        'app/modules/auth/reset-password/reset-password.module'
                    ).then((m) => m.AuthResetPasswordModule),
            },
            {
                path: 'sign-in',
                loadChildren: () =>
                    import('app/modules/auth/sign-in/sign-in.module').then(
                        (m) => m.AuthSignInModule
                    ),
            },
            {
                path: 'sign-up',
                loadChildren: () =>
                    import('app/modules/auth/sign-up/sign-up.module').then(
                        (m) => m.AuthSignUpModule
                    ),
            },
        ],
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'sign-out',
                loadChildren: () =>
                    import('app/modules/auth/sign-out/sign-out.module').then(
                        (m) => m.AuthSignOutModule
                    ),
            },
            {
                path: 'unlock-session',
                loadChildren: () =>
                    import(
                        'app/modules/auth/unlock-session/unlock-session.module'
                    ).then((m) => m.AuthUnlockSessionModule),
            },
        ],
    },

    // Landing routes
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'home',
                loadChildren: () =>
                    import('app/modules/landing/home/home.module').then(
                        (m) => m.LandingHomeModule
                    ),
            },
        ],
    },

    // Admin routes
    {
        path: '',
        canActivate: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            // Dashboards
            {
                path: 'dashboards',
                canActivate: [RoleGuard],
                data: { required_roles: ['admin', 'Manager', 'HR'] },
                children: [
                    {
                        path: 'project',
                        loadChildren: () =>
                            import(
                                'app/modules/admin/dashboards/project/project.module'
                            ).then((m) => m.ProjectModule),

                    },

                    {
                        path: 'analytics',
                        canActivate: [RoleGuard],
                        data: { required_roles: ['admin', 'Manager', 'HR'] },
                        loadChildren: () =>
                            import(
                                'app/modules/admin/dashboards/analytics/analytics.module'
                            ).then((m) => m.AnalyticsModule),
                    },

                ],
            },

            // Apps
            {
                path: 'apps',


                children: [
                    //For Sales
                    {
                        path: 'sales-order',
                        canActivate: [RoleGuard],

                        data: { required_roles: ["Manager", 'Communication Manager', "Operation Manager", "Production Manager", "HR"] },

                        loadChildren: () =>
                            import('app/sales-order/sales-order.module').then(
                                (m) => SalesOrderModule
                            ),
                    },

                    {
                        path: 'sales-logs',
                        canActivate: [RoleGuard],

                        data: { required_roles: ["Manager", 'Communication Manager', "Operation Manager", "Production Manager", "Accountant", "HR"] },

                        loadChildren: () =>
                            import('app/sales-logs/sales-logs.module').then(
                                (m) => SalesLogsModule
                            ),
                    },
                    {
                        path: 'exchanges',
                        canActivate: [RoleGuard],
                        data: { required_roles: ["Manager", 'Communication Manager', "Operation Manager", "Production Manager", "HR"] },

                        loadChildren: () =>
                            import('app/exchanges/exchanges.module').then(
                                (m) => ExchangesModule
                            ),
                    },
                    {
                        path: 'pre-order',
                        canActivate: [RoleGuard],
                        data: { required_roles: ["Manager", 'Communication Manager', "Operation Manager", "Production Manager", "HR"] },
                        loadChildren: () =>
                            import('app/pre-orders/pre-orders.module').then(
                                (m) => PreOrdersModule
                            ),
                    },
                    {
                        path: 'sales-return',
                        canActivate: [RoleGuard],
                        data: { required_roles: ["Manager", 'Communication Manager', "Operation Manager", "Production Manager", "HR"] },
                        loadChildren: () =>
                            import('app/sales-return/sales-return.module').then(
                                (m) => SalesReturnModule
                            ),
                    },
                    {
                        path: 'credit-notes',
                        canActivate: [RoleGuard],
                        data: { required_roles: ["Manager", 'Communication Manager', "Operation Manager", "Production Manager", "HR", "Accountant"] },
                        loadChildren: () =>
                            import('app/credit-notes/credit-notes.module').then(
                                (m) => CreditNotesModule
                            ),
                    },
                    {
                        path: 'promo-code',
                        canActivate: [RoleGuard],
                        data: { required_roles: ["Manager", 'Communication Manager', "Operation Manager", "Production Manager", "HR"] },
                        loadChildren: () =>
                            import('app/promo-code/promo-code.module').then(
                                (m) => PromoCodeModule
                            ),
                    },

                    // For Relationship
                    {
                        path: 'contacts',
                        canActivate: [RoleGuard],
                        data: { required_roles: ["Manager", 'Communication Manager', "Operation Manager", "Production Manager", "HR"] },

                        loadChildren: () =>
                            import(
                                'app/modules/admin/apps/contacts/contacts.module'
                            ).then((m) => m.ContactsModule),
                    },
                    {
                        path: 'influencer',
                        canActivate: [RoleGuard],
                        data: { required_roles: ["Manager", "Creative Manager", "Production Manager", "HR"] },
                        loadChildren: () =>
                            import(
                                'app/modules/Influencer/influencer.module'
                            ).then((m) => m.InfluencersModule),
                    },
                    {
                        path: 'employees',
                        // canActivate: [RoleGuard],

                        // data: { required_roles: ["Manager","Production Manager", "HR"] },

                        loadChildren: () =>
                            import(
                                'app/modules/Employees/employee.module'
                            ).then((m) => m.EmployeeModule),
                    },
                    {
                        path: 'vendors',
                        canActivate: [RoleGuard],
                        data: { required_roles: ["Manager", "Production Manager", "Accountant", "HR"] },


                        loadChildren: () =>
                            import('app/modules/Vendor/vendors.module').then(
                                (m) => m.VendorsModule
                            ),
                    },
                    //For Daybook
                    {
                        path: 'daybook',
                        canActivate: [RoleGuard],
                        data: { required_roles: ["Manager", "Production Manager", "Accountant", "HR"] },
                        loadChildren: () =>
                            import('app/daybook/daybook.module').then(
                                (m) => m.DaybookModule
                            ),
                    },

                    //For Benefits
                    {
                        path: 'benifits',
                        canActivate: [RoleGuard],
                        data: { required_roles: ["Manager", "Production Manager", "Accountant", "HR"] },
                        loadChildren: () =>
                            import('app/benefits/benefits.module').then(
                                (m) => m.BenefitsModule
                            ),
                    },
                    //For Prodcut Master
                    {
                        path: 'products',
                        canActivate: [RoleGuard],
                        data: {
                            required_roles: ["Manager", "Operation Manager", "Production Manager", "HR"],
                        },

                        loadChildren: () =>
                            import('app/products/products.module').then(
                                (m) => ProductsModule
                            ),
                    },
                    {
                        path: 'product-bundle',
                        canActivate: [RoleGuard],
                        data: {
                            required_roles: ["Manager", "Operation Manager", "Production Manager", "HR"],
                        },
                        loadChildren: () =>
                            import(
                                'app/product-bundle/product-bundle.module'
                            ).then((m) => ProductBundleModule),
                    },
                    {
                        path: 'product-group',
                        loadChildren: () =>
                            import(
                                'app/product-group/product-group.module'
                            ).then((m) => ProductGroupModule),
                    },
                    {
                        path: 'product-categories',
                        canActivate: [RoleGuard],
                        data: {
                            required_roles: ["Manager", "Operation Manager", "Production Manager", "HR"],
                        },
                        loadChildren: () =>
                            import(
                                'app/product-categories/product-categories.module'
                            ).then((m) => ProductCategoriesModule),
                    },
                    {
                        path: 'colors',
                        canActivate: [RoleGuard],
                        data: {
                            required_roles: ["Manager", "Operation Manager", "Production Manager", "HR"],
                        },
                        loadChildren: () =>
                            import('app/colors/colors.module').then(
                                (m) => ProductColorsModule
                            ),
                    },
                    {
                        path: 'size',
                        canActivate: [RoleGuard],
                        data: {
                            required_roles: ["Manager", "Operation Manager", "Production Manager", "HR"],
                        },
                        loadChildren: () =>
                            import('app/size/size.module').then(
                                (m) => SizeModule
                            ),
                    },
                    {
                        path: 'prizeList',
                        canActivate: [RoleGuard],
                        data: {
                            required_roles: ["Manager", "Operation Manager", "Production Manager", "HR"],
                        },
                        loadChildren: () =>
                            import('app/prize-list/prize-list.module').then(
                                (m) => PrizeListModule
                            ),
                    },

                    //For Inventory
                    {
                        path: 'inventory-sheet',
                        canActivate: [RoleGuard],
                        data: {
                            required_roles: ["Manager", 'Communication Manager', "Operation Manager", "Creative Manager", "Production Manager", "Accountant", "HR"]

                        },

                        loadChildren: () => import('app/inventroy-sheet/inventroy-sheet.module').then(m => InventroySheetModule)
                    },
                    {
                        path: 'inventory-entry',
                        canActivate: [RoleGuard],
                        data: {
                            required_roles: ["Manager", "Operation Manager", "Production Manager", "HR"]

                        },
                        loadChildren: () => import('app/inventory-entry/inventory-entry.module').then(m => InventoryEntryModule)
                    },
                    {
                        path: 'restock-entry',
                        canActivate: [RoleGuard],
                        data: {
                            required_roles: ["Manager", "Operation Manager", "Production Manager", "HR"]

                        },
                        loadChildren: () => import('app/restock-entry/restock-entry.module').then(m => RestockEntryModule)
                    },
                    {
                        path: 'inventory-adjustment',
                        canActivate: [RoleGuard],
                        data: {
                            required_roles: ["Manager", "Operation Manager", "Production Manager", "HR"]

                        },
                        loadChildren: () => import('app/inventroy-adjustment/inventroy-adjustment.module').then(m => InventroyAdjustmentModule)
                    },
                    {
                        path: 'restock-product',
                        canActivate: [RoleGuard],
                        data: {
                            required_roles: ["Manager", "Operation Manager", "Production Manager", "HR"]

                        },
                        loadChildren: () => import('app/restock-product/restock-product.module').then(m => RestockProductModule)
                    },


                    //For Purchases
                    {
                        path: 'vendors-payment',
                        canActivate: [RoleGuard],
                        data: {
                            required_roles: ["Manager", "Accountant", "Production Manager", "HR"],
                        },
                        loadChildren: () => import('app/vendors-payment/vendors-payment.module').then(m => VendorsPaymentModule)
                    },
                    {
                        path: 'vendors-expenses',
                        canActivate: [RoleGuard],
                        data: {
                            required_roles: ["Manager", "Accountant", "Production Manager", "HR"],
                        },
                        loadChildren: () => import('app/vendors-expense/vendors-expense.module').then(m => VendorsExpenseModule)
                    },
                    {
                        path: 'vendors-credit',
                        canActivate: [RoleGuard],
                        data: {
                            required_roles: ["Manager", "Accountant", "Production Manager", "HR"],
                        },
                        loadChildren: () => import('app/vendors-credit/vendors-credit.module').then(m => VendorsCreditModule)
                    },

                    //For Inventory

                    {
                        path: 'inventory-entry',
                        canActivate: [RoleGuard],
                        data: {
                            required_roles: ["Manager", "Operation Manager", "Production Manager", "HR"]

                        },
                        loadChildren: () =>
                            import(
                                'app/inventory-entry/inventory-entry.module'
                            ).then((m) => InventoryEntryModule),
                    },
                    {
                        path: 'restock-entry',
                        canActivate: [RoleGuard],
                        data: {
                            required_roles: ["Manager", "Operation Manager", "Production Manager", "HR"]

                        },
                        loadChildren: () =>
                            import(
                                'app/restock-entry/restock-entry.module'
                            ).then((m) => RestockEntryModule),
                    },
                    {
                        path: 'inventory-adjustment',
                        canActivate: [RoleGuard],
                        data: {
                            required_roles: ["Manager", "Operation Manager", "Production Manager", "HR"]

                        },
                        loadChildren: () =>
                            import(
                                'app/inventroy-adjustment/inventroy-adjustment.module'
                            ).then((m) => InventroyAdjustmentModule),
                    },

                    //For Purchases
                    {
                        path: 'vendors-payment',
                        canActivate: [RoleGuard],
                        data: {
                            required_roles: ["Manager", "Accountant", "Production Manager", "HR"],
                        },
                        loadChildren: () =>
                            import(
                                'app/vendors-payment/vendors-payment.module'
                            ).then((m) => VendorsPaymentModule),
                    },
                    {
                        path: 'vendors-expenses',
                        canActivate: [RoleGuard],
                        data: {
                            required_roles: ["Manager", "Accountant", "Production Manager", "HR"],
                        },
                        loadChildren: () =>
                            import(
                                'app/vendors-expense/vendors-expense.module'
                            ).then((m) => VendorsExpenseModule),
                    },
                    {
                        path: 'vendors-credit',
                        canActivate: [RoleGuard],
                        data: {
                            required_roles: ["Manager", "Accountant", "Production Manager", "HR"],
                        },
                        loadChildren: () =>
                            import(
                                'app/vendors-credit/vendors-credit.module'
                            ).then((m) => VendorsCreditModule),
                    },

                    //For Influencer Package
                    {
                        path: 'pr-packages',
                        canActivate: [RoleGuard],
                        data: {
                            required_roles: ["Manager", "Creative Manager", "HR"],
                        },
                        loadChildren: () =>
                            import('app/pr-packages/pr-packages.module').then(
                                (m) => PrPackagesModule
                            ),
                    },
                    {
                        path: 'pr-packages-return',
                        canActivate: [RoleGuard],
                        data: {
                            required_roles: ["Manager", "Creative Manager", "HR"],
                        },
                        loadChildren: () =>
                            import(
                                'app/pr-packages-return/pr-packages-return.module'
                            ).then((m) => PrPackagesReturnModule),
                    },
                    {
                        path: 'pr-packages-creditNotes',
                        canActivate: [RoleGuard],
                        data: {
                            required_roles: ["Manager", "Creative Manager", "HR"],
                        },
                        loadChildren: () =>
                            import(
                                'app/pr-packages-credit-notes/pr-packages-credit-notes.module'
                            ).then((m) => PrPackagesCreditNotesModule),
                    },

                    //For Packages
                    {
                        path: 'packages',
                        loadChildren: () =>
                            import('app/packages/packages.module').then(
                                (m) => PackagesModule
                            ),
                    },
                    {
                        path: 'packages-history',
                        loadChildren: () =>
                            import(
                                'app/packages-history/packages-history.module'
                            ).then((m) => PackagesHistoryModule),
                    },


                ],
            },





            // 404 & Catch all
            {
                path: '404-not-found',
                pathMatch: 'full',
                loadChildren: () =>
                    import(
                        'app/modules/admin/pages/error/error-404/error-404.module'
                    ).then((m) => m.Error404Module),
            },
            {
                path: '402-no-permission',
                pathMatch: 'full',
                loadChildren: () =>
                    import(
                        'app/modules/admin/pages/error/error-500/error-500.module'
                    ).then((m) => m.Error500Module),
            },


            { path: '**', redirectTo: '404-not-found' },
        ],
    },
];
