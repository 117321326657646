<div class="w-full h-full">
    <!-- Header -->
    <div
        class="flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between p-6 sm:py-8 sm:px-10 border-b bg-card dark:bg-transparent">
        <div class="flex-1 min-w-0">
            <div class="mt-2">
                <h2 class="text-3xl md:text-4xl font-extrabold tracking-tight leading-7 sm:leading-10 truncate">
                    Expense Management
                </h2>
            </div>
        </div>
    </div>

    <app-loader *ngIf="isLoading"></app-loader>

    <mat-horizontal-stepper #horizontalStepper>
        <!-- Step 1 - Expense Head Information -->
        <mat-step>
            <ng-template matStepLabel>Expense Head</ng-template>
            <form [formGroup]="expenseHeadForm">
                <p class="mt-3 mb-2 font-medium">Provide details about the Expense Head.</p>

                <div class="flex">
                    <mat-form-field [ngClass]="formFieldHelpers"
                        class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix" style="max-width:500px;">
                        <input matInput oninput="this.value = this.value.toUpperCase()" formControlName="name"
                            placeholder="Expense Head Name" required>
                        <mat-icon class="icon-size-4" matPrefix svgIcon="feather:credit-card"></mat-icon>
                    </mat-form-field>

                   
                </div>

                <div class="flex justify-start mt-5">
                    <button class="px-8" mat-flat-button [color]="'primary'"
                        [disabled]="expenseHeadForm.pristine || expenseHeadForm.invalid" type="button"
                        (click)="saveExpenseHead()">Save</button>
                </div>
            </form>
        </mat-step>

        <!-- Step 2 - Expense Category Information -->
        <mat-step>
            <ng-template matStepLabel>Expense Category</ng-template>
            <form [formGroup]="expenseCategoryForm">
                <p class="mt-3 mb-2 font-medium">Provide details about the Expense Category.</p>

                <div class="flex">
                    <mat-form-field [ngClass]="formFieldHelpers"
                        class="flex-auto fuse-mat-rounded fuse-mat-emphasized-affix" style="max-width:500px;">
                        <input matInput oninput="this.value = this.value.toUpperCase()" formControlName="name"
                            placeholder="Category Name" required>
                        <mat-icon class="icon-size-4" matPrefix svgIcon="feather:grid"></mat-icon>
                    </mat-form-field>
                </div>

                <div class="flex justify-start mt-5">
                    <button class="px-8" mat-flat-button [color]="'accent'"
                        [disabled]="expenseCategoryForm.pristine || expenseCategoryForm.invalid" type="button"
                        (click)="saveExpenseCategory()">Save</button>
                </div>
            </form>
        </mat-step>
    </mat-horizontal-stepper>
</div>
