
import {  Component, ElementRef, OnInit } from '@angular/core';
import {  FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { ActivatedRoute, Router } from '@angular/router';


import { Subject } from 'rxjs';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-create-size',
  templateUrl: './create-size.component.html',
  styleUrls: ['./create-size.component.scss']
})
export class CreateSizeComponent implements OnInit {

  drawerMode: 'over' | 'side' = 'side';
  drawerOpened: boolean = true;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  vendorsCredit: FormGroup;
  successMessage: string;
  errorMessage: string;

  genderOptions: { label: string, value: string }[] = [];
  vendorControl: FormControl = new FormControl();

  constructor(
 
    private _formBuilder: FormBuilder,
    private elementRef: ElementRef,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
 
  ) {

  }

  ngOnInit() {
    // Get the vendors
   


    this.vendorsCredit = this._formBuilder.group({
      waist:[''],
      size:[''],
      hips:[''],
      bust:[''],
      cup_size:[''],
      height:[''],
      length:[''],
      fit:[''],
   

      



    });


  }


  
  openVendorDetails() {
  }


 
  private scrollToMessages(): void {
    const messagesElement = this.elementRef.nativeElement.querySelector('#messages');
    if (messagesElement) {
      messagesElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }



  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }





  sumbitsizeInfo(){
    if (this.vendorsCredit.valid) {
      // Show the loading indicator
    
  
      const customerData = { ...this.vendorsCredit.value };
  
     
  
     
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        })
        
        Toast.fire({
          icon: 'success',
          title: ` ${customerData.fit} Size created!! `
        })
        this.vendorsCredit.reset();
        
        this._router.navigate(['../'], { relativeTo: this._activatedRoute });

            
       error=>{
// Hide the loading indicator


if (error && error.message) {

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })
  
  Toast.fire({
    icon: 'error',
    title: `'Error creating Size ' ${error.message};`
  })
} else {

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })
  
  Toast.fire({
    icon: 'error',
    title: 'Error creating Vendors Credit'
  })
}


}

    }
  }
}


