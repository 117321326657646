import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators, FormControl, FormGroup, FormArray } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { validColorValidator } from 'ngx-colors';
import { forkJoin, Observable, of } from 'rxjs';
import { SkuCreationService } from './sku-creation.service';

@Component({
  selector: 'app-product-varients',
  templateUrl: './product-varients.component.html',
  styleUrls: ['./product-varients.component.scss']
})
export class ProductVarientsComponent implements OnInit {
  @Output() formSubmitted: EventEmitter<any> = new EventEmitter<any>();

  productForm: FormGroup;
  formCancel: boolean = true;
  stock_show: boolean[] = [];
  sku_already_created: boolean = false;
  SkuStatus: boolean = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _matDialog: MatDialogRef<ProductVarientsComponent>,
    private _formBuilder: FormBuilder,
    private skuCreationService: SkuCreationService



  ) {

  }

  ngOnInit(): void {

    this.productForm = this._formBuilder.group({

      varients: this._formBuilder.array([])
    });
    
    const observables = this.data.variations.map(variation => this.createSkuOnce(variation));

    forkJoin(observables).subscribe(editedSkus => {
      editedSkus.forEach((editedSku, index) => {
        const variation = this.data.variations[index];
  
        const varientsGroup = this._formBuilder.group({
          productName: [`${variation.size || ''} -  ${variation.color || ''}`],
          size: [`${variation.size || ''}`],
          color: new FormControl(variation.color || '', validColorValidator()),
          sku: [`${editedSku || ''}`],
          price: [`${variation.price || 0}`],
          cfg: [''],
          stock: [`${variation.stock || ''}`],
          status: [`${variation.status || ''}`]
        });
  
        (this.productForm.get('varients') as FormArray).push(varientsGroup);
      });

    });
    
    this.skuCreationService.isSkuAlreadyCreated = true;
  }
  stockSelectionChange(event: any, index: number) {
    this.stock_show[index] = event.value === 'In stock';
  }

  addVarientsField(): void {
    const varientsGroup = this._formBuilder.group({

      productName: [''],
      size: [''],
      color: new FormControl('rgb(79, 195, 255)', validColorValidator()),
      sku: ['', Validators.required],
      price: [''],
      cfg: [''],
      stock: [''],
      status: ['']
    });

    const productArray = this.productForm.get('varients') as FormArray;
    productArray.push(varientsGroup);

    const index = productArray.length - 1;

  }
  toggleReadOnly() {
    this.SkuStatus = !this.SkuStatus; // Toggle the status
  }

  cancel(): void {
    this.formCancel = false;
    this._matDialog.close(false); // Close the dialog with false result
  }

  confirmDelete(): void {



    this._matDialog.close(true); // Close the dialog with true result

    // Flip the fuse-card to the back

  }


  submitForm(formData: any): void {

    // Emit the form data
    this.formSubmitted.emit(formData);

    // Close the modal
    this._matDialog.close();
  }

  createSkuOnce(variation: any): Observable<string> {
    if (!this.skuCreationService.isSkuAlreadyCreated) {
      const color = variation.color || '';
      const size = variation.size || '';
  
      // Get the size based on the specified conditions
      let sizeInitial: string;
    
      let colorInitial = '';
     
  
      const editedSku = `${variation.sku}`;
      return of(editedSku);
    } else {
      return of(variation.sku);
    }
  }


  /**
    * Track by function for ngFor loops
    *
    * @param index
    * @param item
    */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }



  removeVarientsField(index: number): void {
    const productArray = this.productForm.get('varients') as FormArray;
    productArray.removeAt(index);


  }
 


}

