import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SizeComponent } from './size/size.component';
import { ListSizeComponent } from './list-size/list-size.component';
import { CreateSizeComponent } from './create-size/create-size.component';
import { DetailSizeComponent } from './detail-size/detail-size.component';
import { MaterialModule } from 'app/modules/material/material.module';
import { RouterModule } from '@angular/router';
import { SizeRouting } from './size.routing';



@NgModule({
  declarations: [
    SizeComponent,
    ListSizeComponent,
    CreateSizeComponent,
    DetailSizeComponent
  ],
  imports: [
    RouterModule.forChild(SizeRouting),
    MaterialModule,
    CommonModule
  ]
})
export class SizeModule { }
