import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    NgZone,
    OnDestroy,
    OnInit,
    ViewChild,

} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';

import { Observable, Subject, Subscription } from 'rxjs';

import * as ExcelJS from 'exceljs';

export interface SalesOrderData {
    id: string
    name: string;
    sku: string;
    status: boolean;
    stock: string;
    price: any;


}

import { InventoryEntry } from 'app/inventory-entry/inventory-entry-type';
import { productVaraitons } from 'app/productVariations/productVariations.service';
import { productVaraitonService } from 'app/productVariations/productVarations.type';

import { InventorySheetService } from '../inventory-sheet.service';
import { InventorySheet } from '../inventory-sheet.type';
import { MatInput } from '@angular/material/input';
import { MatDialog } from '@angular/material/dialog';
import { AdvanceFilteringComponent } from '../advance-filtering/advance-filtering.component';
import Swal from 'sweetalert2';
import { productsService } from 'app/products/products.service';
import { InitalstockPopupComponent } from '../initalstock-popup/initalstock-popup.component';
import { StockSubmissionService } from '../stock-submission-service.service';

@Component({
    selector: 'app-listinventroy-sheet',
    templateUrl: './listinventroy-sheet.component.html',
    styleUrls: ['./listinventroy-sheet.component.scss'],

})
export class ListinventroySheetComponent implements OnInit, AfterViewInit, OnDestroy {
    displayedColumns: string[] = [


    ];
    isDarkMode = true; // Set this based on your dark mode logic


    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatInput) searchInput!: MatInput;

    @ViewChild(MatPaginator) private _paginator: MatPaginator;
    @ViewChild(MatSort) private _sort: MatSort;
    private inventorySheetsSubscription: Subscription;


    dataSource: MatTableDataSource<any>;

    dataSourceProductVariants: MatTableDataSource<InventorySheet>;


    products$: Observable<InventorySheet[]>;
    product: InventoryEntry[];
    productVar: productVaraitons[];
    productVaraitonss$: Observable<productVaraitons[]>

    flashMessage: 'success' | 'error' | null = null;
    isLoading: boolean = true;

    selectedProduct: InventorySheet | null = null;
    selectedProductVariations: productVaraitons | null = null;


    tagsEditMode: boolean = false;


    private _unsubscribeAll: Subject<any> = new Subject<any>();

    _activatedRoute: any;
    inventoryItems: InventorySheet[];
    inventoryDisplayedColumns: string[];
    productBundleItems: InventorySheet[];
    productGroupItems: InventorySheet[];
    productVariantsItems: InventorySheet[];
    bundleDisplayedColumns: string[];
    prodcutVarientsdisplayedColumns: string[];
    groupdDisplayedColumns: string[];
    isFilterApplied = false;


    // Initialize variables to store combined totals
    combinedTotalPrice = 0;
    combinedTotalStock = 0;
    totalProduct = 0;
    totalPreOrder = 0;
    isToggleEnabled: boolean;

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _inventoryService: InventorySheetService,
        private _productVariations: productVaraitonService,
        private _router: Router,
        private dialog: MatDialog,
        private _productService: productsService,
        private stockSubmissionService: StockSubmissionService,
        private cdr: ChangeDetectorRef


    ) {




        this.dataSourceProductVariants = new MatTableDataSource<InventorySheet>([]);
        this.dataSourceProductVariants.paginator = this.paginator;
        this.dataSourceProductVariants.sort = this.sort;



    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.loadTable();

    }


    loadTable(): void {
        this.isLoading = true;
        this.inventorySheetsSubscription = this._inventoryService.getMergedData().subscribe((mergedData) => {
            if (mergedData.data && Array.isArray(mergedData.data)) {
                this.productVariantsItems = mergedData.data.filter((item) => item.inventory_type === 'Product Variants');
                this.prodcutVarientsdisplayedColumns = this.getDisplayedColumns('Product Variants');
                this.dataSourceProductVariants.data = this.productVariantsItems;
                this.setVarientsItemsData();
                this.isLoading = false;
                this.cdr.detectChanges();
            }
        });
    }
    
    

    setVarientsItemsData() {
        const tableData = [];
        for (const item of this.productVariantsItems) {
            const rowData = {
                Name: item.product_variants.base_product.productName,
                Stock: item.product_variants.stock,
                SKU: item.product_variants.sku,
                Color: item.product_variants.color,
                Size: item.product_variants.size,
                PreOrder: item.product_variants.preOrder,
                active: item.product_variants.active,
                Price: item.product_variants.basePrice,
                id: item.product_variants.id, // Ensure id is set correctly
                BASE_PRODUCT: item.product_variants.base_product.id,
                product: item.product_variants.base_product,
                size: item.product_variants.size,
                color: item.product_variants.color,
                restock: item.product_variants.restock,
            };
    
            this.isToggleEnabled = rowData.PreOrder;
            tableData.push(rowData);
        }
    
        this.displayedColumns = this.getDisplayedColumns('Product Variants');
        this.dataSourceProductVariants.data = tableData;
    
        // Calculate totals
        const totalPrice = this.calculateTotalPrice(tableData);
        const totalStock = this.calculateTotalStock(tableData);
        const totalPreOrders = this.calculateTotalPreOrders(tableData);
    
        const totalProducts = tableData.length;
        this.combinedTotalPrice = totalPrice;
        this.combinedTotalStock = totalStock;
        this.totalProduct = totalProducts;
        this.totalPreOrder = totalPreOrders;
    }
    
   
    

    onToggleChange(row: any, event: any): void {
        event.preventDefault(); // Prevent default toggle action

        Swal.fire({
            title: row.PreOrder ? 'Disable Pre-Order?' : 'Enable Pre-Order?',
            text: row.PreOrder ? "This will remove the Pre-Order option for this item. Are you sure?" :
                "This will add the Pre-Order option for this item. Are you sure?",
            icon: row.PreOrder ? 'warning' : 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: row.PreOrder ? 'Yes, disable it!' : 'Yes, enable it!',
            allowOutsideClick: false,  // Prevent users from clicking outside the dialog to close it
        }).then((result) => {
            if (result.isConfirmed) {
                // Disable interaction during the asynchronous operation
                this.isToggleEnabled = false;

                // Update the Pre-Order status (temporary)

                // Call the appropriate update function based on stock
                let updatePromise: Promise<void>;
                if (row.PreOrder == true) {

                    updatePromise = this.disablePreOrder(row);

                } else if (row.PreOrder == false) {

                    updatePromise = this.EnablePreOrder(row);
                } else {
                    // Handle other cases here if needed
                    updatePromise = Promise.reject("Invalid operation");
                }

                // Handle update completion
                updatePromise
                    .then(() => {
                        // Reload the table

                        // Update the visual state now that update is successful
                        row.PreOrder = !row.PreOrder; // Update actual Pre-Order value
                    })
                    .catch((error) => {
                        console.error('Error updating Pre-Order:', error);
                        row.PreOrder = !row.PreOrder; // Revert temporary Pre-Order value
                    })

                    .finally(() => {
                        this.isToggleEnabled = true;
                        this.loadTable();
                        this.cdr.detectChanges();
    

                    });
            } else {
                // If the user cancels, revert the visual state
                event.source.checked = row.PreOrder;
            }
        });
    }

    private disablePreOrder(row: any): Promise<void> {
        return new Promise((resolve, reject) => {
            const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.onmouseenter = Swal.stopTimer;
                    toast.onmouseleave = Swal.resumeTimer;
                }
            });
            if (row.Stock < 0) {
                const dialogRef = this.dialog.open(InitalstockPopupComponent, {
                    width: '900px',
                    data: {
                        size: row.size,
                        color: row.color,
                        product: row.product,
                        id: row.BASE_PRODUCT
                    }
                });
    
                if (this.stockSubmissionService.getStockSubmissionStatus()) {
                    Toast.fire({
                        icon: "success",
                        title: `Pre-Order successfully ${row.PreOrder ? 'enabled' : 'disabled'} for ${row.Name}`
                    });
                    this.loadTable();
                    this.cdr.detectChanges();
    
                    this.stockSubmissionService.resetStockSubmissionStatus();
                    resolve();
                } else {
                    Toast.fire({
                        icon: "info",
                        title: `Item has negative stock. You need to make sure you apply the used stock in initial stock`
                    });
                    reject("Invalid stock submission status");
                }
            } else if (row.Stock == 0) {
                this._productService.updateproductsVarients(row.id, { preOrder: false, base_product: row.BASE_PRODUCT, active: false })
                    .then((response) => {
                        // Success toast should be here
                        Toast.fire({
                            icon: "success",
                            title: `Pre-Order successfully ${row.PreOrder ? 'enabled' : 'disabled'} for ${row.Name}`
                        });
    
                        resolve();
                    })
                    .catch((error) => {
                        console.error('Error updating Pre-Order:', error);
                        reject(error);
                    })
                    .finally(() => {
                   
                        this.loadTable();
                        this.cdr.detectChanges();
    
                        this.stockSubmissionService.resetStockSubmissionStatus();
                    });
            }else if (row.stock  === 0 && row.restock === true){
                this._productService.updateproductsVarients(row.id, { preOrder: false, base_product: row.BASE_PRODUCT })
                .then((response) => {
                    // Success toast should be here
                    Toast.fire({
                        icon: "success",
                        title: `Pre-Order successfully ${row.PreOrder ? 'enabled' : 'disabled'} for ${row.Name}`
                    });

                    resolve();
                })
                .catch((error) => {
                    console.error('Error updating Pre-Order:', error);
                    reject(error);
                })
                .finally(() => {
               
                    this.loadTable();
                    this.cdr.detectChanges();

                });
            }
        });
    }
    


    private EnablePreOrder(row: any): Promise<void> {

        return new Promise((resolve, reject) => {
            let Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.onmouseenter = Swal.stopTimer;
                    toast.onmouseleave = Swal.resumeTimer;
                }
            });

            if (row.Stock === 0 ) {
                console.log("here",row.id)
                this._productService.updateproductsVarients(row.id, { preOrder: true, base_product: row.BASE_PRODUCT })
                    .then((response) => {
                        Toast.fire({
                            icon: "success",
                            title: `Pre-Order successfully ${row.PreOrder ? 'enabled' : 'disabled'} for ${row.Name}`
                        });

                        resolve();  // Resolve the Promise after updating Pre-Order
                    })
                    .catch((error) => {
                        console.error('Error updating Pre-Order:', error);
                        reject(error);  // Reject the Promise if there's an error
                    })
                    .finally(() => {
                        this.loadTable();

                        this.cdr.detectChanges();

                        this.stockSubmissionService.resetStockSubmissionStatus();
                    });


            } else if (row.Stock !== 0) {
                Toast.fire({
                    icon: "error",
                    title: `Cannot enable Pre-Order. Stock should be zero for this operation.`
                });


                reject("Invalid operation");
                  // Reject the Promise when stock is not zero and no update is needed
            }else if (row.stock === 0  && row.preOrder === false && row.restock === true){

                this._productService.updateproductsVarients(row.id, { preOrder: true, base_product: row.BASE_PRODUCT})
                    .then((response) => {
                        Toast.fire({
                            icon: "success",
                            title: `Pre-Order successfully ${row.PreOrder ? 'enabled' : 'disabled'} for ${row.Name}`
                        });

                        resolve();  // Resolve the Promise after updating Pre-Order
                    })
                    .catch((error) => {
                        console.error('Error updating Pre-Order:', error);
                        reject(error);  // Reject the Promise if there's an error
                    })
                    .finally(() => {
                        this.loadTable();

                        this.cdr.detectChanges();

                        this.stockSubmissionService.resetStockSubmissionStatus();
                    });


            }

        });



    }
    getTooltipText(restock: boolean): string {
        return restock ? 'This product has gone through the sales cycle at least once' : 'This product has not gone through the sales cycle at least once';
      }
      
    // Function to calculate total pre-orders
    calculateTotalPreOrders(items: any[]): number {
        return items.filter(row => row.PreOrder).length;
    }

    // Function to calculate total price
    calculateTotalPrice(items: any[]): number {
        return items.reduce((total, row) => total + row.Price, 0);
    }

    // Function to calculate total stock
    calculateTotalStock(items: any[]): number {
        return items.reduce((total, row) => total + row.Stock, 0);
    }


    // Method to get displayed columns based on inventory type
    getDisplayedColumns(inventoryType: string): string[] {
        switch (inventoryType) {

            case 'Product Variants':
                return ['Name', 'SKU', 'Size', 'Color', 'Price', 'Stock','Actions'];
            default:
                return [];
        }
    }
    exportInventoryExcel(): void {
        try {
            const element = document.getElementById('inventory-table');
            
            // Check if the element exists
            if (!element) {
                console.error('Error: The inventory-table element was not found.');
                return;
            }
    
            const data = [];
    
            // Iterate over table rows and collect data
            const rows = element.querySelectorAll('tr');
            rows.forEach((row) => {
                const rowData = [];
                const cells = row.querySelectorAll('td');
                cells.forEach((cell) => {
                    rowData.push(cell.textContent?.trim() || ''); // Use textContent to get the cell's text
                });
                data.push(rowData);
            });
    
            // Create a new workbook and add a worksheet
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('InventorySheet');
    
            // Define styles for header and data cells
            const headerStyle = {
                font: { size: 13, bold: true, color: { argb: 'FFFFFF' } },
                fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '000000' } },
                alignment: { horizontal: 'center' },
                border: { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } },
                padding: { top: 5, bottom: 5, left: 5, right: 5 },
            };
    
            const dataCellStyle = {
                font: { size: 12 },
                alignment: { horizontal: 'left' },
                border: { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } },
                padding: { top: 5, bottom: 5, left: 5, right: 5 },
            };
    
            // Add headers with custom style
            const headers = ['Name', 'SKU', 'Size', 'Color', 'Price', 'Stock'];
            worksheet.addRow(headers).eachCell((cell) => {
                Object.assign(cell, headerStyle);
            });
    
            // Add data with custom style
            data.forEach((rowData) => {
                worksheet.addRow(rowData).eachCell((cell) => {
                    Object.assign(cell, dataCellStyle);
                });
            });
    
            // Ensure minimum column width based on headers
            headers.forEach((header, columnIndex) => {
                const headerWidth = header.length;
                worksheet.getColumn(columnIndex + 1).width = Math.max(headerWidth + 3, 10); // Set minimum width of 10 characters
            });
    
            // Automatically adjust column widths based on cell content
            worksheet.columns.forEach((column) => {
                column.eachCell({ includeEmpty: true }, (cell) => {
                    const cellContentWidth = cell.value ? String(cell.value).length : 0;
                    column.width = Math.max(cellContentWidth + 5, column.width || 15); // Set minimum width of 10 characters
                });
            });
    
            // Create a blob and initiate file download
            workbook.xlsx.writeBuffer().then((buffer) => {
                const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'InventorySheet.xlsx';
                a.click();
            });
    
        } catch (error) {
            console.error('Error occurred while processing data:', error);
        }
    }
    



    /**
     * After view init
     */
    ngAfterViewInit(): void {
        this.dataSourceProductVariants.sort = this.sort;
        this.dataSourceProductVariants.paginator = this.paginator;
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
        if (this.inventorySheetsSubscription) {
            this.inventorySheetsSubscription.unsubscribe();
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------


    /**
    * Toggle product details
    *
    * @param productVarationsId
    */
    toggleDetailsVarationsId(productVarationsId: string): void {
        // If the product is already selected...
        if (this.selectedProductVariations && this.selectedProductVariations.id === productVarationsId) {
            // Close the details
            this.closeDetails();
            return;
        }

        // Get the product by id
        this._productVariations.getproductVaraitonById(productVarationsId)
            .subscribe((productVaraitons) => {

                // Set the selected product
                this.selectedProductVariations = productVaraitons;

                // Fill the form

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
    }



    closeDetails(): void {

        this.selectedProduct = null;
    }


    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();
        this.dataSourceProductVariants.filter = filterValue;

        // If there is a filter value, focus on the search input
        if (filterValue) {
            this.searchInput.focus();
        }
    }



    private calculatePriceRanges(prices: number[]): string[] {

        if (!prices || prices.length === 0) {
            return [];
        }

        const maxPrice = Math.max(...prices);
        if (maxPrice <= 0) {
            return ['0'];
        }

        const minPrice = Math.min(...prices);
        const priceRange = maxPrice - minPrice;



        const ranges = [];

        // Ensure a minimum step size of 1
        const step = Math.max(1, priceRange / 10);

        for (let i = minPrice; i <= maxPrice; i += step) {
            const endRange = i + step;
            const rangeLabel = i === 0 ? `0 - ${endRange.toFixed(2)}` : `${i.toFixed(2)} - ${endRange.toFixed(2)}`;
            ranges.push(rangeLabel);
        }


        return ranges;
    }


    private calculateStockRanges(stocks: number[]): string[] {
        if (!stocks || stocks.length === 0) {
            return ['0'];
        }

        const maxStock = Math.max(...stocks);
        if (maxStock <= 0) {
            return ['0'];
        }

        const ranges = [];
        const step = Math.ceil(maxStock / 10); // Adjust the division factor as needed

        for (let i = 0; i <= maxStock; i += step) {
            const endRange = i + step;
            const rangeLabel = i === 0 ? `0 - ${endRange}` : `${i} - ${endRange}`;
            ranges.push(rangeLabel);
        }

        return ranges;
    }

    calculateFilterValue(filterOptions: any): string {
        const filters = [];

        if (filterOptions.type) {
            filters.push(`Type: ${filterOptions.type}`);
        }

        if (filterOptions.size && filterOptions.size.length > 0) {
            filters.push(`Size: ${filterOptions.size.join(', ')}`);
        }

        if (filterOptions.color && filterOptions.color.length > 0) {
            filters.push(`Color: ${filterOptions.color.join(', ')}`);
        }

        if (filterOptions.price_range) {
            filters.push(`Price Range: ${filterOptions.price_range}`);
        }

        if (filterOptions.stock_range) {
            filters.push(`Stock Range: ${filterOptions.stock_range}`);
        }

        return filters.join(' | ');
    }

    applyFilters(selectedOptions: any) {
        this.isLoading = true;

        const filteredData = this.productVariantsItems.filter(item => {
            // Check if the type matches
            if (selectedOptions.type && item.product_variants.pre_order_toggel !== (selectedOptions.type === 'Pre Order')) {
                return false;
            }

            // Check if the selected sizes are present
            if (selectedOptions.size && selectedOptions.size.length > 0) {
                if (!selectedOptions.size.includes(item.product_variants.size)) {
                    return false;
                }
            }

            // Check if the selected colors are present
            if (selectedOptions.color && selectedOptions.color.length > 0) {
                if (!selectedOptions.color.includes(item.product_variants.color)) {
                    return false;
                }
            }

            // Check if the price is within the selected range
            if (selectedOptions.price_range) {
                const [minPrice, maxPrice] = selectedOptions.price_range.split(' - ');
                const itemPrice = item.product_variants.basePrice;
                if (itemPrice < parseFloat(minPrice) || itemPrice > parseFloat(maxPrice)) {
                    return false;
                }
            }

            // Check if the stock is within the selected range
            if (selectedOptions.stock_range) {
                const [minStock, maxStock] = selectedOptions.stock_range.split(' - ');
                const itemStock = item.product_variants.stock;
                if (itemStock < parseInt(minStock) || itemStock > parseInt(maxStock)) {
                    return false;
                }
            }
            this.isFilterApplied = true;


            return true;
        });
        if (filteredData == null || filteredData.length === 0) {
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer);
                    toast.addEventListener('mouseleave', Swal.resumeTimer);
                },
            });

            Toast.fire({
                icon: 'error',
                title: `No match !!!`,
            });
            this.loadTable();

        } else {
            this.dataSourceProductVariants.data = filteredData;
            const tableData = [];

            for (const item of filteredData) {
                const rowData = {
                    Name: item.product_variants.base_product.productName,
                    Stock: item.product_variants.stock,
                    SKU: item.product_variants.sku,
                    Color: item.product_variants.color,
                    Size: item.product_variants.size,
                    PreOrder: item.product_variants.base_product.pre_order_toggel,
                    active: item.product_variants.active,
                    Price: item.product_variants.basePrice,
                    id:item.product_variants.id,
                };
                tableData.push(rowData);
                this.displayedColumns = this.getDisplayedColumns('Product Variants');
                this.dataSourceProductVariants.data = tableData;
                this.isLoading = false;
            }
        }


    }


    revertSearch() {
        this.isLoading = true;

        this.loadTable();
        this.isFilterApplied = false;

    }


    detailFiltering() {
        if (this.isFilterApplied) {
          this.revertSearch();
        } else {
          const uniqueSizes = Array.from(new Set(this.productVariantsItems.map(item => item.product_variants.size)));
          const uniqueColors = Array.from(new Set(this.productVariantsItems.map(item => item.product_variants.color)));
          const uniquePrices = this.calculatePriceRanges(this.productVariantsItems.map(item => item.product_variants.basePrice));
          const uniqueStocks = this.calculateStockRanges(this.productVariantsItems.map(item => item.product_variants.stock));
      
          const dialogRef = this.dialog.open(AdvanceFilteringComponent, {
            width: '500px',
            data: {
              sizes: uniqueSizes,
              colors: uniqueColors,
              prices: uniquePrices,
              stocks: uniqueStocks
            }
          });
      
          dialogRef.afterClosed().subscribe((result) => {
            if (result && result.saved === true) {
              this.applyFilters(result.selectedOptions);
            }
          });
        }
      }
      




    getStyleForColumn(column: string, row: any) {
        const styles: any = {};

        // Apply specific styles based on the column name
        if (column === 'Name') {
            styles['font-weight'] = 'bold';
        }
        if (column == 'Price') {

            styles['font-weight'] = 'bold';
        }

        if (column === 'SKU') {
            styles['font-style'] = 'italic';
        }

        if (column === 'Stock') {
            if (row[column] >= 5) {
                styles['color'] = 'green';

                styles['font-weight'] = 'bold';
            } else if (row[column] < 1) {
                styles['color'] = 'red';
                styles['font-weight'] = 'bold';
            } else if (row[column] < 3) {
                styles['color'] = 'orange';
                styles['font-weight'] = 'bold';
            }

        }


        return styles;
    }

    getColumnWidth(column: string): string {
        switch (column) {
            case 'Name':
            case 'SKU':
                return '30%'; // Adjust the width for Name and SKU
            case 'Size':
            case 'Stock':
                return '10%'; // Adjust the width for Size and Stock
            case 'Color':
            case 'Price':
                return '15%'; // Adjust the width for Color and Price
            default:
                return 'auto';
        }
    }



    getStockIndicatorBar(stockLevel: number): any {
        const element = document.createElement('div');
        element.classList.add('stock-indicator-bar');

        // Apply specific styles to the stock indicator bar based on the stock level
        if (stockLevel >= 5) {
            element.classList.add('green');
        } else if (stockLevel < 1) {
            element.classList.add('red');
        } else if (stockLevel < 3) {
            element.classList.add('orange');
        }

        return element;
    }

    getProductStyle(product: any) {
        const styles: any = {};

        // Apply specific styles to product properties
        // You can customize these styles as needed
        styles['font-weight'] = 'bold';
        styles['font-style'] = 'italic';
        // Add other styles as needed

        return styles;
    }


    /**
     * Create product
     */
    createInventoryEntry(): void {
        this._router.navigate(['apps/inventory-entry', 'NEW']);
    }

    /**
     * Update the selected product using the form data
     */
    updateSelectedProduct(id: string): void {
        // Get the product object



        // Update the product on the server

    }

    /**
     * Delete the selected product using the form data
     */


    /**
     * Show flash message
     */
    showFlashMessage(type: 'success' | 'error'): void {
        // Show the message
        this.flashMessage = type;

        // Mark for check
        this._changeDetectorRef.markForCheck();

        // Hide it after 3 seconds
        setTimeout(() => {

            this.flashMessage = null;

            // Mark for check
            this._changeDetectorRef.markForCheck();
        }, 3000);
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    viewProductVariation(id:any){
        console.log(id)

        this._router.navigate(['apps/inventory-sheet',id])
    }
}