
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';

import { ActivatedRoute, Router } from '@angular/router';
import { productsService } from 'app/products/products.service';
import { products } from 'app/products/products.type';

import { forkJoin, Observable, of } from 'rxjs';
import Swal from 'sweetalert2';

import {
  map,
  switchMap,
  take,
} from 'rxjs/operators';
import { productVaraitons } from 'app/productVariations/productVariations.service';
import { DetailRestockService } from '../detail-restock.service';
import { DatePipe } from '@angular/common';
import { HasUnsavedChanges } from 'app/page-detect/wm-can-leave/detect-changes.guard';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { productVaraitonService } from 'app/productVariations/productVarations.type';

@Component({
  selector: 'app-create-restock',
  templateUrl: './create-restock.component.html',
  styleUrls: ['./create-restock.component.scss'],

})
export class CreateRestockComponent implements OnInit, AfterViewInit, HasUnsavedChanges {
  @ViewChild('scrollframe', { static: false }) scrollFrame: ElementRef;
  @ViewChildren('item') itemElements: QueryList<any>;

  filteredProductOptions$: Observable<products[]>;
  selectedItems: string[] = [];
  availableItems: string[] = ['Option 1', 'Option 2', 'Option 3', 'Option 4'];
  drawerMode: 'over' | 'side' = 'side';
  drawerOpened: boolean = true;
  restock_entry_form: FormGroup;
  successMessage: string;
  errorMessage: string;
  selectedProductThumbnail: string[] = [];
  selectedProductThumbnailClass: string;

  genderOptions: { label: string, value: string }[] = [];
  vendorControl: FormControl = new FormControl();
  cardValue: any;
  quantity_adjustment: boolean = true;
  value_adjustment: boolean = true;
  current_stock: any[] = [];
  productActive: any[] = [];

  isActives: boolean = false;


  mappedVariations: any;
  sizes: any[] = []; // Initialize with empty array
  colors: any[] = []; // Initialize with empty array
  active: boolean[] = [];
  animationFlags: boolean[] = [];
  matchedVariationIds: Set<string> = new Set();
  todayDate: any;
  configForm: FormGroup;
  configForms: FormGroup;

  private scrollContainer: any;
  private isNearBottom = true;
  constructor(

    private _formBuilder: FormBuilder,
    private elementRef: ElementRef,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _productService: productsService,
    private _productVariationService: productVaraitonService,
    private _itemsService: DetailRestockService,
    private cd: ChangeDetectorRef,
    private datePipe: DatePipe,
    private _fuseConfirmationService: FuseConfirmationService,

  ) { }

  ngOnInit(): void {
    this.configForm = this._formBuilder.group({
      title: 'Info',
      message: '<span class="font-medium">Are you sure you want to proceed?</span> Please be aware that once you submit this information, it cannot be reverted. Are you sure you want to finalize it? Proceed carefully.',
      icon: this._formBuilder.group({
        show: true,
        name: 'heroicons_outline:exclamation',
        color: 'info'
      }),
      actions: this._formBuilder.group({
        confirm: this._formBuilder.group({
          show: true,
          label: 'Proceed',
          color: 'accent'
        }),
        cancel: this._formBuilder.group({
          show: true,
          label: 'Cancel',
          color: 'warn'

        })
      }),
      dismissible: true
    });

    this.configForms = this._formBuilder.group({
      title: 'Info',
      message: '<span class="font-medium">Are you sure you want to proceed?</span> Please be aware that once you remove the product, all the data will be reverted. Are you sure you want to finalize it? Proceed carefully.',
      icon: this._formBuilder.group({
        show: true,
        name: 'heroicons_outline:exclamation',
        color: 'info'
      }),
      actions: this._formBuilder.group({
        confirm: this._formBuilder.group({
          show: true,
          label: 'Proceed',
          color: 'accent'
        }),
        cancel: this._formBuilder.group({
          show: true,
          label: 'Cancel',
          color: 'warn'

        })
      }),
      dismissible: true
    });


    this.restock_entry_form = this._formBuilder.group({
      mode_of_adujstment: ['QuantityAdjustment'],
      date: [''],
      isScheduled: [''],

      products: this._formBuilder.array([]),

    });
    this.filteredProductOptions$ = of([]);

    //clear the product formArray intitally
    (this.restock_entry_form.get('products') as FormArray).clear();
    const initialProductCount = 1;

    this.active = Array(initialProductCount).fill(false);
    // Create a function to handle value changes for a specific product field

    // Loop to create the product form controls
    for (let i = 0; i < initialProductCount; i++) {
      const productGroup = this._formBuilder.group({
        product: ['', [Validators.required]],
        id: [''],
        size: ['',],
        preOrder: [''],
        restock: [''],
        rate: [''],
        quantity: [''],
        stock: ['', [Validators.required]], // Apply the custom validator here
        color: ['',],
        amount: [''],
        tax: [''],

      });

      const first_date = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
      const current_date = new Date(first_date)

      current_date.setDate(current_date.getDate() + 1)

      this.todayDate = this.datePipe.transform(current_date, 'yyyy-MM-dd');

      const productArrayN = this.restock_entry_form.get(
        'products'
      ) as FormArray;


      productArrayN.push(productGroup);

      for (let i = 0; i < productArrayN.length; i++) {
        this.animationFlags[i] = false;
        this.productActive[i] = false;
      };
      this.active = Array(initialProductCount).fill(false);


    }
  }

  ngAfterViewInit(): void {
    this.scrollContainer = this.scrollFrame.nativeElement;
    this.itemElements.changes.subscribe((_) => this.onItemElementsChanged());


  }

  selectChange = (event: any) => {
    const key: string = event.key;
    this.cardValue[key] = [...event.data];
  };

  openVendorDetails() {
  }



  private scrollToMessages(): void {
    const messagesElement = this.elementRef.nativeElement.querySelector('#messages');
    if (messagesElement) {
      messagesElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  hasUnsavedChanges(): boolean {
    return this.restock_entry_form.dirty;
  }

  canDeactivate(): boolean {
    return !this.restock_entry_form.dirty || confirm('You have unsaved changes. Are you sure you want to leave?');
  }


  openConfirmationDialogs(): Observable<boolean> {
    const dialogRef = this._fuseConfirmationService.open(this.configForms.value);

    return dialogRef.afterClosed().pipe(
      map(result => {
        return result === 'confirmed';
      })
    );
  }


  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }





  async sumbitInitalStock() {
    // Handle invalid form data

    let Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });
    if (this.restock_entry_form.valid) {
      const products = this.restock_entry_form.get('products') as FormArray;

      // Calculate the total stock from the 'stock' field
      const totalStock = products.controls.reduce((sum, productControl) => {
        const stock = parseInt(productControl.get('stock').value, 10) || 0;
        return sum + stock;
      }, 0);



      let truex = true;
      let falsex = false;


      const confirmationResult = await this.openConfirmationDialog().toPromise();


      if (confirmationResult) {

        const customerData = { ...this.restock_entry_form.value };


        for (let i = 0; i < products.length; i++) {
          const productData = products.at(i).value;
          const currentStock = this.current_stock[i];
          const changeInStock = parseInt(productData.stock, 10);


          let updatedPreOrder = productData.preOrder;
          let updatedRestock = productData.restock;

          // Check if both preOrder and restock are true
          if (updatedPreOrder == true && updatedRestock == true) {
            updatedPreOrder = false;
          }

          const updatedProduct = {
            product: productData.id,
            size: productData.size,
            color: productData.color,
            basePrice: productData.price,
            base_product: productData.product.id,
            stock: currentStock + changeInStock,  // Always add to the current stock
            active: true,
            preOrder: falsex,
            restock: truex,
          };

          if (updatedProduct.stock < 0) {
            updatedProduct.active = true;
            updatedProduct.preOrder = true;
            updatedProduct.restock = true;

            const assumedStock = {
              base_product: productData.id, // Assuming 'product' field contains an object with 'id'
              stock: productData.stock,
            };

            await this._productVariationService.createAssumedStock(assumedStock);
          }

          console.log(updatedProduct.active, updatedProduct.preOrder, updatedProduct.restock)

          // Call the product update service for each product
          try {
            if (customerData.isScheduled === true) {

              const updatedProductDate = {
                product: productData.id,
                size: productData.size,
                color: productData.color,
                basePrice: productData.price,
                base_product: productData.product.id,
                stock: currentStock + changeInStock,  // Always add to the current stock
                active: true,
                preOrder: updatedPreOrder,
                restock: updatedRestock
              };

              this._productService.updateproductsVarientsScheduled(productData.id, updatedProductDate);
            } else {

              this._productService.updateproductsVarients(productData.id, updatedProduct);
            }

            // Call the inventory update service
            const inventoryData = {
              date: customerData.date,
              isScheduled: customerData.isScheduled,
              products: productData.id,
              restock_entry: productData.stock,
            };

            this._itemsService.createRestockEntrys(inventoryData);

          } catch (error) {
            // Handle errors in updating product or inventory
            const Toast = Swal.mixin({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
              },
            });

            Toast.fire({
              icon: 'error',
              title: `Error updating Product: ${error.message}`,
            });
          }
        }

        // Reset form and navigate if all products are successfully updated
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: 'success',
          title: 'Product Re-stock Successfully.',
        });

        this.restock_entry_form.reset();
        this._router.navigate(['../'], {
          relativeTo: this._activatedRoute,
        });
      } else {

        Toast.fire({
          icon: 'info',
          title: 'Cancled by user',
        });

      }


    } else {


      Toast.fire({
        icon: 'error',
        title: 'Invalid form data',
        text: 'Please fill in all the required fields correctly.',
      });
    }
  }



  addProductField(): void {
    const productFormGroup = this._formBuilder.group({
      id: [''],
      stock: ['', [Validators.required, this.customStockValidator]],
      preOrder: [''],
      restock: [''],

      product: ['',],
      size: ['',],
      rate: [''],
      quantity: [''],
      color: ['',],
      amount: [''],
    });

    const productArray = this.restock_entry_form.get('products') as FormArray;
    productArray.push(productFormGroup);
    const index = productArray.length - 1;

  }


  removeProductField(index: number): void {
    const productArray = this.restock_entry_form.get('products') as FormArray;
    const existingFormGroup = productArray.at(index) as FormGroup;

    // Remove the ID from the matchedVariationIds set
    const removedId = existingFormGroup.get('id').value;
    this.matchedVariationIds.delete(removedId);

    // Remove the values associated with the deleted product
    this.active.splice(index, 1);
    this.animationFlags.splice(index, 1);
    this.current_stock.splice(index, 1);
    this.productActive.splice(index, 1)


    // Remove sizes and colors associated with the deleted product
    this.sizes.splice(index, 1);
    this.colors.splice(index, 1);

    // Remove the form group from the form array
    productArray.removeAt(index);

    this.cd.markForCheck();
  }



  filterOptionsProduct(value: string): void {
    if (typeof value !== 'string') {
      this.filteredProductOptions$ = of([]);
      return;
    }

    this._productService.getproducts().pipe(
      take(1),
      switchMap((products) => {
        if (!Array.isArray(products['data'])) {
          return of([]);
        }

        const productObservables = products['data'].map((product) => {
          return this._productService.getProductVariations(product.id).pipe(
            map((variations) => ({
              product,
              variations: variations.data,
            }))
          );
        });

        return forkJoin(productObservables);
      }),
      map((productsWithVariations) => {
        const filteredProducts = productsWithVariations
          .map(({ product, variations }) => {
            const productId = product.id;

            const allDisabled = variations.every((variation) => {
              const isNotActive = !variation.active;
              const isPreOrder = variation.preOrder;
              const isRestock = variation.restock;



              const isSelected = this.matchedVariationIds.has(variation.id);
              return isNotActive || isSelected || (isPreOrder && !isRestock);
            });

            const disabled = allDisabled;

            return {
              product: productId,
              id: productId,
              productName: product.productName,
              sku: product.sku,
              basePrice: product.basePrice,
              size: product.size,
              color: product.color,
              description: product.description,
              disabled: disabled,
            };
          })
          .filter((filteredProduct) =>
            filteredProduct.productName.toLowerCase().includes(value.toLowerCase()) ||
            (filteredProduct.sku && filteredProduct.sku.toString().toLowerCase().includes(value.toLowerCase()))
          );

        return filteredProducts;
      })
    ).subscribe((filteredProducts) => {
      this.filteredProductOptions$ = of(filteredProducts);
    });
  }



  filtersOptionsProduct(productId: string, index: number): void {
    this._productService.getProductVariations(productId).subscribe((variations) => {
      this.mappedVariations = variations.data.map((variation) => ({
        id: variation.id,
        size: variation.size,
        color: variation.color,
        price: variation.basePrice,
        base_product: productId,
        stock: variation.stock,
        active: variation.active,
        description: variation.base_product.description,
        category: variation.base_product.category,
        gender: variation.base_product.gender,
        preOrder: variation.preOrder,
        restock: variation.restock,
      }));

      const sizeToColorMap = {};
      const allColorsDisabledMap = {};

      this.mappedVariations.forEach((variation) => {
        const { size, color, id, active, preOrder, restock } = variation;

        if (!sizeToColorMap[size]) {
          sizeToColorMap[size] = [];
        }
        const isNotActive = !active;
        const isPreOrder = preOrder;
        const isRestock = restock;

        const isSelected = this.matchedVariationIds.has(id);
        const isOutOfStock = isNotActive || isSelected || (isPreOrder === true && !isRestock);
        const style = isOutOfStock ? { color: 'red' } : {};
        const disabled = isOutOfStock;

        sizeToColorMap[size].push({
          id,
          color,
          style,
          disabled,
        });

        // Mark the color as disabled for this size
        allColorsDisabledMap[color] = allColorsDisabledMap[color] || {};
        allColorsDisabledMap[color][size] = disabled;
      });


      const sizes = Object.keys(sizeToColorMap).map((size) => {
        const colors = sizeToColorMap[size] || [];

        // Check if all colors for this size are disabled
        const disabled = colors.every((color) => allColorsDisabledMap[color.color]?.[size]);

        return {
          id: size,
          name: size,
          disabled,
        };
      });

      const firstSize = sizes[0]?.id;
      const colors =
        sizeToColorMap[firstSize]?.map((color) => ({
          id: color.color,
          name: color.color,
          style: color.style,
          disabled: allColorsDisabledMap[color.color]?.[firstSize],
        })) || [];

      this.updateSizesAndColors(index, sizes, colors);
    });
  }




  openConfirmationDialog(): Observable<boolean> {
    const dialogRef = this._fuseConfirmationService.open(this.configForm.value);

    return dialogRef.afterClosed().pipe(
      map(result => {
        return result === 'confirmed';
      })
    );
  }


  updateSizesAndColors(index: number, sizes: any[], colors: any[]): void {
    this.sizes[index] = sizes;
    this.colors[index] = colors;
  }




  onSelectionChange(index: number): void {
    const productArray = this.restock_entry_form.get('products') as FormArray;
    const existingFormGroup = productArray.at(index) as FormGroup;

    if (existingFormGroup) {
      const selectedProductId = existingFormGroup.get('product').value.id;
      const selectedSizeId = existingFormGroup.get('size').value;


      this.colors[index]?.forEach((color) => {
        color.disabled = false;
      });

      this.mappedVariations.forEach((variation) => {
        const { size, color, id, active, preOrder, restock } = variation;

        if (size === selectedSizeId) {
          const colorToDisable = this.colors[index]?.find(c => c.name === color);

          if (colorToDisable) {
            colorToDisable.disabled = !active || (preOrder === true && !restock) || this.matchedVariationIds.has(id);
          }
        }
      });



      const selectedColorId = existingFormGroup.get('color').value;

      const selectedSizeColorCombo = `${selectedSizeId}${selectedColorId}`;
      const matchingOriginalVariation = this.mappedVariations?.find(variation => {
        const variationSizeColorCombo = `${variation.size}${variation.color}`;
        return variationSizeColorCombo === selectedSizeColorCombo;
      });

      if (matchingOriginalVariation) {
        existingFormGroup.get('amount').patchValue(matchingOriginalVariation.price);
        this.current_stock[index] = matchingOriginalVariation.stock;

        existingFormGroup.get('size').patchValue(matchingOriginalVariation.size);
        existingFormGroup.get('restock').patchValue(matchingOriginalVariation.restock);
        existingFormGroup.get('preOrder').patchValue(matchingOriginalVariation.preOrder);

        existingFormGroup.get('color').patchValue(matchingOriginalVariation.color);
        existingFormGroup.get('id').patchValue(matchingOriginalVariation.id)
        this.matchedVariationIds.add(matchingOriginalVariation.id);


        this.active[index] = true;

        // Set the animation flag to true for the specific form field
        this.animationFlags[index] = true;
        this.addProductField();

        // Reset the animation flag after 3 seconds (adjust the duration as needed)
        setTimeout(() => {
          this.animationFlags[index] = false;
        }, 3000)

        // Call your function to update the form based on the selected product
        console.log("Matching variation found for the selected size and color:", matchingOriginalVariation);
      } else {
        console.log("No matching original variation found for the selected size and color.");
      }
    }
  }


  selectOptionProduct(option: products, index: number): void {

    if (option && !option.isOutOfStock) {
      const productFormGroup = this._formBuilder.group({
        id: [''],
        product: [option, [Validators.required]],
        stock: [option.stock, [Validators.required, this.customStockValidator]],
        preOrder: [""],
        restock: [""],
        size: [],
        color: [],
      });
      this.productActive[index] = true;
      this.filtersOptionsProduct(option.id, index)


      const productArray = this.restock_entry_form.get('products') as FormArray;

    }
  }



  displayProductOption(product: productVaraitons): string {
    if (product) {
      const productName = product.productName || '';
      const productPrice = product.sku
        ? ' - ' + product.sku.toString()
        : '';

      return productName + productPrice;
    }
    return '';
  }


  customStockValidator(index: number) {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      const currentStock = this.current_stock?.[index]; // Use optional chaining here

      if (currentStock !== undefined && value < -currentStock) {
        return { invalidStock: true, message: 'Stock must be at least ' + (-currentStock) + ' to make it zero' };
      }


      // Trigger change detection to ensure the validator reacts to changes in current_stock
      this.cd.detectChanges();

      return null;
    };
  }




  private onItemElementsChanged(): void {
    if (this.isNearBottom) {
      this.scrollToBottom();
    }
  }

  private scrollToBottom(): void {
    this.scrollContainer.scroll({
      top: this.scrollContainer.scrollHeight,
      left: 0,
      behavior: 'smooth',
    });
  }

  private isUserNearBottom(): boolean {
    const threshold = 150;
    const position =
      this.scrollContainer.scrollTop + this.scrollContainer.offsetHeight;
    const height = this.scrollContainer.scrollHeight;
    return position > height - threshold;
  }

  onCheckboxChange(event: any): void {
    if (event.target.checked) {



    } else {

      this.isActives = false;

    }

  }

  scrolled(event: any): void {
    this.isNearBottom = this.isUserNearBottom();
  }

  async makeFieldEditableProduct(index: number) {

    const confirmationResult = await this.openConfirmationDialogs().toPromise();
    if (confirmationResult) {

      this.productActive[index] = false;
      const productInput = document.getElementById(`product-input-${index}`) as HTMLInputElement;
      if (productInput) {
        productInput.removeAttribute('readonly'); // Make the input field editable
        productInput.value = ''; // Clear the value of the input field
      }

      const productArray = this.restock_entry_form.get('products') as FormArray;
      const existingFormGroup = productArray.at(index) as FormGroup;

      // Remove the ID from the matchedVariationIds set
      const removedId = existingFormGroup.get('id').value;
      this.matchedVariationIds.delete(removedId);

      // Clear values associated with the deleted product
      this.active.splice(index, 1);
      this.animationFlags.splice(index, 1);

      // Clear sizes and colors associated with the deleted product
      this.sizes[index] = [];
      this.colors[index] = [];

      // Remove the productActive flag associated with the deleted product
      this.productActive.splice(index, 1);

      // Remove the form group from the form array
      productArray.removeAt(index);
      this.addProductField();
      this.cd.markForCheck();
    } else {
      ""
    }
  }



}
