import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Observable, Subject } from 'rxjs';
import { take, } from 'rxjs/operators';
import { fuseAnimations } from '@fuse/animations';
import { Router } from '@angular/router';
import { InventoryEntryService } from 'app/inventory-entry/inventory-entry.service';
import { MatTableDataSource } from '@angular/material/table';
import Swal from 'sweetalert2';


export interface IntialStock {
  id?: string;
  date?: string;
  products?: any;
  initial_entry?: any;
  isPreOrder?: boolean;
  created_by?:any;

}



@Component({
  selector: 'app-listinventroy-adjustment',
  templateUrl: './listinventroy-adjustment.component.html',
  styleUrls: ['./listinventroy-adjustment.component.scss'],


  animations: fuseAnimations
})
export class ListinventroyAdjustmentComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = ['id', 'date', 'product', 'size', 'color', 'stock','created_by', ];
  selectedInital: string;
  initalStockFilter: string ;

  dataSource: MatTableDataSource<IntialStock>;
  initalSheet: IntialStock[];
  initalSheet$: Observable<IntialStock[]>;

  isLoading: boolean = true;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  _activatedRoute: any;

  /**
   * Constructor
   */
  constructor(
    private _inventoryService: InventoryEntryService,
    private _router: Router,
  ) {

  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {

    this.loadInital();

  }

  ngAfterViewInit(): void {



  }
  applyFilter(event: Event) {


    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  applyFilters() {
    this.isLoading = true;

    this.initalStockFilter = this.selectedInital ? this.selectedInital.trim().toLowerCase() : '';
  
    this.loadInital();
    

  }
  /**
   * On destroy
   */



  delteInital(id: any) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this! This will only delete the record won't revert the stock changes",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {

      if (result.isConfirmed) {
        this._inventoryService.deleteInventoryEntry(id)

          .subscribe((isDeleted) => {

            this.loadInital();
            if (!isDeleted) {
              Swal.fire("Error!", "Cannot Delete");
              return;
            }

            // Display a success message
            Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            )
          });
      }
    });
  }


  createInital() {
    this._router.navigate(['apps/inventory-adjustment', 'NEW']);

  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }


  loadInital() {
    this.initalSheet$ = this._inventoryService.getInventoryEntry().pipe(take(1));

    this.initalSheet$.subscribe((inital) => {
      this.initalSheet = inital['data'];

      this.dataSource = new MatTableDataSource<IntialStock>(
        this.initalSheet
          .map((inital) => {
            // Map nested properties of products array
            const product = inital.products;
            const pipedDate = inital.date.split('T')[0]; // Extracting only the date part

            return {
              id: inital.id,
              date: pipedDate,
              productName: product.base_product.productName,
              size: product.size,
              color: product.color,
              initial_entry: inital.initial_entry,
              isPreOrder: product.base_product.pre_order_toggel,
              created_by:inital.created_by.username,
            };
          })
          .filter((item) => {
          
            if (this.initalStockFilter === 'pre order inital') {

              return item.isPreOrder === true;

            } else if (this.initalStockFilter === 'product inital') {
              return item.isPreOrder === false;
            } else {
              return true;
            }

          })

      );
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.isLoading = false;
    });
  }


}
